import { ITicketADO } from '../components/Interface/ITicketADO'
export const TicketPattern = {
    Title: `Sonic - {{{CurationConfiguration.CurationConfigurationName}}} - {{{CurationJob.JobCompletedDate}}}`,
    Description: `Sonic - {{{CurationConfiguration.CurationConfigurationDescription}}} - {{{CurationJob.JobCompletedDate}}}`
}

export const RemediateTicketPattern = {
    Title: `Action Required! Review automated change by {{{ExpectedCompletionDate}}} for {{{KBID}}} – {{{ArticleTitle}}} `,
    Description: `<div>
  <div>
	  <div>
		  {{{NoneProdWarning}}}
	  </div>
	  <div>
		  <h2>
			  <span style="color:red;">ACTION REQUIRED: </span>
		  </h2>
		  <b><span
			  style="font-size: 12pt; font-family: Segoe UI,sans-serif; color: rgb(0, 117, 209) !important">
			  Please click "Review the automated changes" button below to open the Microsoft Digital Asset
			  Self-Healing Tool and review the automated suggested changes of your help or support content, select the
			  appropriate options shown on the page and select Confirm by
			  <span
				  style="font-size: 12pt; font-family: Segoe UI,sans-serif; color: red !important">{{{ExpectedCompletionDate}}}</span>.
		  </span></b>
		  <br><br>
	  </div>
	  <div>
		  <table width="100%" style="width:100.0%;border-collapse:collapse;">
			  <tbody>
				  <tr style="height:39.0pt;">
					  <td style="padding:.75pt .75pt .75pt .75pt;height:39.0pt;">
						  <table style="border-collapse:collapse;">
							  <tbody>
								  <tr style="height:37.5pt;">
									  <td width=560
										  style="padding:.75pt .75pt .75pt .75pt;height:37.5pt;">
										  <div align=center>
											  <table style="border-collapse:collapse;">
												  <tbody>
													  <tr style="height:.5in;">
														  <td style="background:#0C64C0;padding:7.5pt 18.75pt 7.5pt 18.75pt;height:.5in;">
															  <p align=center
																  style="margin:0in;font-size: 12pt;font-family: Segoe UI Semibold,sans-serif;text-align:center;">
																  <span
																	  style="font-size: 16pt;font-family: Segoe UI Semibold,sans-serif;color:white;">
																	  <a style="text-decoration:none"
																		  href="{{{AutoHealingLink}}}"
																		  title="Original URL: {{{AutoHealingLink}}}. Click or tap if you trust this link.">
																		  <span style="color:rgb(255, 255, 255);">
																			  Review the automated changes
																		  </span>
																	  </a>
																  </span>
															  </p>
														  </td>
													  </tr>
												  </tbody>
											  </table>
										  </div>
									  </td>
								  </tr>
							  </tbody>
						  </table>
					  </td>
				  </tr>
			  </tbody>
		  </table>
		  <br>
	  </div>
  </div>
  <div>
	  <span style="font-size: 12pt;font-family: Segoe UI,sans-serif;">
		  You can fix the issues by removing the 404 URLs or substituting the broken links with a functional alternative.
          Once you <b>approve</b> the change to the article, it will be automatically modified,
		  re-published, and the Azure DevOps ticket will be auto-resolved.
		  If you decide not to <b>take any action</b> on the articles, the ticket will also be auto-resolved. However,
		  if you choose to <b>decline</b> the change and open the file for manual edits, you will need to update and
		  then close the ticket.
	  </span>
  </div>
  <div>
	  <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">&nbsp; </p>
	  <table style="width:480.0pt;">
		  <tbody>
			  <tr>
				  <td style="width:191.25pt;padding:9.0pt .25in 0in .25in;">
					  <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;margin-bottom:12.0pt;">
						  <b><span
							  style="font-size: 15pt;font-family: Segoe UI,sans-serif;color:#11100F;">Details</span></b>
					  </p>
				  </td>
			  </tr>
			  <tr>
				  <td colspan=2 style="padding:0in .25in 9.0pt .25in;">
					  <table style="width:441.0pt;border-collapse:collapse;border:none;">
						  <tbody>
							  <tr>
								  <td
									  style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
									  <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
										  <b><span
												  style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Article
												  with issues</span></b>
									  </p>
								  </td>
								  <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
									  <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
										  <b><span style="font-size: 12pt;">{{{KBID}}}</span></b><span
											  style="font-size: 12pt;"> -&nbsp;</span><a href="{{{ArticleLink}}}"
											  title="{{{ArticleLink}}}"><span
												  style="font-size: 12pt;">{{{ArticleTitle}}}</span></a>
									  </p>
								  </td>
							  </tr>
							  <tr>
								  <td
									  style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
									  <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
										  <b><span
												  style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Article
												  contacts</span></b>
									  </p>
								  </td>
								  <td
									  style="border: none; border-top: solid #DEDEDE 1.5pt; padding: 6.0pt 0in 6.0pt 0in; height: 100px;">
									  <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
										  {{{ArticlePublisher}}}
									  </p>
									  <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
										  {{{ArticleWriter}}}
									  </p>
									  <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
										  {{{ArticleAssigned}}}
									  </p>
									  <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
										  {{{ArticleCreator}}}
									  </p>
								  </td>
							  </tr>
							  <tr>
								  <td
									  style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
									  <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
										  <b><span
												  style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Issue
												  type</span></b>
									  </p>
								  </td>
								  <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
									  <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
										  <span style="font-size: 12pt;">Broken link</span>
									  </p>
								  </td>
							  </tr>
							  <tr>
								  <td
									  style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
									  <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
										  <b><span
												  style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Detected
												  time</span></b>
									  </p>
								  </td>
								  <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
									  <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
										  <span style="font-size: 12pt;">{{{DetectedTime}}}</span>
									  </p>
								  </td>
							  </tr>
							  <tr>
                                    <td style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                              <b><span style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">History</span></b>
                                         </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                       <p style="margin:0in;font-size:12pt;font-family: Segoe UI,sans-serif;">
                                           <span style="font-size: 12pt;">Visit the <a href="{{{PowerBIReport}}}">PowerBI report</a> to review the details.</span>
                                        </p>
                                    </td>
                              </tr>
						  </tbody>
					  </table>
				  </td>
			  </tr>
		  </tbody>
	  </table>
	  <br>
	  <br>
  </div>
  <div>
	  <table class="footer">
		  <tbody>
			  <tr>
				  <td>
					  <div>
						  <div>
							  <div>
								  <h3>
									  About this ticket
								  </h3>
							  </div>
							  <div>
								  <span><u>Q. Why is the ticket assigned to me?</u></span><br>
								  <span>A. You're assigned to handle this ticket because you're either the last
									  recorded author of the article or you are the designated default because there
									  is no last recorded author or that individual is no longer in the system. We
									  have identified one or more content-quality issues in the article and ADO ticket
									  has been auto generated and assigned to you. We value your time and appreciate
									  that reviewing and resolving such issues is likely outside your typical work
									  tasks. We have developed an automated “self-healing” system called Chiron to
									  save you as many steps – and as much time – as possible. </span>
							  </div>
							  <br />
							  <div>
								  <span>
									  <u>Q. What do I need to do?</u>
								  </span> <br>
								  <span>
									  A. You need to open the “Review the automated changes” link
									  above and follow the instructions in the page. The page contains details about
									  the issues found in the article. Depending on the type of issue, the article
									  might have been automatically checked out and a possible resolution applied
									  pending your review and approval. Please review the issues and any
									  auto-applied fix, and determine whether you wish to modify or approve the
									  automated change, and select Confirm. If you choose to not approve and update
									  the article manually, you need to open the ADO ticket and close when the
									  change has been implemented.
								  </span>
							  </div>
						  </div>
						  <br />
						  <div>
							  <h3>Information and support </h3>
							  <ul>
								  <li>
									  <span>
										  If you have any questions on the ticket assigned to you, or a specific
										  content-quality issue, please click <a
											  href="https://aka.ms/Chironsupport">here</a> to create a ticket for
										  assistance.
									  </span>
								  </li>
								  <li>
									  <span>
										  If you have any technical questions about Sonic and Chiron, or if the tools
										  are not working correctly, please click <a
											  href="https://aka.ms/ChironTools">here</a> to create a ticket for
										  assistance.
									  </span>
								  </li>
							  </ul>
							  <br>
							  <span>Thank you, </span>
							  <h3>
								  <span>
									  The CE&amp;S Knowledge
									  Management Team
								  </span>
							  </h3>
						  </div>
					  </div>
				  </td>
			  </tr>
		  </tbody>
	  </table>
  </div>
</div>`,
}

export const Fix404HyperlinksADOTicketSettingDev: ITicketADO = {
    Organization: "Internal-KM-CMS",
    Project: "Sonic Test ADO",
    EpicId: "69",
    FeatureId: "70",
    RequirementId: "71",
    UserStoryId: null,
    WorkItemType: "Task",
    MaximumChildTasks: "10",
    OrderBy: "",
    AssignTo: "",
    AssignToType: "Assign to a default person",
    ChildTaskAssignTo: "",
    ChildTaskAssignToType: "Assign to a default person",
    Priority: "3",
    Severity: "3",
    Area: "Sonic Test ADO",
    AreaId: "56",
    Iteration: "Sonic Test ADO",
    IterationId: "51",
    TitlePattern: TicketPattern.Title,
    DescriptionPattern: TicketPattern.Description,
    WorkItemTitlePattern: RemediateTicketPattern.Title,
    WorkItemDescriptionPattern: RemediateTicketPattern.Description,
    Tags: "",
    CreateChildTasksOrNot: true,
    HasAttachmentOrNot: false,
    SendIncrementalOrNot: true,
    FieldItems: [],
    OtherFields: []
};

export const Fix404HyperlinksADOTicketSettingProd: ITicketADO = {
    Organization: "Automatic Content Creation Tool",
    Project: "Automatic Content Creation Tool",
    EpicId: "74",
    FeatureId: "75",
    RequirementId: "1188",
    UserStoryId: null,
    WorkItemType: "Task",
    MaximumChildTasks: "10",
    OrderBy: "",
    AssignTo: "",
    AssignToType: "Assign to a default person",
    ChildTaskAssignTo: "",
    ChildTaskAssignToType: "Assign to a default person",
    Priority: "3",
    Severity: "3",
    Area: "Automatic Content Creation Tool",
    AreaId: "16",
    Iteration: "Automatic Content Creation Tool",
    IterationId: "11",
    TitlePattern: TicketPattern.Title,
    DescriptionPattern: TicketPattern.Description,
    WorkItemTitlePattern: RemediateTicketPattern.Title,
    WorkItemDescriptionPattern: RemediateTicketPattern.Description,
    Tags: "",
    CreateChildTasksOrNot: true,
    HasAttachmentOrNot: false,
    SendIncrementalOrNot: true,
    FieldItems: [],
    OtherFields: []
};

export const LMCDataSetTicketPattern = {
	Title:`Action Required! Review and update the outdated article by {{{ExpiredDate}}} – {{{ArticleTitle}}}. `,
	Description:`<div>
    <div>
        <div>
            <h2>
                <span style="color:red;">ACTION REQUIRED: </span>
            </h2>
            <span
                style="font-size: 12pt; font-family: Segoe UI,sans-serif; color: rgb(0, 120, 212) !important">
                Please follow the provided link to access the article and review its content. Make sure the article is
                up to date. After making the necessary updates, please modify the "ms.date" property in the article to
                reflect the date of the latest republishing by
                <span
                    style="font-size: 12pt; font-family: Segoe UI,sans-serif; color: red !important">{{{ExpiredDate}}}</span>.
            </span>
            <br><br>
        </div>
    </div>
    <div>
        <a href="{{{ArticleLink}}}" title="{{{ArticleLink}}}"><span
                style="font-size:12pt;">{{{ArticleTitle}}}</span></a>
        <br />
    </div>
    <div>
        <br />
    </div>
    <div>
        <span style="font-size: 12pt;font-family: Segoe UI,sans-serif;">
            Once you fix this issue, you will need to close the ticket.
        </span>
    </div>
    <div>
        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">&nbsp; </p>
        <table style="width:480.0pt;">
            <tbody>
                <tr>
                    <td style="width:191.25pt;padding:9.0pt .25in 0in .25in;">
                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;margin-bottom:12.0pt;">
                            <span
                                style="font-size:15pt;font-family: Segoe UI,sans-serif;color:#11100F;">Details</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colspan=2 style="padding:0in .25in 9.0pt .25in;">
                        <table style="width:441.0pt;border-collapse:collapse;border:none;">
                            <tbody>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Article
                                                    with issues</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <span style="font-size: 12pt;"> &nbsp;</span><a href="{{{ArticleLink}}}"
                                                title="{{{ArticleLink}}}"><span
                                                    style="font-size: 12pt;">{{{ArticleTitle}}}</span></a>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Article
                                                    contacts</span></b>
                                        </p>
                                    </td>
                                    <td
                                        style="border: none; border-top: solid #DEDEDE 1.5pt; padding: 6.0pt 0in 6.0pt 0in; height: 100px;">
                                        <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
                                            {{{Author}}}
                                        </p>
                                        <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
                                            {{{Manager}}}
                                        </p>
                                        <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
                                            {{{ContentManager}}}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Issue
                                                    type</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <span style="font-size: 12pt;">Outdated</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Issue
                                                    details</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <span style="font-size: 12pt;">The value of the "ms.date" property in the
                                                article is prior to {{{MsDate}}}.</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Detected
                                                    time</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <span style="font-size: 12pt;">{{{DetectedTime}}}</span>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <br>
    </div>
    <div>
        <table class="footer">
            <tbody>
                <tr>
                    <td>
                        <div>
                            <div>
                                <div>
                                    <h3>
                                        About this ticket
                                    </h3>
                                </div>
                                <div>
                                    <span><u>Q. Why is the ticket assigned to me?</u></span><br>
                                    <span>A. You're assigned to handle this ticket because you're either the last
                                        recorded author of the article or you are the designated default because there
                                        is no last recorded author or that individual is no longer in the system. We
                                        have identified one or more content-quality issues in the article and ADO ticket
                                        has been auto generated and assigned to you. </span>
                                </div>
                                <br />
                                <div>
                                    <span>
                                        <u>Q. What do I need to do?</u>
                                    </span> <br>
                                    <span>
                                        A. You need to open the article link above and update the article or piece of
                                        content contains information that is no longer accurate or relevant, which can
                                        lead to confusion and misinformation for the readers. You need to open the ADO
                                        ticket and close when the content issue has been fixed.
                                    </span>
                                </div>
                            </div>
                            <br />
                            <div>
                                <h3>Information and support </h3>
                                <ul>
                                    <li>
                                        <span>
                                            If you have any questions on the ticket assigned to you, or a specific
                                            content-quality issue, please click <a
                                                href="https://aka.ms/ACCTSupport">here</a> to create a ticket for
                                            assistance.
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            If you have any technical questions about Sonic and Chiron, or if the tools
                                            are not working correctly, please click <a
                                                href="https://aka.ms/ACCTTools">here</a> to create a ticket for
                                            assistance.
                                        </span>
                                    </li>
                                </ul>
                                <br>
                                <span>Thank you, </span>
                                <h3>
                                    <span>
                                        The CE&amp;S Knowledge
                                        Management Team
                                    </span>
                                </h3>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>`
}

export const PoliCheckIssuePattern = {
    Title: `[PoliCheck issue] {{{ArticleTitle}}} `,
    Description: `<div style="font-family: Segoe UI,sans-serif;">
    <div>
        <div>     
            <span
                style="font-size: 12pt; font-weight:500;color: rgb(0, 120, 212) !important">
                Please review the identified PoliCheck issues on the following table, and take action for remediation. 
            </span>
        </div>
    </div>
    <div>
        <br />
    </div>
    
    <div>
        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">&nbsp; </p>
        <table style="width:480.0pt;">
            <tbody>
                <tr>
                    <td style="width:191.25pt;padding:9.0pt .25in 0in .25in;">
                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;margin-bottom:12.0pt;">
                            <span
                                style="font-size:15pt;font-family: Segoe UI,sans-serif;color:#11100F;">Details</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colspan=2 style="padding:0in .25in 9.0pt .25in;">
                        <table style="width:441.0pt;border-collapse:collapse;border:none;">
                            <tbody>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Article
                                                    with issues</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <span style="font-size: 12pt;"> &nbsp;</span><a href="{{{ArticleLink}}}"
                                                title="{{{ArticleLink}}}"><span
                                                    style="font-size: 12pt;">{{{ArticleTitle}}}</span></a>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Article
                                                    contacts</span></b>
                                        </p>
                                    </td>
                                    <td
                                        style="border: none; border-top: solid #DEDEDE 1.5pt; padding: 6.0pt 0in 6.0pt 0in; height: 100px;">
                                        <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
                                            {{{Author}}}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Issue
                                                    type</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <span style="font-size: 12pt;">PoliCheck Terms</span>
                                        </p>
                                    </td>
                                </tr>                               
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Detected
                                                    time</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <span style="font-size: 12pt;">{{{DetectedTime}}}</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">DetectedBy</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <span style="font-size: 12pt;">{{{DetectedBy}}}</span>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <br>
        <div>
            <h3>
                Issue details
            </h3>
        </div>
        <!-- <table style="width: 100%;border-spacing: 0;box-shadow: 3px 3px 3px #d3d1d1;border-radius: 4px;">
            <tbody>
                <tr>
                    <th style="border-top-left-radius: 4px;padding: 8px;text-align: left; background-color: #f2f2f2;">Location</th>
                    <th style="padding: 8px; text-align: left; background-color: #f2f2f2;">Term</th>
                    <th style="padding: 8px; text-align: left; background-color: #f2f2f2;">Term Class</th>
                    <th style="padding: 8px; text-align: left; background-color: #f2f2f2;">Context</th>
                    <th style="border-top-right-radius: 4px;padding: 8px; text-align: left; background-color: #f2f2f2;">Guidelines</th>
                </tr>
                <tr>
                    <td style="border-bottom: 1px solid #DEDEDE; padding: 8px; text-align: left;">Line 16</td>
                    <td style="border-bottom: 1px solid #DEDEDE; padding: 8px; text-align: left;">Country</td>
                    <td style="border-bottom: 1px solid #DEDEDE; padding: 8px; text-align: left;">Term Class</td>
                    <td style="border-bottom: 1px solid #DEDEDE; padding: 8px; text-align: left;">test test conntry</td>
                    <td style="border-bottom: 1px solid #DEDEDE; padding: 8px; text-align: left;">For detailed info see - http://policheck.azurewebsites.net/Pages/TermInfo.aspx?LCID=9&TermID=172432</td>
                </tr>
                <tr>
                    <td style="border-bottom: 1px solid #DEDEDE; padding: 8px; text-align: left;">Line 16</td>
                    <td style="border-bottom: 1px solid #DEDEDE; padding: 8px; text-align: left;">Country</td>
                    <td style="border-bottom: 1px solid #DEDEDE; padding: 8px; text-align: left;">Term Class</td>
                    <td style="border-bottom: 1px solid #DEDEDE; padding: 8px; text-align: left;">test test conntry</td>
                    <td style="border-bottom: 1px solid #DEDEDE; padding: 8px; text-align: left;">For detailed info see - http://policheck.azurewebsites.net/Pages/TermInfo.aspx?LCID=9&TermID=172432</td>
                </tr>
            </tbody>
        </table> -->
        {{{PoliCheckIssueDetails}}}
    </div>
   
</div>`
}

export const TextReplaceTicketPattern = {
    Title: `Action Required! Review automated change by {{{ExpectedCompletionDate}}} for {{{KBID}}} – {{{ArticleTitle}}} `,
    Description: `<div>
    <div>
        <div>
            {{{NoneProdWarning}}}
        </div>
        <div>
            <h2>
                <span style="color:red;">ACTION REQUIRED: </span>
            </h2>
            <b><span
                style="font-size: 12pt; font-family: Segoe UI,sans-serif; color: rgb(0, 117, 209) !important">
                Please click "Review the automated changes" button below to open the Microsoft Digital Asset
                Self-Healing Tool and review the automated suggested changes of your help or support content, select the
                appropriate options shown on the page and select Confirm by
                <span
                    style="font-size: 12pt; font-family: Segoe UI,sans-serif; color: red !important">{{{ExpectedCompletionDate}}}</span>.
            </span></b>
            <br><br>
        </div>
        <div>
            <table width="100%" style="width:100.0%;border-collapse:collapse;">
                <tbody>
                    <tr style="height:39.0pt;">
                        <td style="padding:.75pt .75pt .75pt .75pt;height:39.0pt;">
                            <table style="border-collapse:collapse;">
                                <tbody>
                                    <tr style="height:37.5pt;">
                                        <td width=560
                                            style="padding:.75pt .75pt .75pt .75pt;height:37.5pt;">
                                            <div align=center>
                                                <table style="border-collapse:collapse;">
                                                    <tbody>
                                                        <tr style="height:.5in;">
                                                            <td style="background:#0C64C0;padding:7.5pt 18.75pt 7.5pt 18.75pt;height:.5in;">
                                                                <p align=center
                                                                    style="margin:0in;font-size: 12pt;font-family: Segoe UI Semibold,sans-serif;text-align:center;">
                                                                    <span
                                                                        style="font-size: 16pt;font-family: Segoe UI Semibold,sans-serif;color:white;">
                                                                        <a style="text-decoration:none"
                                                                            href="{{{AutoHealingLink}}}"
                                                                            title="Original URL: {{{AutoHealingLink}}}. Click or tap if you trust this link.">
                                                                            <span style="color:rgb(255, 255, 255);">
                                                                                Review the automated changes
                                                                            </span>
                                                                        </a>
                                                                    </span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
        </div>
    </div>
    <div>
        <span style="font-size: 12pt;font-family: Segoe UI,sans-serif;">
            You can fix the issues by substituting the text with a functional alternative.
            Once you <b>approve</b> the change to the article, it will be automatically modified,
            re-published, and the Azure DevOps ticket will be auto-resolved.
            If you decide not to <b>take any action</b> on the articles, the ticket will also be auto-resolved. However,
            if you choose to <b>decline</b> the change and open the file for manual edits, you will need to update and
            then close the ticket.
        </span>
    </div>
    <div>
        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">&nbsp; </p>
        <table style="width:480.0pt;">
            <tbody>
                <tr>
                    <td style="width:191.25pt;padding:9.0pt .25in 0in .25in;">
                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;margin-bottom:12.0pt;">
                            <b><span
                                style="font-size: 15pt;font-family: Segoe UI,sans-serif;color:#11100F;">Details</span></b>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colspan=2 style="padding:0in .25in 9.0pt .25in;">
                        <table style="width:441.0pt;border-collapse:collapse;border:none;">
                            <tbody>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Article
                                                    with issues</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span style="font-size: 12pt;">{{{KBID}}}</span></b><span
                                                style="font-size: 12pt;"> -&nbsp;</span><a href="{{{ArticleLink}}}"
                                                title="{{{ArticleLink}}}"><span
                                                    style="font-size: 12pt;">{{{ArticleTitle}}}</span></a>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Article
                                                    contacts</span></b>
                                        </p>
                                    </td>
                                    <td
                                        style="border: none; border-top: solid #DEDEDE 1.5pt; padding: 6.0pt 0in 6.0pt 0in; height: 100px;">
                                        <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
                                            {{{ArticlePublisher}}}
                                        </p>
                                        <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
                                            {{{ArticleWriter}}}
                                        </p>
                                        <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
                                            {{{ArticleAssigned}}}
                                        </p>
                                        <p style="margin: 0in; font-size: 12pt; font-family: Segoe UI,sans-serif;">
                                            {{{ArticleCreator}}}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Issue
                                                    type</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <span style="font-size: 12pt;">Invalid text, such as an expired product name.</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:2.45in;border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <b><span
                                                    style="font-size: 12pt;font-family: Segoe UI,sans-serif;color:#11100F;">Detected
                                                    time</span></b>
                                        </p>
                                    </td>
                                    <td style="border:none;border-top:solid #DEDEDE 1.5pt;padding:6.0pt 0in 6.0pt 0in;">
                                        <p style="margin:0in;font-size: 12pt;font-family: Segoe UI,sans-serif;">
                                            <span style="font-size: 12pt;">{{{DetectedTime}}}</span>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <br>
    </div>
    <div>
        <table class="footer">
            <tbody>
                <tr>
                    <td>
                        <div>
                            <div>
                                <div>
                                    <h3>
                                        About this ticket
                                    </h3>
                                </div>
                                <div>
                                    <span><u>Q. Why is the ticket assigned to me?</u></span><br>
                                    <span>A. You're assigned to handle this ticket because you're either the last
                                        recorded author of the article or you are the designated default because there
                                        is no last recorded author or that individual is no longer in the system. We
                                        have identified one or more content-quality issues in the article and ADO ticket
                                        has been auto generated and assigned to you. We value your time and appreciate
                                        that reviewing and resolving such issues is likely outside your typical work
                                        tasks. We have developed an automated “self-healing” system called Chiron to
                                        save you as many steps – and as much time – as possible. </span>
                                </div>
                                <br />
                                <div>
                                    <span>
                                        <u>Q. What do I need to do?</u>
                                    </span> <br>
                                    <span>
                                        A. You need to open the “Review the automated changes” link
                                        above and follow the instructions in the page. The page contains details about
                                        the issues found in the article. Depending on the type of issue, the article
                                        might have been automatically checked out and a possible resolution applied
                                        pending your review and approval. Please review the issues and any
                                        auto-applied fix, and determine whether you wish to modify or approve the
                                        automated change, and select Confirm. If you choose to not approve and update
                                        the article manually, you need to open the ADO ticket and close when the
                                        change has been implemented.
                                    </span>
                                </div>
                            </div>
                            <br />
                            <div>
                                <h3>Information and support </h3>
                                <ul>
                                    <li>
                                        <span>
                                            If you have any questions on the ticket assigned to you, or a specific
                                            content-quality issue, please click <a
                                                href="https://aka.ms/Chironsupport">here</a> to create a ticket for
                                            assistance.
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            If you have any technical questions about Sonic and Chiron, or if the tools
                                            are not working correctly, please click <a
                                                href="https://aka.ms/ChironTools">here</a> to create a ticket for
                                            assistance.
                                        </span>
                                    </li>
                                </ul>
                                <br>
                                <span>Thank you, </span>
                                <h3>
                                    <span>
                                        The CE&amp;S Knowledge
                                        Management Team
                                    </span>
                                </h3>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>`
}