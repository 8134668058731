import React, { Component, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { returnType } from "../Utilities/ReturnType";
import { GlobalDataQuery, IDataSource } from "../Interface/IPowerQuery";
import { getDataSourceList, deleteDataSource } from "../../redux/Actions/PowerDataQuery";
import { Table, renderSimpleCell, ColumnMore } from "azure-devops-ui/Table";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Spinner } from "@fluentui/react";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { CustomHeader, HeaderTitle, HeaderTitleArea, HeaderTitleRow } from "azure-devops-ui/Header";
import { TitleSize } from "azure-devops-ui/Header";
import { IHeaderCommandBarItem, HeaderCommandBar } from "azure-devops-ui/HeaderCommandBar";
import { SplitButton } from "azure-devops-ui/SplitButton";
import { Card } from "azure-devops-ui/Card";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { Dialog } from "azure-devops-ui/Dialog";
import { Observer } from "azure-devops-ui/Observer";
import { IMenuItem, MenuItemType } from "azure-devops-ui/Menu";
import FileUploadDialog from '../Dialogs/FileUploadDialog/FileUploadDialog'

import './CustomizedText.css'

const actionCreators = {
  getDataSourceList,
  deleteDataSource
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
  dataSourceList: state.dataSourceList
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

/**
 * interface for recent task search 
 */
interface IDataSourceListState {
  items: IDataSource[]
}

/**
 * interface for delete/enable/disable
 */
interface IDataSourceActionState {
  showDialog: boolean,
  dialogType: "delete" | "enable" | "disable" | "other",
  Id?: string,
  dataSourceName: string
}

const dialogObservable = new ObservableValue<boolean>(false);
function CustomizedText(props: StoreProps & DispatchProps) {
  const history = useNavigate();

  /**
   * Initialize menu items
   */
  const menuItems: IMenuItem[] = [
    { id: "importcsv", text: "Import a csv file" },
    // { id: "two", text: "Create from text" },
    // { id: "separator", itemType: MenuItemType.Divider },
    // { id: "four", text: "Item 4" }
  ];

  /**
   * Initialize header bar
   */
  const commandBarItemsAdvanced: IHeaderCommandBarItem[] = [
    {

      id: "datasourceBar",
      renderButton: () => {
        return <SplitButton
          key={"datasourceSelect"}
          buttonProps={{
            text: "New custom data source",
            iconProps: {
              iconName: "Add"
            }
          }}
          menuButtonProps={{
            ariaLabel: "See options",
            contextualMenuProps: {
              menuProps: {
                id: "5",
                items: menuItems,
                onActivate: itemProps => handleDataSourceSelect(itemProps)
              }
            }
          }}
        />
      }
    }
  ]

  /**
  * initialize table items state
  */
  const [currentState, setCurrentState] = useState<IDataSourceListState>({
    items: []
  })

  /**
   * initialize loading state
   */
  const [isLoading, setIsLoading] = useState<boolean>(false)

  /**
   * initialize file dailog status
   */
  const [isFileDailogHidden, setIsFileDailogHidden] = useState(true);

  /**
  * initialize action state
  */
  const [actionState, setActionState] = useState<IDataSourceActionState>({
    showDialog: false,
    dialogType: "delete",
    Id: '',
    dataSourceName: ''
  })


  /**
   * load data source list
   */
  const loadData = async () => {
    setIsLoading(true)

    // call the api to get the list
    await props.getDataSourceList();

    setCurrentState({
      ...currentState,
      items: buildItems()
    })

    // set isLoading = false
    setIsLoading(false)
  }

  /**
 * like componentDidMount
 */
  useEffect(() => {
    loadData();
  }, [])

  /**
* like componentDidUpdate
*/
  useEffect(() => {

    setCurrentState({
      items: buildItems()
    })

  }, [props.dataSourceList])

  /**
   * build data list columns
   * @returns 
   */
  function buildColumns(): any {
    const columns = [
      {
        id: "Name",
        name: "Data Source Name",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: new ObservableValue(-30),
      },
      {
        id: "Type",
        maxWidth: 300,
        name: "Data Source Type",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-30),
      },

      new ColumnMore((currentItem: IDataSource) => {
        return {
          id: "sub-menu",
          items: [
            { id: "create", disabled: false, text: "Create a curation", onActivate: () => { history(`/curations/create`) } },
            // { id: "edit", disabled: true, text: "Edit data Source", onActivate: () => { console.log('edit') } },
            {
              id: "delete", disabled: false, text: "Delete data Source", onActivate: () => {
                setActionState({
                  ...actionState,
                  showDialog: true,
                  dialogType: "delete",
                  Id: '',
                  dataSourceName: currentItem.Name
                })
              }
            },
          ],
        };
      }),
    ];

    return columns;
  }

  /**
   * build data list items
   * @returns 
   */
  function buildItems() {
    let itmes: IDataSource[] = []
    if (!props.dataSourceList || !props.dataSourceList.length) {
      return itmes;
    }

    props.dataSourceList.map(item => {
      if (item.Type === 'TextSearch' || item.Type === 'TermSearch') {
        itmes.push({
          Name: item.Name,
          Type: item.Type,
          ConnectionEndPoint: item.ConnectionEndPoint
        })
      }      
    })

    return itmes;
  }

  /**
   * handle data source select
   * @param itemProps 
   */
  function handleDataSourceSelect(itemProps: IMenuItem) {
    switch (itemProps.id) {
      case "importcsv":
        setIsFileDailogHidden(false);
        break;
    }
  }

  /**
   * handle file submit callback
   * @param submitStatus 
   */
  function handleFileUploadSubmit(submitStatus: string) {
    setIsFileDailogHidden(true);

    loadData();
  }

  /**
   * hancle cancel click
   */
  function handleFileUploadCancel() {
    setIsFileDailogHidden(true);
  }

  /**
   * handle dialog dismiss
   */
  function handleActionDialogeDismiss() {
    dialogObservable.value = false;

    setActionState({
      ...actionState,
      showDialog: false
    })
  }

  /**
   * handle action event
   */
  async function handleActionDilagConfirm() {
    const { dialogType, dataSourceName } = actionState

    setActionState({
      ...actionState,
      showDialog: false
    })

    setIsLoading(true)

    switch (dialogType) {
      case "delete":
        await props.deleteDataSource(dataSourceName)
        break;
      // case "disable":
      //   await props.deleteDataSource("Disable", actionCurationId)
      //   break;
      // case "enable":
      //   await props.deleteDataSource("Enable", actionCurationId)
      //   break;
      default:
        break
    }

    await props.getDataSourceList()

    setIsLoading(false)
  }

  /**
   * build the dialog title
   * @returns 
   */
  function buildDialogTitle() {
    const { dialogType } = actionState

    switch (dialogType) {
      case "delete":
        return "Delete data source";
      case "disable":
        return "Disable data source";
      case "enable":
        return "Enable data source";
      default:
        return "Delete data source";
    }
  }

  /**
  * build the conform dialog text
  * @param taskName 
  * @returns dialog text
  */
  function buildDialogText(): JSX.Element {
    const { dialogType, dataSourceName } = actionState

    switch (dialogType) {
      case "delete":
        return (
          <div>
            The data source <b>{dataSourceName}</b> will be deleted permanently, click confirm to proceed.
          </div>
        )
      case "disable":
        return (
          <div>
            The data source <b>{dataSourceName}</b> will be disabled, click confirm to proceed.
          </div>
        )
      case "enable":
        return (
          <div>
            The data source <b>{dataSourceName}</b> will be enabled, click confirm to proceed.
          </div>
        )
      default:
        return (
          <div>
            The data source <b>{dataSourceName}</b> will be deleted permanently, click confirm to proceed.
          </div>
        )
    }
  }

  return (
    <div >
      <Surface background={SurfaceBackground.neutral}>
        <Page className="pipelines-page flex-grow">
          <CustomHeader className="bolt-header-with-commandbar">
            <HeaderTitleArea>
              <HeaderTitleRow className="sonic-datasource-title">
                <HeaderTitle ariaLevel={3} className="text-ellipsis" titleSize={TitleSize.Medium}>
                  Customized text
                </HeaderTitle>
              </HeaderTitleRow>
            </HeaderTitleArea>
            <HeaderCommandBar buttonCount={1} items={commandBarItemsAdvanced} />
          </CustomHeader>

          <Card
            className="flex-grow bolt-card-no-vertical-padding bolt-table-card"
            contentProps={{ contentPadding: false }}>
            {/* It must be written like this, otherwise the UseEffect of the FileUploadDialog component will not be used */}
            {
              !isFileDailogHidden &&
              <FileUploadDialog
                isHidden={isFileDailogHidden}
                dataSourceItems={currentState.items}
                dataSourceType = {"TextSearch"}
                OnSubmit={handleFileUploadSubmit}
                OnCancel={handleFileUploadCancel}
              />
            }

            {/* Load data source list */}
            {
              isLoading ?
                (
                  <Spinner className='msccat-page-loading' label="Loading" />
                )
                :
                (
                  <Table<Partial<IDataSource>>
                    columns={buildColumns()}
                    itemProvider={new ArrayItemProvider<IDataSource>(currentState.items)}
                    showLines={true}
                    containerClassName="msacct-devops-table"
                  />
                )
            }

            {/* delete/enable/disable data source */}
            <Observer isDialogOpen={dialogObservable}>
              {
                () => {
                  return actionState.showDialog ?
                    (
                      <Dialog
                        titleProps={{ text: buildDialogTitle() }}
                        footerButtonProps={[
                          {
                            text: "Cancel",
                            onClick: handleActionDialogeDismiss
                          },
                          {
                            text: "Confirm",
                            onClick: handleActionDilagConfirm,
                            primary: true
                          }
                        ]}
                        onDismiss={handleActionDialogeDismiss}
                      >
                        {buildDialogText()}
                      </Dialog>
                    )
                    : null
                }
              }
            </Observer>
          </Card>
        </Page>
      </Surface>
    </div>
  )
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(CustomizedText);


