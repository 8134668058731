import { FormItem } from "azure-devops-ui/FormItem"
import { TextField, TextFieldWidth } from "azure-devops-ui/TextField"
import { Button } from "azure-devops-ui/Button"
import { IconButton } from '@fluentui/react/lib/Button'
import { memo, useEffect, useState } from 'react'
import { Link } from "azure-devops-ui/Link"
import { Spinner, SpinnerSize, SpinnerOrientation } from "azure-devops-ui/Spinner";
import { checkURLFormatIsCorrect } from '../../../Utilities/CommonFunction'

interface IScanResultState {
    allCount: number,
    scannedCount: number,
    errorCount: number,
    warningCount: number,
    successCount: number,
    reportedCount: number,
    scanStatus: string
}

interface IScanUrlState {
    scanUrl: string,
    scanStatus: string,
    urlStatus: string,
    showUrlError: boolean,
    urlErrorMessage: string
}

interface IMiniAPPCommonCheckProps {
    scanResultState?: IScanResultState,
    clearResultState?: Function,
    filterListByType?: Function,
    resultLabel?: string,
    errorLabel?: string,
    warningLabel?: string,
    successLabel?: string,
    statusLabel?: string,
    startCheck: Function,
    featureName: string,
    showResultStateBar: boolean
}

type ReactEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>

function MiniAppCommonCheck(props: IMiniAPPCommonCheckProps) {

    const [scanUrlTextValue, setScanUrlTextValue] = useState<string>('')

    const [scanUrlState, setScanUrlState] = useState<IScanUrlState>({
        scanUrl: '',
        scanStatus: '',
        urlStatus: '',
        showUrlError: false,
        urlErrorMessage: ''
    })

    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

    const enterTip = `Enter the address of your article below and let ${props.featureName} assess the health of your article:`

    useEffect(()=>{
        if(props.scanResultState?.scanStatus === 'Running') {
            setButtonDisabled(true)
        }else {
            setButtonDisabled(false)
        }
    },[props.scanResultState?.scanStatus])

    const renderScanStatus = () => {
        switch (props.scanResultState?.scanStatus) {
            case "Running":
                return <Spinner className='msacct-brokenlink-scanresult' size={SpinnerSize.small} label="Scanning..." orientation={SpinnerOrientation.row} />
            case "Existing":
                return <span style={{ color: "green" }}>Completed</span>
            case "Failed":
                return <span style={{ color: "red" }}>Failed</span>
            default:
                return <span>Not started</span>
        }
    }

    const handleLinkClick = (event: ReactEvent, type: string) => {
        event.preventDefault()
        props.filterListByType(type)
    }

    const urlValidation = () => {
        // empty check
        if (scanUrlTextValue.trim() === '') {
            setScanUrlState({
                ...scanUrlState,
                showUrlError: true,
                urlErrorMessage: `The URL cannot be empty, please use the URL format like "https://internal.evergreen.microsoft.com/en-us/topic/d2177191-3315-ff0d-8973-5cf7a3acd766" to crawl.`
            })
            if(props.showResultStateBar) {
                props.clearResultState()
            }
            return false;
        }

        // format check
        if (!checkURLFormatIsCorrect(scanUrlTextValue)) {
            setScanUrlState({
                ...scanUrlState,
                showUrlError: true,
                urlErrorMessage: `It is incorrect URL format, please use the URL format like "https://internal.evergreen.microsoft.com/en-us/topic/d2177191-3315-ff0d-8973-5cf7a3acd766" to crawl.`
            })
            if(props.showResultStateBar) {
                props.clearResultState()
            }
            return false;
        }
        return true;
    }

    const startCheckClick = () => {
        if(urlValidation()){
            setScanUrlState({
                ...scanUrlState,
                showUrlError: false,
                urlErrorMessage: ''
            })
            props.startCheck(scanUrlTextValue.trim())
        }
    }

    const renderScannedResultCount = () => {
        if(!props.showResultStateBar) {
            return (
                <div className="LayoutPage-demoBlock">
                    <span>{props.resultLabel} ({props.scanResultState.allCount}) </span>
                    <span> | False positive reported ({props.scanResultState.reportedCount})</span>
                    <span> | {props.statusLabel} </span>
                    <span>({renderScanStatus()})</span>
                </div>
            )
        }
        if (props.scanResultState.scanStatus === "Existing") {
            return (
                <div className="LayoutPage-demoBlock">
                    <Link href="#" onClick={event => handleLinkClick(event, 'all')}>{props.resultLabel} </Link>
                    <span style={{ fontWeight: "bold" }}>({props.scanResultState.scannedCount}/{props.scanResultState.allCount}) </span>
                    <span> | </span>
                    <Link href="#" onClick={event => handleLinkClick(event, 'error')}>{ props.errorLabel } </Link>
                    (<span style={{ color: "red", fontWeight: "bold" }}>{props.scanResultState.errorCount}</span>)
                    <span> | </span>
                    <Link href="#" onClick={event => handleLinkClick(event, 'warning')}>{ props.warningLabel } </Link>
                    (<span style={{ color: "#D67F3C", fontWeight: "bold" }}>{props.scanResultState.warningCount}</span>)
                    <span> | </span>
                    <Link href="#" onClick={event => handleLinkClick(event, 'success')}>{ props.successLabel } </Link>
                    (<span style={{ color: "green", fontWeight: "bold" }}>{props.scanResultState.successCount}</span>)
                    <span> | {props.statusLabel} </span>
                    <span>({renderScanStatus()})</span>
                </div>
            )
        }
        else {
            return (
                <div className="LayoutPage-demoBlock">
                    <span>{props.resultLabel} ({props.scanResultState.scannedCount}/{props.scanResultState.allCount}) </span>
                    <span> | </span>
                    <span>{ props.errorLabel } (<span style={{ color: "red" }}>{props.scanResultState.errorCount}</span>) </span>
                    <span> | </span>
                    <span>{ props.warningLabel } (<span style={{ color: "#D67F3C" }}>{props.scanResultState.warningCount}</span>) </span>
                    <span> | </span>
                    <span>{ props.successLabel } (<span style={{ color: "green" }}>{props.scanResultState.errorCount}</span>) </span>
                    <span> | {props.statusLabel} </span>
                    <span>({renderScanStatus()})</span>
                </div>
            )
        }
    }


    return (
        <>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg10">
                    <FormItem label={enterTip} className='sonic-ticketADO-formitem' error={scanUrlState.showUrlError} message={scanUrlState.urlErrorMessage} >
                        <TextField
                            value={scanUrlTextValue}
                            onChange={(e, newValue) => {setScanUrlTextValue(newValue)}}
                            placeholder="Your Website URL"
                            disabled={false}
                            width={TextFieldWidth.standard}
                            containerClassName={"msacct-brokenlink-searchbox"}
                        />
                    </FormItem>
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg2">
                    <Button
                        style={{ "marginTop": "18px" }}
                        text="Start Check"
                        primary={true}
                        disabled={buttonDisabled}
                        onClick={startCheckClick}
                    />
                    <IconButton style={{ marginTop: "18px" }} onClick={() => { window.open("https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Known-issues-of-404-Errors-Algorithms.aspx") }} iconProps={{ iconName: 'Info' }} title="About this link crawler" ariaLabel="help" />
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-lg8" style={{ fontSize: "18px" }}>
                    {renderScannedResultCount()}
                </div>
            </div>
            <div className="ms-Grid-row">
                <br />
            </div>
        </>
    )
}

export default memo(MiniAppCommonCheck);