export const SystemCurationForDEV: Array<any> = [
    { curationName: "Product Expired Articles", curationId: "8ec511f4-b35e-48b4-a74d-1dc5843e46a6" },
    { curationName: "Articles with Empty Products Lifecycle Data", curationId: "69b98ee7-2bc7-4704-9561-880456336812" },
    { curationName: "Articles with Partial Expired Products", curationId: "f79c560a-be13-4bb6-9b8e-edad400311ef" },
    { curationName: "Articles with Outdated SAP Values", curationId: "f0f14485-9762-4b16-9e62-b086fdc4cf4b" },
    { curationName: "Product Expired Articles without Retired SAP Values", curationId: "4f3bf185-5ae4-4998-93ab-5c1c158a870f" },
    { curationName: "Articles with Empty SAP Values", curationId: "4323121f-3b02-4420-a62b-4f1a8495fd75" },
    { curationName: "Broken Link Checker", curationId: "8ac52033-0df4-42c5-a6d9-1737caab778f" },
    { curationName: "Broken Link Checker for Evergreen", curationId: "8ac52033-0df4-42c5-a6d9-1737caab778f" },
    { curationName: "Broken Link Checker for AMC", curationId: "882c2dad-bcb2-46dc-9670-80064ac185a6" },
    { curationName: "Broken Link Checker for DMC", curationId: "f71f16e1-ba6b-4895-b16c-703f2ef5aeb2" },
    { curationName: "Broken Link Checker for SMC", curationId: "7874e7f6-9001-4265-8aba-3c0898483fea" },
    { curationName: "Broken Link Checker for SXC", curationId: "cf5c4553-201f-4d91-8187-b14d7cceb773" },
    { curationName: "Broken Link Checker for Other MS Sites", curationId: "4a16198a-4f6c-4897-8685-29de218e2ab3" },
    { curationName: "Process Article Checker", curationId: "7cda8c01-104f-4d60-abbe-74e1a76ba470"},
    { curationName: "Product Article Checker", curationId: "35605330-c5a0-4080-829b-5ae1a9b8566d"},
    { curationName: "Internal Tool Articles", curationId: "8a18a2e2-854b-45fd-bb71-a8ec650e05c8"},

    { curationName: "PoliCheck for Evergreen", curationId: "cfa1598e-704e-4b41-b1b5-9e202c6997f8"},
    { curationName: "PoliCheck for LMC", curationId: "900822c8-9ec6-40b1-9e94-9750e545f431"},
    { curationName: "PoliCheck for SMC", curationId: "28bcce8b-0f61-4311-b268-661bf4a4fb41"},
    { curationName: "PoliCheck for SXC", curationId: "20c58c9c-1209-4755-b698-df75c18a85e0"},
]
    
export const SystemCurationForPPE: Array<any> = [
    { curationName: "Product Expired Articles", curationId: "06d083d5-2289-4552-af01-8df0219ed97c"},
    { curationName: "Articles with Empty Products Lifecycle Data", curationId: "64eab41f-7e7c-4791-b665-582727c06d45" },
    { curationName: "Articles with Partial Expired Products", curationId: "2c24265e-12d3-48b4-a195-83aca1a4ef2b" },
    { curationName: "Articles with Outdated SAP Values", curationId: "a3f20cef-7f48-4061-a272-b1a95b78b11c" },
    { curationName: "Product Expired Articles without Retired SAP Values", curationId: "63c3376c-57f1-4774-842e-721197bdbd02" },
    { curationName: "Articles with Empty SAP Values", curationId: "c03d803b-ac4d-487a-aea2-262b0a5edfcc" },
    { curationName: "Broken Link Checker", curationId: "52699a44-bdc5-4ed5-a950-431877513ec1" },
    { curationName: "Broken Link Checker for Evergreen", curationId: "52699a44-bdc5-4ed5-a950-431877513ec1" },
    { curationName: "Broken Link Checker for AMC", curationId: "5ac51ded-3058-4306-a8e2-333700b0bb4f" },
    { curationName: "Broken Link Checker for DMC", curationId: "9890c9dc-c86e-4f23-802d-6d69eacfe4e2" },
    { curationName: "Broken Link Checker for SMC", curationId: "cf69ddb3-1938-4861-a0b5-057b8487c3e2" },
    { curationName: "Broken Link Checker for SXC", curationId: "a555c925-52c2-42f2-9104-3cc85934cea7" },
    { curationName: "Broken Link Checker for Other MS Sites", curationId: "197f6819-ab01-4043-bd68-d9a192a52937" },
    { curationName: "Process Article Checker", curationId: ""},
    { curationName: "Product Article Checker", curationId: ""},
    { curationName: "Internal Tool Articles", curationId: ""},

    { curationName: "PoliCheck for Evergreen", curationId: ""},
    { curationName: "PoliCheck for DMC", curationId: ""},
    { curationName: "PoliCheck for SMC", curationId: ""},
    { curationName: "PoliCheck for SXC", curationId: ""},
]

export const SystemCurationForProd: Array<any> = [
    { curationName: "Product Expired Articles", curationId: "b7f0d8c5-d0a0-4ebd-ba32-ff22d1d437fb"},
    { curationName: "Articles with Empty Products Lifecycle Data", curationId: "b28fd599-c79c-40aa-bbb2-ffdcb40c01a6" },
    { curationName: "Articles with Partial Expired Products", curationId: "825548e8-22f8-42b3-9f50-19365616ae5a" },
    { curationName: "Articles with Outdated SAP Values", curationId: "b3bfcc7c-5002-4b38-afa9-e058fdc89ab9" },
    { curationName: "Product Expired Articles without Retired SAP Values", curationId: "de7fb293-a559-49f0-87e5-a6e2bce2ef5a" },
    { curationName: "Articles with Empty SAP Values", curationId: "0ff0ffc8-97ef-408d-b889-4c5811107115" },
    { curationName: "Broken Link Checker", curationId: "46ffdc3c-f772-4022-8942-11e424b8cb03" },
    { curationName: "Broken Link Checker for Evergreen", curationId: "46ffdc3c-f772-4022-8942-11e424b8cb03" },
    { curationName: "Broken Link Checker for AMC", curationId: "cb651de8-3319-4292-bf6d-6fcd8de0a6b3" },
    { curationName: "Broken Link Checker for DMC", curationId: "2f4b6d47-98c8-4849-ab5c-7dcec0ad1b90" },
    { curationName: "Broken Link Checker for SMC", curationId: "6eb89661-f833-4395-8554-d5dad3f5198f" },
    { curationName: "Broken Link Checker for SXC", curationId: "a266ceb3-a080-4b65-9eaf-57dfb1391a7a" },
    { curationName: "Broken Link Checker for Other MS Sites", curationId: "ae4d36c2-a50c-49d7-a30b-da599e94fd9d" },
    { curationName: "Process Article Checker", curationId: "399a12cc-757f-4d5a-87b6-d259b3750313"},
    { curationName: "Product Article Checker", curationId: "f504e5ca-3f42-46f5-a4ec-a0aa9e5fec55"},
    { curationName: "Internal Tool Articles", curationId: "513a69bd-d5cc-40c2-80fc-874fa76deb49"},

    { curationName: "PoliCheck for Evergreen", curationId: "dd6c5741-3edb-4b8c-982b-b4cf78add5c9"},
    { curationName: "PoliCheck for LMC", curationId: "184fc17d-33c0-4293-b709-c56cb978050e"},
    { curationName: "PoliCheck for SMC", curationId: "255bba7e-bfd4-400f-803d-3405077e4b95"},
    { curationName: "PoliCheck for SXC", curationId: "a7aae82a-ddbf-49ba-9a61-8cb5dd510614"},
]
    