import * as React from "react";
import { ObservableArray } from "azure-devops-ui/Core/Observable";
import {
    IdentityPicker,
    IIdentity,
    IPeoplePickerProvider,
    IPersonaConnections,
} from "azure-devops-ui/IdentityPicker";

import { sonicReportUsers } from '../../../ConfigData/ReportToUsers'
import './IdentityPickerExtension.css'

/**
 * interface for default value props
 */
interface IDefaultUsersProps {
    UserMails: string,
    isRequired?: boolean,
    className?: string,
    CustomizeClassName?:string,
    onIdentityPickerChange: (data: any) => void
}

/**
 * Extended Identity Picker Control
 */
export default class MultipleIdentityPickerExtension extends React.Component<IDefaultUsersProps> {
    private pickerProvider = new IdentityPickerProviderExample();
    private selectedIdentities = new ObservableArray<IIdentity>([]);
    private _className = "msacct-identity-picker"

    componentDidMount() {
        if (this.props.UserMails && this.props.UserMails !== '') {
            this.props.onIdentityPickerChange(this.props.UserMails)
            const lstMail = this.props.UserMails.split(";")
            if (lstMail) {
                lstMail.map(item => {
                    let findMail = sonicReportUsers.filter(t => t.userName === item.toLowerCase())
                    if (findMail && findMail.length > 0) {
                        this.selectedIdentities.push({
                            displayName: findMail[0].name,
                            entityId: findMail[0].name,
                            entityType: "user",
                            mail: item,
                            originDirectory: "aad",
                            originId: ""
                        })
                    }
                })
            }
        }

        // Users can customize their own class name
        if (this.props.className && this.props.className !== '') {
            this._className = this.props.className
        }
    }

    public render() {
        return (
            <IdentityPicker
                onIdentitiesRemoved={this.onIdentitiesRemoved}
                onIdentityAdded={this.onIdentityAdded}
                onIdentityRemoved={this.onIdentityRemoved}
                pickerProvider={this.pickerProvider}
                selectedIdentities={this.selectedIdentities}
                className={this._className}
            />
        );
    }

    private onIdentitiesRemoved = (identities: IIdentity[]) => {
        
        this.selectedIdentities.value = this.selectedIdentities.value.filter(
            (entity: IIdentity) =>
                identities.filter((item) => item.entityId === entity.entityId).length === 0
        );

        this.props.onIdentityPickerChange(this.getIdentityMails())
    };

    private onIdentityAdded = (identity: IIdentity) => {
        
        this.selectedIdentities.push(identity);

        this.props.onIdentityPickerChange(this.getIdentityMails())
    };

    private onIdentityRemoved = (identity: IIdentity) => {
        this.selectedIdentities.value = this.selectedIdentities.value.filter(
            (entity: IIdentity) => entity.entityId !== identity.entityId
        );

        this.props.onIdentityPickerChange(this.getIdentityMails())
    };

    private getIdentityMails(): string {
        let mails = ""
        if (this.selectedIdentities.value) {
            this.selectedIdentities.value.map(item => {
                mails += item.mail + ";"
            })
        }

        if (mails.endsWith(";")) {
            return mails.substring(0, mails.lastIndexOf(";"))
        }

        return mails;
    }
}

class IdentityPickerProviderExample implements IPeoplePickerProvider {
    private currentPersonas: IIdentity[] = [];

    private personas: IIdentity[] = this.getPersonList()

    private getPersonList() {
        let personList: IIdentity[] = []
        sonicReportUsers.map(item => {
            personList.push(this.getSampleEntity(item.name, item.userName));
        })

        return personList;
    }

    public onFilterIdentities(filter: string, selectedItems?: IIdentity[]) {
        return this.getPersonaList(filter, selectedItems);
    }

    public getEntityFromUniqueAttribute = (entityId: string): IIdentity => {
        return this.personas.filter((x) => x.entityId === entityId)[0];
    };

    public onRequestConnectionInformation = (
        entity: IIdentity,
        getDirectReports?: boolean
    ): IPersonaConnections => {
        return entity.entityId === "Julie Runkle"
            ? {
                directReports: getDirectReports ? this.personas : undefined,
                managers: [],
            }
            : {
                directReports: getDirectReports ? [] : undefined,
                managers: []
            };
    };

    public onEmptyInputFocus(): IIdentity[] {
        return []
    }

    private getSampleEntity(displayName: string, mail: string): IIdentity {
        return {
            displayName,
            entityId: displayName,
            entityType: "user",
            mail,
            originDirectory: "aad",
            originId: "",
        } as IIdentity;
    }

    // Simulate initial request delay when the user first tries to grab a list
    private getPersonaList(
        filter: string,
        selectedItems?: IIdentity[]
    ): Promise<IIdentity[]> | IIdentity[] {
        if (this.currentPersonas.length > 0) {
            return this.filterList(filter, selectedItems);
        } else {
            return new Promise<IIdentity[]>((resolve, reject) =>
                setTimeout(() => {
                    this.currentPersonas = this.personas;
                    resolve(this.filterList(filter, selectedItems));
                }, 800)
            );
        }
    }

    private filterList(filter: string, selectedItems?: IIdentity[]) {
        if (filter === "") {
            return this.onEmptyInputFocus();
        }
        return this.currentPersonas.filter(
            (x) =>
                (selectedItems === undefined || selectedItems.indexOf(x) === -1) &&
                ((x.displayName &&
                    x.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
                    (x.mail && x.mail.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
        );
    }
}