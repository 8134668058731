import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { returnType } from "../Utilities/ReturnType";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    Spinner
} from "@fluentui/react";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { Link } from "azure-devops-ui/Link";
import {
    CustomHeader,
    HeaderTitle,
    HeaderTitleArea,
    HeaderTitleRow
} from "azure-devops-ui/Header";
import { TitleSize } from "azure-devops-ui/Header";
import { Card } from "azure-devops-ui/Card";
import { Tree, ITreeColumn } from "azure-devops-ui/TreeEx";
import { ISimpleListCell } from "azure-devops-ui/List";
import { ColumnMore, ISimpleTableCell } from "azure-devops-ui/Table";
import { Dialog } from "azure-devops-ui/Dialog";
import { Observer } from "azure-devops-ui/Observer";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { renderExpandableTreeCell, renderTreeCell } from "azure-devops-ui/TreeEx";
import {
    ITreeItem,
    TreeItemProvider,
} from "azure-devops-ui/Utilities/TreeItemProvider";
import { ITreeItemProvider, ITreeItemEx } from "azure-devops-ui/Utilities/TreeItemProvider";
import { Tooltip } from "azure-devops-ui/TooltipEx";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";

import { Status, StatusSize } from "azure-devops-ui/Status";
import { css } from "azure-devops-ui/Util";
import { Ago } from "azure-devops-ui/Ago";
import { AgoFormat } from "azure-devops-ui/Utilities/Date";
import { Icon } from "azure-devops-ui/Icon";
import { Duration } from "azure-devops-ui/Duration";
import { IIconProps } from '@fluentui/react/lib/Icon';
import { formatDateTime,StringToArray } from '../Utilities/CommonFunction'
import { ISonicUserInfo } from '../Interface/IMsalAuth'
import MsacctAPI from "../Utilities/Loaders";
import { sonicSystemUsers } from '../../ConfigData/SystemUser'
import {
    IHeaderCommandBarItem,
    HeaderCommandBar,
    HeaderCommandBarWithFilter,
} from "azure-devops-ui/HeaderCommandBar";

import {
    getStatusIndicatorData
} from '../Utilities/DataListExtension'
import './CurationAll.css'

import { GlobalDataQuery } from '../Interface/IPowerQuery'

import {
    loadAllTaskList,
    onCurationDelete,
    clearAllTaskList,
    switchCurationStatus,
    runCuration
} from "../../redux/Actions/PowerDataQuery";

import RunTypeDialog from '../Modules/RunTypeDialog/RunTypeDialog'

const actionCreators = {
    loadAllTaskList,
    onCurationDelete,
    clearAllTaskList,
    switchCurationStatus,
    runCuration
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    allTaskList: state.allTaskList
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

/**
 * interface for delete/enable/disable
 */
interface IAllDialogState {
    showDialog: boolean,
    dialogType: "delete" | "enable" | "disable" | "other",
    actionCurationId: string,
    actionCurationName: string
}

/**
 * interface for run type
 */
interface IRunTypeDialogState extends IAllDialogState {
    selectedRunType: string,
    runTypeSubmitState: string,
}

// interface ILocationTableItem extends ISimpleTableCell {
interface ILocationTableItem {
    CurationId?: string;
    CurationConfigurationName?: string;
    CurationConfigurationNameDisplayName?: string;
    UpdatedBy?: string;
    RunId?: number;
    RunBuildNumber?: string;
    RunStatus?: string;
    RunResult?: string;
    RunUrl?: string;
    RunStartTime?: string,
    RunFinishTime?: string,
    UpdatedDate?: string;
    Status?: string;
    IsOwner?: boolean;
    ArticleCount?: string;
    SharedType?: string;
    SharedWith?: string;
    NextRun?: string;
    PipelineId?: number;
}

const dialogObservable = new ObservableValue<boolean>(false);
const runDialogObservable = new ObservableValue<boolean>(false);
const runTypeDialogObservable = new ObservableValue<boolean>(false);
let itemProvider: ITreeItemProvider<ILocationTableItem>;
function CurationAll(props: StoreProps & DispatchProps) {
    const { instance, accounts } = useMsal();
    const userAccount = useAccount(accounts[0]);
    const history = useNavigate();

    const commandBarItemsAdvanced: IHeaderCommandBarItem[] = [
        {
            iconProps: {
                iconName: "Add"
            },
            id: "testSave",
            important: true,
            onActivate: () => {
                history('/curations/create');
            },
            text: "New curation"
        }
    ]

    /**
    * navigate to curation jobs form
    * @param event 
    * @param CurationConfigurationName 
    */
    function navigateToCurationJobs(
        event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>,
        curationId: string,
        curationName: string) {
        history(`/curations/runs?curationId=${curationId}&curationName=${curationName}`)
        event.preventDefault()
    }

    /**
   * navigate to edit form
   * @param curationId 
   */
    function navigateToEditForm(curationId: string, curationName: string) {
        history(`/curations/edit?curationId=${curationId}&curationName=${curationName}`)
    }

    /**
  * navigate to detail form
  * @param event 
  * @param buildId 
  */
    function navigateToDetailForm(event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>, buildId: string) {
        history(`/runs/results?buildId=${buildId}`)
        event.preventDefault()
    }


    // set loading state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // set dialog state
    const [allDialogState, setAllDialogState] = useState<IAllDialogState>({
        showDialog: false,
        dialogType: "delete",
        actionCurationId: '',
        actionCurationName: ''
    })

    /**
     * run type state
     */
    const [runTypeDialogState, setRunTypeDialogState] = useState<IRunTypeDialogState>({
        showDialog: false,
        dialogType: "other",
        actionCurationId: "",
        actionCurationName: "",
        runTypeSubmitState: "",
        selectedRunType: "Incremental"
    })

    /**
     * user information
     */
    const [userInfoState, setUserInfoState] = useState<ISonicUserInfo>({
        name: '',
        userName: '',
        isSystemUser: false
    })

    /**
    * like componentDidMount
    */
    useEffect(() => {

        setRunTypeDialogState({
            showDialog: false,
            dialogType: "other",
            actionCurationId: '',
            actionCurationName: '',
            runTypeSubmitState: "",
            selectedRunType: "Incremental"
        })

        runDialogObservable.value = false

        // load user 
        let filterUser = sonicSystemUsers.filter(t => t.userName === userAccount.username)
        if (filterUser && filterUser.length) {
            setUserInfoState({
                name: userAccount.name,
                userName: userAccount.username,
                isSystemUser: true
            });
        }
        else {
            setUserInfoState({
                name: userAccount.name,
                userName: userAccount.username,
                isSystemUser: false
            });
        }

        const loadData = async () => {
            // turn on the loading
            setIsLoading(true)

            // call the api to get the list
            await props.loadAllTaskList();

            itemProvider = getItemProvider()

            // turn off the loading
            setIsLoading(false)
        }

        loadData()
    }, [])

    /**
     * like componentDidUpdate
     */
    useEffect(() => {
        itemProvider = getItemProvider()
    }, [props.allTaskList])

    /**
     * build tree item provider
     * @returns 
     */
    function getItemProvider(): ITreeItemProvider<ILocationTableItem> {
        const rootItems: Array<ITreeItem<ILocationTableItem>> = [];

        // add my curation
        let rootMyCuration: ILocationTableItem = {
            CurationConfigurationNameDisplayName: "My Curations"
        }

        let childMyCuration: Array<ITreeItem<ILocationTableItem>> = []

        props.allTaskList.filter(f => f.IsOwner === true && f.SharedType === "My Curations").map(item => {
            const taskName = item.Status.toString() === "Enabled" ?
                item.CurationConfigurationName
                : item.CurationConfigurationName + " (Disabled)"

            return childMyCuration.push({
                data: {
                    CurationId: item.id,
                    CurationConfigurationNameDisplayName: taskName,
                    CurationConfigurationName: item.CurationConfigurationName,
                    UpdatedBy: item.UpdatedBy ? item.UpdatedBy : item.CreatedBy,
                    RunId: item.LastRun?.Id,
                    RunBuildNumber: item.LastRun?.BuildNumber,
                    RunStatus: item.LastRun?.Status,
                    RunResult: item.LastRun?.Result,
                    RunUrl: item.LastRun?.Url,
                    RunStartTime: item.LastRun?.StartTime,
                    RunFinishTime: item.LastRun?.FinishTime,
                    UpdatedDate: formatDateTime(item.UpdatedDate ? item.UpdatedDate : item.CreatedDate),
                    Status: item.Status,
                    IsOwner: item.IsOwner,
                    ArticleCount: "  # of Articles: 123 (Scanned: 131 | Flagged: 6)",
                    SharedType: item.SharedType,
                    SharedWith: item.SharedWith,
                    NextRun: formatDateTime(item.NextRun),
                    PipelineId: item.PipelineId
                },
                expanded: true
            })
        })

        rootItems.push({
            childItems: childMyCuration,
            data: rootMyCuration,
            expanded: true

        })

        // add shared with item
        let rootSharedWith: ILocationTableItem = {
            CurationConfigurationNameDisplayName: "Shared Curations"
        }

        let childSharedWith: Array<ITreeItem<ILocationTableItem>> = []
        props.allTaskList.filter(f => f.SharedType === "Shared With" || f.SharedType === "Everyone").map(item => {
            const taskName = item.Status.toString() === "Enabled" ?
                item.CurationConfigurationName
                : item.CurationConfigurationName + " (Disabled)"

            return childSharedWith.push({
                data: {
                    CurationId: item.id,
                    CurationConfigurationNameDisplayName: taskName,
                    CurationConfigurationName: item.CurationConfigurationName,
                    UpdatedBy: item.UpdatedBy ? item.UpdatedBy : item.CreatedBy,
                    RunId: item.LastRun?.Id,
                    RunBuildNumber: item.LastRun?.BuildNumber,
                    RunStatus: item.LastRun?.Status,
                    RunResult: item.LastRun?.Result,
                    RunUrl: item.LastRun?.Url,
                    RunStartTime: item.LastRun?.StartTime,
                    RunFinishTime: item.LastRun?.FinishTime,
                    UpdatedDate: formatDateTime(item.UpdatedDate ? item.UpdatedDate : item.CreatedDate),
                    Status: item.Status,
                    IsOwner: item.IsOwner,
                    ArticleCount: "  # of Articles: 123 (Scanned: 131 | Flagged: 6)",
                    SharedType: item.SharedType,
                    SharedWith: item.SharedWith,
                    NextRun: formatDateTime(item.NextRun),
                    PipelineId: item.PipelineId
                },
                expanded: true
            })
        })

        rootItems.push({
            childItems: childSharedWith,
            data: rootSharedWith,
            expanded: true
        })

        // add system item
        let systemList = props.allTaskList.filter(f => f.SharedType === "System");
        if (systemList && systemList.length > 0 && userInfoState.isSystemUser) {
            let rootSystem: ILocationTableItem = {
                CurationConfigurationNameDisplayName: "System"
            }

            let childSystem: Array<ITreeItem<ILocationTableItem>> = []
            systemList.map(item => {
                const taskName = item.Status.toString() === "Enabled" ?
                    item.CurationConfigurationName
                    : item.CurationConfigurationName + " (Disabled)"

                return childSystem.push({
                    data: {
                        CurationId: item.id,
                        CurationConfigurationNameDisplayName: taskName,
                        CurationConfigurationName: item.CurationConfigurationName,
                        UpdatedBy: item.UpdatedBy ? item.UpdatedBy : item.CreatedBy,
                        RunId: item.LastRun?.Id,
                        RunBuildNumber: item.LastRun?.BuildNumber,
                        RunStatus: item.LastRun?.Status,
                        RunResult: item.LastRun?.Result,
                        RunUrl: item.LastRun?.Url,
                        RunStartTime: item.LastRun?.StartTime,
                        RunFinishTime: item.LastRun?.FinishTime,
                        UpdatedDate: formatDateTime(item.UpdatedDate ? item.UpdatedDate : item.CreatedDate),
                        Status: item.Status,
                        IsOwner: item.IsOwner,
                        ArticleCount: "  # of Articles: 123 (Scanned: 131 | Flagged: 6)",
                        SharedType: item.SharedType,
                        SharedWith: item.SharedWith,
                        NextRun: formatDateTime(item.NextRun),
                        PipelineId: item.PipelineId
                    },
                    expanded: true
                })
            })

            rootItems.push({
                childItems: childSystem,
                data: rootSystem,
                expanded: true
            })
        }

        return new TreeItemProvider<ILocationTableItem>(rootItems);
    }

    /**
     * Check is shared with user
     * @param sharedWith 
     * @returns 
     */
    function isSharedWithUser(sharedWith:string) {
        let sharedWiths = StringToArray(sharedWith)
        if (sharedWiths && sharedWiths.length > 0) {
            let filterUser = sharedWiths.filter(f => f === userInfoState.userName)
            if (filterUser && filterUser.length > 0) {
                return true
            }
        }

        return false
    }

    /**
     * build tree columns
     * @returns 
     */
    function getTreeColumns(): any {
        const nameColumn = {
            id: "CurationConfigurationNameDisplayName",
            name: "Name",
            renderCell: renderExpandableTreeCell,
            width: -200,
        };

        const viewRunsColumn = {
            id: "CurationConfigurationNameDisplayName",
            name: "All runs",
            renderCell: renderAllRuns,
            width: -80,
        };

        const lastRunColumn = {
            id: "RunId",
            minWidth: 200,
            name: "Last run",
            renderCell: renderLastRunColumn,
            width: -100,
        };

        const nextRunColumn = {
            id: "NextRun",
            minWidth: 200,
            name: "Next run",
            renderCell: renderTreeCell,
            width: -150,
        };

        const updatedDateTimeColumn = {
            id: "UpdatedDate",
            minWidth: 200,
            name: "Modified date",
            renderCell: renderTreeCell,
            width: -150,
        };

        const lastModifyColumn = {
            id: "UpdatedBy",
            name: "Last modified by",
            renderCell: renderTreeCell,
            width: -150,
        };

        const moreColumn = new ColumnMore((currentItem: ITreeItemEx<ILocationTableItem>) => {
            // if the expandable item
            if (currentItem.depth === 0) {
                return {
                    id: "sub-menu",
                    items: [
                        { id: "edit", text: "Edit", disabled: true }
                    ],
                };
            }

            const curationId = currentItem.underlyingItem.data.CurationId
            const curationConfigurationName = currentItem.underlyingItem.data.CurationConfigurationName
            const status = currentItem.underlyingItem.data.Status
            const isOwner = currentItem.underlyingItem.data.IsOwner
            const sharedType = currentItem.underlyingItem.data.SharedType
            const sharedWith = currentItem.underlyingItem.data.SharedWith
            const pipelineId = currentItem.underlyingItem.data.PipelineId
            const statusText = status === "Enabled" ? "Disable" : "Enable";

            let editDisable = false
            let allDisable = false
            // case 1: if the status is 'disabled', can not edit the item
            if (status !== "Enabled") {
                editDisable = true
            }

            // case 2: if not the item's owner, disable the action
            let isSharedWith = isSharedWithUser(sharedWith)            
            if (!isOwner && sharedType !== "Everyone" && !isSharedWith) {
                editDisable = true
            }

            if (!isOwner) {
                allDisable = true
            }

            // case 3: if the SharedType = "System", and the user is system user
            if (sharedType === "System" && userInfoState.isSystemUser) {
                if (status !== "Enabled") {
                    editDisable = true
                    allDisable = false
                }
                else {
                    editDisable = false
                    allDisable = false
                }
            }

            // case 4:
            if (pipelineId === undefined || pipelineId === null || pipelineId === -1) {
                allDisable = true
            }

            let runDisable = true;
            if (!editDisable && pipelineId && pipelineId !== -1) {
                runDisable = false
            }

            return {
                id: "sub-menu",
                items: [
                    { id: "edit", disabled: editDisable, text: "Edit", onActivate: () => { navigateToEditForm(curationId, curationConfigurationName) } },
                    { id: "run", disabled: runDisable, text: "Run Curation", onActivate: () => { handleRunTypeDialogShow(curationId, curationConfigurationName, '') } },
                    {
                        id: "delete", text: "Delete", disabled: allDisable, onActivate: () => {
                            setAllDialogState({
                                ...allDialogState,
                                showDialog: true,
                                dialogType: "delete",
                                actionCurationId: curationId,
                                actionCurationName: curationConfigurationName
                            })
                        }
                    },
                    {
                        id: "disable", text: statusText, disabled: allDisable, onActivate: (disStatus) => {
                            if (disStatus.text === "Disable") {
                                setAllDialogState({
                                    ...allDialogState,
                                    showDialog: true,
                                    dialogType: "disable",
                                    actionCurationId: curationId,
                                    actionCurationName: curationConfigurationName
                                })
                            }
                            else {
                                setAllDialogState({
                                    ...allDialogState,
                                    showDialog: true,
                                    dialogType: "enable",
                                    actionCurationId: curationId,
                                    actionCurationName: curationConfigurationName
                                })
                            }

                        }
                    },
                ],
            };

        });

        return [nameColumn, viewRunsColumn, lastRunColumn, nextRunColumn, updatedDateTimeColumn, lastModifyColumn, moreColumn];
    }

    /**
   * 
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
    function renderLastRunColumn(
        rowIndex: number,
        columnIndex: number,
        treeColumn: ITreeColumn<ILocationTableItem>,
        treeItem: ITreeItemEx<ILocationTableItem>
    ) {

        // if the expandable item
        if (treeItem.depth === 0) {
            return (
                <td className='bolt-table-cell bolt-list-cell'></td>
            )
        }

        const runUlr = treeItem.underlyingItem.data.RunUrl
        const runId = treeItem.underlyingItem.data.RunId
        const runResult = treeItem.underlyingItem.data.RunResult
        const runStatus = treeItem.underlyingItem.data.RunStatus
        const runStartTime = treeItem.underlyingItem.data.RunStartTime
        const runFinishTime = treeItem.underlyingItem.data.RunFinishTime
        const articleCount = treeItem.underlyingItem.data.ArticleCount
        const text = "Job ID: #" + runId

        return (
            <td className='bolt-table-cell bolt-list-cell'>
                {
                    runId && runId.toString() !== '' ?
                        (
                            <>
                                <span className="flex-row scroll-hidden">
                                    <Tooltip text={text} overflowOnly>
                                        <Link
                                            className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                                            excludeTabStop
                                            href={runUlr}
                                            onClick={(event) => navigateToDetailForm(event, runId.toString())}
                                        >
                                            <Status
                                                {...getStatusIndicatorData(runResult).statusProps}
                                                className="icon-large-margin task-datalist-icon"
                                                size={StatusSize.s}
                                            />
                                            {text}
                                        </Link>
                                    </Tooltip>
                                </span>
                                <span className="flex-row scroll-hidden dataFormatter">
                                    {
                                        WithIcon({
                                            className: "fontSize font-size",
                                            iconProps: { iconName: "Calendar" },
                                            children: (
                                                <Ago date={new Date(runFinishTime)!} /*format={AgoFormat.Extended}*/ />
                                            )
                                        })
                                    }
                                    {
                                        WithIcon({
                                            className: "fontSize font-size bolt-table-two-line-cell-item msacct-curationall-clock",
                                            iconProps: { iconName: "Clock" },
                                            children: (
                                                <Duration
                                                    startDate={new Date(runStartTime)!}
                                                    endDate={new Date(runFinishTime)}
                                                />
                                            )
                                        })
                                    }

                                </span>
                                {/* <span className="flex-row scroll-hidden">
                                    {articleCount}
                                </span> */}

                            </>
                        )
                        :
                        (
                            <>
                                <Status
                                    {...getStatusIndicatorData("Queued").statusProps}
                                    className="icon-large-margin task-datalist-icon"
                                    size={StatusSize.s}
                                />
                                <span>
                                    No runs yet
                                </span>
                            </>

                        )
                }
            </td >
        )
    }

    /**
     * build all runs
     * @param rowIndex 
     * @param columnIndex 
     * @param treeColumn 
     * @param treeItem 
     * @returns 
     */
    function renderAllRuns(
        rowIndex: number,
        columnIndex: number,
        treeColumn: ITreeColumn<ILocationTableItem>,
        treeItem: ITreeItemEx<ILocationTableItem>
    ) {
        // if the expandable item
        if (treeItem.depth === 0) {
            return (
                <td className='bolt-table-cell bolt-list-cell'></td>
            )
        }

        const curationName = treeItem.underlyingItem.data.CurationConfigurationName
        const curationId = treeItem.underlyingItem.data.CurationId
        const runId = treeItem.underlyingItem.data.RunId

        return (
            <td className='bolt-table-cell bolt-list-cell'>
                {
                    runId && runId.toString() !== '' ?
                        <Link
                            className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                            excludeTabStop
                            href={"#"}
                            onClick={(event) => navigateToCurationJobs(event, curationId, curationName)}
                        >
                            {"View runs"}
                        </Link>
                        :
                        <span></span>
                }

            </td>
        )
    }

    function WithIcon(props: {
        className?: string;
        iconProps: IIconProps;
        children?: React.ReactNode;
    }) {
        return (
            <div className={css(props.className, "flex-row flex-center")}>
                {Icon({ ...props.iconProps, className: "icon-margin" })}
                {props.children}
            </div>
        );
    }

    /**
    * build the conform dialog text
    * @param taskName 
    * @returns dialog text
    */
    function buildDialogText(): JSX.Element {
        const { dialogType, actionCurationName } = allDialogState

        switch (dialogType) {
            case "delete":
                return (
                    <div>
                        The curation <b>{actionCurationName}</b> will be deleted permanently, click confirm to proceed.
                    </div>
                )
            case "disable":
                return (
                    <div>
                        The curation <b>{actionCurationName}</b> will be disabled, click confirm to proceed.
                    </div>
                )
            case "enable":
                return (
                    <div>
                        The curation <b>{actionCurationName}</b> will be enabled, click confirm to proceed.
                    </div>
                )
            default:
                return (
                    <div>
                        The curation <b>{actionCurationName}</b> will be deleted permanently, click confirm to proceed.
                    </div>
                )
        }
    }

    /**
     * build run type complete message
     * @returns 
     */
    function buildRunDialogText() {
        const { actionCurationName } = runTypeDialogState

        return (
            <div>
                Curation <b>{actionCurationName}</b> has been running, it may take a few minutes, you can refresh the page later to check the running status.
            </div>
        )
    }

    /**
   * build the dialog title
   * @returns 
   */
    function buildDialogTitle() {
        const { dialogType } = allDialogState

        switch (dialogType) {
            case "delete":
                return "Delete curation";
            case "disable":
                return "Disable curation";
            case "enable":
                return "Enable curation";
            default:
                return "Delete curation";
        }
    }

    /**
   * click confirm of dialog form
   */
    async function handleDilagConfirm() {
        const { dialogType, actionCurationId } = allDialogState

        setAllDialogState({
            ...allDialogState,
            showDialog: false
        })

        setIsLoading(true)

        switch (dialogType) {
            case "delete":
                await props.onCurationDelete(actionCurationId)
                break;
            case "disable":
                await props.switchCurationStatus("Disable", actionCurationId)
                break;
            case "enable":
                await props.switchCurationStatus("Enable", actionCurationId)
                break;
            default:
                break
        }

        await props.loadAllTaskList()

        setIsLoading(false)
    }

    /**
   * click cancel of dialog form
   */
    function handleDialogeDismiss() {
        dialogObservable.value = false;

        setAllDialogState({
            ...allDialogState,
            showDialog: false
        })
    };

    /**
  * click cancel of dialog form
  */
    function handleRunDialogeDismiss() {
        runDialogObservable.value = false;

        setRunTypeDialogState({
            ...runTypeDialogState,
            showDialog: false
        })
    };

    /**
   * 
   * @param curationId 
   * @param curationName 
   */
    function handleRunTypeDialogShow(curationId: string, curationName: string, selectedRunType: string) {

        setRunTypeDialogState({
            ...runTypeDialogState,
            showDialog: false,
            actionCurationId: curationId,
            actionCurationName: curationName,
            selectedRunType: selectedRunType
        })

        runTypeDialogObservable.value = true
        runDialogObservable.value = false
    }

    /**
     * handle run type dialog dismiss
     */
    function handleRunTypeDialogDismiss() {
        runTypeDialogObservable.value = false
    }

    function onRunTypeSubmit(data: any) {

        if (data !== null && data !== undefined && data.submitState !== '') {
            setRunTypeDialogState({
                ...runTypeDialogState,
                showDialog: true,
                actionCurationId: data.actionCurationId,
                actionCurationName: data.actionCurationName,
            })
        }
    }

    return (

        <Surface background={SurfaceBackground.neutral}>
            <Page className="flex-grow">
                <CustomHeader className="bolt-header-with-commandbar">
                    <HeaderTitleArea>
                        <HeaderTitleRow>
                            <HeaderTitle ariaLevel={3} className="text-ellipsis" titleSize={TitleSize.Medium}>
                                Curation Configurations
                            </HeaderTitle>
                        </HeaderTitleRow>
                    </HeaderTitleArea>
                    <HeaderCommandBar items={commandBarItemsAdvanced} />
                </CustomHeader>

                <div className="page-content-left page-content-right page-content-top page-content-bottom" style={{ "paddingBottom": "80px" }}>
                    <Card
                        className="flex-grow bolt-card-no-vertical-padding bolt-table-card"
                        contentProps={{ contentPadding: false }}
                    >
                        {
                            isLoading ?
                                (
                                    <Spinner className='msccat-page-loading' label="Loading" />
                                )
                                :
                                (
                                    itemProvider ?
                                        (
                                            <Tree<ILocationTableItem>
                                                ariaLabel="Basic tree"
                                                columns={getTreeColumns()}
                                                itemProvider={itemProvider}
                                                onToggle={(event, treeItem: ITreeItemEx<ILocationTableItem>) => {
                                                    itemProvider.toggle(treeItem.underlyingItem);
                                                }}
                                                scrollable={true}
                                                containerClassName="msacct-devops-table"
                                            />
                                        )
                                        :
                                        (null)
                                )

                        }

                        {/* delete/enable/disable task */}
                        <Observer isDialogOpen={dialogObservable}>
                            {
                                () => {
                                    return allDialogState.showDialog ?
                                        (
                                            <Dialog
                                                titleProps={{ text: buildDialogTitle() }}
                                                footerButtonProps={[
                                                    {
                                                        text: "Cancel",
                                                        onClick: handleDialogeDismiss
                                                    },
                                                    {
                                                        text: "Confirm",
                                                        onClick: handleDilagConfirm,
                                                        primary: true
                                                    }
                                                ]}
                                                onDismiss={handleDialogeDismiss}
                                            >
                                                {buildDialogText()}
                                            </Dialog>
                                        )
                                        : null
                                }
                            }
                        </Observer>

                        {/* run curation */}
                        <Observer isDialogOpen={runDialogObservable}>
                            {
                                () => {
                                    return runTypeDialogState.showDialog ?
                                        (
                                            <Dialog
                                                titleProps={{ text: "" }}
                                                footerButtonProps={[
                                                    {
                                                        text: "OK",
                                                        primary: true,
                                                        onClick: handleRunDialogeDismiss
                                                    },
                                                ]}
                                                onDismiss={handleRunDialogeDismiss}
                                            >
                                                {buildRunDialogText()}
                                            </Dialog>
                                        )
                                        : null
                                }
                            }
                        </Observer>

                        {/* run type form */}
                        <Observer isDialogOpen={runTypeDialogObservable} >
                            {(runTypeProps: { isDialogOpen: boolean }) => {
                                return runTypeProps.isDialogOpen ? (
                                    <RunTypeDialog
                                        actionCurationId={runTypeDialogState.actionCurationId}
                                        actionCurationName={runTypeDialogState.actionCurationName}
                                        curationRunType={runTypeDialogState.selectedRunType}
                                        onRunTypeSubmit={onRunTypeSubmit}
                                        onRunTypeDialogDimiss={handleRunTypeDialogDismiss} />

                                ) : null;
                            }}
                        </Observer>
                    </Card>
                </div>
            </Page>
        </Surface>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(CurationAll);
