import {ISonicUserInfo} from '../components/Interface/IMsalAuth'

export const sonicSystemUsers: Array<ISonicUserInfo> = [          
    { name: "Jinping Zheng", userName: "v-jinzhe@microsoft.com" },
    { name: "Dale Chen", userName: "daleche@microsoft.com" },          
    { name: "Ying Huang", userName: "v-yinghuang@microsoft.com" },
    { name: "Young Yang", userName: "v-yaya13@microsoft.com" },
    { name: "William Chen", userName: "willchen@microsoft.com" },
    { name: "Tony Cheung", userName: "tcheung@microsoft.com" },
    { name: "Mingyu Tan", userName: "v-mingyutan@microsoft.com" },
    { name: "Xiaojun Hu", userName: "v-xiaojunhu@microsoft.com" }
];