import React, { Component, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { returnType } from "../Utilities/ReturnType";
import { connect } from "react-redux";

import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { FormItem } from "azure-devops-ui/FormItem";
import {
  RadioButton,
  RadioButtonGroup,
  RadioButtonGroupDirection,
} from "azure-devops-ui/RadioButton";
import { DropdownSelection } from "azure-devops-ui/Utilities/DropdownSelection";
import { TextField, TextFieldWidth } from "azure-devops-ui/TextField";
import { Checkbox } from "azure-devops-ui/Checkbox";
import { Button } from "azure-devops-ui/Button";
import { Card } from "azure-devops-ui/Card";
import { Observer } from "azure-devops-ui/Observer";
import { Dropdown } from "azure-devops-ui/Dropdown";
import { IListBoxItem } from "azure-devops-ui/ListBox";
import { Link } from "azure-devops-ui/Link";
import { getWorkItemPatternValue } from '../Utilities/CommonFunction'
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { Status, Statuses, StatusSize } from "azure-devops-ui/Status";

import {
  CustomHeader,
  HeaderTitle,
  HeaderTitleArea,
  HeaderTitleRow,
  TitleSize,
} from "azure-devops-ui/Header";

import { Pivot, PivotItem } from '@fluentui/react';
import { useMsal, useAccount } from "@azure/msal-react";
import { Spinner as AzureSpinner, SpinnerSize } from "azure-devops-ui/Spinner";

import ConfirmationNavigateDialog from "../Dialogs/ConfirmationNavigateDialog/ConfirmationNavigateDialog";
import CronBuilder from "../Controls/CronBuilder/CronBuilder";
import DataQueryBuilder from "../Modules/DataQueryBuilder/DataQueryBuilder";
import ExemptionEditorDialog from "../Modules/CurationExemptions/ExemptionEditor";
import { SingleDropdown } from "../Controls/DropdownControl/DropdownControls";
import { ticketTypeDropdownItems } from "../../ConfigData/Curation.Actions";
import MultipleIdentityPickerExtension from '../Controls/IdentityPickerExtension/MultipleIdentityPickerExtension'
import { Fix404HyperlinksADOTicketSettingDev, Fix404HyperlinksADOTicketSettingProd } from '../../ConfigData/Curation.Actions.FixIt.ADOTicketSetting'
import { GlobalDataQuery, DataQueryIndex } from "../Interface/IPowerQuery";
import { TriggerSettings, ITicketEvent, ITextSearchSettings } from "../Interface/ITask";
import { IQueryExpressions } from "../Interface/IQueryExpressions";
import { ITagItem } from "../Interface/ITag";
import { sonicSystemUsers } from '../../ConfigData/SystemUser'
import ErrorDialog from '../Dialogs/ErrorDialog/ErrorDialog'
import { ITicketADO, IOtherField } from '../Interface/ITicketADO'
import DataQueryCommandBar from '../Modules/DataQueryCommandBar/DataQueryCommandBar'
import QueryPreveiw from "./QueryPreveiw/QueryPreveiw";
import { CommonConstants } from '../Utilities/CommonConstants'
import { parseIntFromString } from '../Utilities/CommonFunction'
import { RemediateTicketPattern, LMCDataSetTicketPattern, PoliCheckIssuePattern, TextReplaceTicketPattern } from '../../ConfigData/Curation.Actions.FixIt.ADOTicketSetting'
import { ITicketADODropdownItem, ITicketAssignToFields } from '../Interface/ITicketADO'
import { CurationFilter } from '../Interface/ITask'

import {
  clearApiResponseStatusAction,
  loadDataQueryIndexes,
  loadMetadataFields,
  selectIndex,
  intTaskCreate,
  onTaskSubmit,
  getCurationTags,
  getAllCurationName,
  clearSearchResults,
  getTicketDevOpsConfig,
  getDataSourceList
} from "../../redux/Actions/PowerDataQuery";

import {
  QueryExpression,
  getExpression,
  removeExpression,
  FieldOptions,
  generateSearchStrings,
  removeInvalidExpressions,
  hasValidExpression,
} from "../Utilities/QueryBuilderUtils";

import {
  initExprTree,
  setExpression,
  appendNewExpression,
  insertNewExpression,
  ungroupExpressions,
  groupSelectedExpressions,
  getGroupingHoverText,
} from "../Utilities/ExpressionTreeUtils";

import {
  MetadataQueryOperator,
  groupHoverText_notEnough,
  isSetOperator,
  isNotSetOperator,
  defaultFieldOperators,
  defaultFieldValues,
  SPECIAL_SEARCH_FIELDS
} from "../Utilities/QueryBuilderConstants";

import {
  getCurationQueryFields,
  setCurationFieldsDefault
} from "../Utilities/QueryBuilderExtension";

import TicketADO from './TicketADO/TicketADO'
import FixIt from '../Modules/Actions/FixIt'
import { IRemediateIt, RemediateTypes, FixHyperLinksTypes, MergePullRequestTypes } from "../Interface/IActionFixIt";

import "./TaskCreate.css";

const actionCreators = {
  clearApiResponseStatusAction,
  loadDataQueryIndexes,
  loadMetadataFields,
  selectIndex,
  intTaskCreate,
  onTaskSubmit,
  getCurationTags,
  getAllCurationName,
  clearSearchResults,
  getTicketDevOpsConfig,
  getDataSourceList
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
  apiResponseState: state.apiResponseState,
  taskCreate: state.taskCreate,
  indexList: state.indexList,
  index: state.index,
  curationTags: state.curationTags,
  allCurationName: state.allCurationName,
  dataSourceList: state.dataSourceList,
  metadataFields:
    state.metadataFields &&
    state.index &&
    state.index.metadataSchema &&
    state.metadataFields[state.index.metadataSchema],
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

/** task count items */
const CurationFilterTopNItems = [
  {
    id: "1",
    text: "1"
  },
  {
    id: "5",
    text: "5"
  },
  {
    id: "10",
    text: "10"
  },
  {
    id: "20",
    text: "20"
  },
  {
    id: "50",
    text: "50"
  },
  {
    id: "100",
    text: "100"
  },
  {
    id: "200",
    text: "200"
  },
  {
    id: "500",
    text: "500"
  },
  {
    id: "999999",
    text: "999999"
  }
]

interface MetaDataQueryState {
  queryExpressions: QueryExpression;
  groupHoverText: string;
}

interface ITaskButtonStatusState {
  disableGeneralNextButton: boolean;
  disableSubmitButton: boolean;
}

interface ITriggerState {
  triggerSettings: TriggerSettings;
}

interface IReportToState {
  isReportTo: boolean;
  reportTo: string;
}

interface ITicketState {
  isTicket: boolean;
  ticketTypeDisabled: boolean;
  isSendIcrementally: boolean;
  ticketTypeId: string;
}

interface INoCreateTicketState {
  ShowNoCreateTicketError: boolean;
  NoCreateTicketMessage: string;
}

// Shared type DropdownList
let shareTypeSelection = new DropdownSelection();
let sharedTypeDropdown = new ObservableValue<string>("My Curations");

// Text Search DropdownList
let textDataSourceSelection = new DropdownSelection();
let textDataSourceDropdown = new ObservableValue<string>("");

// Curation Filter TopN DropdownList
let curationFilterTopNSelection = new DropdownSelection()

// Curation Filter Order by DropdownList
let curtionFilterOrderBySelection = new DropdownSelection()

// selected ticket system type
let ticketSystemSelection = new DropdownSelection();

// Curation area RadioButton
let curationAreaRadioButton = new ObservableValue<string>("empty");

// Run type RadioButton
let runTypeRadioButton = new ObservableValue<string>("Incremental");

// Curation configuration name TextBox
let curationNameObservable: ObservableValue<string> = new ObservableValue("");

// Description TextBox
let descriptionObservable: ObservableValue<string> = new ObservableValue("");

// PoliCheck TextBox
let poliCheckObservable: ObservableValue<string> = new ObservableValue<string>("PoliCheckTermData");

// Report it RadioButton
let isReportToObservable: ObservableValue<boolean> = new ObservableValue(false);

// Create a Ticket RadioButton
let isTicketObservable: ObservableValue<boolean> = new ObservableValue(false);

// Create a Ticket checkbox
let isSendIcrementally: ObservableValue<boolean> = new ObservableValue(true);

// Show ticket ADO dialog form
let isTicketADODialogOpen = new ObservableValue<boolean>(false);

// Show query preview
let isQueryPreviewDialogOpen = new ObservableValue<boolean>(false);

let expList: IQueryExpressions[] = [];
let expListForExemption: IQueryExpressions[] = [];
let filterMetaHasBeenLoad = false
let _sharedTypeItems: any[] = []

const sonci_Env = process.env.REACT_APP_SONIC_ENV

function TaskCreate(props: StoreProps & DispatchProps) {
  const history = useNavigate();

  const [remediateItState, setRemediateItState] = useState<IRemediateIt>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /** Get template params from Welcome page */
  const [search, setSearch] = useSearchParams();
  const templateName = search.get('templateName')

  /** Import msal-react for getting login user information */
  const { instance, accounts } = useMsal();
  const userAccount = useAccount(accounts[0]);
  /** Initialize tab */
  const [selectedKey, setSelectedKey] = useState(0);

  /** Initialize querybuilder state */
  const [currentQueryState, setCurrentQueryState] = useState<MetaDataQueryState>({
    groupHoverText: groupHoverText_notEnough,
    queryExpressions: initExprTree()
  })

  /** Initialize base state */
  const [currentCreateState, setCurrentCreateState] = useState<ITaskButtonStatusState>({
    disableGeneralNextButton: true,
    disableSubmitButton: false
  })

  /** Initialize base state */
  const [triggerState, setTriggerState] = useState<ITriggerState>({
    triggerSettings: {
      frequency: 1,
      RecurIntervals: 6,
      RecurDaysOfWeek: [],
      RecurDaysOfMonth: []
    }
  })

  /** Initialize report to state */
  const [reportToState, setReportToState] = useState<IReportToState>({
    isReportTo: false,
    reportTo: ''
  })

  /** Initialize shared with state */
  const [sharedWidthState, setSharedWithState] = useState<string>('')

  /** Initialize ticket ADO */
  const [ticketADOState, setTicketADOState] = useState<ITicketADO>()

  /** Initialize ticket state */
  const [ticketState, setTicketState] = useState<ITicketState>({
    isTicket: false,
    ticketTypeDisabled: true,
    isSendIcrementally: true,
    ticketTypeId: '',
  })

  /** Initialize curation configuration name error state */
  const [taskErrorState, setTaskErrorState] = useState({
    showTaskNameError: false,
    taskNameErrorMessage: ""
  })

  /** Set query filter for preview dialog page */
  const [queryFilterString, setQueryFilterString] = useState('');

  /** Set algorithem */
  const [algorithemType, setAlgorithemType] = useState('Blank');

  /** Set datasource items*/
  const [dataSourceListState, setDataSourceListState] = useState<any[]>([])

  const [curationFilterOrderByItems, setCurationFilterOrderByItems] = useState<ITicketADODropdownItem[]>([])

  const [curationFilter, setCurationFilter] = useState<CurationFilter>({})

  const [noCreateTicketState, setNoCreateTicketState] = useState<INoCreateTicketState>({
    ShowNoCreateTicketError: false,
    NoCreateTicketMessage: ""
  })

  const [indexName, setIndexName] = useState<string>('')

  /**
   * 
   */
  useEffect(() => {

    // Set the default tab
    setSelectedKey(0)

    // Set the default error message of curation name
    setTaskErrorState({
      showTaskNameError: false,
      taskNameErrorMessage: "",
    });

    // Set the default value of Shared Type
    shareTypeSelection.select(0)
    sharedTypeDropdown.value = "My Curations"

    // Set the default value of Text Search DataSource
    textDataSourceSelection.clear()
    textDataSourceDropdown.value = "";

    // Set the default value of PoliCheck TextBox
    poliCheckObservable.value = "PoliCheckTermData"

    // Set the default value of Report it
    isReportToObservable.value = false

    // Set the default value of ticket
    isTicketObservable.value = false
    setTicketState({
      isTicket: false,
      ticketTypeDisabled: true,
      isSendIcrementally: true,
      ticketTypeId: ''
    })

    // Set the default expression
    expList = []

    // Set the QueryBuilder state
    if (!templateName) {
      setCurrentQueryState({
        groupHoverText: groupHoverText_notEnough,
        queryExpressions: initExprTree()
      })
    }

    loadTemplate()

    // Call api to get data
    const loadInitData = async () => {
      await props.getCurationTags();
      await props.loadDataQueryIndexes();
      await props.getAllCurationName();
      await props.getTicketDevOpsConfig();
      await props.getDataSourceList('TextSearch');
    }

    loadInitData()

    if (props.indexList && props.indexList.length) {
      filterMetaHasBeenLoad = true
      initPageState();

      // Set QueryBuilder by template
      initQueryExpression();
    }

    // Check the use is System
    let filterUser = sonicSystemUsers.filter(t => t.userName === userAccount.username)
    if (filterUser && filterUser.length) {
      _sharedTypeItems = [
        { id: "MyCurations", text: "My Curations" },
        { id: "SharedWith", text: "Shared With" },
        { id: "Everyone", text: "Everyone" },
        { id: "System", text: "System" }
      ]
    }
    else {
      _sharedTypeItems = [
        { id: "MyCurations", text: "My Curations" },
        { id: "SharedWith", text: "Shared With" },
        { id: "Everyone", text: "Everyone" }
      ]
    }

    setNoCreateTicketState({
      ShowNoCreateTicketError: false,
      NoCreateTicketMessage: ""
    })

    return () => {
      // Jump from the welcome page to "Create Curation" page, then click "New Curation" to jump to "Create Curation", you need to clear query builder items
      if (templateName === "broken") {
        for (let i = 1; i >= 0; i--) {
          removeExpression2(i)
        }
      }
      else if (templateName === "curation" || templateName === "product") {
        for (let i = 2; i >= 0; i--) {
          removeExpression2(i)
        }
      }
      else {
        for (let i = 2; i >= 0; i--) {
          removeExpression2(i)
        }
      }
    }

  }, [templateName])

  /**
   * 
   */
  useEffect(() => {
    if (!filterMetaHasBeenLoad && props.indexList && props.indexList.length) {
      filterMetaHasBeenLoad = true
      initPageState();

      initQueryExpression();
    }

  }, [props.indexList])

  /**
 * 
 */
  useEffect(() => {
    setCurationFilterOrderByItems(initializeOrderByItems());
    curationFilterTopNSelection.clear();
    curtionFilterOrderBySelection.clear();
    setCurationFilter({
      ...curationFilter,
      Top: 999999,
      OrderBy: ""
    })
  }, [props.metadataFields])

  /**
   * set datasource items
   */
  useEffect(() => {
    let dataSourceItems: any[] = [];

    if (props.dataSourceList && props.dataSourceList.length) {
      props.dataSourceList.map((item: any) => {
        if (item.Type === "TextSearch") {
          dataSourceItems.push({
            id: item.Name,
            text: item.Name
          })
        }
      })
    }

    setDataSourceListState(dataSourceItems)

  }, [props.dataSourceList])

  /**
     * initialize order by dropdown list
     * @returns 
     */
  function initializeOrderByItems() {
    let orderByItems: ITicketADODropdownItem[] = []
    if (props.metadataFields && props.metadataFields.length > 0) {
      props.metadataFields.map(item => {
        orderByItems.push({
          id: item.FieldName,
          text: item.DisplayName
        })
      })
    }

    return orderByItems
  }

  const getResetPattern = () => {
    if(props.index?.metadataSchema.startsWith("gitpub-metadata-schema-lmc-") && algorithemType === "Blank") {
        return LMCDataSetTicketPattern
    } 
    if(algorithemType === "PoliCheckCrawler") {
        return PoliCheckIssuePattern
    }
    if(props.index?.metadataSchema.startsWith("gitpub-metadata-schema-evergreen-") && ["TextCrawler", "TextFinderAndReplacer"].includes(algorithemType)) {
        return TextReplaceTicketPattern
    }
    return RemediateTicketPattern
}

  /**
   * Handle the "Remediate It" click event.
   * @param data - Data object containing "RemediateItOrNot" property.
   */
  function handleRemediateItClick(data: IRemediateIt) {
    // Check if "Remediate It" is enabled.
    if (data.RemediateItOrNot) {
      // Enable ticket observable.
      isTicketObservable.value = true;

      // Update ticket state with relevant settings.
      setTicketState({
        ...ticketState,
        ticketTypeDisabled: false,
        isTicket: true,
        ticketTypeId: sonci_Env === "prod" ? "RecommendedCreateTasks" : "CreateTasks",
      });

      // Check and set ticket ADO state if not already initialized.
      if (ticketADOState === undefined || !ticketADOState.Organization || !ticketADOState.Project) {
        // Prepare the default ticket setting based on environment.
        let ticketSetting = sonci_Env === "prod" ? Fix404HyperlinksADOTicketSettingProd : Fix404HyperlinksADOTicketSettingDev;
        ticketSetting.AssignTo = userAccount.username;
        ticketSetting.ChildTaskAssignTo = userAccount.username;
        const resetPattern = getResetPattern();
        ticketSetting.WorkItemTitlePattern = resetPattern.Title;
        ticketSetting.WorkItemDescriptionPattern = resetPattern.Description;
        setTicketADOState(ticketSetting);
      }
    }
  }

  useEffect(()=>{
    const resetPattern = getResetPattern();
    setTicketADOState({
        ...ticketADOState,
        WorkItemTitlePattern: resetPattern.Title,
        WorkItemDescriptionPattern: resetPattern.Description
    })
},[props.index?.metadataSchema,algorithemType])

  /**
   * Handle the state of Remediate It
   * @param data 
   */
  function handleRemediateItStateChanged(data: IRemediateIt) {
    setRemediateItState(data);
  }

  /**
   * Set default value according to the selection of welcome page
   */
  function loadTemplate() {

    // Default Value of "broken"
    let disableGeneralNextButton = false
    let frequency = 1
    let RecurIntervals = 12
    let RecurDaysOfWeek = []
    let RecurDaysOfMonth = []
    let RecurHour = 10
    let RecurMinute = 30
    let isReportTo = true
    let reportTo = userAccount.username

    switch (templateName) {
      case "broken":
        curationNameObservable.value = "My Broken Link Checker"
        descriptionObservable.value = "Template for Broken Link Checker"
        curationAreaRadioButton.value = "Quality"
        isReportToObservable.value = true

        break;
      case "curation":
        curationNameObservable.value = "My Curation Standards"
        descriptionObservable.value = "Template for Curation Standards"
        curationAreaRadioButton.value = "Curation"
        isReportToObservable.value = true

        disableGeneralNextButton = false
        frequency = 3
        RecurIntervals = 0
        RecurDaysOfWeek = []
        RecurDaysOfMonth = [1]
        RecurHour = 11
        RecurMinute = 30
        reportTo = userAccount.username
        isReportTo = true

        break;
      case "product":
        curationNameObservable.value = "My Product Lifecycle Analyzer"
        descriptionObservable.value = "Template for Product Lifecycle Analyzer"
        curationAreaRadioButton.value = "Curation"
        isReportToObservable.value = true

        disableGeneralNextButton = false
        frequency = 3
        RecurIntervals = 0
        RecurDaysOfWeek = []
        RecurDaysOfMonth = [1]
        RecurHour = 12
        RecurMinute = 30
        reportTo = userAccount.username
        isReportTo = true

        break;
      default:
        curationNameObservable.value = ""
        descriptionObservable.value = ""
        curationAreaRadioButton.value = "empty"

        disableGeneralNextButton = true
        frequency = 1
        RecurIntervals = 12
        RecurDaysOfWeek = []
        RecurDaysOfMonth = []
        RecurHour = null
        RecurMinute = null
        reportTo = ''
        isReportTo = false

        break;
    }

    setCurrentCreateState({
      disableGeneralNextButton: disableGeneralNextButton,
      disableSubmitButton: false,
    })

    setTriggerState({
      triggerSettings: {
        frequency: frequency,
        RecurIntervals: RecurIntervals,
        RecurDaysOfWeek: RecurDaysOfWeek,
        RecurDaysOfMonth: RecurDaysOfMonth,
        RecurHour: RecurHour,
        RecurMinute: RecurMinute
      }
    })

    setReportToState({
      reportTo: reportTo,
      isReportTo: isReportTo
    })
  }

  /**
   * initalize the query expression by template
   */
  function initQueryExpression() {
    // set editor box default value
    setCurationFieldsDefault(
      props.index?.metadataSchema,
      "TaskCreate",
      templateName,
      appendExpression,
      onUpdateGroupingCheckbox,
      groupRows,
      removeExpression2)
  }

  /**
   * Set the defalut metadata schema
   * @returns 
   */
  function initPageState() {
    if (!props.indexList || !props.indexList.length) {
      return;
    }

    let currentIndex: DataQueryIndex = null;
    if (props.index && props.index.searchService !== "") {
      currentIndex = props.index
    }
    else {
      currentIndex = props.indexList[0];
    }

    props.selectIndex(currentIndex);

    const loadedSchemas = [];
    props.indexList.forEach((r) => {
      let metadataSchema = r.metadataSchema;
      if (metadataSchema && !loadedSchemas.includes(metadataSchema)) {
        props.loadMetadataFields(metadataSchema);

        loadedSchemas.push(metadataSchema);
      }
    });
  }

  /**
   * @description Adds a new empty expression before the specified index
   * @param rowIndex Position in array to add new expression
   */
  function addExpression(rowIndex: number) {
    const newRoot = { ...currentQueryState.queryExpressions };
    insertNewExpression(newRoot, rowIndex);
    setCurrentQueryState({
      ...currentQueryState,
      queryExpressions: newRoot,
    });
  };

  /**
   * remove invalid expressions
   */
  function removeInvalidExpressions2() {
    const newRoot = { ...currentQueryState.queryExpressions };
    removeInvalidExpressions(newRoot);
    setCurrentQueryState({
      ...currentQueryState,
      queryExpressions: newRoot,
    });
  };

  /**
   * 
   * @param rowIndex 
   * @param queryExpressions 
   */
  function appendExpression(rowIndex: number, queryExpressions: QueryExpression) {
    appendNewExpression2();
    onUpdateAndOr(rowIndex, queryExpressions.andOr);

    let myFieldOptions: FieldOptions = {
      fieldType: queryExpressions.fieldType,
      enumValues: [],
      operation: "update",
      fieldName: queryExpressions.field,
      key: queryExpressions.field,
      text: queryExpressions.field,
    };
    onUpdateField(rowIndex, myFieldOptions);
    onUpdateOperator(rowIndex, queryExpressions.operator);
    onUpdateValue(rowIndex, queryExpressions.value);
  };

  /**
 * @description Appends new expression to end of QueryRows
 */
  function appendNewExpression2() {
    const newRoot = { ...currentQueryState.queryExpressions };
    appendNewExpression(newRoot);
    setCurrentQueryState({
      ...currentQueryState,
      queryExpressions: newRoot,
    });
  };

  /**
  * @description Removes an expression from tree
  * @param rowIndex Position of expression to remove
  */
  function removeExpression2(rowIndex: number) {
    const newRoot = { ...currentQueryState.queryExpressions };
    removeExpression(newRoot, rowIndex);
    setCurrentQueryState({
      ...currentQueryState,
      queryExpressions: newRoot,
    });
  };

  /**
 * @description Creates a new expression group consisting of selected rows,
 * and inserts group into tree at position of closest common ancestor
 */
  function groupRows() {
    const newRoot = { ...currentQueryState.queryExpressions };
    groupSelectedExpressions(newRoot);
    setCurrentQueryState({
      ...currentQueryState,
      queryExpressions: newRoot,
    });
  };

  /**
  * @description Ungroups target expresstion group
  * @param groupID ID of target expression group
  */
  function onUngroupExpressions(groupID: number) {
    const newRoot = { ...currentQueryState.queryExpressions };
    ungroupExpressions(newRoot, groupID);
    setCurrentQueryState({
      ...currentQueryState,
      queryExpressions: newRoot,
    });
  };

  /**
  * @description Toggles grouping checkbox for target expression in array
  * @param rowIndex Position in expression array to update
  */
  function onUpdateGroupingCheckbox(rowIndex: number) {
    const root = { ...currentQueryState.queryExpressions };
    const expr = getExpression(root, rowIndex);
    expr.group ? (expr.group = false) : (expr.group = true);
    setExpression2(rowIndex, expr);
    setGroupingHoverText();
  };

  /**
  * @description Overwrites the expression at target rowIndex
  * @param rowIndex Position in QueryRows of target expression
  * @param newExpression New values for target expression to use
  */
  function setExpression2(rowIndex: number, newExpression: QueryExpression) {
    const newRoot = { ...currentQueryState.queryExpressions };
    setExpression(newRoot, rowIndex, newExpression);
    setCurrentQueryState({
      ...currentQueryState,
      queryExpressions: newRoot,
    });
  };

  /**
   * @description Sets hover text for grouping button to indicate reason why they can/can't
   * be grouped.
   */
  function setGroupingHoverText() {
    setCurrentQueryState({
      ...currentQueryState,
      groupHoverText: getGroupingHoverText(currentQueryState.queryExpressions),
    });
  };

  /**
   * @description Sets grouping operator for target expression in array
   * @param rowIndex Position in expression array to update
   * @param key Selected grouping operator (And/Or)
   */
  function onUpdateAndOr(rowIndex: number, key: string) {
    const root = { ...currentQueryState.queryExpressions };
    const expr = getExpression(root, rowIndex);
    expr.andOr = key;
    setExpression2(rowIndex, expr);
  };

  /**
   * @description Sets field for target expression in array
   * @rowIndex Position in expression array to update
   * @option Selected field
   */
  function onUpdateField(rowIndex: number, option: FieldOptions) {
    const root = { ...currentQueryState.queryExpressions };
    const expr = getExpression(root, rowIndex);
    expr.field = option.key as string;
    if (expr.fieldType !== option.fieldType) {
      expr.fieldType = option.fieldType;
      // set default operator and value
      if (option.fieldType in defaultFieldOperators)
        expr.operator = defaultFieldOperators[option.fieldType];
      if (option.fieldType in defaultFieldValues)
        expr.value = defaultFieldValues[option.fieldType];
    }
    setExpression2(rowIndex, expr);
  };

  /**
  * @description Sets operator for target expression in array
  * @param Position in expression array to update
  * @param operator Selected operator
  */
  function onUpdateOperator(rowIndex: number, operator: MetadataQueryOperator) {
    const root = { ...currentQueryState.queryExpressions };
    const expr = getExpression(root, rowIndex);
    expr.operator = operator;
    if (operator === isSetOperator || operator === isNotSetOperator) {
      expr.value = "";
    } else {
      if (expr.fieldType in defaultFieldValues)
        expr.value = defaultFieldValues[expr.fieldType];
    }
    setExpression2(rowIndex, expr);
  };

  /**
    * @description Sets value for target expression in array
    * @param rowIndex Position in expression array to update
    * @param value New value
    */
  function onUpdateValue(rowIndex: number, value: string) {
    const root = { ...currentQueryState.queryExpressions };
    const expr = getExpression(root, rowIndex);
    expr.field === "guid"
      ? (expr.value = value.toLocaleLowerCase())
      : (expr.value = value);
    setExpression2(rowIndex, expr);
  };

  /**
   * the tag id change
   * @param newTabId
   */
  function onSelectedTabChanged(newTabId: number) {
    setSelectedKey(newTabId);
  };

  /**
   * Set the curation name or description
   * @param type "Name"/"Description"
   * @returns
   */
  function onCurationNameOrDescriptionChange(type: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue: string
    ) => {
      if (type === "Name") {
        curationNameObservable.value = newValue;
        const shareWith = sharedWidthState
        setGeneralNextButtonStatus(shareWith);
      } else {
        descriptionObservable.value = newValue;
      }
    };
  };

  /**
   * Set the curation area RadioButtons value
   * @param buttonId radio button id
   */
  function onCurationAreaSelect(buttonId: string) {
    curationAreaRadioButton.value = buttonId;
    const shareWith = sharedWidthState
    setGeneralNextButtonStatus(shareWith);
  };

  /**
   * Set the run type RadioButtons value
   * @param buttonId radio button id
   */
  function onRunTypeSelect(buttonId: string) {
    runTypeRadioButton.value = buttonId;
  }

  /**
   * Handle the shared type selected
   * @param event 
   * @param item 
   */
  function onSharedTypeSelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<{}>) {
    sharedTypeDropdown.value = item.text
    const shareWith = sharedWidthState
    setGeneralNextButtonStatus(shareWith)
  }

  /**
   * Handle the Text Search data source selected
   * @param event 
   * @param item 
   */
  function onTextSearchDataSourceSelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<{}>) {
    textDataSourceDropdown.value = item.text
  }

  /**
   * Handle ticket top N selected
   * @param event 
   * @param item 
   */
  function onCurationFilterTopNSelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<{}>) {
    setCurationFilter({
      ...curationFilter,
      Top: parseIntFromString(item.id)
    })
  }

  /**
   * Handle ticket top N selected
   * @param event 
   * @param item 
   */
  function onCurationFilterOrdrBySelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<{}>) {
    setCurationFilter({
      ...curationFilter,
      OrderBy: item.id
    })
  }

  /**
 * trigger the IdentityPicker control
 * @param data
 */
  function onSharedWithUsersChange(data: string) {
    setSharedWithState(data);
    setGeneralNextButtonStatus(data)
  };

  /**
* trigger the cron control
* @param data
*/
  function onCronChange(data: TriggerSettings) {
    setTriggerState({
      triggerSettings: data
    })
  };

  /**
* Report it checkbox click
* @param _event
* @param newValue
*/
  function onIsReportToValueChange(
    _event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    newValue: boolean
  ) {
    isReportToObservable.value = newValue;

    let reportTo = reportToState.reportTo
    if (reportTo === null || reportTo === '') {
      reportTo = userAccount.username
    }

    setReportToState({
      reportTo: reportTo,
      isReportTo: newValue
    })

    setSubmitButtonStatus(reportTo)
  };

  /**
* Create Ticket checkbox click
* @param _event
* @param newValue
*/
  function onIsTicketValueChange(
    _event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    newValue: boolean
  ) {
    isTicketObservable.value = newValue;

    setTicketState({
      ...ticketState,
      ticketTypeDisabled: !newValue,
      isTicket: newValue
    })
  };

  /**
* Create Ticket checkbox click
* @param _event
* @param newValue
*/
  function onIsSendIcrementallyChange(
    _event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    newValue: boolean
  ) {
    isSendIcrementally.value = newValue;

    setTicketState({
      ...ticketState,
      isSendIcrementally: newValue
    })
  };

  /**
 * Handle ticket type selection.
 * @param selectValue - Selected ticket type value.
 */
  function onTicketTypeSelect(selectValue: string) {
    if (ticketState.ticketTypeId !== selectValue) {
      if (remediateItState?.RemediateItOrNot) {
        // Prepare the default ticket setting.
        let ticketSetting = Fix404HyperlinksADOTicketSettingDev;
        ticketSetting.AssignTo = userAccount.username;
        ticketSetting.ChildTaskAssignTo = userAccount.username;

        // Use production ticket setting if "RecommendedCreateTasks" is selected.
        if (selectValue === "RecommendedCreateTasks") {
          ticketSetting = Fix404HyperlinksADOTicketSettingProd;
        }

        setTicketADOState(ticketSetting);
      }
    }

    // Update ticket type in the state.
    setTicketState({
      ...ticketState,
      ticketTypeId: selectValue
    });
  }

  /**
  * trigger the IdentityPicker control
  * @param data
  */
  function onReportWithChange(data: string) {
    setReportToState({
      ...reportToState,
      reportTo: data
    });

    setSubmitButtonStatus(data)
  };

  /**
   * build query expression condition
   */
  function buildConditionJson() {
    for (var i = 0; i < currentQueryState.queryExpressions.children.length; i++) {
      const node = currentQueryState.queryExpressions.children[i];
      if (node.children) {
        expList.push({
          id: node.id,
          group: false,
          andOr: null,
          field: null,
          fieldType: null,
          operator: null,
          value: null,
          children: buildConditionChildrenJson(node.children),
        });
      } else {
        expList.push({
          id: node.id,
          group: node.group,
          andOr: node.andOr,
          field: node.field,
          fieldType: node.fieldType,
          operator: node.operator,
          value: node.value,
        });
      }
    }
  };

  /**
   * build children expression condition
   * @param childNode
   * @returns
   */
  function buildConditionChildrenJson(childNode: IQueryExpressions[]) {
    let currentChildren: IQueryExpressions[] = [];
    for (let i = 0; i < childNode.length; i++) {
      let node = childNode[i];
      if (node.children) {
        currentChildren.push({
          id: node.id,
          group: false,
          andOr: null,
          field: null,
          fieldType: null,
          operator: null,
          value: null,
          children: buildConditionChildrenJson(node.children),
        });
      } else {
        currentChildren.push({
          id: node.id,
          group: node.group,
          andOr: node.andOr,
          field: node.field,
          fieldType: node.fieldType,
          operator: node.operator,
          value: node.value,
        });
      }
    }

    return currentChildren;
  };

  /**
   * build query expression condition
   */
  function buildConditionJsonForExemption() {
    for (var i = 0; i < currentQueryState.queryExpressions.children.length; i++) {
      const node = currentQueryState.queryExpressions.children[i];
      if (node.children) {
        buildConditionChildrenJsonForExemption(node.children);
      } else {
        expListForExemption.push({
          id: node.id,
          group: node.group,
          andOr: node.andOr,
          field: node.field,
          fieldType: node.fieldType,
          operator: node.operator,
          value: node.value,
        });
      }
    }
  };

  /**
   * build children expression condition
   * @param childNode
   * @returns
   */
  function buildConditionChildrenJsonForExemption(childNode: IQueryExpressions[]) {
    for (let i = 0; i < childNode.length; i++) {
      let node = childNode[i];
      if (node.children) {
        buildConditionChildrenJsonForExemption(node.children);
      } else {
        expListForExemption.push({
          id: node.id,
          group: node.group,
          andOr: node.andOr,
          field: node.field,
          fieldType: node.fieldType,
          operator: node.operator,
          value: node.value,
        });
      }
    }
  };

  /**
   * check the curation name is vaild
   * @param curationName 
   * @returns 
   */
  function checkCurationNameIsVaild(curationName: string): boolean {
    const validTaskName = new RegExp("^[A-Za-z0-9\\s]+$");
    if (curationName.trim() !== "" && !validTaskName.test(curationName.trim())) {
      setTaskErrorState({
        showTaskNameError: true,
        taskNameErrorMessage: "The curation name must be alphanumeric.",
      });

      return false;
    }

    if (curationName.trim() !== "" && props.allCurationName) {
      let exsitCurationName = props.allCurationName.filter(t => t === curationName.trim())
      if (exsitCurationName && exsitCurationName.length > 0) {
        setTaskErrorState({
          showTaskNameError: true,
          taskNameErrorMessage: `The curation configuration name already exists.`
        });
        return false;
      }
    }

    setTaskErrorState({
      showTaskNameError: false,
      taskNameErrorMessage: "",
    });

    return true;
  };

  /**
   * when click the ok button
   */
  function onSubmitComplete() {
    props.clearApiResponseStatusAction()
  };

  /**
   * Close error dailog
   */
  function onErrorDialogDismss() {
    props.clearApiResponseStatusAction()
  }

  /**
   * handle the apply exemption event
   * @param values 
   */
  function onApplyExemption(values: ITagItem[]) {
    expListForExemption = [];
    buildConditionJsonForExemption();

    let firstRowFiledValue = "";
    if (expListForExemption.length > 0) {
      firstRowFiledValue = expListForExemption[0].field;
    }

    let rowCount = expListForExemption.length;

    let appendRowIndex = 0;
    let appendToGroup = false;

    values.map((value, index) => {
      let expClause = new QueryExpression();
      let andOr = "Or";

      // there is only one row
      if (rowCount === 1) {
        // if the value of the first row has already been set
        // Set the value starting from the second rows
        if (firstRowFiledValue && firstRowFiledValue.trim() !== "") {
          appendRowIndex = rowCount + index;
          andOr = "And";
          appendToGroup = values.length > 1;
        } else {
          // Set the value starting from the first rows
          appendRowIndex = index;
          andOr = null;
        }
      } else {
        appendRowIndex = rowCount + index;
        andOr = "And";
        appendToGroup = values.length > 1;
      }

      if (index > 0) {
        andOr = "Or";
      }

      expClause.andOr = andOr;
      expClause.field = "SetFreeTag";
      expClause.fieldType = "string";
      expClause.operator = "Does not contain";
      expClause.value = value.text.trim();
      appendExpression(appendRowIndex, expClause);

      if (appendToGroup) {
        onUpdateGroupingCheckbox(appendRowIndex);
      }
    });

    if (appendToGroup) {
      groupRows();
    }

    removeInvalidExpressions2();
  };

  /**
   * set next button status
   * @param sharedWith 
   */
  function setGeneralNextButtonStatus(sharedWith: string) {
    const taskName = curationNameObservable.value;
    const curationArea = curationAreaRadioButton.value;
    const shareType = sharedTypeDropdown.value

    if (
      checkCurationNameIsVaild(taskName) &&
      taskName.trim() !== "" &&
      curationArea !== "empty" &&
      curationArea !== "" &&
      (shareType !== "Shared With" || (shareType === "Shared With" && sharedWith !== ''))
    ) {
      setCurrentCreateState({
        ...currentCreateState,
        disableGeneralNextButton: false
      });
    } else {
      setCurrentCreateState({
        ...currentCreateState,
        disableGeneralNextButton: true
      });
    }
  };

  /**
   * set submit button status
   * @param reportWith 
   */
  function setSubmitButtonStatus(reportWith: string) {
    const isReport = isReportToObservable.value
    if (isReport && (reportWith === null || reportWith === '')) {
      setCurrentCreateState({
        ...currentCreateState,
        disableSubmitButton: true
      });
    }
    else {
      setCurrentCreateState({
        ...currentCreateState,
        disableSubmitButton: false
      });
    }
  }

  /**
   * handle the ticket apply event
   * @param data 
   */
  function onTicketADOApply(data: ITicketADO, defaultAreaId: string, defalutArea?: string, defalutIterationId?: string, defalutIteration?: string, dropdownList?: ITicketAssignToFields[]) {
    let areaId = data.AreaId
    let areaPath = data.Area
    if (!areaId || areaId?.toString() === "") {
      areaId = defaultAreaId
      areaPath = defalutArea
    }
    let iterationId = data.IterationId
    let iteration = data.Iteration
    if (!iterationId || iterationId?.toString() === "") {
      iterationId = defalutIterationId
      iteration = defalutIteration
    }

    setTicketADOState({
      Organization: data.Organization,
      Project: data.Project,
      EpicId: data.EpicId,
      FeatureId: data.FeatureId,
      RequirementId: data.RequirementId,
      UserStoryId: data.UserStoryId,
      WorkItemType: data.WorkItemType,
      AssignToType: data.AssignToType,
      AssignTo: data.AssignTo,
      Priority: data.Priority,
      Severity: data.Severity,
      Area: areaPath,
      AreaId: areaId,
      Iteration: iteration,
      IterationId: iterationId,
      TitlePattern: data.TitlePattern,
      DescriptionPattern: data.DescriptionPattern,
      WorkItemTitlePattern: data.WorkItemTitlePattern,
      WorkItemDescriptionPattern: data.WorkItemDescriptionPattern,
      Tags: data.Tags,
      CreateChildTasksOrNot: data.CreateChildTasksOrNot,
      MaximumChildTasks: data.MaximumChildTasks,
      OrderBy: data.OrderBy,
      HasAttachmentOrNot: data.HasAttachmentOrNot,
      ChildTaskAssignToType: data.ChildTaskAssignToType,
      ChildTaskAssignTo: data.ChildTaskAssignTo,
      ChildTaskAssignToFields: dropdownList,
      OtherFields: data.OtherFields
    })

    setNoCreateTicketState({
      ShowNoCreateTicketError: false,
      NoCreateTicketMessage: ""
    })
  }

  /**
   * clear query
   */
  function clearQuery() {
    setCurrentQueryState({
      groupHoverText: groupHoverText_notEnough,
      queryExpressions: initExprTree()
    })
  }

  /**
   * handle discard click event
   */
  function onDiscardClick() {
    clearQuery()
    props.clearSearchResults()
  }

  /**
   * handle algorithm select
   * @param algoKey 
   */
  function onAlgoClick(algoType: string) {
    textDataSourceDropdown.value = ""
    textDataSourceSelection.clear()

    setAlgorithemType(algoType)
  }


  /**
   * handle repo click
   * @param index 
   */
  function onRepoClick(index: DataQueryIndex) {
    clearQuery()
    props.selectIndex(index)

    for (let i = currentQueryState.queryExpressions.children.length - 1; i >= 0; i--) {
      removeExpression2(i)
    }

    // set editor box default value
    setCurationFieldsDefault(
      index.metadataSchema,
      "TaskCreate",
      "",
      appendExpression,
      onUpdateGroupingCheckbox,
      groupRows,
      removeExpression2)
    
    setIndexName(index.name)
  }

  /**
   * Close report dialog
   */
  function onQueryPreviewDimiss() {
    isQueryPreviewDialogOpen.value = false
  }

  /** Open preview dialog */
  function onPreviewClick() {
    const allFields = [...props.metadataFields, ...SPECIAL_SEARCH_FIELDS];
    removeInvalidExpressions2()
    const { filterString } = generateSearchStrings(
      currentQueryState.queryExpressions,
      allFields
    );

    setQueryFilterString(filterString)
    isQueryPreviewDialogOpen.value = true
  }

  const getHtmlPatternByAlgorithemType = () => {
    if(algorithemType === 'PoliCheckCrawler') {
      return PoliCheckIssuePattern
    }
    if(props.index?.metadataSchema.startsWith("gitpub-metadata-schema-evergreen-") && ["TextCrawler", "TextFinderAndReplacer"].includes(algorithemType)) {
      return TextReplaceTicketPattern
    }
    return RemediateTicketPattern
  }

  /**
   * submit the task data
   */
  function submitTask() {
    if (ticketState?.ticketTypeDisabled && remediateItState?.RemediateItOrNot) {
      setNoCreateTicketState({
        ShowNoCreateTicketError: true,
        NoCreateTicketMessage: `It is required to select "Create a Ticket" when "Remediate it for each Identified Article" is chosen.`
      })

      return
    }
    setIsSubmitting(true);
    const allFields = [...props.metadataFields];

    removeInvalidExpressions2();

    const { searchString, filterString } = generateSearchStrings(
      currentQueryState.queryExpressions,
      allFields
    );

    const SearchIndex = props.index.metadataSchema;
    const IndexPrefix = props.index.IndexPrefix;
    const Filter = filterString;
    const SearchQuery = "*";


    const { triggerSettings } = triggerState;
    const CurationConfigurationName = curationNameObservable.value;
    const IsReport = isReportToObservable.value;
    const Description = descriptionObservable.value;
    const CurationArea = curationAreaRadioButton.value;
    const RunType = runTypeRadioButton.value
    const SharedType = sharedTypeDropdown.value
    const SharedWith = sharedWidthState
    const ReportTo = reportToState.reportTo
    const IsTicket = isTicketObservable.value
    const TicketType = isTicketObservable.value === false ? "" : ticketState.ticketTypeId
    const AlgoType = algorithemType

    let AssignTo = "";
    const Path = ''
    const id = ''

    let ticketEvent: ITicketEvent = null

    if (ticketADOState && isTicketObservable.value &&
      (ticketState.ticketTypeId === CommonConstants.CurationRecommendedTicketTypeId
        || ticketState.ticketTypeId === CommonConstants.CurationTicketTypeId)) {

      // trim other fields
      let trimOtherFields: IOtherField[] = []
      if (ticketADOState.OtherFields && ticketADOState.OtherFields.length > 0) {
        for (let i = 0; i < ticketADOState.OtherFields.length; i++) {
          if (ticketADOState.OtherFields[i].FieldName.trim() !== '') {
            trimOtherFields.push({
              FieldId: ticketADOState.OtherFields[i].FieldId,
              FieldName: ticketADOState.OtherFields[i].FieldName.trim(),
              FieldValue: ticketADOState.OtherFields[i].FieldValue
            })
          }
        }
      }

      const htmlPattern = getHtmlPatternByAlgorithemType();

      ticketEvent = {
        Organization: ticketADOState.Organization,
        Project: ticketADOState.Project,
        WorkItemType: ticketADOState.WorkItemType,
        EpicId: ticketADOState.EpicId === "" ? null : ticketADOState.EpicId,
        FeatureId: ticketADOState.FeatureId === "" ? null : ticketADOState.FeatureId,
        RequirementId: ticketADOState.RequirementId === "" ? null : ticketADOState.RequirementId,
        UserStoryId: ticketADOState.UserStoryId === "" ? null : ticketADOState.UserStoryId,
        AssignToType: ticketADOState.AssignToType,
        AssignTo: ticketADOState.AssignTo,
        Priority: ticketADOState.Priority,
        Severity: ticketADOState.Severity,
        Area: ticketADOState.Area,
        AreaId: ticketADOState.AreaId,
        Iteration: ticketADOState.Iteration,
        IterationId: ticketADOState.IterationId,
        TitlePattern: ticketADOState.TitlePattern,
        DescriptionPattern: ticketADOState.DescriptionPattern,
        WorkItemTitlePattern: getWorkItemPatternValue(
          ticketADOState?.WorkItemTitlePattern,
          props.index?.metadataSchema,
          algorithemType,
          LMCDataSetTicketPattern.Title,
          htmlPattern.Title,
          remediateItState?.RemediateItOrNot
        ),
        WorkItemDescriptionPattern: getWorkItemPatternValue(
          ticketADOState?.WorkItemDescriptionPattern,
          props.index?.metadataSchema,
          algorithemType,
          LMCDataSetTicketPattern.Description,
          htmlPattern.Description,
          remediateItState?.RemediateItOrNot
        ),
        Tags: ticketADOState.Tags,
        CreateChildTasksOrNot: ticketADOState.CreateChildTasksOrNot,
        MaximumChildTasks: ticketADOState.MaximumChildTasks,
        OrderBy: ticketADOState.OrderBy,
        HasAttachmentOrNot: ticketADOState.HasAttachmentOrNot,
        ChildTaskAssignToType: ticketADOState.ChildTaskAssignToType,
        ChildTaskAssignTo: ticketADOState.ChildTaskAssignTo,
        ChildTaskAssignToFields: ticketADOState.ChildTaskAssignToFields,
        SendIncrementalOrNot: ticketState.isSendIcrementally,
        OtherFields: trimOtherFields
      }
    }

    let textSearchSettings: ITextSearchSettings = null
    if (AlgoType === "PoliCheckCrawler") {
      textSearchSettings = {
        TextSearchSource: "PoliCheckTermData",
        TextSearchSourceType: "PolicCheck"
      }
    }
    else if (AlgoType === "PIIScrubber") {
      textSearchSettings = {
        TextSearchSource: "PIIScrubber",
        TextSearchSourceType: "PIIScrubber"
      }
    }
    else if (["TextClawer", "TextFinderAndReplacer"].includes(AlgoType) && textDataSourceDropdown.value !== "") {
      textSearchSettings = {
        TextSearchSource: textDataSourceDropdown.value,
        TextSearchSourceType: getSearchTextDataSourceType()
      }
    }

    let Select = getCurationQueryFields(SearchIndex, AlgoType)
    let TopN = curationFilter?.Top
    let OrderBy = curationFilter.OrderBy

    expList = [];
    buildConditionJson();

    const ConditionJsonString = JSON.stringify(expList);

    props.onTaskSubmit(
      id,
      CurationConfigurationName,
      SearchQuery,
      SearchIndex,
      IndexPrefix,
      Filter,
      Select,
      TopN,
      OrderBy,
      AlgoType,
      RunType,
      SharedType,
      SharedWith,
      Path,
      IsReport,
      ReportTo,
      IsTicket,
      TicketType,
      AssignTo,
      ticketEvent,
      Description,
      CurationArea,
      ConditionJsonString,
      triggerSettings,
      remediateItState,
      textSearchSettings
    );
  };

  /**
   * Get text search data source type
   * @returns 
   */
  function getSearchTextDataSourceType(): string {
    if (props.dataSourceList && props.dataSourceList.length > 0) {
      const dataSource = props.dataSourceList.find(x => x.Name === textDataSourceDropdown.value)
      if (dataSource) {
        return dataSource.Type
      }
    }
  }

  /**
   * for test
   */
  function test() {
    expList = [];
    buildConditionJson();
    const ConditionJsonString = JSON.stringify(expList);

    console.log(currentQueryState.queryExpressions);
    console.log("ConditionJsonString", ConditionJsonString);
  };

  /**
   * render general tab
   * @returns
   */
  function rendGeneralTab(): JSX.Element {
    return (
      <div>
        <Card
          className="bolt-card-content"
          contentProps={{ contentPadding: false }}
        >
          <ul>
            <li className="clearfix">
              <FormItem
                message={taskErrorState.taskNameErrorMessage}
                error={taskErrorState.showTaskNameError}
              >
                <TextField
                  value={curationNameObservable}
                  onChange={onCurationNameOrDescriptionChange("Name")}
                  placeholder="Type curation name"
                  width={TextFieldWidth.standard}
                  label="Curation configuration name*:"
                  maxLength={50}
                />
              </FormItem>
            </li>
            <li className="clearfix">
              <FormItem label="Curation area*:">
                <RadioButtonGroup
                  onSelect={onCurationAreaSelect}
                  selectedButtonId={curationAreaRadioButton}
                  direction={null}
                >
                  <RadioButton id="Curation" text="Curation" key="Curation" />
                  <RadioButton
                    id="Performance"
                    text="Performance"
                    key="Performance"
                  />
                  <RadioButton
                    id="PrivacyAndSecurity"
                    text="Privacy and Security"
                    key="PrivacyAndSecurity"
                  />
                  <RadioButton id="Quality" text="Quality" key="option4" />
                  <RadioButton
                    id="CaseAndDigitalAssetCorrelation"
                    text="Case and Digital Asset Correlation"
                    key="Quality"
                  />
                  <RadioButton
                    id="Localization"
                    text="Localization"
                    key="Localization"
                  />
                  <RadioButton
                    id="Accessibility"
                    text="Accessibility"
                    key="Accessibility"
                  />
                  <RadioButton
                    id="AdvancedAnalyticsAndAutomation"
                    text="Advanced Analytics and Automation"
                    key="AdvancedAnalyticsAndAutomation"
                  />
                  <RadioButton id="Others" text="Others" key="Others" />
                </RadioButtonGroup>
              </FormItem>
            </li>
            <li className="clearfix">
              <FormItem
                label="Run type:"
              >
                <RadioButtonGroup
                  onSelect={onRunTypeSelect}
                  selectedButtonId={runTypeRadioButton}
                  direction={null}
                >
                  <RadioButton id="Full" text="Full" key="RunTypeFull" />
                  <RadioButton
                    id="Incremental"
                    text="Incremental"
                    key="RunTypeIncremental"
                  />
                  <TooltipHost className='tooltip_list' calloutProps={{ gapSpace: 0 }}
                    tooltipProps={{
                      onRenderContent: () => {
                        return (
                          <div className="tooltip_list_content">
                            <ul>                       
                              <li>Full runs - Scan the complete data set.</li>
                              <li>Incremental runs - Scan the changes since the previous run.</li>
                            </ul>
                          </div>
                        );
                      }
                    }}
                  >
                    <Status
                        {...Statuses.Information}
                        key="information"
                        size={StatusSize.s}
                        className="status-example flex-self-center tooltip_information"
                    />
                  </TooltipHost>
                </RadioButtonGroup>
              </FormItem>
            </li>
            <li className="clearfix">
              <FormItem
                label="Share type:"
              >
                <div >
                  <div className="bolt-textfield-default-width" style={{ "float": "left" }}>
                    <Dropdown
                      ariaLabel="Basic"
                      className="task-create-example-dropdown"
                      items={_sharedTypeItems}
                      onSelect={onSharedTypeSelect}
                      selection={shareTypeSelection}
                    />
                    <div style={{ "display": "inline-block" }}>
                      <TooltipHost className='tooltip_list' calloutProps={{ gapSpace: 0 }}
                        tooltipProps={{
                          onRenderContent: () => {
                            return (
                              <div className="tooltip_list_content">
                                <ul>                       
                                  <li>My Curations - Only you can see the curation.</li>
                                  <li>Shared With - This curation can be shared with others.</li>
                                  <li>Everyone - All users of content curation can view the configurations.</li>
                                </ul>
                              </div>
                            );
                          }
                        }}
                      >
                        <Status
                            {...Statuses.Information}
                            key="information"
                            size={StatusSize.s}
                            className="status-example flex-self-center tooltip_information"
                        />
                      </TooltipHost>
                    </div>
                  </div>
                  <Observer selectedItem={sharedTypeDropdown}>
                    {(sharedTypeprops: { selectedItem: string }) => {
                      if (sharedTypeprops.selectedItem === "Shared With") {
                        return (
                          <div className="bolt-textfield-default-width" style={{ "float": "left", "marginLeft": "20px" }}>
                            <MultipleIdentityPickerExtension UserMails={sharedWidthState} isRequired={true} onIdentityPickerChange={onSharedWithUsersChange} />
                          </div>
                        )
                      }
                      else {
                        return (null)
                      }
                    }}
                  </Observer>
                </div>
              </FormItem>
            </li>
            <li className="clearfix">
              <TextField
                ariaLabel="Aria label"
                value={descriptionObservable}
                onChange={onCurationNameOrDescriptionChange("Description")}
                placeholder="Type description"
                multiline
                rows={4}
                width={TextFieldWidth.standard}
                label="Description:"
                containerClassName="sonic-curation-description"
              />
            </li>
          </ul>
        </Card>
        <ul className="msacct-tab-button">
          <li>
            <Button
              primary={true}
              disabled={currentCreateState.disableGeneralNextButton}
              onClick={() => onSelectedTabChanged(1)}
            >
              Next &gt;
            </Button>
          </li>
        </ul>
      </div>
    );
  };

  /**
   * render cron control
   * @returns 
   */
  function rendCornTab(): JSX.Element {
    return (
      <CronBuilder
        onCronChange={onCronChange}
        frequencyProps={triggerState.triggerSettings.frequency}
        RecurIntervalsProps={triggerState.triggerSettings.RecurIntervals}
        RecurDaysOfWeekProps={triggerState.triggerSettings.RecurDaysOfWeek}
        RecurDaysOfMonthProps={triggerState.triggerSettings.RecurDaysOfMonth}
        RecurHourProps={triggerState.triggerSettings.RecurHour}
        RecurMinuteProps={triggerState.triggerSettings.RecurMinute}
      />
    )
  }

  /**
   * render action tab
   * @returns
   */
  function rendActionTab(): JSX.Element {
    return (
      <div>
        <Card
          className="bolt-card-content"
          contentProps={{ contentPadding: false }}
        >
          <div className="actionList flex-column">
            <div>
              <div style={{ "float": "left" }}>
                <Checkbox
                  onChange={onIsReportToValueChange}
                  checked={isReportToObservable}
                  label="Deliver mail to"
                />
              </div>
              {
                reportToState.isReportTo && <div style={{ "float": "left", "marginLeft": "40px" }}>
                  <MultipleIdentityPickerExtension className="msacct-identity-picker deliver" UserMails={reportToState.reportTo} isRequired={true} onIdentityPickerChange={onReportWithChange} />
                </div>
              }
            </div>
            <div>
              <Checkbox
                onChange={onIsTicketValueChange}
                checked={isTicketObservable}
                label="Create a ticket"
              />
            </div>
            <div className="msacct-ticket-container">
              <SingleDropdown
                selection={ticketSystemSelection}
                placeholder="Select a ticketing system"
                dropdownItems={ticketTypeDropdownItems}
                onSelectDropdown={onTicketTypeSelect}
                disabled={ticketState.ticketTypeDisabled}
                defaultValue={ticketState.ticketTypeId}
                className="msacct-single-dropdown-float-left" />
            </div>
            <FormItem className="sonic-no-ticket" error={noCreateTicketState.ShowNoCreateTicketError} message={noCreateTicketState.NoCreateTicketMessage}>
              <div className="msacct-ticket-container">
                {
                  (!ticketState.ticketTypeDisabled &&
                    (ticketState.ticketTypeId === CommonConstants.CurationRecommendedTicketTypeId ||
                      ticketState.ticketTypeId === CommonConstants.CurationTicketTypeId
                    )
                  ) &&
                  <>
                    <Link
                      className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link sonic-ticket-ado-link"
                      excludeTabStop
                      onClick={() => {
                        isTicketADODialogOpen.value = true
                      }}
                    >
                      {"Edit the work item template"}
                    </Link>
                    {
                      runTypeRadioButton.value === "Full" &&
                      <Checkbox
                        onChange={onIsSendIcrementallyChange}
                        disabled={true}
                        checked={isSendIcrementally}
                        label='Only send items identified incrementally since the last run (This option is only available for the curation when the run type is set to "Full".)'
                      />
                    }
                  </>
                }
              </div>
            </FormItem>

            <FixIt
              handleRemediateItClick={handleRemediateItClick}
              remediateItState={remediateItState}
              loginUserAlias={userAccount?.username}
              metadataSchema={props.index?.metadataSchema}
              algorithemType={algorithemType}
              isNewCuration={true}
              textSearchSource={textDataSourceDropdown.value}
              handleTicketCustomizationPageButtonClick={function (): void {
                isTicketADODialogOpen.value = true;
              }}
              handleRemediateItStateChanged={handleRemediateItStateChanged} />
          </div>
        </Card>
        <ul className="msacct-tab-button">
          <li>
            <Button primary={true} onClick={() => onSelectedTabChanged(2)}>
              &lt; Previous
            </Button>
          </li>
          <li>
            <Button primary={true} disabled={currentCreateState.disableSubmitButton} onClick={submitTask}>
              {isSubmitting? <>
                <AzureSpinner size={SpinnerSize.small}/>Submitting...
              </>: 'Submit'}
            </Button>
          </li>
        </ul>
      </div>
    );
  };

  /**
   * render action tab
   * @returns
   */
  function rendActionTabForProd(): JSX.Element {
    return (
      <div>
        <Card
          className="bolt-card-content"
          contentProps={{ contentPadding: false }}
        >
          <div className="actionList flex-column">
            <div style={{ marginTop: "10px" }}>
              <div style={{ "float": "left" }}>
                <Checkbox
                  onChange={onIsReportToValueChange}
                  checked={isReportToObservable}
                  label="Deliver mail to"
                />
              </div>
              {
                reportToState.isReportTo && <div style={{ "float": "left", "marginLeft": "40px" }}>
                  <MultipleIdentityPickerExtension className="msacct-identity-picker deliver" UserMails={reportToState.reportTo} isRequired={true} onIdentityPickerChange={onReportWithChange} />
                </div>
              }
            </div>
            <div>
              <Checkbox
                onChange={onIsTicketValueChange}
                checked={isTicketObservable}
                label="Create a ticket"
              />
            </div>
            <div className="msacct-ticket-container">
              <SingleDropdown
                placeholder="Select a ticketing system"
                dropdownItems={ticketTypeDropdownItems}
                onSelectDropdown={onTicketTypeSelect}
                disabled={ticketState.ticketTypeDisabled}
                className="msacct-single-dropdown-float-left" />
            </div>
            <FormItem className="sonic-no-ticket" error={noCreateTicketState.ShowNoCreateTicketError} message={noCreateTicketState.NoCreateTicketMessage}>
              <div className="msacct-ticket-container">
                {
                  (!ticketState.ticketTypeDisabled &&
                    (ticketState.ticketTypeId === CommonConstants.CurationRecommendedTicketTypeId ||
                      ticketState.ticketTypeId === CommonConstants.CurationTicketTypeId
                    )
                  ) &&
                  <>
                    <Link
                      className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link sonic-ticket-ado-link"
                      excludeTabStop
                      onClick={() => {
                        isTicketADODialogOpen.value = true
                      }}
                    >
                      {"Edit the work item template"}
                    </Link>
                    {
                      runTypeRadioButton.value === "Full" &&
                      <Checkbox
                        onChange={onIsSendIcrementallyChange}
                        disabled={true}
                        checked={isSendIcrementally}
                        label='Only send items identified incrementally since the last run (This option is only available for the curation when the run type is set to "Full".)'
                      />
                    }
                  </>
                }
              </div>
            </FormItem>
            <FixIt
              handleRemediateItClick={handleRemediateItClick}
              remediateItState={remediateItState}
              loginUserAlias={userAccount?.username}
              metadataSchema={props.index?.metadataSchema}
              algorithemType={algorithemType}
              isNewCuration={true}
              textSearchSource={textDataSourceDropdown.value}
              handleTicketCustomizationPageButtonClick={function (): void {
                isTicketADODialogOpen.value = true;
              }}
              handleRemediateItStateChanged={handleRemediateItStateChanged} />
          </div>
        </Card >
        <ul className="msacct-tab-button">
          <li>
            <Button primary={true} onClick={() => onSelectedTabChanged(2)}>
              &lt; Previous
            </Button>
          </li>
          <li>
            <Button primary={true} disabled={currentCreateState.disableSubmitButton} onClick={submitTask}>
              {isSubmitting? <>
                <AzureSpinner size={SpinnerSize.small}/>Submitting...
              </>: 'Submit'}
            </Button>
          </li>
        </ul>
      </div >
    );
  };

  /**
   * Build the text search source dropdown
   * @returns 
   */
  function renderTextSearchSource() {
    if (algorithemType === "TextCrawler" || algorithemType === "TextFinderAndReplacer") {
      return (
        <Card
          className="bolt-card-content"
          contentProps={{ contentPadding: false }}
          collapsible={true}
          titleProps={{
            text: "Text search settings",
            ariaLevel: 3,
          }}
        >
          <div className="ms-Grid" style={{ width: "100%" }}>
            <div className="ms-Grid-row" style={{ height: "auto" }} >
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ paddingLeft: "50px" }}>
                <div>
                  <span>Text Search Source:</span>
                </div>
                      <div style={{ marginTop: "6px" }}>
                        <Dropdown
                          ariaLabel="Basic"
                          className="sonic-base-dropdown-400-float-left"
                          items={dataSourceListState}
                          placeholder="Select an text search source"
                          onSelect={onTextSearchDataSourceSelect}
                          selection={textDataSourceSelection}
                                                  />
                        <Link
                          className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link sonic-ticket-ado-link"
                          excludeTabStop
                          onClick={() => { history(`/data/text`) }}
                                                  >
                          {"New text source"}
                        </Link>
                      </div>
                                          </div>
                                </div>
          </div>
        </Card>
      )
    }
    // else if (algorithemType === "PoliCheckCrawler") {
    //   return (
    //     <Card
    //       className="bolt-card-content"
    //       contentProps={{ contentPadding: false }}
    //       collapsible={true}
    //       titleProps={{
    //         text: "PoliCheck settings",
    //         ariaLevel: 3,
    //       }}
    //     >
    //       <div className="ms-Grid" style={{ width: "100%" }}>
    //         <div className="ms-Grid-row" style={{ height: "auto" }}>
    //           <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ paddingLeft: "50px" }}>
    //             <div>
    //               <span>PoliCheck Source:</span>
    //             </div>
    //             <div style={{ marginTop: "6px" }}>
    //               <TextField
    //                 value={poliCheckObservable}
    //                 placeholder="Type curation name"
    //                 width={TextFieldWidth.standard}
    //                 required
    //                 disabled
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </Card>
    //   )
    // }

    return null;
  }

  // render curation filter
  function renderCurationFilter() {
    return (
      <Card
        className="bolt-card-content"
        contentProps={{ contentPadding: false }}
        collapsible={true}
        titleProps={{
          text: "Advanced settings",
          ariaLevel: 3,
        }}
      >
        <div className="ms-Grid" style={{ width: "100%" }}>
          <div className="ms-Grid-row" style={{ height: "auto" }}>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ paddingLeft: "50px" }}>
              <div>
                <span>Sort Largest to Smallest:</span>
              </div>
              <div style={{ marginTop: "6px" }}>
                <Dropdown
                  ariaLabel="Basic"
                  className="sonic-base-dropdown-400-float-left"
                  items={curationFilterOrderByItems}
                  onSelect={onCurationFilterOrdrBySelect}
                  selection={curtionFilterOrderBySelection}
                />
                <span style={{ display: "inline-block", float: "left", marginTop: "5px", marginLeft: "5px" }}>Sort them in reverse alphabetical order if the field consists of letters.</span>
              </div>
            </div>
          </div>
          <div className="ms-Grid-row" style={{ height: "auto", marginTop: "5px" }}>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ paddingLeft: "50px" }}>
              <div>
                <span>Number Filters:</span>
              </div>
              <div style={{ marginTop: "6px" }}>
                <span style={{ display: "inline-block", float: "left", marginTop: "5px", marginRight: "5px" }}>Top</span>
                <Dropdown
                  ariaLabel="Basic"
                  items={CurationFilterTopNItems}
                  className="sonic-base-dropdown-300-float-left"
                  onSelect={onCurationFilterTopNSelect}
                  selection={curationFilterTopNSelection}
                />
                <Button
                  primary={false}
                  onClick={() => {
                    curtionFilterOrderBySelection.clear();
                    curationFilterTopNSelection.clear();
                    setCurationFilter({
                      Top: 0,
                      OrderBy: ""
                    })
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
            </div>
          </div>
        </div>
      </Card>
    )
  }

  useEffect(()=>{
    if(props.apiResponseState.showSuccessDailog || props.apiResponseState.showErrorDailog) {
        setIsSubmitting(false);
    }
  },[props.apiResponseState.showSuccessDailog, props.apiResponseState.showErrorDailog])

  return (
    <div className="msacct_taskcreate">
      {/* submit complete dialog */}
      <ConfirmationNavigateDialog
        isOpen={props.apiResponseState.showSuccessDailog}
        isBlocking={false}
        dialogTitle={"Changes submitted successfully"}
        dialogText={"Your curation configuration has been updated."}
        confirmButtonText={"OK"}
        onConfirm={onSubmitComplete}
        cancelButtonText={"Cancel"}
        onCancel={null}
        jumpPath="/curations"
      />

      {/* api error dialog */}
      <ErrorDialog
        showDialog={props.apiResponseState.showErrorDailog}
        title=''
        content={props.apiResponseState.ErrorMessage}
        onErrorDialogDismss={onErrorDialogDismss}
      />

      {/* render prview dialog */}
      <Observer isDialogOpen={isQueryPreviewDialogOpen}  >
        {(reportProps: { isDialogOpen: boolean }) => {
          return reportProps.isDialogOpen ? (
            <QueryPreveiw
              AlgorithmType={algorithemType}
              indexName={indexName}
              SearchIndex={props.index.metadataSchema}
              Filter={queryFilterString}
              OrderByField={curationFilter.OrderBy}
              Top={curationFilter.Top}
              expressionChildren={currentQueryState.queryExpressions.children}
              onReportDialogDimiss={onQueryPreviewDimiss} />
          ) : null;
        }}
      </Observer>

      {/* ticket ADO form */}
      <Observer isDialogOpen={isTicketADODialogOpen}  >
        {(ticketADOProps: { isDialogOpen: boolean }) => {
          return ticketADOProps.isDialogOpen ? (
            <TicketADO
              ticketADO={ticketADOState}
              ticketTypeId={ticketState.ticketTypeId}
              metadataSchema={props.index.metadataSchema}
              metadataFields={props.metadataFields}
              indexList={props.indexList}
              algoType={algorithemType}
              RemediateItOrNot={remediateItState?.RemediateItOrNot}
              onTicketADOApply={onTicketADOApply}
              onTicketADODialogDimiss={() => {
                isTicketADODialogOpen.value = false
              }} />
          ) : null;
        }}
      </Observer>

      <div className="taskcreate_body ">
        <Surface background={SurfaceBackground.neutral}>
          <Page className="pipelines-page flex-grow">
            <CustomHeader className="bolt-header-with-commandbar">
              <HeaderTitleArea>
                <HeaderTitleRow>
                  <HeaderTitle
                    ariaLevel={3}
                    className="text-ellipsis"
                    titleSize={TitleSize.Medium}
                  >
                    Create Curation
                  </HeaderTitle>
                </HeaderTitleRow>
              </HeaderTitleArea>
            </CustomHeader>

            <div className="page-content page-content-top">
              <Pivot aria-label="Override Selected Item Pivot Example" selectedKey={String(selectedKey)}>
                <PivotItem headerText="1. General" itemKey="0">
                  {rendGeneralTab()}
                </PivotItem>
                <PivotItem headerText="2. Filters" itemKey="1">
                  <>
                    <div>
                      <Card
                        className="bolt-card-content"
                        contentProps={{ contentPadding: false }}
                      >
                        <div className="msacct-create-filter">
                          <DataQueryCommandBar
                            queryExpressions={currentQueryState.queryExpressions}
                            isCurationPage={true}
                            loginUserAlias={userAccount?.username}
                            defaultAlogType={algorithemType}
                            onRunClick={onPreviewClick}
                            onAlgoClick={onAlgoClick}
                            onRepoClick={onRepoClick}
                            onExportResultsClick={null}
                            onDiscardClick={onDiscardClick}
                          />

                          <DataQueryBuilder
                            queryExpressions={currentQueryState.queryExpressions}
                            addExpression={addExpression}
                            metadataSchema={props.index?.metadataSchema}
                            appendNewExpression={appendNewExpression2}
                            removeExpression={removeExpression2}
                            groupRows={groupRows}
                            groupHoverText={currentQueryState.groupHoverText}
                            onUngroupExpressions={onUngroupExpressions}
                            onUpdateGroupingCheckbox={
                              onUpdateGroupingCheckbox
                            }
                            onUpdateAndOr={onUpdateAndOr}
                            onUpdateField={onUpdateField}
                            onUpdateOperator={onUpdateOperator}
                            onUpdateValue={onUpdateValue}
                          />
                        </div>

                      </Card>
                    </div>
                    <div><br /></div>
                    {
                      renderCurationFilter()
                    }
                    <div><br /></div>
                    {
                      renderTextSearchSource()
                    }
                    <div><br /></div>
                    {
                      props.index?.metadataSchema.startsWith("gitpub-metadata-schema-evergreen-") ?
                        <Card
                          className="flex-grow"
                          collapsible={true}
                          titleProps={{
                            text: "Exemption settings",
                            ariaLevel: 3,
                          }}
                          contentProps={{ contentPadding: true }}
                        >
                          <ExemptionEditorDialog
                            exemptionItems={props.curationTags}
                            onApplyExemption={onApplyExemption}
                          />
                        </Card> :
                        null
                    }

                    <ul className="msacct-tab-button">
                      <li>
                        <Button
                          primary={true}
                          onClick={() => onSelectedTabChanged(0)}
                        >
                          &lt; Previous
                        </Button>
                      </li>
                      <li>
                        <Button
                          primary={true}
                          onClick={() => onSelectedTabChanged(2)}
                          disabled={
                            !hasValidExpression(
                              currentQueryState.queryExpressions
                            )
                          }
                        >
                          Next &gt;
                        </Button>
                      </li>
                      <li>
                        <Button
                          primary={true}
                          disabled
                          style={{ display: "none" }}
                          onClick={test}
                        >
                          Check the Condition
                        </Button>
                      </li>
                    </ul>
                  </>
                </PivotItem>
                <PivotItem headerText="3. Triggers" itemKey="2">
                  <div>
                    <Card
                      className="bolt-card-content"
                      contentProps={{ contentPadding: true }}
                    >
                      <div style={{ "width": "100%" }}>
                        <div className="scheduleModule">
                          <FormItem label="Schedules:">
                            {rendCornTab()}
                          </FormItem>
                        </div>
                        {
                          sonci_Env === "prod" ?
                            null
                            :
                            <div className="eventsModule">
                              <FormItem label="Events (Ga):">
                                <RadioButtonGroup
                                  className="eventsRDGroup"
                                  selectedButtonId={"empty"}
                                  direction={RadioButtonGroupDirection.Vertical}
                                >
                                  <RadioButton disabled={true} id="event 1" text="When new content is published" key="event 1" />
                                  <RadioButton disabled={true} id="event 2" text="When existing content is re-published" key="event 2" />
                                  <RadioButton disabled={true} id="event 3" text="When a specific tag is added" key="event 3" />
                                  <RadioButton disabled={true} id="event 4" text="When content sensitivity is marked as 'Microsoft Partners'" key="event 4" />
                                </RadioButtonGroup>
                              </FormItem>
                            </div>
                        }

                      </div>
                    </Card>
                    <ul className="msacct-tab-button">
                      <li>
                        <Button
                          primary={true}
                          onClick={() => onSelectedTabChanged(1)}
                        >
                          &lt; Previous
                        </Button>
                      </li>
                      <li>
                        <Button
                          primary={true}
                          onClick={() => onSelectedTabChanged(3)}
                        >
                          Next &gt;
                        </Button>
                      </li>
                    </ul>
                  </div>
                </PivotItem>
                <PivotItem headerText="4. Actions" itemKey="3">
                  {
                    sonci_Env === "prod" ?
                      rendActionTabForProd()
                      :
                      rendActionTab()
                  }
                </PivotItem>
              </Pivot>
            </div>

          </Page>
        </Surface>
      </div>
    </div>
  );
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(TaskCreate);
