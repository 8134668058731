import { MessageBar, MessageBarType } from "@fluentui/react";
import './CustomMessageAlert.css'

interface ICustomMessageAlertProps {
    message: string;
    showAlert: boolean;
    type: string
}

export default function CustomMessageAlert(props: ICustomMessageAlertProps) {
    return (
        <>
            {props.showAlert && (
                <MessageBar
                    messageBarType={MessageBarType[props.type]}
                    isMultiline={false}
                    className="custom_message_alert"
                >
                    {props.message}
                </MessageBar>
            )}
        </>
    );
}
