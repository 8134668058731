import React, { Component } from 'react'
import AppLayout from "../AppLayout/AppLayout";
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'

import ScanRequests from '../../ScanRequests/ScanRequests'

const breadcrumbItem: IBreadCrumbExtension[] = [
  { text: 'Home', key: 'Home', href: '/' },
  { text: 'Data Freshness', key: 'ia'},
  { text: 'Unified Data Platform', key: 'ia1'},
  { text: '404 Error Data', key: 'error'},
];

export default class ScanRequestsUnified404ErrorPage extends Component {
  

  render() {
    document.title = MSACCT_TITLE + " | " + "Unified Data Platform (404 Error Data)";
    return (
      <AppLayout
        renderBreadcrumb={() =>
          <BreadCrumbExtension items={breadcrumbItem} />
        }
        renderContentPane={() => <ScanRequests FactoryType='Database' FactoryName='404 Error Data' />} />
    );
  }
}

