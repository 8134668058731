import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useState, useEffect } from "react";
import { Status, Statuses, StatusSize } from "azure-devops-ui/Status";
import './CustomConfirmDialog.css'

/**
 *  dialogType should be error/waning/info
 */
interface ICustomConfirmDialogProps {
    showDialog: boolean
    dialogType: string,
    title?: string,
    content: string | React.ReactNode,
    hasCancel?: boolean,
    cancelButtonLabel?: string,
    confirmButtonLabel?: string,
    onConfirmDismiss: ()=> void,
    onCancelDismiss?: ()=> void
}

export default function CustomConfirmDialog(props: ICustomConfirmDialogProps) {

    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)

    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        className: "custom_confirm_dialog",
    };

    useEffect(()=>{
        setShowConfirmDialog(props.showDialog)
    },[props.showDialog])

    const defaultDismissAction = () => {
        setShowConfirmDialog(false)
    }

    const getFooterButtonProps = () => {
        const footerButtonProps = [
            {
                text: props.confirmButtonLabel? props.confirmButtonLabel: 'Confirm',
                onClick: props.onConfirmDismiss? props.onConfirmDismiss: defaultDismissAction,
                primary: true
            }
        ]
        if(props.hasCancel) {
            footerButtonProps.push({
                text: props.cancelButtonLabel? props.cancelButtonLabel: 'Cancel',
                onClick: props.onCancelDismiss? props.onCancelDismiss: defaultDismissAction,
                primary: false
            })
        }
        return footerButtonProps
    }

    const getIconByDialogType = () => {
        if(props.dialogType === 'error') {
            return 'Failed'
        }else if(props.dialogType === 'warning') {
            return 'Warning'
        }
        return 'Success'
    }

    return (
        <>
            <Dialog
                hidden={!showConfirmDialog}
                dialogContentProps={{ 
                    title: props.title? props.title: 'Confirm',
                    className: `custom_confirm_dialog_${props.dialogType}`
                }}
                modalProps={modelProps}
                onDismiss={defaultDismissAction}
            >
                <div className="custom_confirm_dialog_content">
                    <div>
                        <Status
                            {...Statuses[getIconByDialogType()]}
                            key="failed"
                            size={StatusSize.xl}
                        />
                    </div>
                    <div className="custom_confirm_dialog_content_main">
                        { props.content }
                    </div>
                </div>
                <DialogFooter>
                    {props.hasCancel && <DefaultButton
                        onClick={props.onCancelDismiss? props.onCancelDismiss: defaultDismissAction}
                        text={props.cancelButtonLabel? props.cancelButtonLabel: 'Cancel'}
                    />}
                    <PrimaryButton 
                        onClick={props.onConfirmDismiss? props.onConfirmDismiss: defaultDismissAction} 
                        text={props.confirmButtonLabel? props.confirmButtonLabel: 'Confirm'} 
                    />
                </DialogFooter>
            </Dialog>
        </>
    )
}