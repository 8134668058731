import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { returnType } from "../../Utilities/ReturnType";
import { onColumnOptionsClose } from "../../../redux/Actions/PowerDataQuery";
import {
  DetailsList,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Selection,
  SelectionMode,
  DetailsListLayoutMode,
  IconButton,
  IIconProps,
  MarqueeSelection,
  PrimaryButton,
  IColumn,
  CheckboxVisibility,
  Icon,
} from "@fluentui/react";

import { GlobalDataQuery } from "../../Interface/IPowerQuery";

import "./ColumnOptionsDialog.css";

const forwardIcon: IIconProps = { iconName: "Forward" };
const backIcon: IIconProps = { iconName: "Back" };
const upIcon: IIconProps = { iconName: "Up" };
const downIcon: IIconProps = { iconName: "Down" };

const mapStateToProps = (state: GlobalDataQuery) => ({
  indexList: state.indexList,
  index: state.index,
  isOpen: state.layout.showColumnOptionsDialog,
  metadataFields:
    state.metadataFields &&
    state.index &&
    state.index.metadataSchema &&
    state.metadataFields[state.index.metadataSchema],
});

const actionCreators = {
    onColumnOptionsClose
};
type DispatchProps = typeof actionCreators;

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

class ColumnOptionsDialog extends Component<StoreProps & DispatchProps> {
  getAvailableFields = () => {
    console.log("column opton", this.props.metadataFields)
    const allAvailableFields = 
    !this.props.metadataFields?
    []:
    this.props.metadataFields
    .map(f=>({key: f.FieldName, name:f.DisplayName}))

    console.log("column opton", allAvailableFields)
    return [...allAvailableFields]
  }

  render() {
    
    if (!this.props.isOpen) return null

    return (
      <Dialog
        hidden={!this.props.isOpen}
        dialogContentProps={{
          type: DialogType.normal,
          subText: "",
        }}
        modalProps={{ isBlocking: true }}
        onDismiss = {this.props.onColumnOptionsClose}
        title="Column options"
      >
        <div className="dialog-tbl">
          <div>
          <DetailsList
                items={this.getAvailableFields()}
                compact={true}
                columns={[
                  {
                    key: 'available_columns',
                    name: 'Available columns',
                    fieldName: 'availableColumns',
                    minWidth: 150,
                    maxWidth: 150,
                    data: 'string',
                    onRender: (item: IColumn) => <span>{item.name}</span>
                  }
                ]}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                //selection={this.state._availableColumns_selection}
                checkboxVisibility={CheckboxVisibility.hidden}
              />
          </div>
        </div>

        <DialogFooter>
          <PrimaryButton
            onClick={this.props.onColumnOptionsClose}
          >OK</PrimaryButton>
          <DefaultButton
            onClick={this.props.onColumnOptionsClose}
          >Cancel</DefaultButton>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(ColumnOptionsDialog);
