import * as React from 'react';
import { VerticalStackedBarChart } from '@uifabric/charting';
import { IVSChartDataPoint, IVerticalStackedChartProps } from '@uifabric/charting';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { DirectionalHint } from '@fluentui/react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';

const options: IChoiceGroupOption[] = [
  { key: 'singleCallout', text: 'Single callout' },
  { key: 'MultiCallout', text: 'Stack callout' },
];

interface IVerticalStackedBarState {
  width: number;
  height: number;
  selectedCallout: string;
}

export default class VerticalStackedBarChartCalloutExample extends React.Component<{}, IVerticalStackedBarState> {
  constructor(props: IVerticalStackedChartProps) {
    super(props);
    this.state = {
      width: 280,
      height: 160,
      selectedCallout: 'MultiCallout',
    };
  }
  public render(): JSX.Element {
    return <div>{this._basicExample()}</div>;
  }

  private _onWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ width: parseInt(e.target.value, 10) });
  };
  private _onHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ height: parseInt(e.target.value, 10) });
  };

  private _onChange = (ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption): void => {
    this.setState({ selectedCallout: option.key! });
  };

  private _basicExample(): JSX.Element {
    const firstChartPoints: IVSChartDataPoint[] = [
      { legend: 'Digital Assets Checked During …', data: 60, color: DefaultPalette.accent },
      { legend: 'Issues/Possible Issues Flagged …', data: 25, color: DefaultPalette.redDark },
    ];

    const secondChartPoints: IVSChartDataPoint[] = [
      { legend: 'Digital Assets Checked During …', data: 40, color: DefaultPalette.accent },
      { legend: 'Issues/Possible Issues Flagged …', data: 13, color: DefaultPalette.redDark },
    ];

    const data: IVerticalStackedChartProps[] = [
      { chartData: firstChartPoints, xAxisPoint: 'To Date' },
      { chartData: secondChartPoints, xAxisPoint: 'This Year' },
      { chartData: firstChartPoints, xAxisPoint: 'This Month' },
      { chartData: firstChartPoints, xAxisPoint: 'Last Run' },
    ];

    const rootStyle = { width: `${this.state.width}px`, height: `${this.state.height}px` };

    return (
      <>
        <div style={rootStyle}>
          <VerticalStackedBarChart
            data={data}
            height={this.state.height}
            width={this.state.width}
            yAxisTickCount={5}
            chartLabel="Card title"
            isCalloutForStack={this.state.selectedCallout === 'MultiCallout'}
            yMaxValue={100}
            calloutProps={{
              directionalHint: DirectionalHint.topCenter,
            }}
            margins={{ left: 25 }}
            colors={['red', 'white', 'green', 'black']}
          />
        </div>
      </>
    );
  }
}