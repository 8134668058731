import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import {
    IdentityPickerDropdown,
    IIdentity,
    IPeoplePickerProvider,
    IPersonaConnections,
} from "azure-devops-ui/IdentityPicker";
import { InteractionType } from "@azure/msal-browser"
import { useMsalAuthentication } from "@azure/msal-react"

import './IdentityPickerExtension.css'

/**
 * interface for default value props
 */
interface IDefaultUsersProps {
    DefaultUserMail: string,
    className?: string,
    placeholder?: string,
    onIdentityPickerChange: (data: any) => void
}

let selectedIdentity = new ObservableValue<IIdentity | undefined>(undefined);
export default function SingleIdentityPickerExtensionV2(props: IDefaultUsersProps) {
    
    let pickerProvider = new IdentityPickerSingleProviderV2()    

    pickerProvider.refreshToken = refreshToken

    const { login, result, error } = useMsalAuthentication(InteractionType.Popup, {
        scopes: ["user.read"]
    })

    useEffect(() => {
        if (result) {
            pickerProvider.accessToken = result.accessToken
        }

        selectedIdentity = new ObservableValue<IIdentity | undefined>(undefined);

        if (props.DefaultUserMail && props.DefaultUserMail !== "") {
            props.onIdentityPickerChange(props.DefaultUserMail)

            let searchCondition = "\"userPrincipalName:" + props.DefaultUserMail + "\"";

            const loadData = async () => {
                if (result) {
                    var config = {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            "ConsistencyLevel": "eventual",
                            'Authorization': `Bearer ${result.accessToken}`
                        }
                    }

                    await axios.get(`https://graph.microsoft.com/v1.0/users?$count=true&$search=${searchCondition}&$filter=endsWith(mail,'@microsoft.com')&$select=id,displayName,userPrincipalName`, config)
                        .then(res => {
                            if (res && res.data && res.data.value) {
                                res.data.value.map(item => {
                                    let defaultIIdentity = new ObservableValue<IIdentity>({
                                        displayName: item.displayName,
                                        entityId: item.displayName,
                                        entityType: "user",
                                        mail: item.userPrincipalName,
                                        originDirectory: "aad",
                                        originId: ""
                                    });

                                    selectedIdentity.value = defaultIIdentity.value
                                })
                            }
                        })
                        .catch(error => {
                            console.error("Get default users", error)
                        })
                }
            }

            loadData()
        }

    }, [result, error])

    /**
     * reset the provider access token
     */
    function refreshToken() {
        if (result) pickerProvider.accessToken = result.accessToken
    }

    /**
     * 
     * @param identity 
     */
    function onChange(identity?: IIdentity) {
        selectedIdentity.value = identity;
        props.onIdentityPickerChange(identity?.mail)
    }


    return (
        <IdentityPickerDropdown
            onChange={onChange}
            pickerProvider={pickerProvider}
            value={selectedIdentity}
            className={props.className ? props.className : "msacct-single-identity-picker-percent80"}
            placeholder={props.placeholder ? props.placeholder : "Assign To"}
        />
    )
}

/**
 * define the IdentityPickerProvider
 */
class IdentityPickerSingleProviderV2 implements IPeoplePickerProvider {
    private currentPersonas: IIdentity[] = [];

    private personas: IIdentity[] = []

    // access token from component
    public accessToken: string = ''

    // function for reset token
    public refreshToken: any = null


    public onFilterIdentities(filter: string, selectedItems?: IIdentity[]) {
        const loadData = async () => {
            var config = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "ConsistencyLevel": "eventual",
                    'Authorization': `Bearer ${this.accessToken}`
                }
            }

            if (filter === "") return Promise.resolve(null)

            return await axios.get(`https://graph.microsoft.com/v1.0/users?$count=true&$search="displayName:${filter}" OR "userPrincipalName:${filter}"&$filter=endsWith(mail,'@microsoft.com')&$select=id,displayName,userPrincipalName`, config)
        }

        this.personas = []
        loadData().then(item => {
            if (item && item.data.value) {
                item.data.value.map(personItem => {
                    if (!this.personas.find(exsitItem => exsitItem.mail === personItem.userPrincipalName)) {
                        this.personas.push(this.getIdentityEntity(personItem.displayName, personItem.userPrincipalName))
                    }
                })
            }

            return this.getPersonaList(filter, selectedItems);

        }).catch(error => {
            console.log("onFilterIdentities error", error)
            if (this.refreshToken) {
                this.refreshToken()
            }
        })

        return this.getPersonaList(filter, selectedItems);
    }

    /**
     * Request Entity information given an entityId, inherited method
     * @param entityId 
     * @returns 
     */
    public getEntityFromUniqueAttribute = (entityId: string): IIdentity => {
        return this.personas.filter((x) => x.entityId === entityId)[0];
    };

    /**
     * Request connection information about a given Entity, inherited method
     * @param entity 
     * @param getDirectReports 
     * @returns 
     */
    public onRequestConnectionInformation = (
        entity: IIdentity,
        getDirectReports?: boolean
    ): IPersonaConnections => {
        return {
            directReports: getDirectReports ? [] : undefined,
            managers: []
        };
    };

    /**
     * If no input is in the search box when clicked, provide a set of identities to show (used for MRU)
     * @returns 
     */
    public onEmptyInputFocus(): IIdentity[] {
        return []
    }

    /**
     * get Identity entity
     * @param displayName 
     * @param mail 
     * @returns 
     */
    private getIdentityEntity(displayName: string, mail: string): IIdentity {
        return {
            displayName,
            entityId: displayName,
            entityType: "user",
            mail: mail,
            originDirectory: "aad",
            originId: "",
        } as IIdentity;
    }

    /**
     * Simulate initial request delay when the user first tries to grab a list
     * @param filter 
     * @param selectedItems 
     * @returns 
     */
    private getPersonaList(
        filter: string,
        selectedItems?: IIdentity[]
    ): Promise<IIdentity[]> | IIdentity[] {
        this.currentPersonas = []
        if (this.currentPersonas.length > 0) {
            return this.filterList(filter, selectedItems);
        } else {
            return new Promise<IIdentity[]>((resolve, reject) =>
                setTimeout(() => {
                    this.currentPersonas = this.personas;
                    resolve(this.filterList(filter, selectedItems));
                }, 3000)
            );
        }
    }

    /**
     * filter users
     * @param filter 
     * @param selectedItems 
     * @returns 
     */
    private filterList(filter: string, selectedItems?: IIdentity[]) {
        if (filter === "") {
            return this.onEmptyInputFocus();
        }

        return this.currentPersonas.filter(
            (x) =>
                (selectedItems === undefined || selectedItems.indexOf(x) === -1) &&
                ((x.displayName &&
                    x.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
                    (x.mail && x.mail.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
        );
    }
}