import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { connect } from "react-redux";

import { CustomDialog } from "azure-devops-ui/Dialog";
import { PanelContent, PanelFooter } from "azure-devops-ui/Panel";
import { FormItem } from "azure-devops-ui/FormItem";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Button } from "azure-devops-ui/Button";
import { Dropdown } from "azure-devops-ui/Dropdown";
import { DropdownSelection } from "azure-devops-ui/Utilities/DropdownSelection";
import { IListBoxItem } from "azure-devops-ui/ListBox";
import { CustomHeader, HeaderTitleArea } from "azure-devops-ui/Header";
import { Toggle } from "azure-devops-ui/Toggle";
import { useMsal, useAccount } from "@azure/msal-react";
import { Card } from "azure-devops-ui/Card";
import { Icon } from '@fluentui/react/lib/Icon';
import { Observer } from "azure-devops-ui/Observer";
import { GlobalDataQuery, DataField, DataQueryIndex } from "../../Interface/IPowerQuery";
import SingleIdentityPickerExtensionV2 from '../../Controls/IdentityPickerExtension/SingleIdentityPickerExtensionV2'
import SingleIdentityPickerExtensionV3 from '../../Controls/IdentityPickerExtension/SingleIdentityPickerExtensionV3'
import { ITicketADO, IOtherField, ITicketADODropdownItem, ITicketAssignToFields } from '../../Interface/ITicketADO'
import { CommonConstants } from '../../Utilities/CommonConstants'
import TicketWorkItem from './TicketWorkItem';
import TicketWorkItem2 from './TicketWorkItem';
import { TicketPattern, RemediateTicketPattern, LMCDataSetTicketPattern } from '../../../ConfigData/Curation.Actions.FixIt.ADOTicketSetting'
import { getWorkItemPatternValue } from '../../Utilities/CommonFunction';

import { clearApiResponseStatusAction, getTicketDevOpsConfig } from "../../../redux/Actions/PowerDataQuery";
import CascadingDropdown from '../../Modules/CascadingDropdown/CascadingDropdown';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { Status, Statuses, StatusSize } from "azure-devops-ui/Status";
import { Spinner, SpinnerSize } from 'azure-devops-ui/Spinner';

import './TicketADO.css'

const actionCreators = {
    clearApiResponseStatusAction,
    getTicketDevOpsConfig
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    apiResponseState: state.apiResponseState,
    ticketDevOpsConfig: state.ticketDevOpsConfig
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

interface ITicketADOProps {
    ticketADO: ITicketADO,
    ticketTypeId?: string,
    RemediateItOrNot?: boolean,
    metadataSchema?: string,
    metadataFields?: DataField[],
    indexList?:DataQueryIndex[],
    algoType?: string,
    onTicketADOApply: (data: ITicketADO, defaultAreaId: string, defalutArea?: string, defalutIterationId?: string, defalutIteration?: string, dropdownList?: ITicketAssignToFields[]) => void,
    onTicketADODialogDimiss: () => void
}

interface IInputErrorState {
    showDevOpsUrlError: boolean;
    showEpicError: boolean;
    showFeatureError: boolean;
    showRequirementError: boolean;
    showWorkItemTypeError: boolean;
    DevOpsUrlErrorMessage: string;
    EpicErrorMessage: string;
    FeatureErrorMessage: string;
    RequirementErrorMessage: string;
    WorkItemTypeErrorMessage: string;
}

/** Priority or Severity items value */
const PriorityItems = [
    {
        id: "1",
        text: "1",
    },
    {
        id: "2",
        text: "2",
    },
    {
        id: "3",
        text: "3",
    }
]

/** task count items */
const taskCountItems = [
    {
        id: "1",
        text: "1"
    },
    {
        id: "5",
        text: "5"
    },
    {
        id: "10",
        text: "10"
    },
    {
        id: "20",
        text: "20"
    },
    {
        id: "50",
        text: "50"
    },
    {
        id: "100",
        text: "100"
    },
    {
        id: "200",
        text: "200"
    },
    {
        id: "500",
        text: "500"
    },
    {
        id: "999999",
        text: "999999"
    }
]

/** task count items */
const assignToTypeParentItems = [
    {
        id: "Assign to a default person",
        text: "Assign to a default person"
    },
    {
        id: "Assign to the curation owner",
        text: "Assign to the curation owner"
    },
    {
        id: "Leave it as default (usually empty or Active on Azure DevOps)",
        text: "Leave it as default (usually empty or Active on Azure DevOps)"
    },
    {
        id: "Assign to an Azure DevOps group",
        text: "Assign to an Azure DevOps group"
    }
]

/** assign to child items */
const assignToTypeChildItems = [
    {
        id: "Assign to a default person",
        text: "Assign to a default person"
    },
    {
        id: "Assign to the person copying the value from field",
        text: "Assign to the person copying the value from field"
    },
    {
        id: "Leave it as default (usually empty or Active on Azure DevOps)",
        text: "Leave it as default (usually empty or Active on Azure DevOps)"
    },
    {
        id: "Assign to an Azure DevOps group",
        text: "Assign to an Azure DevOps group"
    }
]

const USER_VALID_TOOLTIP = "The system will examine the values of the selected fields in sequential order as configured. If the value corresponds to an invalid user, it will proceed to check the next field until a valid user is found. In the event that all values are invalid, the program will utilize the default value."

/** store items value of the dropdown list   */
interface ITicketADODropdownState {
    DevOpsURLItems: ITicketADODropdownItem[],
    EpicItems: ITicketADODropdownItem[],
    FeatureItems: ITicketADODropdownItem[],
    RequirementItems: ITicketADODropdownItem[],
    UserStoryItems: ITicketADODropdownItem[],
    AreaPathItems: ITicketADODropdownItem[],
    IterationsItems: ITicketADODropdownItem[],
    WorkItemTypes?: string[],
    OrderByItems?: ITicketADODropdownItem[],
    AssignToCopyItem?: ITicketADODropdownItem[]
}

/** store the default value of control */
let createChildToggle = new ObservableValue<boolean>(false);
let hasAttachmentToggle = new ObservableValue<boolean>(false);

let devOpsUrlSelection = new DropdownSelection();
let epicIdSelection = new DropdownSelection();
let featureIdSelection = new DropdownSelection();
let requirementIdSelection = new DropdownSelection();
let userStoryIdSelection = new DropdownSelection();
let workItemTypeSelection = new DropdownSelection();

let assignToTypeSelection = new DropdownSelection()
let childAssignToTypeSelection = new DropdownSelection()
let childAssignToFieldSelection = new DropdownSelection()
let priorityFieldSelection = new DropdownSelection();
let severityFieldSelection = new DropdownSelection();
let areaFieldSelection = new DropdownSelection();
let iterationsFieldSelection = new DropdownSelection();
let trackingSystemSelection = new DropdownSelection()
let orderBySelection = new DropdownSelection()
let taskCountSelection = new DropdownSelection()
let collapsedTicketFields = new ObservableValue<boolean>(true);
let collapsedTicketAdvanced = new ObservableValue<boolean>(true);
// Show ticket ADO dialog form
let isTicketWorkItemDialogOpen = new ObservableValue<boolean>(false);

let _ticketTimer: any = null

function TicketADO(props: StoreProps & DispatchProps & ITicketADOProps) {
    /** Import msal-react for getting login user information */
    const { instance, accounts } = useMsal();
    const userAccount = useAccount(accounts[0]);
    /** Initialize ticket ADO state, to store the value */
    const [ticketADOState, setTicketADOState] = useState<ITicketADO>({
        Organization: "",
        Project: "",
        EpicId: "",
        FeatureId: "",
        RequirementId: "",
        UserStoryId: "",
        WorkItemType: "",
        AssignToType: "Assign to a default person",
        AssignTo: "",
        Priority: "3",
        Severity: "3",
        Area: "",
        AreaId: "",
        Iteration: "",
        IterationId: "",
        TitlePattern: TicketPattern.Title,
        DescriptionPattern: TicketPattern.Description,
        WorkItemTitlePattern: getWorkItemPatternValue(
            props.ticketADO?.WorkItemTitlePattern,
            props.metadataSchema,
            props.algoType,
            LMCDataSetTicketPattern.Title,
            props.ticketADO?.WorkItemTitlePattern? props.ticketADO?.WorkItemTitlePattern: RemediateTicketPattern.Title,
            props.RemediateItOrNot
            ),
          WorkItemDescriptionPattern: getWorkItemPatternValue(
            props.ticketADO?.WorkItemDescriptionPattern,
            props.metadataSchema,
            props.algoType,
            LMCDataSetTicketPattern.Description,
            props.ticketADO?.WorkItemDescriptionPattern? props.ticketADO?.WorkItemDescriptionPattern: RemediateTicketPattern.Description,
            props.RemediateItOrNot
            ),
        Tags: "",
        CreateChildTasksOrNot: false,
        MaximumChildTasks: "100",
        OrderBy: "",
        HasAttachmentOrNot: false,
        ChildTaskAssignToType: "Assign to a default person",
        ChildTaskAssignTo: "",
        ChildTaskAssignToFields: [],
        OtherFields: [{
            FieldId: 0,
            FieldName: '',
            FieldValue: ''
        }]
    })

    const [isLoading, setIsLoading] = useState<boolean>(false)

    /** Initialize ticket ADO dropdown state */
    const [ticketADODropdownState, setTicketADODropdownState] = useState<ITicketADODropdownState>({
        DevOpsURLItems: [],
        EpicItems: [],
        FeatureItems: [],
        RequirementItems: [],
        UserStoryItems: [],
        AreaPathItems: [],
        IterationsItems: [],
        WorkItemTypes: [],
        OrderByItems: []
    })

    /** Initialize control error message */
    const [inputErrorState, setInputErrorState] = useState<IInputErrorState>({
        showDevOpsUrlError: false,
        showEpicError: false,
        showFeatureError: false,
        showRequirementError: false,
        showWorkItemTypeError: false,
        DevOpsUrlErrorMessage: "",
        EpicErrorMessage: "",
        FeatureErrorMessage: "",
        RequirementErrorMessage: "",
        WorkItemTypeErrorMessage: ""
    })

    const [dropdownList, setDropdownList] = useState<ITicketAssignToFields[]>(props.ticketADO?.ChildTaskAssignToFields)

    useEffect(() => {
        const loadInitData = async () => {
            setIsLoading(true)
            await props.getTicketDevOpsConfig()
            setIsLoading(false)
        }

        if (!props.ticketDevOpsConfig || props.ticketDevOpsConfig.length == 0) {
            loadInitData()
        }
    }, [])

    useEffect(() => {
        trackingSystemSelection.select(0)
        orderBySelection.select(0)
        taskCountSelection.select(5)

        devOpsUrlSelection.clear()
        epicIdSelection.clear()
        featureIdSelection.clear()
        requirementIdSelection.clear()
        userStoryIdSelection.clear()
        workItemTypeSelection.clear()

        assignToTypeSelection.select(0)
        childAssignToTypeSelection.select(0)
        childAssignToFieldSelection.select(0)

        /** when the value has been saved */
        if (props.ticketADO && props.ticketADO.Organization && props.ticketADO.Organization !== "") {
            // for old ticket
            let defaultOtherFields = [{
                FieldId: 0,
                FieldName: '',
                FieldValue: ''
            }]

            setTicketADOState({
                Organization: props.ticketADO.Organization,
                Project: props.ticketADO.Project,

                EpicId: props.ticketADO.EpicId,
                FeatureId: props.ticketADO.FeatureId,
                RequirementId: props.ticketADO.RequirementId,
                UserStoryId: props.ticketADO.UserStoryId,
                WorkItemType: props.ticketADO.WorkItemType,

                AssignToType: props.ticketADO.AssignToType,
                AssignTo: props.ticketADO.AssignTo,

                Priority: props.ticketADO.Priority,
                Severity: props.ticketADO.Severity,
                Area: props.ticketADO.Area,
                AreaId: props.ticketADO.AreaId,
                Iteration: props.ticketADO.Iteration,
                IterationId: props.ticketADO.IterationId,
                TitlePattern: props.ticketADO.TitlePattern,
                DescriptionPattern: props.ticketADO.DescriptionPattern,
                WorkItemTitlePattern: getWorkItemPatternValue(
                    props.ticketADO?.WorkItemTitlePattern,
                    props.metadataSchema,
                    props.algoType,
                    LMCDataSetTicketPattern.Title,
                    props.ticketADO?.WorkItemTitlePattern? props.ticketADO?.WorkItemTitlePattern:RemediateTicketPattern.Title,
                    props.RemediateItOrNot
                    ),
                  WorkItemDescriptionPattern: getWorkItemPatternValue(
                    props.ticketADO?.WorkItemDescriptionPattern,
                    props.metadataSchema,
                    props.algoType,
                    LMCDataSetTicketPattern.Description,
                    props.ticketADO?.WorkItemDescriptionPattern? props.ticketADO?.WorkItemDescriptionPattern: RemediateTicketPattern.Description,
                    props.RemediateItOrNot
                    ),
                Tags: props.ticketADO.Tags,
                CreateChildTasksOrNot: props.ticketADO.CreateChildTasksOrNot,
                MaximumChildTasks: props.ticketADO.MaximumChildTasks,
                OrderBy: props.ticketADO.OrderBy,
                HasAttachmentOrNot: props.ticketADO.HasAttachmentOrNot,
                ChildTaskAssignToType: props.ticketADO.ChildTaskAssignToType,
                ChildTaskAssignTo: props.ticketADO.ChildTaskAssignTo,
                ChildTaskAssignToFields: props.ticketADO.ChildTaskAssignToFields,
                OtherFields: (props.ticketADO?.OtherFields === null || props.ticketADO?.OtherFields?.length === 0) ? defaultOtherFields : props.ticketADO.OtherFields
            })

            // load dropdown list items
            let devOpsUrlItems = initializeDevOpsUrlItems()
            let epicItems = initializeEpicItems(props.ticketADO.Organization, props.ticketADO.Project)
            let featureItems = initializeFeatureItems(props.ticketADO.Organization, props.ticketADO.Project, props.ticketADO.EpicId?.toString())
            let requirementItems = initializeRequirementItems(props.ticketADO.Organization, props.ticketADO.Project, props.ticketADO.EpicId?.toString(), props.ticketADO.FeatureId?.toString())
            let userStroyItems = initializeUserStoryItems(props.ticketADO.Organization, props.ticketADO.Project, props.ticketADO.EpicId?.toString(), props.ticketADO.FeatureId?.toString())
            let areaPathItems = initializeAreaPath(props.ticketADO.Organization, props.ticketADO.Project)
            let iterationsItems = initializeIterations(props.ticketADO.Organization, props.ticketADO.Project)
            let workItemTypesItems = initializeWorkItemTypes(props.ticketADO.Organization, props.ticketADO.Project)
            let orderByItems = initializeOrderByItems()
            let assignToCopyItems = initializeAssignToCopyItems()

            setTicketADODropdownState({
                DevOpsURLItems: devOpsUrlItems,
                EpicItems: epicItems,
                FeatureItems: featureItems,
                RequirementItems: requirementItems,
                UserStoryItems: userStroyItems,
                AreaPathItems: areaPathItems,
                IterationsItems: iterationsItems,
                WorkItemTypes: workItemTypesItems,
                OrderByItems: orderByItems,
                AssignToCopyItem: assignToCopyItems
            })

            // set dropdown list default value
            devOpsUrlSelection.select(getDropdwonSelectIndex(devOpsUrlItems, `${props.ticketADO.Organization}/${props.ticketADO.Project}`))
            if (props.ticketADO.EpicId != null && props.ticketADO.EpicId?.toString() !== '') {
                epicIdSelection.select(getDropdwonSelectIndex(epicItems, props.ticketADO.EpicId?.toString()))
            }
            if (props.ticketADO.FeatureId != null && props.ticketADO.FeatureId?.toString() !== '') {
                featureIdSelection.select(getDropdwonSelectIndex(featureItems, props.ticketADO.FeatureId?.toString()))
            }

            if (props.ticketADO.RequirementId != null && props.ticketADO.RequirementId?.toString() !== '') {
                requirementIdSelection.select(getDropdwonSelectIndex(requirementItems, props.ticketADO.RequirementId?.toString()))
            }

            if (props.ticketADO.UserStoryId != null && props.ticketADO.UserStoryId?.toString() !== '') {
                userStoryIdSelection.select(getDropdwonSelectIndex(userStroyItems, props.ticketADO.UserStoryId?.toString()))
            }

            if (props.ticketADO.WorkItemType != null && props.ticketADO.WorkItemType?.toString() !== '') {
                workItemTypeSelection.select(getDropdwonSelectIndex(workItemTypesItems, props.ticketADO.WorkItemType?.toString()))
            }

            // set field dropdown list default value
            assignToTypeSelection.select(getDropdwonSelectIndex(assignToTypeParentItems, props.ticketADO.AssignToType?.toString()))
            childAssignToTypeSelection.select(getDropdwonSelectIndex(assignToTypeChildItems, props.ticketADO.ChildTaskAssignToType?.toString()))
            priorityFieldSelection.select(getDropdwonSelectIndex(PriorityItems, props.ticketADO.Priority?.toString()))
            severityFieldSelection.select(getDropdwonSelectIndex(PriorityItems, props.ticketADO.Severity?.toString()))
            areaFieldSelection.select(getDropdwonSelectIndex(areaPathItems, props.ticketADO.AreaId?.toString()))
            iterationsFieldSelection.select(getDropdwonSelectIndex(iterationsItems, props.ticketADO.IterationId?.toString()))

            if (props.ticketADO.ChildTaskAssignToType === "Assign to the person copying the value from field") {
                childAssignToFieldSelection.select(getDropdwonSelectIndex(assignToCopyItems, props.ticketADO.ChildTaskAssignTo?.toString()))
            }

            // set advanced default value
            orderBySelection.select(getDropdwonSelectIndex(orderByItems, props.ticketADO.OrderBy))
            taskCountSelection.select(getDropdwonSelectIndex(taskCountItems, props.ticketADO.MaximumChildTasks))
            createChildToggle.value = props.ticketADO.CreateChildTasksOrNot
            hasAttachmentToggle.value = props.ticketADO.HasAttachmentOrNot
        }
        else {
            // load dropdown list items   
            if (props.ticketTypeId === CommonConstants.CurationRecommendedTicketTypeId) {
                // load devops project url items
                setTicketADODropdownState({
                    ...ticketADODropdownState,
                    DevOpsURLItems: initializeDevOpsUrlItems(),
                    EpicItems: initializeEpicItems("KMContent", "Automatic Content Creation Tool"),
                    AreaPathItems: initializeAreaPath("KMContent", "Automatic Content Creation Tool"),
                    IterationsItems: initializeIterations("KMContent", "Automatic Content Creation Tool"),
                    WorkItemTypes: initializeWorkItemTypes("KMContent", "Automatic Content Creation Tool"),
                    OrderByItems: initializeOrderByItems(),
                    AssignToCopyItem: initializeAssignToCopyItems()
                })

                // store the organization and project
                setTicketADOState({
                    ...ticketADOState,
                    AssignTo: userAccount.username,
                    ChildTaskAssignTo: userAccount.username,
                    Organization: "KMContent",
                    Project: "Automatic Content Creation Tool"
                })

                devOpsUrlSelection.select(getDropdwonSelectIndex(initializeDevOpsUrlItems(), `KMContent/Automatic Content Creation Tool`))
            }
            else {
                // load devops project url items
                setTicketADODropdownState({
                    ...ticketADODropdownState,
                    DevOpsURLItems: initializeDevOpsUrlItems(),
                    OrderByItems: initializeOrderByItems(),
                    AssignToCopyItem: initializeAssignToCopyItems()
                })

                // set assign to default value
                setTicketADOState({
                    ...ticketADOState,
                    AssignTo: userAccount.username,
                    ChildTaskAssignTo: userAccount.username,
                })
            }

            // set fields default value
            priorityFieldSelection.select(2)
            severityFieldSelection.select(2)
            areaFieldSelection.select(0)
            iterationsFieldSelection.select(0)

            // set advanced default value
            createChildToggle.value = false
            hasAttachmentToggle.value = false
        }

        return () => {
            if (_ticketTimer) {
                clearTimeout(_ticketTimer)
            }
        }

    }, [props.ticketDevOpsConfig])

    /**
     * initialize devops url dropdown list
     * @returns 
     */
    function initializeDevOpsUrlItems() {
        let devOpsUrlList: ITicketADODropdownItem[] = []
        if (props.ticketDevOpsConfig && props.ticketDevOpsConfig.length > 0) {
            let newTicketDevOpsConfig = props.ticketDevOpsConfig.filter(item => {
                if (props.ticketTypeId === CommonConstants.CurationRecommendedTicketTypeId) {
                    return item.Organization === "KMContent"
                }
                else {
                    return item.Organization !== "KMContent"
                }
            })

            newTicketDevOpsConfig.map(item => {
                devOpsUrlList.push({
                    id: `${item.Organization}/${item.Project}`,
                    text: `https://dev.azure.com/${item.Organization}/${item.Project}`,
                })
            })
        }

        return devOpsUrlList
    }

    /**
     * initialize Epic dropdown list
     * @returns 
     */
    function initializeEpicItems(organization: string, project: string) {
        if (props.ticketDevOpsConfig && props.ticketDevOpsConfig.length > 0) {
            const devOpsUrlItem = props.ticketDevOpsConfig.find((item) => {
                return (item.Organization === organization && item.Project === project)
            })

            if (devOpsUrlItem && devOpsUrlItem.Epics) {
                let epicList: any[] = []
                for (var i = 0; i < devOpsUrlItem.Epics.length; i++) {
                    epicList.push({
                        id: devOpsUrlItem.Epics[i].EpicId,
                        text: `${devOpsUrlItem.Epics[i].EpicName} (${devOpsUrlItem.Epics[i].EpicId})`
                    })
                }

                return epicList
            }
        }

        return []
    }

    /**
     * initialize Feature dropdown list
     * @param organization 
     * @param project 
     * @param epicId 
     * @returns 
     */
    function initializeFeatureItems(organization: string, project: string, epicId: string) {
        if (epicId === "") {
            return []
        }

        if (props.ticketDevOpsConfig && props.ticketDevOpsConfig.length > 0) {
            const devOpsUrlItem = props.ticketDevOpsConfig.find((item) => {
                return (item.Organization === organization && item.Project === project)
            })

            if (devOpsUrlItem && devOpsUrlItem.Epics) {
                // Get selected Epic
                const epicItem = devOpsUrlItem.Epics.find((item) => { return item.EpicId?.toString() === epicId?.toString() })

                if (epicItem && epicItem.Features) {
                    let featureItems: any[] = []

                    for (var i = 0; i < epicItem.Features.length; i++) {
                        featureItems.push({
                            id: epicItem.Features[i].FeatureId,
                            text: `${epicItem.Features[i].FeatureName} (${epicItem.Features[i].FeatureId})`
                        })
                    }

                    return featureItems
                }
            }
        }

        return []
    }

    /**
     * initialize Epic dropdown list through the Epic Id and Feature Id
     * @param featureId 
     */
    function initializeRequirementItems(organization: string, project: string, epicId: string, featureId: string) {
        if (epicId === "" || featureId === "") {
            return []
        }

        if (props.ticketDevOpsConfig && props.ticketDevOpsConfig.length > 0) {
            const devOpsUrlItem = props.ticketDevOpsConfig.find((item) => {
                return (item.Organization === organization && item.Project === project)
            })

            if (devOpsUrlItem && devOpsUrlItem.Epics) {
                const epicItem = devOpsUrlItem.Epics.find((item) => { return item.EpicId?.toString() === epicId?.toString() })

                if (epicItem && epicItem.Features) {
                    let requirementItems: any[] = []

                    const featureItem = epicItem.Features.find(item => { return item.FeatureId?.toString() === featureId?.toString() })
                    if (featureItem && featureItem.Requirements) {
                        for (var i = 0; i < featureItem.Requirements.length; i++) {
                            requirementItems.push({
                                id: featureItem.Requirements[i].RequirementsId,
                                text: `${featureItem.Requirements[i].RequirementsName} (${featureItem.Requirements[i].RequirementsId})`
                            })
                        }

                        return requirementItems
                    }
                }
            }
        }

        return []
    }

    /**
     * initialize User Story dropdown list through the organization and project and epicId and Feature Id
     * @param organization 
     * @param project 
     * @param epicId 
     * @param featureId 
     * @returns 
     */
    function initializeUserStoryItems(organization: string, project: string, epicId: string, featureId: string) {
        if (epicId === "" || featureId === "") {
            return []
        }

        if (props.ticketDevOpsConfig && props.ticketDevOpsConfig.length > 0) {
            const devOpsUrlItem = props.ticketDevOpsConfig.find((item) => {
                return (item.Organization === organization && item.Project === project)
            })

            if (devOpsUrlItem && devOpsUrlItem.Epics) {
                const epicItem = devOpsUrlItem.Epics.find((item) => { return item.EpicId?.toString() === epicId?.toString() })

                if (epicItem && epicItem.Features) {
                    let userStoryItems: any[] = []

                    const featureItem = epicItem.Features.find(item => { return item.FeatureId?.toString() === featureId?.toString() })

                    if (featureItem && featureItem.UserStories) {
                        for (var i = 0; i < featureItem.UserStories.length; i++) {
                            userStoryItems.push({
                                id: featureItem.UserStories[i].UserStoryId,
                                text: `${featureItem.UserStories[i].UserStoryName} (${featureItem.UserStories[i].UserStoryId})`
                            })
                        }

                        return userStoryItems
                    }
                }
            }
        }

        return []
    }

    /**
     * initialize devops url dropdown list
     * @returns 
     */
    function initializeWorkItemTypes(organization: string, project: string) {

        if (props.ticketDevOpsConfig && props.ticketDevOpsConfig.length > 0) {
            const devOpsUrlItem = props.ticketDevOpsConfig.find((item) => {
                return (item.Organization === organization && item.Project === project)
            })

            if (devOpsUrlItem && devOpsUrlItem.WorkItemTypes) {
                let workItemTypes: any[] = []
                for (var i = 0; i < devOpsUrlItem.WorkItemTypes.length; i++) {
                    workItemTypes.push({
                        id: devOpsUrlItem.WorkItemTypes[i],
                        text: devOpsUrlItem.WorkItemTypes[i]
                    })
                }

                return workItemTypes
            }
        }

        return []
    }

    /**
     * initialize order by dropdown list
     * @returns 
     */
    function initializeOrderByItems() {
        let orderByItems: ITicketADODropdownItem[] = []
        if (props.metadataFields && props.metadataFields.length > 0) {
            props.metadataFields.map(item => {
                orderByItems.push({
                    id: item.FieldName,
                    text: item.DisplayName
                })
            })
        }

        return orderByItems
    }

    /**
     * initialize order by dropdown list
     * @returns 
     */
    function initializeAssignToCopyItems() {
        let assignToCopyItems: ITicketADODropdownItem[] = []
        const selectMetadataSchema = props.indexList?.filter(item => item.metadataSchema === props.metadataSchema)
        selectMetadataSchema[0]?.TicketAssignToFields?.forEach(item => {
            assignToCopyItems.push({
                id: item,
                text: item
            })
        })

        return assignToCopyItems
    }

    /**
     * initialize Area Path dropdown list through organization and project
     * @param organization 
     * @param project 
     */
    function initializeAreaPath(organization: string, project: string) {
        if (props.ticketDevOpsConfig && props.ticketDevOpsConfig.length > 0) {
            const devOpsUrlItem = props.ticketDevOpsConfig.find((item) => {
                return (item.Organization === organization && item.Project === project)
            })

            if (devOpsUrlItem && devOpsUrlItem.Areas) {
                let areaPathItems: any[] = []
                for (var i = 0; i < devOpsUrlItem.Areas.length; i++) {
                    areaPathItems.push({
                        id: devOpsUrlItem.Areas[i].Id,
                        text: `${devOpsUrlItem.Areas[i].Name}`
                    })
                }

                return areaPathItems
            }
        }

        return []
    }

    /**
     * initialize Area Path dropdown list through organization and project
     * @param organization 
     * @param project 
     * @returns 
     */
    function initializeIterations(organization: string, project: string) {
        if (props.ticketDevOpsConfig && props.ticketDevOpsConfig.length > 0) {
            const devOpsUrlItem = props.ticketDevOpsConfig.find((item) => {
                return (item.Organization === organization && item.Project === project)
            })

            if (devOpsUrlItem && devOpsUrlItem.Iterations) {
                let iterationsItems: any[] = []
                for (var i = 0; i < devOpsUrlItem.Iterations.length; i++) {
                    iterationsItems.push({
                        id: devOpsUrlItem.Iterations[i].Id,
                        text: `${devOpsUrlItem.Iterations[i].Name}`
                    })
                }

                return iterationsItems
            }
        }

        return []
    }

    /**
     * get dropdown select index
     * @param dowpdownItems 
     * @param itemId 
     * @returns 
     */
    function getDropdwonSelectIndex(dowpdownItems: ITicketADODropdownItem[], itemId: string): number {
        if (dowpdownItems && dowpdownItems.length > 0 && itemId !== "") {
            let dropdownSelectIndex = dowpdownItems.findIndex(item => item.id?.toString() === itemId?.toString());

            return dropdownSelectIndex >= 0 ? dropdownSelectIndex : 0
        }

        return 0;
    }

    /**
     * Take Organization and Project from DevOps Project Url
     * @param organizationAndProject 
     * @param splitType 
     * @returns 
     */
    function getOrganizationOrProject(organizationAndProject: string, splitType: "Organization" | "Project") {
        if (organizationAndProject === "") {
            return ""
        }

        const splitItems = organizationAndProject.split("/")
        if (splitItems.length < 2) {
            return ""
        }

        if (splitType === "Organization") {
            return splitItems[0]
        }
        else {
            return splitItems[1]
        }
    }

    /**
     * Collapse or expand the advanced
     */
    function onCollapseTicketAdvancedClicked() {
        collapsedTicketAdvanced.value = !collapsedTicketAdvanced.value;
    }

    /**
     * Collapse or expand the fields
     */
    function onCollapseTicketFieldsClicked() {
        collapsedTicketFields.value = !collapsedTicketFields.value;
    }

    /**
     * handle devOps project url selected
     * @param event 
     * @param item 
     * @returns 
     */
    function onDevOpsUrlSelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<any>) {
        const organization = getOrganizationOrProject(item.id, "Organization")
        const project = getOrganizationOrProject(item.id, "Project")

        // reset the error state
        if (organization !== "") {
            setInputErrorState({
                ...inputErrorState,
                showDevOpsUrlError: false,
                DevOpsUrlErrorMessage: ""
            })
        }

        // no change
        if (organization === ticketADOState.Organization &&
            project === ticketADOState.Project) {
            return
        }

        // load epic items
        setTicketADODropdownState({
            ...ticketADODropdownState,
            EpicItems: initializeEpicItems(organization, project),
            AreaPathItems: initializeAreaPath(organization, project),
            IterationsItems: initializeIterations(organization, project),
            WorkItemTypes: initializeWorkItemTypes(organization, project),
            FeatureItems: [],
            RequirementItems: [],
            UserStoryItems: []
        })

        // store the organization and project
        setTicketADOState({
            ...ticketADOState,
            Organization: organization,
            Project: project,
            EpicId: "",
            FeatureId: "",
            RequirementId: "",
            UserStoryId: "",
            WorkItemType: ""
        })

        // clear epic/feature/requirement
        _ticketTimer = setTimeout(() => {
            epicIdSelection.select(0)
            featureIdSelection.select(0)
            requirementIdSelection.select(0)
            userStoryIdSelection.select(0)
            workItemTypeSelection.select(0)
            epicIdSelection.clear()
            featureIdSelection.clear()
            requirementIdSelection.clear()
            userStoryIdSelection.clear()
            workItemTypeSelection.clear()
        }, 100)
    }

    /**
     * handle epic selected
     * @param event 
     * @param item 
     * @returns 
     */
    function onEpicSelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<any>) {
        // reset the error state
        if (item.id !== "") {
            setInputErrorState({
                ...inputErrorState,
                showEpicError: false,
                EpicErrorMessage: ""
            })
        }

        // Do not re-find features when Epic Id has not changed
        if (ticketADOState.EpicId === item.id) {
            return
        }

        // load feature items
        setTicketADODropdownState({
            ...ticketADODropdownState,
            FeatureItems: initializeFeatureItems(ticketADOState.Organization, ticketADOState.Project, item.id),
            RequirementItems: [],
            UserStoryItems: [],
        })

        // store the EpicId
        setTicketADOState({
            ...ticketADOState,
            EpicId: item.id,
            FeatureId: "",
            RequirementId: "",
            UserStoryId: ""
        })

        // clear feature/requirement
        featureIdSelection.select(0)
        requirementIdSelection.select(0)
        userStoryIdSelection.select(0)
        featureIdSelection.clear()
        requirementIdSelection.clear()
        userStoryIdSelection.clear()
    }

    /**
     * handle feature selected
     * @param event 
     * @param item 
     */
    function onFeatureSelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<any>) {
        // reset the error state
        if (item.id !== "") {
            setInputErrorState({
                ...inputErrorState,
                showFeatureError: false,
                FeatureErrorMessage: ""
            })
        }

        let requirementItems = initializeRequirementItems(ticketADOState.Organization, ticketADOState.Project, ticketADOState.EpicId, item.id)
        let userStoryItems = initializeUserStoryItems(ticketADOState.Organization, ticketADOState.Project, ticketADOState.EpicId, item.id)

        // when only user story exsit, show the user story dropdown
        if (userStoryItems && userStoryItems.length > 0 && (!requirementItems || requirementItems.length === 0)) {
            setTicketADODropdownState({
                ...ticketADODropdownState,
                RequirementItems: [],
                UserStoryItems: initializeUserStoryItems(ticketADOState.Organization, ticketADOState.Project, ticketADOState.EpicId, item.id)
            })

            userStoryIdSelection.select(0)
            userStoryIdSelection.clear()
        }
        else {
            // load requirementItems items
            setTicketADODropdownState({
                ...ticketADODropdownState,
                UserStoryItems: [],
                RequirementItems: initializeRequirementItems(ticketADOState.Organization, ticketADOState.Project, ticketADOState.EpicId, item.id)
            })

            // clear requirement
            requirementIdSelection.select(0)
            requirementIdSelection.clear()
        }

        // store the feature
        setTicketADOState({
            ...ticketADOState,
            FeatureId: item.id,
            RequirementId: "",
            UserStoryId: ""
        })
    }

    /**
     * handle requirement selected
     * @param event 
     * @param item 
     */
    function onRequirementSelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<any>) {
        // reset the error state
        if (item.id !== "") {
            setInputErrorState({
                ...inputErrorState,
                showRequirementError: false,
                RequirementErrorMessage: ""
            })
        }

        // store the requirement and project
        setTicketADOState({
            ...ticketADOState,
            RequirementId: item.id,
            UserStoryId: ""
        })
    }

    /**
     * handle user story selected
     * @param event 
     * @param item 
     */
    function onUserStorySelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<any>) {
        // store the user story id
        setTicketADOState({
            ...ticketADOState,
            UserStoryId: item.id,
            RequirementId: ""
        })
    }

    /**
     * handle work item type selected
     * @param event 
     * @param item 
     */
    function onWorkItemTypeSelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<any>) {
        // reset the error state
        if (item.id !== "") {
            setInputErrorState({
                ...inputErrorState,
                showWorkItemTypeError: false,
                WorkItemTypeErrorMessage: ""
            })
        }

        // store the work item type
        setTicketADOState({
            ...ticketADOState,
            WorkItemType: item.id
        })
    }

    /**
     * handle assign to change
     * @param data 
     */
    function onAssignToChange(data: string) {
        setTicketADOState({
            ...ticketADOState,
            AssignTo: data
        })
    }

    /**
     * handle assign to change
     * @param data 
     */
    function onChildAssignToChange(data: string) {
        setTicketADOState({
            ...ticketADOState,
            ChildTaskAssignTo: data
        })
    }

    /**
     * handle field dropdown list select event
     * @param fieldName 
     * @returns 
     */
    function onDropdownListSelect(fieldName: string) {
        return (event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<any>) => {
                        switch (fieldName) {
                case "AssignToType":
                    let assignTo = userAccount.username
                    if (item.id === "Leave it as default (usually empty or Active on Azure DevOps)") {
                        assignTo = ""
                    }
                    setTicketADOState({
                        ...ticketADOState,
                        AssignTo: assignTo,
                        AssignToType: item.id
                    })
                    break;
                case "ChildTaskAssignToType":
                    let childAssignTo = userAccount.username
                    if (item.id === "Leave it as default (usually empty or Active on Azure DevOps)") {
                        childAssignTo = ""
                    }
                    else if (item.id === "Assign to the person copying the value from field") {
                        if (props.metadataSchema.startsWith("gitpub-metadata-schema-others-")) {
                            childAssignTo = "Referrer Owner"
                        }
                        if (props.metadataSchema?.startsWith("gitpub-metadata-schema-lmc-")) {
                            childAssignTo = "Author"
                        }
                        else {
                            childAssignTo = "Article Creator"
                        }
                    }

                    setTicketADOState({
                        ...ticketADOState,
                        ChildTaskAssignTo: childAssignTo,
                        ChildTaskAssignToType: item.id
                    })
                    break;
                case "Priority":
                    setTicketADOState({
                        ...ticketADOState,
                        Priority: item.id
                    })
                    break;
                case "Severity":
                    setTicketADOState({
                        ...ticketADOState,
                        Severity: item.id
                    })
                    break;
                case "Area":
                    setTicketADOState({
                        ...ticketADOState,
                        Area: item.text,
                        AreaId: item.id
                    })
                    break;
                case "Iteration":
                    setTicketADOState({
                        ...ticketADOState,
                        Iteration: item.text,
                        IterationId: item.id
                    })
                    break;
                case "ChildTaskAssignToField":
                    setTicketADOState({
                        ...ticketADOState,
                        ChildTaskAssignTo: item.id
                    })
                    break;

                default:
                    break;
            }
        }
    }

    /**
     * handle field text box change event
     * @param fieldName 
     * @param event 
     */
    function onTextValueChange(fieldName: string, event: any) {
        switch (fieldName) {
            case "TitlePattern":
                setTicketADOState({
                    ...ticketADOState,
                    TitlePattern: event.target.value
                })
                break;
            case "DescriptionPattern":
                setTicketADOState({
                    ...ticketADOState,
                    DescriptionPattern: event.target.value
                })
                break;
            case "Tags":
                setTicketADOState({
                    ...ticketADOState,
                    Tags: event.target.value
                })
                break;
            case "CustomAssignTo":
                setTicketADOState({
                    ...ticketADOState,
                    AssignTo: event.target.value
                })
                break;
            case "CustomChildAssignTo":
                setTicketADOState({
                    ...ticketADOState,
                    ChildTaskAssignTo: event.target.value
                })
                break;
            default:
                break;
        }
    }

    /**
     * handle order by select
     * @param event 
     * @param item 
     */
    function onOderBySelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<any>) {
        setTicketADOState({
            ...ticketADOState,
            OrderBy: item.id
        })
    }

    /**
     * handle order by select
     * @param event 
     * @param item 
     */
    function onTaskCountSelect(event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<any>) {
        setTicketADOState({
            ...ticketADOState,
            MaximumChildTasks: item.id
        })
    }

    /**
     * handle create child tasks click
     * @param event 
     * @param newValue 
     */
    function onCreateChildTasksClick(event: any, newValue: boolean) {
        let orderBy = "";
        if (newValue && ticketADODropdownState.OrderByItems && ticketADODropdownState.OrderByItems.length > 0) {
            orderBy = ticketADODropdownState.OrderByItems[0].id
        }

        createChildToggle.value = newValue
        setTicketADOState({
            ...ticketADOState,
            OrderBy: orderBy,
            CreateChildTasksOrNot: newValue
        })
    }

    // add other field
    function handleAddOtherField() {
        // let otherFields = [...otherFieldsState]
        let otherFields = [...ticketADOState.OtherFields]
        let maxFieldItem = otherFields.reduce((prev: IOtherField, next: IOtherField) => {
            return prev.FieldId > next.FieldId ? prev : next
        });
        let maxFieldId = maxFieldItem ? maxFieldItem.FieldId : 0;

        otherFields.push({
            FieldId: maxFieldId + 1,
            FieldName: '',
            FieldValue: ''
        })

        setTicketADOState({
            ...ticketADOState,
            OtherFields: otherFields
        })
    }

    // delete other field
    function handleDeleteOtherField() {
        let otherFields = [...ticketADOState.OtherFields]
        let maxFieldItem = otherFields.reduce((prev: IOtherField, next: IOtherField) => {
            return prev.FieldId > next.FieldId ? prev : next
        });
        let maxFieldId = maxFieldItem ? maxFieldItem.FieldId : 0;
        if (maxFieldId === 0) {
            return
        }

        otherFields.pop()

        setTicketADOState({
            ...ticketADOState,
            OtherFields: otherFields
        })
    }

    /**
     * handle other field name text change
     * @param fieldId 
     * @param event 
     */
    function onOtherFieldNameTextChange(fieldId: number, event: any) {
        let otherFields = [...ticketADOState.OtherFields]
        var fieldItem = otherFields.find(item => item.FieldId === fieldId);
        fieldItem.FieldName = event.target.value

        setTicketADOState({
            ...ticketADOState,
            OtherFields: otherFields
        })
    }

    /**
     * handle other field value text change
     * @param fieldId 
     * @param event 
     */
    function onOtherFieldValueTextChange(fieldId: number, event: any) {
        let otherFields = [...ticketADOState.OtherFields]
        var fieldItem = otherFields.find(item => item.FieldId === fieldId);
        fieldItem.FieldValue = event.target.value

        setTicketADOState({
            ...ticketADOState,
            OtherFields: otherFields
        })
    }

    // render other fields element
    function renderOtherFieldsElement(): any {
        if (ticketADOState && ticketADOState.OtherFields && ticketADOState.OtherFields.length > 0) {
            return ticketADOState.OtherFields.map((item, index) => {
                if (index === 0) {
                    return (<tr>
                        <td rowSpan={ticketADOState?.OtherFields?.length}>Other Fields</td>
                        <td>
                            <input type="text" placeholder='Enter field name' multiple={true} onChange={(event) => {
                                onOtherFieldNameTextChange(0, event)
                            }} value={item.FieldName} style={{ width: "30%", height: "30px" }} />
                            <input type="text" placeholder='Enter field value' multiple={true} onChange={(event) => {
                                onOtherFieldValueTextChange(0, event)
                            }} value={item.FieldValue} style={{ width: "48%", height: "30px", marginLeft: "2%" }} />

                            <button onClick={handleAddOtherField} style={{ marginLeft: "1%" }}>
                                <Icon aria-label="Add" iconName="Add" />
                            </button>

                            <button onClick={handleDeleteOtherField} style={{ marginLeft: "1%" }}>
                                <Icon aria-label="Delete" iconName="Delete" />
                            </button>

                        </td>
                    </tr>)
                }
                else if (index > 0) {
                    return (
                        <tr>
                            <td>
                                <input type="text" placeholder='Enter field name' multiple={true} onChange={(event) => {
                                    onOtherFieldNameTextChange(index, event)
                                }} value={item.FieldName} style={{ width: "30%", height: "30px" }} />
                                <input type="text" placeholder='Enter field value' multiple={true} onChange={(event) => {
                                    onOtherFieldValueTextChange(index, event)
                                }} value={item.FieldValue} style={{ width: "48%", height: "30px", marginLeft: "2%" }} />
                            </td>
                        </tr>
                    )
                }

            })
        }

        return null
    }

    /**
     * build fields table
     * @returns 
     */
    function buildFieldsTable(): JSX.Element {
        return (
            <table id="customers">
                <tr>
                    <th>Field name</th>
                    <th>Field value</th>
                </tr>
                <tr>
                    <td style={{ width: "300px" }}>Assign To</td>
                    <td>
                        <Dropdown
                            ariaLabel="Basic"
                            className="sonic-ticketADO-feild-assignTo-dropdown"
                            items={assignToTypeParentItems}
                            onSelect={onDropdownListSelect("AssignToType")}
                            selection={assignToTypeSelection}
                        />
                        {
                            (ticketADOState.AssignToType === "Assign to a default person" || ticketADOState.AssignToType === "Assign to the curation owner") ?
                                <SingleIdentityPickerExtensionV2
                                    className='msacct-single-identity-picker-400'
                                    DefaultUserMail={ticketADOState.AssignTo}
                                    onIdentityPickerChange={onAssignToChange} />
                                : (ticketADOState.AssignToType === "Assign to an Azure DevOps group") ?
                                    <div style={{ float: "left", width: "390px" }}>
                                        <input type="text" multiple={true} onChange={(event) => {
                                            onTextValueChange("CustomAssignTo", event)
                                        }} value={ticketADOState.AssignTo} style={{ width: "100%", height: "34px" }} />
                                    </div>
                                    :
                                    null
                        }
                    </td>
                </tr>
                <tr>
                    <td>Priority</td>
                    <td>
                        <Dropdown
                            ariaLabel="Basic"
                            className="sonic-ticketADO-feild-dropdown"
                            items={PriorityItems}
                            onSelect={onDropdownListSelect("Priority")}
                            selection={priorityFieldSelection}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Severity</td>
                    <td>
                        <Dropdown
                            ariaLabel="Basic"
                            className="sonic-ticketADO-feild-dropdown"
                            items={PriorityItems}
                            onSelect={onDropdownListSelect("Severity")}
                            selection={severityFieldSelection}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Area Path</td>
                    <td>
                        <Dropdown
                            ariaLabel="Basic"
                            className="sonic-ticketADO-feild-dropdown"
                            items={ticketADODropdownState.AreaPathItems}
                            onSelect={onDropdownListSelect("Area")}
                            selection={areaFieldSelection}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Iteration</td>
                    <td>
                        <Dropdown
                            ariaLabel="Basic"
                            className="sonic-ticketADO-feild-dropdown"
                            items={ticketADODropdownState.IterationsItems}
                            onSelect={onDropdownListSelect("Iteration")}
                            selection={iterationsFieldSelection}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Title Pattern</td>
                    <td>
                        <input type="text" multiple={true} onChange={(event) => {
                            onTextValueChange("TitlePattern", event)
                        }} value={ticketADOState.TitlePattern} style={{ width: "80%", height: "30px" }} />
                    </td>
                </tr>
                <tr>
                    <td>Description Pattern</td>
                    <td>
                        <textarea style={{ width: "80%", height: "50px" }}
                            value={ticketADOState.DescriptionPattern}
                            onChange={(event) => {
                                onTextValueChange("DescriptionPattern", event)
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Tags</td>
                    <td>
                        <input type="text" multiple={true} onChange={(event) => {
                            onTextValueChange("Tags", event)
                        }} value={ticketADOState.Tags} style={{ width: "80%", height: "30px" }} />
                    </td>
                </tr>
                {renderOtherFieldsElement()}
            </table>
        )
    }

    function onTicketWorkItemApply(titleValue: string, descriptionValue: string) {
        setTicketADOState({
            ...ticketADOState,
            WorkItemDescriptionPattern: descriptionValue,
            WorkItemTitlePattern: titleValue
        })
    }


    /**
     * handle apply button event
     * @returns 
     */
    function onTicketADOApply() {

        let showDevOpsUrlError = ticketADOState.Organization === ""
        let showEpicError = ticketADOState.EpicId === ""
        let showFeatureError = ticketADOState.FeatureId === ""
        let showRequirementError = ticketADOState.RequirementId === ""
        let showWorkItemTypeError = ticketADOState.WorkItemType === ""
        if (props.ticketTypeId !== CommonConstants.CurationRecommendedTicketTypeId) {
            showEpicError = false
            showFeatureError = false
            showRequirementError = false
        }

        let DevOpsUrlErrorMessage = ""
        let EpicErrorMessage = ""
        let FeatureErrorMessage = ""
        let RequirementErrorMessage = ""
        let WorkItemTypeErrorMessage = ""
        if (showDevOpsUrlError) {
            DevOpsUrlErrorMessage = "Azure DevOps Project URL is required!"
        }

        if (showEpicError) {
            EpicErrorMessage = "Epic is required!"
        }
        if (showFeatureError) {
            FeatureErrorMessage = "Feature is required!"
        }
        if (showRequirementError) {
            RequirementErrorMessage = "Requirement is required!"
        }

        if (showWorkItemTypeError) {
            WorkItemTypeErrorMessage = "Work Item Type is required!"
        }

        if (showDevOpsUrlError || showEpicError || showFeatureError || showRequirementError || showWorkItemTypeError) {
            setInputErrorState({
                showDevOpsUrlError: showDevOpsUrlError,
                showEpicError: showEpicError,
                showFeatureError: showFeatureError,
                showRequirementError: showRequirementError,
                DevOpsUrlErrorMessage: DevOpsUrlErrorMessage,
                EpicErrorMessage: EpicErrorMessage,
                FeatureErrorMessage: FeatureErrorMessage,
                RequirementErrorMessage: RequirementErrorMessage,
                showWorkItemTypeError: showWorkItemTypeError,
                WorkItemTypeErrorMessage: WorkItemTypeErrorMessage
            })

            return
        }

        // set Area default value
        let areaId = ticketADOState.AreaId
        let areaPath = ticketADOState.Area
        if (!areaId || areaId?.toString() === "") {
            let newAreaPathItems = ticketADODropdownState.AreaPathItems.slice(0, 1)
            if (newAreaPathItems && newAreaPathItems.length > 0) {
                areaId = newAreaPathItems[0].id
                areaPath = newAreaPathItems[0].text
            }
        }

        // set Iteration default value
        let iterationId = ticketADOState.IterationId
        let iteration = ticketADOState.Iteration
        if (!iterationId || iterationId?.toString() === "") {
            let newIterationItems = ticketADODropdownState.IterationsItems.slice(0, 1)
            if (newIterationItems && newIterationItems.length > 0) {
                iterationId = newIterationItems[0].id
                iteration = newIterationItems[0].text
            }
        }
        props.onTicketADOApply(ticketADOState, areaId, areaPath, iterationId, iteration, dropdownList)
        props.onTicketADODialogDimiss()
    }

    function getDefaultUsersOrder(configOrder: Array<{priority:number, currentSelect: string}>) {
        const configFields = []
        configOrder.forEach(item => {
            configFields.push({
                Priority: item.priority,
                FieldName: item.currentSelect
            })
        })
        setDropdownList(configFields)
    }

    return (
        <CustomDialog calloutContentClassName='msacct-algorithm-dialog-container' onDismiss={props.onTicketADODialogDimiss} modal={true} >
            <CustomHeader className="bolt-header-with-commandbar" separator>
                <HeaderTitleArea>
                    <div
                        className="flex-grow scroll-hidden"
                        style={{ marginRight: "16px" }}
                    >
                        <div
                            className="title-m"
                            style={{
                                height: "500px",
                                width: "500px",
                                maxHeight: "32px"
                            }}
                        >
                            {"Edit the work item template"}
                        </div>

                    </div>
                </HeaderTitleArea>
            </CustomHeader>
            <PanelContent>
                <div style={{ width: "100%" }}>
                    { isLoading && <Spinner className="ticket_ado_loading" size={SpinnerSize.large} label="Loading..."/> }
                    <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>
                        Select your Azure DevOps content ticket tracking system, enter in the requested parameters and we will configure a new template which you can use to help your teams create work items.
                    </div>
                    <div>
                        <FormItem label="Select your tracking system*" className='sonic-ticketADO-formitem' >
                            <Dropdown
                                ariaLabel="Basic"
                                className="example-dropdown"
                                items={[
                                    {
                                        id: "AzureDevOps",
                                        text: "Azure DevOps"
                                    }
                                ]}
                                disabled={true}
                                selection={trackingSystemSelection}
                            />
                        </FormItem>
                        <FormItem label="Azure DevOps Project URL*" className='sonic-ticketADO-formitem' error={inputErrorState.showDevOpsUrlError} message={inputErrorState.DevOpsUrlErrorMessage}>
                            <Dropdown
                                ariaLabel="Basic"
                                className="example-dropdown"
                                placeholder='Select a DevOps Project URL'
                                items={ticketADODropdownState.DevOpsURLItems}
                                onSelect={onDevOpsUrlSelect}
                                selection={devOpsUrlSelection}
                            />
                            <span className="bolt-formitem-message body-xs">Can't find your Azure DevOps project? Click <a href='https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=u3Y24H' target="_blank">here</a> to file a request</span>
                        </FormItem>
                        <FormItem
                            label={props.ticketTypeId === CommonConstants.CurationRecommendedTicketTypeId ? "Epic*" : "Epic"}
                            className='sonic-ticketADO-formitem'
                            error={inputErrorState.showEpicError}
                            message={inputErrorState.EpicErrorMessage}>
                            <Dropdown
                                ariaLabel="Basic"
                                className="example-dropdown"
                                placeholder='Select a Epic'
                                items={ticketADODropdownState.EpicItems}
                                onSelect={onEpicSelect}
                                selection={epicIdSelection}
                            />
                        </FormItem>
                        <FormItem
                            label={props.ticketTypeId === CommonConstants.CurationRecommendedTicketTypeId ? "Feature*" : "Feature"}
                            className='sonic-ticketADO-formitem'
                            error={inputErrorState.showFeatureError}
                            message={inputErrorState.FeatureErrorMessage}>
                            <Dropdown
                                ariaLabel="Basic"
                                className="example-dropdown"
                                placeholder='Select a Feature'
                                items={ticketADODropdownState.FeatureItems}
                                onSelect={onFeatureSelect}
                                selection={featureIdSelection}
                            />
                        </FormItem>
                        {/* user story not exsit */}
                        {
                            (!(ticketADODropdownState.UserStoryItems && ticketADODropdownState.UserStoryItems.length > 0)) &&
                            <FormItem
                                label={props.ticketTypeId === CommonConstants.CurationRecommendedTicketTypeId ? "Requirement*" : "Requirement"}
                                className='sonic-ticketADO-formitem'
                                error={inputErrorState.showRequirementError}
                                message={inputErrorState.RequirementErrorMessage}>
                                <Dropdown
                                    ariaLabel="Basic"
                                    className="example-dropdown"
                                    placeholder='Select a Requirement'
                                    items={ticketADODropdownState.RequirementItems}
                                    onSelect={onRequirementSelect}
                                    selection={requirementIdSelection}
                                />
                            </FormItem>
                        }
                        {/* user story exsit */}
                        {
                            (ticketADODropdownState.UserStoryItems && ticketADODropdownState.UserStoryItems.length > 0) &&
                            <FormItem
                                label={props.ticketTypeId === CommonConstants.CurationRecommendedTicketTypeId ? "User Story*" : "User Story"}
                                className='sonic-ticketADO-formitem'>
                                <Dropdown
                                    ariaLabel="Basic"
                                    className="example-dropdown"
                                    placeholder='Select a User Story'
                                    items={ticketADODropdownState.UserStoryItems}
                                    onSelect={onUserStorySelect}
                                    selection={userStoryIdSelection}
                                />
                            </FormItem>
                        }

                        <FormItem
                            label={"Work Item Type*"}
                            className='sonic-ticketADO-formitem'
                            error={inputErrorState.showWorkItemTypeError}
                            message={inputErrorState.WorkItemTypeErrorMessage}>
                            <Dropdown
                                ariaLabel="Basic"
                                className="example-dropdown"
                                placeholder='Select a Work Item Type'
                                items={ticketADODropdownState.WorkItemTypes}
                                onSelect={onWorkItemTypeSelect}
                                selection={workItemTypeSelection}
                            />
                        </FormItem>

                    </div>
                    <Card
                        className="flex-grow"
                        collapsible={true}
                        collapsed={collapsedTicketFields}
                        onCollapseClick={onCollapseTicketFieldsClicked}
                        titleProps={{ text: "Fields", ariaLevel: 3 }}
                    >
                        <div style={{ width: "100%" }}>
                            <div style={{ "marginTop": "10px" }}>
                                You can modify field value for the following fields created using this template. (For example, "Assigned To" or "Area Path")  <a target={"_blank"} style={{ "textDecoration": "none" }} href='https://aka.ms/devOpsQueryParams'>Learn more</a>
                            </div>

                            {buildFieldsTable()}
                        </div>
                    </Card>

                    <Card
                        className="flex-grow"
                        collapsible={true}
                        collapsed={collapsedTicketAdvanced}
                        onCollapseClick={onCollapseTicketAdvancedClicked}
                        titleProps={{ text: "Advanced", ariaLevel: 3 }}
                    >
                        <ul className='sonic-ticket-advanced-container'>
                            <li>
                                <div>
                                    <span style={{ marginRight: "15px" }}>{"Attach the result"}</span>
                                    <Toggle
                                        offText={"Off"}
                                        onText={"On"}
                                        checked={hasAttachmentToggle}
                                        onChange={(event, newValue) => {
                                            hasAttachmentToggle.value = newValue
                                            setTicketADOState({
                                                ...ticketADOState,
                                                HasAttachmentOrNot: newValue
                                            })
                                        }}
                                    />
                                </div>
                            </li>
                            <li>
                                <span style={{ marginRight: "10px" }}>{"Create child tasks"}</span>
                                <Toggle
                                    offText={"Off"}
                                    onText={"On"}
                                    checked={createChildToggle}
                                    onChange={onCreateChildTasksClick}
                                />
                            </li>
                            {
                                ticketADOState.CreateChildTasksOrNot ?
                                    <li>
                                        <ul>
                                            <li>
                                                <div style={{ "display": "flex" }}>
                                                    <div style={{ float: "left", "marginRight": "40px" }}>{"Order By (DESC):"}</div>
                                                    <Dropdown
                                                        ariaLabel="Basic"
                                                        className="sonic-ticketADO-feild-childassignTo-dropdown"
                                                        items={ticketADODropdownState.OrderByItems}
                                                        onSelect={onOderBySelect}
                                                        selection={orderBySelection}
                                                    />
                                                </div>
                                            </li>
                                            <li>
                                                <div className="sonic-ticketADO-feild-childassignTo-configs" style={{ display: "flex" }}>
                                                    <div style={{ float: "left", minWidth: "144px" }}>Assign To (Child tasks):&nbsp; </div>
                                                    <Dropdown
                                                        ariaLabel="Basic"
                                                        className="sonic-ticketADO-feild-childassignTo-dropdown"
                                                        items={assignToTypeChildItems}
                                                        onSelect={onDropdownListSelect("ChildTaskAssignToType")}
                                                        selection={childAssignToTypeSelection}
                                                    />
                                                    {
                                                        ticketADOState.ChildTaskAssignToType === "Assign to a default person" ?
                                                            <SingleIdentityPickerExtensionV3
                                                                className='msacct-single-identity-picker-400'
                                                                DefaultUserMail={ticketADOState.ChildTaskAssignTo}
                                                                onIdentityPickerChange={onChildAssignToChange} />
                                                            :
                                                            ticketADOState.ChildTaskAssignToType === "Assign to the person copying the value from field" ?
                                                                <>
                                                                    <CascadingDropdown
                                                                        dropdownItems={ticketADODropdownState.AssignToCopyItem}
                                                                        defaultItems={ticketADOState.ChildTaskAssignToFields}
                                                                        getConfigOrder={getDefaultUsersOrder}
                                                                    >
                                                                        <SingleIdentityPickerExtensionV3
                                                                            className='msacct-single-identity-picker-400'
                                                                            DefaultUserMail={ticketADOState.ChildTaskAssignTo}
                                                                            onIdentityPickerChange={onChildAssignToChange} />
                                                                    </CascadingDropdown>
                                                                    <TooltipHost className='tooltip_list' calloutProps={{ gapSpace: 0 }}
                                                                        tooltipProps={{
                                                                        onRenderContent: () => {
                                                                            return (
                                                                            <div className="sonic-ticketADO-tooltip_list_content">
                                                                                {USER_VALID_TOOLTIP}
                                                                            </div>
                                                                            );
                                                                        }
                                                                        }}
                                                                    >
                                                                        <Status
                                                                            {...Statuses.Information}
                                                                            key="information"
                                                                            size={StatusSize.s}
                                                                            className="status-example flex-self-center tooltip_information"
                                                                        />
                                                                    </TooltipHost>
                                                                </>
                                                                :
                                                                ticketADOState.ChildTaskAssignToType === "Assign to an Azure DevOps group" ?
                                                                    <div style={{ float: "left", width: "350px", position: "relative", "top": "-2px" }}>
                                                                        <input type="text" multiple={true} onChange={(event) => {
                                                                            onTextValueChange("CustomChildAssignTo", event)
                                                                        }} value={ticketADOState.ChildTaskAssignTo} style={{ width: "100%", height: "30px" }} />
                                                                    </div>
                                                                    : null
                                                    }
                                                </div>
                                            </li>
                                            <li>
                                                <div style={{ "overflow": "hidden" }}>
                                                    <div style={{ float: "left" }}>Threshold of creating child tasks:&nbsp; </div>
                                                    <Dropdown
                                                        ariaLabel="Basic"
                                                        className="sonic-ticket-task-count"
                                                        items={taskCountItems}
                                                        onSelect={onTaskCountSelect}
                                                        selection={taskCountSelection}
                                                    />
                                                    <div style={{ float: "left", marginTop: "2px" }} className="bolt-formitem-message body-xs">&nbsp; Thresholds help you limit the amount of tasks that are created by a job. {500} is the maximum limit of creating number of child tasks.</div>
                                                </div>
                                            </li>
                                            <li>
                                                <button onClick={() => { isTicketWorkItemDialogOpen.value = true }}>Child task template settings </button>
                                                {/* ticket Work item form */}
                                                <Observer isDialogOpen={isTicketWorkItemDialogOpen}  >
                                                    {(ticketADOProps: { isDialogOpen: boolean }) => {
                                                        return ticketADOProps.isDialogOpen ? (
                                                            <TicketWorkItem2
                                                                WorkItemDescriptionPattern={ticketADOState.WorkItemDescriptionPattern}
                                                                WorkItemTitlePattern={ticketADOState.WorkItemTitlePattern}
                                                                onTicketWorkItemApply={onTicketWorkItemApply}
                                                                onTicketWorkItemDialogDimiss={() => { isTicketWorkItemDialogOpen.value = false }}
                                                            />
                                                        ) : null;
                                                    }}
                                                </Observer>
                                            </li>

                                        </ul>
                                    </li>
                                    : null
                            }
                        </ul>
                    </Card>
                </div>
            </PanelContent>
            <PanelFooter showSeparator className="body-m">
                <Button
                    style={{ "float": "right", "marginLeft": "15px" }}
                    text="Apply"
                    primary={true}
                    onClick={() => {
                        onTicketADOApply()
                    }}
                />
                <Button
                    style={{ "float": "right" }}
                    text="Close"
                    onClick={() => {
                        props.onTicketADODialogDimiss()
                    }}
                />
            </PanelFooter>
        </CustomDialog>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(TicketADO);