import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { returnType } from "../../Utilities/ReturnType";
import {
  DocumentCard,
  DocumentCardActions,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardImage,
  IDocumentCardStyles,
} from '@fluentui/react/lib/DocumentCard';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { ImageFit } from '@fluentui/react/lib/Image';
import { Label } from '@fluentui/react/lib/Label';
import { FontSizes } from '@fluentui/theme';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { onExportAlgoReport, getCurationById, clearSearchResults, followUser, unFollowUser } from '../../../redux/Actions/PowerDataQuery'

const actionCreators = {
  clearSearchResults,
  onExportAlgoReport,
  getCurationById,
  followUser,
  unFollowUser
};
type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
  searchResult: state.searchResult,
  curation: state.taskEditItem,
});
const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

const onActionClick = (action: string, ev: React.SyntheticEvent<HTMLElement>): void => {
  ev.preventDefault();
};

const documentCardActions = [
  {
    iconProps: { iconName: 'Add' },
    onClick: onActionClick.bind(this, 'add'),
    ariaLabel: 'add action',
  },
];
const documentCardActionswithFavoriteStar = [

  {
    iconProps: { iconName: 'FavoriteStarFill' },
    ariaLabel: 'add action',
  },
];
const addIconProps: IIconProps = {
  iconName: 'BarChartVerticalFilter',
  styles: { root: { color: 'rgb(16, 110, 190)', fontSize: '35px', height: '20px' } },
};

const removeLinkIconProps: IIconProps = {
  iconName: 'RemoveLink',
  styles: { root: { color: 'rgb(116, 39, 116)', fontSize: '40px', height: '20px' } },
};

const productLifeCycleIconProps: IIconProps = {
  iconName: 'Lifesaver',
  styles: { root: { color: 'rgb(8, 129, 66)', fontSize: '40px', height: '20px' } },
};

const curationStandardsIconProps: IIconProps = {
  iconName: 'DocumentManagement',
  styles: { root: { color: 'rgb(36 122 195)', fontSize: '40px', height: '20px' } },
};


function CurationStartTemplateV2() {
  const history = useNavigate();


  function navigateToFilterEditor(event: React.MouseEvent<HTMLElement>) {
    history(`/queries/edit`)
    event.preventDefault()
  }

  const cardStyles: IDocumentCardStyles = {
    root: { display: 'inline-block', marginBottom: 10, maxWidth: 460, width: '97%' },
  };
  const cardTitleshouldTruncateStyles: IDocumentCardStyles = {
    root: { fontSize: '16px', paddingTop: '14px' },
  };


  return (
    <div>
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
            <img
              alt="Products no longer supported under standard or extended support but should still be alive."
              width={"100%"}
              style={{ marginTop: "10px" }}
              src={require("./CurationFiltersIcon.png")}
            />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg1"></div>
          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg5">
            <Label style={{ fontSize: FontSizes.mediumPlus, lineHeight: "120%" }}>Plan your Content Curation Algorithms</Label>
            <div>
              <div>
                <p style={{ margin: '10px 0' }}>                                  <i
                  className="ms-Icon ms-Icon--StatusCircleCheckmark"
                  aria-hidden="true"
                ></i> 57K+ Evergreen articles, 11M+ usage data in various dimensions</p>
              </div>
              <div>
                <p style={{ margin: '10px 0' }}><i
                  className="ms-Icon ms-Icon--StatusCircleCheckmark"
                  aria-hidden="true"
                ></i> 52 content metadata, 15 traversed properties</p>
              </div>
              <div>
                <p style={{ margin: '10px 0' }}><i
                  className="ms-Icon ms-Icon--StatusCircleCheckmark"
                  aria-hidden="true"
                ></i> And more...</p>
              </div>
            </div>
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
            <br />
            <div>
              <PrimaryButton text="Curation Filter Editor >" onClick={navigateToFilterEditor} />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(CurationStartTemplateV2);