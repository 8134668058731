import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { returnType } from "../Utilities/ReturnType";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Card } from "azure-devops-ui/Card";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import {
  CustomHeader,
  HeaderTitle,
  HeaderTitleArea,
  HeaderTitleRow
} from "azure-devops-ui/Header";
import { TitleSize } from "azure-devops-ui/Header";
import { Status, StatusSize } from "azure-devops-ui/Status";
import { ConditionalChildren } from "azure-devops-ui/ConditionalChildren";
import { FilterBar } from "azure-devops-ui/FilterBar";
import { KeywordFilterBarItem } from "azure-devops-ui/TextFilterBarItem";
import { Filter, FILTER_CHANGE_EVENT, IFilter } from "azure-devops-ui/Utilities/Filter";
import { Spinner } from "azure-devops-ui/Spinner";
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Duration } from "azure-devops-ui/Duration";
import { css } from "azure-devops-ui/Util";
import { Ago } from "azure-devops-ui/Ago";
import { Icon } from "azure-devops-ui/Icon";

import {
  ITableColumn,
  SimpleTableCell,
  Table,
  renderSimpleCell,
  TwoLineTableCell,
} from "azure-devops-ui/Table";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import {
  HeaderCommandBarWithFilter
} from "azure-devops-ui/HeaderCommandBar";
import { Link } from "azure-devops-ui/Link";
import { MenuItemType, IMenuItem } from "azure-devops-ui/Menu";
import { Observer } from "azure-devops-ui/Observer";
import { IHeaderCommandBarItem } from "azure-devops-ui/HeaderCommandBar";
import { Dialog } from "azure-devops-ui/Dialog";

import { ICurationRun } from '../Interface/ICurationRun'
import { GlobalDataQuery } from "../Interface/IPowerQuery";
import { onExportTaskRunsResult, getCurationRuns, getCurationRunsById, runCuration, createDownloadLogs } from '../../redux/Actions/PowerDataQuery'
import {
  getStatusIndicatorData
} from '../Utilities/DataListExtension'
import { formatDateTime, buildTicketAndMailStates } from '../Utilities/CommonFunction'
import RunTypeDialog from '../Modules/RunTypeDialog/RunTypeDialog'

const actionCreators = {
  onExportTaskRunsResult,
  getCurationRuns,
  getCurationRunsById,
  runCuration,
  createDownloadLogs
};
type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
  curationRunsWithName: state.curationRunsWithName
});
const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

interface ICurationRunsResultState {
  items: ICurationRun[]
}

/**
 * interface for run type
 */
interface IRunTypeDialogState {
  showDialog: boolean,
  actionCurationId: string,
  actionCurationName: string
  selectedRunType: string,
  runTypeSubmitState: string,
}

const filter = new Filter();
const filterToggled = new ObservableValue<boolean>(false);
const runDialogObservable = new ObservableValue<boolean>(false);
const runTypeDialogObservable = new ObservableValue<boolean>(false);
function CurationRuns(props: StoreProps & DispatchProps) {
  const [search, setSearch] = useSearchParams();
  const curationId = search.get('curationId')
  const curationName = search.get('curationName')

  const history = useNavigate();

  /**
    * navigate to detail form
    * @param event 
    * @param buildId 
    */
  function navigateToDetailForm(event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>, buildId: string) {
    history(`/runs/results?buildId=${buildId}`)
    event.preventDefault()
  }

  /**
  * navigate to detail form
  * @param event 
  * @param buildId 
  */
  function navigateToCurationEdit(menuItem: IMenuItem, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) {
    history(`/curations/edit?curationId=${curationId}&curationName=${curationName}`)
    event.preventDefault()
  }

  /**
   * initialize state
   */
  const [currentState, setCurrentState] = useState<ICurationRunsResultState>({
    items: []
  })

  const [isShowNexRun, setIsShowNexRun] = useState<boolean>(false)

  /**
   * run type state
   */
  const [runTypeDialogState, setRunTypeDialogState] = useState<IRunTypeDialogState>({
    showDialog: false,
    actionCurationId: "",
    actionCurationName: "",
    runTypeSubmitState: "",
    selectedRunType: "Incremental"
  })

  // set loading state
  const [isLoading, setIsLoading] = useState<boolean>(false)

  /**
   * like componentDidMount
   */
  useEffect(() => {
    filter.subscribe(onFilterChanged, FILTER_CHANGE_EVENT);

    setRunTypeDialogState({
      showDialog: false,
      actionCurationId: '',
      actionCurationName: '',
      runTypeSubmitState: "",
      selectedRunType: "Incremental"
    })

    const loadData = async () => {
      setIsLoading(true)

      await props.getCurationRunsById(curationId)

      // set isLoading = false
      setCurrentState({
        items: buildItemsWithName()
      })

      setIsLoading(false)
    }

    loadData();

    return () => {
      filter && filter.unsubscribe(onFilterChanged, FILTER_CHANGE_EVENT);
    }
  }, [])

  /**
   * like componentDidUpdate
   */
  useEffect(() => {

    setCurrentState({
      items: buildItemsWithName()
    })

  }, [props.curationRunsWithName, curationId])

  /**
   * build description cell
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
  function renderDescriptionCell(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<ICurationRun>,
    tableItem: ICurationRun) {
    const { Description, BuildNumber, SourceBranch } = tableItem
    const text = "#" + BuildNumber + "  ";
    const buildId = tableItem.Id;
    return (
      <SimpleTableCell
        columnIndex={columnIndex}
        tableColumn={tableColumn}
        key={"col-" + columnIndex}
        contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
      >
        {
          buildId && buildId.toString() !== '' ?
            (
              <div>
                <Status
                  {...getStatusIndicatorData(tableItem.Result).statusProps}
                  className="icon-large-margin"
                  size={StatusSize.s}
                />
                <Link
                  className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                  excludeTabStop
                  href={""}
                  onClick={(event) => navigateToDetailForm(event, buildId.toString())}
                >
                  {text}
                </Link>
              </div>
            )
            :
            (
              <div className="flex-row scroll-hidden">
                {text}
              </div>
            )
        }

      </SimpleTableCell>
    );
  }

  /**
   * Build article count cell
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
  function renderArticleCountCell(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<ICurationRun>,
    tableItem: ICurationRun) {
    const { RunResultCount, ResultPath } = tableItem

    return (
      <SimpleTableCell
        columnIndex={columnIndex}
        tableColumn={tableColumn}
        key={"col-" + columnIndex}
        contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
      >
        {
          RunResultCount && RunResultCount.toString() !== '0' &&
            ResultPath && ResultPath !== '' ?
            (
              <Link
                className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                excludeTabStop
                href={""}
                onClick={(event) => exportRunResult(ResultPath)}
              >
                {RunResultCount}
              </Link>
            )
            :
            (
              <div className="flex-row scroll-hidden">
                {RunResultCount}
              </div>
            )
        }

      </SimpleTableCell>
    );
  }

  /**
   * Render date column
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
  function renderDateColumn(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<ICurationRun>,
    tableItem: ICurationRun): JSX.Element {
    if (!tableItem.FinishTime) {
      return null
    }
    
    return (
      <TwoLineTableCell
        key={"col-" + columnIndex}
        columnIndex={columnIndex}
        tableColumn={tableColumn}
        line1={WithIcon({
          className: "fontSize font-size",
          iconProps: { iconName: "Calendar" },
          children: (
            <Ago date={new Date(tableItem.StartTime)!} /*format={AgoFormat.Extended}*/ />
          )
        })}
        line2={WithIcon({
          className: "fontSize font-size bolt-table-two-line-cell-item",
          iconProps: { iconName: "Clock" },
          children: (
            <Duration
              startDate={new Date(tableItem.StartTime)!}
              endDate={new Date(tableItem.FinishTime)}
            />
          )
        })}
      />
    );
  }

  function WithIcon(props: {
    className?: string;
    iconProps: IIconProps;
    children?: React.ReactNode;
  }) {
    return (
      <div className={css(props.className, "flex-row flex-center")}>
        {Icon({ ...props.iconProps, className: "icon-margin" })}
        {props.children}
      </div>
    );
  }


  /**
   * on filter change
   */
  function onFilterChanged() {
    if (filter.hasChangesToReset()) {
      let keyword = filter.getFilterItemValue<string>("keyword");
      const items = buildItemsWithName(keyword);
      setCurrentState({
        ...currentState,
        items: items ? items : []
      })
    }
    else {
      setCurrentState({
        ...currentState,
        items: buildItemsWithName()
      })
    }
  };


  /**
   * build items
   * @param keyword 
   * @returns 
   */
  function buildItemsWithName(keyword?: string) {
    if (!props.curationRunsWithName || !props.curationRunsWithName.Runs) {
      return []
    }

    let items: ICurationRun[] = []
    props.curationRunsWithName.Runs.map((item) => {
      let runResultCount = 0
      let buildRunType = ""
      let mailDelivered = ""
      let ticketingEventTrigged = ""
      let triggerType = ""
      if (item.RunResults) {
        runResultCount = item.RunResults.ResultCount === -1 ? 0 : item.RunResults.ResultCount
        buildRunType = item.RunResults.BuildRunType
        mailDelivered = buildTicketAndMailStates(item.RunResults.MailDelivered)
        ticketingEventTrigged = buildTicketAndMailStates(item.RunResults.TicketingEventTrigged)
        triggerType = buildTicketAndMailStates(item.RunResults.TriggerType)
      }

      if (keyword && keyword.trim() !== '') {
        if (item.Description.indexOf(keyword) !== -1) {
          items.push({
            Id: item.Id,
            BuildNumber: item.BuildNumber,
            Description: item.Description,
            CurationConfigurationName: item.Description,
            Status: item.Status,
            Result: item.Result,
            StartTime: item.StartTime,
            FinishTime: item.FinishTime,
            ElapsedTime: item.ElapsedTime,
            Url: item.Url,
            SourceBranch: item.SourceBranch,
            RunResultCount: runResultCount,
            ResultPath: item.RunResults?.ResultPath,
            BuildRunType: buildRunType,
            MailDelivered: mailDelivered,
            TicketingEventTrigged: ticketingEventTrigged,
            TriggerType: triggerType
          });
        }
      }
      else {
        items.push({
          Id: item.Id,
          BuildNumber: item.BuildNumber,
          Description: item.Description,
          CurationConfigurationName: curationName,
          Status: item.Status,
          Result: item.Result,
          StartTime: item.StartTime,
          FinishTime: item.FinishTime,
          ElapsedTime: item.ElapsedTime,
          Url: item.Url,
          SourceBranch: item.SourceBranch,
          RunResultCount: runResultCount,
          ResultPath: item.RunResults?.ResultPath,
          BuildRunType: buildRunType,
          MailDelivered: mailDelivered,
          TicketingEventTrigged: ticketingEventTrigged,
          TriggerType: triggerType
        });
      }
    })

    return items ? items : []
  }

  /**
   * build columns
   * @returns 
   */
  function buildColumns(): any {
    const columns = [
      {
        id: "Description",
        name: "Job ID",
        readonly: true,
        renderCell: renderDescriptionCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: new ObservableValue(-20),
      },
      {
        id: "CurationConfigurationName",
        maxWidth: 300,
        name: "Curation configuration name",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-40),
      },
      {
        id: "RunResultCount",
        maxWidth: 300,
        name: "# of Articles",
        readonly: true,
        renderCell: renderArticleCountCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-20),
      },
      {
        id: "TicketingEventTrigged",
        maxWidth: 300,
        name: "Ticket created or not?",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-25),
      },
      {
        id: "TriggerType",
        maxWidth: 300,
        name: "Trigger type",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-20),
      },
      {
        id: "BuildRunType",
        maxWidth: 300,
        name: "Run type",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-20),
      },
      {
        id: "MailDelivered",
        maxWidth: 300,
        name: "Email sent or not?",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-25),
      },
      {
        id: "FinishTime",
        name: "Completed time",
        width: new ObservableValue(-30),
        readonly: true,
        renderCell: renderDateColumn
      },
    ];

    return columns;
  }

  const commandBarItemsAdvanced: IHeaderCommandBarItem[] = [
    {
      id: "testSave",
      important: true,
      onActivate: navigateToCurationEdit,
      text: "Edit"
    },
    {
      id: "runCuration",
      important: true,
      isPrimary: true,
      onActivate: handleRunTypeDialogShow,
      text: "Run Curation"
    },
    {
      iconProps: {
        iconName: "Clock"
      },
      id: "testCreate",
      important: false,
      onActivate: () => {
        setIsShowNexRun(true)
      },
      text: "Scheduled runs"
    }
  ];

  /**
   * onFilterBarDismissClicked
   */
  function onFilterBarDismissClicked() {
    filterToggled.value = !filterToggled.value;
  };

  /**
   * Export run result
   * @param csvFilePath 
   */
  function exportRunResult(csvFilePath: string) {
    props.onExportTaskRunsResult(csvFilePath)

    if (curationId && curationId !== '') {
      props.createDownloadLogs(
        curationId,
        "runs",
        "/api/pipeline/download2string"
      )
    }
  }

  /**
   * Close dialog
   */
  function onDismiss() {
    setIsShowNexRun(false)
  }

  /**
   * build dialog message
   * @returns 
   */
  function buildRunDialogText(): JSX.Element {
    return (
      <div>
        Curation <b>{curationName}</b> has been running, it may take a few minutes, you can refresh the page later to check the running status.
      </div>
    )
  }

  /**
* click cancel of dialog form
*/
  function handleRunDialogeDismiss() {
    runDialogObservable.value = false;

    setRunTypeDialogState({
      ...runTypeDialogState,
      showDialog: false
    })
  };

  /**
   * 
   * @param curationId 
   * @param curationName 
   */
  function handleRunTypeDialogShow() {

    setRunTypeDialogState({
      ...runTypeDialogState,
      showDialog: false,
      actionCurationId: curationId,
      actionCurationName: curationName,
      selectedRunType: ""
    })

    runTypeDialogObservable.value = true
    runDialogObservable.value = false
  }

  /**
   * handle run type dialog dismiss
   */
  function handleRunTypeDialogDismiss() {
    runTypeDialogObservable.value = false
  }

  /**
   * submit 
   * @param data 
   */
  function onRunTypeSubmit(data: any) {

    if (data !== null && data !== undefined && data.submitState !== '') {
      setRunTypeDialogState({
        ...runTypeDialogState,
        showDialog: true,
        actionCurationId: data.actionCurationId,
        actionCurationName: data.actionCurationName,
      })
    }
  }


  return (
    <div>
      {
        isShowNexRun ?
          (
            <Dialog
              titleProps={{ text: "Next run time" }}
              footerButtonProps={[
                {
                  text: "Close",
                  onClick: onDismiss
                }
              ]}
              onDismiss={null}
            >
              <span>
                {formatDateTime(props.curationRunsWithName?.NextRun)}
              </span>
            </Dialog>
          )
          :
          (null)
      }

      {/* run curation */}
      <Observer isDialogOpen={runDialogObservable}>
        {
          () => {
            return runTypeDialogState.showDialog ?
              (
                <Dialog
                  titleProps={{ text: "" }}
                  footerButtonProps={[
                    {
                      text: "OK",
                      primary: true,
                      onClick: handleRunDialogeDismiss
                    },
                  ]}
                  onDismiss={handleRunDialogeDismiss}
                >
                  {buildRunDialogText()}
                </Dialog>
              )
              : null
          }
        }
      </Observer>

      {/* run type form */}
      <Observer isDialogOpen={runTypeDialogObservable} >
        {(runTypeProps: { isDialogOpen: boolean }) => {
          return runTypeProps.isDialogOpen ? (
            <RunTypeDialog
              actionCurationId={runTypeDialogState.actionCurationId}
              actionCurationName={runTypeDialogState.actionCurationName}
              curationRunType={runTypeDialogState.selectedRunType}
              onRunTypeSubmit={onRunTypeSubmit}
              onRunTypeDialogDimiss={handleRunTypeDialogDismiss} />

          ) : null;
        }}
      </Observer>

      <Surface background={SurfaceBackground.neutral}>
        <Page className="pipelines-page flex-grow">

          <CustomHeader className="bolt-header-with-commandbar">
            <HeaderTitleArea>
              <HeaderTitleRow>
                <HeaderTitle ariaLevel={3} className="text-ellipsis" titleSize={TitleSize.Medium}>
                  {curationName}
                </HeaderTitle>
              </HeaderTitleRow>
            </HeaderTitleArea>
            {
              curationName && curationName !== '' ?
                (<HeaderCommandBarWithFilter
                  filter={filter}
                  filterToggled={filterToggled}
                  items={commandBarItemsAdvanced}
                />)
                :
                (
                  <HeaderCommandBarWithFilter
                    filter={filter}
                    filterToggled={filterToggled}
                    items={null}
                  />)
            }

          </CustomHeader>

          <ConditionalChildren renderChildren={filterToggled} >
            <div className="page-content-left page-content-right page-content-top">
              <FilterBar
                filter={filter}
                onDismissClicked={onFilterBarDismissClicked}
              >
                <KeywordFilterBarItem filterItemKey="keyword" />
              </FilterBar>
            </div>
          </ConditionalChildren>
          <div className="page-content page-content-top">
            <Card
              className="flex-grow bolt-card-no-vertical-padding"
              contentProps={{ contentPadding: true }}
            >
              {
                isLoading ?
                  (
                    <Spinner className='msccat-page-loading' label="Loading" />
                  )
                  :
                  (<Table<Partial<ICurationRun>>
                    columns={buildColumns()}
                    itemProvider={new ArrayItemProvider<ICurationRun>(currentState.items)}
                    showLines={true}
                    onSelect={(event, data) => console.log("Selected Row - " + data.index)}
                    onActivate={(event, row) => console.log("Activated Row - " + row.index)}
                    pageSize={200}
                    containerClassName="msacct-devops-table"
                  />)
              }
            </Card>
          </div>
        </Page>
      </Surface>
    </div>
  )
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(CurationRuns);

