import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { returnType } from "../../Utilities/ReturnType";
import { getSiteStatus } from '../../../redux/Actions/PowerDataQuery'
import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { MessageCard, MessageCardSeverity } from "azure-devops-ui/MessageCard";

const actionCreators = {
    getSiteStatus
};
type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    siteStatus: state.siteStatus
});
const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

function SiteInProcess(props: StoreProps & DispatchProps) {

    /**
    * like componentDidMount
    */
    useEffect(() => {
        const loadData = async () => {
            await props.getSiteStatus()
        }

        loadData();
    }, [])


    return (
        <>
            {(props.siteStatus && props.siteStatus === 'InProgress') && <MessageCard
                className="useTerms"
                severity={MessageCardSeverity.Warning}
            >
                {
                    "This site is currently running a daily data sync, the process may take 10-30 minutes. During the period, you may not have access to the latest data."
                }
            </MessageCard>}
        </>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(SiteInProcess);