import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { connect } from "react-redux";
import {
    DocumentCard,
    DocumentCardTitle,
    DocumentCardDetails,
    DocumentCardImage,
    DocumentCardActions,
    IDocumentCardStyles,
} from '@fluentui/react/lib/DocumentCard';
import { Card } from "azure-devops-ui/Card";
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Label } from '@fluentui/react/lib/Label';
import { Image } from '@fluentui/react/lib/Image';
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { IButtonStyles } from 'office-ui-fabric-react';
import { PillGroup } from "azure-devops-ui/PillGroup";
import { Pill, PillSize, PillVariant } from "azure-devops-ui/Pill";
import { IColor } from "azure-devops-ui/Utilities/Color";
import { FontSizes } from '@fluentui/theme';
import { GlobalDataQuery } from '../../Interface/IPowerQuery'
import { getCurationIdByName } from '../../Utilities/CommonFunction'

import { loadAllTaskList } from "../../../redux/Actions/PowerDataQuery";

import "./ProductLifecycleAnalyzerFamily.css";

const actionCreators = {
    loadAllTaskList
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    allTaskList: state.allTaskList
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;


const cardStyles: IDocumentCardStyles = {
    root: { display: 'inline-block', marginRight: '2%', marginBottom: '3%' },
};

const buttonStyles: IButtonStyles = {
    root: { width: '85%', padding: '15px 0', height: '40px', textAlign: "center", fontSize: '16px' },
};

const documentCardActionswithFavoriteStar = [
    {
        iconProps: { iconName: 'FavoriteStarFill' },
        ariaLabel: 'add action',
    },
];

const cardTitleshouldTruncateStyles: IDocumentCardStyles = {
    root: { fontSize: '16px', paddingTop: '14px' },
};

const retentionPolicyClassifierIconProps: IIconProps = {
    iconName: 'DocumentManagement',
    styles: { root: { color: 'rgb(8, 129, 66)', fontSize: '40px' } },
};

const lightColor: IColor = {
    red: 255,
    green: 206,
    blue: 80,
};

const whiteColor: IColor = {
    red: 255,
    green: 255,
    blue: 255,
};

interface ICurationFollowCount {
    Algo1FollowCount: number,
    Algo2FollowCount: number,
    // Algo3FollowCount: number,
}

function RetentionCheckerFamily(props: StoreProps & DispatchProps) {
    const history = useNavigate();

    const [followCount, setFollowCount] = useState<ICurationFollowCount>({
        Algo1FollowCount: 0,
        Algo2FollowCount: 0,
        // Algo3FollowCount: 0,
    })


    useEffect(() => {
        setFollowCount({
            Algo1FollowCount: 0,
            Algo2FollowCount: 0,
            // Algo3FollowCount: 0,
        })

        props.loadAllTaskList()
        const loadData = async () => {
            // call the api to get the list
            await props.loadAllTaskList();
        }

        loadData()

    }, [])

    useEffect(() => {
        let Algo1FollowCount = getFollowCountById(getCurationIdByName("Retention of Process"))
        let Algo2FollowCount = getFollowCountById(getCurationIdByName("Retention of Product"))
        // let Algo3FollowCount = getFollowCountById(getCurationIdByName("Retention of Internal Tool"))

        setFollowCount({
            Algo1FollowCount: Algo1FollowCount,
            Algo2FollowCount: Algo2FollowCount
            // Algo3FollowCount: Algo3FollowCount
        })

    }, [props.allTaskList])

    /**
     * 
     * @param curationId 
     * @returns 
     */
    function getFollowCountById(curationId: string) {
        if (props.allTaskList) {
            let curationItem = props.allTaskList.find(t => t.id === curationId)
            if (curationItem) {
                return curationItem.FollowCount
            }
        }

        return 0
    }


    function gotoAlgo(name: string) {

        return (ev: React.MouseEvent<HTMLElement>) => {
            var guid = getCurationIdByName(name)
            switch (name) {
                case "Process Article Checker":
                    history(`/algos/retentionofprocess?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Product Article Checker":
                    history(`/algos/retentionofproduct?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                /*
                case "Internal Tool Article Checker":
                    history(`/algos/retentionofinternaltool?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;
                */

                default:
                    history(`/algos/retentionofprocess?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;
            }
        }
    }


    return (
        <Surface background={SurfaceBackground.normal}>
            <Page className="pipelines-page flex-grow">
                <div className="page-content page-content-top">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <div>
                                    <Label style={{ fontSize: FontSizes.xLarge, lineHeight: '120%' }}>Monitor Retention Status to Make your Content Retention Efficient, Reliable. And, Easier to Archive them.</Label>
                                </div>
                                <p>
                                    <div style={{ fontSize: FontSizes.mediumPlus }}>Only retaining necessary content ensures that knowledge is easily accessible for discovery, and to comply with industry regulations such as <a href='https://gdpr-info.eu/art-5-gdpr/'>GDPR</a>. To identify the content need to be retained, Content Standards Checkers have been added.</div>
                                </p>
                                <p>
                                    <div style={{ fontSize: FontSizes.mediumPlus }}>Content Standards Checkers with 2 algos are built for Process, Product content.</div>
                                </p>

                                <p style={{ paddingTop: '50px' }}>
                                    <Card
                                        className="bolt-card-content"
                                    >
                                        <div>
                                            <h3 style={{ marginTop: '0' }}>Content retention and archival policy</h3>
                                            <ul>
                                            <li>Process and procedure content should be retained for 10 years from last edited date.</li>
                                            <li>Product related content 5 years from product decommission or end of support.</li>
                                            </ul>
                                            For further information, please check document "<a href="https://internal.evergreen.microsoft.com/en-us/topic/725b53c6-2331-3a0d-4872-42dd503db8fe">Playbook: Support Content Council and content management framework</a>" section "Content retention and archival policy"
                                        </div>
                                    </Card>
                                </p>
                            </div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">

                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg5">
                                <Image
                                    width={"100%"}
                                    src={require("./AlgoAD003.png")}
                                    alt='Products no longer supported under standard or extended support but should still be alive.'
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label style={{ fontSize: FontSizes.xLarge, marginBottom: "30px", marginTop: "30px" }}>Explore the Curation Standard Checker family</Label>
                            </div>
                        </div>
                        <div className="ms-Grid-row">

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DocumentCard
                                    aria-label={
                                        'Process Article Checker'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={retentionPolicyClassifierIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Process Article Checker" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="Process document should be 10 years' retention from after the last updated." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Process Article Checker')} /></div>
                                    <DocumentCardActions className='msacct-family-follow'  actions={documentCardActionswithFavoriteStar} views={followCount.Algo1FollowCount} />
                                </DocumentCard>

                                <DocumentCard
                                    aria-label={
                                        'Product Article Checker'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={retentionPolicyClassifierIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Product Article Checker" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="Product document should be 5 years' retentioon from product decommission or end of support." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Product Article Checker')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo2FollowCount} />
                                </DocumentCard>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <div className="categoryName"> Curation areas:</div>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                <PillGroup className="flex-row">
                                    <Pill size={PillSize.compact} color={lightColor}>
                                        Curation
                                    </Pill>
                                </PillGroup>
                            </div>
                        </div>
                        <br></br>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <div className="categoryName">Tags:</div>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                <PillGroup className="flex-row">
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}>
                                        Retention
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}>
                                        Article Refresh Age (Month)
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}>
                                        Article Archiving Remaining Days
                                    </Pill>
                                </PillGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </Surface>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(RetentionCheckerFamily);
