import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import {
  renderStatus,
} from '../Utilities/DataListExtension'
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import {
  CustomHeader,
  HeaderDescription,
  HeaderIcon,
  HeaderTitle,
  HeaderTitleArea,
  HeaderTitleRow
} from "azure-devops-ui/Header";
import { TitleSize } from "azure-devops-ui/Header";
import {
  HeaderCommandBarWithFilter
} from "azure-devops-ui/HeaderCommandBar";
import { Filter, FILTER_CHANGE_EVENT, IFilter } from "azure-devops-ui/Utilities/Filter";
import { ConditionalChildren } from "azure-devops-ui/ConditionalChildren";
import { FilterBar } from "azure-devops-ui/FilterBar";
import { KeywordFilterBarItem } from "azure-devops-ui/TextFilterBarItem";
import AlgorithmsAnalytics from '../TaskSearch/AlgorithmsAnalytics/AlgorithmsAnalytics'

const filter = new Filter();
const filterToggled = new ObservableValue<boolean>(false);


export default function AlgoAnalyticsControl() {
  const history = useNavigate();  
  const [filterKey, setFilterKey] = useState('')

  /**
* like componentDidMount
*/
  useEffect(() => {
    filter.subscribe(onFilterChanged, FILTER_CHANGE_EVENT);

    return () => {
      filter.unsubscribe(onFilterChanged, FILTER_CHANGE_EVENT);
    }
  }, [])

  /**
* on filter change
*/
  function onFilterChanged() {
    if (filter.hasChangesToReset()) {
      let keyword = filter.getFilterItemValue<string>("keyword");
      setFilterKey(keyword)
    }
    else {
      setFilterKey('')
    }
  };

  /**
 * onFilterBarDismissClicked
 */
  function onFilterBarDismissClicked() {
    filterToggled.value = !filterToggled.value;
  };

  return (
    <Surface background={SurfaceBackground.neutral}>
      <Page className="flex-grow">
        <CustomHeader className="bolt-header-with-commandbar">
          <HeaderTitleArea>
            <HeaderTitleRow>
              <HeaderTitle ariaLevel={3} className="text-ellipsis" titleSize={TitleSize.Medium}>
              Algorithm Analytics (Cu)
              </HeaderTitle>
            </HeaderTitleRow>
          </HeaderTitleArea>

          <HeaderCommandBarWithFilter
            filter={filter}
            filterToggled={filterToggled}
            items={null}
          />
        </CustomHeader>

        <ConditionalChildren renderChildren={filterToggled} >
          <div className="page-content-left page-content-right page-content-top">
            <FilterBar
              filter={filter}
              onDismissClicked={onFilterBarDismissClicked}
            >
              <KeywordFilterBarItem filterItemKey="keyword" />
            </FilterBar>
          </div>
        </ConditionalChildren>
      </Page>
      <AlgorithmsAnalytics FilterKey={filterKey} />
    </Surface>
  )
}
