import {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { Card } from "azure-devops-ui/Card";
import MiniAppCommonCheck from "../Modules/MiniApps/MiniAppCommonCheck/MiniAppCommonCheck";
import MiniAppReportDialog from "../Modules/MiniApps/MiniAppReportDialog/MiniAppReportDialog";
import PIICheckerResultTable from "./PIICheckerResultTable/PIICheckerResultTable";
// import { Dialog, DialogFooter } from "@fluentui/react/lib/Dialog";
// import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
// import { TextField } from "@fluentui/react/lib/TextField";
// import CustomDatePicker from "../Modules/CustomDatePicker/CustomDatePicker";
import { formatDateToUTCStringDate } from "../Utilities/CommonFunction";
import {
    IPIICheckItem,
    ICurrentRow,
} from "./PIICheckerInterfaces";
import "./PIIChecker.css";
import CustomMessageAlert from "../Modules/CustomMessageAlert/CustomMessageAlert";
import { getAPIService } from "../../redux/Actions/PowerDataQuery";
import { useMsal, useAccount } from "@azure/msal-react";
import CustomConfirmDialog from "../Modules/CustomConfirmDialog/CustomConfirmDialog"
import CommonLoading from "../Modules/CommonLoading/CommonLoading";

const BUSINESS_JUSTIFICATION_ERROR_MESSAGE =
    "Business justification cannot be empty!";
const defaultExpirationDate = new Date();
const apiService = getAPIService().taskService;

interface IScanResultState {
    allCount: number,
    scannedCount: number,
    errorCount: number,
    warningCount: number,
    successCount: number,
    reportedCount: number,
    scanStatus: string
}

export default function PIIChecker() {
    const [PIICheckList, setPIICheckList] = useState<IPIICheckItem[]>([]);

    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
    };

    const [scanResultState, setScanResultState] = useState<IScanResultState>({
        allCount: 0,
        scannedCount: 0,
        errorCount: 0,
        warningCount: 0,
        successCount: 0,
        reportedCount: 0,
        scanStatus: ''
    })

    const [isExemptDialogHidden, setIsExemptDialogHidden] =
        useState<boolean>(true);
    const [isReportDialogHidden, setIsReportDialogHidden] =
        useState<boolean>(true);
    const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

    const [businessJustificationValue, setBusinessJustificationValue] = useState<string>("");

    const [
        businessJustificationErrorMessage,
        setBusinessJustificationErrorMessage,
    ] = useState<string>("");

    const [expirationDate, setExpirationDate] = useState<string>(
        formatDateToUTCStringDate(defaultExpirationDate)
    );

    const [currentExecuteRow, setCurrentExecuteRow] = useState<ICurrentRow>();

    const [isScanning, setIsScanning] = useState<boolean>(false)

    // error alert message
    const [showCalloutError, setShowCalloutError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const renderFlag = useRef(0);

    const [inputScanUrl, setInputScanUrl] = useState<string>('');
    const [piiScanCacheData, setPiiScanCacheData] = useState<[]>([])
    const { accounts } = useMsal();
    const userAccount = useAccount(accounts[0]);

    useEffect(() => {
        if (!businessJustificationValue && renderFlag.current > 0) {
            setBusinessJustificationErrorMessage(
                BUSINESS_JUSTIFICATION_ERROR_MESSAGE
            );
        } else {
            setBusinessJustificationErrorMessage("");
        }
        renderFlag.current++;
    }, [businessJustificationValue]);

    const handlePiiScanData = (piiScanData) => {
        return piiScanData.map(item => {
            return {
                content: item.piiText,
                context: item.context,
                category: item.category
            }
        })
    }

    const sortPIICheckListByDefault = (piiIssues) => {
        piiIssues.forEach(item => {
            if(item.status === 'Passed') {
                item.priority = 1
            }else if(item.status === 'Waiting') {
                item.priority = 2
            }else {
                item.priority = 3
            }
        })
        piiIssues.sort((a,b)=> {
            // sort by status
            if(a.priority === b.priority) {
                // a.status === b.status, sort by category
                if(a.category === b.category) {
                    // a.category === b.category, sort by entity name
                    return a.content.localeCompare(b.content)
                }
                return a.category.localeCompare(b.category)
            }
            return a.priority - b.priority
        })
        return piiIssues
    }

    async function scanLink(scanUrl?: string) {
        setPIICheckList([])
        setIsScanning(true);
        setScanResultState({
            allCount: 0,
            scannedCount: 0,
            errorCount: 0,
            warningCount: 0,
            successCount: 0,
            reportedCount: 0,
            scanStatus: 'Running'
        })
        try {
            let piiScanData;
            if(scanUrl) {
                piiScanData = await apiService.getPiiFreshData(scanUrl)
                setPiiScanCacheData(piiScanData)
                setInputScanUrl(scanUrl);
            }else {
                piiScanData = piiScanCacheData
            }
            if(piiScanData.length > 0) {
                const data = await apiService.getIssueList(scanUrl? scanUrl: inputScanUrl, userAccount.username, handlePiiScanData(piiScanData), 'PII')
                const piiIssues = data.issueData
                let reportedIssues = 0
                //TODO status need get by backend
                piiIssues.forEach(item => {
                    if(item.issueId) {
                        item.status = 'Passed'
                        reportedIssues++
                    }
                })
                setPIICheckList(sortPIICheckListByDefault(piiIssues))
                setScanResultState({
                    ...scanResultState,
                    allCount: piiIssues?.length,
                    reportedCount: reportedIssues,
                    scanStatus: 'Existing'
                })
            }else {
                setScanResultState({
                    ...scanResultState,
                    scanStatus: 'Existing'
                })
            }
        }catch(error) {
            setScanResultState({
                ...scanResultState,
                scanStatus: 'Failed'
            })
        }finally {
            setIsScanning(false);
        }
    }

    function openDialog(buttonType: string, currentRowData: ICurrentRow) {
        setCurrentExecuteRow(currentRowData);
        if (buttonType === "Non-PII") {
            setIsReportDialogHidden(false);
        }
        if (buttonType === "Exempt it") {
            if (!businessJustificationValue) {
                setBusinessJustificationErrorMessage("");
            }
            setIsExemptDialogHidden(false);
        }
    }

    const closeExempt = (): void => {
        if (!businessJustificationValue) {
            setBusinessJustificationErrorMessage("");
        }
        setIsExemptDialogHidden(true);
    };

    const closeReport = (): void => {
        setIsReportDialogHidden(true);
    };

    const getExpireTime = (): Date => {
        const time = new Date();
        time.setTime(time.getTime() + 24*60*60*1000*180);
        return time;
    }

    const confirmReport = async(description: string) => {
        setIsDialogLoading(true)
        const today = formatDateToUTCStringDate(new Date())
        const createParam = {
            issueData:[{
                articleUrl: inputScanUrl,
                content: currentExecuteRow.tableItem.content,
                context: currentExecuteRow.tableItem.context,
                category: currentExecuteRow.tableItem.category,
                status: currentExecuteRow.tableItem.status,
                exceptionType: 'False Positive',
                sourceApp: 'SONIC',
                reporter: userAccount.username,
                reportTime: today,
                riskArea: 'PII',
                businessJustification: description,
                startTime: today,
                expireTime: formatDateToUTCStringDate(getExpireTime()),
            }]
        }
        try {
            const data = await apiService.createIssueReport(createParam);
            if(data.success) {
                setShowSuccessMessage(true)
                setIsReportDialogHidden(true)
                setTimeout(() => {
                    setShowSuccessMessage(false)
                }, 3000);
                await scanLink();
            }
        }catch(error){
            setErrorMessage('Sorry, some errors occurred, please try again later')
            setShowCalloutError(true)
        }finally {
            setIsDialogLoading(false);
        }
    };

    const validateExemptInput = (): boolean => {
        let validateFlag = true;
        if (!businessJustificationValue) {
            setBusinessJustificationErrorMessage(
                BUSINESS_JUSTIFICATION_ERROR_MESSAGE
            );
            validateFlag = false;
        }
        if (!expirationDate) {
            validateFlag = false;
        }
        return validateFlag;
    };

    const saveExemptChange = (): void => {
        if (!validateExemptInput()) {
            return;
        }
        console.log(businessJustificationValue, expirationDate);
    };

    const resetExemptDialogData = useCallback(function (date: string) {
        setExpirationDate(date);
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <Surface background={SurfaceBackground.neutral}>
                <Page className="pipelines-page flex-grow">
                    <div className="page-content page-content-top">
                        <CustomMessageAlert
                            showAlert={showSuccessMessage}
                            message="Report false positive successfully!"
                            type="success"
                        />
                        <div className="ms-Grid">
                            <MiniAppCommonCheck
                                showResultStateBar={false}
                                scanResultState={scanResultState}
                                startCheck={scanLink}
                                featureName="PII Checker"
                                statusLabel="Check status"
                                resultLabel="Scanned issues"
                            />
                            <div className="ms-Grid-row">
                                <Card>
                                    <PIICheckerResultTable
                                        tableData={PIICheckList}
                                        openDialog={openDialog}
                                    />
                                </Card>
                            </div>
                        </div>
                    </div>
                    <CommonLoading
                        label="Loading..."
                        isLoading={isScanning}
                    />
                </Page>
            </Surface>
            <CustomConfirmDialog
                showDialog={showCalloutError}
                title="Action Error"
                dialogType="error"
                onConfirmDismiss={()=>{ setShowCalloutError(false) }}
                content={errorMessage}
                confirmButtonLabel="Dismiss"
            />
            {/* <Dialog
                hidden={isExemptDialogHidden}
                onDismiss={closeExempt}
                modalProps={modelProps}
                dialogContentProps={{ title: "Exempt it" }}
            >
                <TextField
                    label="Business justification:"
                    required={true}
                    placeholder="Please provide the business justification"
                    errorMessage={businessJustificationErrorMessage}
                    rows={4}
                    multiline
                    resizable={false}
                    onChange={(e, newValue) =>
                        setBusinessJustificationValue(newValue)
                    }
                />
                <CustomDatePicker
                    label="Expiration Date:"
                    required={true}
                    defaultDate={defaultExpirationDate}
                    getDate={resetExemptDialogData}
                />
                <DialogFooter>
                    <DefaultButton onClick={closeExempt} text="Cancel" />
                    <PrimaryButton
                        onClick={saveExemptChange}
                        text="Save Changes"
                    />
                </DialogFooter>
            </Dialog> */}
            <MiniAppReportDialog
                hideReportDialog={isReportDialogHidden}
                close={closeReport}
                confirm={confirmReport}
                isLoading={isDialogLoading}
            />
        </div>
    );
}
