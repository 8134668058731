import React, { Component } from 'react'
import CurationRuns from '../../CurationRuns/CurationRuns'
import AppLayout from "../AppLayout/AppLayout";
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'

const breadcrumbItem: IBreadCrumbExtension[] = [
    { text: 'Home', key: 'Home', href: '/' },
    { text: 'Intelligent Automation', key: 'f4', href: '/autocuration'},
    { text: 'All Curations', key: 'f5', href: '/curations'},
  ];

export default class CurationRunsPage extends Component {
    render() {
      document.title = MSACCT_TITLE + " | " + "All Curations";
        return (
          <AppLayout
            renderBreadcrumb={() =>
              <BreadCrumbExtension items={breadcrumbItem} />
            }
            renderContentPane={() => <CurationRuns />} />
        );
      }
}
