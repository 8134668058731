import React, { Component } from "react";
import AppLayout from "../AppLayout/AppLayout";
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'

import TaskSearch from '../../TaskSearch/TaskSearch'

import './TaskQueryPage.css'

const breadcrumbItem: IBreadCrumbExtension[] = [
  { text: 'Home', key: 'Home', href: '/' },
  { text: 'Intelligent Automation', key: 'ia'},
];

export default class TaskQueryPage extends Component {
  render() {
    document.title = MSACCT_TITLE + " | " + "Intelligent Automation";
    return (
      <AppLayout
        renderBreadcrumb={() =>
          <BreadCrumbExtension items={breadcrumbItem} />
        }

        renderContentPane={() => <TaskSearch />} />
    )
  }
}