import * as React from 'react';
import {
  DocumentCard,
  DocumentCardActions,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardImage,
  IDocumentCardStyles,
} from '@fluentui/react/lib/DocumentCard';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { ImageFit } from '@fluentui/react/lib/Image';
import { useNavigate } from "react-router-dom";

const onActionClick = (action: string, ev: React.SyntheticEvent<HTMLElement>): void => {
  ev.preventDefault();
};

const documentCardActions = [
  {
    iconProps: { iconName: 'Add' },
    onClick: onActionClick.bind(this, 'add'),
    ariaLabel: 'add action',
  },
];
const documentCardActionswithFavoriteStar = [

  {
    iconProps: { iconName: 'FavoriteStarFill' },
    ariaLabel: 'add action',
  },
];
const addIconProps: IIconProps = {
  iconName: 'Add',
  styles: { root: { color: 'rgb(16, 110, 190)', fontSize: '35px', height: '20px' } },
};

const removeLinkIconProps: IIconProps = {
  iconName: 'RemoveLink',
  styles: { root: { color: 'rgb(116, 39, 116)', fontSize: '40px', height: '20px' } },
};

const productLifeCycleIconProps: IIconProps = {
  iconName: 'Lifesaver',
  styles: { root: { color: 'rgb(8, 129, 66)', fontSize: '40px', height: '20px' } },
};

const curationStandardsIconProps: IIconProps = {
  iconName: 'DocumentManagement',
  styles: { root: { color: 'rgb(36 122 195)', fontSize: '40px', height: '20px' } },
};


export const CurationStartTemplate: React.FunctionComponent = () => {
  const history = useNavigate();


  function navigateToPage(templateName:string) {
    return (event: React.SyntheticEvent<HTMLElement>) => {
      history(`/curations/create?templateName=${templateName}`)
      event.preventDefault()
    }
    
  }

  const cardStyles: IDocumentCardStyles = {
    root: { display: 'inline-block', marginRight: '1.3%', marginBottom: 10, maxWidth: 460, width: '23.6%' },
  };
  const cardTitleshouldTruncateStyles: IDocumentCardStyles = {
    root: { fontSize: '16px', paddingTop: '14px' },
  };


  return (
    <div>
      <DocumentCard
        aria-label={
          'Blank template'
        }
        styles={cardStyles}
        onClickHref="/curations/create"
        onClick={navigateToPage("blank")}
      >
        <DocumentCardImage height={70} imageFit={ImageFit.cover} iconProps={addIconProps} />
        <DocumentCardDetails>
          <DocumentCardTitle title="Blank template" shouldTruncate styles={cardTitleshouldTruncateStyles} />
          <DocumentCardTitle
            title="Create your new curation."
            shouldTruncate
            showAsSecondaryTitle
          />
        </DocumentCardDetails>
        <DocumentCardActions actions={documentCardActionswithFavoriteStar} views={502} />
      </DocumentCard>
      <DocumentCard
        aria-label={
          'Broken Link Checker template'
        }
        styles={cardStyles}
        onClickHref="/curations/create"
        onClick={navigateToPage("broken")}
      >
        <DocumentCardImage height={70} imageFit={ImageFit.cover} iconProps={removeLinkIconProps} />
        <DocumentCardDetails>
          <DocumentCardTitle title="Broken Link Checker" shouldTruncate styles={cardTitleshouldTruncateStyles} />
          <DocumentCardTitle
            title="This algo will run daily to check for broken links."
            shouldTruncate
            showAsSecondaryTitle
          />
        </DocumentCardDetails>
        <DocumentCardActions actions={documentCardActionswithFavoriteStar} views={104} />
      </DocumentCard>

      <DocumentCard
        aria-label={
          'Curation standards template'
        }
        styles={cardStyles}
        onClickHref="/curations/create"
        onClick={navigateToPage("curation")}
      >
        <DocumentCardImage height={70} imageFit={ImageFit.cover} iconProps={curationStandardsIconProps} />
        <DocumentCardDetails>
          <DocumentCardTitle title="Curation Standards" shouldTruncate styles={cardTitleshouldTruncateStyles} />
          <DocumentCardTitle
            title="Run scans of all articles to identify those that meet curation standards."
            shouldTruncate
            showAsSecondaryTitle
          />
        </DocumentCardDetails>
        <DocumentCardActions actions={documentCardActions} views={53} />
      </DocumentCard>

      <DocumentCard
        aria-label={
          'Product Lifecycle Analyzer template'
        }
        styles={cardStyles}
        onClickHref="/curations/create"
        onClick={navigateToPage("product")}
      >
        <DocumentCardImage height={70} imageFit={ImageFit.cover} iconProps={productLifeCycleIconProps} />
        <DocumentCardDetails>
          <DocumentCardTitle title="Product Lifecycle Analyzer" shouldTruncate styles={cardTitleshouldTruncateStyles} />
          <DocumentCardTitle
            title="Product no longer supported under standard or extended support."
            shouldTruncate
            showAsSecondaryTitle
          />
        </DocumentCardDetails>
        <DocumentCardActions actions={documentCardActions} views={42} />
      </DocumentCard>
    </div>
  );
};
