import React, { Component } from 'react'
import { RadioButton, RadioButtonGroup } from "azure-devops-ui/RadioButton";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Checkbox } from "azure-devops-ui/Checkbox";
import { Dropdown } from "azure-devops-ui/Dropdown";
import { DropdownMultiSelection } from "azure-devops-ui/Utilities/DropdownSelection";
import { IListBoxItem } from "azure-devops-ui/ListBox";
import { SplitterElementPosition, Splitter } from "azure-devops-ui/Splitter";
import {
    TimePicker,
    IComboBox
} from "@fluentui/react";

import {
    Label,
    ILabelStyles,
    SpinButton,
    ISpinButtonStyles
} from "@fluentui/react";

import './CronBuilder.css'

/**
 * cron state item
 */
interface ICronBuilderItem {
    frequency: number,  //1：day， 2：weekly， 3：monthly
    RecurIntervals: number,
    RecurDaysOfWeek: number[],
    RecurDaysOfMonth: number[],
    RecurHour: number,
    RecurMinute: number
}

/**
 * cron state
 */
interface ICronBuilderState {
    ICron: ICronBuilderItem,
}

/**
 * cron props
 */
interface ICronBuilderProps {
    onCronChange: (data: any) => void,
    frequencyProps?: number,  //1：day， 2：weekly， 3：monthly
    RecurIntervalsProps?: number,
    RecurDaysOfWeekProps?: number[],
    RecurDaysOfMonthProps?: number[],
    RecurHourProps?: number,
    RecurMinuteProps?: number
}

// set spin style
const spinLeftLableStyles: Partial<ILabelStyles> = { root: { float: "left" } };
const spinRightLableStyles: Partial<ILabelStyles> = { root: { float: "left", marginLeft: "10px" } };
const spinButtonStyles: Partial<ISpinButtonStyles> = { spinButtonWrapper: { width: 60, float: "left", marginLeft: "5px" } };

const selectedRadioButton = new ObservableValue<string>("");

export default class CronBuilder extends Component<ICronBuilderProps, ICronBuilderState> {
    // weekly
    private checkboxSunday = new ObservableValue<boolean>(false);
    private checkboxMonday = new ObservableValue<boolean>(false);
    private checkboxTuesday = new ObservableValue<boolean>(false);
    private checkboxWednesday = new ObservableValue<boolean>(false);
    private checkboxThursday = new ObservableValue<boolean>(false);
    private checkboxFriday = new ObservableValue<boolean>(false);
    private checkboxSaturday = new ObservableValue<boolean>(false);

    // monthly
    private monthDropDwonItems: Array<IListBoxItem<{}>> = []
    private monthSelection = new DropdownMultiSelection();


    constructor(props) {
        super(props)

        this.state = {
            ICron: {
                frequency: this.props.frequencyProps,
                RecurIntervals: this.props.RecurIntervalsProps,
                RecurDaysOfWeek: this.props.RecurDaysOfWeekProps,
                RecurDaysOfMonth: this.props.RecurDaysOfMonthProps,
                RecurHour: this.props.RecurHourProps,
                RecurMinute: this.props.RecurMinuteProps,
            }
        }
    }

    componentDidMount() {
        let newDate = new Date()
        let recurHour = newDate.getHours()
        let recurMinute = newDate.getMinutes()
        if (recurMinute > 30) {
            recurHour = recurHour + 1
            recurMinute = 0
        }
        else if (recurMinute <= 30) {
            recurMinute = 30
        }

        if (this.props.RecurHourProps) {
            recurHour = this.props.RecurHourProps
        }

        if (this.props.RecurMinuteProps) {
            recurMinute = this.props.RecurMinuteProps
        }

        this.setState({
            ICron: {
                frequency: this.props.frequencyProps,
                RecurIntervals: this.props.RecurIntervalsProps,
                RecurDaysOfWeek: this.props.RecurDaysOfWeekProps,
                RecurDaysOfMonth: this.props.RecurDaysOfMonthProps,
                RecurHour: recurHour,
                RecurMinute: recurMinute,
            }
        }, () => {
            this.setCronDefaultValue()
            if (this.props.onCronChange) {
                this.props.onCronChange(this.state.ICron)
            }
        })
    }


    setCronDefaultValue() {
        // set default frequency
        selectedRadioButton.value = this.state.ICron.frequency?.toString() // this.props.FrequencyProps?.toString()

        // set week default value
        if (this.props.RecurDaysOfWeekProps && this.props.RecurDaysOfWeekProps.length > 0) {
            for (var weekIndex = 0; weekIndex < 7; weekIndex++) {
                const findItem = this.props.RecurDaysOfWeekProps.find((element) => element === weekIndex)

                switch (findItem) {
                    case 0:
                        this.checkboxSunday = new ObservableValue<boolean>(true);
                        break;
                    case 1:
                        this.checkboxMonday = new ObservableValue<boolean>(true);
                        break;
                    case 2:
                        this.checkboxTuesday = new ObservableValue<boolean>(true);
                        break;
                    case 3:
                        this.checkboxWednesday = new ObservableValue<boolean>(true);
                        break;
                    case 4:
                        this.checkboxThursday = new ObservableValue<boolean>(true);
                        break;
                    case 5:
                        this.checkboxFriday = new ObservableValue<boolean>(true);
                        break;
                    case 6:
                        this.checkboxSaturday = new ObservableValue<boolean>(true);
                        break;
                    default:
                        break;
                }
            }
        }

        // set month init data
        for (var i = 1; i < 32; i++) {
            this.monthDropDwonItems.push({
                id: i.toString(),
                text: i.toString()
            })
        }

        // set the dropdown default value
        this.monthSelection.clear()
        if (this.props.RecurDaysOfMonthProps && this.props.RecurDaysOfMonthProps.length > 0) {
            this.props.RecurDaysOfMonthProps.map((item) => {
                let selectIndex = parseInt(item.toString())
                this.monthSelection.select(selectIndex - 1);
            })
        }
    }

    /**
     * spin value change
     * @param frequency 
     * @returns 
     */
    handleSpinChange = (_event: React.SyntheticEvent, spinValue: string) => {
        this.setState({
            ICron: {
                ...this.state.ICron,
                RecurIntervals: parseInt(spinValue),
            }
        }, () => {
            if (this.props.onCronChange) {
                this.props.onCronChange(this.state.ICron)
            }
        })
    }

    /**
     * frequency select
     * @param selectedId 
     */
    handleFrequencySelect = (selectedId: string) => {
        selectedRadioButton.value = selectedId

        this.setState({
            ICron: {
                ...this.state.ICron,
                frequency: parseInt(selectedId)
            }
        })
    }

    /**
     * week change
     * @param weekly the week that has been selected
     * @returns 
     */
    handleWeeklyChange = (weekly: number) => {
        return (_event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, checkedValue: boolean) => {

            switch (weekly) {
                case 0:
                    this.checkboxSunday.value = checkedValue
                    break;
                case 1:
                    this.checkboxMonday.value = checkedValue
                    break;
                case 2:
                    this.checkboxTuesday.value = checkedValue
                    break;
                case 3:
                    this.checkboxWednesday.value = checkedValue
                    break;
                case 4:
                    this.checkboxThursday.value = checkedValue
                    break;
                case 5:
                    this.checkboxFriday.value = checkedValue
                    break;
                case 6:
                    this.checkboxSaturday.value = checkedValue
                    break;
            }

            const { RecurDaysOfWeek } = this.state.ICron

            // if the checkbox status from true to false
            // remove the value from array
            let newRecurDaysOfWeek: number[] = []
            if (RecurDaysOfWeek && RecurDaysOfWeek.length > 0) {
                RecurDaysOfWeek.map((week) => {
                    if (week !== weekly) {
                        newRecurDaysOfWeek.push(week)
                    }
                })
            }

            // add new value to array
            if (checkedValue) {
                newRecurDaysOfWeek.push(weekly)
            }

            // update state and props
            this.setState({
                ICron: {
                    ...this.state.ICron,
                    RecurDaysOfWeek: newRecurDaysOfWeek,
                }
            }, () => {
                if (this.props.onCronChange) {
                    this.props.onCronChange(this.state.ICron)
                }
            })
        }
    }

    /**
     * monthly change
     * @param event 
     * @param item 
     */
    handleMothlySelect = (event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<{}>) => {
        const { RecurDaysOfMonth } = this.state.ICron
        const { id } = item

        let isExistId: boolean = false
        let newRecurDaysOfMonth: number[] = []
        if (RecurDaysOfMonth && RecurDaysOfMonth.length > 0) {
            RecurDaysOfMonth.map((month) => {
                if (id !== month.toString()) {
                    newRecurDaysOfMonth.push(month)
                }
                else {
                    isExistId = true
                }
            })
        }

        if (!isExistId) {
            newRecurDaysOfMonth.push(parseInt(id))
        }

        this.setState({
            ICron: {
                ...this.state.ICron,
                RecurDaysOfMonth: [...newRecurDaysOfMonth]
            }
        }, () => {
            if (this.props.onCronChange) {
                this.props.onCronChange(this.state.ICron)
            }
        })
    }

    /**
     * time picker change
     * @param event 
     * @param time 
     */
    handleTimePickerChange = (event: React.FormEvent<IComboBox>, time: Date) => {
        this.setState({
            ICron: {
                ...this.state.ICron,
                RecurHour: time.getHours(),
                RecurMinute: time.getMinutes()
            }
        }, () => {
            if (this.props.onCronChange) {
                this.props.onCronChange(this.state.ICron)
            }
        })
    }

    /**
     * render time picker
     * @param recurHour default hour
     * @param recurMinute default minute
     * @returns 
     */
    renderTimePicker = (): JSX.Element => {
        let newDate = new Date()
        let date = newDate.toLocaleDateString()

        let defaultDate = new Date()

        if (this.state.ICron.RecurHour) {
            let defaultTimeString = date + " "
                + this.state.ICron.RecurHour.toString() + ":" +
                + this.state.ICron.RecurMinute.toString() + ":" + "00";

            defaultDate = new Date(defaultTimeString)
        }

        return (
            <div className="clearfix" style={{ marginBottom: "10px" }}>
                <div style={{ "width": "82px", "float": "left" }}>
                    <Label styles={spinLeftLableStyles}>Start: </Label>
                </div>
                <div style={{ "width": "165px", "float": "left" }}>
                    {/* https://github.com/microsoft/fluentui/issues/9545 */}
                    {/* https://github.com/microsoft/fluentui/pull/18556 */}
                    <TimePicker
                        styles={{
                            optionsContainerWrapper: {
                                height: '500px',
                            },
                            root: {
                                width: '80%',
                            },
                        }}
                        // timeRange={timeRange}
                        allowFreeform={false}
                        useComboBoxAsMenuWidth
                        defaultValue={defaultDate}
                        onChange={this.handleTimePickerChange}
                    />
                </div>
            </div>
        )
    }


    /**
     * build left content
     * @returns 
     */
    renderNearContent = () => {
        return (
            <div>
                <RadioButtonGroup
                    onSelect={this.handleFrequencySelect}
                    selectedButtonId={selectedRadioButton}
                >
                    <RadioButton id="1" text="Daily" key="Daily" />
                    <RadioButton id="2" text="Weekly" key="Weekly" />
                    <RadioButton id="3" text="Monthly" key="Monthly" />
                </RadioButtonGroup>
            </div>
        )
    }

    renderFarContent = () => {
        return (
            <div>
                {this.renderTimePicker()}
                {this.renderDetailsContent()}
            </div>
        )
    }

    /**
     * build right content
     * @returns 
     */
    renderDetailsContent = () => {
        const { frequency } = this.state.ICron
        switch (frequency) {
            case 1:
                return (
                    <div className="clearfix">                        
                        <div className="clearfix" style={{ "width": "300px" }}>
                            <Label styles={spinLeftLableStyles}>Recur every: </Label>
                            <SpinButton
                                value={this.state.ICron.RecurIntervals.toString()}
                                min={12}
                                max={24}
                                step={12}
                                styles={spinButtonStyles}
                                onChange={this.handleSpinChange}
                            />
                            <Label styles={spinRightLableStyles}> hours</Label>
                        </div>
                        <div style={{"marginTop":"15px"}}><b>*Some settings can take up to 24 hours to take effect.</b></div>
                    </div>

                )
            case 2:
                return (
                    <div className="clearfix">
                        {/* {this.renderTimePicker(11, 30)} */}
                        <ul className='msacct-cron-weekly'>
                            <li>
                                <Checkbox onChange={this.handleWeeklyChange(0)} checked={this.checkboxSunday} label="Sunday" />
                            </li>
                            <li>
                                <Checkbox onChange={this.handleWeeklyChange(1)} checked={this.checkboxMonday} label="Monday" />
                            </li>
                            <li>
                                <Checkbox onChange={this.handleWeeklyChange(2)} checked={this.checkboxTuesday} label="Tuesday" />
                            </li>
                            <li>
                                <Checkbox onChange={this.handleWeeklyChange(3)} checked={this.checkboxWednesday} label="Wednesday" />
                            </li>
                            <li>
                                <Checkbox onChange={this.handleWeeklyChange(4)} checked={this.checkboxThursday} label="Thursday" />
                            </li>
                            <li>
                                <Checkbox onChange={this.handleWeeklyChange(5)} checked={this.checkboxFriday} label="Friday" />
                            </li>
                            <li>
                                <Checkbox onChange={this.handleWeeklyChange(6)} checked={this.checkboxSaturday} label="Saturday" />
                            </li>
                        </ul>
                    </div>
                )
            case 3:
                return (
                    <div style={{ width: "300px" }}>
                        <div style={{ "width": "82px", "float": "left" }}>
                            <Label styles={spinLeftLableStyles}>Days: </Label>
                        </div>
                        <div style={{ "width": "132px", "float": "left" }}>
                            <Dropdown
                                ariaLabel="Multiselect"
                                actions={[
                                    {
                                        className: "bolt-dropdown-action-right-button",
                                        iconProps: { iconName: "Clear" },
                                        text: "Clear",
                                        onClick: () => {
                                            this.monthSelection.clear();
                                        }
                                    }
                                ]}
                                className="msacct-corn-month-dropdown"
                                items={this.monthDropDwonItems}
                                selection={this.monthSelection}
                                placeholder="Select day"
                                onSelect={this.handleMothlySelect}
                                showFilterBox={false}
                                width={132}
                            />
                        </div>
                    </div>
                )

            default:
                return null
        }
    }

    render() {
        const containerStyle = { height: "110px", width: "100%", display: "flex" };
        return (
            <div style={containerStyle}>
                <Splitter
                    fixedElement={SplitterElementPosition.Near}
                    initialFixedSize={120}
                    minFixedSize={120}
                    nearElementClassName="v-scroll-auto custom-scrollbar"
                    farElementClassName="v-scroll-auto custom-scrollbar right"
                    onRenderNearElement={this.renderNearContent}
                    onRenderFarElement={this.renderFarContent}
                />
            </div>
        )
    }
}
