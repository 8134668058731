import React, { Component, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import {
  CustomHeader,
  HeaderDescription,
  HeaderIcon,
  HeaderTitle,
  HeaderTitleArea,
  HeaderTitleRow
} from "azure-devops-ui/Header";
import { TitleSize } from "azure-devops-ui/Header";
import {
  IHeaderCommandBarItem,
  HeaderCommandBar,
  HeaderCommandBarWithFilter,
} from "azure-devops-ui/HeaderCommandBar";
import { ConditionalChildren } from "azure-devops-ui/ConditionalChildren";
import { FilterBar } from "azure-devops-ui/FilterBar";
import { KeywordFilterBarItem } from "azure-devops-ui/TextFilterBarItem";
import { IFilter, Filter, FILTER_CHANGE_EVENT } from "azure-devops-ui/Utilities/Filter";
import { Observer } from "azure-devops-ui/Observer";
import { Tab, TabBar, TabSize } from "azure-devops-ui/Tabs";

import All from './All/All'
import Recent from './Recent/Recent'

import "./TaskSearch.css";

const filter = new Filter();
const filterToggled = new ObservableValue<boolean>(false);
const selectedTabId = new ObservableValue("recent")

export default function TaskSearch() {
  const history = useNavigate();  

  // set the search keyword
  const [filterKey, setFilterKey] = useState('')

  /**
 * like componentDidMount
 */
  useEffect(() => {
    selectedTabId.value = "recent"
    filter.subscribe(onFilterChanged, FILTER_CHANGE_EVENT);

    // like componentwillUMount
    return () => {
      filter && filter.unsubscribe(onFilterChanged, FILTER_CHANGE_EVENT);
    }
  }, [])

  /**
* on filter change
*/
  function onFilterChanged() {
    if (filter.hasChangesToReset()) {
      let keyword = filter.getFilterItemValue<string>("keyword");
      setFilterKey(keyword)
    }
    else {
      setFilterKey('')
    }
  };

  /**
   * reset the tab key when the tab click
   * @param newTabId 
   */
  function onSelectedTabChanged(newTabId: string) {
    selectedTabId.value = newTabId;
  };

  const commandBarItemsAdvanced: IHeaderCommandBarItem[] = [
    {
        iconProps: {
            iconName: "Add"
        },
        id: "testSave",
        important: true,
        onActivate: () => {
          history('/curations/create');
        },
        text: "New curation"
    }
  ]

  /**
   * render filter bar
   * @returns 
   */
  function renderTabBarCommands() {
    const tabBarCommands: IHeaderCommandBarItem[] = [
    ];

    return (
      <HeaderCommandBarWithFilter
        filter={filter}
        filterToggled={filterToggled}
        items={tabBarCommands}
      />
    );
  };

  /**
   * filter close
   */
  function onFilterBarDismissClicked() {
    filterToggled.value = !filterToggled.value;
  };

  return (
    <div >
      <Surface background={SurfaceBackground.neutral}>
        <Page className="pipelines-page flex-grow">
        <CustomHeader className="bolt-header-with-commandbar">
          <HeaderTitleArea>
            <HeaderTitleRow>
              <HeaderTitle ariaLevel={3} className="text-ellipsis" titleSize={TitleSize.Medium}>
              Curation Configurations
              </HeaderTitle>
            </HeaderTitleRow>
            <HeaderDescription>
            </HeaderDescription>
          </HeaderTitleArea>
          <HeaderCommandBar items={commandBarItemsAdvanced} />
        </CustomHeader>
          <TabBar
            onSelectedTabChanged={onSelectedTabChanged}
            selectedTabId={selectedTabId}
            tabSize={TabSize.Tall}
            renderAdditionalContent={renderTabBarCommands}
            disableSticky={false}
          >
            <Tab name="Recent" id="recent" />
            <Tab name="All" id="all" />
          </TabBar>
          <ConditionalChildren renderChildren={filterToggled}>
            <div className="page-content-left page-content-right page-content-top">
              <FilterBar
                filter={filter}
                onDismissClicked={onFilterBarDismissClicked}
              >
                <KeywordFilterBarItem filterItemKey="keyword" placeholder="Filter by curation configuration name" />

              </FilterBar>
            </div>
          </ConditionalChildren>
          <Observer selectedTabId={selectedTabId}>
            {(props: { selectedTabId: string }) => {
              if (props.selectedTabId === "recent") {
                return <Recent FilterKey={filterKey} />;
              }
              else {
                return <All FilterKey={filterKey}  />;
              }
            }}
          </Observer>
        </Page>
      </Surface>
    </div>
  )
}
