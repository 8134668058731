import { IPoliCheckCheckItem } from '../PoliCheckInterfaces';
import MiniAppCommonTable from '../../Modules/MiniApps/MiniAppCommonTable/MiniAppCommonTable'

interface IPIICheckerResultTableProps  {
    openDialog: Function,
    tableData: IPoliCheckCheckItem[]
}

export default function PoliCheckResultTable(props: IPIICheckerResultTableProps) {

    const columns = [
        {
            id: "content",
            name: "PoliCheck Entities",
            maxWidth: 1000,
            width: 120,
        },
        {
            id: "category",
            name: "Categories",
            maxWidth: 500,
            width: 120,
        },
        {
            id: "context",
            name: "Context",
            maxWidth: 300,
            width: 250,
        }
    ]

    return (
        <MiniAppCommonTable
            tableColumns={columns}
            sourceData={props.tableData}
            openDialog={(buttonLabel, data)=>{props.openDialog(buttonLabel, data)}}
        />
    )
}