import { useState, useEffect } from 'react';
import { EditableDropdown } from "azure-devops-ui/EditableDropdown";
import { IListBoxItem } from "azure-devops-ui/ListBox";
import { TextField } from "azure-devops-ui/TextField";
import { Button } from "azure-devops-ui/Button";
import { IReplacementList } from "../../../Interface/IActionFixIt"
import './TextReplacement.css';

interface ITextReplacementProps {
    active: boolean,
    replacementList: IReplacementList[],
    dropdownItems: string[]
    handleReplaceListChange: (list: IReplacementList[]) => void
}

const buildDropdownItems = (items: string[]) => {
    const dropdownItems: IListBoxItem<{}>[] = []
    items.forEach(item => {
        dropdownItems.push({id: item, text: item})
    })
    return dropdownItems
}

const buildReplaceList = (replacementList: IReplacementList[]) => {
    return replacementList?.map((item,index) => {
        return {
            findText: item.FindText,
            replaceText: item.ReplaceWith,
            id: index
        }
    })
}

export default function TextReplacement(props: ITextReplacementProps) {

    const [dropdownItems, setDropdownItems] = useState<IListBoxItem[]>([])

    const [replaceList, setReplaceList] = useState(()=>{
        return buildReplaceList(props.replacementList)
    })

    useEffect(()=>{
        if(props.dropdownItems && props.dropdownItems.length > 0) {
            const newDropdownItems = buildDropdownItems(props.dropdownItems)
            setDropdownItems(newDropdownItems)
            const propsReplacementList = props.replacementList? props.replacementList: []
            const replaceListItems = propsReplacementList.map(item => item.FindText)
            // all diff between orgFindText and dropdown's FindText
            const newArr: string[] = props.dropdownItems.filter(item => !replaceListItems.includes(item))
            // if orgFindText's length less than 3, fill to 3
            if(propsReplacementList.length < 3) {
                const newReplaceList = buildReplaceList(propsReplacementList)
                let diff = newArr.length
                diff = diff > 3 ? 3 : diff
                for(let i=0; i< diff; i++) {
                    newReplaceList.push({
                        findText: newArr[i],
                        replaceText: '',
                        id: newReplaceList.length > 0 ? newReplaceList[newReplaceList.length -1].id + 1 : i
                    })
                }
                setReplaceList(newReplaceList)
            }
        }
    },[props.dropdownItems])

    const handleReplaceTextChange = (item, newValue: string) => {
        const newReplaceList = [...replaceList]
        newReplaceList.forEach(replaceItem => {
            if(item.id === replaceItem.id) {
                replaceItem.replaceText = newValue
            }
        })
        setReplaceList(newReplaceList)
    }

    const handleFindTextChange = (item, newValue?: IListBoxItem<{}>) => {
        const newReplaceList = [...replaceList]
        newReplaceList.forEach(replaceItem => {
            if(item.id === replaceItem.id) {
                replaceItem.findText = newValue.text
            }
        })
        setReplaceList(newReplaceList)
    }

    useEffect(()=>{
        const propsReplaceList = replaceList?.map(item => {
            return {
                FindText: item.findText,
                ReplaceWith: item.replaceText
            }
        })
        props.handleReplaceListChange(propsReplaceList)
    },[replaceList])

    const handleAddClick = () => {
        if(replaceList.length >= 10) {
            return
        }
        const newReplaceList = [...replaceList]
        const newId = newReplaceList[newReplaceList.length-1]? newReplaceList[newReplaceList.length-1].id + 1: 0
        newReplaceList.push({findText: '', replaceText: '', id: newId})
        setReplaceList(newReplaceList)
    }

    const handleDeleteClick = (id: number) => {
        const newReplaceList = replaceList.filter(item => item.id !== id)
        setReplaceList(newReplaceList)
    }

    const renderReplaceList = () => {
        return replaceList?.map((item) => {
            return (
                <div key={item.id} className='text-replacement-body'>
                    <div className='text-replacement-body-item'>
                        <EditableDropdown
                            items={dropdownItems}
                            key={item.id + dropdownItems.length}
                            allowFreeform={true}
                            onValueChange={value => {handleFindTextChange(item, value)}}
                            placeholder="Select an Option"
                            selectedText={item.findText}
                            className='text-replacement-body-dropdown'
                            disabled={!props.active}
                        />
                    </div>
                    <div className='text-replacement-body-item'>
                        <TextField
                            value={item.replaceText}
                            onChange={(e, newValue) => (handleReplaceTextChange(item, newValue))}
                            placeholder="Replace text"
                            className='text-replacement-body-text'
                            disabled={!props.active}
                            maxLength={50}
                        />
                    </div>
                    <div style={{display: 'flex', flexGrow: 1}}>
                        <Button
                            ariaLabel="Delete"
                            iconProps={{ iconName: "Delete" }}
                            onClick={() => handleDeleteClick(item.id)}
                            className='text-replacement-body-delete-btn'
                            disabled={!props.active}
                        />
                    </div>
                </div>
            )
        })
    }

    return (
        <div>
            <div className="text-replacement-header">
                <div style={{flexGrow: 2}}>Find the Text</div>
                <div style={{flexGrow: 3}}>Replace with</div>
            </div>
            { renderReplaceList() }
            <Button
                ariaLabel="Add new replacement"
                iconProps={{ iconName: "Add" }}
                onClick={() => handleAddClick()}
                className='text-replacement-body-add-btn'
                disabled={!props.active}
            />
        </div>
    )
}