// import powerquery interface
import { GlobalDataQuery } from "../../components/Interface/IPowerQuery";


// import action
import {
  ShowApiSuccessAction,
  ShowApiErrorAction,
  ClearApiResponseStatusAction,
  UpdateDataQueryIndexListAction,
  UpdateDataFieldsAction,
  SelectIndexAction,
  SearchResultAction,
  LoadPowerQuerySearchAction,
  OnSelectColumnsAction,
  SelectBranchAction,
  ClearMetaDataQuerySearchAction,
  OnColumnOptionsOpenAction,
  OnColumnOptionsCloseAction,
  OnConfigurationSettingOpenAction,
  OnConfigurationSettingCloseAction,
  AllCurationNameSearchAction,
  AllTaskSearchAction,
  ClearAllTaskSearchAction,
  RecentTaskSearchAction,
  TaskSubmitAction,
  TaskDeleteAction,
  InitTaskCreateAction,
  SearchTaskByNameAction,
  ClearSearchTaskByNameAction,
  SwitchTaskStatusAction,
  RunCurationAction,
  CurationRunsSearchAction,
  CurationRunsSearchByNameAction,
  MyCurationRunsSearchAction,
  SearchTaskRunsResultByIdAction,
  SearchCurationTagsAction,
  FollowUserAction,
  UnFollowUserAction,
  TicketDownloadAction,
  CreateDownloadLogAction,
  GetTicketDevOpsConfigAction,
  GetBrokenLinkScanResult,
  ClearBrokenLinkScanResult,
  GetChildLinks,
  ClearChildLinks,
  ClearDataRefreshSchedule,
  GetDataRefreshSchedule,
  GetDataRefreshStatus,
  WelcomeStatsSearchAction,
  SiteInProcessCheckAction,
  UploadDataSourceFileAction,
  DataSourceListSearchAction,
  DeleteDataSourceAction
} from "../Actions/ActionTypes";

// import action types
import {
  SHOW_API_SUCCESS,
  SHOW_API_ERROR,
  CLEAR_API_RESPONSE_STATUS,
  UPDATE_DATA_QUERY_INDEX_LIST,
  UPDATE_DATA_FIELDS,
  SELECT_INDEX,
  POWER_QUERY_SEARCH,
  LOAD_POWER_QUERY_SEARCH,
  ON_SELECT_COLUMNS,
  SELECT_BRANCH,
  CLEAR_METADATA_QUERY_SEARCH,
  ON_COLUMN_OPTIONS_OPEN,
  ON_COLUMN_OPTIONS_CLOSE,
  ON_CONFIGURATION_SETTING_OPEN,
  ON_CONFIGURATION_SETTING_CLOSE,
  ALL_CURATION_NAME_SEARCH,
  ALL_TASK_SEARCH,
  CLEAR_ALL_TASK_SEARCH,
  RECENT_TASK_SEARCH,
  TASK_SUBMIT,
  TASK_DELETE,
  INIT_TASK_CREATE,
  TASK_QUERY_BY_NAME,
  CLEAR_TASK_QUERY_BY_NAME,
  TASK_STATUS_SWITCH,
  CURATION_RUN,
  CURATION_RUNS_SEARCH,
  CURATION_RUNS_SEARCH_BY_NAME,
  MY_CURATION_RUNS_SEARCH,
  TASK_RUNS_RESULT_SEARCH_BY_ID,
  CURATION_TAGS_SEARCH,
  FOLLOW_USER,
  UNFOLLOW_USER,
  TICKET_DOWNLOAD,
  DOWNLOAD_LOG,
  TICKET_DEVOPS_CONFIG,
  BROKENLINK_SCAN_RESULT,
  CLEAR_BROKENLINK_SCAN_RESULT,
  CHILD_LINKS,
  CLEAR_CHILD_LINKS,
  CLEAR_DATA_REFRESH_SCHEDULE,
  GET_DATA_REFRESH_SCHEDULE,
  GET_DATA_REFRESH_STATUS,
  WELCOME_STATS_SEARCH,
  SITE_INPROCESS_CHECK,
  UPLOAD_DATASOURCE_FILE,
  DATASOURCE_LIST_SEARCH,
  DLETE_DATASOURCE
} from "../Actions/ActionTypes";

// declare inital state
const initState: GlobalDataQuery = {
  indexList: [],
  index: null,
  metadataFields: {},
  // searchResults: {
  //   loading: false,
  //   hasRunQuery: false,
  //   Count: 0,
  //   PageIndex: 0,
  //   DataResult: [],
  //   columns:[]
  // },
  searchResult: {
    loading: false,
    hasRunQuery: false,
    Count: 0,
    PageIndex: 0,
    Topics: [],
    columns: []
  },
  layout: {
    showColumnOptionsDialog: false,
    showConfigrationDialog: false,
  },
  branch: null,
  allCurationName: [],
  allTaskList: [],
  recentTaskList: [],
  curationRuns: [],
  curationRunsWithName: {
    Id: '',
    Name: '',
    Path: '',
    Url: '',
    NextRun: '',
    LastRun: '',
    Runs: []
  },
  myCurationRuns: [],
  taskCreate: {},
  taskEditItem: {
    TriggerSettings: {},
    CurationFilter: {}
  },
  taskRunsResultItem: {},
  curationTags: [],
  ticketDevOpsConfig: [],
  welcomeStats: {
    RepoCount: 0,
    AssetCount: 0,
    CurationCount: 0,
    RunsCount: 0
  },
  apiResponseState: {
    StatusCode: '0',
    ErrorMessage: '',
    showErrorDailog: false,
    showSuccessDailog: false
  },
  dataSourceList: []
};

// contact all actions
type AllActions =
  | ShowApiSuccessAction
  | ShowApiErrorAction
  | ClearApiResponseStatusAction
  | UpdateDataQueryIndexListAction
  | UpdateDataFieldsAction
  | SelectIndexAction
  | SearchResultAction
  | LoadPowerQuerySearchAction
  | OnSelectColumnsAction
  | SelectBranchAction
  | ClearMetaDataQuerySearchAction
  | OnColumnOptionsOpenAction
  | OnColumnOptionsCloseAction
  | OnConfigurationSettingOpenAction
  | OnConfigurationSettingCloseAction
  | AllCurationNameSearchAction
  | AllTaskSearchAction
  | ClearAllTaskSearchAction
  | RecentTaskSearchAction
  | TaskSubmitAction
  | TaskDeleteAction
  | RunCurationAction
  | InitTaskCreateAction
  | SearchTaskByNameAction
  | ClearSearchTaskByNameAction
  | SwitchTaskStatusAction
  | CurationRunsSearchAction
  | CurationRunsSearchByNameAction
  | MyCurationRunsSearchAction
  | SearchTaskRunsResultByIdAction
  | SearchCurationTagsAction
  | FollowUserAction
  | UnFollowUserAction
  | TicketDownloadAction
  | CreateDownloadLogAction
  | GetTicketDevOpsConfigAction
  | GetBrokenLinkScanResult
  | ClearBrokenLinkScanResult
  | GetChildLinks
  | ClearChildLinks
  | ClearDataRefreshSchedule
  | GetDataRefreshSchedule
  | GetDataRefreshStatus
  | WelcomeStatsSearchAction
  | SiteInProcessCheckAction
  | UploadDataSourceFileAction
  | DataSourceListSearchAction
  | DeleteDataSourceAction


// build power query reducer
export function powerQueryReducer(
  state = initState,
  action: AllActions
): GlobalDataQuery {
  switch (action.type) {
    case SHOW_API_SUCCESS:
      return {
        ...state,
        apiResponseState: {
          ...state.apiResponseState,
          ErrorMessage: '',
          StatusCode: '0',
          showErrorDailog: false,
          showSuccessDailog: true
        },
      };
    case SHOW_API_ERROR:
      return {
        ...state,
        apiResponseState: action.apiResponseState,
      };
    case CLEAR_API_RESPONSE_STATUS:
      return {
        ...state,
        apiResponseState: {
          ...state.apiResponseState,
          ErrorMessage: '',
          StatusCode: '0',
          showErrorDailog: false,
          showSuccessDailog: false
        },
      };
    case UPDATE_DATA_QUERY_INDEX_LIST:
      return {
        ...state,
        indexList: action.indexList,
      };
    case UPDATE_DATA_FIELDS:
      return {
        ...state,
        metadataFields: {
          ...state.metadataFields,
          [action.metadataSchemaId]: action.metadataFields,
        },
      };
    case SELECT_INDEX:
      return {
        ...state,
        index: action.index,
      };
    case LOAD_POWER_QUERY_SEARCH:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          loading: action.status === 'start'
        }
      }
    case ON_SELECT_COLUMNS:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          columns: action.keys
        }
      }
    case SELECT_BRANCH:
      return {
        ...state,
        branch: action.branch
      }
    case POWER_QUERY_SEARCH:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          Topics: action.searchResult && action.searchResult.Topics ? action.searchResult.Topics : [],
          hasRunQuery: true,
          Count: action.searchResult ? action.searchResult.Count : 0
        }
      }
    case CLEAR_METADATA_QUERY_SEARCH:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          Topics: [],
          Count: 0,
          hasRunQuery: false
        }
      };
    case ON_COLUMN_OPTIONS_OPEN:
      return {
        ...state,
        layout: {
          ...state.layout,
          showColumnOptionsDialog: true,
        },
      };
    case ON_COLUMN_OPTIONS_CLOSE:
      return {
        ...state,
        layout: {
          ...state.layout,
          showColumnOptionsDialog: false,
        },
      };
    case ON_CONFIGURATION_SETTING_OPEN:
      return {
        ...state,
        layout: {
          ...state.layout,
          showConfigrationDialog: true,
        },
      };
    case ON_CONFIGURATION_SETTING_CLOSE:
      return {
        ...state,
        layout: {
          ...state.layout,
          showConfigrationDialog: false,
        },
      };
    case ALL_CURATION_NAME_SEARCH:
      return {
        ...state,
        allCurationName: action.allCurationName
      };
    case ALL_TASK_SEARCH:
      return {
        ...state,
        taskEditItem: {},
        taskRunsResultItem: {},
        allTaskList: action.allTaskList
      };
    case CLEAR_ALL_TASK_SEARCH:
      return {
        ...state,
        allTaskList: []
      };
    case RECENT_TASK_SEARCH:
      return {
        ...state,
        taskEditItem: {},
        taskRunsResultItem: {},
        recentTaskList: action.recentTaskList
      };
    case TASK_SUBMIT:
    case TASK_DELETE:
    case INIT_TASK_CREATE:
    case CURATION_RUN:
    case TICKET_DOWNLOAD:
    case DOWNLOAD_LOG:
    case UPLOAD_DATASOURCE_FILE:
    case DLETE_DATASOURCE:
      return state;
    case CLEAR_TASK_QUERY_BY_NAME:
      return {
        ...state,
        taskEditItem: {}
      };
    case TASK_QUERY_BY_NAME:
      return {
        ...state,
        taskEditItem: {
          ...action.taskEditItem
        }
      }
    case CURATION_RUNS_SEARCH:
      return {
        ...state,
        curationRuns: action.curationRuns
      }
    case CURATION_RUNS_SEARCH_BY_NAME:
      return {
        ...state,
        curationRunsWithName: action.curationRunsWithName
      }
    case MY_CURATION_RUNS_SEARCH:
      return {
        ...state,
        myCurationRuns: action.myCurationRuns
      }
    case TASK_RUNS_RESULT_SEARCH_BY_ID:
      return {
        ...state,
        taskRunsResultItem: action.taskRunsResultItem
      }
    case CURATION_TAGS_SEARCH:
      return {
        ...state,
        curationTags: [...action.curationTags]
      }
    case FOLLOW_USER:
    case UNFOLLOW_USER:
      return state;
    case TICKET_DEVOPS_CONFIG:
      return {
        ...state,
        ticketDevOpsConfig: [...action.ticketDevOpsConfig]
      }
    case BROKENLINK_SCAN_RESULT:
      return {
        ...state,
        brokenLinkScanResult: action.brokenLinkScanResult
      }
    case CLEAR_BROKENLINK_SCAN_RESULT:
      return {
        ...state,
        brokenLinkScanResult: null
      }
    case CHILD_LINKS:
      return {
        ...state,
        childLinks: action.childLinks
      }
    case CLEAR_CHILD_LINKS:
      return {
        ...state,
        childLinks: []
      }
    case GET_DATA_REFRESH_SCHEDULE:
      return {
        ...state,
        dataRefreshSchedule: action.dataRefreshSchedule
      }
    case CLEAR_DATA_REFRESH_SCHEDULE:
      return {
        ...state,
        dataRefreshSchedule: []
      }
    case GET_DATA_REFRESH_STATUS:
      return {
        ...state,
        contentDataFactory: action.contentDataFactory
      }
    case WELCOME_STATS_SEARCH:
      return {
        ...state,
        welcomeStats: action.welcomeStats
      }
    case SITE_INPROCESS_CHECK:
      return {
        ...state,
        siteStatus: action.siteStatus
      }
    case DATASOURCE_LIST_SEARCH:
      return {
        ...state,
        dataSourceList: action.dataSourceList
      }
    default:
      return state;
  }
}
