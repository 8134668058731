import { Dialog, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { useState, useRef, useEffect } from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import CommonLoading from "../../CommonLoading/CommonLoading";
import "./MiniAppReportDialog.css";

const modelProps = {
    isBlocking: true,
    topOffsetFixed: true,
    className: "mini_app_dialog_common",
};

interface IDialogProps {
    hideReportDialog: boolean,
    close: Function,
    confirm: Function,
    isLoading: boolean
}
export default function MiniAppReportDialog(props: IDialogProps) {
    const [descriptionValue, setDescriptionValue] = useState<string>("");
    const cancelButtonRef = useRef(null);

    useEffect(() => {
        if (!props.hideReportDialog) {
            setTimeout(() => {
                cancelButtonRef.current.focus();
            }, 100);
        }
    }, [props.hideReportDialog]);

    function confirmClick() {
        props.confirm(descriptionValue);
    }

    function cancelClick() {
        props.close();
    }

    return (
        <Dialog
            hidden={props.hideReportDialog}
            onDismiss={() => {
                props.close();
            }}
            modalProps={modelProps}
            dialogContentProps={{
                title: "Report False Case",
            }}
        >
            <MessageBar
                messageBarType={MessageBarType.warning}
                className="mini_app_dialog_content"
            >
                Do you want to report this as a false case to the Content Privacy Concern Review team (SecureCI@microsoft.com) in order to assist with the research?
            </MessageBar>
            <TextField
                label="Description:"
                placeholder="If possible, please provide a generic description of what you feel may be the problem. Please don't copy and paste the content in question into this field as that may further expand the exposure."
                rows={4}
                multiline
                resizable={false}
                onChange={(e, newValue) => setDescriptionValue(newValue)}
            />
            <DialogFooter>
                <DefaultButton
                    onClick={cancelClick}
                    text="Cancel"
                    componentRef={cancelButtonRef}
                />
                <PrimaryButton onClick={confirmClick} text="Confirm" />
            </DialogFooter>
            <CommonLoading
                label="Loading..."
                isLoading={props.isLoading}
            />
        </Dialog>
    );
}
