import { IListBoxItem, ListBoxItemType } from "azure-devops-ui/ListBox";

export const popularDropdownItems: Array<IListBoxItem<{}>> = [
  {
    id: "1",
    text: "Locale",
    iconProps: {
      iconName: "MicrosoftTranslatorLogo",
    },
  },
  {
    id: "5",
    text: "Tags",
    iconProps: {
      iconName: "Tag",
    },
  },
  {
    id: "2",
    text: "Repository Name",
    iconProps: {
      iconName: "FabricFolder",
    },
  },
  { id: "7", text: "Product Name" },
  { id: "11", type: ListBoxItemType.Divider },
  {
    id: "3",
    text: "Last Published Date",
    iconProps: {
      iconName: "Clock",
    },
  },
  {
    id: "4",
    text: "Article Created Date",
    iconProps: {
      iconName: "Clock",
    },
  },
  { id: "6", type: ListBoxItemType.Divider },

  {
    id: "8",
    text: "Content ID",
    iconProps: {
      iconName: "GUID",
    },
  },
  { id: "9", text: "KB ID" },
  { id: "10", text: "Article Publisher" },
];

export const metadataDropdownItems: Array<IListBoxItem<{}>> = [
  { id: "header2", text: "Product", type: ListBoxItemType.Header },
  { id: "5", text: "Product Family Guid" },
  { id: "5", text: "Product Family" },
  { id: "5", text: "Product Version Guid" },
  { id: "5", text: "Product Version" },
  { id: "5", text: "Product Name Guid" },
  { id: "5", text: "Product Name" },
  { id: "5", text: "Category Guid" },
  { id: "5", text: "Category" },
  { id: "5", text: "SAP GUID" },
  { id: "5", text: "SAP Name" },
  { id: "divider", type: ListBoxItemType.Divider },
  { id: "first", text: "Content Type" },
  { id: "1", text: "Content Sub Type" },
  { id: "2", text: "Has Side Navigation" },
  { id: "5", text: "Robots" },
  { id: "5", text: "CSS Audience" },
  { id: "5", text: "Keyword" },
  { id: "4", text: "Page Title" },
  { id: "5", text: "URL" },
  { id: "5", text: "Revision Number" },

  { id: "divider", type: ListBoxItemType.Divider },
  { id: "header2", text: "Translation", type: ListBoxItemType.Header },
  { id: "5", text: "Translation Type" },
  { id: "5", text: "Localization Extent" },
  { id: "5", text: "Localization Priority" },
];

export const contributorsDropdownItems: Array<IListBoxItem<{}>> = [
  { id: "first", text: "Writer Name" },
  { id: "second", text: "Article Creator" },
  { id: "third", text: "Article Publisher" },
  { id: "fourth", text: "Assigned To" },
];

export const KPIDropdownItems: Array<IListBoxItem<{}>> = [
  { id: "first", text: "HRR %" },
  { id: "divider", type: ListBoxItemType.Divider },
  { id: "header", text: "Visits/Page views", type: ListBoxItemType.Header },
  { id: "first", text: "# of Article Visits" },  
  { id: "divider", type: ListBoxItemType.Divider },
  { id: "first", text: "# of Page Views (Total)" },
  { id: "first", text: "# of Page Views (1 Week)" },
  { id: "first", text: "# of Page Views (1 Month)" },
  { id: "first", text: "# of Page Views (1 Quarter)" },
  { id: "first", text: "# of Page Views (1 Year)" },
  { id: "first", text: "Avg Page Views/Day" },
  { id: "first", text: "Avg Page Views/Month" },
  { id: "divider", type: ListBoxItemType.Divider },
  { id: "first", text: "# of Helpful Votes" },
  { id: "first", text: "# of Surveys" },
  { id: "first", text: "# of Unhelpful Votes " },
  { id: "first", text: "# of Unique Visitors " },
  { id: "divider", type: ListBoxItemType.Divider },
  { id: "first", text: "# of Engaged Visits" },
  { id: "first", text: "Engaged Rate %  " },
  { id: "first", text: "Avg Engaged Time (secs) " },  
];

export const lifecycleDropdownItems: Array<IListBoxItem<{}>> = [  
  { id: "header", text: "Article lifecycle", type: ListBoxItemType.Header },  
  { id: "first", text: "Age since Last Publish" },
  { id: "first", text: "Article Age" },
  { id: "divider", type: ListBoxItemType.Divider },
  { id: "header", text: "Product lifecycle", type: ListBoxItemType.Header },
  { id: "first", text: "Product EOS Remaining Days" },  
  { id: "divider", type: ListBoxItemType.Divider },
  { id: "first", text: "Mainstream Remaining Days" },
  { id: "first", text: "Extended End Remaining Days" },
  { id: "first", text: "Retirement Remaining Days" },
  { id: "first", text: "Release EndRemaining Days" },
  { id: "divider", type: ListBoxItemType.Divider },
  { id: "first", text: "Mainstream Date" },
  { id: "first", text: "Extended End Date" },   
  { id: "first", text: "Retirement Date" },
  { id: "first", text: "Release End Date" },  
];