import { IListBoxItem, ListBoxItemType } from "azure-devops-ui/ListBox";

export const policyDropdownItems: Array<IListBoxItem<{}>> = [
    { id: "Delete", text: "Delete", type: ListBoxItemType.Header },
    { id: "archive1", text: "Delete after 1 year" },
    { id: "archive3", text: "Delete after 3 years" },
    { id: "archive8", text: "Delete after 8 years" },      
    { id: "divider", type: ListBoxItemType.Divider },
    { id: "Archive", text: "Archive", type: ListBoxItemType.Header },
    { id: "archive2", text: "Archive after 2 years" },
    { id: "archive5", text: "Archive after 5 years" },
    { id: "archive10", text: "Archive after 10 years" },                                        
];

export const ticketTypeDropdownItems: Array<IListBoxItem<{}>> = [
    /*
    { id: "Delete", text: "Content Author", type: ListBoxItemType.Header },
    { id: "archive1", text: "Assign a remediation task to each content author" }, 
    { id: "Archive", text: "Curation Configuration Owner", type: ListBoxItemType.Header },
    { id: "archive1", text: "Assign a remediation task to the curation configuration owner" },
    { id: "divider", type: ListBoxItemType.Divider },    
    { id: "Archive", text: "Content Privacy Concern Reviewer", type: ListBoxItemType.Header },
    { id: "archive1", text: "Assign a remediation task to the content privacy concern reviewers" },
    { id: "Archive", text: "Content Security Concern Reviewer", type: ListBoxItemType.Header },
    { id: "archive1", text: "Assign a remediation task to the content security concern reviewers" },    
    { id: "divider", type: ListBoxItemType.Divider },    
    { id: "Archive", text: "Content Developer", type: ListBoxItemType.Header },
    { id: "archive1", text: "Assign a writing task to the content developer" },
    { id: "archive1", text: "Assign an editing task to the content developer" },
    { id: "archive1", text: "Assign an accessibility task to the content developer" },
    { id: "Archive", text: "SME", type: ListBoxItemType.Header },
    { id: "archive1", text: "Assign a technical research task to the SME group" },
    { id: "divider", type: ListBoxItemType.Divider },
    { id: "Archive", text: "Content Localization Concern Reviewer", type: ListBoxItemType.Header },
    { id: "archive1", text: "Assign a localization task to the localization service group" },
    { id: "Archive", text: "Content SEO Concern Reviewer", type: ListBoxItemType.Header },
    { id: "archive1", text: "Assign an SEO task to the SEO engineer" },
    { id: "Archive", text: "Content Media Designer", type: ListBoxItemType.Header },
    { id: "archive1", text: "Assign a media task to the media team" },
    */
    { id: "Archive", text: "(Recommended) CE&S KM Azure DevOps Content Ticketing System", type: ListBoxItemType.Header },
    { id: "RecommendedCreateTasks", text: "Create content remediation tasks" },
    { id: "divider", type: ListBoxItemType.Divider },    
    { id: "Archive", text: "My own team's Azure DevOps project", type: ListBoxItemType.Header },
    { id: "CreateTasks", text: "Create content remediation tasks" },
];