import React, { Component } from "react";
import { IconButton, IIconProps } from "@fluentui/react";

const ungroupIcon: IIconProps = { iconName: "RowsGroup" };

interface GroupColumnsProps {
  groupID: number;
  whiteSpace: boolean;
  topBorder: boolean;
  bottomBorder: boolean;
  colSpan: number;
  category: number;
  onUngroupExpressions: (groupID: number) => void;
}

export default class GroupColumn extends Component<GroupColumnsProps> {
    render() {
        let classStr = ''
        if (!this.props.whiteSpace) {
          classStr += `grp-cat-${this.props.category}`
          if (this.props.topBorder) {
            classStr += ` grp-pos-start`
          } else if (this.props.bottomBorder) {
            classStr += ` grp-pos-end`
          }
        }
    
        return (
          <td className={classStr} colSpan={this.props.colSpan}>
            {this.props.topBorder ? (
              <IconButton
                iconProps={ungroupIcon}
                title="Ungroup clauses"
                ariaLabel="Ungroup clauses"
                className="ungroupIcon"
                onClick={() => this.props.onUngroupExpressions(this.props.groupID)}
              />
            ) : null}
          </td>
        )
      }
}