import React, { Component,useState, useEffect } from 'react'
import BrokenLinkChecker from '../../BrokenLinkChecker/BrokenLinkChecker'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";

import AppLayout from "../AppLayout/AppLayout";
import { MSACCT_TITLE } from '../../Utilities/QueryBuilderConstants'
import {isSonicUser} from '../../Utilities/CommonFunction'
import NoPermission from '../../NoPermission/NoPermission';
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'

const breadcrumbItem: IBreadCrumbExtension[] = [
  { text: 'Home', key: 'Home', href: '/' },
  { text: 'Mini APPs', key: 'MiniApps' },
  { text: 'Broken Link Checker', key: 'f5' },
];

export default class BrokenLinkCheckerPage extends Component {
  render() {
    document.title = MSACCT_TITLE + " | " + "Broken Link Checker";
    return (
      <AppLayout
        renderBreadcrumb={() =>
          <BreadCrumbExtension items={breadcrumbItem} />
        }
        renderContentPane={() => <BrokenLinkChecker />} />
    );
  }
}

// export default function BrokenLinkCheckerPage() {
//   document.title = MSACCT_TITLE + " | " + "Broken Link Checker";
//   const { instance, accounts } = useMsal();
//   const userAccount = useAccount(accounts[0]);

//   const [isSonicUserState, setIsSonicUserState] = useState(true)

//   useEffect(() => {
//     setIsSonicUserState(isSonicUser(userAccount?.username))
//   }, [])

//   return (
//     <AppLayout
//       renderBreadcrumb={() =>
//         <BreadCrumbExtension items={breadcrumbItem} />
//       }
//       renderContentPane={() => isSonicUserState? <BrokenLinkChecker /> : <NoPermission />} />
//   );
// }