import React, { Component, useEffect, useState } from "react";
import PoliCheckWithSXC from '../../Modules/Algorithms/PoliCheckWithSXC'
import AppLayout from "../AppLayout/AppLayoutV2";
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'


import './ProductLifecycleAnalyzer.css'

const breadcrumbItem: IBreadCrumbExtension[] = [
  { text: 'Home', key: 'Home', href: '/' },
  { text: 'Algorithms Center', key: 'ia'},
  { text: 'PoliCheck', key: 'blc'},
];

export default class PoliCheckWithSXCPage extends Component {
  render() {
    document.title = MSACCT_TITLE + " | " + "Algorithms Center";
    return (
      <AppLayout
        renderBreadcrumb={() =>
          <BreadCrumbExtension items={breadcrumbItem} />
        }
        renderContentPane={() => <PoliCheckWithSXC />} />
    )
  }
}
