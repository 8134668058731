import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./QueryRows.css";
import { GlobalDataQuery, DataField } from "../../Interface/IPowerQuery";
import { returnType } from "../../Utilities/ReturnType";
import GroupColumn from "../GroupColumn/GroupColumn";
import ValueField from "../ValueField/ValueField";
// import fluentui
import {
  Icon,
  Checkbox,
  ComboBox,
  IComboBox,
  Spinner,
  SpinnerSize,
  SelectableOptionMenuItemType,
} from "@fluentui/react";

// import constants
import {
  MetadataQueryOperator,
  numericalOperators,
  stringOperators,
  dateOperators,
  fullTextOperators,
  sysChangedDateOperators,
  TEXT,
  DATE,
  NUMERICAL,
  BOOLEAN,
  FULLTEXT,
  SYSCHANGEDDATE,
  booleanOperators,
  enumOperators,
  isSetOperator,
  isNotSetOperator,
  SPECIAL_SEARCH_FIELDS,
} from "../../Utilities/QueryBuilderConstants";

// import common function
import {
  QueryExpression,
  getMaxDepth,
  getQueryRows,
  getGroupInfo,
  excludeSearchOptions,
  FieldOptions,
} from "../../Utilities/QueryBuilderUtils";

const mapStateToProps = (state: GlobalDataQuery) => ({
  index: state.index,
  metadataFields: state.metadataFields,
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

const actionCreators = {}
type DispatchProps = typeof actionCreators

interface QueryRowsProps {
  queryExpressions: QueryExpression
  addExpression: (index: number) => void
  removeExpression: (index: number) => void
  onUngroupExpressions: (groupID: number) => void
  onUpdateGroupingCheckbox: (index: number) => void
  onUpdateAndOr: (index: number, key: string) => void
  onUpdateField: (index: number, option: FieldOptions) => void
  onUpdateOperator: (index: number, option: MetadataQueryOperator) => void
  onUpdateValue: (index: number, value: string) => void
}

class QueryRows extends Component<QueryRowsProps & StoreProps & DispatchProps> {

  metadataFieldOptions = (): FieldOptions[] => {

    if (
      !this.props.metadataFields ||
      !this.props.index ||
      !this.props.index.metadataSchema ||
      !this.props.metadataFields[this.props.index.metadataSchema]
    ) {
      return []
    }

    return this.sortQueryRowFieldOptions(
      [
        ...this.props.metadataFields[this.props.index.metadataSchema],
        ...SPECIAL_SEARCH_FIELDS
      ],
      // this.props.index.topFieldOptions
      [
        "a"
      ]
    )
  }

  /**
* @param {MetadataField[]} fields
* metadata api schema
* @param {string[]} topFieldOptions
* options to display at top of input dropdown list
* @returns {FieldOptions[]}
* all options sorted alphabetically, most used options at the top with a divider between the rest
*/
  sortQueryRowFieldOptions = (
    fields: DataField[],
    topFieldOptionsList: string[]
  ): FieldOptions[] => {


    // divide most used options from all others and sort alphabetically
    const topFieldOptions: FieldOptions[] = []
    const sortedFieldOptions = fields
      .map(f => {
        if (topFieldOptionsList?.includes(f.FieldName)) {
          topFieldOptions.push({
            key: f.FieldName,
            text: f.DisplayName,
            fieldType: f.FieldType.toLowerCase(),
            enumValues: f.EnumValues
          })
        } else {
          return {
            key: f.FieldName,
            text: f.DisplayName,
            fieldType: f.FieldType.toLowerCase(),
            enumValues: f.EnumValues
          }
        }
      })
      .sort((a, b) => a.text.localeCompare(b.text))

    // remove undefined items at end of mapped array from sorting into two list
    sortedFieldOptions.splice(
      sortedFieldOptions.length - topFieldOptions.length
    )
    const finalOptions = [
      ...topFieldOptions,
      {
        key: 'divider',
        text: '-',
        itemType: SelectableOptionMenuItemType.Divider,
        fieldType: '',
        enumValues: []
      },
      ...sortedFieldOptions
    ]

    return excludeSearchOptions(
      this.props.index.excludedFieldOptions,
      finalOptions
    )
  }

  getGroupColumns = (expr: QueryExpression, maxDepth: number) => {
    const groupCols = getGroupInfo(expr)

    // count colsUsed
    let colsUsed = 0
    for (const group of groupCols) {
      colsUsed += group.colSpan
    }

    // pad cols with whitespace if needed
    const numWhitespace = maxDepth - 1 - colsUsed
    if (numWhitespace > 0) {
      groupCols.unshift({
        colSpan: numWhitespace,
        whiteSpace: true // ignore coloring/borders CSS
      })
    }

    return groupCols
  }

  /**
   * @param {string} fieldName selected value key to match enum values
   */
  getFieldValueOptions = (fieldName: string) => {
    // check if loaded
    if (
      !this.props.metadataFields ||
      !this.props.index ||
      !this.props.metadataFields[this.props.index.metadataSchema]
    )
      return []

    // filter target field from schema on fieldName
    const fields = this.props.metadataFields[this.props.index.metadataSchema]
    const targetField = fields.filter(field => field.FieldName === fieldName)[0]

    // return valueOptions of targetField
    return targetField ? targetField.EnumValues : []
  }

  render() {
    const queryRows = getQueryRows(this.props.queryExpressions)
    const maxDepth = getMaxDepth(this.props.queryExpressions)

    return queryRows.map((row, index: number) => {
      const fieldInfo =
        this.props.metadataFields &&
        this.props.index &&
        this.props.index.metadataSchema &&
        this.props.metadataFields[this.props.index.metadataSchema] &&
        this.props.metadataFields[this.props.index.metadataSchema].find(
          f => f.FieldName === row.field
        )
      const isMultiValue = fieldInfo && fieldInfo.MultiValue
      let validOperators
      switch (row.fieldType) {
        
        case TEXT:
          if (isMultiValue) {
            // list type (|| delim values)
            validOperators = enumOperators
          } else {
            // string type
            validOperators = stringOperators
          }
          break
        case DATE:
          validOperators = dateOperators
          break
        case NUMERICAL:
          validOperators = numericalOperators
          break
        case BOOLEAN:
          validOperators = booleanOperators
          break
        case FULLTEXT:
          validOperators = fullTextOperators
          break
        case SYSCHANGEDDATE:
          validOperators = sysChangedDateOperators
          break
        /**
         * ToDo: TASK 3995341 - Add support for ENUM types
         */
        default:
          validOperators = stringOperators
      }

      if (this.props.metadataFields !== null) {
        return (
          <tr className="query-row" key={index}>
            {/* Add remove icons */}
            <td className="add-remove">
              <span className="add">
                <Icon
                  onClick={() => this.props.addExpression(index)}
                  iconName="Add"
                />
              </span>
              <span className="remove">
                <Icon
                  onClick={() => this.props.removeExpression(index)}
                  iconName="CalculatorMultiply"
                />
              </span>
            </td>
            {/* Grouping checkbox */}
            <td className="group">
              <Checkbox
                className="group-checkbox"
                checked={row.group}
                onChange={() => {
                  this.props.onUpdateGroupingCheckbox(index)
                }}
              />
            </td>
            {/* Grouping depth */}
            {this.getGroupColumns(row, maxDepth).map((groupCol, ind) => (
              <GroupColumn
                key={ind}
                groupID={groupCol.node ? groupCol.node.id : null}
                whiteSpace={groupCol.whiteSpace}
                topBorder={groupCol.position === 'start'}
                bottomBorder={groupCol.position === 'end'}
                colSpan={groupCol.colSpan}
                category={groupCol.category}
                onUngroupExpressions={this.props.onUngroupExpressions}
              />
            ))}
            {/* And/Or */}
            <td className="and-or">
              {index === 0 ? null : (
                <ComboBox
                  autoComplete="on"
                  selectedKey={row.andOr}
                  options={[
                    { key: 'And', text: 'And' },
                    { key: 'Or', text: 'Or' }
                  ]}
                  onChange={(e: React.FormEvent<IComboBox>, option) => {
                    this.props.onUpdateAndOr(index, option.key as string)
                  }}
                />
              )}
            </td>
            <td className="field">
              {/* Field */}
              <ComboBox
                autoComplete="on"
                selectedKey={row.field}
                options={this.metadataFieldOptions()}
                onChange={(
                  e: React.FormEvent<IComboBox>,
                  option: FieldOptions
                ) => {
                  this.props.onUpdateField(index, option)
                }}
                allowFreeform={true}
              />
            </td>
            <td className="operator">
              {/* Operator */}
              <ComboBox
                disabled={row.fieldType !== null ? false : true}
                autoComplete="on"
                selectedKey={row.operator}
                options={validOperators}
                onChange={(e: React.FormEvent<IComboBox>, option) => {
                  this.props.onUpdateOperator(
                    index,
                    option.key as MetadataQueryOperator
                  )
                }}
              />
            </td>
            <td className="value">

              {row.operator === isSetOperator ||
                row.operator === isNotSetOperator ? null : (
                <ValueField
                  type={row.fieldType}
                  disable={row.fieldType === null}
                  index={index}
                  value={row.value}
                  options={this.getFieldValueOptions(row.field)}
                  onChange={this.props.onUpdateValue}
                  onFieldValueChanged={this.props.onUpdateValue}
                />
              )}
            </td>
          </tr>
        )
      } else {
        return (
          <tr key={index}>
            <td>
              <Spinner size={SpinnerSize.medium} />
            </td>
          </tr>
        )
      }
    })
  }
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(QueryRows)