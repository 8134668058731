import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Duration } from "azure-devops-ui/Duration";
import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { css } from "azure-devops-ui/Util";
import { Ago } from "azure-devops-ui/Ago";
// import { ITaskDataListResult } from '../../Interface/ITaskSearch'
import {
  loadAllTaskList,
  onCurationDelete,
  clearAllTaskList,
  switchCurationStatus
} from "../../../redux/Actions/PowerDataQuery";
import {
  Spinner
} from "@fluentui/react";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import {
  Table,
  SimpleTableCell,
  renderSimpleCell,
  ITableColumn,
  TwoLineTableCell,
  ColumnMore,
  TableColumnLayout,
  TableColumnStyle
} from "azure-devops-ui/Table";
import { Tooltip } from "azure-devops-ui/TooltipEx";
import { Link } from "azure-devops-ui/Link";

import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Card } from "azure-devops-ui/Card";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { Status, StatusSize } from "azure-devops-ui/Status";
import { Icon } from "azure-devops-ui/Icon";
import { IIconProps } from '@fluentui/react/lib/Icon';
import {
  getStatusIndicatorData
} from '../../Utilities/DataListExtension'
import {
  DocumentCard,
  DocumentCardActions,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardImage,
  IDocumentCardStyles,
} from '@fluentui/react/lib/DocumentCard';
import { ImageFit } from '@fluentui/react/lib/Image';

import ConfirmationDialog from '../../Dialogs/ConfirmationDialog/ConfirmationDialog'
import VerticalStackedBarChartCalloutExample from './VerticalBarChartSample'
import { AlgoanalyticsData } from '../../../SampleData/AlgoanalyticsData'
import './AlgorithmsAnalytics.css'
import NumberFormat from "react-number-format";

const actionCreators = {
  loadAllTaskList,
  onCurationDelete,
  clearAllTaskList,
  switchCurationStatus
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
  allTaskList: state.allTaskList
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

interface IDataAlgoList {
  DocumentType: string,
  CurationId:string,
  CurationConfigurationName: string,
  CurationConfigurationNameDisplayName: string,
  CreatedDateTime: string,
  Status: string,
  RunId: number,
  RunBuildNumber: string,
  RunStatus: string,
  RunResult: string,
  RunUrl: string,
  RunDescription: string,
  RunStartTime: string,
  RunFinishTime: string
  RunSourceBranch: string,
  TimesRuntoDate: number,
  LastRun: number,
  ThisMonth: number,
  ThisYear: number,
  ToDate: number,
  LastRun2: number,
  ThisMonth2: number,
  ThisYear2: number,
  ToDate2: number
}

/**
 * interface for all task search 
 */
interface IRecentTaskSearchResultState {
  showDeleteConfirmation?: boolean,
  deleteCurationId:string,
  deleteCurationName: string,
  items: IDataAlgoList[]
}

interface ITaskFiterProps {
  FilterKey?: string
}

function AlgorithmsAnalytics(props: StoreProps & DispatchProps & ITaskFiterProps) {
  const history = useNavigate();

  /**
   * navigate to detail form
   * @param event 
   * @param buildId 
   */
  function navigateToDetailForm(event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>, buildId: string) {
    // history(`/taskrunresult/${buildId}`)
    history(`/runs/results?buildId=${buildId}`)
    event.preventDefault()
  }

  /**
   * initialize state
   */
  const [currentState, setCurrentState] = useState<IRecentTaskSearchResultState>({
    showDeleteConfirmation: false,
    deleteCurationId:"",
    deleteCurationName: "",
    items: []
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  /**
  * like componentDidMount
  */
  useEffect(() => {
    console.log("first", AlgoanalyticsData.length)
    const loadData = async () => {
      // await props.clearAllTaskList();

      setIsLoading(true)

      setCurrentState({
        ...currentState,
        showDeleteConfirmation: false,
        items: buildItems()
      })

      // call the api to get the list
      await props.loadAllTaskList();

      setIsLoading(false)
    }
    
    loadData();
  }, [])

  /**
   * like componentDidUpdate
   */
  useEffect(() => {
    setCurrentState({
      ...currentState,
      showDeleteConfirmation: false,
      items: buildItems()
    })

  }, [props.allTaskList, props.FilterKey])

  /**
 * build the conform dailog text
 * @param taskName 
 * @returns dialog text
 */
  function buildDialogText(taskName: string): string {
    return "The curation " + taskName + " will be deleted permanently, click confirm to proceed."
  }

  /**
   * 
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
  function renderLastRunColumn(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<IDataAlgoList>,
    tableItem: IDataAlgoList
  ) {
    const {
      RunId,
      RunDescription,
      RunUrl,
      RunResult,
      RunStatus,
      RunSourceBranch
    } = tableItem;
    const text = "#" + RunId;

    return (
      <TwoLineTableCell
        className="bolt-table-cell-content-with-inline-link no-v-padding"
        key={"col-" + columnIndex}
        columnIndex={columnIndex}
        tableColumn={tableColumn}
        line1={
          <span className="flex-row scroll-hidden">
            {             
              RunId && RunId.toString() !== '' ?
                (
                  <Tooltip text={text} overflowOnly>
                    <Link
                      className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                      excludeTabStop
                      href={RunUrl}
                      onClick={(event) => navigateToDetailForm(event, RunId.toString())}
                    >
                      {text}
                    </Link>
                  </Tooltip>
                )
                :
                (
                  <>
                    <Status
                      {...getStatusIndicatorData("Queued").statusProps}
                      className="icon-large-margin task-datalist-icon"
                      size={StatusSize.s}
                    />
                    <span>
                      No runs yet
                    </span>
                  </>

                )
            }
          </span>
        }
        line2={
          <span className="flex-row scroll-hidden">
            {
              RunId && RunId.toString() !== '' ?
                (
                  <>
                    <Status
                      {...getStatusIndicatorData(RunResult).statusProps}
                      className="icon-large-margin task-datalist-icon"
                      size={StatusSize.s}
                    />
                    <div className="flex-row scroll-hidden">
                      <span className="text-ellipsis">{RunStatus}</span>
                    </div>
                  </>
                )
                : null
            }
          </span>
        }
      />
    )
  }

  /**
   * render curation configuration name
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
  function renderCurationConfigurationName(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<IDataAlgoList>,
    tableItem: IDataAlgoList) {
    const {
      CurationConfigurationNameDisplayName,
      CurationConfigurationName,
      RunId,
      DocumentType
    } = tableItem;

    let linkIconProps: IIconProps = {
      iconName: 'RemoveLink',
      styles: { root: { color: 'rgb(116, 39, 116)', fontSize: '45px', height: '20px' } },
    };

    if (DocumentType === "blank") {
      linkIconProps = {
        iconName: 'Add',
        styles: { root: { color: 'rgb(16, 110, 190)', fontSize: '35px', height: '45px' } },
      }
    }
    else if (DocumentType === "broken") {
      linkIconProps = {
        iconName: 'RemoveLink',
        styles: { root: { color: 'rgb(116, 39, 116)', fontSize: '40px', height: '40px' } },
      }
    }
    else if (DocumentType === "curation") {
      linkIconProps = {
        iconName: 'DocumentManagement',
        styles: { root: { color: 'rgb(36 122 195)', fontSize: '40px', height: '40px' } },
      }
    }
    else if (DocumentType === "product") {
      linkIconProps = {
        iconName: 'Lifesaver',
        styles: { root: { color: 'rgb(8, 129, 66)', fontSize: '40px', height: '40px' } },
      }
    }
    const cardTitleshouldTruncateStyles: IDocumentCardStyles = {
      root: { fontSize: '14px', paddingTop: '14px', marginBottom: '15px' },
    };
    const cardStyles: IDocumentCardStyles = {
      root: { display: 'inline-block', marginBottom: 10, maxWidth: 450, width: '95%' },
    };
    return (
      <SimpleTableCell
        columnIndex={columnIndex}
        tableColumn={tableColumn}
        key={"col-" + columnIndex}
        contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
      >
        <DocumentCard
          aria-label={
            'Broken Link Checker template'
          }
          styles={cardStyles}
          onClickHref="/curations/create"
        >
          <DocumentCardImage height={50} iconProps={linkIconProps} />
          <DocumentCardDetails>
            <DocumentCardTitle title={CurationConfigurationNameDisplayName} shouldTruncate styles={cardTitleshouldTruncateStyles} />
          </DocumentCardDetails>
        </DocumentCard>
      </SimpleTableCell>
    );
  }


  function WithIcon(props: {
    className?: string;
    iconProps: IIconProps;
    children?: React.ReactNode;
  }) {
    return (
      <div className={css(props.className, "flex-row flex-center")}>
        {Icon({ ...props.iconProps, className: "icon-margin" })}
        {props.children}
      </div>
    );
  }

  function renderDateColumn(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<IDataAlgoList>,
    tableItem: IDataAlgoList): JSX.Element {
    console.log("renderDateColumn", tableItem)

    if (!tableItem.RunStartTime ||
      !tableItem.RunFinishTime ||
      tableItem.RunStartTime === '' ||
      tableItem.RunFinishTime === '') {
      return (
        <SimpleTableCell
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          key={"col-" + columnIndex}
          contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
        >
          <span>No runs yet</span>
        </SimpleTableCell>
      )
    }

    return (
      <TwoLineTableCell
        key={"col-" + columnIndex}
        columnIndex={columnIndex}
        tableColumn={tableColumn}
        line1={WithIcon({
          className: "fontSize font-size",
          iconProps: { iconName: "Calendar" },
          children: (
            <Ago date={new Date(tableItem.RunStartTime)!} /*format={AgoFormat.Extended}*/ />
          )
        })}
        line2={WithIcon({
          className: "fontSize font-size bolt-table-two-line-cell-item",
          iconProps: { iconName: "Clock" },
          children: (
            <Duration
              startDate={new Date(tableItem.RunStartTime)!}
              endDate={new Date(tableItem.RunFinishTime)}
            />
          )
        })}
      />
    );
  }

  function renderTrendCell(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<IDataAlgoList>,
    tableItem: IDataAlgoList): JSX.Element {
    return (
      <td className="bolt-table-cell bolt-list-cell">
        <VerticalStackedBarChartCalloutExample></VerticalStackedBarChartCalloutExample>
      </td>
    );
  }

  function renderSampleDataCell(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<IDataAlgoList>,
    tableItem: IDataAlgoList): JSX.Element {
    return (
      <td className="bolt-table-cell bolt-list-cell">
        20
      </td>
    );
  }

  function renderCurationConfigurationNameColumn(
    columnIndex: number,
    tableColumn: ITableColumn<IDataAlgoList>,
    tableItem: IDataAlgoList
  ) {

    return (
      <th  className="msacct-header-merge bolt-table-header-cell col-header-1 bolt-table-header-cell-actionable" colSpan={4}>
        Digital Assets Checked During...
        <ul>
          <li>Last Run</li>
          <li>This Month</li>
          <li>This Year</li>
          <li>To Date</li>
        </ul>      
      </th>
    );
  }

  function renderCurationConfigurationNameColumn2(
    columnIndex: number,
    tableColumn: ITableColumn<IDataAlgoList>,
    tableItem: IDataAlgoList
  ) {

    return (
      <th  className="msacct-header-merge bolt-table-header-cell col-header-1 bolt-table-header-cell-actionable" colSpan={4}>
        Issues/Possible Issues Flagged...
        <ul>
          <li>Last Run</li>
          <li>This Month</li>
          <li>This Year</li>
          <li>To Date</li>
        </ul>      
      </th>
    );
  }

  /**
   * build data list columns
   * @returns 
   */
  function buildColumns(): any {
    const columns = [
      {
        id: "CurationConfigurationNameDisplayName",
        // name: "Algorithm (Curation configuration name)",
        name: `Algorithm (Configuration name)`,
        readonly: true,
        renderCell: renderCurationConfigurationName,
        //renderCell: TwoLineTableCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: new ObservableValue(-36),
      },
      {
        id: "LastRun",
        name: "Last run",
        maxWidth: 300,
        readonly: true,
        renderCell: renderDateColumn,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-13),
      },
      {
        id: "TimesRuntoDate",
        name: "Run times",
        maxWidth: 300,
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-12),
      },
      {
        id: "LastRun",
        name: "Last Run",
        maxWidth: 300,
        readonly: true,
        renderCell: renderLastRunColumnCell,
        renderHeaderCell: renderCurationConfigurationNameColumn,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        //width: new ObservableValue(-14),
        width: 80,
      },
      {
        id: "ThisMonth",
        name: "This Month",        
        maxWidth: 200,
        readonly: true,
        renderCell: renderThisMonthColumnCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        //width: new ObservableValue(-14),
        width: 80,
        
      },
      {
        id: "ThisYear",
        name: "This Year",
        maxWidth: 300,
        readonly: true,
        renderCell: renderThisYearColumnCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
         //width: new ObservableValue(-14),
         width: 80,
      },
      {
        id: "ToDate",
        name: "To Date",
        maxWidth: 300,
        readonly: true,
        renderCell: renderToDateColumnCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
         //width: new ObservableValue(-14),
         width: 80,
      },
      {
        id: "LastRun2",
        name: "Last Run",
        maxWidth: 300,
        readonly: true,
        renderHeaderCell: renderCurationConfigurationNameColumn2,
        renderCell: renderLastRun2ColumnCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 80,
      },
      {
        id: "ThisMonth2",
        name: "This Month",
        maxWidth: 300,
        readonly: true,
        renderCell: renderThisMonth2ColumnCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 80
      },
      {
        id: "ThisYear2",
        name: "This Year",
        maxWidth: 300,
        readonly: true,
        renderCell: renderThisYear2ColumnCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 80
      },
      {
        id: "ToDate2",
        name: "To Date",
        maxWidth: 300,
        readonly: true,
        renderCell: renderToDate2ColumnCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 80
        
      },
      {
        id: "RunBuildNumber",
        name: "Trends",
        maxWidth: 280,
        readonly: true,
        renderCell: renderTrendCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-50)
      },
      // {
      //   id: "RunBuildNumber",
      //   name: "# of Suggested Edits Made (Ga)",
      //   maxWidth: 300,
      //   readonly: true,
      //   renderCell: renderSampleDataCell,
      //   sortProps: {
      //     ariaLabelAscending: "Sorted low to high",
      //     ariaLabelDescending: "Sorted high to low",
      //   },
      //   width: new ObservableValue(-10),
      // },
      // {
      //   id: "RunBuildNumber",
      //   name: "Time from Reporting to Resolution (Ga)",
      //   maxWidth: 300,
      //   readonly: true,
      //   renderCell: renderSampleDataCell,
      //   sortProps: {
      //     ariaLabelAscending: "Sorted low to high",
      //     ariaLabelDescending: "Sorted high to low",
      //   },
      //   width: new ObservableValue(-10),
      // },
    ];

    return columns;
  }


  // function buildItems() {
  //   let itmes: IDataAlgoList[] = []
  //   if (!props.allTaskList || !props.allTaskList.length) {
  //     return itmes;
  //   }

  //   props.allTaskList.map((item, index) => {
  //     var TimesRuntoDate = 13
  //     var LastRun = 1023
  //     var ThisMonth = 4033
  //     var ThisYear = 12072
  //     var ToDate = 42033
  //     var LastRun2 = 332
  //     var ThisMonth2 = 1295
  //     var ThisYear2 = 6493
  //     var ToDate2 = 77553
  //     if (index===1) {
  //       TimesRuntoDate =22
  //       LastRun = 454
  //       ThisMonth = 298
  //       ThisYear = 844
  //       ToDate = 5676
  //       LastRun2 = 107
  //       ThisMonth2 = 201
  //       ThisYear2 = 572
  //       ToDate2 = 4711
  //     }
  //     else if (index === 2) {
  //       TimesRuntoDate =15
  //       LastRun = 178
  //       ThisMonth = 503
  //       ThisYear = 871
  //       ToDate = 4595
  //       LastRun2 = 84
  //       ThisMonth2 = 399
  //       ThisYear2 = 69
  //       ToDate2 = 3490
  //     }

  //     const taskName = item.Status.toString() === "Enabled" ?
  //       item.CurationConfigurationName
  //       : item.CurationConfigurationName + " (Disabled)"

  //     if (props.FilterKey && props.FilterKey.trim() !== '') {
  //       if (item.CurationConfigurationName.indexOf(props.FilterKey?.toString()) !== -1) {
  //         itmes.push({
  //           CurationConfigurationName: item.CurationConfigurationName,
  //           CurationConfigurationNameDisplayName: taskName,
  //           CreatedDateTime: item.CreatedDateTime,
  //           Status: item.Status,
  //           RunId: item.LastRun?.Id,
  //           RunBuildNumber: item.LastRun?.BuildNumber,
  //           RunStatus: item.LastRun?.Status,
  //           RunResult: item.LastRun?.Result,
  //           RunUrl: item.LastRun?.Url,
  //           RunDescription: item.LastRun?.Description,
  //           RunStartTime: item.LastRun?.StartTime,
  //           RunFinishTime: item.LastRun?.FinishTime,
  //           RunSourceBranch: item.LastRun?.SourceBranch,
  //           TimesRuntoDate: TimesRuntoDate,
  //           LastRun: LastRun,
  //           ThisMonth: ThisMonth,
  //           ThisYear: ThisYear,
  //           ToDate: ToDate,
  //           LastRun2: LastRun2,
  //           ThisMonth2: ThisMonth2,
  //           ThisYear2: ThisYear2,
  //           ToDate2: ToDate2
  //         })
  //       }
  //     }
  //     else {
  //       itmes.push({
  //         CurationConfigurationName: item.CurationConfigurationName,
  //         CurationConfigurationNameDisplayName: taskName,
  //         CreatedDateTime: item.CreatedDateTime,
  //         Status: item.Status,
  //         RunId: item.LastRun?.Id,
  //         RunBuildNumber: item.LastRun?.BuildNumber,
  //         RunStatus: item.LastRun?.Status,
  //         RunResult: item.LastRun?.Result,
  //         RunUrl: item.LastRun?.Url,
  //         RunDescription: item.LastRun?.Description,
  //         RunStartTime: item.LastRun?.StartTime,
  //         RunFinishTime: item.LastRun?.FinishTime,
  //         RunSourceBranch: item.LastRun?.SourceBranch,
  //         TimesRuntoDate: TimesRuntoDate,
  //         LastRun: LastRun,
  //         ThisMonth: ThisMonth,
  //         ThisYear: ThisYear,
  //         ToDate: ToDate,
  //         LastRun2: LastRun2,
  //         ThisMonth2: ThisMonth2,
  //         ThisYear2: ThisYear2,
  //         ToDate2: ToDate2
  //       })
  //     }
  //   })

  //   return itmes;
  // }

  function buildItems() {
    let itmes: IDataAlgoList[] = []


    AlgoanalyticsData.map((item, index) => {


      const taskName = item.Status.toString() === "Enabled" ?
        item.CurationConfigurationName
        : item.CurationConfigurationName + " (Disabled)"
       
      if (props.FilterKey && props.FilterKey.trim() !== '') {
        if (item.CurationConfigurationName.indexOf(props.FilterKey?.toString()) !== -1) {
          itmes.push({
            DocumentType: item.DocumentType,
            CurationId: item.id,
            CurationConfigurationName: item.CurationConfigurationName,
            CurationConfigurationNameDisplayName: taskName,
            CreatedDateTime: item.CreatedDateTime,
            Status: item.Status,
            RunId: item.RunId,
            RunBuildNumber: item.RunBuildNumber,
            RunStatus: item.RunStatus,
            RunResult: item.RunResult,
            RunUrl: item.RunUrl,
            RunDescription: item.RunDescription,
            RunStartTime: item.RunStartTime,
            RunFinishTime: item.RunFinishTime,
            RunSourceBranch: item.RunSourceBranch,
            TimesRuntoDate: item.TimesRuntoDate,
            LastRun: item.LastRun,
            ThisMonth: item.ThisMonth,
            ThisYear: item.ThisYear,
            ToDate: item.ToDate,
            LastRun2: item.LastRun2,
            ThisMonth2: item.ThisMonth2,
            ThisYear2: item.ThisYear2,
            ToDate2: item.ToDate2
          })
        }
      }
      else {
        itmes.push({
          DocumentType: item.DocumentType,
          CurationId: item.id,
          CurationConfigurationName: item.CurationConfigurationName,
          CurationConfigurationNameDisplayName: taskName,
          CreatedDateTime: item.CreatedDateTime,
          Status: item.Status,
          RunId: item.RunId,
          RunBuildNumber: item.RunBuildNumber,
          RunStatus: item.RunStatus,
          RunResult: item.RunResult,
          RunUrl: item.RunUrl,
          RunDescription: item.RunDescription,
          RunStartTime: item.RunStartTime,
          RunFinishTime: item.RunFinishTime,
          RunSourceBranch: item.RunSourceBranch,
          TimesRuntoDate: item.TimesRuntoDate,
          LastRun: item.LastRun,
          ThisMonth: item.ThisMonth,
          ThisYear: item.ThisYear,
          ToDate: item.ToDate,
          LastRun2: item.LastRun2,
          ThisMonth2: item.ThisMonth2,
          ThisYear2: item.ThisYear2,
          ToDate2: item.ToDate2
        })
      }
    })

    return itmes;
  }

  /**
   * delete the item
   */
  async function handleDelete() {
    await props.onCurationDelete(currentState.deleteCurationId)

    // set showDeleteConfirmation = false
    setCurrentState({
      ...currentState,
      showDeleteConfirmation: false
    })

    await props.loadAllTaskList()

    // set showDeleteConfirmation = false
    setCurrentState({
      ...currentState,
      showDeleteConfirmation: false
    })
  }

  async function handleSwitchStatus(currentItem: IDataAlgoList, statusText: string) {
    props.switchCurationStatus(statusText, currentItem.CurationId)
  }

  return (
    <div>
      <Surface background={SurfaceBackground.neutral}>
        <Page className="pipelines-page flex-grow">
          {
            isLoading ?
              (
                <div className="flex-row">
                  <Spinner label="Loading" />
                </div>
              )
              : currentState.items && currentState.items.length > 0 ?
                (
                  <div className="page-content page-content-top">
                    <Card
                      className="flex-grow bolt-card-no-vertical-padding"
                      contentProps={{ contentPadding: true }}
                    >
                      <Table<Partial<IDataAlgoList>>
                        columns={buildColumns()}
                        itemProvider={new ArrayItemProvider<IDataAlgoList>(currentState.items)}
                        showLines={true}
                        onSelect={(event, data) => console.log("Selected Row - " + data.index)}
                        onActivate={(event, row) => console.log("Activated Row - " + row.index)}
                        className="msacct-algo-table"
                        containerClassName='msacct-devops-table'
                      />
                      <ConfirmationDialog
                        isOpen={currentState.showDeleteConfirmation}
                        isBlocking={true}
                        dialogTitle="Delete curation"
                        dialogText={buildDialogText(currentState.deleteCurationName)}
                        confirmButtonText={'Confirm'}
                        onConfirm={handleDelete}
                        cancelButtonText={'Cancel'}
                        onCancel={() => setCurrentState({
                          ...currentState,
                          showDeleteConfirmation: false
                        })}
                      />
                    </Card>
                  </div>
                )
                : null
          }
        </Page>
      </Surface>
    </div>
  )
}

function renderLastRunColumnCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IDataAlgoList>,
  tableItem: IDataAlgoList
): JSX.Element {
  return (
        <SimpleTableCell
          key={"col-" + columnIndex}
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          >
        <span>
          <NumberFormat displayType="text" thousandSeparator={true} value={tableItem.LastRun}></NumberFormat>
        </span>
      </SimpleTableCell>
  );
}

function renderLastRun2ColumnCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IDataAlgoList>,
  tableItem: IDataAlgoList
): JSX.Element {
  return (
        <SimpleTableCell
          key={"col-" + columnIndex}
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          >
          <span>
            <NumberFormat displayType="text" thousandSeparator={true} value={tableItem.LastRun2}></NumberFormat>
          </span>
      </SimpleTableCell>
  );
}

function renderThisMonthColumnCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IDataAlgoList>,
  tableItem: IDataAlgoList
): JSX.Element {
  return (
        <SimpleTableCell
          key={"col-" + columnIndex}
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          >
        <span>
          <NumberFormat displayType="text" thousandSeparator={true} value={tableItem.ThisMonth}></NumberFormat>
        </span>
      </SimpleTableCell>
  );
}

function renderThisMonth2ColumnCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IDataAlgoList>,
  tableItem: IDataAlgoList
): JSX.Element {
  return (
        <SimpleTableCell
          key={"col-" + columnIndex}
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          >
          <span>
            <NumberFormat displayType="text" thousandSeparator={true} value={tableItem.ThisMonth2}></NumberFormat>
          </span>
      </SimpleTableCell>
  );
}

function renderThisYearColumnCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IDataAlgoList>,
  tableItem: IDataAlgoList
): JSX.Element {
  return (
        <SimpleTableCell
          key={"col-" + columnIndex}
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          >
        <span>
          <NumberFormat displayType="text" thousandSeparator={true} value={tableItem.ThisYear}></NumberFormat>
        </span>
      </SimpleTableCell>
  );
}

function renderThisYear2ColumnCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IDataAlgoList>,
  tableItem: IDataAlgoList
): JSX.Element {
  return (
        <SimpleTableCell
          key={"col-" + columnIndex}
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          >
          <span>
            <NumberFormat displayType="text" thousandSeparator={true} value={tableItem.ThisYear2}></NumberFormat>
          </span>
      </SimpleTableCell>
  );
}

function renderToDateColumnCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IDataAlgoList>,
  tableItem: IDataAlgoList
): JSX.Element {
  return (
        <SimpleTableCell
          key={"col-" + columnIndex}
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          >
          <span>
            <NumberFormat displayType="text" thousandSeparator={true} value={tableItem.ToDate}></NumberFormat>
          </span>
      </SimpleTableCell>
  );
}

function renderToDate2ColumnCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IDataAlgoList>,
  tableItem: IDataAlgoList
): JSX.Element {
  return (
        <SimpleTableCell
          key={"col-" + columnIndex}
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          >
          <span>
            <NumberFormat displayType="text" thousandSeparator={true} value={tableItem.ToDate2}></NumberFormat>
          </span>
      </SimpleTableCell>
  );
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(AlgorithmsAnalytics);
