// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
// https://github.com/Azure-Samples/application-insights-react-demo
import React, { Component } from 'react'
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';


let appInsights:any = null;
/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
    let reactPlugin:any = null;
    
    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = () => {

        const instrumentationKey = `${process.env.REACT_APP_SONIC_WEBAPP_INSIGHTS_ID}`

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: null
                    }
                }
            }
        });

        appInsights.loadAppInsights();
    };

    return {reactPlugin, appInsights, initialize};
};

export const ctService = createTelemetryService();
export const getAppInsights = () => appInsights;