import moment from "moment";
import { DataQueryIndex } from '../Interface/IPowerQuery'
import { sonicReportUsers } from '../../ConfigData/ReportToUsers'
import { SystemCurationForDEV, SystemCurationForPPE, SystemCurationForProd } from '../../ConfigData/CurationNameAndIdMapping'
import { sonicSystemUsers } from '../../ConfigData/SystemUser'

export function formatDateTime(date: string) {
    if (!date || date === '') {
        return ''
    }

    const localDate = new Date(moment(date).local().valueOf())

    let localDateString = localDate.toString()
    if (localDateString.indexOf("GMT") > 0) {
        localDateString = localDateString.substring(0, localDateString.indexOf("GMT"))
    }

    return localDateString

    // 2021-12-20T08:59:52.291585Z
    if (date.indexOf(".") > 0) {
        date = date.substring(0, date.indexOf("."))
    }

    // 2021-12-21T23:10:48Z
    if (date.endsWith("Z")) {
        date = date.replace("Z", "")
    }


    // test
    return moment.utc(date).format('YYYY-MM-DDTHH:mm:ssZ')
}

export function checkUserExsit(currentUser: string, users: string) {
    if (!users || users === '') {
        return false
    }

    const lstUser = users.replaceAll(",", ";").split(";")
    const findUser = lstUser.filter(t => t === currentUser)

    if (findUser && findUser.length > 0) {
        return true
    }

    return false
}

/**
 * 
 * @param users 
 * @returns 
 */
export function getUserCount(users: string) {
    if (!users || users === '') {
        return 0
    }

    const lstUser = users.replaceAll(",", ";").split(";")

    return lstUser.length === 0 ? 1 : lstUser.length
}

/**
 * get curation name by id
 * @param curationName 
 * @returns 
 */
export function getCurationIdByName(curationName: string) {
    let sonic_Env = process.env.REACT_APP_SONIC_ENV
    let curationItem = null
    if (sonic_Env === "dev") {
        curationItem = SystemCurationForDEV.find(t => t.curationName === curationName)
    }
    else if (sonic_Env === "ppe") {
        curationItem = SystemCurationForPPE.find(t => t.curationName === curationName)
    }
    else if (sonic_Env === "prod") {
        curationItem = SystemCurationForProd.find(t => t.curationName === curationName)
    }

    return curationItem && curationItem.curationId
}

export function getDefaultDateTime() {
    let currentDate = new Date();
    let year = currentDate.getFullYear()
    let month = currentDate.getMonth() + 1

    if (month < 7) {
        year = year - 1
    }

    return `${year}-07-01T14:55:33+00:00`
}

export function getCurrentIndex(indexList: DataQueryIndex[], searchIndex: string, indexPrefix: string): DataQueryIndex {
    let currentIndex;
    if(indexPrefix && indexPrefix !== '') {
        currentIndex = indexList.find(item => item.IndexPrefix === indexPrefix)
    }else {
        currentIndex = indexList.find(item => item.metadataSchema === searchIndex)
    }
    if (currentIndex) {
        return currentIndex
    }

    return indexList[0]
}

/**
 * get ticket or mail send status
 * @param currentState 
 * @returns 
 */
export function buildTicketAndMailStates(currentState: string) {
    if (currentState?.toLowerCase() === "succeed") {
        return "Yes";
    }
    else if (currentState?.toLowerCase() === "failed") {
        return "No";
    }
    else {
        return currentState
    }
}

/**
 * check the url address is correct
 */
export function checkURLFormatIsCorrect(urlString: string) {
    var reg = /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]*\.)+[a-zA-Z]+)(:\d+)?(\/.*)?$/
    return reg.test(urlString.trim())
}

/**
 * check is sonic user
 * @param userName 
 * @returns 
 */
export function isSonicUser(userName: string) {
    if (userName === "") {
        return true
    }

    return sonicReportUsers.some(t => t.userName === userName)
}

export function convertUTCToPaciflic(inputUTCDateTime: string) {

    let date = new Date(inputUTCDateTime);
    let dateUTC = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())

    let datePST = new Date(dateUTC.getFullYear(), dateUTC.getMonth(), dateUTC.getDate(), dateUTC.getHours() - 8, dateUTC.getMinutes(), dateUTC.getSeconds())


    return datePST
}

export function getUTCDateTime() {
    let date = new Date()

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
}

export function getPSTDateTime() {
    let date = new Date()

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours() - 8, date.getUTCMinutes(), date.getUTCSeconds())
}

export function getCurrentPSTDateInfo() {
    let pstDate = getPSTDateTime()
    let pstDate2 = getPSTDateTime()
    var firstIndex = pstDate.getDate() - pstDate.getDay()
    var lastIndex = firstIndex + 6

    var firstDateOfWeek = new Date(pstDate.setDate(firstIndex));
    var lastDateOfWeek = new Date(pstDate.setDate(lastIndex));
    return {
        firstIndex,
        lastIndex,
        firstDateOfWeek,
        lastDateOfWeek,
        PSTDate: pstDate2
    }

    //return new Date(pstDate.setDate(first))
}

export function getMonthName(inputMonth: number) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return monthNames[inputMonth]
}

/**
 * 
 * @param inputValue 
 * @returns 
 */
export function StringToArray(inputValue: string) {

    if (!inputValue || inputValue === '') {
        return []
    }

    return inputValue.split(";")
}

/**
 * check is system user
 * @param userName 
 * @returns 
 */
export function isSystemUser(userName: string) {
    if (!userName || userName === '') {
        return false
    }

    return sonicSystemUsers.some(t => t.userName === userName)
}


export function parseIntFromString(inputValue: string) {
    const parsedNumber = parseInt(inputValue);
    return isNaN(parsedNumber) ? 0 : parsedNumber;
}

/**
   * Get work item title/description
   */
export function getWorkItemPatternValue(WorkItemPatternPropsValue: string,
    metadataSchema: string,
    algorithemType: string,
    LMCDataSetTicketPatternValue: string,
    RemediateTicketPatternValue: string,
    RemediateItOrNot: boolean
) {
    if (metadataSchema?.startsWith("gitpub-metadata-schema-lmc-") && algorithemType === "Blank") {
        if (WorkItemPatternPropsValue) {
            return WorkItemPatternPropsValue
        }

        return LMCDataSetTicketPatternValue
    }

    if(algorithemType === "PoliCheckCrawler") {
        return RemediateTicketPatternValue
    }

    if (RemediateItOrNot) {
        if (WorkItemPatternPropsValue) {
            return WorkItemPatternPropsValue
        }

        return RemediateTicketPatternValue
    }

    return WorkItemPatternPropsValue;
}

const fillZero = (time: number) => {
    return String(time).padStart(2, '0');
}
/**
 * format date to mm/dd/yyyy
 * @param date 
 * @returns 
 */
export function formatDateToMMDDYYYY(date?: Date){
  return date ? `${fillZero(date.getUTCMonth()+1)}/${fillZero(date.getUTCDate())}/${date.getUTCFullYear()}` : ''
};

export function formatMMDDYYToDate(dateStr: string) {
    const timeList = dateStr.split('/');
    const date = new Date();
    date.setUTCDate(+timeList[1]);
    date.setUTCMonth(+timeList[0]);
    date.setUTCFullYear(+timeList[2]);
    return date;
}

/**
 * format date to yyyy-mm-ddThh:mm:ss.msZ
 * @param date 
 * @returns 
 */
export function formatDateToUTCStringDate(date?: Date): string {
    return date? `${date.getUTCFullYear()}-${fillZero(date.getUTCMonth() + 1)}-${fillZero(date.getUTCDate())}T${fillZero(date.getUTCHours())}:${fillZero(date.getUTCMinutes())}:${fillZero(date.getUTCSeconds())}.${fillZero(date.getUTCMilliseconds())}Z`: "";
}

/**
 * format yyyy-mm-ddThh:mm:ss.msZ to date
 * @param dataStr 
 * @returns 
 */
export function formatUTCStringDateToDate(dataStr: string): Date {
    if(dataStr && dataStr.trim() !== '') {
        const dateString = dataStr.substring(0,dataStr.length-1)
        const UTCTime = dateString.split('T')
        // UTCTime[0]:yyyy-mm-dd
        const date = new Date(UTCTime[0])
        // UTCTime[1] hh:mm:ss.ms
        const time = UTCTime[1]
        const timeArr = time.split(':')
        date.setUTCHours(+timeArr[0])
        date.setUTCMinutes(+timeArr[1])
        const secondArr = timeArr[2].split('.')
        date.setUTCSeconds(+secondArr[0])
        date.setUTCMilliseconds(+secondArr[1])
        return date
    }
}



