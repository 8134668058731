import React, { Component } from "react";
import { connect } from "react-redux";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import "./AppLayout.css"

type AppLayoutProps = {
  renderBreadcrumb?: () => JSX.Element;
  renderContentPane: () => JSX.Element;
};
initializeIcons();

class AppLayout extends Component<AppLayoutProps> {
  render() {
    return (
      <div className="appContainer">
        <Surface background={SurfaceBackground.neutral}>        
        <div className="appRoot">
          {this.props.renderBreadcrumb && this.props.renderBreadcrumb()}
          <Page className="flex-grow">
          {this.props.renderContentPane()}
          </Page>
          </div> 
          </Surface>
      </div>
    );
  }
}

export default connect<AppLayoutProps>(null, null)(AppLayout);
