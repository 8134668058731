import { IListBoxItem } from "azure-devops-ui/ListBox";
import { Icon } from "azure-devops-ui/Icon";
import './CascadingDropdown.css';
import CustomDropdown from "./CustomDropdown";
import { useEffect, useState, Fragment } from "react";
import { ITicketAssignToFields } from "../../Interface/ITicketADO"
interface IConfigObject {
    id: string,
    dropdownList: Array<IListBoxItem<{}>>,
    priority: number,
    currentSelect: string
}
interface IUserRelatedFieldsProp {
    dropdownItems: Array<IListBoxItem<{}>>,
    getConfigOrder: Function,
    defaultItems?: ITicketAssignToFields[]
    children?: React.ReactNode
}
/**
 * pass dropdownItems like [
 *  {id: 'xxx', text: 'xxx'}
 * ]
 * children is optional, use as default config value
 * @param props 
 * @returns 
 */
export default function CascadingDropdown(props: IUserRelatedFieldsProp) {

    const [configObjList, setConfigObjList] = useState<Array<IConfigObject>>([]);

    useEffect(()=>{
        const initConfigObjList = [];
        props.dropdownItems.forEach((configItem,index)=>{
            const newConfigObj:IConfigObject = {
                id: '',
                dropdownList: [],
                priority: null,
                currentSelect: ''
            };
            if(index === 0) {
                newConfigObj.id = configItem.id
                newConfigObj.dropdownList = props.dropdownItems
                newConfigObj.priority = index+1
            }else {
                newConfigObj.id = configItem.id
                newConfigObj.priority = index+1
            }            
            initConfigObjList.push(newConfigObj)
        })
        const isSameSchema = props.defaultItems?.every(defaultItem => {
            return props.dropdownItems.some(item => 
                item.text === defaultItem.FieldName
            )
        })
        if(props.defaultItems?.length > 0 && isSameSchema) {
            const selectedItems = []
            initConfigObjList.forEach((configObj,configObjIndex) => {
                props.defaultItems.forEach(item => {
                    if(configObjIndex < props.defaultItems.length && configObj.priority === item.Priority) {
                        configObj.currentSelect = item.FieldName
                        configObj.dropdownList = props.dropdownItems.filter(item=> !selectedItems.includes(item.text))
                        selectedItems.push(item.FieldName)
                    }
                    if(configObjIndex === props.defaultItems.length) {
                        // have dropdownList, but have no select
                        configObj.dropdownList = props.dropdownItems.filter(item=> !selectedItems.includes(item.text))
                    }
                })
            })
        }
        setConfigObjList(initConfigObjList);
    },[props.dropdownItems,props.defaultItems])

    const renderLength = props.dropdownItems.length;

    const resetAllDropdowns = (configName: string, priority: number) =>{
        const selectedItems = [];
        if(priority <= props.dropdownItems.length) {
            const tempList = [...configObjList];
            const newConfigObjList = []; 
            // find high priority configs
            tempList.forEach(item => {
                if(item.priority === priority) {
                    item.currentSelect = configName;
                }
                if(item.priority<=priority) {
                    selectedItems.push(item.currentSelect);
                }
            })
            // reset low priority configs
            tempList.forEach(item=>{
                let newConfigObj:IConfigObject = {
                    id: '',
                    dropdownList: [],
                    priority: null,
                    currentSelect: ''
                };
                if(item.priority === priority +1) {
                    newConfigObj = {
                        id: item.id,
                        dropdownList: props.dropdownItems.filter(item=> !selectedItems.includes(item.text)),
                        priority: item.priority,
                        currentSelect: ''
                    }
                }else if(item.priority > priority +1) {
                    newConfigObj = {
                        id: item.id,
                        dropdownList: [],
                        priority: item.priority,
                        currentSelect: ''
                    }
                }else {
                    newConfigObj = {
                        id: item.id,
                        dropdownList: item.dropdownList,
                        priority: item.priority,
                        currentSelect: item.currentSelect
                    }
                }
                newConfigObjList.push(newConfigObj);
            })
            const configOrder = [];
            newConfigObjList.forEach(item => {
                if(item.currentSelect) {
                    configOrder.push({
                        priority: item.priority,
                        currentSelect: item.currentSelect
                    });
                }
            })
            setConfigObjList(newConfigObjList);
            props.getConfigOrder(configOrder);
        }
    }


    return (
        <div className="user_related_fields_main">
            {
                configObjList.map((item,index) => {
                    return (
                        <Fragment key={index}>
                            <CustomDropdown
                                priority={item.priority}
                                items={item.dropdownList}
                                currentSelect={item.currentSelect}
                                onSelectFun={resetAllDropdowns}
                            ></CustomDropdown>
                            {index < renderLength-1 && <Icon className="user_related_fields_arrow_right" iconName="ChevronRight" />}
                        </Fragment>
                    )
                })
            }
            {
                props.children? 
                <>
                    <Icon className="user_related_fields_arrow_right" iconName="ChevronRight" />
                    {props.children}
                </>
                : ''
            }
        </div>
    )
}