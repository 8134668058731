import React, { Component } from 'react'

import {
    PrimaryButton,
    Dialog,
    DialogFooter,
    DialogType,
    DefaultButton
  } from "@fluentui/react";

  interface ConfirmationDialogProps {
    isOpen: boolean
    isBlocking: boolean
    dialogTitle: string
    dialogText: string
    confirmButtonText: string
    onConfirm: () => void
    cancelButtonText: string
    onCancel: () => void
  }

export default class ConfirmationDialog extends Component<ConfirmationDialogProps> {
  render() {
    if (!this.props.isOpen) return null

    console.log("oncancle", !this.props.onCancel)
    return (
        <Dialog
        hidden={!this.props.isOpen}
        dialogContentProps={{
          type: DialogType.normal,
          subText: this.props.dialogText
        }}
        modalProps={{ isBlocking: this.props.isBlocking }}
        onDismiss={this.props.onCancel}
        title={this.props.dialogTitle}
      >
        <DialogFooter>
          {!this.props.onConfirm ? null : (
            <PrimaryButton onClick={this.props.onConfirm}>
              {this.props.confirmButtonText}
            </PrimaryButton>
          )}
          {!this.props.onCancel ? null : (
            <DefaultButton onClick={this.props.onCancel}>
              {this.props.cancelButtonText}
            </DefaultButton>
          )}
        </DialogFooter>
      </Dialog>
    )
  }
}
