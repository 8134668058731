import { useEffect, useState } from "react"
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Table, renderSimpleCell, ITableColumn, SimpleTableCell } from "azure-devops-ui/Table";
import { getUTCDateTime, formatUTCStringDateToDate } from "../../../Utilities/CommonFunction";
import CustomStatus from "../../../Modules/CustomStatus/CustomStatus";
import { Button } from "azure-devops-ui/Button";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";

interface IConfigColumn {
    id: string,
    name: string,
    maxWidth?: number,
    readonly?: boolean,
    renderCell?: ()=> React.ReactNode
    sortProps?: {
        ariaLabelAscending: string,
        ariaLabelDescending: string,
    },
    width: number
}
interface IMiniAppCommonTableProps {
    tableColumns: IConfigColumn[],
    sourceData: any[]
    openDialog: (buttonLabel: string, data: ICurrentRow)=>void
}

interface ICurrentRow {
    tableItem: any,
    rowIndex: number
}

const defaultSortProps = {
    ariaLabelAscending: "Sorted low to high",
    ariaLabelDescending: "Sorted high to low",
}

export default  function MiniAppCommonTable(props: IMiniAppCommonTableProps) {
    const[columns, setColumns] = useState([])

    function buttonClick(buttonLabel: string, data: ICurrentRow) {
        props.openDialog(buttonLabel,data)
    }

    function checkExpireTime(expireTime: string) {
        if(!expireTime) {
            return true
        }
        const date = formatUTCStringDateToDate(expireTime)
        if(date > getUTCDateTime()) {
            return true
        }
        return false
    }

    function checkUndo(tableItem) { 
        return !tableItem.issueId || !checkExpireTime(tableItem.expireTime) || tableItem.status !== 'Waiting'
    }

    const getRowStatus = (tableItem) => {
        const isExpired = !checkExpireTime(tableItem.expireTime);
        const rowStatus = {
            status: 'Failed',
            message: 'A scanned issue.'
        }
        if(tableItem.status === 'Waiting' && !isExpired) {
            rowStatus.status = 'PartialSuccess'
            rowStatus.message = 'False positive is reported successfully! Waiting for approval.'
        }
        if(tableItem.status === 'Passed' && !isExpired) {
            rowStatus.status = 'Success'
            rowStatus.message = 'False positive is approved successfully.'
        }
        if((tableItem.status === 'Passed' || tableItem.status === 'Waiting') && isExpired) {
            rowStatus.status = 'Warning'
            rowStatus.message = 'False positive report is expired and needs to be renewed.'
        }
        return rowStatus
    }

    function renderStatusCell(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<any>,
        tableItem: any) {
            const rowStatus = getRowStatus(tableItem)
            return(
                <SimpleTableCell
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                key={tableColumn.id}
                contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
                >
                    <CustomStatus type={rowStatus.status} toolTipMessage={rowStatus.message} className="pii_check_table_status_cell"/>
                </SimpleTableCell>
            )
    }

    function renderButtonCell(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<any>,
        tableItem: any) {
            if(process.env.REACT_APP_SONIC_ENV === 'dev') {
                return (
                    <SimpleTableCell
                        columnIndex={columnIndex}
                        tableColumn={tableColumn}
                        key={tableColumn.id}
                        contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
                    >
                        {
                            !tableItem.issueId &&
                            <Button
                                text="Report as false positive"
                                style={{minWidth: '80px'}}
                                onClick={()=>{buttonClick('Report as false positive',{tableItem,rowIndex})}}
                            />
                        }
                        {
                            (tableItem.issueId && !checkExpireTime(tableItem.expireTime)) &&
                            <Button
                                text="Renew"
                                style={{minWidth: '80px'}}
                                onClick={()=>{buttonClick('Renew',{tableItem,rowIndex})}}
                            />
                        }
                        {
                            !checkUndo(tableItem) &&
                            <Button
                                text="Undo"
                                style={{minWidth: '80px'}}
                                onClick={()=>{buttonClick('Undo',{tableItem,rowIndex})}}
                            />
                        }
                    </SimpleTableCell>
                )
            }
            return (
                <SimpleTableCell
                    columnIndex={columnIndex}
                    tableColumn={tableColumn}
                    key={tableColumn.id}
                    contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
                    >
                        {
                            !tableItem.issueId &&
                            <Button
                                text="Report as false positive"
                                style={{minWidth: '80px'}}
                                onClick={()=>{buttonClick('Report as false positive',{tableItem,rowIndex})}}
                            />
                        }
                </SimpleTableCell>
            )
        }

    useEffect(()=>{
        const newColumns = []
        props.tableColumns.forEach(item => {
            const formatColumn = {
                id: item.id,
                name: item.name,
                maxWidth: item.maxWidth,
                readonly: item.readonly? item.readonly: true,
                sortProps: item.sortProps? item.sortProps: defaultSortProps,
                renderCell: item.renderCell? item.renderCell: renderSimpleCell,
                width: new ObservableValue(-item.width)
            }
            newColumns.push(formatColumn)
        })
        newColumns.push({
            id: "status",
            name: "Status",
            maxWidth: 300,
            readonly: true,
            renderCell: renderStatusCell,
            sortProps: {
                ariaLabelAscending: "Sorted low to high",
                ariaLabelDescending: "Sorted high to low",
            },
            width: new ObservableValue(-60)
        })
        newColumns.push({
            id: "actions",
            name: "Actions",
            maxWidth: 300,
            readonly: true,
            renderCell: renderButtonCell,
            width: new ObservableValue(-80)
        })
        setColumns(newColumns)
    },[props.tableColumns])

    return (
        <Table<Partial<any>>
            columns={columns}
            itemProvider={new ArrayItemProvider<any>(props.sourceData)}
            showLines={true}
            pageSize={2000}
            containerClassName="msacct-devops-table"
            showScroll={false}
        />
    )
}