
import {IQueryExpressions} from '../components/Interface/IQueryExpressions'
export const AlgoanalyticsData = [
    {
        "DocumentType":"broken",
        "id":"a50229f1-a6ae-4fc3-b20a-e8f058639a9d",
        "CurationConfigurationName": "Broken Link Checker",
        "CreatedDateTime": "2022-03-28T10:13:33.7578826Z",
        "Status": "Enabled",
        "RunId": 3882,
        "RunBuildNumber": "20220407.1",
        "RunStatus": "Completed",
        "RunResult": "Failed",

        "RunUrl": "https://dev.azure.com/AutoContentCuration-Dev/9df51e56-58b0-4131-a532-2563a49b5fe5/_apis/build/Builds/3882",
        "RunDescription": "Test 1",
        "RunStartTime": "2022-04-07T00:00:30.5141518Z",
        "RunFinishTime": "2022-04-07T00:00:30.5141518Z",
        "RunSourceBranch": "refs/heads/main",
        "TimesRuntoDate": 15,
        "LastRun": 178,

        "ThisMonth": 503,
        "ThisYear": 871,
        "ToDate": 4595,
        "LastRun2": 84,
        "ThisMonth2": 399,
        "ThisYear2": 697,
        "ToDate2": 3490,
      },
      {
        "DocumentType":"curation",
        "id":"a50229f1-a6ae-4fc3-b20a-e8f058639a9d",
        "CurationConfigurationName": "Curation Standards",
        "CreatedDateTime": "2022-03-28T10:13:33.7578826Z",
        "Status": "Enabled",
        "RunId": 3882,
        "RunBuildNumber": "20220407.1",
        "RunStatus": "Completed",
        "RunResult": "Failed",

        "RunUrl": "https://dev.azure.com/AutoContentCuration-Dev/9df51e56-58b0-4131-a532-2563a49b5fe5/_apis/build/Builds/3882",
        "RunDescription": "Test 1",
        "RunStartTime": "2022-04-07T00:00:30.5141518Z",
        "RunFinishTime": "2022-04-07T00:00:30.5141518Z",
        "RunSourceBranch": "refs/heads/main",
        "TimesRuntoDate": 13,
        "LastRun": 1023,

        "ThisMonth": 4033,
        "ThisYear": 12072,
        "ToDate": 42033,
        "LastRun2": 232,
        "ThisMonth2": 1295,
        "ThisYear2": 6493,
        "ToDate2": 77553,
      },
      {
        "DocumentType":"product",
        "id":"a50229f1-a6ae-4fc3-b20a-e8f058639a9d",
        "CurationConfigurationName": "Product Lifecycle Analyzer",
        "CreatedDateTime": "2022-03-28T10:13:33.7578826Z",
        "Status": "Enabled",
        "RunId": 3882,
        "RunBuildNumber": "20220407.1",
        "RunStatus": "Completed",
        "RunResult": "Failed",
        "RunUrl": "https://dev.azure.com/AutoContentCuration-Dev/9df51e56-58b0-4131-a532-2563a49b5fe5/_apis/build/Builds/3882",
        "RunDescription": "Test 1",
        "RunStartTime": "2022-04-07T00:00:30.5141518Z",
        "RunFinishTime": "2022-04-07T00:00:30.5141518Z",
        "RunSourceBranch": "refs/heads/main",
        "TimesRuntoDate": 22,
        "LastRun": 452,

        "ThisMonth": 298,
        "ThisYear": 844,
        "ToDate": 5676,
        "LastRun2": 107,
        "ThisMonth2": 201,
        "ThisYear2": 572,
        "ToDate2": 4711,
      }
]
