import { DataQueryIndex, DataField, SearchResultItem, IApiResponseState, IWelcomeStats, IDataSource } from '../../components/Interface/IPowerQuery'
import { ITaskSearch } from '../../components/Interface/ITaskSearch'
import { Task } from '../../components/Interface/ITask'
import { ICurationRun, ICurationRunWithName } from '../../components/Interface/ICurationRun'
import { ITaskRunsResult } from '../../components/Interface/ITaskRunsResult'
import { ITagItem } from '../../components/Interface/ITag'

export type SHOW_API_SUCCESS = 'SHOW_API_SUCCESS'
export const SHOW_API_SUCCESS: SHOW_API_SUCCESS = 'SHOW_API_SUCCESS'
export type ShowApiSuccessAction = {
  type: SHOW_API_SUCCESS
}

export type SHOW_API_ERROR = 'SHOW_API_ERROR'
export const SHOW_API_ERROR: SHOW_API_ERROR = 'SHOW_API_ERROR'
export type ShowApiErrorAction = {
  type: SHOW_API_ERROR
  apiResponseState: IApiResponseState
}

export type CLEAR_API_RESPONSE_STATUS = 'CLEAR_API_RESPONSE_STATUS'
export const CLEAR_API_RESPONSE_STATUS: CLEAR_API_RESPONSE_STATUS = 'CLEAR_API_RESPONSE_STATUS'
export type ClearApiResponseStatusAction = {
  type: CLEAR_API_RESPONSE_STATUS
}


/** Start: For PowerQuery Page  */
export type UPDATE_DATA_QUERY_INDEX_LIST = 'UPDATE_DATA_QUERY_INDEX_LIST'
export const UPDATE_DATA_QUERY_INDEX_LIST: UPDATE_DATA_QUERY_INDEX_LIST = 'UPDATE_DATA_QUERY_INDEX_LIST'
export type UpdateDataQueryIndexListAction = {
  type: UPDATE_DATA_QUERY_INDEX_LIST
  indexList: DataQueryIndex[]
}

export type UPDATE_DATA_FIELDS = 'UPDATE_DATA_FIELDS'
export const UPDATE_DATA_FIELDS: UPDATE_DATA_FIELDS = 'UPDATE_DATA_FIELDS'
export type UpdateDataFieldsAction = {
  type: UPDATE_DATA_FIELDS
  metadataSchemaId: string
  metadataFields: DataField[]
}

export type SELECT_INDEX = 'SELECT_INDEX'
export const SELECT_INDEX: SELECT_INDEX = 'SELECT_INDEX'
export type SelectIndexAction = {
  type: SELECT_INDEX
  index: DataQueryIndex
}

export type POWER_QUERY_SEARCH = 'POWER_QUERY_SEARCH'
export const POWER_QUERY_SEARCH: POWER_QUERY_SEARCH = 'POWER_QUERY_SEARCH'
export type SearchResultAction = {
  type: POWER_QUERY_SEARCH
  // searchResults: DataResult
  searchResult: SearchResultItem
}

export type ON_COLUMN_OPTIONS_OPEN = 'ON_COLUMN_OPTIONS_OPEN'
export const ON_COLUMN_OPTIONS_OPEN: ON_COLUMN_OPTIONS_OPEN =
  'ON_COLUMN_OPTIONS_OPEN'
export type OnColumnOptionsOpenAction = {
  type: ON_COLUMN_OPTIONS_OPEN
}

export type ON_COLUMN_OPTIONS_CLOSE = 'ON_COLUMN_OPTIONS_CLOSE'
export const ON_COLUMN_OPTIONS_CLOSE: ON_COLUMN_OPTIONS_CLOSE =
  'ON_COLUMN_OPTIONS_CLOSE'
export type OnColumnOptionsCloseAction = {
  type: ON_COLUMN_OPTIONS_CLOSE
}

export type ON_CONFIGURATION_SETTING_OPEN = 'ON_CONFIGURATION_SETTING_OPEN'
export const ON_CONFIGURATION_SETTING_OPEN: ON_CONFIGURATION_SETTING_OPEN =
  'ON_CONFIGURATION_SETTING_OPEN'
export type OnConfigurationSettingOpenAction = {
  type: ON_CONFIGURATION_SETTING_OPEN
}

export type ON_CONFIGURATION_SETTING_CLOSE = 'ON_CONFIGURATION_SETTING_CLOSE'
export const ON_CONFIGURATION_SETTING_CLOSE: ON_CONFIGURATION_SETTING_CLOSE =
  'ON_CONFIGURATION_SETTING_CLOSE'
export type OnConfigurationSettingCloseAction = {
  type: ON_CONFIGURATION_SETTING_CLOSE
}

export type CLEAR_METADATA_QUERY_SEARCH = 'CLEAR_METADATA_QUERY_SEARCH'
export const CLEAR_METADATA_QUERY_SEARCH: CLEAR_METADATA_QUERY_SEARCH =
  'CLEAR_METADATA_QUERY_SEARCH'
export type ClearMetaDataQuerySearchAction = {
  type: CLEAR_METADATA_QUERY_SEARCH
}

export type LOAD_POWER_QUERY_SEARCH = 'LOAD_POWER_QUERY_SEARCH'
export const LOAD_POWER_QUERY_SEARCH: LOAD_POWER_QUERY_SEARCH =
  'LOAD_POWER_QUERY_SEARCH'
export type LoadPowerQuerySearchAction = {
  type: LOAD_POWER_QUERY_SEARCH
  status: 'start' | 'finish'
}

export type ON_SELECT_COLUMNS = 'ON_SELECT_COLUMNS'
export const ON_SELECT_COLUMNS: ON_SELECT_COLUMNS = 'ON_SELECT_COLUMNS'
export type OnSelectColumnsAction = {
  type: ON_SELECT_COLUMNS
  keys: string[]
}

export type SELECT_BRANCH = 'SELECT_BRANCH'
export const SELECT_BRANCH: SELECT_BRANCH = 'SELECT_BRANCH'
export type SelectBranchAction = {
  type: SELECT_BRANCH
  branch: string
}


/** End: For PowerQuery Page */

/** Start: For Task Page */
export type ALL_CURATION_NAME_SEARCH = 'ALL_CURATION_NAME_SEARCH'
export const ALL_CURATION_NAME_SEARCH: ALL_CURATION_NAME_SEARCH = 'ALL_CURATION_NAME_SEARCH'
export type AllCurationNameSearchAction = {
  type: ALL_CURATION_NAME_SEARCH
  // items: Task[]
  allCurationName: string[]
}

export type ALL_TASK_SEARCH = 'ALL_TASK_SEARCH'
export const ALL_TASK_SEARCH: ALL_TASK_SEARCH = 'ALL_TASK_SEARCH'
export type AllTaskSearchAction = {
  type: ALL_TASK_SEARCH
  // items: Task[]
  allTaskList: ITaskSearch[]
}

export type CLEAR_ALL_TASK_SEARCH = 'CLEAR_ALL_TASK_SEARCH'
export const CLEAR_ALL_TASK_SEARCH: CLEAR_ALL_TASK_SEARCH = 'CLEAR_ALL_TASK_SEARCH'
export type ClearAllTaskSearchAction = {
  type: CLEAR_ALL_TASK_SEARCH
}

export type RECENT_TASK_SEARCH = 'RECENT_TASK_SEARCH'
export const RECENT_TASK_SEARCH: RECENT_TASK_SEARCH = 'RECENT_TASK_SEARCH'
export type RecentTaskSearchAction = {
  type: RECENT_TASK_SEARCH
  recentTaskList: ITaskSearch[]
}

export type LOAD_TASK_SEARCH = 'LOAD_TASK_SEARCH'
export const LOAD_TASK_SEARCH: LOAD_TASK_SEARCH = 'LOAD_TASK_SEARCH'
export type LoadTaskSearchAction = {
  type: LOAD_TASK_SEARCH
  status: 'start' | 'finish'
}

export type TASK_SUBMIT = 'TASK_SUBMIT'
export const TASK_SUBMIT: TASK_SUBMIT = 'TASK_SUBMIT'
export type TaskSubmitAction = {
  type: TASK_SUBMIT
}

export type TASK_DELETE = 'TASK_DELETE'
export const TASK_DELETE: TASK_DELETE = 'TASK_DELETE'
export type TaskDeleteAction = {
  type: TASK_DELETE
}

export type INIT_TASK_CREATE = 'INIT_TASK_CREATE'
export const INIT_TASK_CREATE: INIT_TASK_CREATE = 'INIT_TASK_CREATE'
export type InitTaskCreateAction = {
  type: INIT_TASK_CREATE
}


export type TASK_QUERY_BY_NAME = 'TASK_QUERY_BY_NAME'
export const TASK_QUERY_BY_NAME: TASK_QUERY_BY_NAME = 'TASK_QUERY_BY_NAME'
export type SearchTaskByNameAction = {
  type: TASK_QUERY_BY_NAME
  taskEditItem: Task
}

export type CLEAR_TASK_QUERY_BY_NAME = 'CLEAR_TASK_QUERY_BY_NAME'
export const CLEAR_TASK_QUERY_BY_NAME: CLEAR_TASK_QUERY_BY_NAME = 'CLEAR_TASK_QUERY_BY_NAME'
export type ClearSearchTaskByNameAction = {
  type: CLEAR_TASK_QUERY_BY_NAME
}

export type TASK_STATUS_SWITCH = 'TASK_STATUS_SWITCH'
export const TASK_STATUS_SWITCH: TASK_STATUS_SWITCH = 'TASK_STATUS_SWITCH'
export type SwitchTaskStatusAction = {
  type: TASK_STATUS_SWITCH
}

export type CURATION_RUN = 'CURATION_RUN'
export const CURATION_RUN: CURATION_RUN = 'CURATION_RUN'
export type RunCurationAction = {
  type: CURATION_RUN
}

export type CURATION_RUNS_SEARCH = 'CURATION_RUNS_SEARCH'
export const CURATION_RUNS_SEARCH: CURATION_RUNS_SEARCH = 'CURATION_RUNS_SEARCH'
export type CurationRunsSearchAction = {
  type: CURATION_RUNS_SEARCH
  curationRuns: ICurationRun[]
}

export type CURATION_RUNS_SEARCH_BY_NAME = 'CURATION_RUNS_SEARCH_BY_NAME'
export const CURATION_RUNS_SEARCH_BY_NAME: CURATION_RUNS_SEARCH_BY_NAME = 'CURATION_RUNS_SEARCH_BY_NAME'
export type CurationRunsSearchByNameAction = {
  type: CURATION_RUNS_SEARCH_BY_NAME
  curationRunsWithName: ICurationRunWithName
}

export type MY_CURATION_RUNS_SEARCH = 'MY_CURATION_RUNS_SEARCH'
export const MY_CURATION_RUNS_SEARCH: MY_CURATION_RUNS_SEARCH = 'MY_CURATION_RUNS_SEARCH'
export type MyCurationRunsSearchAction = {
  type: MY_CURATION_RUNS_SEARCH
  myCurationRuns: ICurationRunWithName[]
}

export type TASK_RUNS_RESULT_SEARCH_BY_ID = 'TASK_RUNS_RESULT_SEARCH_BY_ID'
export const TASK_RUNS_RESULT_SEARCH_BY_ID: TASK_RUNS_RESULT_SEARCH_BY_ID = 'TASK_RUNS_RESULT_SEARCH_BY_ID'
export type SearchTaskRunsResultByIdAction = {
  type: TASK_RUNS_RESULT_SEARCH_BY_ID
  taskRunsResultItem: ITaskRunsResult
}

export type CURATION_TAGS_SEARCH = 'CURATION_TAGS_SEARCH'
export const CURATION_TAGS_SEARCH: CURATION_TAGS_SEARCH = 'CURATION_TAGS_SEARCH'
export type SearchCurationTagsAction = {
  type: CURATION_TAGS_SEARCH
  curationTags: ITagItem[]
}

export type FOLLOW_USER = 'FOLLOW_USER'
export const FOLLOW_USER: FOLLOW_USER = 'FOLLOW_USER'
export type FollowUserAction = {
  type: FOLLOW_USER
}

export type UNFOLLOW_USER = 'UNFOLLOW_USER'
export const UNFOLLOW_USER: UNFOLLOW_USER = 'UNFOLLOW_USER'
export type UnFollowUserAction = {
  type: UNFOLLOW_USER
}

export type TICKET_DOWNLOAD = 'TICKET_DOWNLOAD'
export const TICKET_DOWNLOAD: TICKET_DOWNLOAD = 'TICKET_DOWNLOAD'
export type TicketDownloadAction = {
  type: TICKET_DOWNLOAD
}

export type DOWNLOAD_LOG = 'DOWNLOAD_LOG'
export const DOWNLOAD_LOG: DOWNLOAD_LOG = 'DOWNLOAD_LOG'
export type CreateDownloadLogAction = {
  type: DOWNLOAD_LOG
}

export type TICKET_DEVOPS_CONFIG = 'TICKET_DEVOPS_CONFIG'
export const TICKET_DEVOPS_CONFIG: TICKET_DEVOPS_CONFIG = 'TICKET_DEVOPS_CONFIG'
export type GetTicketDevOpsConfigAction = {
  type: TICKET_DEVOPS_CONFIG,
  ticketDevOpsConfig: any[]
}


export type BROKENLINK_SCAN_RESULT = 'BROKENLINK_SCAN_RESULT'
export const BROKENLINK_SCAN_RESULT: BROKENLINK_SCAN_RESULT = 'BROKENLINK_SCAN_RESULT'
export type GetBrokenLinkScanResult = {
  type: BROKENLINK_SCAN_RESULT,
  brokenLinkScanResult: any
}

export type CLEAR_BROKENLINK_SCAN_RESULT = 'CLEAR_BROKENLINK_SCAN_RESULT'
export const CLEAR_BROKENLINK_SCAN_RESULT: CLEAR_BROKENLINK_SCAN_RESULT = 'CLEAR_BROKENLINK_SCAN_RESULT'
export type ClearBrokenLinkScanResult = {
  type: CLEAR_BROKENLINK_SCAN_RESULT
}

export type CHILD_LINKS = 'CHILD_LINKS'
export const CHILD_LINKS: CHILD_LINKS = 'CHILD_LINKS'
export type GetChildLinks = {
  type: CHILD_LINKS,
  childLinks: any
}

export type CLEAR_CHILD_LINKS = 'CLEAR_CHILD_LINKS'
export const CLEAR_CHILD_LINKS: CLEAR_CHILD_LINKS = 'CLEAR_CHILD_LINKS'
export type ClearChildLinks = {
  type: CLEAR_CHILD_LINKS
}

export type CLEAR_DATA_REFRESH_SCHEDULE = 'CLEAR_DATA_REFRESH_SCHEDULE'
export const CLEAR_DATA_REFRESH_SCHEDULE: CLEAR_DATA_REFRESH_SCHEDULE = 'CLEAR_DATA_REFRESH_SCHEDULE'
export type ClearDataRefreshSchedule = {
  type: CLEAR_DATA_REFRESH_SCHEDULE
}

export type GET_DATA_REFRESH_SCHEDULE = 'GET_DATA_REFRESH_SCHEDULE'
export const GET_DATA_REFRESH_SCHEDULE: GET_DATA_REFRESH_SCHEDULE = 'GET_DATA_REFRESH_SCHEDULE'
export type GetDataRefreshSchedule = {
  type: GET_DATA_REFRESH_SCHEDULE,
  dataRefreshSchedule: any
}

export type GET_DATA_REFRESH_STATUS = 'GET_DATA_REFRESH_STATUS'
export const GET_DATA_REFRESH_STATUS: GET_DATA_REFRESH_STATUS = 'GET_DATA_REFRESH_STATUS'
export type GetDataRefreshStatus = {
  type: GET_DATA_REFRESH_STATUS,
  contentDataFactory: any
}




/** End: For Task Page */

/** Start: For Welcom Page */
export type WELCOME_STATS_SEARCH = 'WELCOME_STATS_SEARCH'
export const WELCOME_STATS_SEARCH: WELCOME_STATS_SEARCH = 'WELCOME_STATS_SEARCH'
export type WelcomeStatsSearchAction = {
  type: WELCOME_STATS_SEARCH
  welcomeStats: IWelcomeStats
}

export type SITE_INPROCESS_CHECK = 'SITE_INPROCESS_CHECK'
export const SITE_INPROCESS_CHECK: SITE_INPROCESS_CHECK = 'SITE_INPROCESS_CHECK'
export type SiteInProcessCheckAction = {
  type: SITE_INPROCESS_CHECK
  siteStatus: string
}
/** End: For Welcom Page */

/** Start: For DataSource Page */
export type UPLOAD_DATASOURCE_FILE = 'UPLOAD_DATASOURCE_FILE'
export const UPLOAD_DATASOURCE_FILE: UPLOAD_DATASOURCE_FILE = 'UPLOAD_DATASOURCE_FILE'
export type UploadDataSourceFileAction = {
  type: UPLOAD_DATASOURCE_FILE
}

export type DATASOURCE_LIST_SEARCH = 'DATASOURCE_LIST_SEARCH'
export const DATASOURCE_LIST_SEARCH: DATASOURCE_LIST_SEARCH = 'DATASOURCE_LIST_SEARCH'
export type DataSourceListSearchAction = {
  type: DATASOURCE_LIST_SEARCH
  dataSourceList: IDataSource[]
}

export type DLETE_DATASOURCE = 'DLETE_DATASOURCE'
export const DLETE_DATASOURCE: DLETE_DATASOURCE = 'DLETE_DATASOURCE'
export type DeleteDataSourceAction = {
  type: DLETE_DATASOURCE
}
/** End: For DataSource Page */