import React, { useState, useCallback } from 'react'
import MonacoEditor from 'react-monaco-editor';
import { Icon } from '@fluentui/react/lib/Icon';
import { CustomDialog } from "azure-devops-ui/Dialog";
import { Button } from "azure-devops-ui/Button";
import { PanelContent, PanelFooter } from "azure-devops-ui/Panel";
import { CustomHeader, HeaderTitleArea } from "azure-devops-ui/Header";

// Ticket work item props
interface TicketWorkItemProps {
    WorkItemTitlePattern: string;
    WorkItemDescriptionPattern: string;
    onTicketWorkItemApply: (titleValue: string, descriptionValue: string) => void;
    onTicketWorkItemDialogDimiss: () => void;
}

// Ticket work item state
interface ITransformTextState {
    transformText: string;
    transformIconTitle: string;
}

// This code creates a new TicketWorkItem component that renders a React component.
export default function TicketWorkItem2(props: TicketWorkItemProps) {
    const options = {
        readOnly: false,
        selectOnLineNumbers: false,
        wordWrap: 'on' as any,
        minimap: { enabled: false },
        automaticLayout: true,
        scrollbar: { horizontal: 'auto' as string } as object
    };

    // Initialize transform text state
    const [transformText, setTransformText] = useState<ITransformTextState>({
        transformText: "Html Source",
        transformIconTitle: "Preview"
    });

    // Initialize work item source state
    const [workitemTitleSource, setWorkitemTitleSource] = useState<string>(props.WorkItemTitlePattern);

    // Initialize work item source state
    const [workItemDescriptionSource, setWorkItemDescriptionSource] = useState<string>(props.WorkItemDescriptionPattern);

    // Handle description change event
    function onDescriptionChange(newValue: string, e: any) {
        setWorkItemDescriptionSource(newValue);
    }

    // Handle title change event
    function onTitleChange(event: any) {
        setWorkitemTitleSource(event.target.value)
    }

    // Handle icon Click event
    function handleIconClick() {
        if (transformText.transformText === "Html Source") {
            setTransformText({
                transformText: "Preview",
                transformIconTitle: "Html Source"
            });
        } else {
            setTransformText({
                transformText: "Html Source",
                transformIconTitle: "Preview"
            });
        }
    }

    // Handle apply event
    function onTicketWorkItemApply(){
        props.onTicketWorkItemApply(workitemTitleSource, workItemDescriptionSource);
        props.onTicketWorkItemDialogDimiss();
    }

    return (
        <CustomDialog calloutContentClassName='msacct-algorithm-dialog-container' onDismiss={props.onTicketWorkItemDialogDimiss} modal={true} >
            <CustomHeader className="bolt-header-with-commandbar" separator>
                <HeaderTitleArea>
                    <div
                        className="flex-grow scroll-hidden"
                        style={{ marginRight: "16px" }}
                    >
                        <div
                            className="title-m"
                            style={{
                                height: "500px",
                                width: "500px",
                                maxHeight: "32px"
                            }}
                        >
                            {"Edit the work item template (child)"}
                        </div>
                    </div>
                </HeaderTitleArea>
            </CustomHeader>
            <PanelContent>
                <div style={{ width:"100%" }}>
                    <div style={{width:"100%",marginTop:"10px",marginBottom:"10px"}}><span>You can modify field value for the following fields created using this template (<a href='https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Variables-in-Sonic-work-item-templates.aspx' target='_blank' >Supported variables</a>). </span></div>
                    
                    <div>
                        <span>Title Pattern:</span>
                    </div>
                    <div>
                        <input type="text" style={{ width: "98%", height: "30px" }} onChange={(event) => onTitleChange(event)} value={workitemTitleSource} />
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <span>Description Pattern:</span>
                        <Icon iconName="TransitionPop" style={{ cursor: "pointer", marginLeft: "10px", marginTop: "3px" }} onClick={handleIconClick} title={transformText.transformIconTitle} />
                    </div>
                    {
                        transformText.transformText === "Html Source" ?
                            <MonacoEditor
                                height="100%"
                                width="100%"
                                language="html"
                                value={workItemDescriptionSource}
                                options={options}
                                onChange={onDescriptionChange}
                            />
                            :
                            <>
                                <br />
                                <div style={{ width: "100%" }} dangerouslySetInnerHTML={{
                                    __html: workItemDescriptionSource
                                }}></div>
                            </>
                    }
                </div >
            </PanelContent>
            <PanelFooter showSeparator className="body-m">
                <Button
                    style={{ "float": "right", "marginLeft": "15px" }}
                    text="Apply"
                    primary={true}
                    onClick={() => {
                        onTicketWorkItemApply()
                    }}
                />
                <Button
                    style={{ "float": "right" }}
                    text="Close"
                    onClick={() => {
                        props.onTicketWorkItemDialogDimiss()
                    }}
                />
            </PanelFooter>
        </CustomDialog>


    )
}
