import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from "redux";
import { returnType } from "../Utilities/ReturnType";
import { connect } from "react-redux";
import { GlobalDataQuery } from "../Interface/IPowerQuery";
import { getDataRefreshSchedule, getContentDataFactory } from "../../redux/Actions/PowerDataQuery";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { Table, renderSimpleCell, ITableColumn, SimpleTableCell } from "azure-devops-ui/Table";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Card } from "azure-devops-ui/Card";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { Link } from "azure-devops-ui/Link";
import { Status, StatusSize, Statuses } from "azure-devops-ui/Status";
import { IIconProps } from '@fluentui/react';
import { css } from "azure-devops-ui/Util";
import { Ago } from "azure-devops-ui/Ago";
import { Icon } from "azure-devops-ui/Icon";
import { Duration } from "azure-devops-ui/Duration";
import { Tooltip } from "azure-devops-ui/TooltipEx";
import { convertUTCToPaciflic, getCurrentPSTDateInfo, getUTCDateTime, getPSTDateTime, getMonthName } from '../Utilities/CommonFunction'
import { CrawlerScheduleData } from '../../ConfigData/CrawlerScheduleData'

import {
    getStatusIndicatorData
} from '../Utilities/DataListExtension'

import './ScanRequests.css'

interface IDataRefreshScheduleItem {
    ErrorCode: string,
    RefreshStartDate: string,
    RefreshEndDate: string,
    RefreshStatus: string,
    CreatedDate: string,
    UpdatedDate: string,
    RefreshYear?: number,
    RefreshMonth?: number,
    RefreshDay?: number,
    RefreshHour?: number
}

interface IScheduleTemplateItem {
    WeekeedLabel: string,
    CurrentDate?: string,
    CurrentYear?: number,
    CurrentMonth?: number,
    CurrentDay?: number,
    CurrentHour?: number,
    CurrentWeek?: number,
}

interface ILastScanItem {
    ScanedStartTime: string,
    ScanedFinishTime: string,
    ScanedStatus: string
}

interface IContentFactory {
    FactoryType: string,
    FactoryName: string,
    Status: string
}

interface IFactoryProps {
    FactoryType: string,
    FactoryName: string
}


const actionCreators = {
    getDataRefreshSchedule,
    getContentDataFactory
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    dataRefreshSchedule: state.dataRefreshSchedule,
    contentDataFactory: state.contentDataFactory
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

function ScanRequests(props: StoreProps & DispatchProps & IFactoryProps) {

    /**
   * loading state
   */
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [dataRefreshScheduleItems, setDataRefreshScheduleItems] = useState<IDataRefreshScheduleItem[]>([])

    const [scanScheduleTempateItems, setScheduleTempateItems] = useState<IScheduleTemplateItem[]>([])

    const [currentCalendarDuration, setcurrentCalendarDuration] = useState<string>('')

    const [contentDataFactory, SetContentDataFactory] = useState<IContentFactory>({
        FactoryType:'',
        FactoryName:'',
        Status: ''
    })

    const [lastScan, setLastScan] = useState<ILastScanItem>({
        ScanedStartTime: '',
        ScanedFinishTime: '',
        ScanedStatus: ''
    })

    const [nextRefresh, setNextRefresh] = useState<ILastScanItem>(
        {
            ScanedStartTime: '',
            ScanedFinishTime: '',
            ScanedStatus: ''
        }
    )

    useEffect(() => {
        // get Pacific Time
        let pstDateInfo = getCurrentPSTDateInfo()

        // set a range for this week
        setcurrentCalendarDuration(`
        ${getMonthName(pstDateInfo.firstDateOfWeek.getMonth())} 
        ${pstDateInfo.firstDateOfWeek.getDate()} - 
        ${getMonthName(pstDateInfo.lastDateOfWeek.getMonth())} 
        ${pstDateInfo.lastDateOfWeek.getDate()}, 
        ${pstDateInfo.lastDateOfWeek.getFullYear()}  (Pacific Time)`)

        // set schedule template
        setCalenderScheduleTemplate()

        const loadData = async () => {

            setIsLoading(true)

            // call the api to get crawl schedule
            await props.getDataRefreshSchedule(props.FactoryType, props.FactoryName)

            await props.getContentDataFactory(props.FactoryType, props.FactoryName)

            // set isLoading = false
            setIsLoading(false)
        }

        loadData();

        return () => {
            SetContentDataFactory({
                FactoryType:'',
                FactoryName:'',
                Status:''
            })

            setDataRefreshScheduleItems([])
            setLastScan({
                ScanedStartTime: '',
                ScanedFinishTime: '',
                ScanedStatus: ''
            })

            setNextRefresh({
                ScanedStartTime: '',
                ScanedFinishTime: '',
                ScanedStatus: ''
            })

            setScheduleTempateItems([])
        }

    }, [])

    useEffect(() => {
        if (!props.dataRefreshSchedule || !props.dataRefreshSchedule.length) {
            setDataRefreshScheduleItems([])

            setLastScan({
                ScanedStatus: 'Canceled',
                ScanedStartTime: '',
                ScanedFinishTime: ''
            })

            setNextRefresh({
                ScanedStartTime: '',
                ScanedFinishTime: '',
                ScanedStatus: 'Canceled'
            })

            return
        }

        let lastRefreshStartDate = ''
        let lastRefreshEndDate = ''
        let lastScannedStatus = ''
        let requestItmes: IDataRefreshScheduleItem[] = []
        props.dataRefreshSchedule.map((item, index) => {
            if (item.RefreshStartDate !== null &&
                item.RefreshStartDate !== '') {

                let inputUTCDateTime = item.RefreshStartDate
                let dateUTCObj = new Date(inputUTCDateTime);
                let refreshDateObj = new Date(
                    dateUTCObj.getUTCFullYear(),
                    dateUTCObj.getUTCMonth(),
                    dateUTCObj.getUTCDate(),
                    dateUTCObj.getUTCHours() - 8,
                    dateUTCObj.getUTCMinutes(),
                    dateUTCObj.getUTCSeconds())

                let refreshYear = refreshDateObj.getFullYear()
                let refreshMonth = refreshDateObj.getMonth() + 1
                let refreshDay = refreshDateObj.getDate()
                let refreshHour = refreshDateObj.getHours()

                requestItmes.push({
                    ErrorCode: item.ErrorCode,
                    RefreshStartDate: item.RefreshStartDate && refreshDateObj.toString(),
                    RefreshEndDate: item.RefreshEndDate && convertUTCToPaciflic(item.RefreshEndDate).toString(),
                    RefreshStatus: item.RefreshStatus,
                    CreatedDate: item.CreatedDate,
                    UpdatedDate: item.UpdatedDate,
                    RefreshYear: refreshYear,
                    RefreshMonth: refreshMonth,
                    RefreshDay: refreshDay,
                    RefreshHour: refreshHour
                });

                if (item.RefreshEndDate && item.RefreshEndDate !== '') {
                    lastRefreshStartDate = item.RefreshStartDate
                    lastRefreshEndDate = item.RefreshEndDate
                    lastScannedStatus = item.RefreshStatus
                }
            }
        })

        setDataRefreshScheduleItems(requestItmes)

        setLastScan({
            ScanedStatus: lastScannedStatus,
            ScanedStartTime: lastRefreshStartDate && convertUTCToPaciflic(lastRefreshStartDate).toString(),
            ScanedFinishTime: (lastRefreshEndDate) && convertUTCToPaciflic(lastRefreshEndDate).toString()
        })

        if (requestItmes) {
            let currentPSTDate = getCurrentPSTDateInfo()
            let currentPSTYear = currentPSTDate.PSTDate.getFullYear()
            let currentPSTMonth = currentPSTDate.PSTDate.getMonth() + 1
            let currentPSTDay = currentPSTDate.PSTDate.getDate()
            let currentPSTHour = currentPSTDate.PSTDate.getHours()

            let filterNextRefreshItem = requestItmes.find(t => {
                return ((t.RefreshYear > currentPSTYear) ||
                    (t.RefreshYear === currentPSTYear && t.RefreshMonth === currentPSTMonth && t.RefreshDay > currentPSTDay) ||
                    (t.RefreshYear === currentPSTYear && t.RefreshMonth === currentPSTMonth && t.RefreshDay === currentPSTDay && t.RefreshHour > currentPSTHour) ||
                    (t.RefreshYear === currentPSTYear && t.RefreshMonth > currentPSTMonth)
                )
            })

            if (filterNextRefreshItem ) {
                setNextRefresh({
                    ScanedStartTime: filterNextRefreshItem.RefreshStartDate,
                    ScanedFinishTime: '',
                    ScanedStatus: filterNextRefreshItem.RefreshStatus
                })
            }
        }


    }, [props.dataRefreshSchedule])

    useEffect(() => {
        if (!props.contentDataFactory || !props.contentDataFactory.length) {
            SetContentDataFactory({
                FactoryType:'',
                FactoryName:'',
                Status:''
            })
            
            return
        }

        SetContentDataFactory({
            FactoryType: props.contentDataFactory[0].FactoryType,
            FactoryName:props.contentDataFactory[0].FactoryName,
            Status:props.contentDataFactory[0].Status
        })

    }, [props.contentDataFactory])

    function setCalenderScheduleTemplate() {
        let weeksEnglish = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        var pstDateInfo = getCurrentPSTDateInfo()

        let items: IScheduleTemplateItem[] = []
        for (let i = 0; i <= 6; i++) {
            let loopPSTDate = getNextDate(
                pstDateInfo.firstDateOfWeek.getFullYear(),
                pstDateInfo.firstDateOfWeek.getMonth(),
                pstDateInfo.firstDateOfWeek.getDate() + i,
                pstDateInfo.firstDateOfWeek.getHours(),
                pstDateInfo.firstDateOfWeek.getMinutes(),
                pstDateInfo.firstDateOfWeek.getSeconds())

            items.push({
                WeekeedLabel: weeksEnglish[i],
                CurrentDate: loopPSTDate.toString(),
                CurrentYear: loopPSTDate.getFullYear(),
                CurrentMonth: loopPSTDate.getMonth() + 1,
                CurrentDay: loopPSTDate.getDate(),
                CurrentHour: loopPSTDate.getHours(),
            })
        }

        setScheduleTempateItems(items)
    }


    /**
     * get next date object
     * @param paraYear 
     * @param paraMonth 
     * @param paraDay 
     * @param paraHour 
     * @param paraMinte 
     * @param paraSecond 
     * @returns 
     */
    function getNextDate(paraYear, paraMonth, paraDay, paraHour, paraMinte, paraSecond) {

        return new Date(paraYear, paraMonth, paraDay, paraHour, paraMinte, paraSecond)
    }

    /**
     * build table columns
     * @returns 
     */
    function buildColumns(): any {
        const columns = [
            {
                id: "WeekeedLabel",
                name: " ",
                maxWidth: 300,
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-60),
            },
            {
                id: "ScanedStartTime",
                name: "0",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "1",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },

            {
                id: "ScanedStartTime",
                name: "2",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "3",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "ScanedStartTime",
                name: "4",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "ScanedStartTime",
                name: "5",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-39),
            },
            {
                id: "ScanedStartTime",
                name: "6",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "7",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "ScanedStartTime",
                name: "8",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "9",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "ScanedStartTime",
                name: "10",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "11",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "ScanedStartTime",
                name: "12",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "13",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "ScanedStartTime",
                name: "14",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "15",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "ScanedStartTime",
                name: "16",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "17",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "ScanedStartTime",
                name: "18",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "19",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "ScanedStartTime",
                name: "20",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "21",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "ScanedStartTime",
                name: "22",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            {
                id: "RequestBy",
                name: "23",
                maxWidth: 50,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-30),
            },
            // {
            //     id: "CurrentDate",
            //     name: " xx",
            //     maxWidth: 300,
            //     readonly: true,
            //     renderCell: renderSimpleCell,
            //     sortProps: {
            //         ariaLabelAscending: "Sorted low to high",
            //         ariaLabelDescending: "Sorted high to low",
            //     },
            //     width: new ObservableValue(-80),
            // }
        ];

        return columns;
    }

    function renderScanStausColumn(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<IScheduleTemplateItem>,
        tableItem: IScheduleTemplateItem
    ) {
        const {
            CurrentDate,
            CurrentYear,
            CurrentMonth,
            CurrentDay,
            CurrentHour
        } = tableItem;

        let columnHour = columnIndex - 1
        let showStatus = ''
        let tooltipDate = ''
        if (dataRefreshScheduleItems) {
            let filterItem = dataRefreshScheduleItems.find(t =>
                t.RefreshYear === CurrentYear &&
                t.RefreshMonth === CurrentMonth &&
                t.RefreshDay === CurrentDay &&
                t.RefreshHour === columnHour)

            if (filterItem) {
                showStatus = filterItem.RefreshStatus
                tooltipDate = filterItem.RefreshStartDate
            }
        }

        return (
            <SimpleTableCell
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                key={"col-" + columnIndex}
                contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
            >
                {
                    showStatus && <Tooltip text={tooltipDate} overflowOnly={false}>
                        <div>
                            <Status 
                                {...getStatusIndicatorData(showStatus).statusProps}
                                className="icon-large-margin task-datalist-icon"
                                size={StatusSize.s}

                            />
                        </div>

                    </Tooltip>
                }
            </SimpleTableCell>
        );
    }


    function WithIcon(props: {
        className?: string;
        iconProps: IIconProps;
        children?: React.ReactNode;
    }) {
        return (
            <div style={{ width: "100%" }} className={css(props.className, "flex-row flex-center")}>
                {Icon({ ...props.iconProps, className: "icon-margin" })}
                {props.children}
            </div>
        );
    }

    function renderNextCrawl() {
        let nextRefreshDate = nextRefresh.ScanedStartTime
        if (nextRefreshDate.indexOf("GMT") > 0) {
            nextRefreshDate = nextRefreshDate.substring(0, nextRefreshDate.indexOf("GMT"))
        }

        let scannedStatus = nextRefresh.ScanedStatus === "Scanning" ? "Running" : nextRefresh.ScanedStatus

        if (nextRefreshDate === '' || contentDataFactory.Status === 'Disabled') {
            return (
                <div>
                    <h3>Next Refresh Date</h3>
                    <ul className='sonic-scanrequest-last'>
                        <li>
                            <Status
                                {...getStatusIndicatorData("Canceled").statusProps}
                                size={StatusSize.m}
                            />
                        </li>
                        <li>
                            <span>Disabled</span>
                        </li>
                    </ul>
                </div>
            )
        }
        else {
            return (
                <div>
                    <h3>Next Refresh Date</h3>
                    <ul className='sonic-scanrequest-last'>
                        <li>
                            <Status
                                {...getStatusIndicatorData(scannedStatus).statusProps}
                                size={StatusSize.m}
                            />
                        </li>
                        <li>
                            {nextRefreshDate}
                        </li>

                    </ul>
                </div>
            )
        }
    }

    function renderLastCrawl() {
        let scannedFinishTime = lastScan.ScanedFinishTime || lastScan.ScanedStartTime
        let scannedStatus = lastScan.ScanedStatus === "Scanning" ? "Running" : lastScan.ScanedStatus

        if (lastScan.ScanedStartTime === '') {
            return (
                <div>
                    <h3>Last Refresh Date</h3>
                    <ul className='sonic-scanrequest-last'>
                        <li>
                            <Status
                                {...getStatusIndicatorData("Canceled").statusProps}
                                size={StatusSize.m}
                            />
                        </li>
                        <li>
                            <span>Disabled</span>
                        </li>
                    </ul>
                </div>
            )
        }
        else {
            return (
                <div>
                    <h3>Last Refresh Date</h3>
                    <ul className='sonic-scanrequest-last'>
                        <li>
                            <Status
                                {...getStatusIndicatorData(scannedStatus).statusProps}
                                size={StatusSize.m}
                            />
                        </li>
                        <>
                            <li>
                                {
                                    WithIcon({
                                        className: "fontSize font-size",
                                        iconProps: { iconName: "Calendar" },
                                        children: (
                                            <Ago date={new Date(lastScan.ScanedStartTime)!} /*format={AgoFormat.Extended}*/ />
                                        )
                                    })
                                }
                            </li>
                            <li>
                                {
                                    WithIcon({
                                        className: "fontSize font-size bolt-table-two-line-cell-item msacct-curationall-clock",
                                        iconProps: { iconName: "Clock" },
                                        children: (
                                            <Duration
                                                startDate={new Date(lastScan.ScanedStartTime)!}
                                                endDate={new Date(scannedFinishTime)}
                                            />
                                        )
                                    })
                                }
                            </li>
                        </>
                    </ul>
                </div>
            )
        }
    }


    return (
        <Surface background={SurfaceBackground.neutral}>
            <Page className="pipelines-page flex-grow">
                <div className="page-content">
                    <div className="page-content-top">
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                    <Card
                                        className="bolt-card-content sonic-schedule"
                                        contentProps={{ contentPadding: true }}
                                    >
                                        {renderLastCrawl()}
                                    </Card>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                    <Card
                                        className="bolt-card-content sonic-schedule"
                                        contentProps={{ contentPadding: true }}
                                    >
                                        {renderNextCrawl()}
                                    </Card>
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                    <ul className='sonic-crawler-date'>
                                        <li><b>
                                            {currentCalendarDuration}
                                        </b></li>
                                    </ul>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4" >
                                    <ul className='sonic-crawler-status-li'>
                                        <li>
                                            <Status {...Statuses.Queued} size={StatusSize.m} /> Queued
                                        </li>
                                        <li>
                                            <Status {...Statuses.Running} size={StatusSize.m} /> Scanning
                                        </li>
                                        <li>
                                            <Status {...Statuses.Failed} size={StatusSize.m} /> Failed
                                        </li>
                                        <li>
                                            <Status {...Statuses.Success} size={StatusSize.m} /> Success
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Card
                                        className="bolt-card-content"
                                        contentProps={{ contentPadding: true }}
                                    >
                                        <Table<Partial<IScheduleTemplateItem>>
                                            columns={buildColumns()}
                                            itemProvider={new ArrayItemProvider<IScheduleTemplateItem>(scanScheduleTempateItems)}
                                            showLines={true}
                                            pageSize={2000}
                                            onSelect={(event, data) => console.log("Selected Row - " + data.index)}
                                            onActivate={(event, row) => console.log("Activated Row - " + row.index)}
                                        />
                                    </Card>

                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {
                                        props.FactoryType === "Database" ?
                                            <>
                                                <span>Learn more about </span><Link href='https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Sonic-Content-Crawler-run-dates-and-the-sites-to-crawl.aspx'>the scheduling datasets.</Link>
                                            </>
                                            :
                                            <>
                                                <span>Learn more about </span><Link href='https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Sonic-Content-Crawler-Run-Dates.aspx'>the date the crawler is scheduled to run.</Link>
                                            </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </Surface>
    )
}



export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(ScanRequests);
