import React, { Component } from 'react'
import AppLayout from "../AppLayout/AppLayout";
import TaskRunResult from '../../TaskRunResult/TaskRunResult'
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'

export default class TaskRunResultPage extends Component {
    render() {
        document.title = MSACCT_TITLE + " | " + "Run Result";
        
        return (
          <AppLayout
            // renderBreadcrumb={() => this.renderHeader()}    
            renderContentPane={() => <TaskRunResult />} />
        )
      }
}
