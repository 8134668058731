import React, { Component } from 'react';
import {
    CommandBar,
    ICommandBarItemProps,
    Text,
    Callout,
    Link,
} from "@fluentui/react";
import "./MenuBar.css";
import {
    NavLink,
} from "react-router-dom";
import "office-ui-fabric-core/dist/css/fabric.css";
import MyCurationPivot from '../MyCurationPivot/MyCurationPivot'
import { VssPersona } from "azure-devops-ui/VssPersona";
import { Nav, INavStyles, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { IconButton } from 'office-ui-fabric-react';
import { msalConfig } from "../../../msal/authConfig";
import { msalInstance } from "../../../index";

const sonci_Env = process.env.REACT_APP_SONIC_ENV

export default class MenuBar extends Component { 
    state = { 
        isLauncherCalloutVisible: false, 
        isCalloutVisible: false, 
        isMyCurationCalloutVisible: false,
        longname: '',
        shorname:''
     }

    componentDidMount() {
        const userAccount = msalInstance.getActiveAccount();
        this.setState({
            longname:userAccount.username,
            shorname: userAccount.name.substring(0, userAccount.name.indexOf("("))
        })
    }

    showLauncherCallOut = () => {
        const { isLauncherCalloutVisible } = this.state
        this.setState({ isLauncherCalloutVisible: !isLauncherCalloutVisible })
    };

    showCallOut = () => {
        const { isCalloutVisible } = this.state
        this.setState({ isCalloutVisible: !isCalloutVisible })
    };

    showMyCurationCallOut = () => {
        const { isMyCurationCalloutVisible } = this.state
        this.setState({ isMyCurationCalloutVisible: !isMyCurationCalloutVisible })
    };

    render() {
        const items: ICommandBarItemProps[] = [];
        items.push({
            key: "AppLauncher",
            iconProps: { iconName: "WaffleOffice365", title: "App Launcher" },
            onClick: () => this.showLauncherCallOut(),
        });

        const navStyles: Partial<INavStyles> = {
            groupContent: {
                marginBottom: 0
            },
            link: {
                height: 30
            }
        };

        const navLinkGroups: INavLinkGroup[] = [
            {
                name: 'Content Request',
                links: [
                    { icon: "WindowsLogo", name: 'Content Idea', url: 'https://contentidea/', key: 'key1', target: '_blank' },
                ],
            },
            {
                name: 'Content Authoring',
                links: [
                    { icon: "Page", name: 'C3', url: 'https://authoring.support.microsoft.com', key: 'key2', target: '_blank' },
                    { icon: "VSTSLogo", name: 'Content Idea Auto-publish tool', url: 'https://aka.ms/INTContentCI', key: 'key3', target: '_blank' },
                ],
            },
            {
                name: 'Content Surfacing',
                links: [
                    { icon: "Documentation", name: 'Microsoft technical documentation (Learn)', url: 'https://docs.microsoft.com', key: 'key4', target: '_blank' },
                    { icon: "WindowsLogo", name: 'Microsoft Support (SMC)', url: 'https://support.microsoft.com', key: 'key5', target: '_blank' },
                    { icon: "WindowsLogo", name: 'Evergreen (iEMC)', url: 'https://internal.evergreen.microsoft.com', key: 'key6', target: '_blank' },
                ],
            },
            {
                name: 'Content Consumption',
                links: [
                    { icon: "KnowledgeArticle", name: 'DfM Knowledge', url: 'https://onesupport.crm.dynamics.com', key: 'key7', target: '_blank' },
                    { icon: "WindowsLogo", name: 'Microsoft technical documentation (Learn)', url: 'https://docs.microsoft.com/en-us/search', key: 'key8', target: '_blank' },
                ],
            },
            {
                name: 'Content Monitoring',
                links: [
                    { icon: "WindowsLogo", name: 'Automated Content Curation Tool', url: 'https://aka.ms/ACCT', key: 'key9', target: '_blank' },
                ],
            },
            {
                name: 'Content Reporting',
                links: [
                    { icon: "PowerBILogo16", name: 'Evergreen', url: 'https://msit.powerbi.com/groups/me/reports/83ee677f-91aa-4cdf-b913-276b3459f504/ReportSection6433e2cf1eae6a0e6f65', key: 'key11', target: '_blank' },
                ],
            },
        ];

        items.push({
            name: "Micorsoft",
            key: "Micorsoft",
            className: "ms-log",
        });

        items.push({
            name: "Automated Content Curation Tool",
            key: "AutomatedContentCurationTool",
            onRender: () => <NavLink className="brand" to=".">{sonci_Env === "prod"?"CE&S KM Automated Content Curation Tool":"CE&S KM Automated Content Curation Tool"}</NavLink>,
        });

        const farItems: ICommandBarItemProps[] = [];

        const myCurationItem: ICommandBarItemProps = {
            name: "Recent Curation",
            key: "MyCuration",
            iconProps: { iconName: "CheckList", style: { fontSize: 13 } },
            onClick: () => this.showMyCurationCallOut()
        };
        farItems.push(myCurationItem);

        const helpItem: ICommandBarItemProps = {
            name: "Help and Support",
            key: "HelpAndSupport",
            iconProps: { iconName: "Feedback", style: { fontSize: 13} },
            subMenuProps: {
                items: [
                    {
                        name: "Get started",
                        key: "Getstarted",
                        iconProps: { iconName: "Articles" },
                        target:"_blank",
                        href: "https://aka.ms/ACCTUserGuide",
                    },
                    {
                        name: "Report a bug",
                        key: "reportbug",
                        iconProps: { iconName: "Bug" },
                        target:"_blank",
                        href: "https://aka.ms/ACCTTools",
                    },
                    {
                        name: "Make a suggestion",
                        key: "makesuggestion",
                        iconProps: { iconName: "TextDocumentShared" },
                        target:"_blank",
                        href: "https://aka.ms/ACCTSupport",
                    },
                    {
                        name: "Microsoft Data Privacy Notice",
                        key: "privacynotice",
                        iconProps: { iconName: 'Info' },
                        target:"_blank",
                        href: "https://aka.ms/ACCTPrivacy",
                    },
                ],
            },
        };

        farItems.push(helpItem);

        const userMenuItem: ICommandBarItemProps = {
            key: "User",
            commandBarButtonAs: () =>
                <div onClick={() => this.showCallOut()}>
                    <VssPersona className="msacct-user" size={"medium"} displayName={this.state.shorname} />
                </div>

            //<Persona text={this.shorname} className="msacct-user" size={PersonaSize.size32} onClick={() => this.showCallOut()} />
        };

        farItems.push(userMenuItem);

        return (
            <div style={{height:"46px"}}>
                <CommandBar items={items} farItems={farItems} className="menuBar" />
                {this.state.isLauncherCalloutVisible ?
                    (
                        <Callout ariaLabelledBy='callout-label'
                            ariaDescribedBy='callout-description'
                            className="msacct-applauncher"
                            onDismiss={this.showLauncherCallOut}>
                            <div className="msacct-callout-inner" style={{marginLeft:15}}>
                                <div className="callout-heder">
                                    <IconButton iconProps={{ iconName: 'WaffleOffice365' }} ariaLabel="Close App Launcher" onClick={this.showLauncherCallOut}></IconButton>
                                    <IconButton style={{ marginLeft: 250 }} iconProps={{ iconName: 'Cancel' }} ariaLabel="Close App Launcher" onClick={this.showLauncherCallOut}></IconButton>
                                </div>
                                <div>
                                    <h2>Content Sites</h2>
                                    <div id="appLauncherMainView">
                                        <Nav
                                            onRenderGroupHeader={_onRenderGroupHeader}
                                            styles={navStyles}
                                            groups={navLinkGroups}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Callout>
                    ) : null
                }

                {this.state.isMyCurationCalloutVisible && (
                    <Callout
                        ariaLabelledBy='callout-label'
                        ariaDescribedBy='callout-description'
                        className="msacct-mycurationpan"
                        gapSpace={0}
                        isBeakVisible={true}
                        beakWidth={10}
                        onDismiss={this.showMyCurationCallOut}
                    >
                        <MyCurationPivot />
                    </Callout>
                )}

                {this.state.isCalloutVisible ?
                    (
                        <Callout
                            ariaLabelledBy='callout-label'
                            ariaDescribedBy='callout-description'
                            className="msacct-signout"
                            gapSpace={0}
                            isBeakVisible={true}
                            beakWidth={10}
                            onDismiss={this.showCallOut}
                        >
                            <div className="msacct-callout-inner">
                                <div className="callout-heder">
                                    <div>
                                        <Text id='callout-label'>
                                            Microsoft
                                        </Text>
                                    </div>
                                    <div>
                                        <Link onClick={()=> {
                                            msalInstance.logoutRedirect()
                                        }} target="_blank" >
                                            Sign Out
                                        </Link>
                                    </div>
                                </div>
                                <div className="callout-detail">
                                    <div>
                                        <VssPersona size="large" displayName={this.state.shorname} />
                                        {/*<Persona text={this.shorname} size={PersonaSize.size56} />*/}
                                    </div>
                                    <div>
                                        <div>
                                            <Text id='callout-label'>
                                                {this.state.shorname}
                                            </Text>
                                        </div>
                                        <div>
                                            {/*<Link href="http://microsoft.com" target="_blank" >*/}
                                            {/*{this.state.user.userName}*/}
                                            {/*</Link>*/}
                                            <Text id='callout-label'>
                                                {this.state.longname}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Callout>
                    ) : null
                }
            </div>
        );
        function _onRenderGroupHeader(group: INavLinkGroup): JSX.Element {
            return <h4 style={{ marginTop: 10, marginBottom: 10 }}>{group.name}</h4>;
        }
    }
}
