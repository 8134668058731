

/**
 * Redux middleware to handle async generators and effects
 */
// TODO could probably replace this with saga
export const asyncGeneratorMiddleware = ({
    dispatch,
    getState
  }) => next => async action => {
    if (action[Symbol.asyncIterator]) {
      try {
        const it = action
        let result = await it.next()
        while (!result.done) {
          const effect = result.value
          switch (effect.type) {
            case 'select':
              result = await it.next(effect.selector(getState()))
              break
            default:
              dispatch(effect)
              result = await it.next()
          }
        }
      } catch (e) {
      //   dispatch(setError(e))
      }
    } else {
      return next(action)
    }
  }
  
  const allMiddleware = [asyncGeneratorMiddleware]
  export default allMiddleware
  