import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import Store from "./redux/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../src/msal/authConfig";

import { initializeIcons } from "@fluentui/font-icons-mdl2";


import PowerQueryPage from "./components/Pages/PowerQueryPage/PowerQueryPage";
import TaskQueryPage from "./components/Pages/TaskQueryPage/TaskQueryPage";
import TaskConfigurationPage from "./components/Pages/TaskConfigurationPage/TaskConfigurationPage";
import { RouterPage } from "./components/Pages/RouterPage";


export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

initializeIcons();

ReactDOM.render(
  <Provider store={Store}>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        
        <RouterPage />
      </BrowserRouter>
    </MsalProvider>
  </Provider>,
  document.getElementById("root")
);
