import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { returnType } from "../../Utilities/ReturnType";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

import { CustomHeader, HeaderTitleArea } from "azure-devops-ui/Header";
import { CustomDialog } from "azure-devops-ui/Dialog";
import { PanelContent, PanelFooter } from "azure-devops-ui/Panel";
import { ITableColumn, SimpleTableCell, Table, renderSimpleCell } from "azure-devops-ui/Table";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { Link } from "azure-devops-ui/Link";
import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { DataListItem, DataListItemOthers, DataListItemPoliCheck } from '../../Interface/IDataResult'
import { getCurationQueryFields, buildCurationQueryColumns, buildCurationTableItems, buildConditionJson } from "../../Utilities/QueryBuilderExtension";
import InfiniteScroll from "react-infinite-scroll-component";
import { searchContent, onExportAlgoReport, clearSearchResults, createDownloadLogs } from '../../../redux/Actions/PowerDataQuery'
import { formatDateTime } from '../../Utilities/CommonFunction'
import { QueryExpression } from "../../Utilities/QueryBuilderUtils";

import "./QueryPreveiw.css";

const actionCreators = {
    clearSearchResults,
    searchContent,
    onExportAlgoReport,
    createDownloadLogs,
};
type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    searchResult: state.searchResult
});
const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

interface IPaginationState {
    PageSize: number,
    PageIndex: number,
    SearchDataIndex: number
}

interface ISearchParameterProps {
    AlgorithmType: string;
    SearchIndex: string;
    Filter: string;
    OrderByField: string;
    Top: number;
    CustomColumns?: any;
    expressionChildren: QueryExpression[];
    onReportDialogDimiss: () => void;
    indexName: string;
}

function QueryPreveiw(props: StoreProps & DispatchProps & ISearchParameterProps) {
    const [articleItems, setArticleItems] = useState<Array<any>>([])
    const [reportPagination, setReportPagination] = useState<IPaginationState>({
        SearchDataIndex: 0,
        PageSize: getDefaultPageSize(),
        PageIndex: 0
    })
    const [seeMore, setSeeMore] = useState<boolean>(true)
    const [firstDataLoadComplete, setFirstDataLoadComplete] = useState<boolean>(false)

    /**
     * like componentDidMount
     */
    useEffect(() => {

        setArticleItems([])
        setSeeMore(true)

        let metadataSchema = props.SearchIndex;

        let selectString = getCurationQueryFields(metadataSchema, props.AlgorithmType);
        let expList = buildConditionJson(props.expressionChildren);
        const conditionJsonString = JSON.stringify(expList);

        setFirstDataLoadComplete(false)
        const loadData = async () => {
            await props.searchContent(
                "test",
                metadataSchema,
                props.Filter,
                conditionJsonString,
                selectString,
                reportPagination.PageSize,
                0,
                props.OrderByField
            );

            setFirstDataLoadComplete(true)
        }

        loadData();

        return () => {
            props.clearSearchResults()
            setArticleItems([])
        }
    }, [props.Filter, props.SearchIndex])

    /**
     * like componentDidUpdate
     */
    useEffect(() => {
        buildItemsWithReport()
    }, [props.searchResult.Topics])

    /**
     * get default page size
     */
    function getDefaultPageSize() {
        if (props.Top && props.Top !== 0 && props.Top !== 999999) {
            return props.Top
        }

        return 200
    }

    /**
     * 
     * @returns 
     */
    function buildItemsWithReport() {
        if (!props.searchResult || !props.searchResult.Topics || props.searchResult.Topics.length === 0) {
            return []
        }

        let tableItems = buildCurationTableItems(props.searchResult.Topics, props.SearchIndex, 'QueryPreview', props.indexName);
        if(props.SearchIndex?.startsWith("gitpub-metadata-schema-others-")) {
            tableItems = tableItems.filter(item=>item.hasOwnProperty('ReferrerLink'));
        }
        if(props.SearchIndex?.startsWith("gitpub-metadata-schema-media-")) {
            setArticleItems(tableItems)
            setSeeMore(false)
        }else {
            setArticleItems([...articleItems, ...tableItems])

            if (props.searchResult.Topics.length < reportPagination.PageSize) {
                setSeeMore(false)
            }
        }
    }

    /**
     * Build datalist columns
     * @returns 
     */
    function buildReportColumns(): any {
        if (props.CustomColumns && props.CustomColumns.length > 0) {
            return props.CustomColumns
        }

        return buildCurationQueryColumns(props.SearchIndex, 'QueryPreview', props.indexName);
    }

    /**
    * PageIndex    SearchDataIndex
    * 0            0
    * 1            100
    * 2            200
    */
    const fetchMoreData = async () => {
        let pageIndex = reportPagination.PageIndex + 1
        let searchDataIndex = pageIndex * reportPagination.PageSize

        let metadataSchema = props.SearchIndex;

        let selectString = getCurationQueryFields(metadataSchema, props.AlgorithmType);
        let expList = buildConditionJson(props.expressionChildren);
        const conditionJsonString = JSON.stringify(expList);

        await props.searchContent(
            "test",
            metadataSchema,
            props.Filter,
            conditionJsonString,
            selectString,
            reportPagination.PageSize,
            searchDataIndex,
            props.OrderByField
        );

        setReportPagination({
            ...reportPagination,
            PageIndex: pageIndex,
            SearchDataIndex: searchDataIndex
        })
    }

    return (
        <CustomDialog calloutContentClassName='msacct-algorithm-dialog-container' onDismiss={props.onReportDialogDimiss} modal={true} className="ddd">
            <CustomHeader className="bolt-header-with-commandbar" separator>
                <HeaderTitleArea>
                    <div
                        className="flex-grow scroll-hidden"
                        style={{ marginRight: "16px" }}
                    >
                        <div
                            className="title-m"
                            style={{
                                height: "500px",
                                width: "500px",
                                maxHeight: "32px"
                            }}
                        >
                            {`Search result`}
                        </div>
                        <div style={{ textAlign: "right" }} ><span>(Showing {articleItems.length} of {props.SearchIndex?.startsWith("gitpub-metadata-schema-media-")? articleItems.length: props.searchResult.Count})</span></div>
                    </div>
                </HeaderTitleArea>
            </CustomHeader>
            <PanelContent className='msacct-algo-panelcontent'>
                <div id='msacct-algo-scrollableDiv'
                    style={{
                        overflow: 'auto',
                        display: 'flex',
                    }}>
                    <InfiniteScroll
                        dataLength={articleItems.length}
                        next={fetchMoreData}
                        hasMore={seeMore}
                        loader={
                            (firstDataLoadComplete && articleItems.length == 0) ?
                                <h4>No results found</h4>
                                :
                                <h4>Loading...</h4>
                        }
                        scrollableTarget="msacct-algo-scrollableDiv"
                        endMessage={
                            <p style={{ textAlign: 'left' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >
                        <Table<Partial<any>>
                            columns={buildReportColumns()}
                            itemProvider={new ArrayItemProvider<any>(articleItems)}
                            showLines={true}
                            pageSize={2000}
                            containerClassName="msacct-devops-table"
                            showScroll={false}
                        />

                    </InfiniteScroll>
                </div>

            </PanelContent>
            <PanelFooter showSeparator className="body-m">
                <PrimaryButton
                    style={{ "float": "right", "marginLeft": "15px" }}
                    text="Close"
                    onClick={() => props.onReportDialogDimiss()}
                />
            </PanelFooter>
        </CustomDialog>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(QueryPreveiw);
