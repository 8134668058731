import React, { useState, useEffect } from 'react';
import { EditorState, ContentState, CompositeDecorator } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './RichTextEditor.css';

interface IRichTextEditorProps {
    // Callback function for content changes
    onContentChange?: (content: string) => void;
    // Custom toolbar options
    ToolBarOptions?: string[];
    // Default content value
    DefaultValue?: string;
}

export default function RichTextEditor(props: IRichTextEditorProps) {
    const { ToolBarOptions, onContentChange } = props;

    // Default value for DefaultValue prop, if not provided or null
    const defaultValueProp = (props.DefaultValue !== undefined && props.DefaultValue !== null) ? props.DefaultValue : '';

    // Create a decorator for mentions or other custom text styling
    const compositeDecorator = new CompositeDecorator([]);

    const [editorState, setEditorState] = useState(() => {
        // Convert HTML to ContentState
        const blocksFromHtml = htmlToDraft(defaultValueProp);
        if (defaultValueProp) {
            const contentState = ContentState.createFromBlockArray(
                blocksFromHtml.contentBlocks,
                blocksFromHtml.entityMap
            );
            return EditorState.createWithContent(contentState, compositeDecorator);
        }
        return EditorState.createEmpty(compositeDecorator);
    });

    useEffect(() => {
        // Update the editorState with content from DefaultValue prop
        const blocksFromHtml = htmlToDraft(defaultValueProp);

        if (defaultValueProp) {
            const contentState = ContentState.createFromBlockArray(
                blocksFromHtml.contentBlocks,
                blocksFromHtml.entityMap
            );
            setEditorState(EditorState.createWithContent(contentState, compositeDecorator));
        } else {
            EditorState.createEmpty(compositeDecorator);
        }
    }, []);

    const handleEditorStateChange = (newEditorState) => {
        if (onContentChange) {
            const contentState = newEditorState.getCurrentContent();

            // Convert ContentState to HTML
            const contentHTML = stateToHTML(contentState)

            // Invoke the content change callback
            onContentChange(contentHTML);
        }

        // Update the editor state
        setEditorState(newEditorState);
    }

    return (
        <div>
            <Editor
                editorState={editorState}
                wrapperClassName="sonic-richtexteditor-wrapper"
                editorClassName="sonic-richtexteditor-editor"
                onEditorStateChange={handleEditorStateChange}
                toolbarClassName="toolbar-class"
                toolbar={{
                    options: ToolBarOptions,
                }}
                textDirection="ltr"
            />
        </div>
    );
}
