import React, { Component } from "react";
import { CommandBar, ICommandBarItemProps, IContextualMenuItem } from "@fluentui/react";
import { ContextualMenuItemType, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { isSystemUser } from '../../Utilities/CommonFunction'

import { GlobalDataQuery, DataQueryIndex } from "../../Interface/IPowerQuery";
import {
  QueryExpression,
  hasValidExpression,
} from "../../Utilities/QueryBuilderUtils";

import {
  onColumnOptionsOpen,
  onConfigurationSettingOpen,
} from "../../../redux/Actions/PowerDataQuery";

import './DataQueryCommandBar.css'

const mapStateToProps = (state: GlobalDataQuery) => ({
  metadataQueryIndexList: state.indexList,
  repo: state.index,
  searchResult: state.searchResult,
  metadataFields:
    state.metadataFields &&
    state.index &&
    state.index.metadataSchema &&
    state.metadataFields[state.index.metadataSchema],
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

const actionCreators = {
  onColumnOptionsOpen,
  onConfigurationSettingOpen,
};

type DispatchProps = typeof actionCreators;

interface MetaDataQueryCommandBarProps {
  queryExpressions: QueryExpression;
  isCurationPage?: boolean;
  loginUserAlias?: string;
  defaultAlogType?: string;
  onAlgoClick?: (algoType: string) => void;
  onRunClick: () => void;
  onRepoClick: (index: DataQueryIndex) => void
  onExportResultsClick: () => void;
  onDiscardClick: () => void;
}

const sonci_Env = process.env.REACT_APP_SONIC_ENV

class DataQueryCommandBar extends Component<
  MetaDataQueryCommandBarProps & StoreProps & DispatchProps
> {
  onTest = () => {
    console.log("OnTest", this.props.repo.name);
  };

  state = {
    AlgoName: 'Select an algorithm',
    indexName: ''
  }

  componentDidMount() {
    if (this.props && this.props.isCurationPage) {
      let algoName = "Select an algorithm"
      switch (this.props.defaultAlogType) {
        case "Blank":
          algoName = "Select an algorithm"
          break;
        case "BrokenLinkCrawler":
          algoName = "Broken Link Crawler"
          break;
        case "PIIScrubber":
          algoName = "D&I PII Scrubber"
          break;
        case "PoliCheckCrawler":
          algoName = "PoliCheck Crawler"
          break;
        case "TextCrawler":
          algoName = "Text Crawler"
          break;
        case "AzureKBSearch":
          algoName = "AzureKB Search"
          break;
        case "ContentDownloader":
          algoName = "Content Download"
          break;
        case "ImageStatusChecker":
          algoName = "Image Status Checker"
          break;
        case "TextFinderAndReplacer":
          algoName = "Text Finder and Replacer"
          break;
        default:
          algoName = "Select an algorithm"
          break;
      }
      this.setState({
        AlgoName: algoName
      })
    }
    if(this.props?.repo?.name) {
      this.setState({
        indexName: this.props.repo.name
      })
    }
  }

  renderAlgorithmItems() {
    const algoItems: IContextualMenuItem[] = [];
    if (this.props.isCurationPage) {
      algoItems.push({
        key: 'Blank',
        name: 'Select an algorithm',
        text: 'Select an algorithm',
        style: { paddingLeft: '40px' },
        iconProps: { iconName: 'ExternalTFVC' },
        onClick: () => this.handlAlgoSelected('Blank', 'Select an algorithm')
      })
      algoItems.push({
        key: 'BrokenLinkCrawler',
        name: 'Broken Link Crawler',
        text: 'Broken Link Crawler',
        style: { paddingLeft: '40px' },
        iconProps: { iconName: 'ExternalTFVC' },
        onClick: () => this.handlAlgoSelected('BrokenLinkCrawler', 'Broken Link Crawler')
      })
      algoItems.push({
        key: 'PIIScrubber',
        name: 'PII Scrubber',
        text: 'D&I PII Scrubber',
        style: { paddingLeft: '40px' },
        iconProps: { iconName: 'ExternalTFVC' },
        onClick: () => this.handlAlgoSelected('PIIScrubber', 'D&I PII Scrubber')
      })
      if (isSystemUser(this.props.loginUserAlias)) {
        algoItems.push({
          key: 'ContentDownloader',
          name: 'Content Download',
          text: 'Content Download',
          style: { paddingLeft: '40px' },
          iconProps: { iconName: 'ExternalTFVC' },
          onClick: () => this.handlAlgoSelected('ContentDownloader', 'Content Download')
        })
      }

      algoItems.push({
        key: 'PoliCheckCrawler',
        name: 'PoliCheck Crawler',
        text: 'PoliCheck Crawler',
        style: { paddingLeft: '40px' },
        iconProps: { iconName: 'ExternalTFVC' },
        onClick: () => this.handlAlgoSelected('PoliCheckCrawler', 'PoliCheck Crawler')
      })

      algoItems.push({
        key: 'TextCrawler',
        name: 'Text Crawler',
        text: 'Text Crawler',
        style: { paddingLeft: '40px' },
        iconProps: { iconName: 'ExternalTFVC' },
        onClick: () => this.handlAlgoSelected('TextCrawler', 'Text Crawler')
      })

      algoItems.push({
        key: 'ImageStatusChecker',
        name: 'Image Status Checker',
        text: 'Image Status Checker',
        style: { paddingLeft: '40px' },
        iconProps: { iconName: 'ExternalTFVC' },
        onClick: () => this.handlAlgoSelected('ImageStatusChecker', 'Image Status Checker')
      })

      algoItems.push({
        key: 'TextFinderAndReplacer',
        name: 'Text Finder and Replacer',
        text: 'Text Finder and Replacer',
        style: { paddingLeft: '40px' },
        iconProps: { iconName: 'ExternalTFVC' },
        onClick: () => this.handlAlgoSelected('TextFinderAndReplacer', 'Text Finder and Replacer')
      })
    }

    algoItems.push({
      key: 'AzureKBSearch',
      name: 'AzureKB Search',
      text: 'AzureKB Search',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.handlAlgoSelected('AzureKBSearch', 'AzureKB Search')
        this.props.onRepoClick(this.getIndexByName('AzureKB Search'))
      }
    })

    return algoItems
  }


  handlAlgoSelected = (algoKey, algoName) => {
    this.setState({
      AlgoName: algoName
    })

    this.props.onAlgoClick && this.props.onAlgoClick(algoKey)
  }

  renderIndexForProdOld() {
    const metadataItems: IContextualMenuItem[] = [];
    metadataItems.push({
      key: 'Evergreen',
      name: 'internal.evergreen.microsoft.com (Evergreen)',
      text: 'internal.evergreen.microsoft.com (Evergreen)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('Evergreen'))
    })

    metadataItems.push({
      key: 'SMC',
      name: 'support.microsoft.com (SMC)',
      text: 'support.microsoft.com (SMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      disabled: true,
      onClick: () => this.props.onRepoClick(this.getIndexByName('SMC'))
    })

    metadataItems.push({
      key: 'LMC',
      name: 'learn.microsoft.com (LMC)',
      text: 'learn.microsoft.com (LMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      disabled: true,
      onClick: () => this.props.onRepoClick(this.getIndexByName('LMC'))
    })

    metadataItems.push({
      key: 'SXC',
      name: 'support.xbox.com (SXC)',
      text: 'support.xbox.com (SXC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      disabled: true,
      onClick: () => this.props.onRepoClick(this.getIndexByName('SXC'))
    })

    metadataItems.push({
      key: 'AMC',
      name: 'answers.microsoft.com (AMC)',
      text: 'answers.microsoft.com (AMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      disabled: true,
      onClick: () => this.props.onRepoClick(this.getIndexByName('AMC'))
    })

    const brokenMenus: ICommandBarItemProps[] = [];
    brokenMenus.push({
      key: 'BrokenLinkCrawler',
      name: 'Broken Link Crawler',
      text: 'Broken Link Crawler',
      disabled: false,
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('Broken Link Crawler'))
    })

    brokenMenus.push({
      key: '404Errors',
      name: '404 Errors',
      text: '404 Errors',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('404 Errors'))
    })

    brokenMenus.push({
      key: '404Links',
      name: '404 Links',
      text: '404 Links',
      // disabled: true,
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' }
    })

    const policMenus: ICommandBarItemProps[] = [];
    policMenus.push({
      key: 'PoliCheckCrawler',
      name: 'PoliCheck Crawler',
      text: 'PoliCheck Crawler',
      disabled: true,
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' }
    })

    policMenus.push({
      key: 'PoliCheckData',
      name: 'PoliCheck Data',
      text: 'PoliCheck Data',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('PoliCheck'))
    })

    const fullTextMenus: ICommandBarItemProps[] = [];
    fullTextMenus.push({
      key: 'AzureKBSearch',
      name: 'AzureKB Search',
      text: 'AzureKB Search',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('AzureKB Search'))
    })

    const algorithmsMenus: ICommandBarItemProps[] = [];
    algorithmsMenus.push({
      key: 'BrokenLink',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level2',
      style: { paddingLeft: '80px' },
      sectionProps: {
        topDivider: false,
        bottomDivider: false,
        title: 'Broken Link',
        items: brokenMenus
      }
    })

    algorithmsMenus.push({
      key: 'PoliCheck',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level2',
      style: { paddingLeft: '80px' },
      sectionProps: {
        topDivider: false,
        bottomDivider: false,
        title: 'PoliCheck',
        items: policMenus
      }
    })

    algorithmsMenus.push({
      key: 'AzureKBSearch',
      name: 'AzureKB Search',
      text: 'AzureKB Search',
      style: { paddingLeft: '50px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('AzureKB Search'))
    })

    const newMenuItems: ICommandBarItemProps[] = [];
    newMenuItems.push({
      key: 'metadataSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'Metadata and Metrix',
        items: metadataItems
      }
    });

    newMenuItems.push({
      key: 'algorithmsSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'Content Algorithms',
        items: algorithmsMenus,
      }
    });

    return newMenuItems
  }

  renderIndexItemsForDevOld() {
    const metadataItems: IContextualMenuItem[] = [];
    metadataItems.push({
      key: 'Evergreen',
      name: 'internal.evergreen.microsoft.com (Evergreen)',
      text: 'internal.evergreen.microsoft.com (Evergreen)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('Evergreen'))
    })

    metadataItems.push({
      key: 'SMC',
      name: 'support.microsoft.com (SMC)',
      text: 'support.microsoft.com (SMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      disabled: true,
      onClick: () => this.props.onRepoClick(this.getIndexByName('SMC'))
    })

    metadataItems.push({
      key: 'LMC',
      name: 'learn.microsoft.com (LMC)',
      text: 'learn.microsoft.com (LMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      disabled: true,
      onClick: () => this.props.onRepoClick(this.getIndexByName('LMC'))
    })

    metadataItems.push({
      key: 'SXC',
      name: 'support.xbox.com (SXC)',
      text: 'support.xbox.com (SXC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      disabled: true,
      onClick: () => this.props.onRepoClick(this.getIndexByName('SXC'))
    })

    metadataItems.push({
      key: 'AMC',
      name: 'answers.microsoft.com (AMC)',
      text: 'answers.microsoft.com (AMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      disabled: true,
      onClick: () => this.props.onRepoClick(this.getIndexByName('AMC'))
    })

    const brokenMenus: ICommandBarItemProps[] = [];
    brokenMenus.push({
      key: 'BrokenLinkCrawler',
      name: 'Broken Link Crawler',
      text: 'Broken Link Crawler',
      disabled: false,
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('Broken Link Crawler'))
    })

    brokenMenus.push({
      key: '404Errors',
      name: '404 Errors',
      text: '404 Errors',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('404 Errors'))
    })

    brokenMenus.push({
      key: '404Links',
      name: '404 Links',
      text: '404 Links',
      // disabled: true,
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' }
    })

    const policMenus: ICommandBarItemProps[] = [];
    policMenus.push({
      key: 'PoliCheckCrawler',
      name: 'PoliCheck Crawler',
      text: 'PoliCheck Crawler',
      disabled: true,
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' }
    })

    policMenus.push({
      key: 'PoliCheckData',
      name: 'PoliCheck Data',
      text: 'PoliCheck Data',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('PoliCheck'))
    })

    const fullTextMenus: ICommandBarItemProps[] = [];
    fullTextMenus.push({
      key: 'AzureKBSearch',
      name: 'AzureKB Search',
      text: 'AzureKB Search',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('AzureKB Search'))
    })

    const algorithmsMenus: ICommandBarItemProps[] = [];
    algorithmsMenus.push({
      key: 'BrokenLink',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level2',
      style: { paddingLeft: '80px' },
      sectionProps: {
        topDivider: false,
        bottomDivider: false,
        title: 'Broken Link',
        items: brokenMenus
      }
    })

    algorithmsMenus.push({
      key: 'PoliCheck',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level2',
      style: { paddingLeft: '80px' },
      sectionProps: {
        topDivider: false,
        bottomDivider: false,
        title: 'PoliCheck',
        items: policMenus
      }
    })

    algorithmsMenus.push({
      key: 'AzureKBSearch',
      name: 'AzureKB Search',
      text: 'AzureKB Search',
      style: { paddingLeft: '50px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('AzureKB Search'))
    })

    const newMenuItems: ICommandBarItemProps[] = [];
    newMenuItems.push({
      key: 'metadataSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'Metadata and Metrix',
        items: metadataItems
      }
    });

    newMenuItems.push({
      key: 'algorithmsSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'Content Algorithms',
        items: algorithmsMenus,
      }
    });

    return newMenuItems
  }

  renderIndexForProdNew() {
    const metadataItems: IContextualMenuItem[] = [];
    metadataItems.push({
      key: 'Evergreen',
      name: 'internal.evergreen.microsoft.com (Evergreen)',
      text: 'internal.evergreen.microsoft.com (Evergreen)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Evergreen'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    metadataItems.push({
      key: 'SMC',
      name: 'support.microsoft.com (SMC)',
      text: 'support.microsoft.com (SMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('SMC'))
    })

    metadataItems.push({
      key: 'LMC',
      name: 'learn.microsoft.com (LMC)',
      text: 'learn.microsoft.com (LMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('LMC'))
    })

    metadataItems.push({
      key: 'SXC',
      name: 'support.xbox.com (SXC)',
      text: 'support.xbox.com (SXC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('SXC'))
    })

    metadataItems.push({
      key: 'CSSWiki',
      name: 'CSS Wiki',
      text: 'CSS Wiki',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('CSS Wiki'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    metadataItems.push({
      key: 'AMC',
      name: 'answers.microsoft.com (AMC)',
      text: 'answers.microsoft.com (AMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      disabled: true,
      onClick: () => this.props.onRepoClick(this.getIndexByName('AMC'))
    })

    const brokenMenus: ICommandBarItemProps[] = [];
    brokenMenus.push({
      key: '404Errors',
      name: '404 Errors',
      text: '404 Errors',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('404 Errors'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    brokenMenus.push({
      key: '404Links',
      name: '404 Links',
      text: '404 Links',
      //disabled: true,
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('404 Links'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    const policMenus: ICommandBarItemProps[] = [];
    policMenus.push({
      key: 'PoliCheckData',
      name: 'PoliCheck Data',
      text: 'PoliCheck Data',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('PoliCheck'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    policMenus.push({
      key: 'PoliCheckAggregatedData',
      name: 'PoliCheck Aggregated Data',
      text: 'PoliCheck Aggregated Data',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('PoliCheck Aggregated Data'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    const brokenImageMenus: ICommandBarItemProps[] = [];
    brokenImageMenus.push({
      key: 'ImageData',
      name: 'Image Data',
      text: 'Image Data',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Image Data'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    brokenImageMenus.push({
      key: 'Image Aggregated Data',
      name: 'Image Aggregated Data',
      text: 'Image Aggregated Data',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Image Aggregated Data'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    const algorithmsMenus: ICommandBarItemProps[] = [];
    algorithmsMenus.push({
      key: 'BrokenLink',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level2',
      style: { paddingLeft: '80px' },
      sectionProps: {
        topDivider: false,
        bottomDivider: false,
        title: 'Broken Link',
        items: brokenMenus
      }
    })

    algorithmsMenus.push({
      key: 'PoliCheck',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level2',
      style: { paddingLeft: '80px' },
      sectionProps: {
        topDivider: false,
        bottomDivider: false,
        title: 'PoliCheck',
        items: policMenus
      }
    })

    algorithmsMenus.push({
      key: 'BrokenImage',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level2',
      style: { paddingLeft: '80px' },
      sectionProps: {
        topDivider: false,
        bottomDivider: false,
        title: 'Broken Image',
        items: brokenImageMenus
      }
    })

    algorithmsMenus.push({
      key: 'CustomizedArticles',
      name: 'Customized articles',
      text: 'Customized articles',
      style: { paddingLeft: '50px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Customized articles'))
        this.handlAlgoSelected('BrokenLinkCrawler', 'Broken Link Crawler')
      }
    })

    algorithmsMenus.push({
      key: 'EmptyDataset',
      name: 'Empty Dataset',
      text: 'Empty Dataset',
      style: { paddingLeft: '50px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('AzureKB Search'))
        this.handlAlgoSelected('AzureKBSearch', 'AzureKB Search')
      }
    })

    const mediaMenus: ICommandBarItemProps[] = [];
    mediaMenus.push({
      key: 'ImageAssets',
      name: 'Image Assets',
      text: 'Image Assets',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Image Assets'))
        this.handlAlgoSelected('ImageStatusChecker', 'Image Status Checker')
      }
    })

    const issueCenterMenus: ICommandBarItemProps[] = [];
    issueCenterMenus.push({
      key: 'IssueCenter',
      name: 'Issue Center',
      text: 'Issue Center',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Issue Center'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    const newMenuItems: ICommandBarItemProps[] = [];
    newMenuItems.push({
      key: 'metadataSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'Metadata and Metrix',
        items: metadataItems
      }
    });

    newMenuItems.push({
      key: 'algorithmsSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'Algorithm results',
        items: algorithmsMenus,
      }
    });

    newMenuItems.push({
      key: 'mediaSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'Media Assets',
        items: mediaMenus
      }
    });

    newMenuItems.push({
      key: 'issueCenterSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'IssueCenter results',
        items: issueCenterMenus,
      }
    });

    return newMenuItems
  }

  renderIndexItemsForDevNew() {
    const metadataItems: IContextualMenuItem[] = [];
    metadataItems.push({
      key: 'Evergreen',
      name: 'internal.evergreen.microsoft.com (Evergreen)',
      text: 'internal.evergreen.microsoft.com (Evergreen)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Evergreen'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    metadataItems.push({
      key: 'SMC',
      name: 'support.microsoft.com (SMC)',
      text: 'support.microsoft.com (SMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('SMC'))
    })

    metadataItems.push({
      key: 'LMC',
      name: 'learn.microsoft.com (LMC)',
      text: 'learn.microsoft.com (LMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('LMC'))
    })

    metadataItems.push({
      key: 'SXC',
      name: 'support.xbox.com (SXC)',
      text: 'support.xbox.com (SXC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => this.props.onRepoClick(this.getIndexByName('SXC'))
    })

    metadataItems.push({
      key: 'CSSWiki',
      name: 'CSS Wiki',
      text: 'CSS Wiki',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('CSS Wiki'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    metadataItems.push({
      key: 'AMC',
      name: 'answers.microsoft.com (AMC)',
      text: 'answers.microsoft.com (AMC)',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      disabled: true,
      onClick: () => this.props.onRepoClick(this.getIndexByName('AMC'))
    })

    const brokenMenus: ICommandBarItemProps[] = [];

    brokenMenus.push({
      key: '404Errors',
      name: '404 Errors',
      text: '404 Errors',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('404 Errors'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    brokenMenus.push({
      key: '404Links',
      name: '404 Links',
      text: '404 Links',
      //disabled: true,
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('404 Links'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    const policMenus: ICommandBarItemProps[] = [];
    policMenus.push({
      key: 'PoliCheckData',
      name: 'PoliCheck Data',
      text: 'PoliCheck Data',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('PoliCheck'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    policMenus.push({
      key: 'PoliCheckAggregatedData',
      name: 'PoliCheck Aggregated Data',
      text: 'PoliCheck Aggregated Data',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('PoliCheck Aggregated Data'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    const brokenImageMenus: ICommandBarItemProps[] = [];
    brokenImageMenus.push({
      key: 'ImageData',
      name: 'Image Data',
      text: 'Image Data',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Image Data'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    brokenImageMenus.push({
      key: 'Image Aggregated Data',
      name: 'Image Aggregated Data',
      text: 'Image Aggregated Data',
      style: { paddingLeft: '60px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Image Aggregated Data'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })

    const algorithmsMenus: ICommandBarItemProps[] = [];
    algorithmsMenus.push({
      key: 'BrokenLink',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level2',
      style: { paddingLeft: '80px' },
      sectionProps: {
        topDivider: false,
        bottomDivider: false,
        title: 'Broken Link',
        items: brokenMenus
      }
    })

    algorithmsMenus.push({
      key: 'PoliCheck',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level2',
      style: { paddingLeft: '80px' },
      sectionProps: {
        topDivider: false,
        bottomDivider: false,
        title: 'PoliCheck',
        items: policMenus
      }
    })

    algorithmsMenus.push({
      key: 'BrokenImage',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level2',
      style: { paddingLeft: '80px' },
      sectionProps: {
        topDivider: false,
        bottomDivider: false,
        title: 'Broken Image',
        items: brokenImageMenus
      }
    })

    algorithmsMenus.push({
      key: 'CustomizedArticles',
      name: 'Customized articles',
      text: 'Customized articles',
      style: { paddingLeft: '50px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Customized articles'))
        this.handlAlgoSelected('BrokenLinkCrawler', 'Broken Link Crawler')
      }
    })

    algorithmsMenus.push({
      key: 'EmptyDataset',
      name: 'Empty Dataset',
      text: 'Empty Dataset',
      style: { paddingLeft: '50px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('AzureKB Search'))
        this.handlAlgoSelected('AzureKBSearch', 'AzureKB Search')
      }
    })

    const mediaMenus: ICommandBarItemProps[] = [];
    mediaMenus.push({
      key: 'ImageAssets',
      name: 'Image Assets',
      text: 'Image Assets',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Image Assets'))
        this.handlAlgoSelected('ImageStatusChecker', 'Image Status Checker')
      }
    })

    const newMenuItems: ICommandBarItemProps[] = [];
    newMenuItems.push({
      key: 'metadataSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'Metadata and Metrix',
        items: metadataItems
      }
    });

    newMenuItems.push({
      key: 'algorithmsSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'Algorithm results',
        items: algorithmsMenus,
      }
    });

    newMenuItems.push({
      key: 'mediaSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'Media Assets',
        items: mediaMenus
      }
    });

    const issueCenterMenus: ICommandBarItemProps[] = [];
    issueCenterMenus.push({
      key: 'IssueCenter',
      name: 'Issue Center',
      text: 'Issue Center',
      style: { paddingLeft: '40px' },
      iconProps: { iconName: 'ExternalTFVC' },
      onClick: () => {
        this.props.onRepoClick(this.getIndexByName('Issue Center'))
        this.handlAlgoSelected('Blank', 'Select an algorithm')
      }
    })
    newMenuItems.push({
      key: 'issueCenterSection',
      itemType: ContextualMenuItemType.Section,
      className: 'sonic-commbar-section-level1',
      sectionProps: {
        topDivider: true,
        bottomDivider: true,
        title: 'IssueCenter results',
        items: issueCenterMenus,
      }
    });
    return newMenuItems
  }

  /**
   * Get index by name
   * @param indexName 
   * @returns 
   */
  getIndexByName(indexName: string) {
    this.setState({
      indexName
    })
    if (this.props && this.props.metadataQueryIndexList) {
      return this.props.metadataQueryIndexList.find(t => t.name === indexName);
    }

    return null;
  }

  /**
   * Build repo name
   * @returns 
   */
  buildRepoName() {
    if (this.props.repo) {
      if (this.props.repo.name === 'Evergreen') {
        return 'internal.evergreen.microsoft.com (Evergreen)'
      }
      if (this.props.repo.name === 'LMC') {
        return 'learn.microsoft.com (LMC)'
      }
      if (this.props.repo.name === 'SMC') {
        return 'support.microsoft.com (SMC)'
      }
      if (this.props.repo.name === 'SXC') {
        return 'support.xbox.com (SXC)'
      }
      else if (this.props.repo.name === 'PoliCheck') {
        return 'PoliCheck Data'
      }
      else if (this.props.repo.name === 'AzureKB Search') {
        return 'Empty Dataset'
      }

      return this.props.repo.name;
    }

    return 'internal.evergreen.microsoft.com (Evergreen)'
  }

  renderCommandBarItems = () => {
    // filter editor page
    let menuItemsFilter: ICommandBarItemProps[] = []
    menuItemsFilter.push({
      key: "Run",
      name: "Run",
      iconProps: {
        iconName: "Play",
      },
      disabled:
        !hasValidExpression(this.props.queryExpressions) ||
        !this.props.metadataFields,
      onClick: () => {
        this.props.onRunClick();
      }
    })
    menuItemsFilter.push({
      key: "Discard",
      name: "Discard",
      iconProps: {
        iconName: "Undo",
      },
      disabled: false,
      onClick: () => {
        this.props.onDiscardClick()
      }
    })
    menuItemsFilter.push({
      key: "Export Results",
      name: "Export Results",
      iconProps: {
        iconName: "Download",
      },
      disabled: !(
        this.props.searchResult.Topics &&
        this.props.searchResult.Topics?.length > 0
      ),
      onClick: () => {
        this.props.onExportResultsClick();
      }
    })
    menuItemsFilter.push({
      name: this.buildRepoName(),
      key: "Content",
      iconProps: {
        iconName: "ExternalTFVC",
      },
      subMenuProps: {
        items: sonci_Env === "prod" ? this.renderIndexForProdNew() : this.renderIndexItemsForDevNew(),
      }
    })
    if (this.props.repo?.name === 'AzureKB Search') {
      menuItemsFilter.push({
        name: this.state.AlgoName,
        key: "Algo",
        disabled: this.state.indexName === 'Issue Center',
        iconProps: {
          iconName: "ExternalTFVC",
        },
        subMenuProps: {
          items: this.renderAlgorithmItems(),
        }
      })
    }

    menuItemsFilter.push({
      key: "FieldDictionary",
      name: "Field Dictionary",
      iconProps: {
        iconName: "DocumentSearch",
      },
      href: "https://aka.ms/ACCTFieldDictionary",
      target: "_blank"
    })

    // curation page
    let menuItemsCurationPage: ICommandBarItemProps[] = []
    menuItemsCurationPage.push({
      key: "Run",
      name: "Preview",
      iconProps: {
        iconName: "Play",
      },
      disabled:
        !hasValidExpression(this.props.queryExpressions) ||
        !this.props.metadataFields,
      onClick: () => {
        this.props.onRunClick();
      }
    })

    menuItemsCurationPage.push({
      key: "Discard",
      name: "Discard",
      iconProps: {
        iconName: "Undo",
      },
      disabled: false,
      onClick: () => {
        this.props.onDiscardClick()
      }
    })

    menuItemsCurationPage.push({
      name: this.buildRepoName(),
      key: "Content",
      iconProps: {
        iconName: "ExternalTFVC",
      },
      subMenuProps: {
        items: sonci_Env === "prod" ? this.renderIndexForProdNew() : this.renderIndexItemsForDevNew(),
      }
    })

    menuItemsCurationPage.push({
      name: this.state.AlgoName,
      key: "Algo",
      disabled: this.state.indexName === 'Issue Center',
      iconProps: {
        iconName: "ExternalTFVC",
      },
      subMenuProps: {
        items: this.renderAlgorithmItems(),
      }
    })

    menuItemsCurationPage.push({
      key: "FieldDictionary",
      name: "Field Dictionary",
      iconProps: {
        iconName: "DocumentSearch",
      },
      href: "https://aka.ms/ACCTFieldDictionary",
      target: "_blank"
    })

    if (this.props.isCurationPage) {
      return menuItemsCurationPage
    }

    return menuItemsFilter
  }

  render() {
    return (
      <div>
        <CommandBar items={this.renderCommandBarItems()} />
      </div>
    );
  }
}
export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(DataQueryCommandBar);
