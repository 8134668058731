import React, { useEffect, useState } from 'react'
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { connect } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import {
    Pivot,
    PivotItem,
    DetailsList,
    IColumn,
    IGroup,
    IDetailsHeaderProps,
    DetailsHeader,
    Link,
    CheckboxVisibility
} from "@fluentui/react";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import {
    Spinner
} from "@fluentui/react";
import {
    ITableColumn,
    SimpleTableCell,
    Table,
    renderSimpleCell,
    TwoLineTableCell,
} from "azure-devops-ui/Table";
import { Duration } from "azure-devops-ui/Duration";
import { css } from "azure-devops-ui/Util";
import { Ago } from "azure-devops-ui/Ago";
import { Icon } from "azure-devops-ui/Icon";
import { IIconProps } from '@fluentui/react/lib/Icon';

import { GlobalDataQuery } from '../../Interface/IPowerQuery'
import { getMyCurationRuns } from '../../../redux/Actions/PowerDataQuery'
import {formatDateTime} from '../../Utilities/CommonFunction'

const actionCreators = {
    getMyCurationRuns
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    myCurationRuns: state.myCurationRuns
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

interface IDetailsListItem {
    key: string;
    name: string;
    status: string;
    result: string;
    startTime: string;
    finishedDate: string;
    id: string;
    aritclecount: string;

}
const iconClass = mergeStyles({
});

const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
    greenYellow: [{ color: 'green' }, iconClass],
    salmon: [{ color: 'salmon' }, iconClass],
});

function MyCurationPivot(props: StoreProps & DispatchProps) {
    const history = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {

        const loadData = async () => {

            setIsLoading(true)

            await props.getMyCurationRuns()

            setIsLoading(false)
        }

        loadData()
    }, [])

    function buildItems() {
        let items: IDetailsListItem[] = []

        props.myCurationRuns.map((item) => {
            if (item.Runs) {
                for (var i = 0; i < item.Runs.length; i++) {
                    let articleCount = "0";
                    if (item.Runs[i].RunResults) {
                        articleCount = item.Runs[i].RunResults.ResultCount?.toString()
                    }

                    items.push({
                        key: item.Runs[i].Id.toString(),
                        name: item.Runs[i].BuildNumber.toString(),
                        status: item.Runs[i].Status?.toString(),
                        result: item.Runs[i].Result.toString(),
                        startTime: item.Runs[i].StartTime.toString(),
                        finishedDate: formatDateTime(item.Runs[i].FinishTime.toString()),
                        id: item.Runs[i].Id,
                        aritclecount: articleCount,
                    });
                }
            }
        })

        return items
    }

    function buildGroupItems() {
        let startIndex = 0
        let groups: IGroup[] = []
        props.myCurationRuns.map((item) => {
            let groupName = item.Name;
            groups.push({
                key: groupName,
                name: groupName,
                startIndex: startIndex,
                count: item.Runs ? item.Runs.length : 0
            });

            startIndex += item.Runs ? item.Runs.length : 0
        })

        return groups
    }

    function renderDateColumn(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<IDetailsListItem>,
        tableItem: IDetailsListItem): JSX.Element {
        return (
            <TwoLineTableCell
                key={"col-" + columnIndex}
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                line1={WithIcon({
                    className: "fontSize font-size",
                    iconProps: { iconName: "Calendar" },
                    children: (
                        <Ago date={new Date(tableItem.startTime)!} /*format={AgoFormat.Extended}*/ />
                    )
                })}
                line2={WithIcon({
                    className: "fontSize font-size bolt-table-two-line-cell-item",
                    iconProps: { iconName: "Clock" },
                    children: (
                        <Duration
                            startDate={new Date(tableItem.startTime)!}
                            endDate={new Date(tableItem.finishedDate)}
                        />
                    )
                })}
            />
        );
    }

    function WithIcon(props: {
        className?: string;
        iconProps: IIconProps;
        children?: React.ReactNode;
    }) {
        return (
            <div className={css(props.className, "flex-row flex-center")}>
                {Icon({ ...props.iconProps, className: "icon-margin" })}
                {props.children}
            </div>
        );
    }

    function buildColumns() {
        let columns: IColumn[] = []
        columns = [
            {
                key: 'result',
                name: '',
                fieldName: 'result',
                minWidth: 20,
                maxWidth: 20,
                onRender: () => (
                    <div>
                        <i className={`ms-Icon ms-Icon--${"CompletedSolid"} ${classNames.greenYellow}`} aria-hidden="true"></i>
                    </div>
                ),
            },
            { key: 'name', name: 'Name', fieldName: 'name', minWidth: 50, maxWidth: 80, isResizable: true },
            {
                key: 'finishedDate',
                name: 'Finished Date',
                fieldName: 'finishedDate',
                minWidth: 50,
                maxWidth: 150
            },
            { key: 'count', name: '# of Articles', fieldName: 'aritclecount', minWidth: 30, maxWidth: 80, isResizable: true },
        ];

        return columns
    }

    /**
     * navigate to detail form
     * @param event 
     * @param buildId 
     */
    function navigateToDetailForm(buildId: string) {

        return (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>) => {
            history(`/runs/results?buildId=${buildId}`)
            event.preventDefault()
        }

    }

    function renderItemColumn(item: IDetailsListItem, index: number, column: IColumn) {
        const fieldContent = item[column.fieldName as keyof IDetailsListItem] as string;

        switch (column.key) {
            case 'name':
                return (
                    //<Link target="_blank" href={"/taskrunresult?buildId?" + item.id} onClick={navigateToDetailForm(item.id) }>{fieldContent}</Link>
                    <Link target="_self" href={"/runs/results?buildId=" + item.id} >{fieldContent}</Link>
                )
            default:
                return <span>{fieldContent}</span>;
        }
    }

    function onRenderDetailsHeader(props: IDetailsHeaderProps) {
        return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={'Expand collapse groups'} />;
    }

    return (
        <Pivot aria-label="Basic Pivot Example">
            <PivotItem
                itemIcon="Recent"
                headerText="Recent runs"
                headerButtonProps={{
                    'data-order': 1,
                    'data-title': 'My Files Title',
                }}
            >
                {
                    isLoading ?
                        (<Spinner className='msccat-mycurationpage-loading' label="Loading" />)
                        :
                        (
                            <DetailsList
                                items={buildItems()}
                                groups={buildGroupItems()}
                                columns={buildColumns()}
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                ariaLabelForSelectionColumn="Toggle selection"
                                checkButtonAriaLabel="select row"
                                checkButtonGroupAriaLabel="select section"
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                onRenderItemColumn={renderItemColumn}
                                checkboxVisibility={CheckboxVisibility.hidden}
                            />
                        )
                }

            </PivotItem>

        </Pivot>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(MyCurationPivot);
