import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    IconButton,
    IIconProps,
    TooltipHost,
    Button,
    DefaultButton,
    Icon
} from '@fluentui/react'
import { returnType } from '../../Utilities/ReturnType'
import { GlobalDataQuery } from '../../Interface/IPowerQuery'
import { QueryExpression, FieldOptions, getMaxDepth } from '../../Utilities/QueryBuilderUtils'
import { MetadataQueryOperator } from '../../Utilities/QueryBuilderConstants'
import QueryRows from '../QueryRows/QueryRows'
import './DataQueryBuilder.css'

interface QueryBuilderProps {
    queryExpressions: QueryExpression
    groupHoverText: string
    metadataSchema: string
    addExpression: (index: number) => void
    appendNewExpression: () => void
    removeExpression: (index: number) => void
    groupRows: () => void
    onUngroupExpressions: (groupID: number) => void
    onUpdateGroupingCheckbox: (index: number) => void
    onUpdateAndOr: (index: number, key: string) => void
    onUpdateField: (index: number, option: FieldOptions) => void
    onUpdateOperator: (index: number, option: MetadataQueryOperator) => void
    onUpdateValue: (index: number, value: string) => void
}

const mapStateToProps = (state: GlobalDataQuery) => ({
    show: "aa"
})

const storeProps = returnType(mapStateToProps)
type StoreProps = typeof storeProps.returnType

const rowsGroupIcon: IIconProps = { iconName: 'RowsGroup' }

class DataQueryBuilder extends Component<QueryBuilderProps & StoreProps> {
    
    render() {
        const numOfGroupCols = getMaxDepth(this.props.queryExpressions) - 1
        return (
            <div className='container query-builder open'>
                <table>
                    <tbody>
                        <tr className="head-row">
                            <th className="add-remove" />
                            <th className="group">
                                <TooltipHost content={this.props.groupHoverText}>
                                    <IconButton
                                        iconProps={rowsGroupIcon}
                                        title="Group"
                                        ariaLabel="Group"
                                        className="groupRowIcon"
                                        onClick={this.props.groupRows}
                                        disabled={this.props.metadataSchema && this.props.metadataSchema.startsWith("AzureKBAPI")}
                                    //disabled={!rowsCanBeGrouped(this.props.queryExpressions)}
                                    />
                                </TooltipHost>
                            </th>
                            {numOfGroupCols > 0 ? <th colSpan={numOfGroupCols} /> : null}
                            <th className="and-or">And/Or</th>
                            <th className="field">Field</th>
                            <th className="operator">Operator</th>
                            <th className="value">Value</th>
                        </tr>
                        <QueryRows
                            queryExpressions={this.props.queryExpressions}
                            addExpression={this.props.addExpression}
                            removeExpression={this.props.removeExpression}
                            onUngroupExpressions={this.props.onUngroupExpressions}
                            onUpdateGroupingCheckbox={this.props.onUpdateGroupingCheckbox}
                            onUpdateAndOr={this.props.onUpdateAndOr}
                            onUpdateField={this.props.onUpdateField}
                            onUpdateOperator={this.props.onUpdateOperator}
                            onUpdateValue={this.props.onUpdateValue}
                        />
                    </tbody>
                </table>
                <DefaultButton
                    className="add-new-clause-btn"
                    onClick={this.props.appendNewExpression}
                >
                    <Icon iconName="add" className="add-new-clause-icon" />
                    <span className="add-new-clause-text">Add new clause</span>
                </DefaultButton>
            </div>
        )
    }
}


export default connect<StoreProps, {}, QueryBuilderProps>(
    mapStateToProps,
    null
)(DataQueryBuilder)