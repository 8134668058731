import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { connect } from "react-redux";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ticketDownload } from '../../../redux/Actions/PowerDataQuery'
import { GlobalDataQuery } from "../../Interface/IPowerQuery";

import { MSACCT_TITLE } from '../../Utilities/QueryBuilderConstants'

const actionCreators = {
  ticketDownload
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
  indexList: state.indexList,
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

function TicketDownloadPage(props: StoreProps & DispatchProps) {
  const [search, setSearch] = useSearchParams();
  const buildId = search.get('buildId')

  const [isDownload, setIsDownload] = useState<boolean>(true)

  useEffect(() => {
    const loadData = async () => { 
      setIsDownload(true)
      await props.ticketDownload(buildId)
      setIsDownload(false)
    }

    loadData()
  },[])
  
  return (
    <div>
      {isDownload && <span>downloading...</span>}
    </div>
  )
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(TicketDownloadPage);