import React, { Component } from "react";
import AppLayout from "../AppLayout/AppLayout";
import DataQuery from '../../Modules/DataQuery/DataQuery'
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'

const breadcrumbItem: IBreadCrumbExtension[] = [
  { text: 'Home', key: 'Home', href: '/' },
  { text: 'Discover Insights', key: 'f4'},
  { text: 'Curation Filter Editor', key: 'f5', href: '/queries/edit', isCurrentItem: true },
];


export default class PowerQueryPage extends Component {
  render() {
    document.title = MSACCT_TITLE + " | " + "Curation Filter Editor";
    return (
      <AppLayout
        containerClassName="filter"
        renderBreadcrumb={() =>
          <BreadCrumbExtension items={breadcrumbItem} />
        }
        renderContentPane={() => <DataQuery />}
         />
    );
  }
}