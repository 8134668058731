import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { connect } from "react-redux";
import {
    DocumentCard,
    DocumentCardTitle,
    DocumentCardDetails,
    DocumentCardImage,
    DocumentCardActions,
    IDocumentCardStyles,
} from '@fluentui/react/lib/DocumentCard';
import { Card } from "azure-devops-ui/Card";
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Label } from '@fluentui/react/lib/Label';
import { Image } from '@fluentui/react/lib/Image';
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { IButtonStyles } from 'office-ui-fabric-react';
import { PillGroup } from "azure-devops-ui/PillGroup";
import { Pill, PillSize, PillVariant } from "azure-devops-ui/Pill";
import { IColor } from "azure-devops-ui/Utilities/Color";
import { FontSizes } from '@fluentui/theme';
import { GlobalDataQuery } from '../../Interface/IPowerQuery'
import {getCurationIdByName} from '../../Utilities/CommonFunction'

import {
    loadAllTaskList
} from "../../../redux/Actions/PowerDataQuery";

import "./ProductLifecycleAnalyzerFamily.css";

const actionCreators = {
    loadAllTaskList
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    allTaskList: state.allTaskList
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;


const cardStyles: IDocumentCardStyles = {
    root: { display: 'inline-block', marginRight: '2%', marginBottom: '3%' },
};

const buttonStyles: IButtonStyles = {
    root: { width: '85%', padding: '15px 0', height: '40px', textAlign: "center", fontSize: '16px' },
};

const documentCardActionswithFavoriteStar = [
    {
        iconProps: { iconName: 'FavoriteStarFill' },
        ariaLabel: 'add action',
    },
];

const cardTitleshouldTruncateStyles: IDocumentCardStyles = {
    root: { fontSize: '16px', paddingTop: '14px' },
};

const productLifeCycleIconProps: IIconProps = {
    iconName: 'Lifesaver',
    styles: { root: { color: 'rgb(8, 129, 66)', fontSize: '40px' } },
};

const lightColor: IColor = {
    red: 255,
    green: 206,
    blue: 80,
};

const whiteColor: IColor = {
    red: 255,
    green: 255,
    blue: 255,
};

interface ICurationFollowCount {
    Algo1FollowCount: number,
    Algo2FollowCount: number,
    Algo3FollowCount: number,
    Algo4FollowCount: number,
    Algo5FollowCount: number,
    Algo6FollowCount: number,
}

function ProductLifecycleAnalyzerFamily(props: StoreProps & DispatchProps) {
    const history = useNavigate();

    const [followCount, setFollowCount] = useState<ICurationFollowCount>({
        Algo1FollowCount: 0,
        Algo2FollowCount: 0,
        Algo3FollowCount: 0,
        Algo4FollowCount: 0,
        Algo5FollowCount: 0,
        Algo6FollowCount: 0,
    })


    useEffect(() => {
        setFollowCount({
            Algo1FollowCount: 0,
            Algo2FollowCount: 0,
            Algo3FollowCount: 0,
            Algo4FollowCount: 0,
            Algo5FollowCount: 0,
            Algo6FollowCount: 0
        })

        props.loadAllTaskList()
        const loadData = async () => {
            // call the api to get the list
            await props.loadAllTaskList();
        }

        loadData()

    }, [])

    useEffect(() => {
        let Algo1FollowCount = getFollowCountById(getCurationIdByName("Product Expired Articles"))
        let Algo2FollowCount = getFollowCountById(getCurationIdByName("Articles with Empty Products Lifecycle Data"))
        let Algo3FollowCount = getFollowCountById(getCurationIdByName("Articles with Partial Expired Products"))
        let Algo4FollowCount = getFollowCountById(getCurationIdByName("Articles with Outdated SAP Values"))
        let Algo5FollowCount = getFollowCountById(getCurationIdByName("Product Expired Articles without Retired SAP Values"))
        let Algo6FollowCount = getFollowCountById(getCurationIdByName("Articles with Empty SAP Values"))

        console.log("Algo4FollowCount", Algo4FollowCount)

        setFollowCount({
            Algo1FollowCount: Algo1FollowCount,
            Algo2FollowCount: Algo2FollowCount,
            Algo3FollowCount: Algo3FollowCount,
            Algo4FollowCount: Algo4FollowCount,
            Algo5FollowCount: Algo5FollowCount,
            Algo6FollowCount: Algo6FollowCount
        })

    }, [props.allTaskList])

    /**
     * 
     * @param curationId 
     * @returns 
     */
    function getFollowCountById(curationId: string) {
        if (props.allTaskList) {
            let curationItem = props.allTaskList.find(t => t.id === curationId)
            if (curationItem) {
                return curationItem.FollowCount
            }
        }

        return 0
    }


    function gotoAlgo(name: string) {

        return (ev: React.MouseEvent<HTMLElement>) => {
            var guid = getCurationIdByName(name)
            switch (name) {
                case "Product Expired Articles":
                    history(`/algos/productexpiredarticlesanalyzer?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Articles with Empty Products Lifecycle Data":
                    history(`/algos/articleswithemptyproductslifecycledataanalyzer?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Articles with Partial Expired Products":
                    history(`/algos/articleswithpartialexpiredproductsanalyzer?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Articles with Outdated SAP Values":
                    history(`/algos/articleswithoutdatedsapvaluesanalyzer?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Product Expired Articles without Retired SAP Values":
                    history(`/algos/productexpiredarticleswithoutretiredsapvaluesanalyzer?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Articles with Empty SAP Values":
                    history(`/algos/articleswithemptysapvaluesanalyzer?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                default:
                    history(`/algos/productlifecycleanalyzer?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;
            }
        }
    }


    return (
        <Surface background={SurfaceBackground.normal}>
            <Page className="pipelines-page flex-grow">
                <div className="page-content page-content-top">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <div>
                                    <Label style={{ fontSize: FontSizes.xLarge, lineHeight: '120%' }}>Monitor Products Life Cycle to Keep your Content Refreshed</Label>
                                </div>
                                <p>
                                    <div style={{ fontSize: FontSizes.mediumPlus }}>What and how do you want to archive or refresh the articles applied to Retired Support Area Path (SAP) or non-supported products?</div>
                                </p>
                                <p>
                                    <div style={{ fontSize: FontSizes.mediumPlus }}>Product Lifecycle Analyzer with 6 algos are built for that.</div>
                                </p>

                                <p style={{ paddingTop: '50px' }}>
                                    <Card
                                        className="bolt-card-content"
                                    >
                                        <div>
                                            <h3 style={{ marginTop: '0' }}>Internal support article governance best practices</h3>
                                            <ul>
                                            <li>Use correct and non-Retired <a href='https://msaas.support.microsoft.com/sap/browse'>Support Area Path</a> (SAP)</li>
                                                        <li>Archive or unindex the articles if the applied products that are <a href='https://docs.microsoft.com/en-us/lifecycle'>no longer supported under standard or extended support</a>.</li>
                                            </ul>
                                        </div>
                                    </Card>
                                </p>
                            </div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">

                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg5">
                                <Image
                                    width={"100%"}
                                    src={require("./AlgoAD001.png")}
                                    alt='Products no longer supported under standard or extended support but should still be alive.'
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label style={{ fontSize: FontSizes.xLarge, marginBottom: "30px", marginTop: "30px" }}>Explore the Product Lifecycle Analyzer family</Label>
                            </div>
                        </div>
                        <div className="ms-Grid-row">

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DocumentCard
                                    aria-label={
                                        'Product Expired Articles'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={productLifeCycleIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Product Expired Articles" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="Product no longer supported under standard or extended support." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Product Expired Articles')} /></div>
                                    <DocumentCardActions className='msacct-family-follow'  actions={documentCardActionswithFavoriteStar} views={followCount.Algo1FollowCount} />
                                </DocumentCard>

                                <DocumentCard
                                    aria-label={
                                        'Articles with Empty Products Lifecycle Data'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={productLifeCycleIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Articles with Empty Products Lifecycle Data" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="An algorithm to monitor the articles without product lifecycle data." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Articles with Empty Products Lifecycle Data')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo2FollowCount} />
                                </DocumentCard>


                                <DocumentCard
                                    aria-label={
                                        'Articles with Partial Expired Products'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={productLifeCycleIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Articles with Partial Expired Products" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="An algorithm to monitor the articles containing certain applied products that are no longer supported under standard or extended support." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Articles with Partial Expired Products')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo3FollowCount} />
                                </DocumentCard>

                                <DocumentCard
                                    aria-label={
                                        'Articles with Outdated SAP Values'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={productLifeCycleIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Articles with Outdated SAP Values" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="An algorithm to monitor the articles that contain retied SAP values, but the applied products are still in support." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'> <PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Articles with Outdated SAP Values')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo4FollowCount} />
                                </DocumentCard>


                                <DocumentCard
                                    aria-label={
                                        'Product Expired Articles without Retired SAP Values'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={productLifeCycleIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Product Expired Articles without Retired SAP Values" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="An algorithm to monitor the articles that don't contain retied SAP values but the products applied to are no longer supported under standard or extended support." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Product Expired Articles without Retired SAP Values')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo5FollowCount} />
                                </DocumentCard>
                                <DocumentCard
                                    aria-label={
                                        'Articles with Empty SAP Values'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={productLifeCycleIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Articles with Empty SAP Values" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="An algorithm to monitor the articles without SAP values." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Articles with Empty SAP Values')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo6FollowCount} />
                                </DocumentCard>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <div className="categoryName"> Curation areas:</div>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                <PillGroup className="flex-row">
                                    <Pill size={PillSize.compact} color={lightColor}>
                                        Curation
                                    </Pill>
                                </PillGroup>
                            </div>
                        </div>
                        <br></br>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <div className="categoryName">Tags:</div>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                <PillGroup className="flex-row">
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}>
                                        Product Lifecycle
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}>
                                        SAP
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}>
                                        Product Name
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined} >
                                        Product Version
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined} >
                                        Product Family
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined} >
                                        Content Lifecycle
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined} >
                                        Archiving
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined} >
                                        End of support
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined} >
                                        Applies to
                                    </Pill>
                                </PillGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>

        </Surface>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(ProductLifecycleAnalyzerFamily);
