import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { connect } from "react-redux";
import DialogProvider from '../../Dialogs/DialogProvider'
import { GlobalDataQuery, DataQueryIndex } from "../../Interface/IPowerQuery";
import DataQueryBuilder from "../DataQueryBuilder/DataQueryBuilder";
import DataQueryCommandBar from "../DataQueryCommandBar/DataQueryCommandBar";
import DataQueryResult from "../DataQueryResult/DataQueryResult";
import { SplitterElementPosition, Splitter, SplitterDirection } from "azure-devops-ui/Splitter";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { getDefaultDateTime } from "../../Utilities/CommonFunction"

import {
  loadDataQueryIndexes,
  loadMetadataFields,
  selectIndex,
  searchContent,
  initializeSelectedColumns,
  exportSearchResult,
  clearSearchResults
} from "../../../redux/Actions/PowerDataQuery";



import {
  QueryExpression,
  getExpression,
  removeExpression,
  FieldOptions,
  generateSearchStrings,
  removeInvalidExpressions
} from "../../Utilities/QueryBuilderUtils";

import {
  initExprTree,
  setExpression,
  appendNewExpression,
  insertNewExpression,
  ungroupExpressions,
  groupSelectedExpressions,
  getGroupingHoverText
} from "../../Utilities/ExpressionTreeUtils";

import {
  MetadataQueryOperator,
  groupHoverText_notEnough,
  isSetOperator,
  isNotSetOperator,
  defaultFieldOperators,
  defaultFieldValues,
  SPECIAL_SEARCH_FIELDS
} from "../../Utilities/QueryBuilderConstants";

import {
  getCurationQueryFields,
  setCurationFieldsDefault,
  buildConditionJson
} from "../../Utilities/QueryBuilderExtension";

import "./DataQuery.css";

const actionCreators = {
  loadDataQueryIndexes,
  loadMetadataFields,
  selectIndex,
  searchContent,
  initializeSelectedColumns,
  clearSearchResults,
  exportSearchResult
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
  indexList: state.indexList,
  index: state.index,
  metadataFields:
    state.metadataFields &&
    state.index &&
    state.index.metadataSchema &&
    state.metadataFields[state.index.metadataSchema],
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

interface MetaDataQueryState {
  queryExpressions: QueryExpression;
  groupHoverText: string;
  hasOnRunClick: boolean;
  filterStrng: string;
  clickCount: number;
  indexName: string;
}

let collapsed = new ObservableValue(false);

class DataQuery extends Component<
  StoreProps & DispatchProps,
  MetaDataQueryState
> {
  constructor(props) {
    super(props);
    this.state = {
      groupHoverText: groupHoverText_notEnough,
      queryExpressions: initExprTree(),
      hasOnRunClick: false,
      filterStrng: '',
      clickCount: 0,
      indexName: ''
    };
  }

  async componentDidMount() {
    await this.props.loadDataQueryIndexes();

    if (this.props.indexList && this.props.indexList.length) {
      this.initPageState();

      // set editor box default value
      setCurationFieldsDefault(
        this.props.index.metadataSchema,
        "DataQuery",
        "",
        this.appendExpression,
        this.onUpdateGroupingCheckbox,
        this.groupRows,
        this.removeExpression)
    }
  }

  componentDidUpdate(prevProps: StoreProps & DispatchProps) {
    if (
      prevProps.indexList &&
      !prevProps.indexList.length &&
      this.props.indexList &&
      this.props.indexList.length
    ) {
      this.initPageState();
    }
  }

  // initialize page state
  initPageState() {
    if (!this.props.indexList || !this.props.indexList.length) {
      return;
    }

    let currentIndex: DataQueryIndex = null;
    if (this.props.index && this.props.index.searchService !== "") {
      currentIndex = this.props.index
    }
    else {
      currentIndex = this.props.indexList[0];
    }

    this.props.selectIndex(currentIndex);

    const loadedSchemas = [];
    this.props.indexList.forEach((r) => {
      let metadataSchema = r.metadataSchema;
      if (metadataSchema && !loadedSchemas.includes(metadataSchema)) {
        this.props.loadMetadataFields(metadataSchema);
        loadedSchemas.push(metadataSchema);
      }
    });

    this.props.clearSearchResults()
  }

  /**
   * @description Adds a new empty expression before the specified index
   * @param rowIndex Position in array to add new expression
   */
  addExpression = (rowIndex: number) => {
    const newRoot = { ...this.state.queryExpressions };
    insertNewExpression(newRoot, rowIndex);
    this.setState({
      queryExpressions: newRoot,
    });
  };

  removeInvalidExpressions = () => {
    const newRoot = { ...this.state.queryExpressions };
    removeInvalidExpressions(newRoot);
    this.setState({
      queryExpressions: newRoot,
    });
  };

  /**
   * @description Appends new expression to end of QueryRows
   */
  appendNewExpression = () => {
    const newRoot = { ...this.state.queryExpressions };
    appendNewExpression(newRoot);
    this.setState({
      queryExpressions: newRoot,
    });
  };

  appendExpression = (rowIndex: number, queryExpressions: QueryExpression) => {
    this.appendNewExpression();
    this.onUpdateAndOr(rowIndex, queryExpressions.andOr);

    let myFieldOptions: FieldOptions = {
      fieldType: queryExpressions.fieldType,
      enumValues: [],
      operation: "update",
      fieldName: queryExpressions.field,
      key: queryExpressions.field,
      text: queryExpressions.field
    };
    this.onUpdateField(rowIndex, myFieldOptions);
    this.onUpdateOperator(rowIndex, queryExpressions.operator);
    this.onUpdateValue(rowIndex, queryExpressions.value);
  };

  /**
   * @description Removes an expression from tree
   * @param rowIndex Position of expression to remove
   */
  removeExpression = (rowIndex: number) => {
    const newRoot = { ...this.state.queryExpressions };
    removeExpression(newRoot, rowIndex);
    this.setState({
      queryExpressions: newRoot,
    });
  };

  /**
   * @description Creates a new expression group consisting of selected rows,
   * and inserts group into tree at position of closest common ancestor
   */
  groupRows = () => {
    const newRoot = { ...this.state.queryExpressions };
    groupSelectedExpressions(newRoot);
    this.setState({
      queryExpressions: newRoot,
    });
  };

  /**
   * @description Ungroups target expresstion group
   * @param groupID ID of target expression group
   */
  onUngroupExpressions = (groupID: number) => {
    const newRoot = { ...this.state.queryExpressions };
    ungroupExpressions(newRoot, groupID);
    this.setState({
      queryExpressions: newRoot,
    });
  };

  /**
   * @description Toggles grouping checkbox for target expression in array
   * @param rowIndex Position in expression array to update
   */
  onUpdateGroupingCheckbox = (rowIndex: number) => {
    const root = { ...this.state.queryExpressions };
    const expr = getExpression(root, rowIndex);
    expr.group ? (expr.group = false) : (expr.group = true);
    this.setExpression(rowIndex, expr);
    this.setGroupingHoverText();
  };

  /**
   * @description Overwrites the expression at target rowIndex
   * @param rowIndex Position in QueryRows of target expression
   * @param newExpression New values for target expression to use
   */
  setExpression = (rowIndex: number, newExpression: QueryExpression) => {
    const newRoot = { ...this.state.queryExpressions };
    setExpression(newRoot, rowIndex, newExpression);
    this.setState({
      queryExpressions: newRoot,
    });
  };

  /**
   * @description Sets hover text for grouping button to indicate reason why they can/can't
   * be grouped.
   */
  setGroupingHoverText = () => {
    this.setState({
      groupHoverText: getGroupingHoverText(this.state.queryExpressions),
    });
  };

  /**
   * @description Sets grouping operator for target expression in array
   * @param rowIndex Position in expression array to update
   * @param key Selected grouping operator (And/Or)
   */
  onUpdateAndOr = (rowIndex: number, key: string) => {
    const root = { ...this.state.queryExpressions };
    const expr = getExpression(root, rowIndex);
    expr.andOr = key;
    this.setExpression(rowIndex, expr);
  };

  /**
   * @description Sets field for target expression in array
   * @rowIndex Position in expression array to update
   * @option Selected field
   */
  onUpdateField = (rowIndex: number, option: FieldOptions) => {
    const root = { ...this.state.queryExpressions };
    const expr = getExpression(root, rowIndex);
    expr.field = option.key as string;
    if (expr.fieldType !== option.fieldType) {
      expr.fieldType = option.fieldType;
      // set default operator and value
      if (option.fieldType in defaultFieldOperators)
        expr.operator = defaultFieldOperators[option.fieldType];
      if (option.fieldType in defaultFieldValues)
        expr.value = defaultFieldValues[option.fieldType];
    }
    this.setExpression(rowIndex, expr);
  };

  /**
   * @description Sets operator for target expression in array
   * @param Position in expression array to update
   * @param operator Selected operator
   */
  onUpdateOperator = (rowIndex: number, operator: MetadataQueryOperator) => {
    const root = { ...this.state.queryExpressions };
    const expr = getExpression(root, rowIndex);
    expr.operator = operator;
    if (operator === isSetOperator || operator === isNotSetOperator) {
      expr.value = "";
    } else {
      if (expr.fieldType in defaultFieldValues)
        expr.value = defaultFieldValues[expr.fieldType];
    }
    this.setExpression(rowIndex, expr);
  };

  /**
   * @description Sets value for target expression in array
   * @param rowIndex Position in expression array to update
   * @param value New value
   */
  onUpdateValue = (rowIndex: number, value: string) => {
    const root = { ...this.state.queryExpressions };
    const expr = getExpression(root, rowIndex);
    expr.field === "guid"
      ? (expr.value = value.toLocaleLowerCase())
      : (expr.value = value);
    this.setExpression(rowIndex, expr);
  };

  /**
  * @description Clears query expressions
  */
  clearQuery = () => {
    this.setState({
      queryExpressions: initExprTree()
    })
  }

  /**
 * @description onClick handler for selecting a repo in the menu bar dropdown
 */
  onRepoClick = (index: DataQueryIndex) => {
    this.setState({
      hasOnRunClick: false,
      filterStrng: ''
    })
    this.clearQuery()
    this.props.selectIndex(index)
    this.props.clearSearchResults()

    for (let i = this.state.queryExpressions.children.length - 1; i >= 0; i--) {
      this.removeExpression(i)
    }

    if (this.props.indexList && this.props.indexList.length) {
      // this.initPageState();

      // set editor box default value
      setCurationFieldsDefault(
        index.metadataSchema,
        "",
        "DataQuery",
        this.appendExpression,
        this.onUpdateGroupingCheckbox,
        this.groupRows,
        this.removeExpression)

      this.setState({
        indexName: index.name
      })
    }
  }

  /**
   * @description onClick handler for executing a query
   */
  onRunClick = () => {
    this.setState({
      hasOnRunClick: false,
      filterStrng: ''
    }, () => {
      const allFields = [...this.props.metadataFields, ...SPECIAL_SEARCH_FIELDS];

      this.props.clearSearchResults();
      this.removeInvalidExpressions()

      const { searchString, filterString } = generateSearchStrings(
        this.state.queryExpressions,
        allFields
      );

      this.setState({
        hasOnRunClick: true,
        filterStrng: filterString,
        clickCount: this.state.clickCount + 1
      })
    })
  }


  /**
   * @description onClick handler for exporting query results to CSV
   */
  onExportResultsClick = () => {
    const allFields = [...this.props.metadataFields];
    this.removeInvalidExpressions()

    const { searchString, filterString } = generateSearchStrings(
      this.state.queryExpressions,
      allFields
    );

    let selectString = getCurationQueryFields(this.props.index.metadataSchema);

    this.props.exportSearchResult(
      "*",
      this.props.index.metadataSchema,
      this.props.index.IndexPrefix,
      filterString,
      selectString,
      "Untitled result.csv"
    )
  }

  /**
 * @description onClick handler for clearing query expressions and any results
 */
  onDiscardClick = () => {
    this.setState({
      hasOnRunClick: false,
      filterStrng: ''
    })
    this.clearQuery()
    this.props.clearSearchResults()
  }

  _renderNearContent = () => {
    return (
      <DataQueryBuilder
        queryExpressions={this.state.queryExpressions}
        addExpression={this.addExpression}
        metadataSchema={this.props.index?.metadataSchema}
        appendNewExpression={this.appendNewExpression}
        removeExpression={this.removeExpression}
        groupRows={this.groupRows}
        groupHoverText={this.state.groupHoverText}
        onUngroupExpressions={this.onUngroupExpressions}
        onUpdateGroupingCheckbox={this.onUpdateGroupingCheckbox}
        onUpdateAndOr={this.onUpdateAndOr}
        onUpdateField={this.onUpdateField}
        onUpdateOperator={this.onUpdateOperator}
        onUpdateValue={this.onUpdateValue}
      />
    )
  }

  _renderFarContent = () => {
    return (
      this.state.hasOnRunClick &&
      <DataQueryResult
        AlgorithmType={'Blank'}
        expressionChildren={this.state.queryExpressions.children}
        metadataSchemaProps={this.props.index.metadataSchema}
        filterStringProps={this.state.filterStrng}
        clickCountProps={this.state.clickCount}
        indexName={this.state.indexName}
      />
    )
  }

  render() {
    return (
      <div className="Metadata-Query-Container">
        <DialogProvider />
        <DataQueryCommandBar
          queryExpressions={this.state.queryExpressions}
          onRunClick={this.onRunClick}
          onRepoClick={this.onRepoClick}
          onExportResultsClick={this.onExportResultsClick}
          onDiscardClick={this.onDiscardClick}
        />
        <Splitter
          collapsed={collapsed}
          fixedElement={SplitterElementPosition.Near}
          splitterDirection={SplitterDirection.Horizontal}
          initialFixedSize={300}
          minFixedSize={100}
          nearElementClassName="v-scroll-auto custom-scrollbar"
          farElementClassName="v-scroll-auto custom-scrollbar"
          onRenderNearElement={this._renderNearContent}
          onRenderFarElement={this._renderFarContent}
          className="msacct-filter-splitter"
        />
      </div>
    );
  }
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(DataQuery);
