import React, { Component } from "react";
import ArticlesWithEmptySAPValues from '../../Modules/Algorithms/ArticlesWithEmptySAPValues'
import AppLayout from "../AppLayout/AppLayoutV2";
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'


import './ProductLifecycleAnalyzer.css'

const breadcrumbItem: IBreadCrumbExtension[] = [
  { text: 'Home', key: 'Home', href: '/' },
  { text: 'Algorithms Center', key: 'ia', href: '/algos/productlifecycleanalyzerfamily'},
  { text: 'Product Lifecycle Analyzer', key: 'pla'},
];

export default class ArticlesWithEmptySAPValuesAnalyzerPage extends Component {
  render() {
    document.title = MSACCT_TITLE + " | Algorithms Center";
    return (
      <AppLayout
        renderBreadcrumb={() =>
          <BreadCrumbExtension items={breadcrumbItem} />
        }
        renderContentPane={() => <ArticlesWithEmptySAPValues />} />
    )
  }
}
