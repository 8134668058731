import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { returnType } from "../../../Utilities/ReturnType";

import { GlobalDataQuery } from "../../../Interface/IPowerQuery";

import { loadDataQueryIndexes, selectIndex } from '../../../../redux/Actions/PowerDataQuery'
import { PillGroup, PillGroupOverflow } from "azure-devops-ui/PillGroup";
import { Pill, PillSize, PillVariant } from "azure-devops-ui/Pill";
import { IColor } from "azure-devops-ui/Utilities/Color";

import { useNavigate } from "react-router-dom";
import { getCurationIdByName } from '../../../Utilities/CommonFunction'


const actionCreators = {
    loadDataQueryIndexes,
    selectIndex
};
type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    indexList: state.indexList,
    index: state.index,
    searchResult: state.searchResult
});
const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;


interface IAlgorithmHeaderProps {
    Domains: string[],
    CurationAreas: string[],
    Tags: string[],
    SelectedDomain: string,
    ClickPageName: string
}

interface IAlgorithmHeaderState {
    SelectedDomain: string
}

function AlgorithmHeaderViews(props: StoreProps & DispatchProps & IAlgorithmHeaderProps) {
    const lightColor: IColor = {
        red: 255,
        green: 206,
        blue: 80,
    };

    const whiteColor: IColor = {
        red: 255,
        green: 255,
        blue: 255,
    };

    const [algorithmHeaderState, setAlgorithmHeaderState] = useState<IAlgorithmHeaderState>({
        SelectedDomain: ""
    })

    const history = useNavigate();

    /**
     * like componentDidMount
     */
    useEffect(() => {
        let selectedDomain = props.SelectedDomain
        // if (props.index) {
        //     selectedDomain = props.index.name
        // }
        setAlgorithmHeaderState({
            SelectedDomain: selectedDomain === "Internal Content" ? "Evergreen" : selectedDomain
        })

        // Call api to get data
        const loadInitData = async () => {
            await props.loadDataQueryIndexes();
        }

        if (!props.indexList || props.indexList.length === 0) {
            loadInitData()
        }
    }, [])

    /**
     * like componentDidUpdate
     */
    useEffect(() => {

    }, [props.searchResult.Topics])

    function renderDomain(): JSX.Element {
        return (
            <PillGroup className="flex-row">
                {
                    props.Domains.map((item, index) => {
                        return (
                            <Pill
                                size={PillSize.compact}
                                color={algorithmHeaderState.SelectedDomain === item ? lightColor : whiteColor}
                                variant={PillVariant.outlined}
                                onClick={() => {
                                    handleDomainClick(item)
                                }}
                            >{item}
                            </Pill>
                        )
                    })

                }
            </PillGroup>
        )
    }

    function reanderCurationAreas(): JSX.Element {
        return (
            <PillGroup className="flex-row">
                {
                    props.CurationAreas.map(item => {
                        return (
                            <Pill size={PillSize.compact} color={lightColor}>
                                {item}
                            </Pill>
                        )
                    })
                }

            </PillGroup>
        )
    }

    function reanderTags(): JSX.Element {
        return (
            <PillGroup className="flex-row">
                {
                    props.Tags.map(item => {
                        return (
                            <Pill
                                size={PillSize.compact}
                                color={whiteColor}
                                variant={PillVariant.outlined}
                            >
                                {item}
                            </Pill>
                        )
                    })
                }

            </PillGroup>
        )
    }

    function handleDomainClick(name: string) {
        setAlgorithmHeaderState({
            SelectedDomain: name
        })

        /*let newName = name === "Evergreen" ? "Internal Content" : name
        let currentIndex = props.indexList.find(item => item.name === newName)
        if (currentIndex) {
            props.selectIndex(currentIndex)
        }*/

        let pageName = `${props.ClickPageName} for ${name}`;
       
        switch (pageName) {

            case "Broken Link Checker for Evergreen":
                history(`/algos/brokenlinkchecker?curationId=${getCurationIdByName(pageName)}`)
                break;

            case "Broken Link Checker for AMC":
                history(`/algos/brokenlinkcheckeramc?curationId=${getCurationIdByName(pageName)}`)
                break;

            case "Broken Link Checker for DMC":
                history(`/algos/brokenlinkcheckerdmc?curationId=${getCurationIdByName(pageName)}`)
                break;

            case "Broken Link Checker for SMC":
                history(`/algos/brokenlinkcheckersmc?curationId=${getCurationIdByName(pageName)}`)
                break;
            case "Broken Link Checker for SXC":
                history(`/algos/brokenlinkcheckersxc?curationId=${getCurationIdByName(pageName)}`)
                break;

            case "Broken Link Checker for Other MS Sites":
                history(`/algos/brokenlinkcheckerunknown?curationId=${getCurationIdByName(pageName)}`)
                break;

            case "PoliCheck for Evergreen":
                history(`/algos/policheck?curationId=${getCurationIdByName(pageName)}`)
                break;

            case "PoliCheck for LMC":
                history(`/algos/polichecklmc?curationId=${getCurationIdByName(pageName)}`)
                break;

            case "PoliCheck for SMC":
                history(`/algos/polichecksmc?curationId=${getCurationIdByName(pageName)}`)
                break;

            case "PoliCheck for SXC":
                history(`/algos/polichecksxc?curationId=${getCurationIdByName(pageName)}`)
                break;

            default:
                history(`/algos/productlifecycleanalyzer?curationId=${getCurationIdByName(pageName)}`)
                //this.preventDefault();
                break;
        }

    }

    return (
        <div className="ms-Grid intro">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <div className="categoryName">Domain:</div>
                </div>
                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                    {renderDomain()}
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <div className="categoryName"> Curation areas:</div>
                </div>
                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                    {reanderCurationAreas()}
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <div className="categoryName">Tags:</div>
                </div>
                <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                    {reanderTags()}
                </div>
            </div>
        </div>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(AlgorithmHeaderViews);
