import React, { Component } from 'react'
import { Dialog } from "azure-devops-ui/Dialog";
import {ContentSize} from "azure-devops-ui/Callout";
import {
    Link,
    Stack,
    StackItem,
    MessageBar,
    MessageBarType,
    ChoiceGroup,
    IStackProps,
    MessageBarButton,
    Text,
    IChoiceGroupStyles,
} from '@fluentui/react';

interface IErrorDialogProps {
    showDialog: boolean;
    title: string;
    content: string;
    onErrorDialogDismss: () => void
}

interface IErrorInfomation {
    errorMessage: string,
    ErrorType: string
}

export default class ErrorDialog extends Component<IErrorDialogProps> {
    covertErrorMessages = () => {
        if (this.props.content.startsWith("The count of curations are more")) {
            return {
                errorMessage:"Usage limit exceeded. We ask that you create no more than 20 curation configurations.",
                ErrorType: "Warning"
            }
        }
        else if (this.props.content.startsWith("The count of 'shared with' are more than")) {
            return {
                errorMessage:"Usage limit exceeded. We ask that you share a curation configuration with no more than 10 people.",
                ErrorType: "Warning"
            }
        }
        else if (this.props.content.startsWith("The count of 'report to' are more than")) {
            return {
                errorMessage:"Usage limit exceeded. We ask that you report to no more than 10 people.",
                ErrorType: "Warning"
            }
        }
        else if (this.props.content.startsWith("The curation named") && this.props.content.indexOf("is already exists") >=0) {
            return {
                errorMessage:"This curation name already exists or has been previously used. Please choose a different curation name.",
                ErrorType: "Warning"
            }
        }
    
        return  {
            errorMessage: this.props.content,
            ErrorType: "Error"
        }
    }

    render() {
        return (
            <>
                {
                    this.props.showDialog ?
                        <Dialog
                            contentSize={ContentSize.Large}
                            titleProps={{ text: this.props.title }}
                            footerButtonProps={[
                                {
                                    text: "OK",
                                    onClick: this.props.onErrorDialogDismss,
                                    primary: true
                                }
                            ]}
                            onDismiss={this.props.onErrorDialogDismss}
                        >
                            {/* <MessageBar 
                            messageBarType={this.covertErrorMessages().ErrorType ==="Error"? MessageBarType.error: MessageBarType.warning}
                            >
                               
                            </MessageBar> */}

                            {this.covertErrorMessages().errorMessage}
                        </Dialog>
                        : null
                }
            </>
        )
    }
}
