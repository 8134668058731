// import api
import MsacctAPI from "../../components/Utilities/Loaders";
// import action
import { ActionCreator } from "./ActionCreator";

import { IRemediateIt } from "../../components/Interface/IActionFixIt";

// import action types
import {
  SHOW_API_SUCCESS,
  SHOW_API_ERROR,
  CLEAR_API_RESPONSE_STATUS,
  UPDATE_DATA_QUERY_INDEX_LIST,
  UPDATE_DATA_FIELDS,
  SELECT_INDEX,
  POWER_QUERY_SEARCH,
  LOAD_POWER_QUERY_SEARCH,
  ON_SELECT_COLUMNS,
  SELECT_BRANCH,
  ON_COLUMN_OPTIONS_OPEN,
  ON_COLUMN_OPTIONS_CLOSE,
  CLEAR_METADATA_QUERY_SEARCH,
  ON_CONFIGURATION_SETTING_OPEN,
  ON_CONFIGURATION_SETTING_CLOSE,
  ALL_CURATION_NAME_SEARCH,
  ALL_TASK_SEARCH,
  CLEAR_ALL_TASK_SEARCH,
  RECENT_TASK_SEARCH,
  LOAD_TASK_SEARCH,
  TASK_SUBMIT,
  TASK_DELETE,
  INIT_TASK_CREATE,
  TASK_QUERY_BY_NAME,
  CLEAR_TASK_QUERY_BY_NAME,
  TASK_STATUS_SWITCH,
  CURATION_RUN,
  CURATION_RUNS_SEARCH,
  CURATION_RUNS_SEARCH_BY_NAME,
  MY_CURATION_RUNS_SEARCH,
  TASK_RUNS_RESULT_SEARCH_BY_ID,
  CURATION_TAGS_SEARCH,
  FOLLOW_USER,
  UNFOLLOW_USER,
  DOWNLOAD_LOG,
  TICKET_DEVOPS_CONFIG,
  BROKENLINK_SCAN_RESULT,
  CLEAR_BROKENLINK_SCAN_RESULT,
  CHILD_LINKS,
  CLEAR_CHILD_LINKS,
  CLEAR_DATA_REFRESH_SCHEDULE,
  GET_DATA_REFRESH_SCHEDULE,
  GET_DATA_REFRESH_STATUS,
  WELCOME_STATS_SEARCH,
  SITE_INPROCESS_CHECK,
  UPLOAD_DATASOURCE_FILE,
  DATASOURCE_LIST_SEARCH,
  DLETE_DATASOURCE
} from "./ActionTypes";

import { DataQueryIndex, IApiResponseState } from "../../components/Interface/IPowerQuery";

import { TriggerSettings, ITicketEvent,ITextSearchSettings, } from '../../components/Interface/ITask'

import {exportCurationFilterData, exportTaskRunsResults} from '../../components/Utilities/ExoportDataUtils'


import { METADATA_QUERY_DEFAULT_COLUMNS } from '../../components/Utilities/QueryBuilderConstants'

// create an API instance
const api = new MsacctAPI();

export function getAPIService() {
  return api
}

export async function* clearApiResponseStatusAction(): ActionCreator {
  yield {
    type: CLEAR_API_RESPONSE_STATUS
  };
}

export async function* clearApiSuccess(): ActionCreator {
  yield {
    type: SHOW_API_SUCCESS
  };
}

export async function* loadDataQueryIndexes(): ActionCreator {
  const indexList = await api.powerQueryService.getQueryDataIndex();

  yield {
    type: UPDATE_DATA_QUERY_INDEX_LIST,
    indexList,
  };
}

/**
 * Retrieves a collection of metadata field definitions for the given metadata schema.
 * @param metadataSchemaId metadata schema id
 */
export async function* loadMetadataFields(metadataSchemaId: string): ActionCreator {
  const metadataFields = await api.powerQueryService.getDataFields(
    metadataSchemaId
  );

  yield {
    type: UPDATE_DATA_FIELDS,
    metadataSchemaId,
    metadataFields,
  };
}

/**
 * Set selected repo for metadata query and bulk update
 */
export async function* selectIndex(index: DataQueryIndex): ActionCreator {
  yield {
    type: SELECT_INDEX,
    index,
  };
}

/**
 * Set selected repo for metadata query and bulk update
 */
export async function* searchContent(
  searchQuery: string,
  searchIndex: string,
    filterStr: string,
  filterJson:string,
  selectStr: string,
  top?: number,
  skip?: number,
  orderByField?:string
): ActionCreator {

  yield {
    type: LOAD_POWER_QUERY_SEARCH,
    status: 'start'
  }

  const searchResult = await api.powerQueryService.searchContent(
    searchQuery,
    searchIndex,
        filterStr,
    filterJson,
    selectStr,
    top,
    skip,
    orderByField
  );

  yield {
    type: POWER_QUERY_SEARCH,
    searchResult,
  };

  yield {
    type: LOAD_POWER_QUERY_SEARCH,
    status: 'finish'
  }
}

/**
 * @description sets selected columns (fields)
 * @param metadataSchemaId metadata schema id
 */
export async function* initializeSelectedColumns(
  metadataSchemaId: string
): ActionCreator {
  yield {
    type: ON_SELECT_COLUMNS,
    keys: METADATA_QUERY_DEFAULT_COLUMNS[metadataSchemaId]
  }
}

/**
 * Set selected branch for metadata query and bulk update
 */
export async function* selectBranch(branch: string): ActionCreator {
  yield {
    type: CLEAR_METADATA_QUERY_SEARCH
  }
  // yield {
  //   type: CLEAR_SELECTED_METADATA_ITEMS
  // }
  // yield {
  //   type: CLEAR_REPOS_CHANGE_LISTS
  // }
  yield {
    type: SELECT_BRANCH,
    branch
  }
}


/**
 * Clears search results, repo diff change list and resets results index
 */
export async function* clearSearchResults(): ActionCreator {
  yield {
    type: CLEAR_METADATA_QUERY_SEARCH
  }

  // yield {
  //   type: CLEAR_SELECTED_METADATA_ITEMS
  // }

  // yield {
  //   type: CLEAR_REPOS_CHANGE_LISTS
  // }
}

/**
 * Opens ColumnOptions dialog
 */
export async function* onColumnOptionsOpen(): ActionCreator {
  yield {
    type: ON_COLUMN_OPTIONS_OPEN,
  };
}

/**
 * Closes ColumnOptions dialog
 */
export async function* onColumnOptionsClose(): ActionCreator {
  yield {
    type: ON_COLUMN_OPTIONS_CLOSE
  }
}

/**
 * Opens ConfigurationSetting dialog
 */
export async function* onConfigurationSettingOpen(): ActionCreator {
  yield {
    type: ON_CONFIGURATION_SETTING_OPEN,
  };
}

/**
 * Closes ConfigurationSetting dialog
 */
export async function* onConfigurationSettingClose(): ActionCreator {
  yield {
    type: ON_CONFIGURATION_SETTING_CLOSE
  }
}

/**
 * Get all task list
 */
export async function* loadAllTaskList(): ActionCreator {

  const allTaskList = await api.taskService.getAllTaskList();

  yield {
    type: ALL_TASK_SEARCH,
    allTaskList
  };
}

/**
 * Clear all task list
 */
export async function* clearAllTaskList(): ActionCreator {

  //const items = await api.taskService.getAllTaskList();

  yield {
    type: CLEAR_ALL_TASK_SEARCH
  };
}

/**
 * Get recent task list
 */
export async function* loadRecentTaskList(): ActionCreator {
  const recentTaskList = await api.taskService.getRecentTask();

  yield {
    type: LOAD_TASK_SEARCH,
    status: 'start'
  }

  yield {
    type: RECENT_TASK_SEARCH,
    recentTaskList
  };

  yield {
    type: LOAD_TASK_SEARCH,
    status: 'finish'
  }

}

/**
 * Closes ConfigurationSetting dialog
 */
export async function* onTaskSubmit(
  id: string,
  taskName: string,
  SearchQuery: string,
  SearchIndex: string,
  IndexPrefix: string,
  Filter: string,
  SelectString: string,
  Top: number,
  OrderBy: string,
  AlgoType:string,
  RunType: string,
  SharedType: string,
  SharedWith: string,
  Path: string,
  IsReport: boolean,
  ReportTo: string,
  IsTicket: boolean,
  TicketType: string,
  AssignTo: string,
  TicketEvent: ITicketEvent,
  Description: string,
  CurationArea: string,
  ConditionJsonString: string,
  triggerSettings: TriggerSettings,  
  remediateItSetting:IRemediateIt,
  textSearchSettings?: ITextSearchSettings
): ActionCreator {

  try {
    await api.taskService.submitTask(
      id,
      taskName,
      SearchQuery,
      SearchIndex,
      IndexPrefix,
      Filter,
      SelectString,
      Top,
      OrderBy,
      AlgoType,
      RunType,
      SharedType,
      SharedWith,
      Path,
      IsReport,
      ReportTo,
      IsTicket,
      TicketType,
      AssignTo,
      TicketEvent,
      Description,
      CurationArea,
      ConditionJsonString,
      triggerSettings,
      remediateItSetting,
      textSearchSettings
    )

    yield {
      type: TASK_SUBMIT
    }

    yield {
      type: SHOW_API_SUCCESS
    }

  }
  catch (error) {

    const apiResponseState: IApiResponseState = {
      ErrorMessage: error && error.errorMessage,
      StatusCode: error && error.statusCode,
      showErrorDailog: true,
      showSuccessDailog: false
    }

    yield {
      type: SHOW_API_ERROR,
      apiResponseState
    }
  }
}

/**
 * Closes ConfigurationSetting dialog
 */
export async function* intTaskCreate(): ActionCreator {
  yield {
    type: INIT_TASK_CREATE
  }
}

/**
 * 
 * @param taskName 
 */
export async function* onCurationDelete(id: string): ActionCreator {
  await api.taskService.deleteCuration(id)

  yield {
    type: TASK_DELETE
  }
}

/**
 * 
 * @param taskName 
 */
export async function* runCuration(
  id: string,
  runType?: string,
  isForcedTicket?:boolean
): ActionCreator {

  await api.taskService.runCuration(id, runType,isForcedTicket)

  yield {
    type: CURATION_RUN
  }
}

/**
 * Closes ConfigurationSetting dialog
 */
export async function* getAllCurationName(): ActionCreator {

  const allCurationName = await api.taskService.getAllCurationName();

  yield {
    type: ALL_CURATION_NAME_SEARCH,
    allCurationName
  }
}

/**
 * Closes ConfigurationSetting dialog
 */
export async function* getCurationById(id: string): ActionCreator {
  yield {
    type: CLEAR_TASK_QUERY_BY_NAME
  }

  try {
    const taskEditItem = await api.taskService.getCurationById(id);

    yield {
      type: TASK_QUERY_BY_NAME,
      taskEditItem
    }

    // yield {
    //   type: SHOW_API_SUCCESS
    // }
  }
  catch (error) {

    const apiResponseState: IApiResponseState = {
      ErrorMessage: error && error.errorMessage,
      StatusCode: error && error.statusCode,
      showErrorDailog: true,
      showSuccessDailog: false
    }

    yield {
      type: SHOW_API_ERROR,
      apiResponseState
    }
  }
}

/**
 * Closes ConfigurationSetting dialog
 */
export async function* followUser(id: string): ActionCreator {
  await api.taskService.followUser(id);
  yield {
    type: FOLLOW_USER
  }
}


/**
 * Closes ConfigurationSetting dialog
 */
export async function* unFollowUser(id: string): ActionCreator {
  await api.taskService.unFollowUser(id);
  yield {
    type: UNFOLLOW_USER
  }
}

/**
 * Closes ConfigurationSetting dialog
 */
export async function* switchCurationStatus(taskStatus: string, id: string): ActionCreator {
  await api.taskService.switchCurationStatus(taskStatus, id);

  yield {
    type: TASK_STATUS_SWITCH
  }
}


export async function* getCurationRuns(): ActionCreator {
  const curationRuns = await api.taskService.getCurationRuns();

  yield {
    type: CURATION_RUNS_SEARCH,
    curationRuns
  }
}

export async function* getCurationRunsById(curationId: string): ActionCreator {
  const curationRunsWithName = await api.taskService.getCurationRunsById(curationId);

  yield {
    type: CURATION_RUNS_SEARCH_BY_NAME,
    curationRunsWithName
  }
}

export async function* getMyCurationRuns(): ActionCreator {
  const myCurationRuns = await api.taskService.getMyCurationRuns();

  yield {
    type: MY_CURATION_RUNS_SEARCH,
    myCurationRuns
  }
}

export async function* searchTaskRunsResultById(buildId: string): ActionCreator {
  const taskRunsResultItem = await api.taskService.searchTaskRunsResultById(buildId);

  yield {
    type: TASK_RUNS_RESULT_SEARCH_BY_ID,
    taskRunsResultItem
  }
}

/**
 * 
 * @param csvPath 
 */
export async function* onExportTaskRunsResult(csvPath: string): ActionCreator {
  const taskRunsResult = await api.taskService.exportTaskRunsResult(csvPath);

  exportTaskRunsResults(taskRunsResult, "CurationRunResult.csv");
}

/**
 * 
 * @param searchQuery 
 * @param searchIndex 
 * @param filterStr 
 * @param selectStr 
 * @param fileName 
 */
export async function* exportSearchResult(
  searchQuery: string,
  searchIndex: string,
  indexPrefix: string,
  filterStr: string,
  selectStr: string,
  fileName: string
): ActionCreator {
  const searchResults = await api.powerQueryService.downloadSearchResult(searchQuery, searchIndex, indexPrefix, filterStr, selectStr);

  exportCurationFilterData(searchResults, fileName);
}

/**
 * 
 * @param searchQuery 
 * @param searchIndex 
 * @param filterStr 
 * @param selectStr 
 * @param fileName 
 */
export async function* onExportAlgoReport(
  searchQuery: string,
  searchIndex: string,
  filterStr: string,
  selectStr: string,
  fileName: string
): ActionCreator {
  const taskRunsResult = await api.taskService.onExportAlgoReport(searchQuery, searchIndex, filterStr, selectStr);

  exportTaskRunsResults(taskRunsResult, fileName);
}

/**
 * 
 * @param searchQuery 
 * @param searchIndex 
 * @param filterStr 
 * @param selectStr 
 * @param fileName 
 */
export async function* ticketDownload(
  buildId: string
): ActionCreator {
  const ticketResult = await api.taskService.ticketDownload(buildId);

  exportTaskRunsResults(ticketResult, "ticket.csv");
}


export async function* getCurationTags(): ActionCreator {
  const curationTags = await api.taskService.getCurationTags();

  yield {
    type: CURATION_TAGS_SEARCH,
    curationTags
  }
}

/**
 * get devops configrations
 */
export async function* getTicketDevOpsConfig(): ActionCreator {
  const ticketDevOpsConfig = await api.taskService.getTicketDevOpsConfig();

  yield {
    type: TICKET_DEVOPS_CONFIG,
    ticketDevOpsConfig
  }
}



export async function* getBrokenLinkScanResult(requestUrl: string, enforce: boolean): ActionCreator {
  const brokenLinkScanResult = await api.taskService.getBrokenLinkScanResult(requestUrl, enforce);

  yield {
    type: BROKENLINK_SCAN_RESULT,
    brokenLinkScanResult
  }
}

export async function* clearBrokenLinkScanResult(): ActionCreator {

  yield {
    type: CLEAR_BROKENLINK_SCAN_RESULT
  }
}

export async function* getChildLinks(requestUrl: string): ActionCreator {
  const childLinks = await api.taskService.getChildLinks(requestUrl);

  yield {
    type: CHILD_LINKS,
    childLinks
  }
}


export async function* clearChildLinks(): ActionCreator {
  yield {
    type: CLEAR_CHILD_LINKS
  }
}

export async function* clearDataRefreshSchedule(): ActionCreator {
  yield {
    type: CLEAR_DATA_REFRESH_SCHEDULE
  }
}

export async function* getDataRefreshSchedule(factoryType: string, factoryName:string): ActionCreator {
  yield {
    type: CLEAR_DATA_REFRESH_SCHEDULE
  }
  const dataRefreshSchedule = await api.taskService.getDataRefreshSchedule(factoryType, factoryName);

  yield {
    type: GET_DATA_REFRESH_SCHEDULE,
    dataRefreshSchedule
  }
}

export async function* getContentDataFactory(factoryType: string, factoryName:string): ActionCreator {
  
  const contentDataFactory = await api.taskService.getContentDataFactory(factoryType, factoryName);

  yield {
    type: GET_DATA_REFRESH_STATUS,
    contentDataFactory
  }
}



/**
 * Closes ConfigurationSetting dialog
 */
export async function* createDownloadLogs(
  CurationId: string,
  PageType: string,
  DownloadUrl: string
): ActionCreator {
  await api.taskService.createDownloadLogs(CurationId, PageType, DownloadUrl)
  yield {
    type: DOWNLOAD_LOG
  }
}

export async function* getWelcomeStats(): ActionCreator {
  const welcomeStats = await api.welcomeService.getStats();

  yield {
    type: WELCOME_STATS_SEARCH,
    welcomeStats
  }
}

export async function* getSiteStatus(): ActionCreator {
  const siteStatus = await api.welcomeService.getSiteStatus();

  yield {
    type: SITE_INPROCESS_CHECK,
    siteStatus
  }
}

/**
 * Upload data source file
 */
export async function* uploadDataSourceFile(formData: any): ActionCreator {

  await api.dataSourceService.uploadDataSourceFile(formData);

  yield {
    type: UPLOAD_DATASOURCE_FILE,
  }
}

/**
 * Get data source list
 */
export async function* getDataSourceList(type:string = ''): ActionCreator {

  const dataSourceList = await api.dataSourceService.getDataSourceList(type);

  yield {
    type: DATASOURCE_LIST_SEARCH,
    dataSourceList
  }
}

/**
 * Delete a data source
 * @param taskName 
 */
export async function* deleteDataSource(name: string): ActionCreator {
  await api.dataSourceService.deleteDataSource(name)

  yield {
    type: DLETE_DATASOURCE
  }
}