import { Status, Statuses, StatusSize } from "azure-devops-ui/Status";
import "./CustomStatus.css";
import { Button } from "azure-devops-ui/Button";

/**
 * size: m/xl/...
 * type: Success/Failed/Warning/Information/Running/Waiting/QueuedCanceled/Skipped/PartialSuccess
 */
interface ICustomStatus {
    size?: string;
    type: string;
    className?: string;
    toolTipMessage?: string;
}
export default function CustomStatus(props: ICustomStatus) {
    const renderStatus = () => {
        if (props.type === "PartialSuccess") {
            return (
                <>
                    <Button
                        tooltipProps={{ text: props.toolTipMessage }}
                        style={{ background: "rgba(255,0,0,0)" }}
                    >
                        <svg
                            className={`custom_status_main_svg ${props.className}`}
                            height="16"
                            role="presentation"
                            viewBox="0 0 16 16"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="8" cy="8" r="8"></circle>
                            <path
                                d="M6.062 11.144l-.003-.002-1.784-1.785A.937.937 0 1 1 5.6 8.031l1.125 1.124 3.88-3.88A.937.937 0 1 1 11.931 6.6l-4.54 4.54-.004.004a.938.938 0 0 1-1.325 0z"
                                fill="#fff"
                            ></path>
                        </svg>
                    </Button>
                </>
            );
        }
        return (
            <Button
                tooltipProps={{ text: props.toolTipMessage }}
                style={{ background: "rgba(255,0,0,0)" }}
            >
                <Status
                    {...Statuses[props.type]}
                    key={props.type}
                    size={props.size ? StatusSize[props.size] : StatusSize.m}
                    className={props.className}
                />
            </Button>
        );
    };

    return renderStatus();
}
