import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';

/**
 * interface for bread crumb
 */
export interface IBreadCrumbExtension {
    text: string
    key?: string
    href?: string
    isCurrentItem?: boolean
}

/**
 * props for bread crumb
 */
interface IBreadCrumbExtensionProps {
    items: IBreadCrumbExtension[]
}

/**
 * Refactor BreadCrumb so that it uses SAP jumps
 * @param props 
 * @returns 
 */
export default function BreadCrumbExtension(props: IBreadCrumbExtensionProps) {

    const history = useNavigate();

    /**
     * jump to target page
     * @param pagePath 
     * @returns 
     */
    function navigateToTargetPage(pagePath: string) {
        return (event?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
            history(pagePath)
            event.preventDefault()
        }
    }

    /**
     * build breadcrumb items
     * @returns 
     */
    function buildBreadItems() {
        let breadcrumbItems: IBreadcrumbItem[] = []

        props.items.map((item, index) => {
            let itemKey = item.key ? item.key : "key" + index

            if (item.href && item.href !== '') {
                breadcrumbItems.push({
                    text: item.text,
                    key: itemKey,
                    isCurrentItem: item.isCurrentItem,
                    href: item.href,
                    onClick: navigateToTargetPage(item.href)
                })
            }
            else {
                breadcrumbItems.push({
                    text: item.text,
                    key: itemKey,
                    isCurrentItem: item.isCurrentItem
                })
            }
        })

        return breadcrumbItems
    }

    return (
        <Breadcrumb
            items={buildBreadItems()}
            maxDisplayedItems={3}
            ariaLabel="Bread crumb"
            overflowAriaLabel="More links"
        />
    )
}
