import { IHeaderCommandBarItem } from "azure-devops-ui/HeaderCommandBar";
import { MenuItemType } from "azure-devops-ui/Menu";

export const commandBarItemsAdvanced: IHeaderCommandBarItem[] = [
    {
        iconProps: {
            iconName: "Download"
        },
        id: "testSave",
        important: true,
        onActivate: () => {
            alert("Example text");
        },
        text: "Download"
    },
    {
        iconProps: {
            iconName: "Share"
        },
        id: "testShare",
        onActivate: () => {
            alert("Example text");
        },
        text: "Share"
    },
    {
        iconProps: {
            iconName: "Retain"
        },
        id: "testCreate",
        important: true,
        isPrimary: true,
        onActivate: () => {
            alert("This would normally trigger a modal popup");
        },
        text: "Retain"
    },
    {
        iconProps: {
            iconName: "FavoriteStar"
        },
        id: "testCreate",
        important: false,
        onActivate: () => {
            alert("This would normally trigger a modal popup");
        },
        text: "Add to favorites"
    },
    {
        iconProps: {
            iconName: "CheckMark"
        },
        id: "testCreate",
        important: false,
        onActivate: () => {
            alert("This would normally trigger a modal popup");
        },
        text: "View retention leases"
    },
    { id: "separator", itemType: MenuItemType.Divider },
    {
        iconProps: {
            iconName: "Delete"
        },
        id: "testDelete",
        onActivate: () => {
            alert("Example text");
        },
        text: "Delete",
    }
];
