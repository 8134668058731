import React, { Component } from 'react'
import AppLayout from "../AppLayout/AppLayout";
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'

import ScanRequests from '../../ScanRequests/ScanRequests'

const breadcrumbItem: IBreadCrumbExtension[] = [
  { text: 'Home', key: 'Home', href: '/' },
  { text: 'Data Freshness', key: 'ia'},
  { text: 'Content Crawler', key: 'ia1'},
  { text: 'LMC', key: 'lmc'},
];

export default class ScanRequestsLMCPage extends Component {
  

  render() {
    document.title = MSACCT_TITLE + " | " + "Content Crawler (LMC)";
    return (
      <AppLayout
        renderBreadcrumb={() =>
          <BreadCrumbExtension items={breadcrumbItem} />
        }
        renderContentPane={() => <ScanRequests FactoryType='ContentCrawler' FactoryName='LMC' />} />
    );
  }
}

