import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { returnType } from "../../Utilities/ReturnType";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Checkbox } from "azure-devops-ui/Checkbox";
import { CustomHeader, HeaderTitleArea } from "azure-devops-ui/Header";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { CustomDialog } from "azure-devops-ui/Dialog";
import { PanelContent, PanelFooter } from "azure-devops-ui/Panel";
import { FormItem } from "azure-devops-ui/FormItem";
import {
    RadioButton,
    RadioButtonGroup,
    RadioButtonGroupDirection,
} from "azure-devops-ui/RadioButton";

import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { runCuration } from '../../../redux/Actions/PowerDataQuery'

import "./RunTypeDialog.css";

const actionCreators = {
    runCuration
};
type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    searchResult: state.searchResult,
    curationRunsWithName: state.curationRunsWithName,
    curation: state.taskEditItem,
});
const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

interface IRunTypeDialogParameterProps {
    actionCurationId: string;
    actionCurationName: string;
    curationRunType: string;
    forcedTicket?: string;
    onRunTypeSubmit: (data: any) => void
    onRunTypeDialogDimiss: () => void
}

interface IRunTypeDialogState {
    actionCurationId: string;
    actionCurationName: string;
    submitState: string,
    selectedRunType: string
}

// Run type RadioButton
let runTypeRadioButton = new ObservableValue<string>("Incremental");

// Force ticket checkbox
let isForcedTicketObservable: ObservableValue<boolean> = new ObservableValue(false);

function RunTypeDialog(props: StoreProps & DispatchProps & IRunTypeDialogParameterProps) {

    const [runTypeSubmitState, setRunTypeSubmitState] = useState<IRunTypeDialogState>({
        actionCurationId: "",
        actionCurationName: "",
        submitState: "",
        selectedRunType: "Incremental"
    })
    /**
    * like componentDidMount
    */
    useEffect(() => {
        setRunTypeSubmitState({
            ...runTypeSubmitState,
            submitState: "",
            selectedRunType: "Incremental"
        })

        runTypeRadioButton.value = "Incremental";
        isForcedTicketObservable.value = false;

        props.onRunTypeSubmit({
            submitState: "",
            selectedRunType: runTypeRadioButton.value
        })

    }, [])

    function handleRunTypeSubmit() {
        let isForcedTicket = isForcedTicketObservable.value;
        if (runTypeRadioButton.value === "Incremental") {
            isForcedTicket = false;
        }
        props.runCuration(props.actionCurationId, runTypeRadioButton.value, isForcedTicket)

        props.onRunTypeSubmit({
            actionCurationId: props.actionCurationId,
            actionCurationName: props.actionCurationName,
            submitState: "Sucess",
            forcedTicket: isForcedTicket,
            selectedRunType: runTypeRadioButton.value
        })

        props.onRunTypeDialogDimiss()
    }

    /**
     * Set the run type RadioButtons value
     * @param buttonId radio button id
     */
    function onRunTypeSelect(buttonId: string) {
        runTypeRadioButton.value = buttonId;
        setRunTypeSubmitState({
            ...runTypeSubmitState,
            submitState: "",
            selectedRunType: runTypeRadioButton.value
        })
    }

    function onForcedTicketValueChange(
        _event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        newValue: boolean
    ) {
        isForcedTicketObservable.value = newValue;
    }

    return (
        <CustomDialog calloutContentClassName='msacct-runtype-form-container' onDismiss={props.onRunTypeDialogDimiss} modal={true} >
            <CustomHeader className="bolt-header-with-commandbar" separator>
                <HeaderTitleArea>

                </HeaderTitleArea>
            </CustomHeader>
            <PanelContent>
                <div>
                    <div className="ms-Grid-row" style={{ height: "auto" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <FormItem
                                label="Run type:"
                                message={
                                    "Full runs - Scan the complete data set. Incremental runs - Scan the changes since the previous run."
                                }
                            >
                                <RadioButtonGroup
                                    onSelect={onRunTypeSelect}
                                    selectedButtonId={runTypeRadioButton}
                                    direction={null}
                                >
                                    <RadioButton id="Full" text="Full" key="RunTypeFull" />
                                    <RadioButton
                                        id="Incremental"
                                        text="Incremental"
                                        key="RunTypeIncremental"
                                    />

                                </RadioButtonGroup>
                            </FormItem>
                        </div>
                        {
                            runTypeSubmitState.selectedRunType === "Full" &&
                            <>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <hr />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <FormItem
                                        label=""
                                        message={
                                            `Note that if you check this option, it will automatically generate tickets for each identified article or issue even the ticket for the article or issue was created.`
                                        }
                                    >
                                        <Checkbox
                                            onChange={onForcedTicketValueChange}
                                            checked={isForcedTicketObservable}
                                            label="Forcefully generate the tickets"
                                        />
                                    </FormItem>
                                </div>
                            </>
                        }
                    </div>

                </div>


            </PanelContent>
            <PanelFooter showSeparator className="body-m">
                <PrimaryButton
                    style={{ "float": "right", "marginLeft": "15px" }}
                    text="Close"
                    onClick={() => props.onRunTypeDialogDimiss()}
                />

                <DefaultButton
                    style={{ "float": "right" }}
                    text="Submit"
                    onClick={() => handleRunTypeSubmit()}
                />
            </PanelFooter>
        </CustomDialog>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(RunTypeDialog);

