import * as React from "react";
import { Dropdown,DropdownCallout  } from "azure-devops-ui/Dropdown";
import { IListBoxItem, ListBoxItemType,LoadingCell } from "azure-devops-ui/ListBox";
import { Observer } from "azure-devops-ui/Observer";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { DropdownSelection, DropdownMultiSelection } from "azure-devops-ui/Utilities/DropdownSelection";
import {
    IDropdownPivot,
    IWithPivotsChildProps,
    WithPivots
} from "azure-devops-ui/Dropdown";
import { AggregateItemProvider } from "azure-devops-ui/Utilities/AggregateItemProvider";
import { ITableColumn } from "azure-devops-ui/Table";
import { Location } from "azure-devops-ui/Utilities/Position";
import { GroupedItemProvider } from "azure-devops-ui/Utilities/GroupedItemProvider";
import { popularDropdownItems, metadataDropdownItems, contributorsDropdownItems, KPIDropdownItems, lifecycleDropdownItems } from "../../../SampleData/DropdownItems";
import './DropdownControls.css'

interface IDropdownControlProps {
    placeholder?: string;
    selection?: DropdownSelection;
    disabled?: boolean;
    defaultValue?: string;
    className?: string;
    onSelectDropdown?: (selectText: string) => void;
    dropdownItems: Array<IListBoxItem<{}>>;
}

/**
 * build a single dropdown list
 */
export class SingleDropdown extends React.Component<IDropdownControlProps> {
    private selection = new DropdownSelection();

    constructor(props) {
        super(props)
        
        // set the dropdown list's default value
        if (this.props.dropdownItems && this.props.dropdownItems.length > 0 && this.props.defaultValue) {
            let dropdownSelectIndex = this.props.dropdownItems.findIndex(item => item.id === this.props.defaultValue)
            this.selection.select(dropdownSelectIndex < 0 ? 0 : dropdownSelectIndex)

            this.props.onSelectDropdown(this.props.defaultValue)
        }
    }

    /**
     * handle the dropdown select event
     * @param event 
     * @param item 
     */
    onDropdownSelected = (event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<{}>) => {
        this.props.onSelectDropdown(item.id)
    }

    // render dropdown list
    public render() {
        return (
            <Dropdown
                ariaLabel="SingleSelect"
                actions={[
                    {
                        className: "bolt-dropdown-action-right-button",
                        disabled: this.selection.selectedCount === 0,
                        iconProps: { iconName: "Clear" },
                        text: "Clear",
                        onClick: () => {
                            this.selection.clear();
                            this.props.onSelectDropdown('')
                        }
                    }
                ]}
                items={this.props.dropdownItems}
                selection={this.selection}
                disabled={this.props.disabled ? this.props.disabled : false}
                placeholder={this.props.placeholder}
                showFilterBox={true}
                onSelect={this.onDropdownSelected}
                className="msacct-single-dropdown-float-left"
            />
        );
    }
}

export default class BasicDropdownControl extends React.Component {
    private selectedItem = new ObservableValue<string>("");

    public render() {
        return (
            <div className="flex-row" style={{ margin: "8px", alignItems: "center" }}>
                <Dropdown
                    ariaLabel="Basic"
                    className="example-dropdown"
                    placeholder="Select a Policy"
                    items={[
                        { id: "item1", text: "Archive after 10 years" },
                        { id: "item2", text: "Archive after 5 years" },
                        { id: "item3", text: "Archive after 2 years" }
                    ]}
                    onSelect={this.onSelect}
                />
                <Observer selectedItem={this.selectedItem}>
                    {(props: { selectedItem: string }) => {
                        return (
                            <span style={{ marginLeft: "8px", width: "150px" }}>
                                Selected Item: {props.selectedItem}{" "}
                            </span>
                        );
                    }}
                </Observer>
            </div>
        );
    }

    private onSelect = (event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<{}>) => {
        this.selectedItem.value = item.text || "";
    };
}



export class DropdownSingleSelectControl extends React.Component<IDropdownControlProps> {
    private selection = new DropdownSelection();
    defaultClassName = "msacct-single-dropdown"

    constructor(props) {
        super(props)

        // customize the class name
        if (this.props.className) {
            this.defaultClassName = "msacct-single-dropdown " + this.props.className
        }

        // set the dropdown list's default value
        if (this.props.dropdownItems && this.props.dropdownItems.length > 0 && this.props.defaultValue) {
            let dropdownSelectIndex = 0;
            for (let i = 0; i < this.props.dropdownItems.length; i++) {
                if (this.props.defaultValue.toLowerCase() === this.props.dropdownItems[i].text?.toLowerCase()) {
                    break;
                }

                dropdownSelectIndex++
            }

            this.selection.select(dropdownSelectIndex)

            this.props.onSelectDropdown(this.props.defaultValue)
        }
    }



    onDropdownSelect = (event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem<{}>) => {

        console.log("dropdown select", item)
        this.props.onSelectDropdown(item.text)
    }

    public render() {

        return (
            <div className={this.defaultClassName}>
                <Observer selection={this.selection}>
                    {() => {
                        return (
                            <Dropdown
                                ariaLabel="SingleSelect"
                                actions={[
                                    {
                                        className: "bolt-dropdown-action-right-button",
                                        disabled: this.selection.selectedCount === 0,
                                        iconProps: { iconName: "Clear" },
                                        text: "Clear",
                                        onClick: () => {
                                            this.selection.clear();
                                            this.props.onSelectDropdown('')
                                        }
                                    }
                                ]}
                                items={this.props.dropdownItems}
                                selection={this.selection}
                                disabled={this.props.disabled ? this.props.disabled : false}
                                placeholder={this.props.placeholder}
                                showFilterBox={true}
                                onSelect={this.onDropdownSelect}
                            />
                        );
                    }}
                </Observer>
            </div>
        );
    }
}

export class DropdownDefaultSelection extends React.Component<IDropdownControlProps> {
    private selection = new DropdownSelection();
    public constructor(props) {
        super(props);
        // Select the first item by default.
        this.selection.select(1);
    }
    public render() {
        return (
            <Dropdown
                ariaLabel="Default selection"
                className="example-dropdown"
                disabled={true}
                placeholder={this.props.placeholder}
                items={this.props.dropdownItems}
                selection={this.selection}
            />
        );
    }
}

export class DropdownAdvanced extends React.Component {
    private dropdown = React.createRef<Dropdown>();
    private groups = [{ id: "first" }, { id: "second" }];
    private selection = new DropdownSelection();
    private selectedPivot = new ObservableValue<string>("popular");
    private pivots = [
        {
            actions: [
                {
                    className: "flex-self-end",
                    iconProps: { iconName: "StatusCircleQuestionMark" },
                    text: "Field help"
                }
            ],
            filteredNoResultsText: "Nothing Found In First Pivot",
            id: "popular",
            items: popularDropdownItems,
            name: "Popular"
        },
        {
            actions: [
                {
                    className: "flex-self-end",
                    iconProps: { iconName: "FabricMDL2Icons" },
                    text: "Field help"
                }
            ],
            filteredNoResultsText: "Nothing Found In First Pivot",
            id: "one",
            items: metadataDropdownItems,
            name: "Metadata"
        },
        {
            id: "two",
            items: contributorsDropdownItems,
            name: "Contributors"
        },
        { id: "three", name: "KPI", items: KPIDropdownItems },
        { id: "Four", name: "Lifecycle", noItemsText: "No items in pivot area", items: lifecycleDropdownItems }
    ];
    private provider = new AggregateItemProvider<IListBoxItem>();

    public constructor(props: {}) {
        super(props);
        this.pivots.forEach(pivot => {
            this.provider.push(pivot.items);
        });
        // Select the first item by default.
        this.selection.select(1);
    }

    public render() {
        return (
            <div style={{ margin: "0px" }}>
                <WithPivots
                    calloutProps={{ title: "Select a field" }}
                    onPivotClicked={this.onPivotClicked}
                    selectedPivot={this.selectedPivot}
                    pivots={this.pivots}
                >
                    {(props: IWithPivotsChildProps) => (
                        <Dropdown
                            ariaLabel="Advanced"
                            className="example-dropdown"
                            placeholder="Select an Option"
                            items={this.provider}
                            selection={this.selection}
                            ref={this.dropdown}
                            {...props}
                            filterPlaceholderText={
                                props.filterPlaceholderText || "Search Current Pivot"
                            }
                        />
                    )}
                </WithPivots>
            </div>
        );
    }

    private onPivotClicked = (pivot: IDropdownPivot) => {
        this.selectedPivot.value = pivot.id;
    };
}

export class LoadingDropdown extends React.Component {
    private loading = new ObservableValue<boolean>(false);
    private selection = new DropdownSelection();
    private loadingItem: IListBoxItem = {
        id: "loading",
        type: ListBoxItemType.Loading,
        render: (
            rowIndex: number,
            columnIndex: number,
            tableColumn: ITableColumn<IListBoxItem<{}>>,
            tableItem: IListBoxItem<{}>
        ) => {
            return (
                <LoadingCell
                    key={rowIndex}
                    columnIndex={columnIndex}
                    tableColumn={tableColumn}
                    tableItem={tableItem}
                    onMount={this.onLoadingMount}
                />
            );
        }
    };
    private itemProvider = new GroupedItemProvider([this.loadingItem], [], true);

    public render() {
        return (
            <div style={{ margin: "8px" }}>
                <Dropdown
                    ariaLabel="Loading"
                    items={this.itemProvider}
                    loading={this.loading}
                    placeholder="Select an Option"
                    selection={this.selection}
                    className="example-dropdown"
                    width={250}
                    renderCallout={props => (
                        <DropdownCallout
                            {...props}
                            dropdownOrigin={{
                                horizontal: Location.start,
                                vertical: Location.start
                            }}
                            anchorOrigin={{
                                horizontal: Location.start,
                                vertical: Location.end
                            }}
                        />
                    )}
                />
            </div>
        );
    }

    private onLoadingMount = () => {
        if (!this.loading.value) {
            // Set loading to true once we start fetching items, this will announce
            // that loading has begun to screen readers.
            this.loading.value = true;
            window.setTimeout(() => {
                //remove the loading item
                this.itemProvider.pop();

                // Add groups and items
                // for (let i = 1; i <= 5; i++) {
                //     const groupId = i.toString();
                //     this.itemProvider.pushGroups({
                //         id: groupId,
                //         name: "Group " + i
                //     });
                //     for (let j = 0; j < 10; j++) {
                //         this.itemProvider.push({
                //             id: i + "-" + j,
                //             text: "item " + ((i - 1) * 10 + j),
                //             groupId: i.toString()
                //         });
                //     }
                // }

                for (let j = 0; j < 10; j++) {
                    this.itemProvider.push({
                        id: j.toString(),
                        text: "item " + j
                    });
                }

                // Set loading to false to announce how many items have loaded to screen readers.
                this.loading.value = false;

                // Select the first item.
                this.selection.select(1);
            }, 1000);
        }
    };
}