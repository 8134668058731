import AppLayout from "../AppLayout/AppLayout"
import { MSACCT_TITLE } from '../../Utilities/QueryBuilderConstants'
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'
import PIIChecker from "../../PIIChecker/PIIChecker"

const breadcrumbItem: IBreadCrumbExtension[] = [
    { text: 'Home', key: 'Home', href: '/' },
    { text: 'Mini APPs', key: 'MiniApps' },
    { text: 'PII Checker', key: 'f5' },
]

export default function PIICheckerPage() {

    document.title = MSACCT_TITLE + " | " + "PII Checker";

    return (
        <AppLayout
        renderBreadcrumb={() =>
          <BreadCrumbExtension items={breadcrumbItem} />
        }
        renderContentPane={() => <PIIChecker/>} />
    )
}