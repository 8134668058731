import { Link } from '@fluentui/react';

export interface IRemediateIt {
    RemediateItOrNot: boolean;
    RemediateType: RemediateTypes;
    FixHyperLinksOrNot: boolean;
    FixHyperLinksType: FixHyperLinksTypes;
    FixHyperLinksReplaceList?: ILinkReplacerMap[];
    MergePullRequestType: MergePullRequestTypes;
    GeneratedInitialOrNot: boolean;
    GeneratedOutOfDateRequestOrNot: boolean;
    TextFindAndReplace?: ITextFindAndReplace;
    MSContentBaseline?: IMSContentBaseline;
  }

interface ITextFindAndReplace {
  ReplaceOrNot: boolean;
  ReplacementList: IReplacementList[];
}

interface IMSContentBaseline {
  EditOrNot: boolean;
  ControlID: string;
  StandardRequirementURL: string;
  InternalSupportArticle: string;
  RemediationGuidance: string;
}

export interface IReplacementList {
  FindText: string;
  ReplaceWith: string;
}

export interface IFixItLinkProps {
    /**
     * URL the link points to. If not provided, the link renders as a button (unless that behavior is
     * overridden using `as`).
     */
    href?: string;
    value?: string | string[] | number;
}

export interface ILinkReplacerMap {
  CurrentLink: IFixItLinkProps;
  NewLink: IFixItLinkProps;
}

export enum FixHyperLinksTypes {
  CommentOut = 'CommentOut',
  Remove = 'Remove',
  Replace = 'Replace',
}

export enum RemediateTypes {
  Update = 'Update',
  UnIndex = 'UnIndex',
  Delete = 'Delete',
}

export enum MergePullRequestTypes {
  Automatic = 'Automatic',
  Manual = 'Manual',
}