import React, { ChangeEvent, useEffect, useState } from 'react'
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { TextField } from '@fluentui/react/lib/TextField';
import { Icon } from '@fluentui/react/lib/Icon';
import { returnType } from "../../Utilities/ReturnType";
import { FormItem } from "azure-devops-ui/FormItem";
import { GlobalDataQuery, IDataSource } from "../../Interface/IPowerQuery";
import { uploadDataSourceFile } from "../../../redux/Actions/PowerDataQuery";

import './FileUploadDialog.css'

const actionCreators = {
    uploadDataSourceFile
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    recentTaskList: state.recentTaskList
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

/**
 * Dailog description
 */
const dialogContentProps = {
    type: DialogType.normal,
    title: 'Import a CSV file',
    with: 600,
    subText: 'Do you want to create a new data source?',
};

/**
 * Dailog stype
 */
const modelProps = {
    isBlocking: false,
    className: "soni-fileupload-dialog",
    styles: { main: { maxWidth: 800, minWidth: 640 } },
};

/**
 * Compnent props interface
 */
interface IFileUploadProps {
    isHidden: boolean;
    dataSourceItems: IDataSource[];
    dataSourceName?: string;
    dataSourceType :string;
    OnSubmit: (submitStatus: string) => void;
    OnCancel: () => void;
}

/**
 * File upload dialog
 * @param props 
 * @returns 
 */
function FileUploadDialog(props: StoreProps & DispatchProps & IFileUploadProps) {

    /** Initialize file state */
    const [uploadFile, setUploadFile] = useState<File>(null)

    /** Initialize source name state */
    const [dataSourceName, setDataSourceName] = useState<string>('')

    /** Initialize source name error state */
    const [dataSourceNameErrorState, setDataSourceNameErrorState] = useState({
        showDataSourceError: false,
        dataSourceErrorMessage: ""
    })

    /** Initialize file upload error state */
    const [fileUploadErrorState, setFileUploadErrorState] = useState({
        showFileUploadError: false,
        fileUploadErrorMessage: ""
    })

    useEffect(() => {
        // Set the default error message of curation name
        setDataSourceNameErrorState({
            showDataSourceError: false,
            dataSourceErrorMessage: "",
        });

        // Set the default error message of curation name
        setFileUploadErrorState({
            showFileUploadError: false,
            fileUploadErrorMessage: "",
        });

        setUploadFile(null)
    }, [])

    /**
     * Handle submit events
     * @param event 
     */
    function handleSubmit(event) {
        console.log(event.target.files)
        if (event.target.files) {

            setUploadFile(event.target.files[0]);
            event.target.value = ''
        }
    }

    /**
     * Delete selected file
     */
    function removeFile() {
        setUploadFile(null);
    }

    /**
     * Upload a data source file
     * @returns 
     */
    async function uploadDataSourceFile() {
        // Verify that data source name is empty
        if (dataSourceName.trim() === '') {
            setDataSourceNameErrorState({
                showDataSourceError: true,
                dataSourceErrorMessage: "The data source name can not be empty.",
            });

            return
        }

        // Verify that data source name already exsits
        let exsitDataSourceName = props.dataSourceItems.filter(t => t.Name.toLowerCase().trim() === dataSourceName.toLowerCase().trim())
        console.log(props.dataSourceItems, exsitDataSourceName)
        if (exsitDataSourceName && exsitDataSourceName.length > 0) {
            setDataSourceNameErrorState({
                showDataSourceError: true,
                dataSourceErrorMessage: `The data source name already exists.`
            });

            return;
        }

        // Verify that the file has been selected
        if (uploadFile === null || uploadFile === undefined) {
            setFileUploadErrorState({
                showFileUploadError: true,
                fileUploadErrorMessage: `Please select a file to upload.`
            });
            return;
        }

        // Verify that the file is in the correct format
        var reg = /\w+\.(csv)$/g
        if (!reg.exec(uploadFile.name.toLowerCase().trim())) {
            setFileUploadErrorState({
                showFileUploadError: true,
                fileUploadErrorMessage: `Please select a csv file to upload.`
            });
            return;
        }

        const formData = new FormData();
        formData.append("UploadFile", uploadFile);
        formData.append("Type", props.dataSourceType);
        formData.append("DataSourceName", dataSourceName);
        formData.append("ConnectionEndPoint", uploadFile.name)
        formData.append("Locale", "en-us")       

        await props.uploadDataSourceFile(formData)

        props.OnSubmit("Success")

        // Set the default error message of curation name
        setDataSourceNameErrorState({
            showDataSourceError: false,
            dataSourceErrorMessage: "",
        });

        // Set the default error message of curation name
        setFileUploadErrorState({
            showFileUploadError: false,
            fileUploadErrorMessage: "",
        });

        // Clear upload file
        setUploadFile(null)
    }

    /**
     * Save data source name
     * @param event 
     * @param newValue 
     */
    function handleDataSourceNameChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) {
        setDataSourceName(newValue);
    }

    return (
        <Dialog
            hidden={props.isHidden}
            onDismiss={props.OnCancel}
            dialogContentProps={dialogContentProps}
            modalProps={modelProps}
        >
            <div>
                <FormItem
                    message={dataSourceNameErrorState.dataSourceErrorMessage}
                    error={dataSourceNameErrorState.showDataSourceError}
                >
                    <TextField label="Data Source Name*" onChange={handleDataSourceNameChange} />
                </FormItem>

                <FormItem
                    message={fileUploadErrorState.fileUploadErrorMessage}
                    error={fileUploadErrorState.showFileUploadError}
                >
                    <p style={{ marginBottom: "5px", fontWeight: "600", fontSize: "14px" }}>Upload File*</p>
                    <div className="sonic-file-card">
                        <div className="file-inputs">
                            <input type="file" onChange={handleSubmit} accept=".csv" />
                            <button>
                                <Icon aria-label="Browse" iconName="Add" />
                                Browse...
                            </button>
                        </div>
                        <p className='main'>Support file: CSV</p>
                    </div>

                    {uploadFile?.name && <ul className='sonic-file-list'>
                        <li className='list-item'>
                            <Icon aria-label="ExcelDocument" iconName="ExcelDocument" />
                            <p>{uploadFile?.name}</p>
                            <div className='action'>
                                <Icon aria-label="Delete" iconName="Delete" onClick={removeFile} />
                            </div>
                        </li>
                    </ul>}
                </FormItem>
                <div>
                    {
                        props.dataSourceType === "LinkScan" ?
                        <span className="bolt-formitem-message body-xs">Please <a href='https://microsoftapc.sharepoint.com/:x:/t/ContentCurationServiceInfo/EfwQI1qs1zFAonLO02XqxSsB5kSQ1MROJTgAq5B4QfQmUw' target="_blank">download the CSV template</a>, fill in your article list, and then upload it.</span>
                        :
                        <span className="bolt-formitem-message body-xs">Please <a href='https://microsoftapc.sharepoint.com/:x:/t/ContentCurationServiceInfo/EY-fvWjr74BJsXIpimJxfXoB7skmNRtcOv_tNxY75xFE7A?e=ah9gxs' target="_blank">download the CSV template</a>, fill in your text list, and then upload it.</span>
                    }
                
                </div>
            </div>
            <DialogFooter>
                <PrimaryButton onClick={uploadDataSourceFile} text="Submit" />
                <DefaultButton onClick={props.OnCancel} text="Cancel" />
            </DialogFooter>
        </Dialog>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(FileUploadDialog);

