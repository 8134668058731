import React, { Component } from 'react'
import { Button } from "azure-devops-ui/Button";
import { Dialog } from "azure-devops-ui/Dialog";
import { Observer } from "azure-devops-ui/Observer";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { MessageCard, MessageCardSeverity } from "azure-devops-ui/MessageCard";
import { ITagItem } from "../../Interface/ITag";
import { TagPicker } from "azure-devops-ui/TagPicker";
import { ISuggestionItemProps } from "azure-devops-ui/SuggestionsList";
import { Pill, PillSize, PillVariant } from "azure-devops-ui/Pill";
import { PillGroup } from "azure-devops-ui/PillGroup";
import { useObservableArray, useObservable } from "azure-devops-ui/Core/Observable";
import './ExemptionEditor.css'


export interface ExemptionEditorDialog {
    onApplyExemption: (data: any) => void,
    exemptionItems:ITagItem[]
}

let isDialogOpen = new ObservableValue<boolean>(false);
export default function ExemptionEditor(props:ExemptionEditorDialog) {

    //const [tagItems, setTagItems] = useObservableArray<ITagItem>(props.exemptionItems.slice(0, 2));
    const [tagItems, setTagItems] = useObservableArray<ITagItem>([]);
    const [suggestions, setSuggestions] = useObservableArray<ITagItem>([]);
    const [suggestionsLoading, setSuggestionsLoading] = useObservable<boolean>(false);
    const timeoutId = React.useRef<number>(0);

    /**
     * apply button click
     */
    function onExempt() {
        isDialogOpen.value = false

        if (props.onApplyExemption) {
            props.onApplyExemption(tagItems.value)
        }
    }

    /**
     * compare the tags
     */
    function areTagsEqual(a: ITagItem, b: ITagItem) {
        return a.id === b.id;
    }

    /**
     * convert item to pill
     * @param tag 
     * @returns 
     */
    function convertItemToPill(tag: ITagItem) {
        return {
            content: tag.text,
        };
    }

    /**
     * search the exmption
     * @param searchValue 
     */
    function onSearchChanged(searchValue: string) {
        clearTimeout(timeoutId.current);

        // Simulates a 1000ms round trip to retrieve values
        setSuggestionsLoading(true);

        timeoutId.current = window.setTimeout(() => {
            setSuggestions(
                props.exemptionItems
                    .filter(
                        // Items not already included
                        testItem =>
                            tagItems.value.findIndex(
                                testSuggestion => testSuggestion.id == testItem.id
                            ) === -1
                    )
                    .filter(
                        testItem =>
                            testItem.text.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                    )
            );

            setSuggestionsLoading(false);
        }, 1000);
    }

    /**
     * add tag
     * @param tag 
     */
    function onTagAdded(tag: ITagItem) {
        setTagItems([...tagItems.value, tag]);        
    }

    /**
     * remove the tag
     * @param tag 
     */
    function onTagRemoved(tag: ITagItem) {
        setTagItems(tagItems.value.filter(x => x.id !== tag.id));       
    }

    /**
     * render the tag
     * @param tag 
     * @returns 
     */
    function renderSuggestionItem(tag: ISuggestionItemProps<ITagItem>) {
        return <div className="body-m">{tag.item.text}</div>;
    }

    return (
        <div>
            <MessageCard
                className="flex-self-stretch"
                severity={MessageCardSeverity.Info}
            >
                {
                    'You can exclude exempted articles from this curation configuration so that they will not be included when this curation is running. The recommended naming standard to use is "Sys.EX.[System Name].[Tag Name]" or "Biz.EX.[Business Name].[Tag Name]" as the tag name. See the following examples:'
                }

                <PillGroup className="flex-row">
                <Pill size={PillSize.compact}>Biz.EX.PPP.LegalDoc</Pill>
                <Pill size={PillSize.compact}>Biz.EX.PPP.ComplianceDoc</Pill>
                <Pill size={PillSize.compact} variant={PillVariant.outlined}>
                Sys.EX.CI.kbContentAuto
                </Pill>
            </PillGroup>
            </MessageCard>
            
            <p>
                <Button
                    text="Set exemptions"
                    onClick={() => {
                        isDialogOpen.value = true;
                    }}
                />
            </p>
            <Observer isDialogOpen={isDialogOpen}>
                {(props: { isDialogOpen: boolean }) => {
                    return props.isDialogOpen ? (
                        <Dialog
                            titleProps={{ text: "Exempt" }}
                            footerButtonProps={[
                                {
                                    text: "Cancel",
                                    onClick: () => { isDialogOpen.value = false },
                                },
                                {
                                    text: "Apply",
                                    onClick: onExempt,
                                    primary: true,
                                },
                            ]}
                            onDismiss={null}
                        >
                            <div>
                                Please enter article tags you would like to exclude from this
                                curation:
                            </div>
                            <p>
                            <div className="flex-column">
                                <TagPicker
                                    areTagsEqual={areTagsEqual}
                                    convertItemToPill={convertItemToPill}
                                    noResultsFoundText={"No results found"}
                                    onSearchChanged={onSearchChanged}
                                    onTagAdded={onTagAdded}
                                    onTagRemoved={onTagRemoved}
                                    renderSuggestionItem={renderSuggestionItem}
                                    selectedTags={tagItems}
                                    suggestions={suggestions}
                                    suggestionsLoading={suggestionsLoading}
                                    className="msacct-exmption"
                                />
                            </div>
                            </p>
                            
                        </Dialog>
                    ) : null;
                }}
            </Observer>
        </div>
    )
}


