import React, { useState, useEffect, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { returnType } from "../Utilities/ReturnType";
import { connect } from "react-redux";
import { GlobalDataQuery } from "../Interface/IPowerQuery";
import { getBrokenLinkScanResult, clearBrokenLinkScanResult, getChildLinks, clearChildLinks } from "../../redux/Actions/PowerDataQuery";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { Table, renderSimpleCell, ITableColumn, SimpleTableCell } from "azure-devops-ui/Table";
import { Button } from "azure-devops-ui/Button";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Card } from "azure-devops-ui/Card";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { FormItem } from "azure-devops-ui/FormItem";
import { TextField, TextFieldWidth } from "azure-devops-ui/TextField";
import { Link } from "azure-devops-ui/Link";
import { Status, StatusSize } from "azure-devops-ui/Status";
import { Spinner, SpinnerSize, SpinnerOrientation } from "azure-devops-ui/Spinner";
import { IIconProps } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import { Tooltip } from "azure-devops-ui/TooltipEx";
import { checkURLFormatIsCorrect } from '../Utilities/CommonFunction'
import {
    getStatusIndicatorData
} from '../Utilities/DataListExtension'
import { getAPIService } from "../../redux/Actions/PowerDataQuery";
import { IScanIssueData } from "../Interface/IPowerQuery";
import { useMsal, useAccount } from "@azure/msal-react";
import MiniAppReportDialog from "../Modules/MiniApps/MiniAppReportDialog/MiniAppReportDialog";
import CustomMessageAlert from "../Modules/CustomMessageAlert/CustomMessageAlert";
import CustomConfirmDialog from "../Modules/CustomConfirmDialog/CustomConfirmDialog"
import { formatDateToUTCStringDate } from "../Utilities/CommonFunction";

import './BrokenLinkChecker.css'

const actionCreators = {
    getBrokenLinkScanResult,
    clearBrokenLinkScanResult,
    getChildLinks,
    clearChildLinks
};

const apiService = getAPIService().taskService;

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    brokenLinkScanResult: state.brokenLinkScanResult,
    childLinks: state.childLinks
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

/**
 * interface for broken links 
 */
interface IBrokenLinkItem {
    ParentLink?: string,
    ChildLink: string,
    LinkText: string,
    StatusCode: string,
    StatusCodeText: string,
    ScanStatus?: string,
    IsScan?: string,
    IssueId?: string
}

/**
 * interface for scan url
 */
interface IScanUrlState {
    scanUrl: string,
    scanStatus: string,
    urlStatus: string,
    showUrlError: boolean,
    urlErrorMessage: string
}

/**
 * interface for scan status
 */
interface IScanResultState {
    allCount: number,
    scannedCount: number,
    brokenCount: number,
    failedCount: number,
    successCount: number,
    falsePositiveCount: number,
    scanStatus: string
}

/**
 * interface for button and textbox status
 */
interface IControlStatus {
    scanUrlTextDisable: boolean,
    startButtonDisable: boolean
}


// help icon button props
const infoIcon: IIconProps = { iconName: 'Info' };

// url textbox abservable
const scanUrlTextBoxObservable = new ObservableValue<string>("");

/**
 * broken link checker
 * @param props 
 * @returns 
 */
function BrokenLinkChecker(props: StoreProps & DispatchProps) {
    const [search, setSearch] = useSearchParams();
    const requesturl = search.get('url')
    const { accounts } = useMsal();
    const userAccount = useAccount(accounts[0]);

    // ref for store interval
    let intervalId = useRef(null)

    /**
     * initialize scan url state
     */
    const [scanUrlState, setScanUrlState] = useState<IScanUrlState>({
        scanUrl: '',
        scanStatus: '',
        urlStatus: '',
        showUrlError: false,
        urlErrorMessage: ''
    });

    /**
     * initialize button and textbox state
     */
    const [controlState, setControlState] = useState<IControlStatus>({
        scanUrlTextDisable: false,
        startButtonDisable: false
    })

    /**
     * initialize scan result state
     */
    const [scanResultState, setScanResultState] = useState<IScanResultState>({
        allCount: 0,
        scannedCount: 0,
        brokenCount: 0,
        failedCount: 0,
        successCount: 0,
        falsePositiveCount: 0,
        scanStatus: ""
    })

    const [isReportDialogHidden, setIsReportDialogHidden] = useState<boolean>(true);
    const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [currentExecuteRow, setCurrentExecuteRow] = useState(null);
    const [expirationDate, setExpirationDate] = useState<string>(
        formatDateToUTCStringDate(new Date())
    );

    // error alert message
    const [showCalloutError, setShowCalloutError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    /**
     * initialize broken link items state
     */
    const [brokenLinksState, setBrokenLinksState] = useState<IBrokenLinkItem[]>([])

    const [catchBrokenLinksState, setCatchBrokenLinksState] = useState<IBrokenLinkItem[]>([])

    useEffect(() => {
        if (intervalId) {
            clearInterval(intervalId.current)
        }

        // render scan status
        renderScanStatus()

        // initialize broken links
        setBrokenLinksState([])

        // initialize url textbox
        scanUrlTextBoxObservable.value = requesturl || ''
        if (requesturl) {

            // if the url format is not correct, show error
            if (!checkURLFormatIsCorrect(scanUrlTextBoxObservable.value)) {
                setScanUrlState({
                    ...scanUrlState,
                    showUrlError: true,
                    urlErrorMessage: `It is incorrect URL format, please use the URL format like "https://internal.evergreen.microsoft.com/en-us/topic/d2177191-3315-ff0d-8973-5cf7a3acd766" to crawl.`
                })

                // initialize scan result
                setScanResultState({
                    allCount: 0,
                    scannedCount: 0,
                    brokenCount: 0,
                    failedCount: 0,
                    successCount: 0,
                    falsePositiveCount: 0,
                    scanStatus: ""
                })

                // enable button and textbox
                setControlState({
                    scanUrlTextDisable: false,
                    startButtonDisable: false,
                })
            }
            else {
                // initialize scan result
                setScanResultState({
                    allCount: 0,
                    scannedCount: 0,
                    brokenCount: 0,
                    failedCount: 0,
                    successCount: 0,
                    falsePositiveCount: 0,
                    scanStatus: "Running"
                })

                setControlState({
                    scanUrlTextDisable: true,
                    startButtonDisable: true,
                })

                if (scanUrlTextBoxObservable.value.trim() !== '') {
                    props.getBrokenLinkScanResult(`${scanUrlTextBoxObservable.value.trim()}`, true)
                }
            }
        }
        else {
            // initialize scan result
            setScanResultState({
                allCount: 0,
                scannedCount: 0,
                brokenCount: 0,
                failedCount: 0,
                successCount: 0,
                falsePositiveCount: 0,
                scanStatus: ""
            })

            setControlState({
                scanUrlTextDisable: false,
                startButtonDisable: false,
            })
        }

        return () => {
            // clear interval
            if (intervalId) {
                clearInterval(intervalId.current)
            }

            // clear child links
            props.clearChildLinks()
            setBrokenLinksState([])

            // clear scan result
            props.clearBrokenLinkScanResult()
        }
    }, [])


    /**
  * like componentDidUpdate
  */
    useEffect(() => {
        if (props.childLinks) {
            buildItems()

            // when an error occurs in the api
            if (props.childLinks.ScanStatus === "Failed" || props.childLinks.StatusCode?.toString() === "1") {
                // clear interval
                if (intervalId) {
                    clearInterval(intervalId.current)
                }

                // enable button and textbox
                setControlState({
                    scanUrlTextDisable: false,
                    startButtonDisable: false
                })

                console.error(props.childLinks)
            }
        }
    }, [props.childLinks])

    /**
     * like componentDidUpdate
     */
    useEffect(() => {
        if (props.brokenLinkScanResult) {
            // the url does not belong to a supported site
            if (props.brokenLinkScanResult.ScanStatus === "OutOfMsSite" ||
                props.brokenLinkScanResult.ScanStatus === "XboxMapNotExists") {
                setScanUrlState({
                    ...scanUrlState,
                    showUrlError: true,
                    urlErrorMessage: `The URL you entered does not belong to a supported site. For allowed sites, please visit this article: https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Known-issues-of-404-Errors-Algorithms.aspx `
                })

                setScanResultState({
                    allCount: 0,
                    scannedCount: 0,
                    brokenCount: 0,
                    failedCount: 0,
                    successCount: 0,
                    falsePositiveCount: 0,
                    scanStatus: ""
                })

                setControlState({
                    scanUrlTextDisable: false,
                    startButtonDisable: false
                })
            }
            // exception from api
            else if (props.brokenLinkScanResult.ScanStatus === "Exception") {
                setScanResultState({
                    allCount: 0,
                    scannedCount: 0,
                    brokenCount: 0,
                    failedCount: 0,
                    successCount: 0,
                    falsePositiveCount: 0,
                    scanStatus: "Failed"
                })

                setControlState({
                    scanUrlTextDisable: false,
                    startButtonDisable: false
                })
            }
            // EvergreenForbidden from api
            else if (props.brokenLinkScanResult.ScanStatus === "EvergreenForbidden") {
                setScanUrlState({
                    ...scanUrlState,
                    showUrlError: true,
                    urlErrorMessage: `Sorry, the recent upgrade of the Evergreen site has caused Sonic to temporarily lose access to internal articles. We are currently working with the relevant personnel to resolve this issue. As for all Evergreen articles, this feature is expected to be temporarily unavailable this week. Error returned from Evergreen site: "403 Forbidden"`
                })
    
                setScanResultState({
                    allCount: 0,
                    scannedCount: 0,
                    brokenCount: 0,
                    failedCount: 0,
                    successCount: 0,
                    falsePositiveCount: 0,
                    scanStatus: ""
                })
    
                setControlState({
                    scanUrlTextDisable: false,
                    startButtonDisable: false
                })
            }
            else {
                setScanResultState({
                    allCount: 0,
                    scannedCount: 0,
                    brokenCount: 0,
                    failedCount: 0,
                    successCount: 0,
                    falsePositiveCount: 0,
                    scanStatus: "Running"
                })

                setControlState({
                    scanUrlTextDisable: true,
                    startButtonDisable: true
                })

                // start getting child link
                if (scanUrlTextBoxObservable.value.trim() !== '') {
                    if (intervalId) {
                        clearInterval(intervalId.current)
                    }
                    intervalId.current = setInterval(() => {
                        props.getChildLinks(`${scanUrlTextBoxObservable.value.trim()}`)
                    }, 5000)
                }
            }
        }
        else {
            setScanResultState({
                allCount: 0,
                scannedCount: 0,
                brokenCount: 0,
                failedCount: 0,
                successCount: 0,
                falsePositiveCount: 0,
                scanStatus: ""
            })

            setControlState({
                scanUrlTextDisable: false,
                startButtonDisable: false
            })
        }

    }, [props.brokenLinkScanResult])

    const buttonClick = (type: string, currentRow: {tableItem: IBrokenLinkItem, rowIndex: number}) => {
        setCurrentExecuteRow(currentRow)
        if(type === 'Report as false positive') {
            setIsReportDialogHidden(false)
        }
    }

    function renderButtonCell(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<IBrokenLinkItem>,
        tableItem: IBrokenLinkItem) {
            return (
                <SimpleTableCell
                    columnIndex={columnIndex}
                    tableColumn={tableColumn}
                    key={tableColumn.id}
                    contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
                    >
                        {
                            (!tableItem.IssueId && tableItem.ScanStatus === "Failed") &&
                            <Button
                                text="Report as false positive"
                                style={{minWidth: '80px'}}
                                onClick={()=>{buttonClick('Report as false positive',{tableItem,rowIndex})}}
                            />
                        }
                </SimpleTableCell>
            )
    }


    /**
     * build data list columns
     * @returns 
     */
    function buildColumns(): any {
        const columns = [
            {
                id: "ChildLink",
                name: "Link",
                maxWidth: 1000,
                readonly: true,
                renderCell: renderChildLinkColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-300),
            },
            {
                id: "LinkText",
                name: "Link text",
                maxWidth: 500,
                readonly: true,
                renderCell: renderLinkTextColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-200),
            },
            {
                id: "StatusCode",
                name: "Status",
                maxWidth: 100,
                readonly: true,
                renderCell: renderScanStausColumn,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-80),
            },
            {
                id: "StatusCodeText",
                name: "Scan result",
                maxWidth: 300,
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-150),
            },
            {
                id: "actions",
                name: "Actions",
                maxWidth: 300,
                readonly: true,
                renderCell: renderButtonCell,
                width: new ObservableValue(-100),
            }

        ];

        return columns;
    }

    /**
     * 
     * @param rowIndex 
     * @param columnIndex 
     * @param tableColumn 
     * @param tableItem 
     * @returns 
     */
    function renderLinkTextColumn(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<IBrokenLinkItem>,
        tableItem: IBrokenLinkItem
    ) {
        const {
            LinkText
        } = tableItem;

        return (
            <SimpleTableCell
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                key={"col-" + columnIndex}
                contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
            >
                <Tooltip text={LinkText} overflowOnly={false}>
                    <span>
                        {LinkText}
                    </span>
                </Tooltip>

            </SimpleTableCell>
        );
    }

    /**
     * render scan status column
     * @param rowIndex 
     * @param columnIndex 
     * @param tableColumn 
     * @param tableItem 
     * @returns 
     */
    function renderScanStausColumn(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<IBrokenLinkItem>,
        tableItem: IBrokenLinkItem
    ) {
        const {
            ScanStatus,
            IsScan
        } = tableItem;

        return (
            <SimpleTableCell
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                key={"col-" + columnIndex}
                contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
            >
                {
                    tableItem.IssueId?
                    <svg className="broken_link_false_positive_status flex-noshrink icon-large-margin task-datalist-icon"
                        height="12"
                        role="presentation"
                        viewBox="0 0 12 12"
                        width="12"
                        xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6"></circle>
                            <path d="M4.74 8.19l-.002-.002-1.29-1.29a.677.677 0 1 1 .958-.957l.813.812 2.804-2.805a.678.678 0 0 1 .959.958L5.7 8.188l-.002.002a.678.678 0 0 1-.958 0z" fill="#fff"></path>
                    </svg> :
                    <Status
                        {...getStatusIndicatorData(ScanStatus).statusProps}
                        className="icon-large-margin task-datalist-icon"
                        size={StatusSize.s}
                    />
                }
            </SimpleTableCell>
        );
    }

    /**
   * Render ContentId cell
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
    function renderChildLinkColumn(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<IBrokenLinkItem>,
        tableItem: IBrokenLinkItem) {
        const { ChildLink } = tableItem

        return (
            <SimpleTableCell
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                key={"col-" + columnIndex}
                contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
            >
                {
                    ChildLink && ChildLink.toString() !== '' ?
                        (
                            <Link
                                className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                                excludeTabStop
                                href={ChildLink}
                                target={"_blank"}
                            >
                                {ChildLink}
                            </Link>
                        )
                        :
                        (
                            <div className="flex-row scroll-hidden">
                                {ChildLink}
                            </div>
                        )
                }

            </SimpleTableCell>
        );
    }

    async function getReportedBrokenLink(items: IBrokenLinkItem[]) {
        const sourceData: IScanIssueData[] = []
        items.forEach((item:IBrokenLinkItem) => {
            if(item.ScanStatus === 'Failed') {
                sourceData.push({
                    content: item.ChildLink
                })
            }
        })
        if(sourceData.length > 0) {
            const data = await apiService.getIssueList(scanUrlTextBoxObservable.value.trim(), userAccount.username, sourceData, 'BrokenLink')
            data.issueData.forEach(issue => {
                items.forEach((item:IBrokenLinkItem) => {
                    if(issue.content === item.ChildLink && issue.issueId) {
                        item.IssueId = issue.issueId
                        item.StatusCodeText = 'Broken(Reported as false positive)'
                    }
                })
            })
        }
        setCatchBrokenLinksState(items)
    }

    /**
     * build data list items
     * @returns 
     */
    async function buildItems() {
        let items: IBrokenLinkItem[] = []

        if (props.childLinks && props.childLinks.ChildResult) {

            let scannedCount = 0;
            props.childLinks.ChildResult.map(item => {
                // true: scan complete, false: scanning
                let isScanned = !!item.IsScan

                // server response
                let statusCode = isScanned ? (item.StatusCode || 'UnKnown') : ''
                let statusCodeText = statusCode === '' ? '' : `Failed (${statusCode})`

                // scan status
                let scanStatus = "Running"
                if (isScanned) {
                    scannedCount++
                    if (statusCode === "OK") {
                        scanStatus = "Success"
                        statusCodeText = `Success`
                    }
                    else if (statusCode === "NotFound") {
                        scanStatus = "Failed"
                        statusCodeText = 'Broken'
                    }
                    else {
                        scanStatus = "Warning"
                        statusCodeText = statusCode === '' ? '' : `Failed (${statusCode})`
                    }
                }

                items.push({
                    ChildLink: item.ScanUrl,
                    LinkText: item.LinkText,
                    StatusCode: statusCode,
                    ScanStatus: scanStatus,
                    StatusCodeText: statusCodeText,
                    IsScan: item.IsScan
                })
            })

            // set count
            var allCount = items.length
            let brokenLinks = items.filter(t => {
                return t.StatusCode === "NotFound"
            });
            let failedLinks = items.filter(f => {
                return (f.StatusCode !== "NotFound" && f.StatusCode !== "OK" && f.StatusCode !== "")
            })
            let successLinks = items.filter(s => {
                return (s.StatusCode === "OK")
            })

            // check have running
            let hasRuning = props.childLinks.ChildResult.some(item => {
                let isScan = !!item.IsScan
                return isScan === false
            })

            let scanStatus = "Running"

            // all completed
            if (!hasRuning) {
                scanStatus = "Existing"

                if (intervalId) {
                    clearInterval(intervalId.current)
                }

                setControlState({
                    scanUrlTextDisable: false,
                    startButtonDisable: false
                })

                await getReportedBrokenLink(items)
            }

            const falsePositiveCount = items.filter(item=> item.IssueId!== undefined && item.IssueId!== null && item.IssueId!=='').length

            // set the scan count
            setScanResultState({
                allCount: allCount,
                scannedCount: scannedCount,
                brokenCount: brokenLinks.length - falsePositiveCount,
                failedCount: failedLinks.length,
                successCount: successLinks.length,
                falsePositiveCount,
                scanStatus: scanStatus
            })

            // set child link list
            setBrokenLinksState(items)
        }
        else {
            setBrokenLinksState([])
        }
    }

    /**
     * all scan status
     * @returns 
     */
    function renderScanStatus() {
        switch (scanResultState.scanStatus) {
            case "Running":
                return <Spinner className='msacct-brokenlink-scanresult' size={SpinnerSize.small} label="Scanning..." orientation={SpinnerOrientation.row} />
            case "Existing":
                return <span style={{ color: "green" }}>Completed</span>
            case "Failed":
                return <span style={{ color: "red" }}>Failed</span>
            default:
                return <span>Not started</span>
        }
    }

    /**
     * render scan result count
     * @returns 
     */
    function renderScannedResultCount() {
        if (scanResultState.scanStatus === "Existing") {
            return (
                <div className="LayoutPage-demoBlock">
                    <Link href="#" onClick={event => handleAllLinkClick(event)}>Scanned links </Link>
                    <span style={{ fontWeight: "bold" }}>({scanResultState.scannedCount}/{scanResultState.allCount}) </span>
                    <span> | </span>
                    <Link href="#" onClick={event => handleBrokenLinkClick(event)}>Broken links </Link>
                    (<span style={{ color: "red", fontWeight: "bold" }}>{scanResultState.brokenCount}</span>)
                    <span> | </span>
                    <Link href="#" onClick={event => handleFalsePositiveLinkClick(event)}>False positive reported </Link>
                    (<span style={{ color: "#25e22e", fontWeight: "bold" }}>{scanResultState.falsePositiveCount}</span>)
                    <span> | </span>
                    <Link href="#" onClick={event => handleFaildLinkClick(event)}>Failed links </Link>
                    (<span style={{ color: "#D67F3C", fontWeight: "bold" }}>{scanResultState.failedCount}</span>)
                    <span> | </span>
                    <Link href="#" onClick={event => handleSuccessLinkClick(event)}>Success links </Link>
                    (<span style={{ color: "green", fontWeight: "bold" }}>{scanResultState.successCount}</span>)
                    <span> | Scan status </span>
                    <span>({renderScanStatus()})</span>
                </div>
            )
        }
        else {
            return (
                <div className="LayoutPage-demoBlock">
                    <span>Scanned links ({scanResultState.scannedCount}/{scanResultState.allCount}) </span>
                    <span> | </span>
                    <span>Broken links (<span style={{ color: "red" }}>{scanResultState.brokenCount}</span>) </span>
                    <span> | </span>
                    <span>False positive reported (<span style={{ color: "#25e22e" }}>{scanResultState.falsePositiveCount}</span>) </span>
                    <span> | </span>
                    <span>Failed links (<span style={{ color: "#D67F3C" }}>{scanResultState.failedCount}</span>) </span>
                    <span> | </span>
                    <span>Success links (<span style={{ color: "green" }}>{scanResultState.brokenCount}</span>) </span>
                    <span> | Scan status </span>
                    <span>({renderScanStatus()})</span>
                </div>
            )
        }
    }

    /**
     * scan link
     * @returns 
     */
    function scanLink() {
        // clear previous link list
        props.clearChildLinks()
        setBrokenLinksState([])

        // empty check
        if (scanUrlTextBoxObservable.value.trim() === '') {
            setScanUrlState({
                ...scanUrlState,
                showUrlError: true,
                urlErrorMessage: `The URL cannot be empty, please use the URL format like "https://internal.evergreen.microsoft.com/en-us/topic/d2177191-3315-ff0d-8973-5cf7a3acd766" to crawl.`
            })

            setScanResultState({
                allCount: 0,
                scannedCount: 0,
                brokenCount: 0,
                failedCount: 0,
                successCount: 0,
                falsePositiveCount: 0,
                scanStatus: ""
            })

            return;
        }

        // format check
        if (!checkURLFormatIsCorrect(scanUrlTextBoxObservable.value)) {
            setScanUrlState({
                ...scanUrlState,
                showUrlError: true,
                urlErrorMessage: `It is incorrect URL format, please use the URL format like "https://internal.evergreen.microsoft.com/en-us/topic/d2177191-3315-ff0d-8973-5cf7a3acd766" to crawl.`
            })

            setScanResultState({
                allCount: 0,
                scannedCount: 0,
                brokenCount: 0,
                failedCount: 0,
                successCount: 0,
                falsePositiveCount: 0,
                scanStatus: ""
            })

            return;
        }

        // disable button and textbox
        setControlState({
            scanUrlTextDisable: true,
            startButtonDisable: true
        })

        // clear the error
        setScanUrlState({
            ...scanUrlState,
            showUrlError: false,
            urlErrorMessage: ''
        })

        if (scanUrlTextBoxObservable.value.trim() !== '') {
            props.getBrokenLinkScanResult(`${scanUrlTextBoxObservable.value.trim()}`, true)
        }
    }

    /**
     * handle scanned link click
     * @param event 
     */
    function handleAllLinkClick(event) {
        let items: IBrokenLinkItem[] = []
        if (props.childLinks && props.childLinks.ChildResult) {
            catchBrokenLinksState.forEach(item => {
                // true: scan complete, false: scanning
                let isScan = !!item.IsScan

                // server response
                let statusCode = isScan ? (item.StatusCode || 'UnKnown') : ''
                if (isScan) {
                    if (statusCode === "OK") {
                        item.ScanStatus = "Success"
                        item.StatusCodeText = `Success`
                    }
                    else if (statusCode === "NotFound") {
                        item.ScanStatus = "Failed"
                        item.StatusCodeText = item.IssueId? 'Broken(Reported as false positive)': 'Broken'
                    }
                    else {
                        item.ScanStatus = "Warning"
                        item.StatusCodeText = `Failed (${statusCode})`
                    }
                }
                items.push(item)
            })

            setBrokenLinksState(items)
        }
        event.preventDefault()
    }

    /**
     * handle broken link click
     */
    function handleBrokenLinkClick(event) {
        if (props.childLinks && props.childLinks.ChildResult) {
            const filterChildLinks = catchBrokenLinksState.filter(t => {
                return t.StatusCode === "NotFound" && !t.IssueId
            })

            setBrokenLinksState(filterChildLinks)
        }
        event.preventDefault()
    }

    function handleFalsePositiveLinkClick(event) {
        if (props.childLinks && props.childLinks.ChildResult) {
            const filterChildLinks = catchBrokenLinksState.filter(t => {
                return t.IssueId!== undefined && t.IssueId!== null && t.IssueId!==''
            })

            setBrokenLinksState(filterChildLinks)
        }
        event.preventDefault()
    }

    /**
     * handle faild link click
     * @param event 
     */
    function handleFaildLinkClick(event) {
        if (props.childLinks && props.childLinks.ChildResult) {
            const filterChildLinks = catchBrokenLinksState.filter(t => {
                return (t.StatusCode !== "NotFound" && t.StatusCode !== "OK")
            })

            setBrokenLinksState(filterChildLinks)
        }
        event.preventDefault()
    }

    /**
     * handle success link click
     * @param event 
     */
    function handleSuccessLinkClick(event) {
        if (props.childLinks && props.childLinks.ChildResult) {
            const filterChildLinks = catchBrokenLinksState.filter(t => {
                return (t.StatusCode === "OK")
            })

            setBrokenLinksState(filterChildLinks)
        }

        event.preventDefault()
    }

    const closeReport = (): void => {
        setIsReportDialogHidden(true);
    };

    const getExpireTime = (): Date => {
        const time = new Date();
        time.setTime(time.getTime() + 24*60*60*1000*180);
        return time;
    }

    const confirmReport = async(description: string) => {
        setIsDialogLoading(true)
        const today = formatDateToUTCStringDate(new Date())
        const createParam = {
            issueData:[{
                articleUrl: scanUrlTextBoxObservable.value.trim(),
                content: currentExecuteRow.tableItem.ChildLink,
                exceptionType: 'False Positive',
                sourceApp: 'SONIC',
                reporter: userAccount.username,
                reportTime: today,
                riskArea: 'BrokenLink',
                businessJustification: description,
                startTime: today,
                expireTime: formatDateToUTCStringDate(getExpireTime()),
            }]
        }
        try {
            const data = await apiService.createIssueReport(createParam);
            if(data.success) {
                setShowSuccessMessage(true)
                setIsReportDialogHidden(true)
                setTimeout(() => {
                    setShowSuccessMessage(false)
                }, 3000);
                await getReportedBrokenLink(catchBrokenLinksState);
            }
        }catch(error){
            setErrorMessage('Report false positive failed, please try again later')
            setShowCalloutError(true)
        }finally {
            setIsDialogLoading(false);
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <Surface background={SurfaceBackground.neutral}>
                <Page className="pipelines-page flex-grow">
                    <div className="page-content page-content-top">
                        <CustomMessageAlert
                            showAlert={showSuccessMessage}
                            message="Report false positive successfully!"
                            type="success"
                        />
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg10">
                                    <FormItem label="Enter the address of your article below and let Broken Link Checker assess the health of your article:" className='sonic-ticketADO-formitem' error={scanUrlState.showUrlError} message={scanUrlState.urlErrorMessage} >
                                        <TextField
                                            value={scanUrlTextBoxObservable}
                                            onChange={(e, newValue) => {
                                                scanUrlTextBoxObservable.value = newValue
                                                setScanUrlState({
                                                    ...scanUrlState,
                                                    scanUrl: newValue
                                                })
                                            }}
                                            placeholder="Your Website URL"
                                            disabled={controlState.scanUrlTextDisable}
                                            width={TextFieldWidth.standard}
                                            containerClassName={"msacct-brokenlink-searchbox"}
                                        />
                                    </FormItem>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg2">
                                    <Button
                                        style={{ "marginTop": "18px" }}
                                        text="Start Check"
                                        primary={true}
                                        disabled={controlState.startButtonDisable}
                                        onClick={() => scanLink()}
                                    />
                                    <IconButton style={{ marginTop: "18px" }} onClick={() => { window.open("https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Known-issues-of-404-Errors-Algorithms.aspx") }} iconProps={infoIcon} title="About this link crawler" ariaLabel="help" />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-lg10" style={{ fontSize: "18px" }}>
                                    {renderScannedResultCount()}
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <br />
                            </div>
                            <div className="ms-Grid-row">
                                <Card>
                                    <Table<Partial<IBrokenLinkItem>>
                                        columns={buildColumns()}
                                        itemProvider={new ArrayItemProvider<IBrokenLinkItem>(brokenLinksState)}
                                        showLines={true}
                                        pageSize={2000}
                                        containerClassName="msacct-devops-table"
                                        showScroll={false}
                                    />
                                </Card>
                            </div>
                        </div>
                    </div>
                </Page>
            </Surface>
            <CustomConfirmDialog
                showDialog={showCalloutError}
                title="Action Error"
                dialogType="error"
                onConfirmDismiss={()=>{ setShowCalloutError(false) }}
                content={errorMessage}
                confirmButtonLabel="Dismiss"
            />
            <MiniAppReportDialog
                hideReportDialog={isReportDialogHidden}
                close={closeReport}
                confirm={confirmReport}
                isLoading={isDialogLoading}
            />
        </div>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(BrokenLinkChecker);
