import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { returnType } from "../../../Utilities/ReturnType";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

import { CustomHeader, HeaderTitleArea } from "azure-devops-ui/Header";
import { CustomDialog } from "azure-devops-ui/Dialog";
import { PanelContent, PanelFooter } from "azure-devops-ui/Panel";
import { ITableColumn, SimpleTableCell, Table, renderSimpleCell } from "azure-devops-ui/Table";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { Link } from "azure-devops-ui/Link";
import { GlobalDataQuery } from "../../../Interface/IPowerQuery";
import { DataListItem, DataListItemOthers } from '../../../Interface/IDataResult'
import { getCurationQueryFields } from "../../../Utilities/QueryBuilderExtension";
import InfiniteScroll from "react-infinite-scroll-component";
import { searchContent, onExportAlgoReport, clearSearchResults, createDownloadLogs } from '../../../../redux/Actions/PowerDataQuery'
import { formatDateTime } from '../../../Utilities/CommonFunction'

import "./ReportView.css";

const actionCreators = {
    clearSearchResults,
    searchContent,
    onExportAlgoReport,
    createDownloadLogs,
};
type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    searchResult: state.searchResult
});
const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;


interface IPaginationState {
    PageSize: number,
    PageIndex: number,
    SearchDataIndex: number
}

interface ISearchParameterProps {
    CurationId: string;
    CurationName: string;
    SearchIndex: string;
    Filter: string;
    CustomColumns?: any;
    onReportDialogDimiss: () => void
}

function ViewReport(props: StoreProps & DispatchProps & ISearchParameterProps) {
    const [reportItems, setReportItems] = useState<Array<DataListItem>>([])
    const [reportItemsOthers, setReportItemsOthers] = useState<Array<DataListItemOthers>>([])
    const [reportItemsPoliCheck, setReportItemsPoliCheck] = useState<Array<any>>([])
    const [reportPagination, setReportPagination] = useState<IPaginationState>({
        SearchDataIndex: 0,
        PageSize: 200,
        PageIndex: 0
    })
    const [seeMore, setSeeMore] = useState<boolean>(true)
    const [firstDataLoadComplete, setFirstDataLoadComplete] = useState<boolean>(false)

    /**
     * like componentDidMount
     */
    useEffect(() => {

        setReportItems([])
        setReportItemsOthers([])
        setSeeMore(true)

        let metadataSchema = props.SearchIndex;

        let selectString = getCurationQueryFields(metadataSchema, 'Blank');

        setFirstDataLoadComplete(false)
        const loadData = async () => {
            await props.searchContent(
                "test",
                metadataSchema,
                props.Filter,
                '',
                selectString,
                reportPagination.PageSize,
                0
            );

            setFirstDataLoadComplete(true)
        }

        loadData();

        return () => {
            props.clearSearchResults()
        }
    }, [])

    /**
     * like componentDidUpdate
     */
    useEffect(() => {
        buildItemsWithReport()
    }, [props.searchResult.Topics])

    /**
     * 
     * @returns 
     */
    function buildItemsWithReport() {
        if (!props.searchResult || !props.searchResult.Topics || props.searchResult.Topics.length === 0) {
            return []
        }

        console.log("buildItemsWithReport", props.searchResult)

        let items: DataListItem[] = []
        let itemsOthers: DataListItemOthers[] = []
        let itemsPoliCheck: any[] = []
        props.searchResult.Topics.map((obj, index) => {

            if (props.SearchIndex.startsWith("gitpub-metadata-schema-others-")) {
                itemsOthers.push({
                    ReferrerLink: obj.ReferrerLink?.toString(),
                    ReferrerType: obj.ReferrerType?.toString(),
                    ReferrerDomain: obj.ReferrerDomain?.toString(),
                    ReferrerID: obj.ReferrerID?.toString(),
                    ReferrerOwner: obj.ReferrerOwner?.toString(),
                    NoOfBrokenLinks: obj.NoOfBrokenLinks?.toString(),
                    HasBrokenLinks: obj.HasBrokenLinks?.toString(),
                    PgDomainList: obj.PgDomainList?.toString(),
                    ErrorPage404List: obj.ErrorPage404List?.toString(),
                    BrokenLinkClicks: obj.BrokenLinkClicks?.toString(),
                    ArticleVisits: obj.ArticleVisits?.toString(),
                    BrokenLinkDetectedTime: formatDateTime(obj.BrokenLinkDetectedTime?.toString()),
                    RepoNameList: obj.RepoNameList?.toString(),
                    DataSource: obj.DataSource?.toString(),
                });
            }
            else if (props.SearchIndex.startsWith("gitpub-metadata-schema-policheck-")) {
                itemsPoliCheck.push({
                    Link: obj.Link?.toString(),
                    Locale: obj.Locale?.toString(),
                    Domain: obj.Domain?.toString(),
                    Text: obj.Text?.toString(),
                    Context: obj.Context?.toString(),
                    MatchScore: obj.MatchScore?.toString(),
                    Guidelines: obj.Guidelines?.toString(),
                    Why: obj.Why?.toString(),
                });
            }
            else {
                var sapName = obj.SapName?.toString()
                    .replaceAll("\\", "")
                    .replaceAll("[", "")
                    .replaceAll("]", "")
                    .replaceAll("\"", "")
                    .replaceAll("\\", "")

                let pageViewMonthAll = "0/0"
                if (obj.NoOfPageViews30days && obj.NoOfPageViews) {
                    pageViewMonthAll = obj.NoOfPageViews30days + "/" +
                        obj.NoOfPageViews
                }
                else if (obj.NoOfPageViews30days) {
                    pageViewMonthAll = obj.NoOfPageViews30days + "/0"
                }
                else if (obj.NoOfPageViews) {
                    pageViewMonthAll = "0/" +
                        obj.NoOfPageViews
                }

                var articleAge = "0/0";
                if (obj.RefreshAgeMonth && obj.AgeMonth) {
                    articleAge = obj.RefreshAgeMonth + "/" + obj.AgeMonth
                }
                else if (obj.RefreshAgeMonth) {
                    articleAge = obj.RefreshAgeMonth + "/0"
                }
                else if (obj.AgeMonth) {
                    articleAge = "0/" + obj.AgeMonth
                }

                items.push({
                    ContentId: obj.ContentID,
                    LocaleCode: obj.Locale,
                    Title: obj.PageTitle,
                    PublishOn: obj.LastPublishDate?.toString(),
                    CreatedOn: obj.ArticleCreatedDate?.toString(),
                    PageView: obj.NoOfPageViews?.toString(),
                    PageViewMonth: obj.NoOfPageViews30days?.toString(),
                    NoOfPageViews7days: obj.NoOfPageViews7days?.toString(),
                    NoOfPageViews30days: obj.NoOfPageViews30days?.toString(),
                    NoOfPageViews90days: obj.NoOfPageViews90days?.toString(),
                    NoOfPageViews360days: obj.NoOfPageViews360days?.toString(),
                    PageViewAll: obj.NoOfPageViews?.toString(),
                    PageViewMonthAll: pageViewMonthAll,
                    BrokenLinkCount: obj.NoOfBrokenLinks?.toString(),
                    HasBrokenLinks: obj.HasBrokenLinks?.toString(),
                    RepoName: obj.RepositoryName,
                    SAPName: sapName,
                    LiveUrl: obj.URL,
                    AgeMonth: obj.AgeMonth?.toString(),
                    RefreshAgeMonth: obj.RefreshAgeMonth?.toString(),
                    CountOfInternalReferences: obj.NoOfInternalReferences ? obj.NoOfInternalReferences.toString() : "0",
                    ArticleAge: articleAge,

                    SapStatus: obj.SapStatus?.toString(),
                    MainstreamDate: formatDateTime(obj.MainstreamDate?.toString()),
                    ExtendedEndDate: formatDateTime(obj.ExtendedEndDate?.toString()),
                    RetirementDate: formatDateTime(obj.RetirementDate?.toString()),
                    ReleaseEndDate: formatDateTime(obj.ReleaseEndDate?.toString()),
                    MainStreamRemainDays: obj.MainStreamRemainDays?.toString(),
                    ExtendedEndRemainDays: obj.ExtendedEndRemainDays?.toString(),
                    RetirementRemainDays: obj.RetirementRemainDays?.toString(),
                    ReleaseEndRemainDays: obj.ReleaseEndRemainDays?.toString(),
                    ArticleArchivingRemainingDays: obj.ArticleArchivingRemainingDays?.toString(),

                    AvgPageViewsDay: obj.AvgPageViewsDay?.toString(),
                    AvgPageViewsMonth: obj.AvgPageViewsMonth?.toString(),
                    NoOfUniqueVisitors: obj.NoOfUniqueVisitors?.toString(),
                    PercentageOfEngagedRate: obj.PercentageOfEngagedRate?.toString(),
                    PercentageOfHRR: obj.PercentageOfHRR?.toString(),
                    RevisionNumber: obj.RevisionNumber?.toString(),

                    PgDomainList: obj.PgDomainList?.toString(),
                    ErrorPage404List: obj.ErrorPage404List?.toString(),
                    BrokenLinkDetectedTime: formatDateTime(obj.BrokenLinkDetectedTime?.toString()),
                });
            }
        })

        if (props.SearchIndex.startsWith("gitpub-metadata-schema-others-")) {
            setReportItemsOthers([...reportItemsOthers, ...itemsOthers])
        }
        if (props.SearchIndex.startsWith("gitpub-metadata-schema-policheck-")) {
            setReportItemsPoliCheck([...reportItemsPoliCheck, ...itemsPoliCheck])
        }
        else {
            setReportItems([...reportItems, ...items])
        }

        if (props.searchResult.Topics.length < reportPagination.PageSize) {
            setSeeMore(false)
        }
    }

    /**
     * Build datalist columns
     * @returns 
     */
    function buildReportColumns(): any {
        if (props.CustomColumns && props.CustomColumns.length > 0) {
            return props.CustomColumns
        }

        const columns = [
            {
                id: "ContentId",
                name: "Content Id",
                readonly: true,
                renderCell: renderContentIdCell,
                sortProps: {
                    ariaLabelAscending: "Sorted A to Z",
                    ariaLabelDescending: "Sorted Z to A",
                },
                width: 300,
            },
            {
                id: "LocaleCode",
                maxWidth: 300,
                name: "Locale",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: 100,
            },
            {
                id: "ArticleArchivingRemainingDays",
                name: "Article Archiving Remaining Days",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 250,
            },
            {
                id: "SapStatus",
                name: "SAP State",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 100,
            },
            {
                id: "Title",
                maxWidth: 300,
                name: "Title",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: 200,
            },
            {
                id: "PageViewAll",
                maxWidth: 300,
                name: "# of PageViews (All)",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: 200,
            },
            {
                id: "NoOfPageViews360days",
                maxWidth: 300,
                name: "# of PageViews (1Y)",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: 200,
            },
            {
                id: "NoOfPageViews90days",
                maxWidth: 300,
                name: "# of PageViews (1Q)",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: 200,
            },
            {
                id: "NoOfPageViews30days",
                maxWidth: 300,
                name: "# of PageViews (1M)",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: 200,
            },
            {
                id: "RefreshAgeMonth",
                name: "# of Months Since Creation",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 250,
            },
            {
                id: "AgeMonth",
                name: "# of Months Since Last Publish",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 250,
            },
            {
                id: "AvgPageViewsMonth",
                name: "AvgPageViews/Mon",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 250,
            },
            {
                id: "NoOfUniqueVisitors",
                name: "# of Unique Visitors",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 250,
            },
            {
                id: "PercentageOfEngagedRate",
                name: "Engaged Rate%",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 250,
            },
            {
                id: "PercentageOfHRR",
                name: "HRR%",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 250,
            },
            {
                id: "RevisionNumber",
                name: "Revision Number",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 250,
            },
            {
                id: "RepoName",
                name: "Repos Name",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 200,
            },
            {
                id: "SAPName",
                name: "SAP Name",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 200,
            },
            {
                id: "MainStreamRemainDays",
                name: "Mainstream Remaining Days",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 200,
            },
            {
                id: "ExtendedEndRemainDays",
                name: "Extended End Remaining Days",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 200,
            },
            {
                id: "RetirementRemainDays",
                name: "Retirement Remaining Days",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 200,
            },
            {
                id: "ReleaseEndRemainDays",
                name: "Release End Remaining Days",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 200,
            },
            {
                id: "MainstreamDate",
                name: "Mainstream Date",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 200,
            },
            {
                id: "ExtendedEndDate",
                name: "Extended End Date",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 200,
            },
            {
                id: "RetirementDate",
                name: "Retirement Date",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 200,
            },
            {
                id: "ReleaseEndDate",
                name: "Release End Date",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 200,
            },
        ];

        return columns;
    }

    /**
   * Render ContentId cell
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
    function renderContentIdCell(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<DataListItem>,
        tableItem: DataListItem) {

        const { ContentId, LiveUrl } = tableItem

        return (
            <SimpleTableCell
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                key={"col-" + columnIndex}
                contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
            >
                {
                    LiveUrl && LiveUrl.toString() !== '' ?
                        (
                            <Link
                                className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                                excludeTabStop
                                href={LiveUrl}
                                target={"_blank"}
                            >
                                {ContentId}
                            </Link>
                        )
                        :
                        (
                            <div className="flex-row scroll-hidden">
                                {ContentId}
                            </div>
                        )
                }

            </SimpleTableCell>
        );
    }

    /**
    * PageIndex    SearchDataIndex
    * 0            0
    * 1            100
    * 2            200
    */
    const fetchMoreData = async () => {
        let pageIndex = reportPagination.PageIndex + 1
        let searchDataIndex = pageIndex * reportPagination.PageSize

        let metadataSchema = props.SearchIndex;

        let selectString = getCurationQueryFields(metadataSchema, 'Blank');

        await props.searchContent(
            "test",
            metadataSchema,
            props.Filter,
            '',
            selectString,
            reportPagination.PageSize,
            searchDataIndex
        );

        setReportPagination({
            ...reportPagination,
            PageIndex: pageIndex,
            SearchDataIndex: searchDataIndex
        })
    }

    /**
     * Export report data to csv file
     */
    function onExportAlgoReport() {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let monthString = month > 9 ? month.toString() : "0" + month.toString()
        let dateString = date > 9 ? date.toString() : "0" + date.toString()

        let fullDate = monthString + dateString + year

        let metadataSchema = props.SearchIndex;

        let selectString = getCurationQueryFields(metadataSchema, 'Blank');

        props.onExportAlgoReport(
            "test",
            metadataSchema,
            props.Filter,
            selectString,
            `${props.CurationName}_${fullDate}.csv`
        );

        props.createDownloadLogs(
            props.CurationId,
            props.CurationName,
            "/api/search/download"
        )
    }

    return (
        <CustomDialog calloutContentClassName='msacct-algorithm-dialog-container' onDismiss={props.onReportDialogDimiss} modal={true} className="ddd">
            <CustomHeader className="bolt-header-with-commandbar" separator>
                <HeaderTitleArea>
                    <div
                        className="flex-grow scroll-hidden"
                        style={{ marginRight: "16px" }}
                    >
                        <div
                            className="title-m"
                            style={{
                                height: "500px",
                                width: "500px",
                                maxHeight: "32px"
                            }}
                        >
                            {`${props.CurationName} - Report`}
                            {/* {props.CurationName + " - " + "Report"} */}
                        </div>
                        <div style={{ textAlign: "right" }} ><span>(Showing {props.SearchIndex.startsWith("gitpub-metadata-schema-others-") ? reportItemsOthers.length : reportItems.length} of {props.searchResult.Count})</span></div>
                    </div>
                </HeaderTitleArea>
            </CustomHeader>
            <PanelContent className='msacct-algo-panelcontent'>
                <div id='msacct-algo-scrollableDiv'
                    style={{
                        overflow: 'auto',
                        display: 'flex',
                    }}>
                    <InfiniteScroll
                        dataLength={props.SearchIndex.startsWith("gitpub-metadata-schema-others-") ? reportItemsOthers.length : reportItems.length}
                        next={fetchMoreData}
                        hasMore={seeMore}
                        loader={
                            firstDataLoadComplete &&
                                (props.SearchIndex.startsWith("gitpub-metadata-schema-others-") && reportItemsOthers.length === 0 ||
                                    !props.SearchIndex.startsWith("gitpub-metadata-schema-others-") && reportItems.length === 0) ?
                                <h4>No results found</h4>
                                :
                                <h4>Loading...</h4>
                        }
                        scrollableTarget="msacct-algo-scrollableDiv"
                        endMessage={
                            <p style={{ textAlign: 'left' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >
                        {
                            props.SearchIndex.startsWith("gitpub-metadata-schema-others-") ?
                                <Table<Partial<DataListItemOthers>>
                                    columns={buildReportColumns()}
                                    itemProvider={new ArrayItemProvider<DataListItemOthers>(reportItemsOthers)}
                                    showLines={true}
                                    pageSize={2000}
                                    containerClassName="msacct-devops-table"
                                    showScroll={false}
                                />
                                :
                                props.SearchIndex.startsWith("gitpub-metadata-schema-policheck-") ?
                                    <Table<Partial<any>>
                                        columns={buildReportColumns()}
                                        itemProvider={new ArrayItemProvider<any>(reportItemsPoliCheck)}
                                        showLines={true}
                                        pageSize={2000}
                                        containerClassName="msacct-devops-table"
                                        showScroll={false}
                                    />
                                    :
                                    <Table<Partial<DataListItem>>
                                        columns={buildReportColumns()}
                                        itemProvider={new ArrayItemProvider<DataListItem>(reportItems)}
                                        showLines={true}
                                        pageSize={2000}
                                        containerClassName="msacct-devops-table"
                                        showScroll={false}
                                    />
                        }

                    </InfiniteScroll>
                </div>

            </PanelContent>
            <PanelFooter showSeparator className="body-m">
                <PrimaryButton
                    style={{ "float": "right", "marginLeft": "15px" }}
                    text="Close"
                    onClick={() => props.onReportDialogDimiss()}
                />
                <DefaultButton
                    style={{ "float": "right" }}
                    text="Export"
                    onClick={() => onExportAlgoReport()}
                />
            </PanelFooter>
        </CustomDialog>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(ViewReport);
