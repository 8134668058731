import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Label } from '@fluentui/react/lib/Label';
import { FontSizes } from '@fluentui/theme';
import { returnType } from "../../Utilities/ReturnType";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Spinner,
  SpinnerSize
} from "@fluentui/react";

import AppLayout from "../AppLayout/AppLayout";

import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import {
  TitleSize,
  CustomHeader,
  HeaderIcon,
  HeaderTitle,
  HeaderTitleArea,
  HeaderTitleRow,
} from "azure-devops-ui/Header";
import { Icon } from "azure-devops-ui/Icon";
import { Card } from "azure-devops-ui/Card";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Link } from "azure-devops-ui/Link";
import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { CurationStartTemplate } from "../../Modules/CurationTemplates/CurationTemplates";
import CurationStartTemplateV2 from "../../Modules/CurationTemplates/CurationTemplatesV2";
import { MSACCT_TITLE } from '../../Utilities/QueryBuilderConstants'
import NavigatePrimaryButton from '../../Controls/NavigatePrimaryButton/NavigatePrimaryButton'
import { getCurationIdByName } from "../../Utilities/CommonFunction";

//import swiper react components and stylesheet
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {
  getWelcomeStats
} from "../../../redux/Actions/PowerDataQuery";

import "./Welcome.css";

const actionCreators = {
  getWelcomeStats
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
  WelcomeStats: state.welcomeStats
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;


class WelcomePage extends Component<StoreProps & DispatchProps> {

  // Defines collapsed status
  private collapsedTemplate = new ObservableValue<boolean>(false);
  private collapsedQuickLinks = new ObservableValue<boolean>(false);
  private collapsedMyCuration = new ObservableValue<boolean>(false);
  private collapsedContentCorpus = new ObservableValue<boolean>(false);
  private collapsedCurationTool = new ObservableValue<boolean>(false);
  private collapsedCurationFilterEditor = new ObservableValue<boolean>(false);
  private sonicSilde1Ref = React.createRef<HTMLDivElement>();
  private sonicSilde2Ref = React.createRef<HTMLDivElement>();
  private sonicSilde3Ref = React.createRef<HTMLDivElement>();
  private _sonicSlideTimer;

  // Defines initial loading state
  state = {
    isLoading: false,
    sonicSlide1Height: "560px",
  }

  /**
   * Call api to load curation data
   */
  async componentDidMount() {
    await this.setState({ isLoading: true }, async () => {
      await this.props.getWelcomeStats()
      this.setState({ isLoading: false })
    })

    // window.addEventListener("resize", this.resizeSildeHeight)
    window.onresize = () => {
      this.resizeSildeHeight()
    }
  }

  /**
   * unmount the resize and timer
   */
  componentWillUnmount() {
    window.onresize = null
    if (this._sonicSlideTimer) {
      clearTimeout(this._sonicSlideTimer)
    }
  }

  /**
   * resize slide2 and slide3's height
   */
  private resizeSildeHeight = () => {
    this._sonicSlideTimer = setTimeout(() => {
      let sonicSlide1Height = 560

      if (this.sonicSilde1Ref && this.sonicSilde1Ref.current) {
        sonicSlide1Height = this.sonicSilde1Ref.current.offsetHeight
      }

      this.setState({
        sonicSlide1Height: `${sonicSlide1Height}px`
      })

    }, 200)
  }


  /**
   * Collapse or expand the Curation Filter Editor
   */
  private onCollapseCurationFilterEditorClicked = () => {
    this.collapsedCurationFilterEditor.value = !this.collapsedCurationFilterEditor.value;
  };

  /**
   * Collapse or expand the "Template" module
   */
  private onCollapseTemplateClicked = () => {
    this.collapsedTemplate.value = !this.collapsedTemplate.value;
  };

  /**
   * Collapse or expand the "Quick Links" module
   */
  private onCollapseQuickLinksClicked = () => {
    this.collapsedQuickLinks.value = !this.collapsedQuickLinks.value;
  };

  /**
   * Collapse or expand the "My Curation" module
   */
  private onCollapseMyCurationClicked = () => {
    this.collapsedMyCuration.value = !this.collapsedMyCuration.value;
  };

  /**
   * Collapse or expand the "Content Corpus" module
   */
  private onCollapseContentCorpusClicked = () => {
    this.collapsedContentCorpus.value = !this.collapsedContentCorpus.value;
  };

  /**
   * Collapse or expand the "Curation Tool" module
   */
  private onCollapseCurationToolClicked = () => {
    this.collapsedCurationTool.value = !this.collapsedCurationTool.value;
  };

  /**
   * Render home icon
   * @param iconName 
   * @returns 
   */
  private renderIcon = (iconName?: string) => {
    return Icon({
      iconName: "Home",
      key: "release-type",
    });
  };

  /**
   * link to open slide
   * @param url 
   * @returns 
   */
  private openExtentralPage = (url: string) => {
    return () => {
      window.open(url)
    }
  }

  /**
   * build the third tab of the slide show
   * @returns 
   */
  renderThirdSlide = (): JSX.Element => {
    return (
      <div className="slide-three" ref={this.sonicSilde3Ref} style={{ "minHeight": "530px", "height": this.state.sonicSlide1Height }} >
        <div className="slide-list">
          <div className="slide-item1" onClick={this.openExtentralPage("https://aka.ms/ACCTUserGuide")}>
            <div className="slide-container">
              <div className="slide-image">
                <img src={require("./slide3-1.png")} alt="Knowledge Automation Overview" />
              </div>
              <div className="slide-text-container">
                <div className="slide-text">
                  <div className="slide-title">KM Automated Content Curation Tool Documentation</div>
                  <div className="slide-subtitle"><a href="https://aka.ms/ACCTUserGuide" target="_blank">Learn more →</a></div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item2" onClick={this.openExtentralPage("https://aka.ms/ACCTTools")}>
            <div className="slide-container">
              <div className="slide-image">
                <img src={require("./slide3-2.png")} alt="Knowledge Automation Overview" />
              </div>
              <div className="slide-text-container">
                <div className="slide-text">
                  <div className="slide-title"><a href="https://aka.ms/ACCTTools" target="_blank">Report a bug</a></div>
                  <div className="slide-subtitle"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item3" onClick={this.openExtentralPage("https://aka.ms/ACCTSupport")}>
            <div className="slide-container">
              <div className="slide-image">
                <img src={require("./slide3-3.png")} alt="Knowledge Automation Overview" />
              </div>
              <div className="slide-text-container">
                <div className="slide-text">
                  <div className="slide-title"><a href="https://aka.ms/ACCTSupport" target="_blank">Make a suggestion </a></div>
                  <div className="slide-subtitle"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item4" onClick={this.openExtentralPage("https://aka.ms/ACCTCurationData?tmpl=G3L2A2")}>
            <div className="slide-container">
              <div className="slide-image">
                <img src={require("./slide3-4.png")} alt="Knowledge Automation Overview" />
              </div>
              <div className="slide-text-container">
                <div className="slide-text">
                  <div className="slide-title"><a href="https://aka.ms/ACCTCurationData?tmpl=G3L2A2" target="_blank">Request a new algorithm</a></div>
                  <div className="slide-subtitle"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item5" onClick={this.openExtentralPage("https://aka.ms/ACCTCurationData?tmpl=KN1R3E")}>
            <div className="slide-container">
              <div className="slide-image">
                <img src={require("./slide3-5.png")} alt="Knowledge Automation Overview" />
              </div>
              <div className="slide-text-container">
                <div className="slide-text">
                  <div className="slide-title"><a href="https://aka.ms/ACCTCurationData?tmpl=KN1R3E" target="_blank">Onboard content curation data</a></div>
                  <div className="slide-subtitle"> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  render() {
    document.title = MSACCT_TITLE + " | Welcome";
    return (
      <AppLayout
        renderContentPane={() => {
          return (
            <Surface background={SurfaceBackground.neutral}>
              <Page className="welcome-page flex-grow">
                <CustomHeader className="bolt-header-with-commandbar">
                  <HeaderTitleArea>
                    <HeaderTitleRow>
                      <HeaderIcon
                        className="Home"
                        iconProps={{ render: this.renderIcon }}
                        titleSize={TitleSize.Large}
                      />
                      <HeaderTitle
                        ariaLevel={3}
                        className="text-ellipsis"
                        titleSize={TitleSize.Large}
                      >
                        About Microsoft CE&S KM Automated Content Curation Tool
                      </HeaderTitle>
                    </HeaderTitleRow>
                  </HeaderTitleArea>
                </CustomHeader>
                <div className="page-content page-content-top">
                  <div className="ms-Grid">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8 sonci-welcome-left">
                        <Swiper
                          slidesPerView={1}
                          spaceBetween={30}
                          autoplay={{
                            delay: 4500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                          }}
                          pagination={{
                            clickable: true,
                          }}
                          rewind={true}
                          navigation={true}
                          modules={[Autoplay, Pagination, Navigation]}
                          className="acct-overviews">
                          <SwiperSlide>
                            <div className="ms-Grid-row content sonic-intro" ref={this.sonicSilde1Ref}>
                              <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7">
                                <div>
                                  <div>
                                    The Automated Content Curation Tool is a project built upon a successful patent submission (now patent pending), which the CE&S Knowledge Management team submitted in 2021. The project aims to enable content leaders to easily create and implement monitoring and alerting algorithms for their content corpora.
                                  </div>
                                </div>
                                <div className="flex-grow">
                                  <p>
                                    The challenge with any content corpus is the manual nature of governing and curating its knowledge assets while simultaneously keeping pace with other strategic and run-time work. Teams often resort to taking Subject Matter Experts (SMEs) away from their work for a week or longer. They are determined to have the SMEs run queries to identify and take action on content that is outdated, unused, inaccurate, etc. Unfortunately, as soon as these SMEs return to their regular work, stagnation, and other negative impacts (negative SEO, poor search results, etc.) inevitably persist. This tool enables content leaders to create and configure automated, report-only monitors that run on the schedule and frequency they choose.
                                  </p>
                                  <div>
                                    These monitoring algorithms enable content leaders to ensure:
                                    <ul>
                                      <li>Knowledge assets don't exist for an extended period without being reviewed for accuracy and relevance. </li>
                                      <li>The articles with zero traffic do not persist indefinitely.</li>
                                      <li>Articles with sub-target PRR, HRR, EAS, or SHS aren't a drag on the performance of the aggregate corpus. </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                                <img
                                  alt="Bars image"
                                  width={"100%"}
                                  src={require("./KnowkedgeAutomationOverview.png")}
                                />
                                <div className="flex-grow">
                                  {" "}
                                  <p />
                                </div>
                                <div>
                                  The tool also support exemption rules (for example, necessary legal topics—despite usage and performance rules applied to other articles). It also includes the ability to target files or collections of files in individual file stores, repos, etc.
                                </div>
                                <div className="flex-grow">
                                  {" "}
                                  <p />
                                </div>
                                { }
                                <p>
                                  We apply this tool across 50,000 English internal-only support articles first. The intent is to connect the tool to your data and file stores as efficiently as possible.
                                </p>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="sonic slide slide2" style={{ "width": "100%", "minHeight": "530px", "height": this.state.sonicSlide1Height }} ref={this.sonicSilde2Ref}>
                              <img style={{ "width": "100%", "height": "100%" }} src={require("./slide2withoutText.png")} alt="Knowledge Automation Overview" />
                              <div className="sonic slide title">Build your algorithms, <br />automate content curation</div>
                              <div className="sonic slide introduce">
                                <div className="sonic slide hint">Know more about Microsoft CE&amp;S KM Automated Content Curation Tool</div>
                                <div className="sonic slide video" id="video-page"><NavigatePrimaryButton buttonText="Watch the Video Now &gt;" jumpPath="https://msit.microsoftstream.com/video/cba20840-98dc-b478-b195-f1ecf1e94790" target="_blank" /></div>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>{this.renderThirdSlide()}</SwiperSlide>
                        </Swiper>
                        <div className="flex-grow">{" "} <br /></div>
                        <Card
                          className="flex-grow"
                          collapsible={true}
                          collapsed={this.collapsedCurationFilterEditor}
                          onCollapseClick={this.onCollapseCurationFilterEditorClicked}
                          titleProps={{ text: "Start from Curation Filter Editor", ariaLevel: 3 }}
                        >
                          <div className="flex-grow content" style={{ paddingBottom: '25px' }}>
                            <CurationStartTemplateV2></CurationStartTemplateV2>
                          </div>
                        </Card>
                        <div className="flex-grow">
                          {" "}
                          <br />
                        </div>
                        <Card
                          className="flex-grow"
                          collapsible={true}
                          collapsed={this.collapsedTemplate}
                          onCollapseClick={this.onCollapseTemplateClicked}
                          titleProps={{ text: "Build your Intelligent Automation from a Template", ariaLevel: 3 }}
                        >
                          <div className="flex-grow content">
                            <CurationStartTemplate></CurationStartTemplate>
                          </div>
                        </Card>

                        <div className="flex-grow">
                          {" "}
                          <br />
                        </div>
                        <div className="flex-grow content" style={{ margin: "25px 0" }}>
                          <div className="ms-Grid">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <p style={{ textAlign: "center", margin: "10px 0 50px" }}>
                                  <Label style={{ fontSize: FontSizes.size20, lineHeight: "120%" }}>See what content leaders are doing with Algorithms Center</Label>
                                </p>
                              </div>
                            </div>
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg6">
                                <div>
                                  <Label style={{ fontSize: FontSizes.size18, lineHeight: '120%' }}>Monitor the lifecycle of products and keep your content fresh</Label>
                                </div>
                                <div>
                                  <p style={{ fontSize: FontSizes.mediumPlus }}>How do you want to archive or refresh articles applied to Retired Support Area Path (SAP) or non-supported products?</p>
                                </div>
                                <div>
                                  <p style={{ fontSize: FontSizes.mediumPlus }}>Product Lifecycle Analyzer with its six algos is built for that.</p>
                                </div>
                                <div>
                                  <NavigatePrimaryButton buttonText="Meet the Product Lifecycle Analyzer family" jumpPath="/algos/productlifecycleanalyzerfamily" />
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <img
                                  alt="Products no longer supported under standard or extended support but should still be alive."
                                  width={"100%"}
                                  src={require("../../Modules/Algorithms/AlgoAD001.png")}
                                />
                              </div>
                            </div>
                            <div className="flex-grow" style={{ borderTop: "1px solid rgb(237, 235, 233)", margin: "45px 0" }}>
                              {" "}
                            </div>

                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg6">
                                <div>
                                  <Label style={{ fontSize: FontSizes.size18, lineHeight: '120%' }}>Monitor the retention status of your Content Retention Policy to ensure GDPR compliance</Label>
                                </div>
                                <div>
                                  <p style={{ fontSize: FontSizes.size14 }}>By only retaining necessary content, you ensure that knowledge is easily accessible for discovery and that it complies with industry regulations such as GDPR. To identify the content that needs to be retained, Content Standards Checkers have been added.
                                    Content Standards Checkers with two algos are built to process product content.
                                  </p>
                                </div>
                                <div>
                                  <p style={{ fontSize: FontSizes.size14 }}>Content Standards Checkers with 2 algos are built for Process, Product content.</p>
                                </div>
                                <div>
                                  <NavigatePrimaryButton buttonText="Meet the Curation Standard Checker family" jumpPath={`/algos/retentioncheckerfamily`} />
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <img
                                  alt="Articles have been out of retention period but still alive."
                                  width={"100%"}
                                  src={require("../../Modules/Algorithms/AlgoAD003.png")}
                                />
                              </div>
                            </div>
                            <div className="flex-grow" style={{ borderTop: "1px solid rgb(237, 235, 233)", margin: "45px 0" }}>
                              {" "}
                            </div>

                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg6">
                                <div>
                                  <Label style={{ fontSize: FontSizes.size18, lineHeight: '120%' }}>Monitor 404 errors to keep you away from annoying dead links</Label>
                                </div>
                                <div>
                                  <p style={{ fontSize: FontSizes.size14 }}>How frequently do customers land on broken links? Where are the broken links?</p>
                                </div>
                                <div>
                                  <p style={{ fontSize: FontSizes.size14 }}>Broken Link Checker is built for that.</p>
                                </div>
                                <div>
                                  <NavigatePrimaryButton buttonText="Try Broken Link Checker" jumpPath={`/algos/brokenlinkchecker?curationId=${getCurationIdByName("Broken Link Checker")}`} />
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <img
                                  alt="Products no longer supported under standard or extended support but should still be alive."
                                  width={"100%"}
                                  src={require("../../Modules/Algorithms/AlgoAD002.png")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow">
                          {" "}
                          <br />
                        </div>

                        <Card
                          className="flex-grow"
                          collapsible={true}
                          collapsed={this.collapsedQuickLinks}
                          onCollapseClick={this.onCollapseQuickLinksClicked}
                          titleProps={{ text: "Quick Links", ariaLevel: 3 }}
                        >
                          <div className="flex-grow content welcome-quick-link">
                            <h3>
                              Getting Started
                            </h3>
                            <div>
                              Guide users through the process of getting their content curation configuration up and running. The following learning resources are available in this section:
                            </div>
                            <ol>
                              <li><Link href="https://aka.ms/ACCTUserGuide" target="_blank">Get started</Link></li>
                              <li><Link href="https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Get-Access.aspx" target="_blank">Get Access</Link></li>
                              <li><Link href="https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Hello-World.aspx" target="_blank">Hello World</Link></li>
                              <li><Link href="https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Automated-content-curation-tool-features.aspx" target="_blank">Automated content curation tool features</Link></li>
                              <ul>
                                <li><Link href="https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Automated-content-curation-tool-features.aspx#curation-filter-editor" target="_blank">Curation Filter Editor</Link></li>
                                <li><Link href="https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Automated-content-curation-tool-features.aspx#intelligent-automation" target="_blank">Intelligent Automation</Link></li>
                                <li><Link href="https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Automated-content-curation-tool-features.aspx#algorithm-center" target="_blank">Algorithm Center</Link></li>
                              </ul>
                              <li><Link href="https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Playground.aspx" target="_blank">Playground with Demo Curation</Link></li>
                              <li><Link href="https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Service-quota-and-constraint.aspx" target="_blank">Service quotas and constraints</Link></li>
                            </ol>
                            <h3>
                              Feedback and Support
                            </h3>
                            <ul>
                              <li><Link href="https://aka.ms/ACCTTools">Report a bug</Link></li>
                              <li><Link href="https://aka.ms/ACCTSupport">Make a suggestion</Link></li>
                            </ul>
                          </div>
                        </Card>
                      </div>
                      <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4 sonci-welcome-right">
                        <Card
                          className="flex-grow"
                          collapsible={true}
                          collapsed={this.collapsedMyCuration}
                          onCollapseClick={this.onCollapseMyCurationClicked}
                          titleProps={{
                            text: "My Curation Stats",
                            ariaLevel: 3,
                          }}
                        >
                          <div className="ms-Grid acctSitestat">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">
                                  Curation Configurations
                                </span>
                                <div className="areaHeader welcome">
                                  <i
                                    className="ms-Icon ms-Icon--FolderList"
                                    aria-hidden="true"
                                  ></i>
                                  {
                                    this.state.isLoading ?
                                      (<Spinner className="count-loading" size={SpinnerSize.xSmall} />)
                                      :
                                      (<NavLink className="areaCount" to="/curations">{this.props.WelcomeStats.CurationCount}</NavLink>)
                                  }
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">Run Jobs (Last 30 days)</span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--TimeEntry"
                                    aria-hidden="true"
                                  ></i>
                                  {
                                    this.state.isLoading ?
                                      (<Spinner className="count-loading" size={SpinnerSize.xSmall} />)
                                      :
                                      (<NavLink className="areaCount" to="/runs">{this.props.WelcomeStats.RunsCount}</NavLink>)
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                        <div className="flex-grow">
                          {" "}
                          <br />
                        </div>
                        <Card
                          className="flex-grow"
                          collapsible={true}
                          collapsed={this.collapsedContentCorpus}
                          onCollapseClick={this.onCollapseContentCorpusClicked}
                          titleProps={{
                            text: "Content Corpus Stats",
                            ariaLevel: 3,
                          }}
                        >
                          <div className="ms-Grid acctSitestat">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">Data Sets</span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--Database"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">2</span>
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">Domain</span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--Website"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">1 (Evergreen)</span>
                                </div>
                              </div>
                              
                            </div>

                            <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">Assets</span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--PageList"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">{this.props.WelcomeStats.AssetCount}</span>
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">Content Sets</span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--HardDriveGroup"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">{this.props.WelcomeStats.RepoCount} Repos</span>
                                </div>
                              </div>                              
                            </div>

                            <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">Languages</span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--LocaleLanguage"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">10</span>
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">Asset Types</span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--GroupList"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">
                                    Support Article
                                  </span>
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"></div>
                            </div>
                          </div>
                        </Card>
                        <div className="flex-grow">
                          {" "}
                          <br />
                        </div>
                        <Card
                          className="flex-grow"
                          collapsible={true}
                          collapsed={this.collapsedCurationTool}
                          onCollapseClick={this.onCollapseCurationToolClicked}
                          titleProps={{
                            text: "Curation Tool Sets",
                            ariaLevel: 6,
                          }}
                        >
                          <div className="ms-Grid acctSitestat">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">
                                  Curation Filter Editor
                                </span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--QueryList"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">
                                    <NavLink to="/queries/edit">Click to query</NavLink>
                                  </span>
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">
                                  Curation Configuration
                                </span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--Settings"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">
                                    <NavLink to="/autocuration">Click to view</NavLink>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">Report It</span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--MailAlert"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">
                                    <a href="https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Automated-content-curation-tool-features.aspx#new-curation">Click to learn</a>
                                  </span>
                                </div>
                              </div>

                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">
                                  Remediate Tickets
                                </span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--Repair"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">
                                    <a href="https://microsoftapc.sharepoint.com/teams/ContentCurationServiceInfo/SitePages/Curation-Configuration-Settings---Create-tickets-for-flagged-articles.aspx">Click to learn</a>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">
                                  Archive It (Zn)
                                </span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--Archive"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">Click to visit</span>
                                </div>
                              </div>

                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">
                                  Unindex It (Zn)
                                </span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--Hide"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">Click to visit</span>
                                </div>
                              </div>
                            </div>
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">Algorithms</span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--DefectSolid"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount"><NavLink to="/algos/productlifecycleanalyzerfamily">13</NavLink></span>
                                </div>
                              </div>
                              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <span className="areaName">Mini APPs</span>
                                <div className="areaHeader">
                                  <i
                                    className="ms-Icon ms-Icon--AppIconDefault"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="areaCount">
                                    1
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </Page>
            </Surface>
          );
        }}
      />
    );
  }
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(WelcomePage);
