import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { connect } from "react-redux";
import {
    DocumentCard,
    DocumentCardTitle,
    DocumentCardDetails,
    DocumentCardImage,
    DocumentCardActions,
    IDocumentCardStyles,
} from '@fluentui/react/lib/DocumentCard';
import { Card } from "azure-devops-ui/Card";
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Label } from '@fluentui/react/lib/Label';
import { Image } from '@fluentui/react/lib/Image';
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { IButtonStyles } from 'office-ui-fabric-react';
import { PillGroup } from "azure-devops-ui/PillGroup";
import { Pill, PillSize, PillVariant } from "azure-devops-ui/Pill";
import { IColor } from "azure-devops-ui/Utilities/Color";
import { FontSizes } from '@fluentui/theme';
import { GlobalDataQuery } from '../../Interface/IPowerQuery'
import { getCurationIdByName } from '../../Utilities/CommonFunction'

import {
    loadAllTaskList
} from "../../../redux/Actions/PowerDataQuery";

import "./ProductLifecycleAnalyzerFamily.css";

const actionCreators = {
    loadAllTaskList
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    allTaskList: state.allTaskList
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;


const cardStyles: IDocumentCardStyles = {
    root: { display: 'inline-block', marginRight: '2%', marginBottom: '3%' },
};

const buttonStyles: IButtonStyles = {
    root: { width: '85%', padding: '15px 0', height: '40px', textAlign: "center", fontSize: '16px' },
};

const documentCardActionswithFavoriteStar = [
    {
        iconProps: { iconName: 'FavoriteStarFill' },
        ariaLabel: 'add action',
    },
];

const cardTitleshouldTruncateStyles: IDocumentCardStyles = {
    root: { fontSize: '16px', paddingTop: '14px' },
};

const brokenlinksIconProps: IIconProps = {
    iconName: 'RemoveLink',
    styles: { root: { color: 'rgb(8, 129, 66)', fontSize: '40px' } },
};

const lightColor: IColor = {
    red: 255,
    green: 206,
    blue: 80,
};

const whiteColor: IColor = {
    red: 255,
    green: 255,
    blue: 255,
};

interface ICurationFollowCount {
    Algo1FollowCount: number,
    Algo2FollowCount: number,
    Algo3FollowCount: number,
    Algo4FollowCount: number,
    Algo5FollowCount: number
}

function BrokenLinkCheckFamily(props: StoreProps & DispatchProps) {
    const history = useNavigate();

    const [followCount, setFollowCount] = useState<ICurationFollowCount>({
        Algo1FollowCount: 0,
        Algo2FollowCount: 0,
        Algo3FollowCount: 0,
        Algo4FollowCount: 0,
        Algo5FollowCount: 0
    })


    useEffect(() => {
        setFollowCount({
            Algo1FollowCount: 0,
            Algo2FollowCount: 0,
            Algo3FollowCount: 0,
            Algo4FollowCount: 0,
            Algo5FollowCount: 0
        })

        props.loadAllTaskList()
        const loadData = async () => {
            // call the api to get the list
            await props.loadAllTaskList();
        }

        loadData()

    }, [])

    useEffect(() => {
        let Algo1FollowCount = getFollowCountById(getCurationIdByName("Broken Link Checker for Evergreen"))
        let Algo2FollowCount = getFollowCountById(getCurationIdByName("Broken Link Checker for AMC"))
        let Algo3FollowCount = getFollowCountById(getCurationIdByName("Broken Link Checker for DMC"))
        let Algo4FollowCount = getFollowCountById(getCurationIdByName("Broken Link Checker for SMC"))
        let Algo5FollowCount = getFollowCountById(getCurationIdByName("Broken Link Checker for Other MS Sites"))

        setFollowCount({
            Algo1FollowCount: Algo1FollowCount,
            Algo2FollowCount: Algo2FollowCount,
            Algo3FollowCount: Algo3FollowCount,
            Algo4FollowCount: Algo4FollowCount,
            Algo5FollowCount: Algo5FollowCount
        })

    }, [props.allTaskList])

    /**
     * 
     * @param curationId 
     * @returns 
     */
    function getFollowCountById(curationId: string) {
        if (props.allTaskList) {
            let curationItem = props.allTaskList.find(t => t.id === curationId)
            if (curationItem) {
                return curationItem.FollowCount
            }
        }

        return 0
    }


    function gotoAlgo(name: string) {

        return (ev: React.MouseEvent<HTMLElement>) => {
            var guid = getCurationIdByName(name)
            switch (name) {

                case "Broken Link Checker for Evergreen":
                    history(`/algos/brokenlinkchecker?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Broken Link Checker for AMC":
                    history(`/algos/brokenlinkcheckeramc?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Broken Link Checker for DMC":
                    history(`/algos/brokenlinkcheckerdmc?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Broken Link Checker for SMC":
                    history(`/algos/brokenlinkcheckersmc?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Broken Link Checker for SXC":
                    history(`/algos/brokenlinkcheckersxc?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                case "Broken Link Checker for Other MS Sites":
                    history(`/algos/brokenlinkcheckerunknown?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;

                default:
                    history(`/algos/productlifecycleanalyzer?curationId=${getCurationIdByName(name)}`)
                    ev.preventDefault();
                    break;
            }
        }
    }


    return (
        <Surface background={SurfaceBackground.normal}>
            <Page className="pipelines-page flex-grow">
                <div className="page-content page-content-top">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <div>
                                    <Label style={{ fontSize: FontSizes.xLarge, lineHeight: '120%' }}>Monitor 404 error data to find the broken links</Label>
                                </div>
                                <p>
                                    <div style={{ fontSize: FontSizes.mediumPlus }}>Do you want to find any broken links in the article?</div>
                                </p>
                                <p>
                                    <div style={{ fontSize: FontSizes.mediumPlus }}>Broken Link Checkers with 5 algos are built for that.</div>
                                </p>

                                <p style={{ paddingTop: '50px' }}>
                                    <Card
                                        className="bolt-card-content"
                                    >
                                        <div>
                                            <h3 style={{ marginTop: '0' }}>Internal support article governance best practices</h3>
                                            <ul>
                                                <li>No broken link exists in article.</li>
                                            </ul>
                                        </div>
                                    </Card>
                                </p>
                            </div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">

                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg5">
                                <Image
                                    width={"100%"}
                                    src={require("./AlgoAD002.png")}
                                    alt='Products no longer supported under standard or extended support but should still be alive.'
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label style={{ fontSize: FontSizes.xLarge, marginBottom: "30px", marginTop: "30px" }}>Explore the Broken Link Checker family</Label>
                            </div>
                        </div>
                        <div className="ms-Grid-row">

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DocumentCard
                                    aria-label={
                                        'Broken Link Checker for Evergreen'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={brokenlinksIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Broken Link Checker for Evergreen" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="Broken links on the referrer from Evergreen content." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Broken Link Checker for Evergreen')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo1FollowCount} />
                                </DocumentCard>

                                <DocumentCard
                                    aria-label={
                                        'Broken Link Checker for AMC'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={brokenlinksIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Broken Link Checker for AMC" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="Broken links on the referrer from AMC content." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Broken Link Checker for AMC')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo2FollowCount} />
                                </DocumentCard>


                                <DocumentCard
                                    aria-label={
                                        'Broken Link Checker for DMC'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={brokenlinksIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Broken Link Checker for DMC" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="Broken links on the referrer from DMC content." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Broken Link Checker for DMC')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo3FollowCount} />
                                </DocumentCard>

                                <DocumentCard
                                    aria-label={
                                        'Broken Link Checker for SMC'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={brokenlinksIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Broken Link Checker for SMC" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="Broken links on the referrer from SMC content." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'> <PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Broken Link Checker for SMC')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo4FollowCount} />
                                </DocumentCard>

                                <DocumentCard
                                    aria-label={
                                        'Broken Link Checker for SXC'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={brokenlinksIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Broken Link Checker for SXC" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="Broken links on the referrer from SXC content." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'> <PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Broken Link Checker for SXC')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo4FollowCount} />
                                </DocumentCard>


                                <DocumentCard
                                    aria-label={
                                        'Broken Link Checker for Other MS Sites'
                                    }
                                    styles={cardStyles}>
                                    <DocumentCardImage height={100} iconProps={brokenlinksIconProps} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title="Broken Link Checker for Other MS Sites" shouldTruncate styles={cardTitleshouldTruncateStyles} />
                                        <DocumentCardTitle title="Broken links on the referrer from Unknown content." shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                    <div className='ViewDetailsBtn'><PrimaryButton text="View details" styles={buttonStyles} onClick={gotoAlgo('Broken Link Checker for Other MS Sites')} /></div>
                                    <DocumentCardActions className='msacct-family-follow' actions={documentCardActionswithFavoriteStar} views={followCount.Algo5FollowCount} />
                                </DocumentCard>

                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <div className="categoryName"> Curation areas:</div>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                <PillGroup className="flex-row">
                                    <Pill size={PillSize.compact} color={lightColor}>
                                        Quality
                                    </Pill>
                                </PillGroup>
                            </div>
                        </div>
                        <br></br>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <div className="categoryName">Tags:</div>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                <PillGroup className="flex-row">
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}>
                                        Broken Links
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}>
                                        Dead Links
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}>
                                        Bad Links
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}>
                                        404 errors
                                    </Pill>
                                </PillGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>

        </Surface>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(BrokenLinkCheckFamily);
