import React, { Component } from "react";
import { connect } from "react-redux";
import SiteInProcess from "../../Modules/SiteInProcess/SiteInProcess";
import { MessageCard, MessageCardSeverity } from "azure-devops-ui/MessageCard";

import "./AppLayout.css"

/**
 * Define the module that needs to be rendered
 * renderBreadcrumb : can be empty
 */
type AppLayoutProps = {
  renderBreadcrumb?: () => JSX.Element;
  renderContentPane: () => JSX.Element;
};

/**
 * used by curation filter Editor page, in this page, we will don't show the scroll
 */
interface ICustomProps {
  containerClassName?: string
}

const sonci_Env = process.env.REACT_APP_SONIC_ENV

/**
 * A basic page structure
 */
class AppLayout extends Component<AppLayoutProps & ICustomProps> {
  private onDismiss = () => {
    return;
  };



  render() {
    const appContainer = this.props.containerClassName ? "appContainer filter" : "appContainer"
    return (
      <div className={appContainer}>
        <div>
          {
            sonci_Env === "prod" || sonci_Env === "dev"  ?
              null :
              <MessageCard
                className="useTerms"
                severity={MessageCardSeverity.Warning}
              >
                {
                  "The content data used on the website is extracted from Automated Content Curation Tool UAT data mart in UDP and is provided only for your trial of the functionality of this website. Do not share a copy of the data. Do not copy/paste data into other files."
                }
              </MessageCard>
          }

          <SiteInProcess />

        </div>
        <div className="appRoot">
          {this.props.renderBreadcrumb && this.props.renderBreadcrumb()}
          {this.props.renderContentPane()}
        </div>
      </div>
    );
  }
}

export default connect<AppLayoutProps>(null, null)(AppLayout);