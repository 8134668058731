export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_SONIC_API_CLIENTID}`,//"4dc139c0-4bc9-495e-8390-a9e6f690319b", // This is the ONLY mandatory field that you need to supply.
    authority: `${process.env.REACT_APP_SONIC_API_AUTHORITY}`, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: `${process.env.REACT_APP_WEBAPP_REDIRECTURL}`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [`${process.env.REACT_APP_SONIC_API_SCOPES}`] //scopes: ["User.Read"]
};
