import { Dropdown } from "azure-devops-ui/Dropdown";
import { DropdownSelection } from "azure-devops-ui/Utilities/DropdownSelection";
import { IListBoxItem } from "azure-devops-ui/ListBox";
import { useEffect, useState } from "react";

interface ICustomDropdownProps {
    priority: number,
    items: Array<IListBoxItem<{}>>,
    currentSelect: string,
    onSelectFun: (text: string, priority: number)=> void
}

export default function CustomDropdown(props: ICustomDropdownProps) {

    const [myItems, setMyItems] = useState(props.items);
    const [dropdownSelection, setDropdownSelection] = useState(new DropdownSelection());

    useEffect(()=>{
        setMyItems(props.items);
        if(props.currentSelect) {
            props.items?.forEach((item,index) => {
                if(item.id === props.currentSelect) {
                    dropdownSelection.select(index)
                }
            })
        }else {
            dropdownSelection.clear();
        }
    },[props.items])

    const selectionChange = (event: React.SyntheticEvent<HTMLElement>, item: IListBoxItem) => {
        props.onSelectFun(item.text, props.priority);
    }

    return(
        <>
            <Dropdown
                ariaLabel="Basic"
                className="example-dropdown"
                placeholder="Select a Config"
                items={myItems}
                onSelect={selectionChange}
                selection={dropdownSelection}
            ></Dropdown>
        </>
    )
}