import React, { Component } from 'react'
import CurationJobs from '../../CurationRuns/CurationJobs'
import AppLayout from "../AppLayout/AppLayout";
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'

const breadcrumbItem: IBreadCrumbExtension[] = [
    { text: 'Home', key: 'Home', href: '/' },
    { text: 'Intelligent Automation', key: 'f4'}
  ];

export default class CurationJobsPage extends Component {
    render() {
      document.title = MSACCT_TITLE + " | " + "Curations Jobs";
        return (
          <AppLayout
            renderBreadcrumb={() =>
              <BreadCrumbExtension items={breadcrumbItem} />
            }
            renderContentPane={() => <CurationJobs />} />
        );
      }
}
