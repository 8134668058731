import React from 'react'
import { Nav, INavLink, INavStyles, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { useNavigate } from "react-router-dom";
import { getCurationIdByName } from "../../Utilities/CommonFunction";
import { useState } from "react"

const navStyles: Partial<INavStyles> = {
    root: {
        overflowY: 'auto',
        height: '100%'
    },
};

const navLinkGroups: INavLinkGroup[] = [
    {
        links: [
            {
                name: 'Home',
                url: '/welcome',
                icon: 'Home',
                key: 'key0',
            },

            {
                name: 'Discover Insights',
                url: '/queries/edit',
                key: 'key4',
                isExpanded: true,
                links: [
                    {
                        name: 'Curation Filter Editor',
                        url: '/queries/edit',
                        icon: 'QueryList',
                        key: 'key4.1',
                    },
                    {
                        name: 'Algorithm Analytics (Cu)',
                        url: '/discoverinsights/algoanalytics',
                        icon: 'AnalyticsView',
                        key: 'key4.3',
                    },

                    {
                        name: 'BI Insights',
                        url: 'https://aka.ms/cesbihub',
                        icon: 'BIDashboard',
                        key: 'key4.2',
                        target: '_blank',
                    }
                ],
            },
            {
                name: 'Intelligent Automation',
                url: '/setting',
                //icon: 'TriggerAuto',
                key: 'key5',
                isExpanded: true,
                links: [
                    {
                        name: 'New Curation',
                        url: '/curations/create',
                        icon: 'Add',
                        key: 'key5.1',
                    },
                    {
                        name: 'Curation Jobs',
                        url: '/runs',
                        icon: 'TimeEntry',
                        key: 'key5.2',
                    },
                    {
                        name: 'All Curations',
                        url: '/curations',
                        icon: 'FolderList',
                        key: 'key5.3',
                    }
                ],
            },
            {
                name: 'Algorithms Center',
                url: '/algos/productlifecycleanalyzerfamily',
                key: 'key6',
                expandAriaLabel: 'Expand section',
                collapseAriaLabel: 'Collapse section',
                links: [
                    {
                        name: 'Product Lifecycle Analyzers',
                        url: '/algos/productlifecycleanalyzerfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key6.1',
                        links: [
                            {
                                name: 'Product Expired Articles',
                                url: '/algos/productexpiredarticlesanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.11',
                            },
                            {
                                name: 'Articles with Empty Products Lifecycle Data',
                                url: '/algos/articleswithemptyproductslifecycledataanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.12',
                            },
                            {
                                name: 'Articles with Partial Expired Products',
                                url: '/algos/articleswithpartialexpiredproductsanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.13',
                            },
                            {
                                name: 'Articles with Outdated SAP Values',
                                url: '/algos/articleswithoutdatedsapvaluesanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.14',
                            },
                            {
                                name: 'Product Expired Articles without Retired SAP Values',
                                url: '/algos/productexpiredarticleswithoutretiredsapvaluesanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.15',
                            },
                            {
                                name: 'Articles with Empty SAP Values',
                                url: '/algos/articleswithemptysapvaluesanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.16',
                            },
                        ]
                    },
                    {
                        name: 'Broken Link Checkers',
                        url: '/algos/brokenlinkcheckerfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key6.2',
                        links: [
                            {
                                name: 'Broken Link Checker for Evergreen',
                                url: '/algos/brokenlinkchecker',
                                icon: 'RemoveLink',
                                key: 'key6.21',
                            },
                            {
                                name: 'Broken Link Checker for AMC',
                                url: '/algos/brokenlinkcheckeramc',
                                icon: 'RemoveLink',
                                key: 'key6.22',
                            },
                            {
                                name: 'Broken Link Checker for DMC',
                                url: '/algos/brokenlinkcheckerdmc',
                                icon: 'RemoveLink',
                                key: 'key6.23',
                            },
                            {
                                name: 'Broken Link Checker for SMC',
                                url: '/algos/brokenlinkcheckersmc',
                                icon: 'RemoveLink',
                                key: 'key6.24',
                            },
                            {
                                name: 'Broken Link Checker for SXC',
                                url: '/algos/brokenlinkcheckersxc',
                                icon: 'RemoveLink',
                                key: 'key6.25',
                            },
                            {
                                name: 'Broken Link Checker for Other MS Sites',
                                url: '/algos/brokenlinkcheckerunknown',
                                icon: 'RemoveLink',
                                key: 'key6.26',
                            }
                        ]
                    },
                    {
                        name: 'Curation Standards',
                        url: '/algos/retentioncheckerfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key6.3',
                        links: [
                            {
                                name: 'Process Article Checker',
                                url: '/algos/retentionofprocess',
                                icon: 'DocumentManagement',
                                key: 'key6.31',
                            },
                            {
                                name: 'Product Article Checker',
                                url: '/algos/retentionofproduct',
                                icon: 'DocumentManagement',
                                key: 'key6.32',
                            },

                            {
                                name: 'Internal Tool Articles',
                                url: '/algos/retentionofinternaltool',
                                icon: 'DocumentManagement',
                                key: 'key6.33',
                            }
                        ]
                    },
                    {
                        name: 'PoliCheck',
                        url: '/algos/policheckfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key6.4',
                        links: [
                            {
                                name: 'PoliCheck for Evergreen',
                                url: '/algos/policheck',
                                icon: 'EntitlementPolicy',
                                key: 'key6.41',
                            },
                            {
                                name: 'PoliCheck for LMC',
                                url: '/algos/polichecklmc',
                                icon: 'EntitlementPolicy',
                                key: 'key6.42',
                            },
                            {
                                name: 'PoliCheck for SMC',
                                url: '/algos/polichecksmc',
                                icon: 'EntitlementPolicy',
                                key: 'key6.43',
                            },
                            {
                                name: 'PoliCheck for SXC',
                                url: '/algos/polichecksxc',
                                icon: 'EntitlementPolicy',
                                key: 'key6.44',
                            }
                        ]
                    },
                ],
                isExpanded: true,
            },
            {
                name: 'Mini APPs',
                url: '',
                key: 'key7',
                isExpanded: false,
                links: [
                    {
                        name: 'Broken Link Checker',
                        url: '',
                        icon: 'RemoveLink',
                        key: 'key7.1'
                    },
                    {
                        name: 'PII Checker',
                        url: '',
                        icon: 'AppIconDefault',
                        key: 'key7.2'
                    },
                    {
                        name: 'PoliCheck',
                        url: '',
                        icon: 'AppIconDefault',
                        key: 'key7.3'
                    }
                ]
            },
            {
                name: 'Data Source',
                url: '',
                key: 'key8',
                isExpanded: false,
                links: [
                    {
                        name: 'Customized articles',
                        url: '/data/custom',
                        icon: 'Database',
                        key: 'key8.1'
                    },
                    {
                        name: 'Customized text',
                        url: '/data/text',
                        icon: 'Database',
                        key: 'key8.2'
                    },
                    {
                        name: 'Knowledge sites',
                        url: '/data/sites',
                        icon: 'Database',
                        key: 'key8.3'
                    }
                ]
            },
            {
                name: 'Data Freshness',
                url: '/algos/productlifecycleanalyzerfamily',
                key: 'key9',
                expandAriaLabel: 'Expand section',
                collapseAriaLabel: 'Collapse section',
                links: [
                    {
                        name: 'Unified Data Platform',
                        url: '/algos/productlifecycleanalyzerfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key9.2',
                        links: [
                            {
                                name: '404 Error Data',
                                url: '/freshness/udp/errordata',
                                icon: 'DatabaseSync',
                                key: 'UnifiedErrorData',
                            },
                            {
                                name: 'Evergreen',
                                url: '/freshness/udp/evergreen',
                                icon: 'DatabaseSync',
                                key: 'UnifiedEvergreen',
                            }
                        ]
                    },
                    {
                        name: 'Content Crawler',
                        url: '/algos/brokenlinkcheckerfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key9.1',
                        links: [
                            {
                                name: 'LMC',
                                url: '/freshness/crawler/lmc',
                                icon: 'Bug',
                                key: 'key9.11',
                            },
                            {
                                name: 'Evergreen',
                                url: '/freshness/crawler/evergreen',
                                icon: 'Bug',
                                key: 'key9.12',
                            },
                            {
                                name: 'AMC',
                                url: '/freshness/crawler/amc',
                                icon: 'Bug',
                                key: 'key9.13',
                            },
                            {
                                name: 'SMC',
                                url: '/freshness/crawler/smc',
                                icon: 'Bug',
                                key: 'key9.14',
                            },
                            {
                                name: 'SXC',
                                url: '/freshness/crawler/sxc',
                                icon: 'Bug',
                                key: 'key9.15'
                            }
                        ]
                    }
                ],
                isExpanded: true,
            }
        ],
    },
];

const navLinkGroups_Prod: INavLinkGroup[] = [
    {
        links: [
            {
                name: 'Home',
                url: '/welcome',
                icon: 'Home',
                key: 'key0',
            },

            {
                name: 'Discover Insights',
                url: '/queries/edit',
                key: 'key4',
                isExpanded: true,
                links: [
                    {
                        name: 'Curation Filter Editor',
                        url: '/queries/edit',
                        icon: 'QueryList',
                        key: 'key4.1',
                    },
                    {
                        name: 'BI Insights',
                        url: 'https://aka.ms/cesbihub',
                        icon: 'BIDashboard',
                        key: 'key4.2',
                        target: '_blank',
                    }
                ],
            },
            {
                name: 'Intelligent Automation',
                url: '/setting',
                //icon: 'TriggerAuto',
                key: 'key5',
                isExpanded: true,
                links: [
                    {
                        name: 'New Curation',
                        url: '/curations/create',
                        icon: 'Add',
                        key: 'key5.1',
                    },
                    {
                        name: 'Curation Jobs',
                        url: '/runs',
                        icon: 'TimeEntry',
                        key: 'key5.2',
                    },
                    {
                        name: 'All Curations',
                        url: '/curations',
                        icon: 'FolderList',
                        key: 'key5.3',
                    }
                ],
            },
            {
                name: 'Algorithms Center',
                url: '/algos/productlifecycleanalyzerfamily',
                key: 'key6',
                expandAriaLabel: 'Expand section',
                collapseAriaLabel: 'Collapse section',
                links: [
                    {
                        name: 'Product Lifecycle Analyzers',
                        url: '/algos/productlifecycleanalyzerfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key6.1',
                        links: [
                            {
                                name: 'Product Expired Articles',
                                url: '/algos/productexpiredarticlesanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.11',
                            },
                            {
                                name: 'Articles with Empty Products Lifecycle Data',
                                url: '/algos/articleswithemptyproductslifecycledataanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.12',
                            },
                            {
                                name: 'Articles with Partial Expired Products',
                                url: '/algos/articleswithpartialexpiredproductsanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.13',
                            },
                            {
                                name: 'Articles with Outdated SAP Values',
                                url: '/algos/articleswithoutdatedsapvaluesanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.14',
                            },
                            {
                                name: 'Product Expired Articles without Retired SAP Values',
                                url: '/algos/productexpiredarticleswithoutretiredsapvaluesanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.15',
                            },
                            {
                                name: 'Articles with Empty SAP Values',
                                url: '/algos/articleswithemptysapvaluesanalyzer',
                                icon: 'Lifesaver',
                                key: 'key6.16',
                            },
                        ]
                    },
                    {
                        name: 'Broken Link Checkers',
                        url: '/algos/brokenlinkcheckerfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key6.2',
                        links: [
                            {
                                name: 'Broken Link Checker for Evergreen',
                                url: '/algos/brokenlinkchecker',
                                icon: 'RemoveLink',
                                key: 'key6.21',
                            },
                            {
                                name: 'Broken Link Checker for AMC',
                                url: '/algos/brokenlinkcheckeramc',
                                icon: 'RemoveLink',
                                key: 'key6.22',
                            },
                            {
                                name: 'Broken Link Checker for DMC',
                                url: '/algos/brokenlinkcheckerdmc',
                                icon: 'RemoveLink',
                                key: 'key6.23',
                            },
                            {
                                name: 'Broken Link Checker for SMC',
                                url: '/algos/brokenlinkcheckersmc',
                                icon: 'RemoveLink',
                                key: 'key6.24',
                            },
                            {
                                name: 'Broken Link Checker for SXC',
                                url: '/algos/brokenlinkcheckersxc',
                                icon: 'RemoveLink',
                                key: 'key6.25',
                            },
                            {
                                name: 'Broken Link Checker for Other MS Sites',
                                url: '/algos/brokenlinkcheckerunknown',
                                icon: 'RemoveLink',
                                key: 'key6.26',
                            }
                        ]
                    },
                    {
                        name: 'Curation Standards',
                        url: '/algos/retentioncheckerfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key6.3',
                        links: [
                            {
                                name: 'Process Article Checker',
                                url: '/algos/retentionofprocess',
                                icon: 'DocumentManagement',
                                key: 'key6.31',
                            },
                            {
                                name: 'Product Article Checker',
                                url: '/algos/retentionofproduct',
                                icon: 'DocumentManagement',
                                key: 'key6.32',
                            },
                            {
                                name: 'Internal Tool Articles',
                                url: '/algos/retentionofinternaltool',
                                icon: 'DocumentManagement',
                                key: 'key6.33',
                            }
                        ]
                    },
                    {
                        name: 'PoliCheck',
                        url: '/algos/policheckfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key6.4',
                        links: [
                            {
                                name: 'PoliCheck for Evergreen',
                                url: '/algos/policheck',
                                icon: 'EntitlementPolicy',
                                key: 'key6.41',
                            },
                            {
                                name: 'PoliCheck for LMC',
                                url: '/algos/polichecklmc',
                                icon: 'EntitlementPolicy',
                                key: 'key6.42',
                            },
                            {
                                name: 'PoliCheck for SMC',
                                url: '/algos/polichecksmc',
                                icon: 'EntitlementPolicy',
                                key: 'key6.43',
                            },
                            {
                                name: 'PoliCheck for SXC',
                                url: '/algos/polichecksxc',
                                icon: 'EntitlementPolicy',
                                key: 'key6.44',
                            }
                        ]
                    },
                ],
                isExpanded: true,
            },
            {
                name: 'Mini APPs',
                url: '',
                key: 'key8',
                isExpanded: false,
                links: [
                    {
                        name: 'Broken Link Checker',
                        url: '',
                        icon: 'RemoveLink',
                        key: 'key8.1'
                    },
                    {
                        name: 'PII Checker',
                        url: '',
                        icon: 'AppIconDefault',
                        key: 'key8.2'
                    },
                    {
                        name: 'PoliCheck',
                        url: '',
                        icon: 'AppIconDefault',
                        key: 'key8.3'
                    }
                ]
            },
            {
                name: 'Data Source',
                url: '',
                key: 'key9',
                isExpanded: false,
                links: [
                    {
                        name: 'Customized articles',
                        url: '/data/custom',
                        icon: 'Database',
                        key: 'key9.1'
                    },
                    {
                        name: 'Customized text',
                        url: '/data/text',
                        icon: 'Database',
                        key: 'key9.2'
                    },
                    {
                        name: 'Knowledge sites',
                        url: '/data/sites',
                        icon: 'Database',
                        key: 'key9.3'
                    }
                ]
            },
            {
                name: 'Data Freshness',
                url: '/algos/productlifecycleanalyzerfamily',
                key: 'key7',
                expandAriaLabel: 'Expand section',
                collapseAriaLabel: 'Collapse section',
                links: [
                    {
                        name: 'Unified Data Platform',
                        url: '/algos/productlifecycleanalyzerfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key7.2',
                        links: [
                            {
                                name: '404 Error Data',
                                url: '/freshness/udp/errordata',
                                icon: 'DatabaseSync',
                                key: 'UnifiedErrorData',
                            },
                            {
                                name: 'Evergreen',
                                url: '/freshness/udp/evergreen',
                                icon: 'DatabaseSync',
                                key: 'UnifiedEvergreen',
                            }
                        ]
                    },
                    {
                        name: 'Content Crawler',
                        url: '/algos/brokenlinkcheckerfamily',
                        expandAriaLabel: 'Expand section',
                        collapseAriaLabel: 'Collapse section',
                        key: 'key7.1',
                        links: [
                            {
                                name: 'LMC',
                                url: '/freshness/crawler/lmc',
                                icon: 'Bug',
                                key: 'key7.11',
                            },
                            {
                                name: 'Evergreen',
                                url: '/freshness/crawler/evergreen',
                                icon: 'Bug',
                                key: 'key7.12',
                            },
                            {
                                name: 'AMC',
                                url: '/freshness/crawler/amc',
                                icon: 'Bug',
                                key: 'key7.13',
                            },
                            {
                                name: 'SMC',
                                url: '/freshness/crawler/smc',
                                icon: 'Bug',
                                key: 'key7.14',
                            },
                            {
                                name: 'SXC',
                                url: '/freshness/crawler/sxc',
                                icon: 'Bug',
                                key: 'key7.15'
                            }
                        ]
                    }
                ],
                isExpanded: true,
            }
        ],
    },
];

const sonci_Env = process.env.REACT_APP_SONIC_ENV

const ACCTSideNav: React.FunctionComponent = () => {
    const history = useNavigate();

    const [selectedKey, setSelectedKey] = useState<string>('key0')

    function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {

        if (item) {
            let itemName = item.name
            if (item.key === "UnifiedEvergreen") {
                itemName = "UnifiedEvergreen"
            }
            setSelectedKey(item.key)
            switch (itemName) {
                case 'Home':
                    history("/welcome");
                    ev.preventDefault();
                    break;

                case 'Intelligent Automation':
                    history("/autocuration");
                    ev.preventDefault();
                    break;

                case 'Discover Insights':
                case 'Curation Filter Editor':
                    history("/queries/edit");
                    ev.preventDefault();
                    break;
                case 'Curation Jobs':
                    history("/runs")
                    ev.preventDefault();
                    break;

                case "All Curations":
                    history("/curations")
                    ev.preventDefault();
                    break
                case "New Curation":
                    history("/curations/create")
                    ev.preventDefault();
                    break
                case "Algorithm Analytics (Cu)":
                    history("/discoverinsights/algoanalytics")
                    ev.preventDefault();
                    break
                case "Product Lifecycle Analyzers":
                    history(`/algos/productlifecycleanalyzerfamily`)
                    ev.preventDefault();
                    break;
                case "Algorithms Center":
                    history(`/algos/productlifecycleanalyzerfamily`)
                    ev.preventDefault();
                    break;
                case "Product Expired Articles":
                    history(`/algos/productexpiredarticlesanalyzer?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Articles with Empty Products Lifecycle Data":
                    history(`/algos/articleswithemptyproductslifecycledataanalyzer?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Articles with Partial Expired Products":
                    history(`/algos/articleswithpartialexpiredproductsanalyzer?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Articles with Outdated SAP Values":
                    history(`/algos/articleswithoutdatedsapvaluesanalyzer?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Product Expired Articles without Retired SAP Values":
                    history(`/algos/productexpiredarticleswithoutretiredsapvaluesanalyzer?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Articles with Empty SAP Values":
                    history(`/algos/articleswithemptysapvaluesanalyzer?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Broken Link Checkers":
                    history(`/algos/brokenlinkcheckerfamily`)
                    ev.preventDefault();
                    break
                // case "Broken Link Checker":
                case "Broken Link Checker for Evergreen":
                    history(`/algos/brokenlinkchecker?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Broken Link Checker for AMC":
                    history(`/algos/brokenlinkcheckeramc?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Broken Link Checker for DMC":
                    history(`/algos/brokenlinkcheckerdmc?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Broken Link Checker for SMC":
                    history(`/algos/brokenlinkcheckersmc?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Broken Link Checker for SXC":
                    history(`/algos/brokenlinkcheckersxc?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Broken Link Checker for Other MS Sites":
                    history(`/algos/brokenlinkcheckerunknown?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "Curation Standards":
                    history(`/algos/retentioncheckerfamily`)
                    ev.preventDefault();
                    break;
                case "Process Article Checker":
                    history(`/algos/retentionofprocess?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break;
                case "Product Article Checker":
                    history(`/algos/retentionofproduct?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break;
                case "Internal Tool Articles":
                    history(`/algos/retentionofinternaltool?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break;
                case "Broken Link Checker":
                    history(`/apps/brokenlinkchecker`)
                    ev.preventDefault();
                    break;
                case "PII Checker":
                    history(`/apps/piichecker`)
                    ev.preventDefault();
                    break;
                case "PoliCheck":
                    history(`/apps/policheck`)
                    ev.preventDefault();
                    break;
                case "LMC":
                    history(`/freshness/crawler/lmc`)
                    ev.preventDefault();
                    break;
                case "AMC":
                    history(`/freshness/crawler/amc`)
                    ev.preventDefault();
                    break;
                case "Evergreen":
                    history(`/freshness/crawler/evergreen`)
                    ev.preventDefault();
                    break;
                case "SMC":
                    history(`/freshness/crawler/smc`)
                    ev.preventDefault();
                    break;
                case "SXC":
                    history(`/freshness/crawler/sxc`)
                    ev.preventDefault();
                    break;
                case "404 Error Data":
                    history(`/freshness/udp/errordata`)
                    ev.preventDefault();
                    break;
                case "UnifiedEvergreen":
                    history(`/freshness/udp/evergreen`)
                    ev.preventDefault();
                    break;
                case "Data Freshness":
                case "Unified Data Platform":
                case "Content Crawler":
                    ev.preventDefault();
                    break;
                case "Customized articles":
                    history(`/data/custom`)
                    ev.preventDefault();
                    break;
                case "Customized text":
                    history(`/data/text`)
                    ev.preventDefault();
                    break;
                case "Knowledge sites":
                    history(`/data/sites`)
                    ev.preventDefault();
                    break;
                case "PoliCheck":
                    history(`/algos/policheckfamily`)
                    ev.preventDefault();
                    break
                case "PoliCheck for Evergreen":
                    history(`/algos/policheck?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "PoliCheck for LMC":
                    history(`/algos/polichecklmc?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "PoliCheck for SMC":
                    history(`/algos/polichecksmc?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                case "PoliCheck for SXC":
                    history(`/algos/polichecksxc?curationId=${getCurationIdByName(item.name)}`)
                    ev.preventDefault();
                    break
                default:
                    break;
            }
        }
    }

    return (
        <Nav
            onLinkClick={_onLinkClick}
            selectedKey={selectedKey}
            ariaLabel="Nav basic example"
            styles={navStyles}
            groups={sonci_Env === "prod" ? navLinkGroups_Prod : navLinkGroups}
        />
    );
};

export default ACCTSideNav;
