import { Table, renderSimpleCell, ITableColumn, SimpleTableCell } from "azure-devops-ui/Table";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Button } from "azure-devops-ui/Button";
import { IPIICheckItem } from '../PIICheckerInterfaces';
import CustomStatus from "../../Modules/CustomStatus/CustomStatus";
import { getUTCDateTime, formatUTCStringDateToDate } from "../../Utilities/CommonFunction";
import './PIICheckerResultTable.css';

interface IPIICheckerResultTableProps  {
    openDialog: Function,
    tableData: IPIICheckItem[]
}

interface ICurrentRow {
    tableItem: IPIICheckItem,
    rowIndex: number
}

export default function PIICheckerResultTable(props: IPIICheckerResultTableProps) {

    function buttonClick(buttonLabel: string, data: ICurrentRow) {
        props.openDialog(buttonLabel,data)
    }

    function checkExpireTime(expireTime: string) {
        if(!expireTime) {
            return true
        }
        const date = formatUTCStringDateToDate(expireTime)
        if(date > getUTCDateTime()) {
            return true
        }
        return false
    }

    function checkUndo(tableItem: IPIICheckItem) { 
        return !tableItem.issueId || !checkExpireTime(tableItem.expireTime) || tableItem.status !== 'Waiting'
    }

    function renderButtonCell(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<IPIICheckItem>,
        tableItem: IPIICheckItem) {
            if(process.env.REACT_APP_SONIC_ENV === 'dev') {
                return (
                    <SimpleTableCell
                        columnIndex={columnIndex}
                        tableColumn={tableColumn}
                        key={tableColumn.id}
                        contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
                    >
                        {
                            !tableItem.issueId &&
                            <Button
                                text="Report as false positive"
                                style={{minWidth: '80px'}}
                                onClick={()=>{buttonClick('Non-PII',{tableItem,rowIndex})}}
                            />
                        }
                        {
                            (tableItem.issueId && !checkExpireTime(tableItem.expireTime)) &&
                            <Button
                                text="Renew"
                                style={{minWidth: '80px'}}
                                onClick={()=>{buttonClick('Renew',{tableItem,rowIndex})}}
                            />
                        }
                        {
                            !checkUndo(tableItem) &&
                            <Button
                                text="Undo"
                                style={{minWidth: '80px'}}
                                onClick={()=>{buttonClick('Undo',{tableItem,rowIndex})}}
                            />
                        }
                    </SimpleTableCell>
                )
            }
            return (
                <SimpleTableCell
                    columnIndex={columnIndex}
                    tableColumn={tableColumn}
                    key={tableColumn.id}
                    contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
                    >
                        {
                            !tableItem.issueId &&
                            <Button
                                text="Report as false positive"
                                style={{minWidth: '80px'}}
                                onClick={()=>{buttonClick('Non-PII',{tableItem,rowIndex})}}
                            />
                        }
                </SimpleTableCell>
            )
    }

    const getRowStatus = (tableItem: IPIICheckItem) => {
        const isExpired = !checkExpireTime(tableItem.expireTime);
        const rowStatus = {
            status: 'Failed',
            message: 'A scanned PII issue.'
        }
        if(tableItem.status === 'Waiting' && !isExpired) {
            rowStatus.status = 'PartialSuccess'
            rowStatus.message = 'False positive is reported successfully! Waiting for approval.'
        }
        if(tableItem.status === 'Passed' && !isExpired) {
            rowStatus.status = 'Success'
            rowStatus.message = 'False positive is approved successfully.'
        }
        if((tableItem.status === 'Passed' || tableItem.status === 'Waiting') && isExpired) {
            rowStatus.status = 'Warning'
            rowStatus.message = 'False positive report is expired and needs to be renewed.'
        }
        return rowStatus
    }

    function renderStatusCell(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<IPIICheckItem>,
        tableItem: IPIICheckItem) {
            const rowStatus = getRowStatus(tableItem)
            return(
                <SimpleTableCell
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                key={tableColumn.id}
                contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
                >
                    <CustomStatus type={rowStatus.status} toolTipMessage={rowStatus.message} className="pii_check_table_status_cell"/>
                </SimpleTableCell>
            )
    }

    /**
     * build data list columns
     * @returns 
     */
    function buildColumns(): any {
        const columns = [
            {
                id: "content",
                name: "PII Entities",
                maxWidth: 1000,
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-120),
            },
            {
                id: "category",
                name: "Categories",
                maxWidth: 500,
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-120),
            },
            {
                id: "status",
                name: "Status",
                maxWidth: 300,
                readonly: true,
                renderCell: renderStatusCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-60),
            },
            {
                id: "context",
                name: "Context",
                maxWidth: 300,
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-250),
            },
            {
                id: "actions",
                name: "Actions",
                maxWidth: 300,
                readonly: true,
                renderCell: renderButtonCell,
                width: new ObservableValue(-80),
            }

        ];

        return columns;
    }

    return (
        <Table<Partial<IPIICheckItem>>
            columns={buildColumns()}
            itemProvider={new ArrayItemProvider<IPIICheckItem>(props.tableData)}
            showLines={true}
            pageSize={2000}
            containerClassName="msacct-devops-table"
            showScroll={false}
        />
    )
}