import React, { Component } from "react";
import AppLayout from "../AppLayout/AppLayout";
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'

import CustomizedText from '../../CustomizedText/CustomizedText'



const breadcrumbItem: IBreadCrumbExtension[] = [
  { text: 'Home', key: 'Home', href: '/' },
  { text: 'Data Source', key: 'ia'},
];

export default class CustomizedTextPage extends Component {
  render() {
    document.title = MSACCT_TITLE + " | " + "Text Search Data Source";
    return (
      <AppLayout
        renderBreadcrumb={() =>
          <BreadCrumbExtension items={breadcrumbItem} />
        }

        renderContentPane={() => <CustomizedText />} />
    )
  }
}