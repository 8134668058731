import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { Pivot, PivotItem } from "@fluentui/react";
import { connect } from "react-redux";
import { Checkbox } from "azure-devops-ui/Checkbox";
import { RadioButton, RadioButtonGroup } from "azure-devops-ui/RadioButton";
import { Separator } from "@fluentui/react/lib/Separator";
import { Stack, IStackTokens } from "@fluentui/react/lib/Stack";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Card } from "azure-devops-ui/Card";
import { Table, renderSimpleCell, ITableColumn, SimpleTableCell } from "azure-devops-ui/Table";
import { MessageCard, MessageCardSeverity } from "azure-devops-ui/MessageCard";
import { TextField, TextFieldWidth } from "azure-devops-ui/TextField";
import { Toggle } from "azure-devops-ui/Toggle";
import { Link } from "@fluentui/react";
import { loadDataQueryIndexes, loadMetadataFields, selectIndex, } from "../../../redux/Actions/PowerDataQuery";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { isSystemUser } from '../../Utilities/CommonFunction'
import { IRemediateIt, RemediateTypes, FixHyperLinksTypes, MergePullRequestTypes, ILinkReplacerMap, IReplacementList } from "../../Interface/IActionFixIt";
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { Status, Statuses, StatusSize } from "azure-devops-ui/Status";
import { getAPIService } from "../../../redux/Actions/PowerDataQuery";
import TextReplacement from './TextReplacement/TextReplacement';
import RichTextEditor from "../../Controls/RichTextEditor/RichTextEditor";
import "./FixIt.css";


const sonci_Env = process.env.REACT_APP_SONIC_ENV;
const apiService = getAPIService().dataSourceService;

interface IRemediateItProps {
  remediateItState: IRemediateIt;
  loginUserAlias: string;
  metadataSchema: string;
  algorithemType: string;
  isNewCuration: boolean;
  textSearchSource: string;
  handleRemediateItClick: (data: IRemediateIt) => void;
  handleRemediateItStateChanged: (data: IRemediateIt) => void;
  handleTicketCustomizationPageButtonClick: () => void;
}

let selectedRemediateItRadioButton = new ObservableValue<string>("");
let selectedFixHyperLinksRadioButton = new ObservableValue<string>("");
// Control ID TextBox
let controlObservable: ObservableValue<string> = new ObservableValue("");
// Standard Requirement  TextBox
let standardRequirmentObservable: ObservableValue<string> = new ObservableValue("");

const stackTokens: IStackTokens = { childrenGap: 12 };

const HorizontalSeparatorStack = (props: { children: JSX.Element[] }) => (
  <>
    {React.Children.map(props.children, (child) => {
      return <Stack tokens={stackTokens}>{child}</Stack>;
    })}
  </>
);

const notificationTableItems = [
  {
    description: "An initial mail is generated",
    type: "Mail",
    notifies: "Reviewer's Manager"
  },
  {
    description: "A review request ticket is out of date",
    type: "Mail",
    notifies: "Reviewer's Manager"
  }
]

const actionCreators = {
  loadDataQueryIndexes,
  loadMetadataFields,
  selectIndex,
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
  allTaskList: state.allTaskList,
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

const TextClawerTemplateForChiron = {
  defaultBestPracticeHtml: `Implement a permanent improvement cycle to ensure article data stays current and accurate. For the more information, please visit the <a href='https://internal.evergreen.microsoft.com/en-us/topic/725b53c6-2331-3a0d-4872-42dd503db8fe'>content policy</a>.`,
  defaultGuidanceHtml: 'Replace the invalid text term (such as expired product name) with the correct one.'
}

const BrokenLinkClawerTemplateForChiron = {
  defaultBestPracticeHtml: `URLs are accessible. For more information, please visit the <a href='https://internal.evergreen.microsoft.com/en-us/topic/725b53c6-2331-3a0d-4872-42dd503db8fe'>content policy</a>.`,
  defaultGuidanceHtml: 'Remove the broken links.'
}



function FixIt(props: IRemediateItProps & DispatchProps) {
  const [selectedKey, setSelectedKey] = useState('AzureDevOps');

  let iLinkReplacerMapArray: ILinkReplacerMap[] = [
    {
      CurrentLink: { href: "" },
      NewLink: { href: "" },
    },
  ];

  function handleLinkClick(item?: PivotItem) {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  }

  /** Initialize FixIT state */
  const [remediateItState, setRemediateItState] = useState<IRemediateIt>({
    RemediateItOrNot: false,
    TextFindAndReplace: {
      ReplaceOrNot: false,
      ReplacementList: []
    },
    RemediateType: RemediateTypes.Update,
    FixHyperLinksOrNot: true,
    FixHyperLinksType: FixHyperLinksTypes.Remove,
    FixHyperLinksReplaceList: iLinkReplacerMapArray,
    MergePullRequestType: MergePullRequestTypes.Manual,
    GeneratedInitialOrNot: false,
    GeneratedOutOfDateRequestOrNot: false,
    MSContentBaseline: {
      EditOrNot: false,
      ControlID: '',
      StandardRequirementURL: '',
      InternalSupportArticle: '',
      RemediationGuidance: '',
    }
  });

  const [findTexts, setFindTexts] = useState<string[]>([])

  const setDefaultEmailSend = (rowIndex: number) => {
    if(rowIndex === 0) {
      return remediateItState.GeneratedInitialOrNot
    }else {
      return remediateItState.GeneratedOutOfDateRequestOrNot
    }
  }

  const notificationColumns = [
    {
      id: "description",
      name: "Description",
      readonly: true,
      renderCell: renderSimpleCell,
      width: new ObservableValue(-300),
    },
    {
      id: "type",
      name: "Type",
      readonly: true,
      renderCell: renderSimpleCell,
      width: new ObservableValue(-100),
    },
    {
      id: "notifies",
      name: "Notifies",
      readonly: true,
      renderCell: renderSimpleCell,
      width: new ObservableValue(-150),
    },
    {
      id: "state",
      name: "State",
      readonly: true,
      renderCell: (
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<any>,
        tableItem: any
      ) => {
        return (
          <SimpleTableCell
            columnIndex={columnIndex}
            key={tableItem.description}
            contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
          >
            <Toggle
              checked={setDefaultEmailSend(rowIndex)}
              onChange={(event, value) => {
                if (rowIndex === 0) {
                  setRemediateItState({
                    ...remediateItState,
                    GeneratedInitialOrNot: value
                  })
                } else {
                  setRemediateItState({
                    ...remediateItState,
                    GeneratedOutOfDateRequestOrNot: value
                  })
                }
              }}
            />
          </SimpleTableCell>
        )
      },
      width: new ObservableValue(-80),
    },
  ]

  // This function handles the click event for the radio buttons that are used to select the type of fix for hyperlinks. The selectedId is the id of the radio button that was clicked.
  const handleFixHyperLinksSelect = (selectedId: string) => {
    // Set the value of the selected radio button.
    selectedFixHyperLinksRadioButton.value = selectedId;

    // Update the state with the selected radio button id.
    setRemediateItState({
      ...remediateItState,
      FixHyperLinksType: selectedId as FixHyperLinksTypes,
    });
  };


  // This function is used to handle the select event of the MergePullRequestType dropdown
  // It sets the MergePullRequestType state to the selectedId
  const handleMergePRSelect = (selectedId: string) => {
    setRemediateItState({
      ...remediateItState,
      MergePullRequestType: selectedId as MergePullRequestTypes,
    });
  };


  // This function is used to handle the radio button click event on the Remediate It page.
  // It is used to set the state of the selected radio button value.
  const onIsRemediateItValueChange = (
    _event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    newValue: boolean
  ) => {
    if (newValue) {
      let myRemediateItState: IRemediateIt = {
        RemediateItOrNot: newValue,
        TextFindAndReplace: remediateItState.TextFindAndReplace,
        RemediateType: selectedRemediateItRadioButton.value as RemediateTypes,
        FixHyperLinksOrNot: newValue,
        FixHyperLinksType: remediateItState.FixHyperLinksType,
        MergePullRequestType: remediateItState.MergePullRequestType,
        GeneratedInitialOrNot: remediateItState.GeneratedInitialOrNot,
        GeneratedOutOfDateRequestOrNot: remediateItState.GeneratedOutOfDateRequestOrNot,
        MSContentBaseline: remediateItState.MSContentBaseline,
        FixHyperLinksReplaceList: remediateItState.FixHyperLinksReplaceList
      };
      props.handleRemediateItClick(myRemediateItState);
    }
    if(props.isNewCuration) {
      setRemediateItState({
        ...remediateItState,
        RemediateItOrNot: newValue,
        GeneratedInitialOrNot: false,
        GeneratedOutOfDateRequestOrNot: true
      });
    }else {
      setRemediateItState({
        ...remediateItState,
        RemediateItOrNot: newValue
      });
    }
  };

  // This function is called when the user clicks on a radio button in the RemediateItType component.
  // The selectedId is the id of the radio button that was clicked.
  // The selectedRemediateItRadioButton is a global variable that is used to keep track of the selected radio button.
  // The remediateItState variable is a global variable that is used to keep track of the state of the RemediateIt component.
  // The RemediateType state variable is used to keep track of which radio button is selected in the RemediateIt component.
  const handleRemediateItTypeValueChanged = (selectedId: string) => {
    selectedRemediateItRadioButton.value = selectedId;
    setRemediateItState({
      ...remediateItState,
      RemediateType: selectedId as RemediateTypes,
    });
  };

  const handleReplaceToggleChange = (_event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    newValue: boolean) => {
    const newTextFindAndReplace = { ...remediateItState.TextFindAndReplace }
    newTextFindAndReplace.ReplaceOrNot = newValue
    setRemediateItState({
      ...remediateItState,
      TextFindAndReplace: newTextFindAndReplace,
    });
  }

  /**
 * When the user clicks the "Ticket Customization" button, this function
 * is called. This function calls the handleTicketCustomizationPageButtonClick
 * function from the parent component.
 */
  const onClickedTicketCustomizationPageButton = () => {
    props.handleTicketCustomizationPageButtonClick();
  };

  // The following function is used to update the state of the 'FixHyperLinksOrNot' property in the 'remediateItState' object.
  // The 'FixHyperLinksOrNot' property is used to keep a track of whether the user has selected the 'Fix 404 hyperlinks' option or not.
  // If the user has selected the 'Fix 404 hyperlinks' option, the value of the 'FixHyperLinksOrNot' property will be set to 'true'.
  // If the user has not selected the 'Fix 404 hyperlinks' option, the value of the 'FixHyperLinksOrNot' property will be set to 'false'.
  // The 'newValue' parameter is used to get the new value of the 'FixHyperLinksOrNot' property (true or false).
  // The 'setRemediateItState' function is used to set the new value of the 'FixHyperLinksOrNot' property in the 'remediateItState' object.
  const onIsFix404HyperlinksValueChange = (
    _event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    newValue: boolean
  ) => {
    setRemediateItState({
      ...remediateItState,
      FixHyperLinksOrNot: newValue,
    });
  };

  useEffect(() => {
    // set the default value
    if (props) {
      initBaseData();
    }
  }, []);

  useEffect(()=>{
    if(props.isNewCuration) {
      setRemediateItState({
        ...props.remediateItState,
        MSContentBaseline: setDefaultContentBaseline()
      })
    }
  },[props.algorithemType])

  const setDefaultContentBaseline = () => {
    let template = {
      defaultBestPracticeHtml: '',
      defaultGuidanceHtml: ''
    }
    if(['TextCrawler','TextFinderAndReplacer'].includes(props.algorithemType)) {
      template = TextClawerTemplateForChiron
    }
    if(props.algorithemType === 'BrokenLinkCrawler') {
      template = BrokenLinkClawerTemplateForChiron
    }
    const { defaultBestPracticeHtml, defaultGuidanceHtml } = template
    const msContentBaseline = {
      EditOrNot: false,
      ControlID: '',
      StandardRequirementURL: '',
      InternalSupportArticle: defaultBestPracticeHtml,
      RemediationGuidance: defaultGuidanceHtml,
    }
    return msContentBaseline
  }

  function initBaseData() {
    // Actions for Remediation
    if (props.remediateItState) {
      if (!props.remediateItState.FixHyperLinksReplaceList) {
        props.remediateItState.FixHyperLinksReplaceList = iLinkReplacerMapArray;
      }
      setRemediateItState(props.remediateItState);
    }
  }

  useEffect(() => {
    if (props.textSearchSource) {
      apiService.getFindTextsConfig(props.textSearchSource).then(configItems => {
        setFindTexts(configItems?.data)
      })
    }
  }, [props.textSearchSource])

  useEffect(() => {
    props.handleRemediateItStateChanged(remediateItState);
  }, [remediateItState]);

  const canUseReplacement = (): boolean => {
    const filterAlgorithemTypes = ['TextCrawler', 'TextFinderAndReplacer', 'PoliCheckCrawler', 'PIIScrubber']
    if (filterAlgorithemTypes.includes(props.algorithemType)
      && props.metadataSchema.startsWith('gitpub-metadata-schema-evergreen-')) {
      return true
    }
    return false
  }

  const handleTextFindAndReplaceChange = (newList: IReplacementList[]) => {
    const newTextFindAndReplace = { ...remediateItState.TextFindAndReplace }
    newTextFindAndReplace.ReplacementList = newList
    setRemediateItState({
      ...remediateItState,
      TextFindAndReplace: newTextFindAndReplace
    })
  }

  const onControlIdChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
    controlObservable.value = newValue;
    setRemediateItState({
      ...remediateItState,
      MSContentBaseline: {
        ...remediateItState.MSContentBaseline,
        ControlID: newValue
      }
    })
  }

  const onStandardRequirementChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
    standardRequirmentObservable.value = newValue;
    setRemediateItState({
      ...remediateItState,
      MSContentBaseline: {
        ...remediateItState.MSContentBaseline,
        StandardRequirementURL: newValue
      }
    })
  }

  const onInternalSupportArticleChange = (content: string) => {
    setRemediateItState({
      ...remediateItState,
      MSContentBaseline: {
        ...remediateItState.MSContentBaseline,
        InternalSupportArticle: content
      }
    })
  }

  const onRemediationGuidanceChange = (content: string) => {
    setRemediateItState({
      ...remediateItState,
      MSContentBaseline: {
        ...remediateItState.MSContentBaseline,
        RemediationGuidance: content
      }
    })
  }

  return (
    <div className="clearfix">
      {!isSystemUser(props.loginUserAlias) ? null : (
        <Stack tokens={stackTokens}>
          <HorizontalSeparatorStack>
            <Separator></Separator>
            <div>
              <Checkbox
                onChange={onIsRemediateItValueChange}
                checked={remediateItState.RemediateItOrNot}
                label="Remediate it for each identified article."
              />
              <div style={{ marginLeft: "30px" }}>
                <RadioButtonGroup
                  onSelect={handleRemediateItTypeValueChanged}
                  selectedButtonId={remediateItState.RemediateType}
                >
                  <RadioButton
                    id="Update"
                    text="Update it"
                    key="Update"
                    disabled={!remediateItState.RemediateItOrNot}
                  />
                  {/* <RadioButton
                    id="UnIndex"
                    text="Unindex it"
                    key="UnIndex"
                    disabled={!remediateItState.RemediateItOrNot}
                  />
                  <RadioButton
                    id="Delete"
                    text="Delete it"
                    key="Delete"
                    disabled={!remediateItState.RemediateItOrNot}
                  /> */}
                </RadioButtonGroup>
                <MessageCard
                  className="flex-self-stretch"
                  severity={MessageCardSeverity.Info}
                >
                  When any option above is chosen, a ticket (ADO work item)
                  will automatically be generated at the conclusion of the
                  event to document all automated actions taken. To customize
                  your ticket template, click
                  <Link style={{ marginLeft: '5px' }} onClick={onClickedTicketCustomizationPageButton}>
                    {"edit the work item template"}
                  </Link>
                  .
                </MessageCard>
              </div>
            </div>

            {remediateItState.RemediateItOrNot && (
              <div style={{ paddingBottom: '10px' }}>
                <Separator>Advanced</Separator>
                <div>
                  {
                    canUseReplacement() ?
                      <div>
                        <div className="replace-product-name-check-box">
                          Replace text
                          <TooltipHost className='fixit_tooltip_list' calloutProps={{ gapSpace: 0 }}
                            tooltipProps={{
                              onRenderContent: () => {
                                return (
                                  <div className="fixit_tooltip_list_content">
                                    In the "Replace with" box, enter your new text. It will replace the text while ignoring case. Maximum support for input is 10 texts.
                                  </div>
                                );
                              }
                            }}
                          >
                            <Status
                              {...Statuses.Information}
                              key="information"
                              size={StatusSize.s}
                              className="status-example flex-self-center fixit_tooltip_information"
                            />
                          </TooltipHost>
                        </div>
                        <Card className="replace-product-name-content" contentProps={{ contentPadding: true }}>
                          <TextReplacement
                            active={true}
                            replacementList={remediateItState.TextFindAndReplace?.ReplacementList}
                            dropdownItems={findTexts}
                            handleReplaceListChange={handleTextFindAndReplaceChange}
                          />
                        </Card>
                      </div> :
                      <div className="settingSection">
                        <Checkbox
                          onChange={onIsFix404HyperlinksValueChange}
                          checked={remediateItState.FixHyperLinksOrNot}
                          disabled={
                            !remediateItState.RemediateItOrNot ||
                            remediateItState.RemediateType !==
                            RemediateTypes.Update
                          }
                          label="Fix hyperlinks with 404 HTTP status code"
                        />
                        <div style={{ marginLeft: "30px" }}>
                          <RadioButtonGroup
                            onSelect={handleFixHyperLinksSelect}
                            selectedButtonId={remediateItState.FixHyperLinksType}
                          >
                            {/* <RadioButton
                            id="CommentOut"
                            text="Comment out the address"
                            key="CommentOut"
                            disabled={
                              !remediateItState.RemediateItOrNot ||
                              !remediateItState.FixHyperLinksOrNot ||
                              remediateItState.RemediateType !==
                              RemediateTypes.Update
                            }
                          /> */}
                            <RadioButton
                              id="Remove"
                              text="Remove the address"
                              key="Remove"
                              disabled={
                                !remediateItState.RemediateItOrNot ||
                                !remediateItState.FixHyperLinksOrNot ||
                                remediateItState.RemediateType !==
                                RemediateTypes.Update
                              }
                            />
                            {/* <RadioButton
                            id="Replace"
                            text="Find and Replace the address"
                            key="Replace"
                            disabled={
                              !remediateItState.RemediateItOrNot ||
                              !remediateItState.FixHyperLinksOrNot ||
                              remediateItState.RemediateType !==
                              RemediateTypes.Update
                            }
                          /> */}
                          </RadioButtonGroup>
                          {!(
                            !remediateItState.RemediateItOrNot ||
                            !remediateItState.FixHyperLinksOrNot ||
                            remediateItState.RemediateType !==
                            RemediateTypes.Update ||
                            remediateItState.FixHyperLinksType !==
                            FixHyperLinksTypes.Replace
                          ) && (
                              <div style={{ marginLeft: "30px" }}>
                                <div>Find address</div>
                                <TextField
                                  prefixIconProps={{
                                    render: (className) => (
                                      <span className={className}></span>
                                    ),
                                  }}
                                  value={
                                    remediateItState.FixHyperLinksReplaceList
                                      ? remediateItState.FixHyperLinksReplaceList[0]
                                        .CurrentLink.href
                                      : ""
                                  }
                                  onChange={(e, newValue) => (
                                    (iLinkReplacerMapArray =
                                      remediateItState.FixHyperLinksReplaceList),
                                    (iLinkReplacerMapArray[0].CurrentLink.href =
                                      newValue),
                                    setRemediateItState({
                                      ...remediateItState,
                                      FixHyperLinksReplaceList:
                                        iLinkReplacerMapArray,
                                    })
                                  )}
                                  width={TextFieldWidth.auto}
                                />
                                <div>Replace with</div>{" "}
                                <TextField
                                  prefixIconProps={{
                                    render: (className) => (
                                      <span className={className}></span>
                                    ),
                                  }}
                                  value={
                                    remediateItState.FixHyperLinksReplaceList[0]
                                      .NewLink.href
                                  }
                                  onChange={(e, newValue) => (
                                    (iLinkReplacerMapArray =
                                      remediateItState.FixHyperLinksReplaceList),
                                    (iLinkReplacerMapArray[0].NewLink.href =
                                      newValue),
                                    setRemediateItState({
                                      ...remediateItState,
                                      FixHyperLinksReplaceList:
                                        iLinkReplacerMapArray,
                                    })
                                  )}
                                  width={TextFieldWidth.auto}
                                />
                              </div>
                            )}
                          {!(
                            !remediateItState.RemediateItOrNot ||
                            !remediateItState.FixHyperLinksOrNot ||
                            remediateItState.RemediateType !==
                            RemediateTypes.Update ||
                            remediateItState.FixHyperLinksType !==
                            FixHyperLinksTypes.CommentOut
                          ) && (
                              <MessageCard severity={MessageCardSeverity.Info}>
                                <ul>
                                  <li>
                                    Before commenting out -{" "}
                                    <a href="https://contentcuration.microsoft.com">
                                      This is a link
                                    </a>
                                  </li>
                                  <li>
                                    After remediating - This is a link
                                    (https://contentcuration.microsoft.com)
                                  </li>
                                </ul>
                              </MessageCard>
                            )}
                          {!(
                            !remediateItState.RemediateItOrNot ||
                            !remediateItState.FixHyperLinksOrNot ||
                            remediateItState.RemediateType !==
                            RemediateTypes.Update ||
                            remediateItState.FixHyperLinksType !==
                            FixHyperLinksTypes.Remove
                          ) && (
                              <MessageCard severity={MessageCardSeverity.Info}>
                                <ul>
                                  <li>
                                    Before removing -{" "}
                                    <a href="https://contentcuration.microsoft.com">
                                      This is a link
                                    </a>
                                  </li>
                                  <li>After remediating - This is a link</li>
                                </ul>
                              </MessageCard>
                            )}
                        </div>
                      </div>
                  }

                  <div className="settingSection" style={{display: 'none'}}>
                    <Separator></Separator>
                    <div>
                      <div>
                        <TextField
                          value={controlObservable}
                          onChange={onControlIdChange}
                          placeholder="Type control id"
                          width={TextFieldWidth.standard}
                          label="Control ID:"
                          maxLength={50}
                        />
                      </div>
                      <br />
                      <div>
                        <TextField
                          value={standardRequirmentObservable}
                          onChange={onStandardRequirementChange}
                          placeholder="Type standard requirement"
                          width={TextFieldWidth.standard}
                          label="Microsoft Content Standard Requirement:"
                          maxLength={3000}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="settingSection">
                    <Separator>Content Baseline</Separator>
                    <div>
                      <div>
                        <div className="content-baseline-title">Internal support article governance best practices:</div>
                        <RichTextEditor DefaultValue={remediateItState?.MSContentBaseline?.InternalSupportArticle} ToolBarOptions={['list', 'link']} onContentChange={onInternalSupportArticleChange} />
                      </div>
                      <br />
                      <div>
                        <div className="content-baseline-title">Remediation guidance:</div>
                        <RichTextEditor DefaultValue={remediateItState?.MSContentBaseline?.RemediationGuidance} ToolBarOptions={['list', 'link']} onContentChange={onRemediationGuidanceChange} />
                      </div>
                    </div>
                  </div>

                  <Pivot
                    aria-label="Override Selected Item Pivot Example"
                    selectedKey={selectedKey}
                    onLinkClick={handleLinkClick}
                  >
                    <PivotItem
                      headerText="Azure DevOps (Evergreen)"
                      itemKey="AzureDevOps"
                    >
                      <div>
                        <br />
                        <RadioButtonGroup
                          text={"Merge pull requests"}
                          onSelect={handleMergePRSelect}
                          selectedButtonId={
                            remediateItState.MergePullRequestType
                          }
                        >
                          <RadioButton
                            id="Manual"
                            text="Manually"
                            key="Manual"
                            disabled={!remediateItState.RemediateItOrNot}
                          />
                        </RadioButtonGroup>

                      </div>
                    </PivotItem>
                    {/* <PivotItem headerText="Github (Learn)" itemKey="GitHub">
                      <div className="settingSection">
                        <RadioButtonGroup
                          text={"Merge pull requests"}
                          onSelect={handleMergePRSelect}
                          selectedButtonId={
                            remediateItState.MergePullRequestType
                          }
                        >
                          <RadioButton
                            id="Manual"
                            text="Manually"
                            key="Manual"
                            disabled={!remediateItState.RemediateItOrNot}
                          />
                          <RadioButton
                            id="Automatic"
                            text="Automatically (This option is only available for repos with auto-merge feature enabled)"
                            key="Automatic"
                            disabled={!remediateItState.RemediateItOrNot}
                          />
                        </RadioButtonGroup>
                      </div>
                    </PivotItem>
                    <PivotItem headerText="Web API (SXC, AMC)" itemKey="WebAPI">
                      <>
                        <div>
                        </div>
                      </>
                    </PivotItem> */}
                  </Pivot>
                </div>
                <Separator>Notification</Separator>
                <Card className="flex-grow bolt-table-card" contentProps={{ contentPadding: false }}>
                  <Table
                    ariaLabel="Basic Table"
                    columns={notificationColumns}
                    itemProvider={new ArrayItemProvider(notificationTableItems)}
                    role="table"
                    className="table-example"
                    containerClassName="h-scroll-auto"
                  />
                </Card>
              </div>
            )}
          </HorizontalSeparatorStack>
        </Stack>
      )}
    </div>
  );
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(FixIt);
