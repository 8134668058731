import { Link } from "azure-devops-ui/Link";
import { ITableColumn, SimpleTableCell, Table, renderSimpleCell } from "azure-devops-ui/Table";
import { DataListItem, DataListItemOthers, DataListItemPoliCheck } from '../Interface/IDataResult'
import { ContentItem, IssueCenterContentItem } from "../Interface/IPowerQuery";
import { formatDateTime, getDefaultDateTime } from './CommonFunction'
import { IQueryExpressions } from "../Interface/IQueryExpressions";
import { QueryExpression } from "../Utilities/QueryBuilderUtils";
import {
  SELECT_STRING_OTHERS,
  SELECT_STRING_404LINKS,
  SELECT_STRING_POLICHECK,
  SELECT_STRING,
  SELECT_STRING_TextSearch,
  SELECT_CustomizedArticles_STRING,
  SELECT_CustomizedArticles_STRING_TextSearch,
  SELECT_LMC_STRING,
  SELECT_LMC_STRING_TextSearch,
  SELECT_PoliCheck_Aggregated_Data,
  SELECT_SMC_STRING,
  SELECT_SMC_STRING_BrokenLinkCrawler,
  SELECT_SMC_STRING_TextSearch,
  SELECT_SXC_STRING,
  SELECT_SXC_STRING_BrokenLinkCrawler,
  SELECT_SXC_STRING_TextSearch,
  SELECT_CSS_WIKI_DATA,
  SELECT_SONIC_MEDIA_DATA,
  SELECT_SONIC_ISSUE_CENTER_DATA
} from './QueryBuilderConstants'

/**
 * Evergreen: gitpub-metadata-schema-evergreen
 * SMC: gitpub-metadata-schema-smc
 * LMC: gitpub-metadata-schema-lmc
 * SXC: gitpub-metadata-schema-sxc
 * AMC: gitpub-metadata-schema-amc
 * 404 Errors: gitpub-metadata-schema-others
 * PoliCheck: gitpub-metadata-schema-policheck
 * Customized articles: gitpub-metadata-schema-customizedarticles
 * PoliCheck Aggregated Data: gitpub-metadata-schema-policheckaggregateddata
 * AzureKB Search: AzureKBAPI
 * Get query fields by metadataSchema
 * @param metadataSchema dataset
 * @returns Query fields
 */
export function getCurationQueryFields(metadataSchema: string, algoType: string = 'Blank') {
  // 404 Errors: gitpub-metadata-schema-others
  if (metadataSchema?.startsWith("gitpub-metadata-schema-others-")) {
    return SELECT_STRING_OTHERS;
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-404links-")) {
    return SELECT_STRING_404LINKS;
  }
  // PoliCheck: gitpub-metadata-schema-policheck
  // Full-Text Search: AzureKBAPI
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-policheck-") || metadataSchema.startsWith("AzureKBAPI")) {
    return SELECT_STRING_POLICHECK
  }
  // Customized articles: gitpub-metadata-schema-customizedarticles
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-customizedarticles-")) {
    if (algoType === 'PoliCheckCrawler' || algoType === "TextCrawler") {
      return SELECT_CustomizedArticles_STRING_TextSearch
    }
    else {
      return SELECT_CustomizedArticles_STRING
    }
  }
  // PoliCheck Aggregated Data: gitpub-metadata-schema-policheckaggregateddata
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-policheckaggregateddata-")) {
    return SELECT_PoliCheck_Aggregated_Data
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-csswiki-")) {
    return SELECT_CSS_WIKI_DATA
  }
  // learn.microsoft.com (LMC): gitpub-metadata-schema-lmc
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-lmc-")) {
    if (algoType === 'PoliCheckCrawler' || algoType === "TextCrawler") {
      return SELECT_LMC_STRING_TextSearch
    }
    else {
      return SELECT_LMC_STRING
    }
  }
  // support.microsoft.com (SMC): gitpub-metadata-schema-smc-
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-smc-")) {
    if (algoType === 'PoliCheckCrawler' || algoType === "TextCrawler") {
      return SELECT_SMC_STRING_TextSearch
    }
    else if (algoType === 'BrokenLinkCrawler') {
      return SELECT_SMC_STRING_BrokenLinkCrawler
    }
    else {
      return SELECT_SMC_STRING
    }
  }
  // support.xbox.com (SXC): gitpub-metadata-schema-sxc-
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-sxc-")) {
    if (algoType === 'PoliCheckCrawler' || algoType === "TextCrawler") {
      return SELECT_SXC_STRING_TextSearch
    }
    else if (algoType === 'BrokenLinkCrawler') {
      return SELECT_SXC_STRING_BrokenLinkCrawler
    }
    else {
      return SELECT_SXC_STRING
    }
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-media-")) {
    return SELECT_SONIC_MEDIA_DATA
  }
  else if (metadataSchema?.startsWith("issue-center-")) {
    return SELECT_SONIC_ISSUE_CENTER_DATA
  }
  else {
    // Evergreen: gitpub-metadata-schema-evergreen
    if (["PoliCheckCrawler", "TextCrawler", "PIIScrubber", "TextFinderAndReplacer"].includes(algoType)) {
      return SELECT_STRING_TextSearch
    }
    else {
      return SELECT_STRING
    }    
  }
  
}

function buildMediaResultData(contents: ContentItem[] | IssueCenterContentItem[], indexName: string) {
  const items = []
  switch(indexName) {
    case "Image Data":
      contents.forEach(item => {
        item.HTMLAsset?.Images.forEach(image => {
          if(image.Statistics?.HTTPCodeStatus === 'NotFound') {
            items.push({
              ArticleUrl: item.URL,
              ImageUrl: image.FullPath,
              ImageAlt: image.Alt,
              ImageTitle: image.Title
            })
          }
        })
      })
      break
    case "Image Aggregated Data":
      contents.forEach(item => {
        let badImageCount = 0
        item.HTMLAsset?.Images.forEach(image => {
          if(image.Statistics?.HTTPCodeStatus === 'NotFound') {
            badImageCount++
          }
        })
        if(badImageCount > 0) {
          items.push({
            ArticleUrl: item.URL,
            ImageCount: badImageCount
          })
        }
      })
      break
    case "Image Assets":
      contents.forEach(item => {
        item.HTMLAsset?.Images.forEach(image => {
          items.push({
            ArticleUrl: item.URL,
            ImageUrl: image.FullPath,
            ImageAlt: image.Alt,
            ImageTitle: image.Title
          })
        })
      })
      break
    case "Video Data":
      break
    case "Video Aggregated Data":
      break
  }
  return items
}

/**
 * Build table items
 * @param contents content from API
 * @param metadataSchema metadataSchema
 * @param pageFrom 
 * @returns 
 */
export function buildCurationTableItems(contents: ContentItem[] | IssueCenterContentItem[], metadataSchema: string, pageFrom: string, indexName: string) {
  let items: any[] = []
  if (metadataSchema?.startsWith("gitpub-metadata-schema-others-")) {
    contents.map(item => {
      items.push({
        ReferrerLink: item.ReferrerLink?.toString(),
        ReferrerType: item.ReferrerType?.toString(),
        ReferrerDomain: item.ReferrerDomain?.toString(),
        ReferrerID: item.ReferrerID?.toString(),
        ReferrerOwner: item.ReferrerOwner?.toString(),
        NoOfBrokenLinks: item.NoOfBrokenLinks?.toString(),
        HasBrokenLinks: item.HasBrokenLinks?.toString(),
        PgDomainList: item.PgDomainList?.toString(),
        ErrorPage404List: item.ErrorPage404List?.toString(),
        BrokenLinkClicks: item.BrokenLinkClicks?.toString(),
        ArticleVisits: item.ArticleVisits?.toString(),
        BrokenLinkDetectedTime: item.BrokenLinkDetectedTime?.toString(),
        RepoNameList: item.RepoNameList?.toString(),
        DataSource: item.DataSource?.toString(),
      });
    })
  }
  if (metadataSchema?.startsWith("gitpub-metadata-schema-404links-")) {
    contents.map(item => {
      items.push({
        Link: item.Link?.toString(),
        Domain: item.Domain?.toString(),
        BrokenLinkDetectedTime: item.BrokenLinkDetectedTime?.toString(),
        NoOfArticles: item.NoOfArticles?.toString(),
      });
    })
  }
  else if (metadataSchema.startsWith("AzureKBAPI")) {
    contents.map(item => {
      items.push({
        Link: item.Link?.toString(),
        Title: item.Title?.toString(),
        Locale: item.Locale?.toString(),
        Description: item.Description?.toString(),
        Domain: item.Domain?.toString(),
        MatchScore: item.MatchScore?.toString(),
      });
    });
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-policheck-") || metadataSchema.startsWith("AzureKBAPI")) {
    contents.map(item => {
      items.push({
        Link: item.Link?.toString(),
        Locale: item.Locale?.toString(),
        Domain: item.Domain?.toString(),
        Text: item.Text?.toString(),
        Context: item.Context?.toString(),
        MatchScore: item.MatchScore?.toString(),
        Guidelines: item.Guidelines?.toString(),
        Why: item.Why?.toString(),
      });
    });
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-csswiki-")) {
    contents.map(item => {
      items.push({
        Title: item.Title?.toString(),
        Organization: item.Organization?.toString(),
        ProjectName: item.ProjectName?.toString(),
        LiveUrl: item.LiveUrl?.toString()
      });
    });
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-customizedarticles-")) {
    contents.map(item => {
      items.push({
        DataSourceName: item.DataSourceName?.toString(),
        DataSourceType: item.DataSourceType?.toString(),
        Link: item.Link?.toString(),
        NoOfBrokenLinks: item.NoOfBrokenLinks?.toString(),
        HasBrokenLinks: item.HasBrokenLinks?.toString(),
        BrokenLinkDetectedTime: item.BrokenLinkDetectedTime?.toString(),
        PgDomainList: item.PgDomainList?.toString(),
        ErrorPage404List: item.ErrorPage404List?.toString(),
        // VisitCount: item.VisitCount?.toString(),
        // PageViewCount: item.PageViewCount?.toString()
      });
    });
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-lmc-")) {
    contents.map(item => {
      items.push({
        ContentId: item.ContentId?.toString(),
        Title: item.Title?.toString(),
        RepoName: item.RepoName?.toString(),
        Locale: item.Locale?.toString(),
        LiveUrl: item.LiveUrl?.toString(),
        LastPublished: formatDateTime(item.LastPublished?.toString()),
        MsData: formatDateTime(item.ms_date?.toString()),
        Visits: item.Visitors?.toString()
      });
    });
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-smc-") || metadataSchema?.startsWith("gitpub-metadata-schema-sxc-")) {
    contents.map(item => {
      items.push({
        Locale: item.Locale?.toString(),
        Link: item.Link?.toString()
      });
    });
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-policheckaggregateddata-")) {
    contents.map(item => {
      items.push({
        Link: item.Link?.toString(),
        Locale: item.Locale?.toString(),
        Domain: item.Domain?.toString(),
        ShortDomain: item.ShortDomain?.toString(),
        WriterName: item.WriterName?.toString(),
        ArticleCreator: item.ArticleCreator?.toString(),
        ArticlePublisher: item.ArticlePublisher?.toString(),
        AssignedTo: item.AssignedTo?.toString(),
        PortfolioOwner: item.PortfolioOwner?.toString(),
        NoOfPoliCheckIssues: item.NoOfPoliCheckIssues?.toString(),
        NoOfTerm: item.NoOfTerm?.toString(),
        NoOfPageViews: item.NoOfPageViews?.toString(),
        NoOfArticleVisits: item.NoOfArticleVisits?.toString(),
        NoOfEngagedVisits: item.NoOfEngagedVisits?.toString(),
      });
    });
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-media-")) {
    items = buildMediaResultData(contents, indexName)
  }
  else if (metadataSchema?.startsWith("issue-center-")) {
    contents.forEach(item => {
      items.push({
        IssueText: item.Content,
        ArticleURL: item.ArticleURL,
        Context: item.Context,
        RiskArea: item.RiskArea,
        SourceAPP: item.SourceApp,
        ExceptionType: item.ExceptionType,
        Reporter: item.Reporter,
        ReportTime: item.ReportTime,
        Comment: item.Comment
      })
    })
  }
  else {

    if (pageFrom === "DataQueryResult") {
      contents.map(item => {
        var sapName = item.SapName?.toString()
          .replaceAll("\\", "")
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("\"", "")
          .replaceAll("\\", "")

        var pageViewMonthAll = ""
        if (item.NoOfPageViews30days &&
          item.NoOfPageViews30days?.toString() !== '' &&
          item.NoOfPageViews &&
          item.NoOfPageViews?.toString() !== '') {

          pageViewMonthAll = item.NoOfPageViews30days + "/" +
            item.NoOfPageViews
        }

        items.push({
          ContentId: item.ContentID,
          LocaleCode: item.Locale,
          Title: item.PageTitle,
          PublishOn: item.LastPublishDate?.toString(),
          CreatedOn: item.ArticleCreatedDate?.toString(),
          PageView: item.NoOfPageViews?.toString(),
          PageViewMonth: item.NoOfPageViews30days?.toString(),
          PageViewAll: item.NoOfPageViews?.toString(),
          PageViewMonthAll: pageViewMonthAll,
          BrokenLinkCount: item.NoOfBrokenLinks?.toString(),
          RepoName: item.RepositoryName,
          SAPName: sapName,
          LiveUrl: item.URL,
          ErrorPage404List: item.ErrorPage404List?.toString(),
          AgeMonth: item.AgeMonth?.toString(),
          RefreshAgeMonth: item.RefreshAgeMonth?.toString(),
          CountOfInternalReferences: item.NoOfInternalReferences ? item.NoOfInternalReferences.toString() : "0"
        });
      });
    }
    else if (pageFrom === "QueryPreview") {
      contents.map(item => {
        var sapName = item.SapName?.toString()
          .replaceAll("\\", "")
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("\"", "")
          .replaceAll("\\", "")

        let pageViewMonthAll = "0/0"
        if (item.NoOfPageViews30days && item.NoOfPageViews) {
          pageViewMonthAll = item.NoOfPageViews30days + "/" +
            item.NoOfPageViews
        }
        else if (item.NoOfPageViews30days) {
          pageViewMonthAll = item.NoOfPageViews30days + "/0"
        }
        else if (item.NoOfPageViews) {
          pageViewMonthAll = "0/" +
            item.NoOfPageViews
        }

        var articleAge = "0/0";
        if (item.RefreshAgeMonth && item.AgeMonth) {
          articleAge = item.RefreshAgeMonth + "/" + item.AgeMonth
        }
        else if (item.RefreshAgeMonth) {
          articleAge = item.RefreshAgeMonth + "/0"
        }
        else if (item.AgeMonth) {
          articleAge = "0/" + item.AgeMonth
        }

        items.push({
          ContentId: item.ContentID,
          LocaleCode: item.Locale,
          Title: item.PageTitle,
          PublishOn: item.LastPublishDate?.toString(),
          CreatedOn: item.ArticleCreatedDate?.toString(),
          PageView: item.NoOfPageViews?.toString(),
          PageViewMonth: item.NoOfPageViews30days?.toString(),
          NoOfPageViews7days: item.NoOfPageViews7days?.toString(),
          NoOfPageViews30days: item.NoOfPageViews30days?.toString(),
          NoOfPageViews90days: item.NoOfPageViews90days?.toString(),
          NoOfPageViews360days: item.NoOfPageViews360days?.toString(),
          PageViewAll: item.NoOfPageViews?.toString(),
          PageViewMonthAll: pageViewMonthAll,
          BrokenLinkCount: item.NoOfBrokenLinks?.toString(),
          HasBrokenLinks: item.HasBrokenLinks?.toString(),
          RepoName: item.RepositoryName,
          SAPName: sapName,
          LiveUrl: item.URL,
          AgeMonth: item.AgeMonth?.toString(),
          RefreshAgeMonth: item.RefreshAgeMonth?.toString(),
          CountOfInternalReferences: item.NoOfInternalReferences ? item.NoOfInternalReferences.toString() : "0",
          ArticleAge: articleAge,
          ErrorPage404List: item.ErrorPage404List?.toString(),

          SapStatus: item.SapStatus?.toString(),
          MainstreamDate: formatDateTime(item.MainstreamDate?.toString()),
          ExtendedEndDate: formatDateTime(item.ExtendedEndDate?.toString()),
          RetirementDate: formatDateTime(item.RetirementDate?.toString()),
          ReleaseEndDate: formatDateTime(item.ReleaseEndDate?.toString()),
          MainStreamRemainDays: item.MainStreamRemainDays?.toString(),
          ExtendedEndRemainDays: item.ExtendedEndRemainDays?.toString(),
          RetirementRemainDays: item.RetirementRemainDays?.toString(),
          ReleaseEndRemainDays: item.ReleaseEndRemainDays?.toString(),
          ArticleArchivingRemainingDays: item.ArticleArchivingRemainingDays?.toString(),

          AvgPageViewsDay: item.AvgPageViewsDay?.toString(),
          AvgPageViewsMonth: item.AvgPageViewsMonth?.toString(),
          NoOfUniqueVisitors: item.NoOfUniqueVisitors?.toString(),
          PercentageOfEngagedRate: item.PercentageOfEngagedRate?.toString(),
          PercentageOfHRR: item.PercentageOfHRR?.toString(),
          RevisionNumber: item.RevisionNumber?.toString()
        });
      });
    }

  }
  return items;
}


/**
 * Build columns by metadataSchema
 * @param metadataSchema dataset
 * @param pageFrom DataQueryResult/QueryPreview
 * @returns Columns
 */
export function buildCurationQueryColumns(metadataSchema: string, pageFrom: string, indexName: string): any {
  // 404 Errors: gitpub-metadata-schema-others
  if (metadataSchema?.startsWith("gitpub-metadata-schema-others-")) {
    const columns = [
      {
        id: "ReferrerLink",
        name: "Referrer Link",
        readonly: true,
        renderCell: renderReferrerLinkCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 300,
      },
      {
        id: "ReferrerType",
        name: "Referrer Type",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 100,
      },
      {
        id: "ReferrerDomain",
        name: "Referrer Domain",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 120,
      },
      {
        id: "ReferrerID",
        name: "Referrer ID",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 300,
      },
      {
        id: "ReferrerOwner",
        name: "Referrer Owner",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 120,
      },
      {
        id: "NoOfBrokenLinks",
        name: "# of BrokenLinks",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 120,
      },
      {
        id: "HasBrokenLinks",
        name: "Has BrokenLink Or Not",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 150,
      },
      {
        id: "PgDomainList",
        name: "PgDomain List",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 150,
      },
      {
        id: "ErrorPage404List",
        name: "ErrorPage404 List",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 300,
      },
      {
        id: "BrokenLinkClicks",
        name: "# of BrokenLink Clicks",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "ArticleVisits",
        name: "# of Article Visits",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 120,
      },
      {
        id: "BrokenLinkDetectedTime",
        name: "BrokenLink Detected Time",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 175,
      },
      {
        id: "RepoNameList",
        name: "Repo Name List",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 150,
      },
      {
        id: "DataSource",
        name: "Data Source",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 100,
      },
    ];

    return columns;
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-404links-")) {
    const columns = [
      {
        id: "Link",
        name: "Link",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 800,
      },
      {
        id: "Domain",
        name: "Domain",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 150,
      },
      {
        id: "BrokenLinkDetectedTime",
        name: "BrokenLink Detected Time",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 300,
      },
      {
        id: "NoOfArticles",
        name: "# of Articles with Broken Link",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 200,
      },
    ];

    return columns;
  }
  // PoliCheck: gitpub-metadata-schema-policheck
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-policheck-")) {
    const columns = [
      {
        id: "Link",
        name: "Link",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 300,
      },
      {
        id: "Locale",
        maxWidth: 100,
        name: "Locale",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 100,
      },
      {
        id: "Domain",
        maxWidth: 100,
        name: "Domain",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 100,
      },
      {
        id: "Text",
        name: "Text",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 150,
      },
      {
        id: "Context",
        name: "Context",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 300,
      },
      {
        id: "MatchScore",
        name: "Match Score",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 150,
      },
      {
        id: "Guidelines",
        name: "Guidelines",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 300,
      },
      {
        id: "Why",
        name: "Why",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 300,
      }
    ];

    return columns;
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-csswiki-")) {
    return [
      {
        id: "Title",
        name: "Title",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 350,
      },
      {
        id: "ProjectName",
        name: "Project",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 350,
      },
      {
        id: "Organization",
        name: "Organization",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 350,
      },
      {
        id: "LiveUrl",
        name: "LiveUrl",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 550,
      }
    ]
  }
  // Customized articles: gitpub-metadata-schema-customizedarticles
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-customizedarticles-")) {
    const columns = [
      {
        id: "DataSourceName",
        name: "Data source name",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 300,
      },
      {
        id: "DataSourceType",
        maxWidth: 100,
        name: "Data source type",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "Link",
        name: "Link",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 350,
      },
      {
        id: "HasBrokenLinks",
        name: "HasBrokenLinks",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 120,
      },
      {
        id: "ErrorPage404List",
        maxWidth: 500,
        name: "ErrorPage404List",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 500,
      }
    ];

    return columns;
  }
  else if (metadataSchema?.startsWith("AzureKBAPI")) {
    const columns = [
      {
        id: "Link",
        name: "Link",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 500,
      },
      {
        id: "Title",
        name: "Title",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 400,
      },
      {
        id: "Locale",
        maxWidth: 100,
        name: "Locale",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 100,
      },
      {
        id: "Domain",
        maxWidth: 100,
        name: "Domain",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 100,
      },
      {
        id: "Description",
        name: "Description",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 500,
      },
      {
        id: "MatchScore",
        name: "Match Score",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 150,
      }
    ];

    return columns;
  }
  // PoliCheck: gitpub-metadata-schema-policheck
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-policheckaggregateddata-")) {
    const columns = [
      {
        id: "Link",
        name: "Link",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 300,
      },
      {
        id: "Locale",
        maxWidth: 100,
        name: "Locale",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 100,
      },
      // {
      //   id: "Domain",
      //   maxWidth: 100,
      //   name: "Domain",
      //   readonly: true,
      //   renderCell: renderSimpleCell,
      //   sortProps: {
      //     ariaLabelAscending: "Sorted low to high",
      //     ariaLabelDescending: "Sorted high to low",
      //   },
      //   width: 100,
      // },
      {
        id: "ShortDomain",
        maxWidth: 100,
        name: "Short Domain",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 100,
      },
      {
        id: "WriterName",
        maxWidth: 150,
        name: "Writer Name",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "ArticleCreator",
        maxWidth: 150,
        name: "Article Creator",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "ArticlePublisher",
        maxWidth: 150,
        name: "Article Publisher",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "AssignedTo",
        maxWidth: 150,
        name: "Assigned To",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "PortfolioOwner",
        maxWidth: 150,
        name: "Portfolio Owner",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "NoOfPoliCheckIssues",
        maxWidth: 150,
        name: "# of PoliCheck Issues",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "NoOfTerm",
        maxWidth: 150,
        name: "# of Term",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "NoOfPageViews",
        maxWidth: 150,
        name: "# of Page Views",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "NoOfArticleVisits",
        maxWidth: 150,
        name: "# of Article Visits",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      },
      {
        id: "NoOfEngagedVisits",
        maxWidth: 150,
        name: "# of Engaged Visits",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 150,
      }
    ];

    return columns;
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-lmc-")) {
    const columns = [
      {
        id: "ContentId",
        name: "ContentId",
        readonly: true,
        renderCell: renderContentIdCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 400,
      },
      {
        id: "Title",
        name: "Title",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: 500,
      },
      {
        id: "Locale",
        maxWidth: 100,
        name: "Locale",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 100,
      },
      {
        id: "RepoName",
        maxWidth: 100,
        name: "Repo Name",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 250,
      },
      {
        id: "LastPublished",
        maxWidth: 100,
        name: "Last Published",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 200,
      },
      {
        id: "MsData",
        maxWidth: 100,
        name: "MsData",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 200,
      },
      {
        id: "Visits",
        maxWidth: 100,
        name: "Visits",
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: 100,
      }
    ];

    return columns;
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-smc-") || metadataSchema?.startsWith("gitpub-metadata-schema-sxc-")) {
    const columns = [
      {
        id: "Link",
        maxWidth: 1000,
        name: "Link",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 1000,
      },
      {
        id: "Locale",
        maxWidth: 500,
        name: "Locale",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 500,
      }
    ];

    return columns;
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-media-")) {
    if(indexName === 'Image Aggregated Data') {
      return [
        {
          id: "ArticleUrl",
          maxWidth: 1500,
          name: "Article Url",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 1300,
        },
        {
          id: "ImageCount",
          maxWidth: 400,
          name: "Image Count",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        }
      ]
    }
    return [
      {
        id: "ArticleUrl",
        maxWidth: 1000,
        name: "Article Url",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 600,
      },
      {
        id: "ImageUrl",
        maxWidth: 1000,
        name: "Image Url",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 600,
      },
      {
        id: "ImageAlt",
        maxWidth: 400,
        name: "Image Alt",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 200,
      },
      {
        id: "ImageTitle",
        maxWidth: 400,
        name: "Image Title",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 200,
      }
    ]
  }
  else if (metadataSchema?.startsWith("issue-center-")) {
    return [
      {
        id: "IssueText",
        maxWidth: 1000,
        name: "Issue Text",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 600,
      },
      {
        id: "ArticleURL",
        maxWidth: 1000,
        name: "Article URL",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 600,
      },
      {
        id: "Context",
        maxWidth: 600,
        name: "Context",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 300,
      },
      {
        id: "RiskArea",
        maxWidth: 300,
        name: "Risk Area",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 150,
      },
      {
        id: "SourceAPP",
        maxWidth: 300,
        name: "Source APP",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 150,
      },
      {
        id: "ExceptionType",
        maxWidth: 300,
        name: "Exception Type",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 150,
      },
      {
        id: "Reporter",
        maxWidth: 600,
        name: "Reporter",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 300,
      },
      {
        id: "ReportTime",
        maxWidth: 600,
        name: "Report Time",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 300,
      },
      {
        id: "Comment",
        maxWidth: 1000,
        name: "Comment",
        readonly: true,
        renderCell: renderSimpleCell,
        width: 500,
      }
    ]
  }
  else {
    // Evergreen: gitpub-metadata-schema-evergreen
    // SMC: gitpub-metadata-schema-smc
    // DMC: gitpub-metadata-schema-dmc
    // SXC: gitpub-metadata-schema-sxc
    // SSC: gitpub-metadata-schema-ssc
    // Answers: gitpub-metadata-schema-answers
    // Alchemy/Emerald: gitpub-metadata-schema-ae
    // PVA: gitpub-metadata-schema-pva
    // Common Content Solution: gitpub-metadata-schema-ccs
    // CSS Wiki: gitpub-metadata-schema-css
    let columns: any = null;
    if (pageFrom === 'DataQueryResult') {
      columns = [
        {
          id: "ContentId",
          name: "Content Id",
          readonly: true,
          renderCell: renderContentIdCell,
          sortProps: {
            ariaLabelAscending: "Sorted A to Z",
            ariaLabelDescending: "Sorted Z to A",
          },
          width: 300,
        },
        {
          id: "Title",
          name: "Title",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted A to Z",
            ariaLabelDescending: "Sorted Z to A",
          },
          width: 300,
        },
        {
          id: "LocaleCode",
          maxWidth: 300,
          name: "Locale",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 100,
        },
        {
          id: "CountOfInternalReferences",
          name: "# of Referrers",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 150,
        },
        {
          id: "PageViewMonthAll",
          name: "# of Page Views (1 Month/Total)",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
        {
          id: "BrokenLinkCount",
          maxWidth: 300,
          name: "# of Broken Links",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 200,
        },
        {
          id: "ErrorPage404List",
          name: "ErrorPage404 List",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 300,
        },
        {
          id: "AgeMonth",
          maxWidth: 300,
          name: "Age (M)",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 100,
        },
        {
          id: "RefreshAgeMonth",
          maxWidth: 300,
          name: "Refresh Age (M)",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 150,
        },
        {
          id: "RepoName",
          maxWidth: 300,
          name: "Repos Name",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 200,
        },
        {
          id: "SAPName",
          maxWidth: 300,
          name: "SAP Name",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 200,
        }
      ];
    }
    else if (pageFrom === 'QueryPreview') {
      columns = [
        {
          id: "ContentId",
          name: "Content Id",
          readonly: true,
          renderCell: renderContentIdCell,
          sortProps: {
            ariaLabelAscending: "Sorted A to Z",
            ariaLabelDescending: "Sorted Z to A",
          },
          width: 300,
        },
        {
          id: "LocaleCode",
          maxWidth: 300,
          name: "Locale",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 100,
        },
        {
          id: "ArticleArchivingRemainingDays",
          name: "Article Archiving Remaining Days",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 250,
        },
        {
          id: "SapStatus",
          name: "SAP State",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 100,
        },
        {
          id: "Title",
          maxWidth: 300,
          name: "Title",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 200,
        },
        {
          id: "PageViewAll",
          maxWidth: 300,
          name: "# of PageViews (All)",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 200,
        },
        {
          id: "NoOfPageViews360days",
          maxWidth: 300,
          name: "# of PageViews (1Y)",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 200,
        },
        {
          id: "NoOfPageViews90days",
          maxWidth: 300,
          name: "# of PageViews (1Q)",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 200,
        },
        {
          id: "NoOfPageViews30days",
          maxWidth: 300,
          name: "# of PageViews (1M)",
          readonly: true,
          renderCell: renderSimpleCell,
          sortProps: {
            ariaLabelAscending: "Sorted low to high",
            ariaLabelDescending: "Sorted high to low",
          },
          width: 200,
        },
        {
          id: "RefreshAgeMonth",
          name: "# of Months Since Creation",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 250,
        },
        {
          id: "AgeMonth",
          name: "# of Months Since Last Publish",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 250,
        },
        {
          id: "AvgPageViewsMonth",
          name: "AvgPageViews/Mon",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 250,
        },
        {
          id: "NoOfUniqueVisitors",
          name: "# of Unique Visitors",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 250,
        },
        {
          id: "PercentageOfEngagedRate",
          name: "Engaged Rate%",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 250,
        },
        {
          id: "PercentageOfHRR",
          name: "HRR%",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 250,
        },
        {
          id: "RevisionNumber",
          name: "Revision Number",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 250,
        },
        {
          id: "RepoName",
          name: "Repos Name",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
        {
          id: "SAPName",
          name: "SAP Name",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
        {
          id: "ErrorPage404List",
          name: "ErrorPage404 List",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 300,
        },
        {
          id: "MainStreamRemainDays",
          name: "Mainstream Remaining Days",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
        {
          id: "ExtendedEndRemainDays",
          name: "Extended End Remaining Days",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
        {
          id: "RetirementRemainDays",
          name: "Retirement Remaining Days",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
        {
          id: "ReleaseEndRemainDays",
          name: "Release End Remaining Days",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
        {
          id: "MainstreamDate",
          name: "Mainstream Date",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
        {
          id: "ExtendedEndDate",
          name: "Extended End Date",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
        {
          id: "RetirementDate",
          name: "Retirement Date",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
        {
          id: "ReleaseEndDate",
          name: "Release End Date",
          readonly: true,
          renderCell: renderSimpleCell,
          width: 200,
        },
      ];
    }


    return columns;
  }
}

/**
   * Render ContentId cell
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
function renderReferrerLinkCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<DataListItemOthers>,
  tableItem: DataListItemOthers) {
  const { ReferrerLink } = tableItem

  return (
    <SimpleTableCell
      columnIndex={columnIndex}
      tableColumn={tableColumn}
      key={"col-" + columnIndex}
      contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
    >
      {
        ReferrerLink && ReferrerLink.toString() !== '' ?
          (
            <Link
              className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
              excludeTabStop
              href={ReferrerLink}
              target={"_blank"}
            >
              {ReferrerLink}
            </Link>
          )
          :
          (
            <div className="flex-row scroll-hidden">
              {ReferrerLink}
            </div>
          )
      }

    </SimpleTableCell>
  );
}

/**
   * Render ContentId cell
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
function renderContentIdCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<DataListItem>,
  tableItem: DataListItem) {
  const { ContentId, LiveUrl } = tableItem

  return (
    <SimpleTableCell
      columnIndex={columnIndex}
      tableColumn={tableColumn}
      key={"col-" + columnIndex}
      contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
    >
      {
        LiveUrl && LiveUrl.toString() !== '' ?
          (
            <Link
              className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
              excludeTabStop
              href={LiveUrl}
              target={"_blank"}
            >
              {ContentId}
            </Link>
          )
          :
          (
            <div className="flex-row scroll-hidden">
              {ContentId}
            </div>
          )
      }

    </SimpleTableCell>
  );
}

// #region convert expression condition to json

/**
 * build query expression condition
 */
export function buildConditionJson(children: QueryExpression[]) {
  let expList: IQueryExpressions[] = [];
  for (var i = 0; i < children.length; i++) {
    const node = children[i];
    if (node.children) {
      expList.push({
        id: node.id,
        group: false,
        andOr: null,
        field: null,
        fieldType: null,
        operator: null,
        value: null,
        children: buildConditionChildrenJson(node.children),
      });
    } else {
      expList.push({
        id: node.id,
        group: node.group,
        andOr: node.andOr,
        field: node.field,
        fieldType: node.fieldType,
        operator: node.operator,
        value: node.value,
      });
    }
  }

  return expList;
};

/**
 * build children expression condition
 * @param childNode
 * @returns
 */
function buildConditionChildrenJson(childNode: IQueryExpressions[]) {
  let currentChildren: IQueryExpressions[] = [];
  for (let i = 0; i < childNode.length; i++) {
    let node = childNode[i];
    if (node.children) {
      currentChildren.push({
        id: node.id,
        group: false,
        andOr: null,
        field: null,
        fieldType: null,
        operator: null,
        value: null,
        children: buildConditionChildrenJson(node.children),
      });
    } else {
      currentChildren.push({
        id: node.id,
        group: node.group,
        andOr: node.andOr,
        field: node.field,
        fieldType: node.fieldType,
        operator: node.operator,
        value: node.value,
      });
    }
  }

  return currentChildren;
};
// #endregion

/**
 * Set editor box default value
 * @param metadataSchema 
 * @param pageFrom 
 * @param appendExpression 
 * @param onUpdateGroupingCheckbox 
 * @param groupRows 
 * @param removeExpression 
 */
export function setCurationFieldsDefault(
  metadataSchema: string,
  pageFrom: string,
  templateName: string,
  appendExpression: Function,
  onUpdateGroupingCheckbox: Function,
  groupRows: Function,
  removeExpression: Function) {

  if (metadataSchema?.startsWith("gitpub-metadata-schema-others-")) {
    const expoHasBrokenLinks = new QueryExpression();
    expoHasBrokenLinks.field = "HasBrokenLinks";
    expoHasBrokenLinks.fieldType = "boolean";
    expoHasBrokenLinks.operator = "=";
    expoHasBrokenLinks.value = "true";
    appendExpression(1, expoHasBrokenLinks);

    const expoDomain = new QueryExpression();
    expoDomain.andOr = "And";
    expoDomain.field = "ReferrerDomain";
    expoDomain.fieldType = "string";
    expoDomain.operator = "=";
    expoDomain.value = "SMC";
    appendExpression(2, expoDomain);
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-404links-")) {

    const expoDomain = new QueryExpression();
    expoDomain.field = "Domain";
    expoDomain.fieldType = "string";
    expoDomain.operator = "=";
    expoDomain.value = "SMC";
    appendExpression(1, expoDomain);
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-policheck-") || metadataSchema?.startsWith("gitpub-metadata-schema-policheckaggregateddata-")) {
    const expoLcale = new QueryExpression();
    expoLcale.field = "Locale";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "en-us";
    appendExpression(1, expoLcale);
  }
  else if (metadataSchema?.startsWith("AzureKBAPI")) {
    const expoLcale = new QueryExpression();
    expoLcale.field = "Locale";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "en-us";
    appendExpression(1, expoLcale);

    const expoProductFamily = new QueryExpression();
    expoProductFamily.andOr = "And";
    expoProductFamily.field = "ProductFamily";
    expoProductFamily.fieldType = "string";
    expoProductFamily.operator = "=";
    expoProductFamily.value = "Azure";
    appendExpression(2, expoProductFamily);

    const expoClientName = new QueryExpression();
    expoClientName.andOr = "And";
    expoClientName.field = "SearchEngine";
    expoClientName.fieldType = "string";
    expoClientName.operator = "=";
    expoClientName.value = "AzureKB";
    appendExpression(3, expoClientName);

    const expoContent = new QueryExpression();
    expoContent.andOr = "And";
    expoContent.field = "Content";
    expoContent.fieldType = "fulltext";
    expoContent.operator = "=";
    expoContent.value = " ";
    appendExpression(4, expoContent);
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-csswiki-")) {
    const expoLcale = new QueryExpression();
    expoLcale.field = "Organization";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "Supportability";
    appendExpression(1, expoLcale);
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-customizedarticles-")) {
    const expoLcale = new QueryExpression();
    expoLcale.field = "DataSourceType";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "LinkScan";
    appendExpression(1, expoLcale);
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-lmc-") || metadataSchema?.startsWith("gitpub-metadata-schema-smc-") || metadataSchema?.startsWith("gitpub-metadata-schema-sxc-")) {
    const expoLcale = new QueryExpression();
    expoLcale.field = "Locale";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "en-us";
    appendExpression(1, expoLcale);
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-media-")) {
    const expoLcale = new QueryExpression();
    expoLcale.field = "Locale";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "en-us";
    appendExpression(1, expoLcale);
  }
  else if (metadataSchema?.startsWith("issue-center-")) {
    const expoRiskArea = new QueryExpression();
    expoRiskArea.field = "RiskArea";
    expoRiskArea.fieldType = "string";
    expoRiskArea.operator = "=";
    expoRiskArea.value = "BrokenLink";
    appendExpression(1, expoRiskArea);
    const expoExceptionType = new QueryExpression();
    expoExceptionType.field = "ExceptionType";
    expoExceptionType.andOr = "And";
    expoExceptionType.fieldType = "string";
    expoExceptionType.operator = "=";
    expoExceptionType.value = "False Positive";
    appendExpression(2, expoExceptionType);
    const expoArticleURL = new QueryExpression();
    expoArticleURL.field = "ArticleURL";
    expoArticleURL.andOr = "And";
    expoArticleURL.fieldType = "string";
    expoArticleURL.operator = "=";
    expoArticleURL.value = "";
    appendExpression(3, expoArticleURL);
    const expoReporter = new QueryExpression();
    expoReporter.field = "Reporter";
    expoReporter.andOr = "And";
    expoReporter.fieldType = "string";
    expoReporter.operator = "=";
    expoReporter.value = "";
    appendExpression(4, expoReporter);
    const expoIssueText = new QueryExpression();
    expoIssueText.field = "Content";
    expoIssueText.andOr = "And";
    expoIssueText.fieldType = "string";
    expoIssueText.operator = "=";
    expoIssueText.value = "";
    appendExpression(5, expoIssueText);
  }
  else {
    if (templateName === "broken" || templateName === "curation" || templateName === "product") {
      setCurationFieldsDefaultByTemplate(templateName, appendExpression, removeExpression);
      return;
    }

    const expoLcale = new QueryExpression();
    expoLcale.field = "Locale";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "en-us";
    appendExpression(1, expoLcale);

    let groupChild1 = new QueryExpression()
    groupChild1.group = true
    groupChild1.andOr = "And";
    groupChild1.field = "LastPublishDate";
    groupChild1.fieldType = "datetime";
    groupChild1.operator = "Is after";
    groupChild1.value = getDefaultDateTime()
    appendExpression(2, groupChild1);

    let groupChild2 = new QueryExpression()
    groupChild2.group = true
    groupChild2.andOr = "Or";
    groupChild2.field = "ArticleCreatedDate";
    groupChild2.fieldType = "datetime";
    groupChild2.operator = "Is after";
    groupChild2.value = getDefaultDateTime()
    appendExpression(3, groupChild2);

    onUpdateGroupingCheckbox(2)
    onUpdateGroupingCheckbox(3)
    groupRows()
  }

  removeExpression(0);
}

/**
 * CreateTask/EditTask
 * @param templateName 
 * @param appendExpression 
 * @param onUpdateGroupingCheckbox 
 * @param groupRows 
 * @param removeExpression 
 */
function setCurationFieldsDefaultByTemplate(
  templateName: string,
  appendExpression: Function,
  removeExpression: Function) {
  let expoLcale = new QueryExpression();
  if (templateName === "broken") {
    expoLcale = new QueryExpression();
    expoLcale.field = "Locale";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "en-us";
    appendExpression(1, expoLcale);

    const groupChild1 = new QueryExpression();
    groupChild1.andOr = "And";
    groupChild1.field = "NoOfBrokenLinks";
    groupChild1.fieldType = "long";
    groupChild1.operator = ">";
    groupChild1.value = "0";
    appendExpression(2, groupChild1);
  }
  else if (templateName === "curation") {
    expoLcale.field = "Locale";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "en-us";
    appendExpression(1, expoLcale);

    expoLcale = new QueryExpression();
    expoLcale.andOr = "And";
    expoLcale.field = "AvgPageViewsMonth";
    expoLcale.fieldType = "long";
    expoLcale.operator = ">";
    expoLcale.value = "120";
    appendExpression(2, expoLcale);

    expoLcale = new QueryExpression();
    expoLcale.andOr = "And";
    expoLcale.field = "Retention";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "Process";
    appendExpression(3, expoLcale);
  }
  else if (templateName === "product") {
    expoLcale.field = "Locale";
    expoLcale.fieldType = "string";
    expoLcale.operator = "=";
    expoLcale.value = "en-us";
    appendExpression(1, expoLcale);

    let groupChild1 = new QueryExpression()
    groupChild1.group = true
    groupChild1.andOr = "And";
    groupChild1.field = "SapStatus";
    groupChild1.fieldType = "string";
    groupChild1.operator = "=";
    groupChild1.value = "Retired";
    appendExpression(2, groupChild1);

    let groupChild2 = new QueryExpression()
    groupChild2.group = true
    groupChild2.andOr = "And";
    groupChild2.field = "ArticleArchivingRemainingDays";
    groupChild2.fieldType = "long";
    groupChild2.operator = "<=";
    groupChild2.value = "0";
    appendExpression(3, groupChild2);
  }

  removeExpression(0);
}

// #region get oder by fields
/**
 * get oder by fields
 * @param metadataSchema 
 * @returns 
 */
export function getOderByFields(metadataSchema: string) {
  let orderBy = "ArticleArchivingRemainingDays desc, SapStatus desc";
  if (metadataSchema?.startsWith("gitpub-metadata-schema-others-")) {
    orderBy = "NoOfBrokenLinks desc";
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-404links-")) {
    orderBy = "NoOfArticles desc";
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-policheck-") || metadataSchema?.startsWith("AzureKBAPI")) {
    orderBy = "Domain";
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-customizedarticles-")) {
    orderBy = "NoOfBrokenLinks desc";
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-policheckaggregateddata-")) {
    orderBy = "Domain";
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-lmc-")) {
    orderBy = "RepoName";
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-smc-") || metadataSchema?.startsWith("gitpub-metadata-schema-sxc-")) {
    orderBy = "Locale";
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-policheckaggregateddata-")) {
    orderBy = "Domain";
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-csswiki-")) {
    orderBy = "Organization";
  }
  else if (metadataSchema?.startsWith("gitpub-metadata-schema-media-")) {
    orderBy = "Domain";
  }
  else if (metadataSchema?.startsWith("issue-center-")) {
    orderBy = "RiskArea";
  }

  return orderBy
}
// #endregion