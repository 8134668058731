import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { returnType } from "../../Utilities/ReturnType";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IColor } from "azure-devops-ui/Utilities/Color";
import { Icon } from '@fluentui/react/lib/Icon';
import { Icon as DevOpsIcon } from "azure-devops-ui/Icon";
import { Tab, TabBar, TabSize } from "azure-devops-ui/Tabs";
import { Toggle } from "azure-devops-ui/Toggle";
import { ButtonGroup } from "azure-devops-ui/ButtonGroup";
import { Card } from "azure-devops-ui/Card";
import { PillGroup, PillGroupOverflow } from "azure-devops-ui/PillGroup";
import {
    CustomHeader,
    TitleSize,
    HeaderDescription,
    HeaderIcon,
    HeaderTitle,
    HeaderTitleArea,
    HeaderTitleRow,
} from "azure-devops-ui/Header";

import { ITableColumn, SimpleTableCell, Table, renderSimpleCell } from "azure-devops-ui/Table";
import { Link } from "azure-devops-ui/Link";
import { Pill, PillSize, PillVariant } from "azure-devops-ui/Pill";
import { FilterBar } from "azure-devops-ui/FilterBar";
import { KeywordFilterBarItem } from "azure-devops-ui/TextFilterBarItem";
import { IFilter, Filter } from "azure-devops-ui/Utilities/Filter";
import { DropdownSelection } from "azure-devops-ui/Utilities/DropdownSelection";
import { DropdownFilterBarItem } from "azure-devops-ui/Dropdown";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Observer } from "azure-devops-ui/Observer";
import { Image, IImageProps, ImageFit } from '@fluentui/react/lib/Image';
import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { onExportAlgoReport, getCurationById, clearSearchResults, followUser, unFollowUser } from '../../../redux/Actions/PowerDataQuery'
import { DataListItem, DataListItemOthers } from '../../Interface/IDataResult'
import { formatDateTime, checkUserExsit, getUserCount } from '../../Utilities/CommonFunction'
import { useMsal, useAccount } from "@azure/msal-react";

import ReportView from './AlgorithmViews/ReportView'
import RunHistoryView from './AlgorithmViews/RunHistoryView'
import AlgorithmHeaderViews from './AlgorithmHeaderViews/AlgorithmHeaderViews'

import "./Algorithm.css";

const actionCreators = {
    clearSearchResults,
    onExportAlgoReport,
    getCurationById,
    followUser,
    unFollowUser
};
type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    searchResult: state.searchResult,
    curation: state.taskEditItem,
});
const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

let selectedTabId: ObservableValue<string> = new ObservableValue("tab1");
let isViewHistoryDialogOpen = new ObservableValue<boolean>(false);
let isViewReportDialogOpen = new ObservableValue<boolean>(false);
const filter = new Filter();

function BrokenLinkCheckWithSMC(props: StoreProps & DispatchProps) {
    /** Get template params from Welcome page */
    const [search, setSearch] = useSearchParams();
    const curationId = search.get('curationId')

    const lightColor: IColor = {
        red: 255,
        green: 206,
        blue: 80,
    };

    const whiteColor: IColor = {
        red: 255,
        green: 255,
        blue: 255,
    };

    /** Import msal-react for getting login user information */
    const { instance, accounts } = useMsal();
    const userAccount = useAccount(accounts[0]);

    const [tabIndex, setTabIndex] = useState("tab1")
    const [isUserExist, setIsUserExist] = useState(false)
    const [userCount, setUserCount] = useState(0)
    const [curationInfo, setCurationInfo] = useState({
        isReportButtonDisabled: true,
        curationName: '',
        Filter: '',
        SearchIndex: ''
    })

    /**
   * like componentDidMount
   */
    useEffect(() => {
        const loadData = async () => {
            await props.clearSearchResults()
            await props.getCurationById(curationId)
        }

        loadData();
    }, [curationId])

    /**
     * like componentUpdateMount
     */
    useEffect(() => {
        if (props.curation &&
            props.curation.ReportTo) {
            setIsUserExist(checkUserExsit(userAccount.username, props.curation.ReportTo))
            setUserCount(getUserCount(props.curation.ReportTo))
        }

        if (props.curation?.CurationConfigurationName) {
            setCurationInfo({
                isReportButtonDisabled: false,
                curationName: props.curation?.CurationConfigurationName,
                Filter: props.curation?.CurationFilter.Filter,
                SearchIndex: props.curation?.CurationFilter.SearchIndex
            })
        }
    }, [props.curation.ReportTo])

    /**
     * Render header icon
     * @param className 
     * @returns 
     */
    function renderIcon(className?: string) {
        return (
            <Icon ariaLabel="Broken Link Checker icon" iconName="RemoveLink" />
        );
    }

    /**
     * Tab change
     * @param newTabId 
     */
    function onSelectedTabChanged(newTabId: string) {
        selectedTabId.value = newTabId;
        setTabIndex(newTabId)
    }

    /**
     * Build datalist columns
     * @returns 
     */
     function buildReportColumns(): any {
        const columns = [
            {
                id: "ReferrerLink",
                name: "Referrer Link",
                readonly: true,
                renderCell: renderReferrerLinkCell,
                sortProps: {
                    ariaLabelAscending: "Sorted A to Z",
                    ariaLabelDescending: "Sorted Z to A",
                },
                width: 300,
            },
            {
                id: "ReferrerType",
                name: "Referrer Type",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted A to Z",
                    ariaLabelDescending: "Sorted Z to A",
                },
                width: 100,
            },
            {
                id: "ReferrerDomain",
                name: "Referrer Domain",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted A to Z",
                    ariaLabelDescending: "Sorted Z to A",
                },
                width: 120,
            },
            {
                id: "ReferrerID",
                name: "Referrer ID",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted A to Z",
                    ariaLabelDescending: "Sorted Z to A",
                },
                width: 300,
            },
            {
                id: "ReferrerOwner",
                name: "Referrer Owner",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted A to Z",
                    ariaLabelDescending: "Sorted Z to A",
                },
                width: 120,
            },
            {
                id: "NoOfBrokenLinks",
                name: "# of BrokenLinks",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: 120,
            },
            {
                id: "HasBrokenLinks",
                name: "Has BrokenLink Or Not",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 120,
            },
            {
                id: "PgDomainList",
                name: "PgDomain List",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 150,
            },
            {
                id: "ErrorPage404List",
                name: "ErrorPage404 List",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 300,
            },
            {
                id: "BrokenLinkClicks",
                name: "# of BrokenLink Clicks",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: 120,
            },
            {
                id: "ArticleVisits",
                name: "# of Article Visits",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: 120,
            },
            {
                id: "BrokenLinkDetectedTime",
                name: "BrokenLink Detected Time",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 175,
            },
            {
                id: "RepoNameList",
                name: "Repo Name List",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 150,
            },
            {
                id: "DataSource",
                name: "Data Source",
                readonly: true,
                renderCell: renderSimpleCell,
                width: 100,
            },
        ];

        return columns;    
    }

    /**
   * Render ContentId cell
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
     function renderReferrerLinkCell(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<DataListItemOthers>,
        tableItem: DataListItemOthers) {
        const { ReferrerLink } = tableItem

        return (
            <SimpleTableCell
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                key={"col-" + columnIndex}
                contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
            >
                {
                    ReferrerLink && ReferrerLink.toString() !== '' ?
                        (
                            <Link
                                className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                                excludeTabStop
                                href={ReferrerLink}
                                target={"_blank"}
                            >
                                {ReferrerLink}
                            </Link>
                        )
                        :
                        (
                            <div className="flex-row scroll-hidden">
                                {ReferrerLink}
                            </div>
                        )
                }
    
            </SimpleTableCell>
        );
    }

    /**
     * Render filter bar
     * @returns 
     */
    function renderFilterBarInContent() {
        if (selectedTabId.value === "tab3") {
            return (
                <FilterBar filter={filter}>
                    <KeywordFilterBarItem filterItemKey="keyword" />
                    <DropdownFilterBarItem
                        filterItemKey="picklist"
                        filter={filter}
                        items={[
                            { id: "Item 1", text: "Item 1" },
                            { id: "Item 2", text: "Item 2" },
                        ]}
                        selection={new DropdownSelection()}
                        placeholder="Dropdown"
                    />
                </FilterBar>
            );
        }
        return null;
    }

    /**
     * Show run history dialog
     */
    function onShowRunHistoryView() {
        isViewHistoryDialogOpen.value = true
    }

    /**
     * Show report dialog
     */
    function onShowReportView() {
        isViewReportDialogOpen.value = true
    }

    /**
     * Close run history dialog
     */
    function onRunHistoryViewDimiss() {
        isViewHistoryDialogOpen.value = false
    }

    /**
     * Close report dialog
     */
    function onReportViewDimiss() {
        isViewReportDialogOpen.value = false
    }

    /**
     * Follow switch
     * @param switchType 
     */
    async function onFollowSwitch(switchType: string) {
        if (switchType === "Follow") {
            await props.unFollowUser(curationId)
        }
        else {
            await props.followUser(curationId)
        }

        await props.getCurationById(curationId)
    }

    /**
     * Render run history dialog
     * @returns 
     */
    function renderRunHistoryView() {
        return (
            <div>
                <Observer isDialogOpen={isViewHistoryDialogOpen}  >
                    {(historyProps: { isDialogOpen: boolean }) => {
                        return historyProps.isDialogOpen ? (
                            <RunHistoryView
                                CurationId={curationId}
                                CurationName={curationInfo.curationName}
                                onRunHistoryDialogDimiss={onRunHistoryViewDimiss} />

                        ) : null;
                    }}
                </Observer>
            </div>
        )
    }

    /**
     * Render report dialog
     * @returns 
     */
    function renderReportView() {
        return (
            <div>
                <Observer isDialogOpen={isViewReportDialogOpen}  >
                    {(reportProps: { isDialogOpen: boolean }) => {
                        return reportProps.isDialogOpen ? (
                            <ReportView
                                CurationId={curationId}
                                CurationName={curationInfo.curationName}
                                Filter={curationInfo.Filter}
                                SearchIndex={curationInfo.SearchIndex}
                                onReportDialogDimiss={onReportViewDimiss}
                                CustomColumns={buildReportColumns()} />

                        ) : null;
                    }}
                </Observer>
            </div>
        )
    }

    /**
     * Render page content
     * @returns 
     */
    function getPageContent() {
        if (tabIndex === "tab1") {
            return (
                <div className="page-content-top">

                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
                                <Card
                                    className="bolt-card-content"
                                    contentProps={{ contentPadding: true }}
                                >
                                    {renderRunHistoryView()}
                                    {renderReportView()}
                                    <div className="ms-Grid">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div>
                                                    <h2>Broken Link Checkers - Broken Link Checker for SMC</h2>
                                                    <div className="ms-Grid">
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <p>
                                                                    <Image
                                                                        width={"100%"}
                                                                        src={require("./AlgoAD002.png")}
                                                                        alt='Product no longer supported under standard or extended support.'
                                                                    />
                                                                </p>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <div className='AlgoDesSection'>
                                                                    <h3>Algorithm description</h3>
                                                                    <div className="algoDescribe">
                                                                        This algorithm is based on the data from a successful Hackathon project "<a href='https://garagehackbox.azurewebsites.net/hackathons/2356/projects/105456'>Find and fix broken links across Microsoft content platforms</a>" submitted by CES teams in 2021. It monitors 404 error data to find the broken links on the referrer from Evergreen content. With this algorithm, you can understand:

                                                                        <ul>
                                                                            <li>How frequently customers land on broken links</li>
                                                                            <li>Where broken links are</li>
                                                                        </ul>


                                                                    </div>
                                                                    <div>
                                                                        <ButtonGroup className='flex-row flex-center flex-noshrink'>
                                                                            <PrimaryButton
                                                                                text="View report"
                                                                                iconProps={{ iconName: 'ReportDocument' }}
                                                                                primary={true}
                                                                                disabled={curationInfo.isReportButtonDisabled}
                                                                                onClick={() => onShowReportView()}
                                                                            />

                                                                            <DefaultButton
                                                                                iconProps={{ iconName: 'FullHistory' }}
                                                                                text="View history"
                                                                                disabled={curationInfo.isReportButtonDisabled}
                                                                                onClick={() => onShowRunHistoryView()}
                                                                            />
                                                                            {
                                                                                isUserExist ?
                                                                                    <DefaultButton
                                                                                        iconProps={{ iconName: 'UserFollowed' }}
                                                                                        text="Unfollow"
                                                                                        onClick={() => onFollowSwitch("Follow")}
                                                                                        className={"msacct-follow-focus"}
                                                                                    />
                                                                                    :
                                                                                    <DefaultButton
                                                                                        iconProps={{ iconName: 'FollowUser' }}
                                                                                        text="Follow"
                                                                                        onClick={() => onFollowSwitch("Unfollow")}
                                                                                        className={"msacct-follow-focus"}
                                                                                    />
                                                                            }
                                                                            <div style={{ marginLeft: "0px" }} className="page-follower-count bolt-pill flex-row flex-center standard regular">
                                                                                <div aria-label="" aria-hidden="true" className="bolt-pill-content text-ellipsis" role="presentation">{userCount}
                                                                                </div>
                                                                            </div>

                                                                        </ButtonGroup>
                                                                    </div>
                                                                </div>
                                                                <Card
                                                                    className="bolt-card-content"
                                                                    contentProps={{ contentPadding: true }}
                                                                >
                                                                    <div>
                                                                        <h3>Internal support article governance best practices</h3>
                                                                        <ul>
                                                                            <li>No broken link exists in article.</li>
                                                                        </ul>
                                                                        <h3>Remediation guidance</h3>
                                                                        <div>
                                                                            Remove the broken links.
                                                                        </div>
                                                                    </div>
                                                                </Card>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h3>Scheduler</h3>
                                                    <ul>
                                                        <li>Recurrence: Recurring</li>
                                                        <li>Recur every: 24 Hours</li>
                                                        <li>Start: Sept 19th, 2022</li>
                                                        <li>End: Never</li>
                                                    </ul>
                                                    <h3>Filters</h3>
                                                    <ul>
                                                        <li>Has Broken Link = <b>true</b></li>
                                                        <li>Referrer Domain = <b>SMC</b></li>
                                                    </ul>
                                                    <h3>Report columns</h3>
                                                    <ul>
                                                        <li>Referrer Link</li>
                                                        <li>Referrer Type</li>
                                                        <li>Referrer Domain</li>
                                                        <li>Referrer ID</li>
                                                        <li>Referrer Owner</li>
                                                        <li># of BrokenLinks</li>
                                                        <li>Has BrokenLink Or Not</li>
                                                        <li>PgDomain List</li>
                                                        <li>ErrorPage404 List</li>
                                                        <li># of BrokenLink Clicks</li>
                                                        <li># of Article Visits</li>
                                                        <li>BrokenLink Detected Time</li>
                                                        <li>Repo Name List</li>
                                                        <li>Data Source</li>
                                                    </ul>
                                                    <h3>Calculated fields</h3>
                                                    <ul>
                                                        <li>Has Broken Link = true (# of Broken Links &gt; 0)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                                <div>
                                    <Card
                                        className="bolt-card-content"
                                        contentProps={{ contentPadding: true }}
                                    >

                                        <div>
                                            <h3 className="columnTitle">Data sources</h3>
                                            <div className="columnCells">
                                                <div className="cell">
                                                    <header aria-label="App_Publisher">
                                                        404 error data
                                                    </header>
                                                    <ul>
                                                        <li><div className="cellLabel">Data source: <a href="https://microsofteur.sharepoint.com/teams/DCSDataScience/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=ijH78p&cid=1d0853ec%2D8c6f%2D4773%2Da343%2D0ecbef4b98ae&RootFolder=%2Fteams%2FDCSDataScience%2FShared%20Documents%2FGeneral%2FAd%20hoc%20reports%2FPOC%20BrokenLinks&FolderCTID=0x01200087EB91D1B3CD4B4EA62365894374D48C">POC BrokenLinks</a></div></li>
                                                        <li><div className="cellLabel">Data latency: 1 month</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="columnTitle">Works with</h3>
                                            <div className="columnCells">
                                                <div className="cell">
                                                    <header aria-label="App_Publisher">
                                                        Internal content
                                                    </header>
                                                    <ul>
                                                        <li><div className="cellLabel">URL: <a href="https://support.microsoft.com">https://support.microsoft.com</a></div></li>
                                                        <li><div className="cellLabel">Data latency: 48 Hours</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="columnTitle">Related filter fields</h3>
                                            <div className="columnCells">
                                                <div className="cell">
                                                    <PillGroup className="flex-row" overflow={PillGroupOverflow.wrap}>
                                                        <Pill
                                                            size={PillSize.compact}
                                                            color={whiteColor}
                                                            variant={PillVariant.outlined}
                                                        ># of Broken Links
                                                        </Pill><Pill
                                                            size={PillSize.compact}
                                                            color={whiteColor}
                                                            variant={PillVariant.outlined}
                                                        >Has Broken Link
                                                        </Pill>
                                                    </PillGroup>
                                                </div>

                                                <DefaultButton
                                                    text="Field dictionary"
                                                    iconProps={{ iconName: 'DocumentSearch' }}
                                                    target="_blank"
                                                    href="https://aka.ms/ACCTFieldDictionary"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (tabIndex === "tab2") {
            return (
                <div className="page-content-top">
                    <Card
                        className="bolt-card-content"
                        contentProps={{ contentPadding: true }}
                    >
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <h3 className="columnTitle">Details</h3>
                                    <div className="columnCells">
                                        <div className="cell">
                                            <header>
                                                Publisher
                                            </header>
                                            <span className="cellLabel">Microsoft CE&amp;S KM team</span>
                                        </div>
                                        <div className="cell">
                                            <header>
                                                Version
                                            </header>
                                            <span className="cellLabel">0.1.0</span>
                                        </div>
                                        <div className="cell">
                                            <header>
                                                Updated
                                            </header>
                                            <span className="cellLabel">{formatDateTime('7/28/2022')!}</span>
                                        </div>
                                        <div className="cell">
                                            <header>
                                                Curation areas
                                            </header>
                                            Quality
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <h3 className="columnTitle">Support</h3>
                                    <div className="columnCells">
                                        <div className="cell">
                                            <header>
                                                Algorithm
                                            </header>
                                            <span className="cellLabel"><a href="https://aka.ms/ACCTTools">Algorithm development v-team</a></span>
                                        </div>
                                        <div className="cell">
                                            <header>
                                                Data
                                            </header>
                                            <span className="cellLabel"><a href="https://microsoft.sharepoint.com/teams/CSSBIReporting">CE&amp;S BI team</a></span>
                                        </div>
                                        <div className="cell">
                                            <header>
                                                Platform
                                            </header>
                                            <span className="cellLabel"><a href="https://aka.ms/ACCTTools">Tooling team</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        } if (tabIndex === "tab3") {
            <div className="page-content page-content-top">
                <Card
                    className="flex-grow bolt-card-no-vertical-padding"
                    contentProps={{ contentPadding: true }}
                >

                </Card>
            </div>
        }
        return null;
    }

    return (
        <div className="algoPage">
            <CustomHeader className="bolt-header-with-commandbar">
                <HeaderIcon
                    className="bolt-table-status-icon-large"
                    iconProps={{ render: renderIcon }}
                    titleSize={TitleSize.Large}
                />
                <HeaderTitleArea>
                    <HeaderTitleRow>
                        <HeaderTitle
                            ariaLevel={3}
                            className="text-ellipsis"
                            titleSize={TitleSize.Medium}
                        >
                            Broken Link Checker for SMC
                        </HeaderTitle>
                        <div>by Microsoft Internal</div>
                    </HeaderTitleRow>
                    <HeaderDescription>
                        An algorithm to find and report dead links on SMC content based on data generated by users' click behavior.
                    </HeaderDescription>
                    <AlgorithmHeaderViews
                        Domains={["Evergreen", "AMC", "DMC", "SMC", "SXC","Other MS Sites"]}
                        CurationAreas={["Quality"]}
                        Tags={["Broken Links", "Dead Links", "Bad Links", "404 errors"]}
                        SelectedDomain ={"SMC"}
                        ClickPageName={"Broken Link Checker"}
                    />
                </HeaderTitleArea>
            </CustomHeader>
            <div className="page-content">
                <TabBar
                    onSelectedTabChanged={onSelectedTabChanged}
                    selectedTabId={selectedTabId}
                    tabSize={TabSize.Tall}
                    disableSticky={false}
                >
                    <Tab name="Overview" id="tab1" />
                    <Tab name="Details + Support" id="tab2" />
                </TabBar>

                {renderFilterBarInContent()}
                {getPageContent()}
            </div>
        </div>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(BrokenLinkCheckWithSMC);
