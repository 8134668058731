import {ISonicUserInfo} from '../components/Interface/IMsalAuth'

export const sonicReportUsers: Array<ISonicUserInfo> = [
    { name: "Jinping Zheng", userName: "v-jinzhe@microsoft.com" },
    { name: "Dale Chen", userName: "daleche@microsoft.com" },          
    { name: "Ying Huang", userName: "v-yinghuang@microsoft.com" }, 
    { name: "Young Yang", userName: "v-yaya13@microsoft.com" },
    { name: "Zuolin Qu", userName: "v-zuolinqu@microsoft.com" },
    { name: "Mingyu Tan", userName: "v-mingyutan@microsoft.com" },
    { name: "Xiaojun Hu", userName: "v-xiaojunhu@microsoft.com" },
    { name: "William Chen", userName: "willchen@microsoft.com" },
    { name: "Ray Ringhiser", userName: "rayring@microsoft.com" },
    { name: "Dalton Young", userName: "dyoung@microsoft.com" },
    { name: "Brian Raffety", userName: "brraffet@microsoft.com" },
    { name: "Charlene Douglass", userName: "cshepard@microsoft.com" },
    { name: "Phil Johnson", userName: "philjohn@microsoft.com" },
    { name: "Ana Baratz", userName: "anabaratz@microsoft.com" },
    { name: "Bob O'Brien (CX)", userName: "bobrien@microsoft.com" },
    { name: "Yoshiko Morita", userName: "yoshmor@microsoft.com" },
    { name: "Helena Masar", userName: "helenam@microsoft.com" },
    { name: "Jovan Varga", userName: "jovanvarga@microsoft.com" },
    { name: "Mithu Dhali", userName: "midhali@microsoft.com" },
    { name: "Narendra Kumar Nagarajan", userName: "v-narnag@microsoft.com" },
    { name: "Balaji Selvarasu", userName: "v-bselvarasu@microsoft.com" },
    { name: "Charlene Douglass", userName: "cshepard@microsoft.com" },
    { name: "Aarti Goyle", userName: "aartigoyle@microsoft.com" },
    { name: "Betsy Kuriakose", userName: "betsyk@microsoft.com" },
    { name: "Bianca Serge", userName: "biancaserge@microsoft.com" },
    { name: "Bob O'Brien (CX)", userName: "bobrien@microsoft.com" },
    { name: "Brian Schneider", userName: "brisc@microsoft.com" },
    { name: "Christy Su", userName: "christys@microsoft.com" },
    { name: "Dan Vasquez", userName: "danv@microsoft.com" },
    { name: "David Packman", userName: "davidpac@microsoft.com" },
    { name: "Deland Han", userName: "delhan@microsoft.com" },
    { name: "Gary Spradling", userName: "gsprad@microsoft.com" },
    { name: "Guilherme Gilli Martins", userName: "guilhermeg@microsoft.com" },
    { name: "Haiying Yu", userName: "haiyingyu@microsoft.com" },
    { name: "Helena Masar", userName: "helenam@microsoft.com" },
    { name: "Jarrett Renshaw", userName: "jarrettr@microsoft.com" },
    { name: "Julie (Percha) Vaerewyck", userName: "jupercha@microsoft.com" },
    { name: "Kaushik Ainapure", userName: "kaushika@microsoft.com" },
    { name: "Louis Broome", userName: "lbroome@microsoft.com" },
    { name: "Lu Chen", userName: "luche@microsoft.com" },
    { name: "Meera Krishna", userName: "meerak@microsoft.com" },
    { name: "Patricia Horton", userName: "phorton@microsoft.com" },
    { name: "Pei Wang", userName: "peiw@microsoft.com" },
    { name: "Peter Robertson", userName: "robertso@microsoft.com" },
    { name: "Ramu Konidena", userName: "ramakoni@microsoft.com" },
    { name: "Thomas Lin", userName: "genli@microsoft.com" },
    { name: "Phil Johnson", userName: "philjohn@microsoft.com" },
    { name: "Tony Cheung", userName: "tcheung@microsoft.com" },
    { name: "Jennifer Lowry", userName: "jelowry@microsoft.com" },
    { name: "Kumar Vivek", userName: "kvivek@microsoft.com" },
    { name: "Lee Clontz", userName: "leeclontz@microsoft.com" },
    { name: "Renee Wesberry (SHE/HER)", userName: "renwe@microsoft.com" },
    { name: "Shelly Haverkamp", userName: "shellyha@microsoft.com" },
    { name: "Shubhada Joshi", userName: "Shujoshi@microsoft.com" },
    { name: "Tonya Fehr", userName: "tfehr@microsoft.com" },
    { name: "Buckley Guderian", userName: "buckg@microsoft.com" },
    { name: "Tapan Maniar", userName: "tapanm@microsoft.com" },
    { name: "Jo Sawyer", userName: "josaw@microsoft.com" },
    { name: "Tricia Gill", userName: "triciag@microsoft.com" },
    { name: "Morgan Hill", userName: "morganhill@microsoft.com" },
    { name: "Fata Dukuly", userName: "fatadukuly@microsoft.com" },
    { name: "Angela Ruggiero", userName: "anrugg@microsoft.com" },
    { name: "Jose Soriano", userName: "josorian@microsoft.com" },
    { name: "Lisa Pachuta (ALLYIS INC)", userName: "v-lpachuta@microsoft.com" },
    { name: "Internal content KM team", userName: "internalckm@microsoft.com" },
    { name: "Venus Mi (Shanghai Wicresoft Co,.Ltd.)", userName: "v-venum@microsoft.com" },
    { name: "Simonx Xu (Shanghai Wicresoft Co,.Ltd.)", userName: "v-six@microsoft.com" },
    { name: "Marcia Ma (Shanghai Wicresoft Co,.Ltd.)", userName: "v-marciama@microsoft.com" },
    { name: "Lena Chen (Shanghai Wicresoft Co,.Ltd.)", userName: "v-lenc@microsoft.com" }
];