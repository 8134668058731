import { Spinner, SpinnerSize } from 'azure-devops-ui/Spinner';
import './CommonLoading.css';

interface   ICommonLoading {
    label: string,
    isLoading: boolean
}

export default function CommonLoading(props: ICommonLoading) {
    return (
        <>
            {props.isLoading && <Spinner className="common_loading" size={SpinnerSize.large} label={props.label}/>}
        </>
    )
}