import React, { Component } from 'react'
import {
  useNavigate,
} from "react-router-dom";

import {
  PrimaryButton,
  Dialog,
  DialogFooter,
  DialogType,
  DefaultButton,

} from "@fluentui/react";

interface ConfirmationNavigateDialogProps {
  isOpen: boolean
  isBlocking: boolean
  dialogTitle: string
  dialogText: string
  confirmButtonText: string
  onConfirm: () => void
  cancelButtonText: string
  onCancel: () => void
  jumpPath: string

}

export default function ConfirmationNavigateDialog(props: ConfirmationNavigateDialogProps) {
  const history = useNavigate();

  function jumpToPath() {
    history(props.jumpPath);
  }

  return (
    <Dialog
      hidden={!props.isOpen}
      dialogContentProps={{
        type: DialogType.normal,
        subText: props.dialogText
      }}
      modalProps={{ isBlocking: props.isBlocking }}
      onDismiss={props.onCancel}
      title={props.dialogTitle}
      onDismissed={jumpToPath}
    >
      <DialogFooter>
        {!props.onConfirm ? null : (
          <PrimaryButton onClick={props.onConfirm}>
            {props.confirmButtonText}
          </PrimaryButton>
        )}
        {!props.onCancel ? null : (
          <DefaultButton onClick={props.onCancel}>
            {this.cancelButtonText}
          </DefaultButton>
        )}
      </DialogFooter>
    </Dialog>
  )
}
