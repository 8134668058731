
import { DataField } from "../Interface/IPowerQuery";

import moment from 'moment'

export const noResultsText = 'No results found'

export const TEXT = "string";
export const DATE = "datetime";
export const NUMERICAL = "long";
export const BOOLEAN = "boolean";
export const USERNAME = "username";
export const FULLTEXT = "fulltext";
export const SYSCHANGEDDATE = "sysdatetime"

export const groupHoverText_notEnough =
  'At least two expressions required for grouping'
export const groupHoverText_noIntersection =
  'Groups can not intersect each other'
export const groupHoverText_alreadyExists =
  'A group already exists for the selected clauses'
export const groupHoverText_okay = 'Group'

/** Start: Operator */
export const lessThanOperator = "<";
export const greaterThanOperator = ">";
export const notEqualsOperator = "<>";
export const lessThanOrEqualsOperator = "<=";
export const greaterThanOrEqualsOperator = ">=";
export const equalsOperator = "=";
export const isSetOperator = "Is set";
export const isNotSetOperator = "Is not set";
export const containsOperator = "Contains";
export const doesNotContainOperator = "Does not contain";
export const beginsWithOperator = "Begins with";
export const endsWithOperator = "Ends with";
export const inOperator = "In";
export const isOnOperator = "Is on";
export const isAfterOperator = "Is after";
export const isBeforeOperator = "Is before";
export const isNotOperator = "Is not";

export const defaultFieldOperators = {
  string: containsOperator,
  datetime: isOnOperator,
  long: equalsOperator,
  boolean: equalsOperator,
  sysdatetime: greaterThanOperator
}

export const defaultFieldValues = {
  string: '',
  datetime: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
  long: '0',
  boolean: 'true'
}

// numberical operator
const NUMERICAL_OPERATORS = [
  lessThanOperator,
  greaterThanOperator,
  notEqualsOperator,
  lessThanOrEqualsOperator,
  greaterThanOrEqualsOperator,
  equalsOperator,
  isSetOperator,
  isNotSetOperator,
] as const;
type NumericalOperatorTuple = typeof NUMERICAL_OPERATORS;
export type NumericalOperator = NumericalOperatorTuple[number];

export const numericalOperators: {
  key: NumericalOperator
  text: NumericalOperator
}[] = [
    { key: lessThanOperator, text: lessThanOperator },
    { key: greaterThanOperator, text: greaterThanOperator },
    { key: notEqualsOperator, text: notEqualsOperator },
    { key: lessThanOrEqualsOperator, text: lessThanOrEqualsOperator },
    { key: greaterThanOrEqualsOperator, text: greaterThanOrEqualsOperator },
    { key: equalsOperator, text: equalsOperator },
    { key: isSetOperator, text: isSetOperator },
    { key: isNotSetOperator, text: isNotSetOperator }
  ]

// boolean operator
const BOOLEAN_OPERATORS = [
  notEqualsOperator,
  equalsOperator,
  isSetOperator,
  isNotSetOperator,
] as const;
type BooleanOperatorTuple = typeof BOOLEAN_OPERATORS;
export type BooleanOperator = BooleanOperatorTuple[number];

export const booleanOperators: {
  key: BooleanOperator
  text: BooleanOperator
}[] = [
    { key: notEqualsOperator, text: notEqualsOperator },
    { key: equalsOperator, text: equalsOperator },
    { key: isSetOperator, text: isSetOperator },
    { key: isNotSetOperator, text: isNotSetOperator }
  ]

// string operator
const STRING_OPERATORS = [
  equalsOperator,
  containsOperator,
  doesNotContainOperator,
  beginsWithOperator,
  endsWithOperator,
  isSetOperator,
  isNotSetOperator,
  inOperator,
] as const;
type StringOperatorTuple = typeof STRING_OPERATORS;
export type StringOperator = StringOperatorTuple[number];

export const stringOperators: {
  key: StringOperator
  text: StringOperator
}[] = [
    { key: equalsOperator, text: equalsOperator },
    { key: containsOperator, text: containsOperator },
    { key: doesNotContainOperator, text: doesNotContainOperator },
    { key: isSetOperator, text: isSetOperator },
    { key: isNotSetOperator, text: isNotSetOperator },
    { key: inOperator, text: inOperator }
  ]

// date operator
const DATE_OPERATORS = [
  isOnOperator,
  isAfterOperator,
  isBeforeOperator,
  isNotOperator,
  isSetOperator,
  isNotSetOperator,
] as const;
type DateOperatorTuple = typeof DATE_OPERATORS;
export type DateOperator = DateOperatorTuple[number];

export const dateOperators: {
  key: DateOperator
  text: DateOperator
}[] = [
    { key: isAfterOperator, text: isAfterOperator },
    { key: isBeforeOperator, text: isBeforeOperator },
    { key: isOnOperator, text: isOnOperator },
    { key: isNotOperator, text: isNotOperator },
    { key: isSetOperator, text: isSetOperator },
    { key: isNotSetOperator, text: isNotSetOperator }
  ]

// enum operator
const ENUM_OPERATORS = [
  containsOperator,
  doesNotContainOperator,
  isSetOperator,
  isNotSetOperator,
] as const;
type EnumOperatorTuple = typeof ENUM_OPERATORS;
export type EnumOperator = EnumOperatorTuple[number];

export const enumOperators: { key: EnumOperator; text: EnumOperator }[] = [
  { key: containsOperator, text: containsOperator },
  { key: doesNotContainOperator, text: doesNotContainOperator },
  { key: isSetOperator, text: isSetOperator },
  { key: isNotSetOperator, text: isNotSetOperator }
]

// fulltext operator
const FULLTEXT_OPERATORS = [
  equalsOperator,
  containsOperator
] as const;
type FullTextOperatorTuple = typeof FULLTEXT_OPERATORS;
export type FullTextOperator = FullTextOperatorTuple[number];

export const fullTextOperators: {
  key: FullTextOperator
  text: FullTextOperator
}[] = [
    { key: equalsOperator, text: equalsOperator },
    { key: containsOperator, text: containsOperator }
  ]

// sysdatetime operator
const SYSCHANGEDDATE_OPERATORS = [
  lessThanOperator,
  greaterThanOperator,
  lessThanOrEqualsOperator,
  greaterThanOrEqualsOperator,
  equalsOperator,
] as const;
type SysChangedDateOperatorTuple = typeof SYSCHANGEDDATE_OPERATORS;
export type SysChangedDateOperator = SysChangedDateOperatorTuple[number];

export const sysChangedDateOperators: {
  key: SysChangedDateOperator
  text: SysChangedDateOperator
}[] = [
    { key: lessThanOperator, text: lessThanOperator },
    { key: greaterThanOperator, text: greaterThanOperator },
    { key: lessThanOrEqualsOperator, text: lessThanOrEqualsOperator },
    { key: greaterThanOrEqualsOperator, text: greaterThanOrEqualsOperator },
    { key: equalsOperator, text: equalsOperator }
  ]

export type MetadataQueryOperator =
  | NumericalOperator
  | BooleanOperator
  | StringOperator
  | DateOperator
  | EnumOperator
  | FullTextOperator
  | SysChangedDateOperator
  | null;
/** End: Operator */

/** Start: Special fields */
export const SPECIAL_SEARCH_FIELDS: DataField[] = [
  // {
  //   FieldName: "Test",
  //   DisplayName: "Test",
  //   SearchFieldName: "Test",
  //   DatabaseFieldName: null,
  //   ReadOnly: true,
  //   Required: false,
  //   MultiValue: false,
  //   FieldType: TEXT,
  //   EnumValues: null,
  //   UsernameField: false,
  // }
  // {
  //   FieldName: "FileName",
  //   DisplayName: "File Name",
  //   SearchFieldName: "FileName",
  //   DatabaseFieldName: null,
  //   ReadOnly: true,
  //   Required: false,
  //   MultiValue: false,
  //   FieldType: TEXT,
  //   EnumValues: null,
  //   UsernameField: false,
  // },
  // {
  //   FieldName: "FilePath",
  //   DisplayName: "File Path",
  //   SearchFieldName: "FilePath",
  //   DatabaseFieldName: null,
  //   ReadOnly: true,
  //   Required: false,
  //   MultiValue: false,
  //   FieldType: TEXT,
  //   EnumValues: null,
  //   UsernameField: false,
  // },
  // {
  //   FieldName: "RepoId",
  //   DisplayName: "Repo Id",
  //   SearchFieldName: "RepoId",
  //   DatabaseFieldName: null,
  //   ReadOnly: true,
  //   Required: false,
  //   MultiValue: false,
  //   FieldType: TEXT,
  //   EnumValues: null,
  //   UsernameField: false,
  // },
  // {
  //   FieldName: "Branch",
  //   DisplayName: "Branch",
  //   SearchFieldName: "Branch",
  //   DatabaseFieldName: null,
  //   ReadOnly: true,
  //   Required: false,
  //   MultiValue: false,
  //   FieldType: TEXT,
  //   EnumValues: null,
  //   UsernameField: false,
  // },
  // {
  //   FieldName: "FileExtension",
  //   DisplayName: "File Extension",
  //   SearchFieldName: "FileExtension",
  //   DatabaseFieldName: null,
  //   ReadOnly: true,
  //   Required: false,
  //   MultiValue: false,
  //   FieldType: TEXT,
  //   EnumValues: null,
  //   UsernameField: false,
  // },
  // {
  //   FieldName: "IsBinary",
  //   DisplayName: "Is Binary",
  //   SearchFieldName: "IsBinary",
  //   DatabaseFieldName: null,
  //   ReadOnly: true,
  //   Required: false,
  //   MultiValue: false,
  //   FieldType: BOOLEAN,
  //   EnumValues: null,
  //   UsernameField: false,
  // },
  // {
  //   FieldName: "IsImage",
  //   DisplayName: "Is Image",
  //   SearchFieldName: "IsImage",
  //   DatabaseFieldName: null,
  //   ReadOnly: true,
  //   Required: false,
  //   MultiValue: false,
  //   FieldType: BOOLEAN,
  //   EnumValues: null,
  //   UsernameField: false,
  // },
];
/** End: Special fields */

// default column options (fields rendered in results table)
export const METADATA_QUERY_DEFAULT_COLUMNS = {
  'gitpub-metadata-schema-soc': ['Branch', 'File_Extension', 'File_Name'],
  'gitpub-metadata-schema-smc': ['title', 'writerName', 'guid'],
  'gitpub-metadata-schema-tips': ['guid']
}

export const MSACCT_TITLE = "Microsoft CE&S KM Automated Content Curation Tool"

// Evergreen: gitpub-metadata-schema-evergreen
export const SELECT_STRING = "ContentID,Locale,PageTitle,LastPublishDate,ArticleCreatedDate," +
  "NoOfPageViews,NoOfBrokenLinks,HasBrokenLinks,RepositoryName,KBID," +
  "FilePath,SapName,NoOfInternalReferences,AgeMonth,RefreshAgeMonth,URL," +
  "SapStatus,MainstreamDate,ExtendedEndDate,RetirementDate,ReleaseEndDate,MainStreamRemainDays," +
  "ExtendedEndRemainDays,RetirementRemainDays,ReleaseEndRemainDays,ArticleArchivingRemainingDays," +
  "NoOfPageViews7days,NoOfPageViews30days,NoOfPageViews90days,NoOfPageViews360days," +
  "AvgPageViewsDay,AvgPageViewsMonth,NoOfUniqueVisitors,PercentageOfEngagedRate,PercentageOfHRR,RevisionNumber,BrokenLinkClicks," +
  "PgDomainList,ErrorPage404List,ActiveLink,BrokenLinkDetectedTime,ArticlePublisher,AssignedTo,ArticleCreator,WriterName,PortfolioOwner"

export const SELECT_STRING_TextSearch = "ContentID,Locale,PageTitle,LastPublishDate,ArticleCreatedDate," +
  "NoOfPageViews,NoOfBrokenLinks,HasBrokenLinks,RepositoryName,KBID," +
  "FilePath,SapName,NoOfInternalReferences,AgeMonth,RefreshAgeMonth,URL," +
  "SapStatus,MainstreamDate,ExtendedEndDate,RetirementDate,ReleaseEndDate,MainStreamRemainDays," +
  "ExtendedEndRemainDays,RetirementRemainDays,ReleaseEndRemainDays,ArticleArchivingRemainingDays," +
  "NoOfPageViews7days,NoOfPageViews30days,NoOfPageViews90days,NoOfPageViews360days," +
  "AvgPageViewsDay,AvgPageViewsMonth,NoOfUniqueVisitors,PercentageOfEngagedRate,PercentageOfHRR,RevisionNumber,BrokenLinkClicks," +
  "PgDomainList,ErrorPage404List,ActiveLink,BrokenLinkDetectedTime,ArticlePublisher,AssignedTo,ArticleCreator,WriterName,PortfolioOwner,NoOfIssues,NoOfTerm,TermInformation,PoliCheckDetectedTime"

// 404 Errors: gitpub-metadata-schema-others
export const SELECT_STRING_OTHERS = "ReferrerLink,ReferrerType,ReferrerDomain,ReferrerID,ReferrerOwner," +
  "NoOfBrokenLinks,HasBrokenLinks,PgDomainList,ErrorPage404List,BrokenLinkClicks,ArticleVisits,BrokenLinkDetectedTime,RepoNameList,DataSource";

// 404 Errors: gitpub-metadata-schema-404links
export const SELECT_STRING_404LINKS = "Link,Domain,BrokenLinkDetectedTime,NoOfArticles";

// PoliCheck: gitpub-metadata-schema-policheck
export const SELECT_STRING_POLICHECK = "Domain,ShortDomain,Link,Locale,Text,Context,MatchScore,Guidelines,Why,PoliCheckDetectedTime";

// PoliCheck Aggregated Data: gitpub-metadata-schema-policheckaggregateddata
export const SELECT_PoliCheck_Aggregated_Data = "Link,Locale,Domain,ShortDomain,NoOfPoliCheckIssues,NoOfTerm,WriterName,ArticleCreator,ArticlePublisher,AssignedTo,PortfolioOwner,NoOfPageViews,NoOfArticleVisits,NoOfEngagedVisits,TermInformation,PoliCheckDetectedTime";

// Customized articles: gitpub-metadata-schema-customizedarticles
export const SELECT_CustomizedArticles_STRING = "DataSourceName,DataSourceType,Link,NoOfBrokenLinks,HasBrokenLinks,BrokenLinkDetectedTime,PgDomainList,ErrorPage404List"
export const SELECT_CustomizedArticles_STRING_TextSearch = "DataSourceName,DataSourceType,Link,NoOfBrokenLinks,HasBrokenLinks,BrokenLinkDetectedTime,PgDomainList,ErrorPage404List,NoOfIssues,NoOfTerm,TermInformation,PoliCheckDetectedTime"

// LMC: gitpub-metadata-schema-lmc
export const SELECT_LMC_STRING = "RepoOwner,RepoName,ms_date,Product,Service,Technology,Author,GitHubAuthor,Manager,Locale,ContentId,Title,LiveUrl,LastPublished,LastReviewed,ContentLastModified,Tags,DocSet,GitUrl,MSReviewer,ContentLocale,Audience,MSProd,MSService,MSTechnology,MSIndustry,ContentManager,Level,RepoNameGlobal,FirstPublishDateTime,GitFileName,OriginalContentGitUrl,ContentPublicGitUrl,SubService,MSSubService,Modules,Products,Visitors,NoOfBrokenLinks,HasBrokenLinks,BrokenLinkDetectedTime,PgDomainList,ErrorPage404List"

export const SELECT_LMC_STRING_TextSearch = "RepoOwner,RepoName,ms_date,Product,Service,Technology,Author,GitHubAuthor,Manager,Locale,ContentId,Title,LiveUrl,LastPublished,LastReviewed,ContentLastModified,Tags,DocSet,GitUrl,MSReviewer,ContentLocale,Audience,MSProd,MSService,MSTechnology,MSIndustry,ContentManager,Level,RepoNameGlobal,FirstPublishDateTime,GitFileName,OriginalContentGitUrl,ContentPublicGitUrl,SubService,MSSubService,Modules,Products,Visitors,NoOfIssues,NoOfTerm,TermInformation,PoliCheckDetectedTime"

// SXC: gitpub-metadata-schema-sxc
export const SELECT_SXC_STRING = "Link, Locale, Priority"
export const SELECT_SXC_STRING_BrokenLinkCrawler = "Link, Locale, Priority,NoOfBrokenLinks,HasBrokenLinks,BrokenLinkDetectedTime,PgDomainList,ErrorPage404List"
export const SELECT_SXC_STRING_TextSearch = "Link, Locale, Priority,NoOfIssues,NoOfTerm,TermInformation,PoliCheckDetectedTime"

// LMC: gitpub-metadata-schema-lmc
export const SELECT_SMC_STRING = "Link, Locale, Priority"
export const SELECT_SMC_STRING_BrokenLinkCrawler = "Link, Locale, Priority,NoOfBrokenLinks,HasBrokenLinks,BrokenLinkDetectedTime,PgDomainList,ErrorPage404List"
export const SELECT_SMC_STRING_TextSearch = "Link, Locale, Priority,NoOfIssues,NoOfTerm,TermInformation,PoliCheckDetectedTime"

export const SELECT_CSS_WIKI_DATA = "Title,Organization,ProjectName,LiveUrl"

export const SELECT_SONIC_MEDIA_DATA = "URL,Locale,Domain,HTMLAsset"

export const SELECT_SONIC_ISSUE_CENTER_DATA = "id,ArticleURL,Content,Context,RiskArea,SourceApp,Reporter,ReportTime,ExceptionType,Comment,ExpirationDate,TraceId"


export const CurationsConstant = {
  SharedType_MyCurations: "My Curations",
  SharedType_SharedWith: "Shared With",
  SharedType_Everyone: "Everyone"
}
