import { Status, Statuses, StatusSize, IStatusProps } from "azure-devops-ui/Status";
import { css } from "azure-devops-ui/Util";
import { IListBoxItem } from "azure-devops-ui/ListBox";

export interface IStatusIndicatorData {
  statusProps: IStatusProps;
  label: string;
}

export enum PipelineStatus {
  running = "running",
  succeeded = "succeeded",
  failed = "failed",
  warning = "warning",
  Queued = "queued",
  Canceled = "canceled"
}

export function renderStatus(className?: string) {
  return <Status {...Statuses.Success} className={className} size={StatusSize.l} />;
};

export function renderAnyStatus(status: string, className?: string) {
  return function () {
    let currentStatus = Statuses.Success

    if (!status || status === '') {
      currentStatus = Statuses.Failed

      return <Status {...currentStatus} className={className} size={StatusSize.l} />;
    }

    switch (status?.toLowerCase()) {
      case "failed":
        currentStatus = Statuses.Failed
        break;
      case "running":
        currentStatus = Statuses.Running
        break;
      default:
        break;
    }

    return <Status {...currentStatus} className={className} size={StatusSize.l} />;

  }
};

export function getStatusIndicatorData(status: string): IStatusIndicatorData {

  status = status || "";
  status = status.toLowerCase();
  let indicatorData: IStatusIndicatorData = {
    statusProps: Statuses.Success,
    label: "Success"
  };
  switch (status) {
    case PipelineStatus.failed:
      indicatorData.statusProps = Statuses.Failed;
      indicatorData.label = "Failed";
      break;
    case PipelineStatus.running:
      indicatorData.statusProps = Statuses.Running;
      indicatorData.label = "Running";
      break;
    case PipelineStatus.warning:
      indicatorData.statusProps = Statuses.Warning;
      indicatorData.label = "Warning";
      break;
    case PipelineStatus.Queued:
      indicatorData.statusProps = Statuses.Queued;
      indicatorData.label = "Queued";
      break;
    case PipelineStatus.Canceled:
      indicatorData.statusProps = Statuses.Canceled;
      indicatorData.label = "canceled";
      break;
  }

  return indicatorData;
}

export function getStatusListItem(status: PipelineStatus): IListBoxItem<PipelineStatus> {
  const statusDetail = getStatusIndicatorData(status);

  return {
    data: status,
    id: status,
    text: statusDetail.label,
    iconProps: {
      render: className => (
        <Status
          {...statusDetail.statusProps}
          className={css(className, statusDetail.statusProps.className)}
          size={StatusSize.m}
          animated={false}
        />
      )
    }
  };
};

export function getStatuses() {
  return [
    PipelineStatus.succeeded,
    PipelineStatus.failed,
    PipelineStatus.warning,
    PipelineStatus.running
  ];
};
