import React, { Component } from 'react'
import AppLayout from "../AppLayout/AppLayout";
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'
import {MSACCT_TITLE} from '../../Utilities/QueryBuilderConstants'

import TaskEdit from '../../TaskEdit/TaskEdit'

const breadcrumbItem: IBreadCrumbExtension[] = [
  { text: 'Home', key: 'Home', href: '/' },
  { text: 'Intelligent Automation', key: 'ia', href: '/autocuration'},
  { text: 'Curation Configuration Settings', key: 'CurationConfiguration', href: '/curations'}
];

export default class TaskEditPage extends Component {
  
  render() {

    document.title = MSACCT_TITLE + " | " + "Curation Configuration Settings";
    return (
      <AppLayout
        renderBreadcrumb={() =>
          <BreadCrumbExtension items={breadcrumbItem} />
        }
        renderContentPane={() => <TaskEdit />} />
    );
  }
}

