import React, {Component, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import AlgoAnalyticsControl from '../../CurationAll/AlgoAnalytics'
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import AppLayout from "../AppLayout/AppLayout";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import {
  CustomHeader,
  HeaderDescription,
  HeaderIcon,
  HeaderTitle,
  HeaderTitleArea,
  HeaderTitleRow
} from "azure-devops-ui/Header";
import { TitleSize } from "azure-devops-ui/Header";
import {
  HeaderCommandBarWithFilter
} from "azure-devops-ui/HeaderCommandBar";
import { Filter, FILTER_CHANGE_EVENT, IFilter } from "azure-devops-ui/Utilities/Filter";
import { ConditionalChildren } from "azure-devops-ui/ConditionalChildren";
import { FilterBar } from "azure-devops-ui/FilterBar";
import { KeywordFilterBarItem } from "azure-devops-ui/TextFilterBarItem";
import {
  IHeaderCommandBarItem,
  HeaderCommandBar,
} from "azure-devops-ui/HeaderCommandBar";
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'

const filter = new Filter();
const filterToggled = new ObservableValue<boolean>(false);

const breadcrumbItem: IBreadCrumbExtension[] = [
    { text: 'Home', key: 'Home', href: '/' },
    { text: 'Intelligent Automation', key: 'f5'},
  ];

export default class AlgoAnalyticsPage extends Component {
    render() {
        document.title = document.getElementsByTagName("title")[0].text + " | " + "Algo Analytics";
        return (
          <AppLayout
            renderBreadcrumb={() =>
              <BreadCrumbExtension items={breadcrumbItem} />
            }
            renderContentPane={() => {
                return (
                    <AlgoAnalyticsControl />
                );
              }}
             />
        );
      }
}
