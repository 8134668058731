import React, { Component } from 'react'
import ColumnOptionsDialog from '../Dialogs/ColumnOptionsDialog/ColumnOptionsDialog'
import ConfigurationSettingsDialog from '../Dialogs/ConfigurationSettingsDialog/ConfigurationSettingsDialog'

export default class DialogProvider extends Component {
  render() {
    return (
      <div>
          <ColumnOptionsDialog />
          <ConfigurationSettingsDialog />
      </div>
    )
  }
}
