import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { returnType } from "../../../Utilities/ReturnType";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

import { Icon as DevOpsIcon } from "azure-devops-ui/Icon";
import { CustomHeader, HeaderTitleArea } from "azure-devops-ui/Header";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { CustomDialog } from "azure-devops-ui/Dialog";
import { PanelContent, PanelFooter } from "azure-devops-ui/Panel";
import {
    ITableColumn,
    SimpleTableCell,
    Table,
    renderSimpleCell,
    TwoLineTableCell,
} from "azure-devops-ui/Table";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Duration } from "azure-devops-ui/Duration";
import { css } from "azure-devops-ui/Util";
import { Ago } from "azure-devops-ui/Ago";
import { Link } from "azure-devops-ui/Link";
import { Status, StatusSize } from "azure-devops-ui/Status";
import { getStatusIndicatorData } from '../../../Utilities/DataListExtension'
import { GlobalDataQuery } from "../../../Interface/IPowerQuery";
import { ICurationRun } from '../../../Interface/ICurationRun'
import { getCurationRunsById, onExportTaskRunsResult } from '../../../../redux/Actions/PowerDataQuery'
import TaskRunResultForAlgo from '../../../TaskRunResult/TaskRunResultForAlgo'
import { formatDateTime, buildTicketAndMailStates } from '../../../Utilities/CommonFunction'


import "./ReportView.css";

const actionCreators = {
    getCurationRunsById,
    onExportTaskRunsResult
};
type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    searchResult: state.searchResult,
    curationRunsWithName: state.curationRunsWithName,
    curation: state.taskEditItem,
});
const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

interface IRunHistoryParameterProps {
    CurationId: string;
    CurationName: string;
    onRunHistoryDialogDimiss: () => void
}


function ViewRunHistory(props: StoreProps & DispatchProps & IRunHistoryParameterProps) {

    const [isClickDetail, setIsClickDetail] = useState(false)
    const [currentClickBuildId, setCurrentClickBuildId] = useState('')

    /**
    * like componentDidMount
    */
    useEffect(() => {

        const loadData = async () => {

            // curation jobs data
            await props.getCurationRunsById(props.CurationId)
        }

        loadData();
    }, [])

    /**
     * Build items
     * @returns 
     */
    function buildItemsWithName() {
        if (!props.curationRunsWithName || !props.curationRunsWithName.Runs) {
            return []
        }

        let items: ICurationRun[] = []
        props.curationRunsWithName.Runs.map((item) => {
            let runResultCount = 0
            let buildRunType = ""
            let mailDelivered = ""
            let ticketingEventTrigged = ""
            let triggerType = ""
            if (item.RunResults) {
                runResultCount = item.RunResults.ResultCount === -1 ? 0 : item.RunResults.ResultCount
                buildRunType = item.RunResults.BuildRunType
                mailDelivered = buildTicketAndMailStates(item.RunResults.MailDelivered)
                ticketingEventTrigged = buildTicketAndMailStates(item.RunResults.TicketingEventTrigged)
                triggerType = buildTicketAndMailStates(item.RunResults.TriggerType)
            }

            items.push({
                Id: item.Id,
                BuildNumber: item.BuildNumber,
                Description: item.Description,
                CurationConfigurationName: props.curation.CurationConfigurationName,
                Status: item.Status,
                Result: item.Result,
                StartTime: item.StartTime,
                FinishTime: item.FinishTime,
                ElapsedTime: item.ElapsedTime,
                Url: item.Url,
                SourceBranch: item.SourceBranch,
                RunResultCount: runResultCount,
                ResultPath: item.RunResults?.ResultPath,
                BuildRunType: buildRunType,
                MailDelivered: mailDelivered,
                TicketingEventTrigged: ticketingEventTrigged,
                TriggerType: triggerType
            });
        })

        return items ? items : []
    }

    /**
   * build columns
   * @returns 
   */
    function buildColumns(): any {
        const columns = [
            {
                id: "Description",
                name: "Job ID",
                readonly: true,
                renderCell: renderDescriptionCell,
                sortProps: {
                    ariaLabelAscending: "Sorted A to Z",
                    ariaLabelDescending: "Sorted Z to A",
                },
                width: new ObservableValue(-20),
            },
            {
                id: "CurationConfigurationName",
                maxWidth: 300,
                name: "Curation configuration name",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-40),
            },
            {
                id: "RunResultCount",
                maxWidth: 300,
                name: "# of Articles",
                readonly: true,
                renderCell: renderArticleCountCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-20),
            },
            {
                id: "TicketingEventTrigged",
                maxWidth: 300,
                name: "Ticket created or not?",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-25),
            },
            {
              id: "TriggerType",
              maxWidth: 300,
              name: "Trigger type",
              readonly: true,
              renderCell: renderSimpleCell,
              sortProps: {
                ariaLabelAscending: "Sorted low to high",
                ariaLabelDescending: "Sorted high to low",
              },
              width: new ObservableValue(-20),
            },
            {
              id: "BuildRunType",
              maxWidth: 300,
              name: "Run type",
              readonly: true,
              renderCell: renderSimpleCell,
              sortProps: {
                ariaLabelAscending: "Sorted low to high",
                ariaLabelDescending: "Sorted high to low",
              },
              width: new ObservableValue(-20),
            },
            {
                id: "MailDelivered",
                maxWidth: 300,
                name: "Email sent or not?",
                readonly: true,
                renderCell: renderSimpleCell,
                sortProps: {
                    ariaLabelAscending: "Sorted low to high",
                    ariaLabelDescending: "Sorted high to low",
                },
                width: new ObservableValue(-25),
            },
            {
                id: "FinishTime",
                name: "Completed time",
                width: new ObservableValue(-30),
                readonly: true,
                renderCell: renderDateColumn
            },
        ];

        return columns;
    }

    /**
   * build description cell
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
    function renderDescriptionCell(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<ICurationRun>,
        tableItem: ICurationRun) {
        const { Description, BuildNumber, SourceBranch } = tableItem
        const text = "#" + BuildNumber  + "  ";
        const buildId = tableItem.Id;
        return (
            <SimpleTableCell
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                key={"col-" + columnIndex}
                contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
            >
                {
                    buildId && buildId.toString() !== '' ?
                        (
                            <div>
                                <Status
                                    {...getStatusIndicatorData(tableItem.Result).statusProps}
                                    className="icon-large-margin"
                                    size={StatusSize.s}
                                />
                                <Link
                                    className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                                    excludeTabStop
                                    href={""}
                                    onClick={(event) => onClickDetail(event, buildId.toString())}
                                >
                                    {text}
                                </Link>
                            </div>
                        )
                        :
                        (
                            <div className="flex-row scroll-hidden">
                                {text}
                            </div>
                        )
                }

            </SimpleTableCell>
        );
    }

    /**
   * Build article count cell
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
    function renderArticleCountCell(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<ICurationRun>,
        tableItem: ICurationRun) {
        const { RunResultCount, ResultPath } = tableItem

        return (
            <SimpleTableCell
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                key={"col-" + columnIndex}
                contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
            >
                {
                    RunResultCount && RunResultCount.toString() !== '0' &&
                        ResultPath && ResultPath !== '' ?
                        (
                            <Link
                                className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                                excludeTabStop
                                href={""}
                                onClick={(event) => exportRunResult(ResultPath)}
                            >
                                {RunResultCount}
                            </Link>
                        )
                        :
                        (
                            <div className="flex-row scroll-hidden">
                                {RunResultCount}
                            </div>
                        )
                }

            </SimpleTableCell>
        );
    }

    /**
     * Render date column
     * @param rowIndex 
     * @param columnIndex 
     * @param tableColumn 
     * @param tableItem 
     * @returns 
     */
    function renderDateColumn(
        rowIndex: number,
        columnIndex: number,
        tableColumn: ITableColumn<ICurationRun>,
        tableItem: ICurationRun): JSX.Element {
        return (
            <TwoLineTableCell
                key={"col-" + columnIndex}
                columnIndex={columnIndex}
                tableColumn={tableColumn}
                line1={WithIcon({
                    className: "fontSize font-size",
                    iconProps: { iconName: "Calendar" },
                    children: (
                        <Ago date={new Date(tableItem.StartTime)!} />
                    )
                })}
                line2={WithIcon({
                    className: "fontSize font-size bolt-table-two-line-cell-item",
                    iconProps: { iconName: "Clock" },
                    children: (
                        <Duration
                            startDate={new Date(tableItem.StartTime)!}
                            endDate={new Date(tableItem.FinishTime)}
                        />
                    )
                })}
            />
        );
    }

    /**
     * Build date icon
     * @param props 
     * @returns 
     */
    function WithIcon(props: {
        className?: string;
        iconProps: IIconProps;
        children?: React.ReactNode;
    }) {
        return (
            <div className={css(props.className, "flex-row flex-center")}>
                {DevOpsIcon({ ...props.iconProps, className: "icon-margin" })}
                {props.children}
            </div>
        );
    }

    /**
     * Export run result
     * @param csvFilePath 
     */
    function exportRunResult(csvFilePath: string) {
        props.onExportTaskRunsResult(csvFilePath)
    }

    /**
     * Click back button
     */
    function onClickBack() {
        setIsClickDetail(false)
    }

    /**
     * Click detail link
     * @param event 
     * @param buildId 
     */
    function onClickDetail(event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>, buildId: string) {
        setIsClickDetail(true)
        setCurrentClickBuildId(buildId)
    }

    return (
        <CustomDialog calloutContentClassName='msacct-algorithm-dialog-container' onDismiss={props.onRunHistoryDialogDimiss} modal={true} className="ddd">
            <CustomHeader className="bolt-header-with-commandbar" separator>
                <HeaderTitleArea>
                    <div
                        className="flex-grow scroll-hidden"
                        style={{ marginRight: "16px" }}
                    >
                        <div
                            className="title-m"
                            style={{
                                height: "500px",
                                width: "500px",
                                maxHeight: "32px"
                            }}
                        >
                            {props.curation.CurationConfigurationName + " - " + "Curation Jobs"}
                        </div>
                        {
                            !isClickDetail && props.curationRunsWithName?.NextRun ?
                                <div style={{ textAlign: "right" }} ><span>Next run date: {formatDateTime(props.curationRunsWithName?.NextRun)} </span></div>
                                :
                                null
                        }

                    </div>
                </HeaderTitleArea>
            </CustomHeader>
            <PanelContent>
                {
                    isClickDetail ?
                        <TaskRunResultForAlgo BuildId={currentClickBuildId} />
                        :
                        <Table<Partial<ICurationRun>>
                            columns={buildColumns()}
                            itemProvider={new ArrayItemProvider<ICurationRun>(buildItemsWithName())}
                            showLines={true}
                            onSelect={(event, data) => console.log("Selected Row - " + data.index)}
                            onActivate={(event, row) => console.log("Activated Row - " + row.index)}
                            pageSize={200}
                            containerClassName="msacct-devops-table"
                        />
                }
            </PanelContent>
            <PanelFooter showSeparator className="body-m">
                {
                    isClickDetail ?
                        <>
                            <PrimaryButton
                                style={{ "float": "right", "marginLeft": "15px" }}
                                text="Close"
                                onClick={() => props.onRunHistoryDialogDimiss()}
                            />

                            <DefaultButton
                                style={{ "float": "right" }}
                                text="Back"
                                onClick={() => onClickBack()}
                            />
                        </>

                        : <PrimaryButton
                            style={{ "float": "right" }}
                            text="Close"
                            onClick={() => props.onRunHistoryDialogDimiss()}
                        />
                }

            </PanelFooter>
        </CustomDialog>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(ViewRunHistory);

