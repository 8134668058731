import React, { Component } from "react";
import AppLayout from "../AppLayout/AppLayout";
import BreadCrumbExtension, { IBreadCrumbExtension } from '../../Controls/BreadCrumbExtension/BreadCrumbExtension'
import LogResults from '../../Modules/LogResults/LogResults'

const breadcrumbItem: IBreadCrumbExtension[] = [
  { text: 'Home', key: 'Home', href: '/' },
  { text: "Curation runs", key: "runs",href: '/' },
  { text: 'Result', key: 'f5', href: '/runs/results', isCurrentItem: true },
];

export default class LogResultsPage extends Component {
  render() {
    document.title =
      document.getElementsByTagName("title")[0].text + " | " + "Result";
    return (
      <AppLayout
        renderBreadcrumb={() => (
          <BreadCrumbExtension items={breadcrumbItem} />
        )}
        renderContentPane={() => {
          return (
            <div>
              <LogResults />
            </div>
          );
        }}
      />
    );
  }
}
