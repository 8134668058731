import React, { Component } from 'react'
import { Nav, INavLink, INavStyles, INavLinkGroup } from '@fluentui/react/lib/Nav';
import PowerQueryPage from './PowerQueryPage/PowerQueryPage'
import TaskQueryPage from './TaskQueryPage/TaskQueryPage'
import TaskConfigurationPage from './TaskConfigurationPage/TaskConfigurationPage'
import CurationRunsPage from '../../components/Pages/CurationRunsPage/CurationRunsPage'
import CurationJobsPage from '../../components/Pages/CurationRunsPage/CurationJobsPage'
import CurationAllPage from '../../components/Pages/CurationAllPage/CurationAllPage'
import { NavLink, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import MenuBar from '../../components/Modules/MenuBar/MenuBar'
import ACCTSideNav from '../../components/Modules/SideNav/SideNav'
import WelcomePage from '../../components/Pages/WelcomePage/Welcome'
import TaskEditPage from '../Pages/TaskEditPage/TaskEditPage'
import TaskRunResultPage from '../Pages/TaskRunResultPage/TaskRunResultPage'
import AlgoAnalyticsPage from '../Pages/DiscoverInsights/AlgoAnalytics'
import LogResultsPage from '../../components/Pages/LogResultsPage/LogResults'
import ProductLifecycleAnalyzerFamily from '../../components/Pages/AlgorithmsPage/ProductLifecycleAnalyzerFamily'
import ProductExpiredArticlesAnalyzer from '../../components/Pages/AlgorithmsPage/ProductExpiredArticlesAnalyzer'
import ArticlesWithPartialExpiredProductsAnalyzer from './AlgorithmsPage/ArticlesWithPartialExpiredProductsAnalyzer'
import ArticlesWithEmptyProductsLifecycleDataAnalyzer from '../../components/Pages/AlgorithmsPage/ArticlesWithEmptyProductsLifecycleDataAnalyzer'
import ArticlesWithOutdatedSAPValuesAnalyzer from './AlgorithmsPage/ArticlesWithOutdatedSAPValuesAnalyzer'
import ProductExpiredArticleswithoutRetiredSAPValuesAnalyzer from '../../components/Pages/AlgorithmsPage/ProductExpiredArticleswithoutRetiredSAPValuesAnalyzer'
import ArticlesWithEmptySAPValuesAnalyzer from '../../components/Pages/AlgorithmsPage/ArticlesWithEmptySAPValuesAnalyzer'
import BrokenLinkCheckerFamily from '../../components/Pages/AlgorithmsPage/BrokenLinkCheckerFamily'
import BrokenLinkChecker from '../../components/Pages/AlgorithmsPage/BrokenLinkChecker'
import BrokenLinkCheckerWithAMC from '../../components/Pages/AlgorithmsPage/BrokenLinkCheckerWithAMC'
import BrokenLinkCheckerWithDMC from '../../components/Pages/AlgorithmsPage/BrokenLinkCheckerWithDMC'
import BrokenLinkCheckerWithSMC from '../../components/Pages/AlgorithmsPage/BrokenLinkCheckerWithSMC'
import BrokenLinkCheckerWithSiteMapSXC from '../../components/Pages/AlgorithmsPage/BrokenLinkCheckerWithSiteMapSXC'
import BrokenLinkCheckerWithUnknown from '../../components/Pages/AlgorithmsPage/BrokenLinkCheckerWithUnknown'

import PoliCheckFamily from '../../components/Pages/AlgorithmsPage/PoliCheckFamily'
import PoliCheckEvergreen from '../../components/Pages/AlgorithmsPage/PoliCheckEvergreen'
import PoliCheckWithDMC from '../../components/Pages/AlgorithmsPage/PoliCheckWithDMC'
import PoliCheckWithSXC from '../../components/Pages/AlgorithmsPage/PoliCheckWithSXC'
import PoliCheckWithSMC from '../../components/Pages/AlgorithmsPage/PoliCheckWithSMC'

import RetentionCheckerFamily from '../../components/Pages/AlgorithmsPage/RetentionCheckerFamily'
import RetentionOfProcessChecker from '../../components/Pages/AlgorithmsPage/RetentionOfProcess'
import RetentionOfProductChecker from '../../components/Pages/AlgorithmsPage/RetentionOfProduct'
import RetentionOfInternalToolChecker from '../../components/Pages/AlgorithmsPage/RetentionOfInternalTool'
import Demo1Page from '../../components/Pages/LogResultsPage/Demo-1'
import Demo2Page from '../../components/Pages/LogResultsPage/Demo-2'
import Demo3Page from '../../components/Pages/LogResultsPage/Demo-3'
import TicketDownloadPage from '../Pages/TicketDownload/TicketDownloadPage'
import BrokenLinkCheckerPage from './BrokenLinkChecker/BrokenLinkCheckerPage'
import PIICheckerPage from './PIIChecker/PIIChecker';
import PoliCheckPage from './PoliCheck/PoliCheck';
import ScanRequestsAMCPage from './ScanRequestsPage/ScanRequestsAMCPage'
import ScanRequestsLMCPage from './ScanRequestsPage/ScanRequestsLMCPage'
import ScanRequestsEvergreenPage from './ScanRequestsPage/ScanRequestsEvergreenPage'
import ScanRequestsSMCPage from './ScanRequestsPage/ScanRequestsSMCPage'
import ScanRequestsSXCPage from './ScanRequestsPage/ScanRequestsSXCPage'
import ScanRequestsUnified404ErrorPage from './ScanRequestsPage/ScanRequestsUnified404ErrorPage'
import ScanRequestsUnifiedEvergreenPage from './ScanRequestsPage/ScanRequestsUnifiedEvergreenPage'
import CustomizedArticlesPage from './DataSource/CustomizedArticlesPage'
import CustomizedTextPage from './TextSearchDataSource/CustomizedTextPage'
import KnowledgeSitesPage from './DataSource/KnowledgeSitesPage'

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { useMsalAuthentication } from '@azure/msal-react';

import { InteractionType } from '@azure/msal-browser'
import { useMsal, useAccount } from '@azure/msal-react';

import { msalConfig } from "../../msal/authConfig";
import { msalInstance } from "../../index";



export function RouterPage() {
  // auto login
  const { result, error } = useMsalAuthentication(InteractionType.Redirect);

  // get user info by hook
  // const { instance, accounts } = useMsal();
  // const account = useAccount(accounts[0]);
  // const userAccount = msalInstance.getActiveAccount();

  return (
    <Routes>
      <Route element={<CommonPageRuter />}>
        <Route path='/' element={<Navigate to='/welcome' />} />
        <Route path='/welcome' element={<WelcomePage />} />
        <Route path='/queries/edit' element={<PowerQueryPage />} />
        <Route path='/autocuration' element={<TaskQueryPage />} />
        <Route path='/curations/create' element={<TaskConfigurationPage />} />
        <Route path='/curations/edit' element={<TaskEditPage />} />
        <Route path='/runs' element={<CurationJobsPage />} />
        <Route path='/curations/runs' element={<CurationRunsPage />} />
        <Route path='/curations' element={<CurationAllPage />} />
        <Route path='/runs/results' element={<TaskRunResultPage />} />
        <Route path='/log/runs/results' element={<LogResultsPage />} />
        <Route path='/discoverinsights' element={<AlgoAnalyticsPage />}>
          <Route path='algoanalytics' element={<AlgoAnalyticsPage />} />
        </Route>
        <Route path='/apps/brokenlinkchecker' element={<BrokenLinkCheckerPage />} />
        <Route path='/apps/piichecker' element={<PIICheckerPage />} />
        <Route path='/apps/policheck' element={<PoliCheckPage />} />
        <Route path='/freshness/crawler/amc' element={<ScanRequestsAMCPage />} />
        <Route path='/freshness/crawler/lmc' element={<ScanRequestsLMCPage />} />
        <Route path='/freshness/crawler/evergreen' element={<ScanRequestsEvergreenPage />} />
        <Route path='/freshness/crawler/smc' element={<ScanRequestsSMCPage />} />
        <Route path='/freshness/crawler/sxc' element={<ScanRequestsSXCPage />} />
        <Route path='/freshness/udp/errordata' element={<ScanRequestsUnified404ErrorPage />} />
        <Route path='/freshness/udp/evergreen' element={<ScanRequestsUnifiedEvergreenPage />} />
        <Route path='/data/custom' element={<CustomizedArticlesPage />} />
        <Route path='/data/text' element={<CustomizedTextPage />} />
        
        <Route path='/data/sites' element={<KnowledgeSitesPage />} />
        
        
        

        <Route path='/algos/productlifecycleanalyzerfamily' element={<ProductLifecycleAnalyzerFamily />} />
        <Route path='/algos/productexpiredarticlesanalyzer' element={<ProductExpiredArticlesAnalyzer />} />
        <Route path='/algos/articleswithemptyproductslifecycledataanalyzer' element={<ArticlesWithEmptyProductsLifecycleDataAnalyzer />} />
        <Route path='/algos/articleswithpartialexpiredproductsanalyzer' element={<ArticlesWithPartialExpiredProductsAnalyzer />} />
        <Route path='/algos/articleswithoutdatedsapvaluesanalyzer' element={<ArticlesWithOutdatedSAPValuesAnalyzer />} />
        <Route path='/algos/productexpiredarticleswithoutretiredsapvaluesanalyzer' element={<ProductExpiredArticleswithoutRetiredSAPValuesAnalyzer />} />
        <Route path='/algos/articleswithemptysapvaluesanalyzer' element={<ArticlesWithEmptySAPValuesAnalyzer />} />

        <Route path='/algos/brokenlinkcheckerfamily' element={<BrokenLinkCheckerFamily />} />
        <Route path='/algos/brokenlinkchecker' element={<BrokenLinkChecker />} />
        <Route path='/algos/brokenlinkcheckeramc' element={<BrokenLinkCheckerWithAMC />} />
        <Route path='/algos/brokenlinkcheckerdmc' element={<BrokenLinkCheckerWithDMC />} />
        <Route path='/algos/brokenlinkcheckersmc' element={<BrokenLinkCheckerWithSMC />} />
        <Route path='/algos/brokenlinkcheckersxc' element={<BrokenLinkCheckerWithSiteMapSXC />} />
        <Route path='/algos/brokenlinkcheckerunknown' element={<BrokenLinkCheckerWithUnknown />} />

        
        <Route path='/algos/policheckfamily' element={<PoliCheckFamily />} />
        <Route path='/algos/policheck' element={<PoliCheckEvergreen />} />
        <Route path='/algos/polichecklmc' element={<PoliCheckWithDMC />} />
        <Route path='/algos/polichecksmc' element={<PoliCheckWithSMC />} />
        <Route path='/algos/polichecksxc' element={<PoliCheckWithSXC />} />

        <Route path='/algos/retentioncheckerfamily' element={<RetentionCheckerFamily />} />
        <Route path='/algos/retentionofprocess' element={<RetentionOfProcessChecker />} />
        <Route path='/algos/retentionofproduct' element={<RetentionOfProductChecker />} />
        <Route path='/algos/retentionofinternaltool' element={<RetentionOfInternalToolChecker />} />

        <Route path='/demo1' element={<Demo1Page />} />
        <Route path='/demo2' element={<Demo2Page />} />
        <Route path='/demo3' element={<Demo3Page />} />
      </Route>
      <Route element={<TicketDownloadPageRuter />}>
      <Route path='/ticket/download' element={<TicketDownloadPage />} />
      </Route>
    </Routes>
  )
}

/**
 * route for common page
 * @returns 
 */
const CommonPageRuter = () => (
  <div>
    <AuthenticatedTemplate>
      <MenuBar />
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2 sidenav">
          <ACCTSideNav />
        </div>
        <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg10 msacct-content">
          <Outlet />
        </div>
      </div>
    </AuthenticatedTemplate>
  </div>
);

/**
 * route for ticket download
 * @returns 
 */
const TicketDownloadPageRuter = () => (
  <div>
    <AuthenticatedTemplate>
      <Outlet />
    </AuthenticatedTemplate>
  </div>
);

