import React, { useEffect, useState } from 'react'
import { bindActionCreators } from "redux";
import { returnType } from "../Utilities/ReturnType";
import { connect } from "react-redux";

import { Card } from "azure-devops-ui/Card";
import { Header, TitleSize } from "azure-devops-ui/Header";
import {
    IHeaderCommandBarItem
} from "azure-devops-ui/HeaderCommandBar";
import { Page } from "azure-devops-ui/Page";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import {
    Spinner
} from "@fluentui/react";

import {
    CustomHeader,
    HeaderDescription,
    HeaderIcon,
    HeaderTitle,
    HeaderTitleArea,
    HeaderTitleRow
} from "azure-devops-ui/Header";
import { HeaderCommandBar } from "azure-devops-ui/HeaderCommandBar";
import { MessageCard, MessageCardSeverity } from "azure-devops-ui/MessageCard";
import { IMenuItem } from "azure-devops-ui/Menu";

import {
    getStatusIndicatorData,
    renderStatus,
    renderAnyStatus
} from '../Utilities/DataListExtension'
import {
    searchTaskRunsResultById,
    onExportTaskRunsResult
} from '../../redux/Actions/PowerDataQuery'
import { GlobalDataQuery } from "../Interface/IPowerQuery";
import { useParams, useSearchParams } from 'react-router-dom';
import { ITaskRunsResult } from '../Interface/ITaskRunsResult'
import { Link } from 'azure-devops-ui/Link';
import { formatDateTime, buildTicketAndMailStates } from '../Utilities/CommonFunction'


const actionCreators = {
    searchTaskRunsResultById,
    onExportTaskRunsResult
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    taskRunsResultItem: state.taskRunsResultItem
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

/**
 * interface for recent task search 
 */
// interface ICurationRunsResultState {
//     taskRunsResultItem: ITaskRunsResult
// }

function TaskRunResult(props: StoreProps & DispatchProps) {
    // get param from query
    // const { buildId } = useParams()
    const [search, setSearch] = useSearchParams()
    const buildId = search.get('buildId')

    const [isLoading, setIsLoading] = useState<boolean>(false)

    /**
  * like componentDidMount
  */
    useEffect(() => {
        const loadData = async () => {

            setIsLoading(true)

            // call the api to get the list
            await props.searchTaskRunsResultById(buildId);

            setIsLoading(false)
        }

        loadData();

        console.log("runresult", props)

    }, [])

    // jump to mail to form
    function navigateMailTo(menuItem: IMenuItem, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) {
        window.location.href = 'mailto:';
        event.preventDefault();
    }

    /**
     * down load the csv file when click download button
     */
    function downloadRunsResult() {
        if (props.taskRunsResultItem &&
            props.taskRunsResultItem.RunResults &&
            props.taskRunsResultItem.RunResults.ResultPath &&
            props.taskRunsResultItem.RunResults.ResultPath !== '') {
            props.onExportTaskRunsResult(props.taskRunsResultItem.RunResults.ResultPath)
        }
    }

    /**
     * creat header command bar
     */
    const commandBarItemsAdvanced: IHeaderCommandBarItem[] = [
        {
            iconProps: {
                iconName: "Download"
            },
            id: "download",
            important: true,
            onActivate: downloadRunsResult,
            disabled: setDownloadStatus(),
            text: "Download"
        },
        {
            iconProps: {
                iconName: "Share"
            },
            id: "share",
            onActivate: navigateMailTo,
            text: "Share"
        },
    ]

    /**
     * set down load button status(disable/enbale)
     * @returns 
     */
    function setDownloadStatus() {
        if (props.taskRunsResultItem &&
            props.taskRunsResultItem.RunResults &&
            props.taskRunsResultItem.RunResults.ResultPath &&
            props.taskRunsResultItem.RunResults.ResultPath !== '') {
            return false
        }

        return true
    }

    /**
     * down load csv file when click article count link
     * @param event 
     */
    function articleDownLoad(event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) {
        downloadRunsResult()
        event.preventDefault()
    }

    /**
     * build the title
     * @returns 
     */
    function buildTitle() {
        if (!props.taskRunsResultItem ||
            !props.taskRunsResultItem.BuildNumber) {
            return ""
        }

        return props.taskRunsResultItem.BuildNumber + " " + props.taskRunsResultItem.Description
    }

    function buildScheduleTitle() {
        if (!props.taskRunsResultItem ||
            !props.taskRunsResultItem.RunResults ||
            !props.taskRunsResultItem.RunResults.CurationConfigurationName) {
            return ""
        }

        return "Scheduled by curation configuration setting '" + props.taskRunsResultItem.RunResults.CurationConfigurationName + "'"
    }

    function buildDescription() {
        if (!props.taskRunsResultItem ||
            !props.taskRunsResultItem.RunResults ||
            !props.taskRunsResultItem.RunResults.CurationConfigurationName) {
            return `The curation is running`
        }

        if (props.taskRunsResultItem.Result === "Running") {
            return `The curation is running`
        }
        else if (props.taskRunsResultItem.Result === "Succeeded") {
            return `The curation run has completed at ${formatDateTime(props.taskRunsResultItem?.FinishTime?.toString())}`
        }
        else if (props.taskRunsResultItem.Result === "Failed") {
            return `The curation run has failed`
        }

        return `The curation is running`
    }

    function buildCompelteDate() {
        if (!props.taskRunsResultItem ||
            !props.taskRunsResultItem.RunResults ||
            !props.taskRunsResultItem.RunResults?.RunFinishTime) {
            return ''
        }

        return `${formatDateTime(props.taskRunsResultItem.RunResults?.RunFinishTime)}`
    }

    return (
        <div>
            <Surface background={SurfaceBackground.neutral}>
                <Page className="pipelines-page flex-grow">
                    {
                        isLoading ?
                            (
                                <Spinner className='msccat-taskresultpage-loading' label="Loading" />
                            )
                            :
                            (
                                <>
                                    <CustomHeader className="bolt-header-with-commandbar">
                                        <HeaderIcon
                                            className="bolt-table-status-icon-large"
                                            iconProps={{ render: renderAnyStatus(props.taskRunsResultItem?.Result) }}
                                            titleSize={TitleSize.Medium}
                                        />
                                        <HeaderTitleArea>
                                            <HeaderTitleRow>
                                                <HeaderTitle ariaLevel={3} className="text-ellipsis" titleSize={TitleSize.Medium}>
                                                    {buildTitle()}
                                                </HeaderTitle>
                                            </HeaderTitleRow>
                                            <HeaderDescription>
                                                {buildDescription()}
                                            </HeaderDescription>
                                        </HeaderTitleArea>
                                        <HeaderCommandBar items={commandBarItemsAdvanced} />
                                    </CustomHeader>
                                    <div className="page-content page-content-top">
                                        <MessageCard
                                            className="flex-self-stretch"
                                            severity={MessageCardSeverity.Info}                    >
                                            {"This run will be cleaned up after 3 months based on this site settings."}
                                        </MessageCard>
                                    </div>

                                    <Header
                                        title="Summary"
                                    />
                                    <div className="page-content page-content-top">
                                        <Card className="flex-grow" titleProps={{ text: buildScheduleTitle(), ariaLevel: 3 }}>
                                            <div className="flex-row" style={{ flexWrap: "wrap" }}>
                                                <div className="flex-column" style={{ minWidth: "120px" }} key={1}>
                                                    <div className="body-m secondary-text">{"Run Id"}</div>
                                                    <div className="body-m primary-text">{props.taskRunsResultItem.RunResults?.BuildId}</div>
                                                </div>
                                                <div className="flex-column" style={{ minWidth: "300px" }} key={2}>
                                                    <div className="body-m secondary-text">{"Run name"}</div>
                                                    <div className="body-m primary-text">{props.taskRunsResultItem.RunResults?.CurationConfigurationName}</div>
                                                </div>
                                                <div className="flex-column" style={{ minWidth: "200px" }} key={3}>
                                                    <div className="body-m secondary-text">{"# of Articles"}</div>
                                                    {
                                                        props.taskRunsResultItem &&
                                                            props.taskRunsResultItem.RunResults &&
                                                            props.taskRunsResultItem.RunResults.ResultPath &&
                                                            props.taskRunsResultItem.RunResults.ResultPath !== '' ?
                                                            (
                                                                <div className="body-m primary-text">
                                                                    <Link href='#' onClick={articleDownLoad}>
                                                                        {props.taskRunsResultItem.RunResults?.ResultCount === -1 ? 0 : props.taskRunsResultItem.RunResults?.ResultCount}
                                                                    </Link>

                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                <div className="body-m primary-text">{props.taskRunsResultItem.ResultCount === -1 ? 0 : props.taskRunsResultItem.RunResults?.ResultCount}</div>
                                                            )
                                                    }
                                                </div>
                                                <div className="flex-column" style={{ minWidth: "300px" }} key={2}>
                                                    <div className="body-m secondary-text">{"Dataset"}</div>
                                                    <div className="body-m primary-text">{props.taskRunsResultItem.RunResults?.CurationDataset}</div>
                                                </div>
                                                <div className="flex-column" style={{ minWidth: "120px" }} key={4}>
                                                    <div className="body-m secondary-text">{"Completed date"}</div>
                                                    <div className="body-m primary-text">{buildCompelteDate()}</div>
                                                </div>
                                            </div>
                                        </Card>
                                        <br />
                                        <Card className="flex-grow" titleProps={{ text: "Ticket", ariaLevel: 4 }}>
                                            <div className="flex-row" style={{ flexWrap: "wrap",marginTop:"20px" }}>
                                                <div className="flex-column" style={{ minWidth: "200px" }} key={1}>
                                                    <div className="body-m secondary-text">{"Ticket created or not?"}</div>
                                                    <div className="body-m primary-text">{buildTicketAndMailStates(props.taskRunsResultItem.RunResults?.TicketingEventTrigged)}</div>
                                                </div>
                                                <div className="flex-column" style={{ minWidth: "300px" }} key={2}>
                                                    <div className="body-m secondary-text">{"Trigger type"}</div>
                                                    <div className="body-m primary-text">{props.taskRunsResultItem.RunResults?.TriggerType}</div>
                                                </div>
                                                <div className="flex-column" style={{ minWidth: "300px" }} key={2}>
                                                    <div className="body-m secondary-text">{"Run type"}</div>
                                                    <div className="body-m primary-text">{props.taskRunsResultItem.RunResults?.BuildRunType}</div>
                                                </div>
                                                <div className="flex-column" style={{ minWidth: "200px" }} key={3}>
                                                    <div className="body-m secondary-text">{"Email sent or not?"}</div>
                                                    <div className="body-m primary-text">{buildTicketAndMailStates(props.taskRunsResultItem.RunResults?.MailDelivered)}</div>
                                                </div>                                                
                                            </div>
                                        </Card>
                                    </div>
                                </>
                            )
                    }

                </Page>
            </Surface>
        </div >
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(TaskRunResult);
