import * as React from "react";

import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Ago } from "azure-devops-ui/Ago";
import { Button } from "azure-devops-ui/Button";
import { Card } from "azure-devops-ui/Card";
import { ConditionalChildren } from "azure-devops-ui/ConditionalChildren";
import { Duration } from "azure-devops-ui/Duration";
import { FilterBar } from "azure-devops-ui/FilterBar";
import { Header, TitleSize } from "azure-devops-ui/Header";
import {
    IHeaderCommandBarItem,
    HeaderCommandBarWithFilter
} from "azure-devops-ui/HeaderCommandBar";
import { IIconProps, Icon } from "azure-devops-ui/Icon";
import { Link } from "azure-devops-ui/Link";
import { Observer } from "azure-devops-ui/Observer";
import { Page } from "azure-devops-ui/Page";
import { DropdownFilterBarItem } from "azure-devops-ui/Dropdown";
import { IListBoxItem } from "azure-devops-ui/ListBox";
import { Status, Statuses, StatusSize, IStatusProps } from "azure-devops-ui/Status";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import {
    ITableColumn,
    SimpleTableCell,
    TableCell,
    Table,
    TwoLineTableCell,
    ColumnSorting,
    SortOrder,
    sortItems
} from "azure-devops-ui/Table";
import { Tab, TabBar } from "azure-devops-ui/Tabs";
import { KeywordFilterBarItem } from "azure-devops-ui/TextFilterBarItem";
import { Tooltip } from "azure-devops-ui/TooltipEx";
import { css } from "azure-devops-ui/Util";
import { IFilter, Filter, FILTER_CHANGE_EVENT } from "azure-devops-ui/Utilities/Filter";
import { DropdownMultiSelection } from "azure-devops-ui/Utilities/DropdownSelection";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";

import {
  CustomHeader,
  HeaderDescription,
  HeaderIcon,
  HeaderTitle,
  HeaderTitleArea,
  HeaderTitleRow
} from "azure-devops-ui/Header";
import { commandBarItemsAdvanced } from "./Demo1Data";
import { HeaderCommandBar } from "azure-devops-ui/HeaderCommandBar";
import { Pill, PillSize, PillVariant } from "azure-devops-ui/Pill";
import { PillGroup } from "azure-devops-ui/PillGroup";

import { PieChart, IPieChartProps } from '@uifabric/charting/lib/PieChart';
import { DefaultPalette } from 'office-ui-fabric-react/lib/Styling';
import { IDataPoint } from '@uifabric/charting/lib/types/IDataPoint';
import { Legends, ILegend } from '@uifabric/charting/lib/Legends';

import "./Demo.css";

enum PipelineStatus {
    running = "running",
    succeeded = "succeeded",
    failed = "failed",
    warning = "warning"
}

enum ReleaseType {
    prAutomated,
    tag,
    scheduled,
    manual
}

const selectedTabId = new ObservableValue<string>("home");
const filterToggled = new ObservableValue<boolean>(false);
const filter = new Filter();
const dropdownSelection = new DropdownMultiSelection();

const headerCommands: IHeaderCommandBarItem[] = [
    {
        id: "new-pipeline",
        text: "New pipeline",
        onActivate: () => {
            alert("New pipeline");
        },
        important: true
    }
];

const tabBarCommands: IHeaderCommandBarItem[] = [
];


const stats = [
    {
        label: "Inventories",
        value: 20,
        icon: PipelineStatus.running,
        className: "ms-Icon ms-Icon--Website"
        
    },
    {
        label: "Assets",
        value: "3 M",
        icon: PipelineStatus.running,
        className: "ms-Icon ms-Icon--PageList"
    },    
    {
        label: "Asset types",
        value: 8,
        icon: PipelineStatus.failed,
        className: "ms-Icon ms-Icon--GroupList"
    },
    {
        label: "Languages",
        value: 10,
        icon: PipelineStatus.warning,
        className: "ms-Icon ms-Icon--LocaleLanguage"
    },
    {
        label: "Content sets",
        value: "125 Repos",
        icon: PipelineStatus.succeeded,
        className: "ms-Icon ms-Icon--HardDriveGroup"
    }
];

const stats3 = [
    {
        label: "Compliance areas",
        value: "8 (PII etc..)",
        icon: PipelineStatus.failed,
        className: "ms-Icon ms-Icon--HardDriveGroup"
    },
    {
        label: "Compliance policies (algorithms)",
        value: "150",
        icon: PipelineStatus.failed,
        className: "ms-Icon ms-Icon--HardDriveGroup"
    }
];

const stats2 = [
    {
        label: "Remediated issues",
        value: 2838,
        icon: PipelineStatus.succeeded,
        className: "ms-Icon ms-Icon--Website"
    },
    {
        label: "Recommendations",
        value: 2000,
        icon: PipelineStatus.warning,
        className: "ms-Icon ms-Icon--Website"
    },
    {
        label: "Active incidents",
        value: 258,
        icon: PipelineStatus.failed,
        className: "ms-Icon ms-Icon--Website"
    }
];
export default class Demo2 extends React.Component<{}> {
  private renderStatus = (className?: string) => {
    return <Status {...Statuses.Success} className={className} size={StatusSize.l} />;
  };

    public render() {
        return (
          <div>
            <Surface background={SurfaceBackground.neutral}>
                <Page className="pipelines-page flex-grow">

                <CustomHeader className="bolt-header-with-commandbar">
                    <HeaderIcon
                        className="bolt-table-status-icon-large"
                        iconProps={{ render: this.renderStatus }}
                        titleSize={TitleSize.Large}
                    />
                    <HeaderTitleArea>
                        <HeaderTitleRow>
                            <HeaderTitle ariaLevel={3} className="text-ellipsis" titleSize={TitleSize.Large}>
                            Content Health Assessment Center
                            </HeaderTitle>
                        </HeaderTitleRow>
                        <HeaderDescription>
                            The report is dynamically generated today at 6:27 pm
                        </HeaderDescription>
                    </HeaderTitleArea>
                    <HeaderCommandBar items={commandBarItemsAdvanced} />
                </CustomHeader>
                <Header
                        title="Overview"
                    />
                <div className="ms-Grid">
                    <div className="ms-Grid-row page-content page-content-top row1">
                      <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8 ">
                      <Card 
                      className="flex-grow"
                      collapsible={true}                     
                      titleProps={{
                        text: 'Content Corpus Stats',
                        ariaLevel: 3,
                      }} >
 <div className="flex-row" style={{ flexWrap: "wrap" }}>
                    {stats.map((items, index) => (
                        <div className="flex-column" style={{ minWidth: "150px" }} key={index}>
                            
                            <div className="body-m primary-text overviewCenter">
                            <i
                                className={items.className}
                                aria-hidden="true"
                            ></i>{items.value}</div>
                            <div className="body-m secondary-text">{items.label}</div>                            
                        </div>
                    ))}
                </div>
                      </Card>
                      </div>
                      <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                      <Card 
                      className="flex-grow"
                      collapsible={true}                     
                      titleProps={{
                        text: 'Compliance stats',
                        ariaLevel: 3,
                      }} >
 <div className="flex-row" style={{ flexWrap: "wrap" }}>
                    {stats3.map((items, index) => (
                        <div className="flex-column" style={{ minWidth: "150px" }} key={index}>
                            
                            <div className="body-m primary-text overviewCenter">
                            <i
                                className={items.className}
                                aria-hidden="true"
                            ></i>{items.value}</div>
                            <div className="body-m secondary-text">{items.label}</div>                            
                        </div>
                    ))}
                </div>
                      </Card>
</div>
                      </div>
                      </div>

                      
          

                      <Header
                        title="Health Stats"
                    /> 
                    <div className="page-content page-content-top">
                    <Card
                className="flex-grow bolt-card-no-vertical-padding"
            >
                     <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm3 ms-md2 ms-lg2">
                      <PieChartDynamicExample 
                      chartTitle="Inventories"
                      data={[{ x: 'Unhealthy', y: 13 }, { x: 'Good', y: 7 }]}
                      colors={[DefaultPalette.red, DefaultPalette.green]}
                      ></PieChartDynamicExample>
                      </div>
                      <div className="ms-Grid-col ms-sm3 ms-md2 ms-lg2">
                      <PieChartDynamicExample 
                      chartTitle="Content sets"
                      data={[{ x: 'Unhealthy', y: 80 }, { x: 'Good', y: 45 }]}
                      colors={[DefaultPalette.red, DefaultPalette.green]}
                      ></PieChartDynamicExample>
                      </div>                      
                      <div className="ms-Grid-col ms-sm3 ms-md2 ms-lg2">
                      <PieChartDynamicExample
                      chartTitle="Assets"
                      data={[{ x: 'Unhealthy', y: 90000 }, { x: 'Good', y: 30000 }]}
                      colors={[DefaultPalette.red, DefaultPalette.green]}
                      ></PieChartDynamicExample>                      
                      </div>
                      <div className="ms-Grid-col ms-sm3 ms-md2 ms-lg2">
                      <PieChartDynamicExample
                      chartTitle="Unhealthy asset types"
                      data={[{ x: 'Doc', y: 183 }, { x: 'JPG', y: 250 }, { x: 'MPG', y: 243 }]}
                      colors={[DefaultPalette.red, DefaultPalette.redDark, DefaultPalette.yellow]}
                      ></PieChartDynamicExample>
                      </div>
                      <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                      <PieChartDynamicExample
                      chartTitle="Unhealthy compliance areas"
                      data={[{ x: 'L10N', y: 20 }, { x: 'PII', y: 18 }, { x: 'Accessibility', y: 25 }]}
                      colors={[DefaultPalette.yellow, DefaultPalette.red, DefaultPalette.yellowDark]}
                      ></PieChartDynamicExample>                      
                      </div>
                      </div>
                      </Card>
                      </div>
                      <Header
                        title="Action Stats"
                    />
                     <div className="page-content page-content-top">

                     <Card className="flex-grow">
                <div className="flex-row" style={{ flexWrap: "wrap" }}>
                    {stats2.map((items, index) => (
                        <div className="flex-column" style={{ minWidth: "150px" }} key={index}>
                            
                            <div className="body-m primary-text overviewCenter">
                            <Status
                {...getStatusIndicatorData(items.icon).statusProps}
                className="icon-large-margin"
                size={StatusSize.m}
            />{items.value}</div>
                            <div className="body-m secondary-text">{items.label}</div>                            
                        </div>
                    ))}
                </div>
            </Card>
                      </div>


                <Header
                        title="Actions for PII issues"
                    />
                    <TabBar
                        selectedTabId={selectedTabId}
                        onSelectedTabChanged={this.onSelectedTabChanged}
                        renderAdditionalContent={this.renderTabBarCommands}
                        disableSticky={false}
                    >
                        <Tab id="home" name="Incidents and Alerts (2)" />
                        <Tab id="home2" name="Recommendations (2)" />
                        <Tab id="home3" name="Remediated issues (38)" />
                    </TabBar>
                    <ConditionalChildren renderChildren={filterToggled}>
                        <div className="page-content-left page-content-right page-content-top">
                            <FilterBar
                                filter={filter}
                                onDismissClicked={this.onFilterBarDismissClicked}
                            >
                                <KeywordFilterBarItem filterItemKey="keyword" />
                                <DropdownFilterBarItem
                                    filterItemKey="status"
                                    filter={filter}
                                    items={this.getStatuses().map(this.getStatusListItem)}
                                    selection={dropdownSelection}
                                    placeholder="Status"
                                />
                            </FilterBar>
                        </div>
                    </ConditionalChildren>
                    <div className="page-content page-content-top">
                        <PipelinesListingPageContent filter={filter} />
                    </div>
                    <Header
                        title="Actions for accessibility issues"
                    />
                  <TabBar
                        selectedTabId={selectedTabId}
                        onSelectedTabChanged={this.onSelectedTabChanged}
                        renderAdditionalContent={this.renderTabBarCommands}
                        disableSticky={false}
                    >
                        <Tab id="home" name="Incidents and Alerts (2)" />
                        <Tab id="home2" name="Recommendations (2)" />
                        <Tab id="home3" name="Remediated issues (38)" />
                    </TabBar>
                    <ConditionalChildren renderChildren={filterToggled}>
                        <div className="page-content-left page-content-right page-content-top">
                            <FilterBar
                                filter={filter}
                                onDismissClicked={this.onFilterBarDismissClicked}
                            >
                                <KeywordFilterBarItem filterItemKey="keyword" />
                                <DropdownFilterBarItem
                                    filterItemKey="status"
                                    filter={filter}
                                    items={this.getStatuses().map(this.getStatusListItem)}
                                    selection={dropdownSelection}
                                    placeholder="Status"
                                />
                            </FilterBar>
                        </div>
                    </ConditionalChildren>
                    <div className="page-content page-content-top">
                        <PipelinesListingPageContent2 filter={filter} />
                    </div>
                    <Header
                        title="Actions for localization issues"
                    />
                  <TabBar
                        selectedTabId={selectedTabId}
                        onSelectedTabChanged={this.onSelectedTabChanged}
                        renderAdditionalContent={this.renderTabBarCommands}
                        disableSticky={false}
                    >
                        <Tab id="home" name="Incidents and Alerts (2)" />
                        <Tab id="home2" name="Recommendations (2)" />
                        <Tab id="home3" name="Remediated issues (38)" />
                    </TabBar>
                    <ConditionalChildren renderChildren={filterToggled}>
                        <div className="page-content-left page-content-right page-content-top">
                            <FilterBar
                                filter={filter}
                                onDismissClicked={this.onFilterBarDismissClicked}
                            >
                                <KeywordFilterBarItem filterItemKey="keyword" />
                                <DropdownFilterBarItem
                                    filterItemKey="status"
                                    filter={filter}
                                    items={this.getStatuses().map(this.getStatusListItem)}
                                    selection={dropdownSelection}
                                    placeholder="Status"
                                />
                            </FilterBar>
                        </div>
                    </ConditionalChildren>
                    <div className="page-content page-content-top rowbottom">
                        <PipelinesListingPageContent3 filter={filter} />
                    </div>
                </Page>
            </Surface>
            </div>
        );
    }

    private onFilterBarDismissClicked = () => {
        filterToggled.value = !filterToggled.value;
    };

    private renderTabBarCommands = () => {
        return (
            <HeaderCommandBarWithFilter
                filter={filter}
                filterToggled={filterToggled}
                items={tabBarCommands}
            />
        );
    };

    private onSelectedTabChanged = (newTabId: string) => {
        selectedTabId.value = newTabId;
    };

    private getStatuses = () => {
        return [
            PipelineStatus.succeeded,
            PipelineStatus.failed,
            PipelineStatus.warning,
            PipelineStatus.running
        ];
    };
    private getStatusListItem = (status: PipelineStatus): IListBoxItem<PipelineStatus> => {
        const statusDetail = getStatusIndicatorData(status);

        return {
            data: status,
            id: status,
            text: statusDetail.label,
            iconProps: {
                render: className => (
                    <Status
                        {...statusDetail.statusProps}
                        className={css(className, statusDetail.statusProps.className)}
                        size={StatusSize.m}
                        animated={false}
                    />
                )
            }
        };
    };
}

interface IPipelinesListingPageContentProps {
    filter: IFilter;
}

interface IPipelinesListingPageContentState {
    filtering: boolean;
    sortedItems: IPipelineItem[];
    filteredItems: IPipelineItem[];
}

class PipelinesListingPageContent extends React.Component<
    IPipelinesListingPageContentProps,
    IPipelinesListingPageContentState
> {
    constructor(props: IPipelinesListingPageContentProps) {
        super(props);

        this.state = {
            filtering: false,
            filteredItems: [...pipelineItems],
            sortedItems: [...pipelineItems]
        };
    }

    render() {
        if (this.state.filtering && this.state.filteredItems.length === 0) {
            return "No pipeline items";
        }
        return (
            <Card
                className="flex-grow bolt-card-no-vertical-padding"
                contentProps={{ contentPadding: false }}
            >
                <Table<Partial<IPipelineItem>>
                    behaviors={[this.sortingBehavior]}
                    columns={this.columns}
                    itemProvider={new ArrayItemProvider<IPipelineItem>(this.state.filteredItems)}
                    showLines={true}
                    onSelect={(event, data) => console.log("Selected Row - " + data.index)}
                    onActivate={(event, row) => console.log("Activated Row - " + row.index)}
                />
            </Card>
        );
    }

    componentDidMount() {
        this.props.filter.subscribe(this.onFilterChanged, FILTER_CHANGE_EVENT);
    }

    componentWillUnmount() {
        this.props.filter.unsubscribe(this.onFilterChanged, FILTER_CHANGE_EVENT);
    }

    private onFilterChanged = () => {
        const filteredItems = this.filterItems(this.state.sortedItems);
        this.setState({
            filtering: this.props.filter.hasChangesToReset(),
            filteredItems: filteredItems
        });
    };

    private filterItems = (items: IPipelineItem[]) => {
        if (this.props.filter.hasChangesToReset()) {
            const filterText = this.props.filter.getFilterItemValue<string>("keyword");
            const statuses = this.props.filter.getFilterItemValue<PipelineStatus[]>("status");
            const filteredItems = items.filter(item => {
                let includeItem = true;
                if (filterText) {
                    includeItem = item.name.indexOf(filterText) !== -1;
                }
                if (includeItem && statuses && statuses.length) {
                    includeItem = statuses.some(s => s === item.status);
                }
                return includeItem;
            });
            return filteredItems;
        } else {
            return [...items];
        }
    };

    private sortFunctions = [
        // Sort on Name column
        (item1: IPipelineItem, item2: IPipelineItem): number => {
            return item1.name.localeCompare(item2.name);
        }
    ];

    // Create the sorting behavior (delegate that is called when a column is sorted).
    private sortingBehavior = new ColumnSorting<IPipelineItem>(
        (
            columnIndex: number,
            proposedSortOrder: SortOrder,
            event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
        ) => {
            const sortedItems = sortItems(
                columnIndex,
                proposedSortOrder,
                this.sortFunctions,
                this.columns,
                this.state.sortedItems
            );
            this.setState({ sortedItems, filteredItems: this.filterItems(sortedItems) });
        }
    );

    
    private columns: ITableColumn<IPipelineItem>[] = [
        {
            id: "name",
            name: "Compliance Policy",
            readonly: true,
            renderCell: renderNameColumn,
            sortProps: {
                ariaLabelAscending: "Sorted A to Z",
                ariaLabelDescending: "Sorted Z to A"
            },
            width: new ObservableValue(-40)
        },
        {
            id: "type",
            name: "Area Type",
            width: new ObservableValue(-8),
            renderCell: renderTypeColumn,
            className: "pipelines-two-line-cell"
        },
        {
            id: "time",
            name: "Severity",
            readonly: true,
            renderCell: renderDateColumn,
            width: new ObservableValue(-10),
            ariaLabel: "Time and duration"
        },
        {
          id: "owner",
          name: "Asset Count",
          readonly: true,
          renderCell: renderOwnerColumn,
          width: new ObservableValue(-10),
          ariaLabel: "Time and duration"
      }
      ,
        { 
            id: "action",
            name: "Remediate it",
            renderCell: renderActionColumn,
            width: new ObservableValue(-10),
            ariaLabel: "Favorite"
        }
        ,
        { 
            id: "favorite",
            renderCell: renderFavoritesColumn,
            width: new ObservableValue(-5),
            ariaLabel: "Favorite"
        }
    ];
}


class PipelinesListingPageContent2 extends React.Component<
    IPipelinesListingPageContentProps,
    IPipelinesListingPageContentState
> {
    constructor(props: IPipelinesListingPageContentProps) {
        super(props);

        this.state = {
            filtering: false,
            filteredItems: [...pipelineItems2],
            sortedItems: [...pipelineItems2]
        };
    }

    render() {
        if (this.state.filtering && this.state.filteredItems.length === 0) {
            return "No pipeline items";
        }
        return (
            <Card
                className="flex-grow bolt-card-no-vertical-padding"
                contentProps={{ contentPadding: false }}
            >
                <Table<Partial<IPipelineItem>>
                    behaviors={[this.sortingBehavior]}
                    columns={this.columns}
                    itemProvider={new ArrayItemProvider<IPipelineItem>(this.state.filteredItems)}
                    showLines={true}
                    onSelect={(event, data) => console.log("Selected Row - " + data.index)}
                    onActivate={(event, row) => console.log("Activated Row - " + row.index)}
                />
            </Card>
        );
    }

    componentDidMount() {
        this.props.filter.subscribe(this.onFilterChanged, FILTER_CHANGE_EVENT);
    }

    componentWillUnmount() {
        this.props.filter.unsubscribe(this.onFilterChanged, FILTER_CHANGE_EVENT);
    }

    private onFilterChanged = () => {
        const filteredItems = this.filterItems(this.state.sortedItems);
        this.setState({
            filtering: this.props.filter.hasChangesToReset(),
            filteredItems: filteredItems
        });
    };

    private filterItems = (items: IPipelineItem[]) => {
        if (this.props.filter.hasChangesToReset()) {
            const filterText = this.props.filter.getFilterItemValue<string>("keyword");
            const statuses = this.props.filter.getFilterItemValue<PipelineStatus[]>("status");
            const filteredItems = items.filter(item => {
                let includeItem = true;
                if (filterText) {
                    includeItem = item.name.indexOf(filterText) !== -1;
                }
                if (includeItem && statuses && statuses.length) {
                    includeItem = statuses.some(s => s === item.status);
                }
                return includeItem;
            });
            return filteredItems;
        } else {
            return [...items];
        }
    };

    private sortFunctions = [
        // Sort on Name column
        (item1: IPipelineItem, item2: IPipelineItem): number => {
            return item1.name.localeCompare(item2.name);
        }
    ];

    // Create the sorting behavior (delegate that is called when a column is sorted).
    private sortingBehavior = new ColumnSorting<IPipelineItem>(
        (
            columnIndex: number,
            proposedSortOrder: SortOrder,
            event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
        ) => {
            const sortedItems = sortItems(
                columnIndex,
                proposedSortOrder,
                this.sortFunctions,
                this.columns,
                this.state.sortedItems
            );
            this.setState({ sortedItems, filteredItems: this.filterItems(sortedItems) });
        }
    );

    
    private columns: ITableColumn<IPipelineItem>[] = [
        {
            id: "name",
            name: "Compliance Policy",
            readonly: true,
            renderCell: renderNameColumn,
            sortProps: {
                ariaLabelAscending: "Sorted A to Z",
                ariaLabelDescending: "Sorted Z to A"
            },
            width: new ObservableValue(-30)
        },
        {
            id: "type",
            name: "Area Type",
            width: new ObservableValue(-8),
            renderCell: renderTypeColumn,
            className: "pipelines-two-line-cell"
        },
        {
            id: "time",
            name: "Severity",
            readonly: true,
            renderCell: renderDateColumn,
            width: new ObservableValue(-10),
            ariaLabel: "Time and duration"
        },
        {
          id: "owner",
          name: "Asset Count",
          readonly: true,
          renderCell: renderOwnerColumn,
          width: new ObservableValue(-10),
          ariaLabel: "Time and duration"
      }
      ,
        { 
            id: "action",
            name: "Remediate it",
            renderCell: renderActionColumn,
            width: new ObservableValue(-10),
            ariaLabel: "Favorite"
        }
        ,
        { 
            id: "favorite",
            renderCell: renderFavoritesColumn,
            width: new ObservableValue(-5),
            ariaLabel: "Favorite"
        }
    ];
}

class PipelinesListingPageContent3 extends React.Component<
    IPipelinesListingPageContentProps,
    IPipelinesListingPageContentState
> {
    constructor(props: IPipelinesListingPageContentProps) {
        super(props);

        this.state = {
            filtering: false,
            filteredItems: [...pipelineItems3],
            sortedItems: [...pipelineItems3]
        };
    }

    render() {
        if (this.state.filtering && this.state.filteredItems.length === 0) {
            return "No pipeline items";
        }
        return (
            <Card
                className="flex-grow bolt-card-no-vertical-padding"
                contentProps={{ contentPadding: false }}
            >
                <Table<Partial<IPipelineItem>>
                    behaviors={[this.sortingBehavior]}
                    columns={this.columns}
                    itemProvider={new ArrayItemProvider<IPipelineItem>(this.state.filteredItems)}
                    showLines={true}
                    onSelect={(event, data) => console.log("Selected Row - " + data.index)}
                    onActivate={(event, row) => console.log("Activated Row - " + row.index)}
                />
            </Card>
        );
    }

    componentDidMount() {
        this.props.filter.subscribe(this.onFilterChanged, FILTER_CHANGE_EVENT);
    }

    componentWillUnmount() {
        this.props.filter.unsubscribe(this.onFilterChanged, FILTER_CHANGE_EVENT);
    }

    private onFilterChanged = () => {
        const filteredItems = this.filterItems(this.state.sortedItems);
        this.setState({
            filtering: this.props.filter.hasChangesToReset(),
            filteredItems: filteredItems
        });
    };

    private filterItems = (items: IPipelineItem[]) => {
        if (this.props.filter.hasChangesToReset()) {
            const filterText = this.props.filter.getFilterItemValue<string>("keyword");
            const statuses = this.props.filter.getFilterItemValue<PipelineStatus[]>("status");
            const filteredItems = items.filter(item => {
                let includeItem = true;
                if (filterText) {
                    includeItem = item.name.indexOf(filterText) !== -1;
                }
                if (includeItem && statuses && statuses.length) {
                    includeItem = statuses.some(s => s === item.status);
                }
                return includeItem;
            });
            return filteredItems;
        } else {
            return [...items];
        }
    };

    private sortFunctions = [
        // Sort on Name column
        (item1: IPipelineItem, item2: IPipelineItem): number => {
            return item1.name.localeCompare(item2.name);
        }
    ];

    // Create the sorting behavior (delegate that is called when a column is sorted).
    private sortingBehavior = new ColumnSorting<IPipelineItem>(
        (
            columnIndex: number,
            proposedSortOrder: SortOrder,
            event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
        ) => {
            const sortedItems = sortItems(
                columnIndex,
                proposedSortOrder,
                this.sortFunctions,
                this.columns,
                this.state.sortedItems
            );
            this.setState({ sortedItems, filteredItems: this.filterItems(sortedItems) });
        }
    );

    
    private columns: ITableColumn<IPipelineItem>[] = [
        {
            id: "name",
            name: "Compliance Policy",
            readonly: true,
            renderCell: renderNameColumn,
            sortProps: {
                ariaLabelAscending: "Sorted A to Z",
                ariaLabelDescending: "Sorted Z to A"
            },
            width: new ObservableValue(-30)
        },
        {
            id: "type",
            name: "Area Type",
            width: new ObservableValue(-8),
            renderCell: renderTypeColumn,
            className: "pipelines-two-line-cell"
        },
        {
            id: "time",
            name: "Severity",
            readonly: true,
            renderCell: renderDateColumn,
            width: new ObservableValue(-10),
            ariaLabel: "Time and duration"
        },
        {
          id: "owner",
          name: "Asset Count",
          readonly: true,
          renderCell: renderOwnerColumn,
          width: new ObservableValue(-10),
          ariaLabel: "Time and duration"
      }
      ,
        { 
            id: "action",
            name: "Remediate it",
            renderCell: renderActionColumn,
            width: new ObservableValue(-10),
            ariaLabel: "Favorite"
        }
        ,
        { 
            id: "favorite",
            renderCell: renderFavoritesColumn,
            width: new ObservableValue(-5),
            ariaLabel: "Favorite"
        }
    ];
}

function modifyNow(days: number, hours: number, minutes: number, seconds: number): Date {
    const now = new Date();
    const newDate = new Date(now as any);
    newDate.setDate(now.getDate() + days);
    newDate.setHours(now.getHours() + hours);
    newDate.setMinutes(now.getMinutes() + minutes);
    newDate.setSeconds(now.getSeconds() + seconds);
    return newDate;
}

function renderNameColumn(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<IPipelineItem>,
    tableItem: IPipelineItem
): JSX.Element {
    return (
        <SimpleTableCell
            columnIndex={columnIndex}
            tableColumn={tableColumn}
            key={"col-" + columnIndex}
            contentClassName="fontWeightSemiBold font-weight-semibold fontSizeM font-size-m scroll-hidden"
        >
            <Status
                {...getStatusIndicatorData(tableItem.status).statusProps}
                className="icon-large-margin"
                size={StatusSize.l}
            />
            <div className="flex-row scroll-hidden">
                <Tooltip overflowOnly={true}>
                    <span className="text-ellipsis">{tableItem.name}</span>
                </Tooltip>
            </div>
        </SimpleTableCell>
    );
}


function renderTypeColumn(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IPipelineItem>,
  tableItem: IPipelineItem
): JSX.Element {
  return (
      <SimpleTableCell
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          key={"col-" + columnIndex}
          contentClassName="fontWeightSemiBold font-weight-semibold fontSizeM font-size-m scroll-hidden"
      >
          <div className="flex-row scroll-hidden">
              <Tooltip overflowOnly={true}>
                  <span className="text-ellipsis">{tableItem.type}</span>
              </Tooltip>
          </div>
      </SimpleTableCell>
  );
}

function renderOwnerColumn(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IPipelineItem>,
  tableItem: IPipelineItem
): JSX.Element {
  return (
      <SimpleTableCell
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          key={"col-" + columnIndex}
          contentClassName="fontWeightSemiBold font-weight-semibold fontSizeM font-size-m scroll-hidden"
      >
          <div className="flex-row scroll-hidden">
              <Tooltip overflowOnly={true}>
                  <span className="text-ellipsis">{tableItem.owner}</span>
              </Tooltip>
          </div>
      </SimpleTableCell>
  );
}


function renderPIIDetailsCell(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IPipelineItem>,
  tableItem: IPipelineItem
): JSX.Element {
  return (
      <SimpleTableCell
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          key={"col-" + columnIndex}
          contentClassName="fontWeightSemiBold font-weight-semibold fontSizeM font-size-m scroll-hidden"
      >
          <div className="flex-row scroll-hidden">
              <Tooltip overflowOnly={true}>
              <PillGroup className="flex-row">
            {tableItem.category.map((category,index) =>
                <Pill size={PillSize.compact} variant={PillVariant.outlined}>{category.name} ({category.score})</Pill>
            )}
            </PillGroup>
              </Tooltip>
          </div>
      </SimpleTableCell>
  );
}

function renderLastRunColumn(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<IPipelineItem>,
    tableItem: IPipelineItem
): JSX.Element {
    const { prName, prId, releaseType, branchName } = tableItem.lastRunData;
    const text = "#" + prId + " \u00b7 " + prName;
    const releaseTypeText = ReleaseTypeText({ releaseType: releaseType });
    const tooltip = `${releaseTypeText} from ${branchName} branch`;
    return (
        <TwoLineTableCell
            className="bolt-table-cell-content-with-inline-link no-v-padding"
            key={"col-" + columnIndex}
            columnIndex={columnIndex}
            tableColumn={tableColumn}
            line1={
                <span className="flex-row scroll-hidden">
                    <Tooltip text={text} overflowOnly>
                        <Link
                            className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                            excludeTabStop
                            href="#pr"
                        >
                            {text}
                        </Link>
                    </Tooltip>
                </span>
            }
            line2={
                <Tooltip text={tooltip} overflowOnly>
                    <span className="fontSize font-size secondary-text flex-row flex-baseline text-ellipsis">
                        {ReleaseTypeIcon({ releaseType: releaseType })}
                        <span className="text-ellipsis" key="release-type-text">
                            {releaseTypeText}
                        </span>
                        <Link
                            className="monospaced-text text-ellipsis flex-row flex-baseline bolt-table-link bolt-table-inline-link"
                            excludeTabStop
                            href="#branch"
                        >
                            {Icon({
                                className: "icon-margin",
                                iconName: "OpenSource",
                                key: "branch-name"
                            })}
                            {branchName}
                        </Link>
                    </span>
                </Tooltip>
            }
        />
    );
}

function renderDateColumn(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<IPipelineItem>,
    tableItem: IPipelineItem
): JSX.Element {
    return (
        <SimpleTableCell
        columnIndex={columnIndex}
        tableColumn={tableColumn}
        key={"col-" + columnIndex}
        contentClassName="fontWeightSemiBold font-weight-semibold fontSizeM font-size-m scroll-hidden"
    >
       {tableItem.severity}
    </SimpleTableCell>
    );
}


function renderActionColumn(
  rowIndex: number,
  columnIndex: number,
  tableColumn: ITableColumn<IPipelineItem>,
  tableItem: IPipelineItem
): JSX.Element {
  return (
      <TableCell
          className="bolt-table-cell-side-action"
          columnIndex={columnIndex}
          tableColumn={tableColumn}
          key={"col-" + columnIndex}
      >
          <div className="bolt-list-cell-content flex-column">
              <Observer favorite={tableItem.favorite}>
                  {(props: { favorite: boolean }) => {
                      return (
                          <Button
                              ariaLabel={"Favorite"}
                              ariaPressed={props.favorite}
                              excludeTabStop={true}
                              text="File a task to authors"
                          />
                      );
                  }}
              </Observer>
          </div>
      </TableCell>
  );
}

function renderFavoritesColumn(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<IPipelineItem>,
    tableItem: IPipelineItem
): JSX.Element {
    return (
        <TableCell
            className="bolt-table-cell-side-action"
            columnIndex={columnIndex}
            tableColumn={tableColumn}
            key={"col-" + columnIndex}
        >
            <div className="bolt-list-cell-content flex-column">
                <Observer favorite={tableItem.favorite}>
                    {(props: { favorite: boolean }) => {
                        return (
                            <Button
                                ariaLabel={"Favorite"}
                                ariaPressed={props.favorite}
                                className={css(!props.favorite && "bolt-table-cell-content-reveal")}
                                excludeTabStop={true}
                                iconProps={{
                                    iconName: props.favorite ? "FavoriteStarFill" : "FavoriteStar",
                                    className: props.favorite ? "yellow" : undefined
                                }}
                                onClick={e => {
                                    tableItem.favorite.value = !tableItem.favorite.value;
                                    e.preventDefault();
                                }}
                                subtle
                            />
                        );
                    }}
                </Observer>
            </div>
        </TableCell>
    );
}

function WithIcon(props: {
    className?: string;
    iconProps: IIconProps;
    children?: React.ReactNode;
}) {
    return (
        <div className={css(props.className, "flex-row flex-center")}>
            {Icon({ ...props.iconProps, className: "icon-margin" })}
            {props.children}
        </div>
    );
}

function ReleaseTypeIcon(props: { releaseType: ReleaseType }) {
    let iconName: string = "";
    switch (props.releaseType) {
        case ReleaseType.prAutomated:
            iconName = "BranchPullRequest";
            break;
        default:
            iconName = "Tag";
    }

    return Icon({
        className: "bolt-table-inline-link-left-padding icon-margin",
        iconName: iconName,
        key: "release-type"
    });
}

function ReleaseTypeText(props: { releaseType: ReleaseType }) {
    switch (props.releaseType) {
        case ReleaseType.prAutomated:
            return "PR Automated";
        case ReleaseType.manual:
            return "Manually triggered";
        case ReleaseType.scheduled:
            return "Scheduled";
        default:
            return "Release new-features";
    }
}

const tempPipelineItems = [
    {
        name: "The content shouldn't have personal name information",
        type: "PII",
        owner: "2",
        severity: 2,
        category:[{name:"Person", score:"0.98"},{name:"PhoneNumber", score:"0.85"}],
        status: PipelineStatus.failed,
        lastRunData: {
            prId: 482,
            prName: "Added testing for get_service_instance_stats",
            startTime: modifyNow(0, -1, 0, 0),
            endTime: modifyNow(0, -1, 23, 8),
            releaseType: ReleaseType.prAutomated,
            branchName: "master"
        },
        favorite: new ObservableValue<boolean>(true)
    },
    {
        name: "The content shouldn't have phone number information",
        type: "PII",
        severity: 2,
        owner: "1",
        category:[{name:"Person", score:"0.98"},{name:"PhoneNumber", score:"0.85"}],
        status: PipelineStatus.failed,
        lastRunData: {
            prId: 137,
            prName: "Update user service",
            startTime: modifyNow(-1, 0, 0, 0),
            endTime: modifyNow(-1, 0, 5, 2),
            releaseType: ReleaseType.tag,
            branchName: "master"
        },
        favorite: new ObservableValue<boolean>(true)
    }
];

const tempPipelineItems2 = [
    {
        name: "Images should include equivalent alternative text (alt text) in the markup/code.",
        type: "Accessibility",
        owner: "2",
        severity: 3,
        category:[{name:"Person", score:"0.98"},{name:"PhoneNumber", score:"0.85"}],
        status: PipelineStatus.warning,
        lastRunData: {
            prId: 482,
            prName: "Added testing for get_service_instance_stats",
            startTime: modifyNow(0, -1, 0, 0),
            endTime: modifyNow(0, -1, 23, 8),
            releaseType: ReleaseType.prAutomated,
            branchName: "master"
        },
        favorite: new ObservableValue<boolean>(true)
    },
    {
        name: "Color contrast between the foreground and background is not sufficiently strong.",
        type: "Accessibility",
        owner: "1",
        severity: 3,
        category:[{name:"Person", score:"0.98"},{name:"PhoneNumber", score:"0.85"}],
        status: PipelineStatus.warning,
        lastRunData: {
            prId: 137,
            prName: "Update user service",
            startTime: modifyNow(-1, 0, 0, 0),
            endTime: modifyNow(-1, 0, 5, 2),
            releaseType: ReleaseType.tag,
            branchName: "master"
        },
        favorite: new ObservableValue<boolean>(true)
    }
];

const tempPipelineItems3 = [
    {
        name: "Avoid using ambiguous modal auxiliary verbs.",
        type: "Localization",
        owner: "2",
        severity: 3,
        category:[{name:"Person", score:"0.98"},{name:"PhoneNumber", score:"0.85"}],
        status: PipelineStatus.warning,
        lastRunData: {
            prId: 482,
            prName: "Added testing for get_service_instance_stats",
            startTime: modifyNow(0, -1, 0, 0),
            endTime: modifyNow(0, -1, 23, 8),
            releaseType: ReleaseType.prAutomated,
            branchName: "master"
        },
        favorite: new ObservableValue<boolean>(true)
    },
    {
        name: "Code content shouldn't be translated",
        type: "Localization",
        owner: "1",
        severity: 3,
        category:[{name:"Person", score:"0.98"},{name:"PhoneNumber", score:"0.85"}],
        status: PipelineStatus.warning,
        lastRunData: {
            prId: 137,
            prName: "Update user service",
            startTime: modifyNow(-1, 0, 0, 0),
            endTime: modifyNow(-1, 0, 5, 2),
            releaseType: ReleaseType.tag,
            branchName: "master"
        },
        favorite: new ObservableValue<boolean>(true)
    }
];

const pipelineItems: IPipelineItem[] = [];
const pipelineItems2: IPipelineItem[] = [];
const pipelineItems3: IPipelineItem[] = [];

for (let i = 0; i < 1; i++) {
    pipelineItems.push(...tempPipelineItems);
}

for (let i = 0; i < 1; i++) {
    pipelineItems2.push(...tempPipelineItems2);
}

for (let i = 0; i < 1; i++) {
    pipelineItems3.push(...tempPipelineItems3);
}

interface IPipelineLastRun {
    startTime?: Date;
    endTime?: Date;
    prId: number;
    prName: string;
    releaseType: ReleaseType;
    branchName: string;
}

interface IPipelineItem {
    name: string;
    type: string;
    severity: number;
    owner: string;
    category: ICategories[];
    status: PipelineStatus;
    lastRunData: IPipelineLastRun;
    favorite: ObservableValue<boolean>;
}
export interface ICategories {
  name: string;
  score: string;
}
interface IStatusIndicatorData {
    statusProps: IStatusProps;
    label: string;
}

function getStatusIndicatorData(status: string): IStatusIndicatorData {
    status = status || "";
    status = status.toLowerCase();
    let indicatorData: IStatusIndicatorData = {
        statusProps: Statuses.Success,
        label: "Success"
    };
    switch (status) {
        case PipelineStatus.failed:
            indicatorData.statusProps = Statuses.Failed;
            indicatorData.label = "Failed";
            break;
        case PipelineStatus.running:
            indicatorData.statusProps = Statuses.Running;
            indicatorData.label = "Running";
            break;
        case PipelineStatus.warning:
            indicatorData.statusProps = Statuses.Warning;
            indicatorData.label = "Warning";

            break;
    }

    return indicatorData;
}


export interface IExampleState {
  dynamicData: IDataPoint[];
  colors: string[];
  title: string;
}

export class PieChartDynamicExample extends React.Component<IPieChartProps, IExampleState> {
  private _colors = [
    [DefaultPalette.blueLight, DefaultPalette.blue, DefaultPalette.tealLight, DefaultPalette.teal, DefaultPalette.greenLight],
    [DefaultPalette.purpleLight, DefaultPalette.purple, DefaultPalette.magentaLight, DefaultPalette.magenta],
    [DefaultPalette.yellowLight, DefaultPalette.yellow, DefaultPalette.orangeLighter, DefaultPalette.orangeLight],
    [DefaultPalette.neutralLight, DefaultPalette.neutralQuaternary, DefaultPalette.neutralTertiary]
  ];

  constructor(props: IPieChartProps) {
    super(props);
    this.state = {
      dynamicData: props.data,
      colors: props.colors,
      title: props.chartTitle
    };

    this._changeData = this._changeData.bind(this);
    this._changeColors = this._changeColors.bind(this);
  }

  public render(): JSX.Element {
    return (
      <div className="PieSection">
        <PieChart width={160} height={160} data={this.state.dynamicData} chartTitle={this.state.title} colors={this.state.colors} />
      </div>
    );
  }

  private _changeData(): void {
    const a = this._randomY(40);
    const b = this._randomY(100 - a - 20);
    const c = this._randomY(100 - a - b - 10);
    const d = 100 - a - b - c;

    this.setState({
      dynamicData: [{ x: 'A', y: a }, { x: 'B', y: b }, { x: 'C', y: c }, { x: 'D', y: d }]
    });
  }

  private _changeColors(): void {
    this.setState({
      colors: [this._randomColor(0), this._randomColor(1), this._randomColor(2), this._randomColor(3)]
    });
  }

  private _randomY(max: number): number {
    return Math.floor(Math.random() * max + 5);
  }

  private _randomColor(index: number): string {
    return this._colors[index][Math.floor(Math.random() * this._colors[index].length)];
  }
}


export class LegendBasicExample extends React.Component<{}, {}> {
  public render(): JSX.Element {
    const legends: ILegend[] = [
      {
        title: 'PII (-10)',
        color: DefaultPalette.red,
        action: () => {
          console.log('click from LegendsPage');
        },
        onMouseOutAction: () => {
          console.log('On mouse out action');
        },
        hoverAction: () => {
          console.log('hover action');
        }
      },
      {
        title: 'Accessibility (-5)',
        color: DefaultPalette.yellow,
        action: () => {
        },
        hoverAction: () => {
          console.log('hover action');
        }
      },
      {
        title: 'Localization (-5)',
        color: DefaultPalette.yellow,
        action: () => {
        },
        hoverAction: () => {
          console.log('hover action');
        }
      },
      {
        title: 'Publishing workflow progress (10)',
        color: DefaultPalette.green,
        action: () => {
        },
        hoverAction: () => {
          console.log('hover action');
        }
      },
      {
        title: 'Customer feedback (10)',
        color: DefaultPalette.green,
        action: () => {
        },
        hoverAction: () => {
          console.log('hover action (10)');
        }
      },
      {
        title: 'Lifecycle (10)',
        color: DefaultPalette.green,
        action: () => {
        },
        hoverAction: () => {
          console.log('hover action');
        }
      },
      {
        title: 'Style (10)',
        color: DefaultPalette.green,
        action: () => {
        },
        hoverAction: () => {
          console.log('hover action');
        }
      },
      {
        title: 'Monetization (10)',
        color: DefaultPalette.green,
        action: () => {
        },
        hoverAction: () => {
          console.log('hover action');
        }
      }
    ];

    return <Legends legends={legends} />;
  }
}
