import React, { Component } from "react";
import moment from "moment";
import * as _ from "lodash";
import { TextField, DatePicker, DayOfWeek, ComboBox } from "@fluentui/react";

import {
  TEXT,
  DATE,
  NUMERICAL,
  BOOLEAN,
  USERNAME,
  FULLTEXT,
  SYSCHANGEDDATE
} from "../../Utilities/QueryBuilderConstants";

export interface ValueFieldProps {
  type: string;
  disable: boolean;
  index: number;
  value: string;
  options: string[];
  onChange: (index: number, value: string | Date) => void;
  onFieldValueChanged: (index: number, newValue: string, type: boolean) => void;
  onGetErrorMessage: (value: string) => string;
}

export default class ValueField extends Component<ValueFieldProps> {
  onFieldValueChange = (_event: React.FormEvent, newValue: string) => {
    this.props.onFieldValueChanged(this.props.index, newValue, false);
  };

  static defaultProps: { onGetErrorMessage: (value: string) => void };

  render() {
    switch (this.props.type) {
      /**
       * Input control for username types
       */
      case USERNAME:
        return (
          <TextField
            disabled={this.props.disable}
            onChange={this.onFieldValueChange}
            value={this.props.value}
            onGetErrorMessage={this.props.onGetErrorMessage}
          />
        )
      /**
       * Input control for string types
       */
      case TEXT:
        /**
         * if metadata type is an Enum
         */
        if (this.props.options !== null && this.props.options.length > 0) {
          return (
            <ComboBox
              options={_.map(this.props.options, option => ({
                key: option,
                text: option
              }))}
              onChange={(e, newValue) => {
                this.props.onChange(this.props.index, newValue.key as string)
              }}
              selectedKey={this.props.value}
            />
          )
        } else {
          /**
           * type is any string
           */
          return (
            <TextField
              disabled={this.props.disable}
              onChange={this.onFieldValueChange}
              value={this.props.value}
              onGetErrorMessage={this.props.onGetErrorMessage}
            />
          )
        }
      /**
       * Input control for Date types
       */
      case DATE:
        const localDate = new Date(moment(this.props.value).local().valueOf())

        return (
          <DatePicker
            firstDayOfWeek={DayOfWeek.Sunday}
            disabled={this.props.disable}
            onSelectDate={(date: Date) => {
              const utcDate = moment.utc(date).format('YYYY-MM-DDTHH:mm:ssZ')
              this.props.onChange(this.props.index, utcDate)
            }}
            value={localDate}
          />
        )
      /**
       * Input control for numeric types
       */
      case NUMERICAL:
        return (
          <TextField
            type="number"
            value={this.props.value}
            onChange={(e, newValue) => {
              this.props.onChange(this.props.index, newValue)
            }}
            onGetErrorMessage={this.props.onGetErrorMessage}
          />
        )
      /**
       * Input control for Boolean types
       */
      case BOOLEAN:
        return (
          <ComboBox
            disabled={this.props.disable}
            options={[
              { key: 'true', text: 'true' },
              { key: 'false', text: 'false' }
            ]}
            onChange={(e, newValue) => {
              this.props.onChange(this.props.index, newValue.key as string)
            }}
            selectedKey={this.props.value}
          />
        )
      case FULLTEXT:
        return (
          <TextField
            disabled={this.props.disable}
            onChange={this.onFieldValueChange}
            value={this.props.value}
            onGetErrorMessage={this.props.onGetErrorMessage}
          />
        )
      case SYSCHANGEDDATE:
        /**
         * if metadata type is an Enum
         */
        if (this.props.options !== null && this.props.options.length > 0) {
          return (
            <ComboBox
              options={_.map(this.props.options, option => ({
                key: option,
                text: option
              }))}
              onChange={(e, newValue) => {
                this.props.onChange(this.props.index, newValue.key as string)
              }}
              selectedKey={this.props.value}
            />
          )
        } else {
          /**
           * type is any string
           */
          return (
            <TextField
              disabled={this.props.disable}
              onChange={this.onFieldValueChange}
              value={this.props.value}
              onGetErrorMessage={this.props.onGetErrorMessage}
            />
          )
        }
      default:
        return <TextField disabled={true} />
    }
  }
}

ValueField.defaultProps = {
  onGetErrorMessage: _.noop,
};
