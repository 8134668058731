// import axios
import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    CancelTokenSource,
} from "axios";
import { selectIndex } from "../../redux/Actions/PowerDataQuery";
import { Task, CurationFilter, TriggerSettings, ITicketEvent, ITextSearchSettings } from '../Interface/ITask'
import { ITaskSearch } from '../Interface/ITaskSearch'
import { ICurationRun, ICurationRunWithName } from '../Interface/ICurationRun'
import { ITaskRunsResult } from '../Interface/ITaskRunsResult'
import { ITagItem } from '../Interface/ITag'
import { IRemediateIt } from "../Interface/IActionFixIt";

// import powerquery props
import {
    DataQueryIndex,
    DataField,
    MetaDataQueryParams,
    IWelcomeStats,
    IDataSource,
    IReportIssueParam,
    IScanIssueData
} from "../Interface/IPowerQuery";
import { getOderByFields } from './QueryBuilderExtension'

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { loginRequest } from "../../msal/authConfig";
import { getAppInsights, ctService } from './TelemetryService'
import { msalInstance } from "../../index";
// import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";
// import { PublicClientApplication, EventType } from "@azure/msal-browser";
// import { msalAuth } from '../../msal/MsalAuthProvider'
// const { instance, accounts } = useMsal();
// const userAccount = useAccount(accounts[0]);
// import { msalConfig } from "../../msal/authConfig";
// const msalInstance = new PublicClientApplication(msalConfig);

const NotAvailable = 'NOT_AVAILABLE'

interface AxiosError extends Error {
    config: AxiosRequestConfig
    code?: string
    response?: AxiosResponse
}

export class ApiError extends Error {
    statusCode: string;
    errorMessage: string;
    url: string;
    methodName: string;
    axiosHttpErr: AxiosError
    constructor(axiosHttpErr: AxiosError, ...params) {
        super(...params)

        this.axiosHttpErr = axiosHttpErr
        console.log("ApiError", this.axiosHttpErr)
        this.url =
            (this.axiosHttpErr.config && this.axiosHttpErr.config.url) || NotAvailable

        this.methodName =
            (this.axiosHttpErr.config &&
                this.axiosHttpErr.config.method &&
                this.axiosHttpErr.config.method.toUpperCase()) ||
            NotAvailable

        this.statusCode = this.axiosHttpErr.response ?
            this.axiosHttpErr.response.status.toString()
            : NotAvailable

        this.errorMessage = `Server request failed.`
        if (axiosHttpErr.response &&
            axiosHttpErr.response.data &&
            axiosHttpErr.response.data.ErrorMessage) {
            this.errorMessage = axiosHttpErr.response.data.ErrorMessage
        }
    }
}

/**
 * Base loader
 */
class Loader {
    /**
     * Axios instance to make HTTP requests. Configured with user JWT
     */
    axiosHelper: AxiosInstance;
    issueCenterHelper: AxiosInstance;

    constructor() {
        this.axiosHelper = axios.create({
            //baseURL: "https://msacctwebapi-young.azurewebsites.net",
            //baseURL: "https://sonic-api-ppe.azurewebsites.net",
            // baseURL: "https://msacct-webapi-dev.azurewebsites.net"
            baseURL: `${process.env.REACT_APP_SONIC_API_URL}`,
            timeout: 80000
        });

        this.issueCenterHelper = axios.create({
            baseURL: `${process.env.REACT_APP_ISSUE_CENTER_API_URL}`,
            timeout: 80000
        })

        function initHelperConfiguration(helper: AxiosInstance) {
            helper.interceptors.request.use(
                async (config) => {
                    const userAccount = msalInstance.getActiveAccount();
                    if (!userAccount) {
                        throw Error('No active account! Verify a user has been signed in.');
                    }
    
                    const accessTokenRequest = {
                        ...loginRequest,
                        account: userAccount
                    };
    
                    const tokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest)
    
                    // console.log("tokenResponse", tokenResponse)
    
                    config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`
                    return config
                }
            )
    
            helper.interceptors.response.use(
                response => {
                    return response
                },
                error => {
                    try {
                        if (error.response) {
    
                            let apiError = new ApiError(error)
                            ctService.initialize()
                            let appInsight = getAppInsights()
    
                            appInsight.trackException({
                                error: new Error("Sonic API_ERR: " + apiError.url + ', error data:' + apiError.errorMessage),
                                severityLevel: SeverityLevel.Error
                            });
    
                            return Promise.reject(apiError)
                        }
                        else {
                            return Promise.reject(error)
                        }
                    }
                    catch (catchError) {
                        console.log("Sonic api error", catchError)
                        return Promise.reject(error)
                    }
                })
        }

        initHelperConfiguration(this.axiosHelper)
        initHelperConfiguration(this.issueCenterHelper)
    }

}

/**
 * Query loader
 */
export class PowerQueryService extends Loader {
    /**
     * Gets the list of indexes that the user has permission to query via global
     * metadata query
     */
    async getQueryDataIndex() {
        return (await this.axiosHelper.get("/api/search/searchableindexes"))
            .data as DataQueryIndex[];
    }

    /**
     * Gets the list of metadata field
     * @param metadataSchemaId
     * @returns
     */
    async getDataFields(metadataSchemaId: string) {
        return (
            await this.axiosHelper.get(`/api/metadata/${metadataSchemaId}/fields`)
        ).data as DataField[];
    }

    /**
     * search content
     * @param searchQuery 
     * @param searchIndex 
     * @param filterStr 
     * @param selectStr 
     * @returns 
     */
    async searchContent(
        searchQuery: string,
        searchIndex: string,
                filterStr: string,
        filterJson: string,
        selectStr: string,
        top?: number,
        skip?: number,
        orderByField?: string
    ): Promise<{
        loading: boolean;
        hasRunQuery: boolean;
        Count: number;
        PageIndex: number;
        Topics: any[];
        columns: string[]
    }> {
        const results = {
            loading: false,
            hasRunQuery: true,
            Count: 0,
            PageIndex: 0,
            Topics: [],
            columns: []
        };

        // console.log("filterStr", filterStr)

        let orderBy = getOderByFields(searchIndex)
        if (orderByField)
        {
            orderBy = `${orderByField} desc`
        }

        const queryParams: MetaDataQueryParams = {
            SearchIndex: searchIndex,
                        OrderBy: orderBy,
            SearchQuery: "*",
            Filter: filterStr || "",
            FilterJson: filterJson,
            Select: selectStr,
            Top: top,
            Skip: skip
        };

        // console.log("searchContent", queryParams)

        const { data } = searchQuery
            ? await this.axiosHelper.post("/api/search/content", queryParams)
            : { data: {} };

        // console.log("searchContent", data)

        if (data) results.Count = data.Count;
        // if (data) results.Topics = data.Topics;
        if (data) results.Topics = data.Contents;
        results.PageIndex = 0;
        results.loading = false;
        results.hasRunQuery = true;
        results.columns = []

        return results;
    }

    /**
     * search content
     * @param searchQuery 
     * @param searchIndex 
     * @param indexPrefix
     * @param filterStr 
     * @param selectStr 
     * @returns 
     */
    async downloadSearchResult(
        searchQuery: string,
        searchIndex: string,
        indexPrefix: string,
        filterStr: string,
        selectStr: string,
    ) {

        let orderBy = getOderByFields(searchIndex)

        const queryParams: MetaDataQueryParams = {
            SearchIndex: searchIndex,
            IndexPrefix: indexPrefix,
            OrderBy: orderBy,
            SearchQuery: "*",
            Filter: filterStr || "",
            Select: selectStr,
        };

        return (await this.axiosHelper.post("/api/search/download", queryParams))
            .data as string;
    }
}

/**
 * Task loader
 */
export class TaskService extends Loader {

    /**
     * Gets all tasks
     * @returns 
     */
    async getAllCurationName() {
        return (await this.axiosHelper.get(`/api/curationconfiguration/allcurationname`))
            .data as string[];
    }

    /**
     * Gets all tasks
     * @returns 
     */
    async getAllTaskList() {
        return (await this.axiosHelper.get(`/api/curationconfiguration/all`))
            .data as ITaskSearch[];
    }

    /**
     * Gets recent tasks
     * @returns 
     */
    async getRecentTask() {
        return (await this.axiosHelper.get(`/api/curationconfiguration/recent`))
            .data as ITaskSearch[];
    }

    /**
     * Gets the curation runs list
     * @returns ICurationRun[]
     */
    async getCurationRuns() {
        return (await this.axiosHelper.get(`/api/pipeline/builds`))
            .data as ICurationRun[];
    }

    /**
  * Gets the curation runs list
  * @returns ICurationRun[]
  */
    async getCurationRunsById(curationId: string) {
        return (await this.axiosHelper.get(`/api/pipeline/curationbuilds?id=${curationId}`))
            .data as ICurationRunWithName;
    }

    /**
 * Gets the curation runs list
 * @returns ICurationRun[]
 */
    async getMyCurationRuns() {
        return (await this.axiosHelper.get(`/api/curationconfiguration/mycurations`))
            .data as ICurationRunWithName[];
    }

    /**
   * Gets the curation runs list
   * @returns ICurationRun[]
   */
    async searchTaskRunsResultById(buildId: string) {
        return (await this.axiosHelper.get(`/api/pipeline/builddetails?buildId=${buildId}`))
            .data as ITaskRunsResult;
    }

    /**
     * 
     * @param taskName 
     * @param SearchQuery 
     * @param SearchIndex 
     * @param Filter 
     * @param SelectString 
     * @param RunType 
     * @param SharedType 
     * @param SharedWith 
     * @param Path 
     * @param IsReport 
     * @param ReportTo 
     * @param Description 
     * @param CurationArea 
     * @param ConditionJsonString 
     * @param triggerSettings 
     */
    async submitTask(
        id: string,
        taskName: string,
        SearchQuery: string,
        SearchIndex: string,
        IndexPrefix: string,
        Filter: string,
        SelectString: string,
        Top: number,
        OrderBy: string,
        AlogType: string,
        RunType: string,
        SharedType: string,
        SharedWith: string,
        Path: string,
        IsReport: boolean,
        ReportTo: string,
        IsTicket: boolean,
        TicketType: string,
        AssignTo: string,
        TicketEvent: ITicketEvent,
        Description: string,
        CurationArea: string,
        ConditionJsonString: string,
        triggerSettings: TriggerSettings,
        remediateItSetting: IRemediateIt,
        textSearchSettings?: ITextSearchSettings
    ) {
        const CurationFilter: CurationFilter = {
            SearchQuery: SearchQuery,
            SearchIndex: SearchIndex,
            IndexPrefix: IndexPrefix,
            Filter: Filter || "",
            ConditionJsonString: ConditionJsonString,
            Select: SelectString,
            Top: Top,
            OrderBy: OrderBy ? (OrderBy + " desc") : "",
            Algorithm: AlogType
        }

        const tasks: Task = {
            id: id,
            CurationConfigurationName: taskName,
            CurationFilter: CurationFilter,
            RunType: RunType,
            SharedType: SharedType,
            SharedWith: SharedWith,
            Path: Path,
            IsReport: IsReport,
            ReportTo: ReportTo,
            IsTicket: IsTicket,
            TicketType: TicketType,
            AssignTo: AssignTo,
            TicketEvent: TicketEvent,
            Description: Description,
            CurationArea: CurationArea,
            TriggerSettings: triggerSettings,
            RemediateIt: remediateItSetting,
            TextSearchSettings: textSearchSettings
        }

        console.log("submitTask", tasks)

        const timezoneOffset = (new Date()).getTimezoneOffset() * -1;


        await this.axiosHelper.post(`/api/curationconfiguration/save?timezoneOffset=${timezoneOffset}`, tasks);
    }

    /**
     * delete task
     * @param taskName 
     */
    async deleteCuration(id: string) {
        await this.axiosHelper.put(`/api/curationconfiguration/delete?id=${id}`);
    }

    /**
     * switch the task
     * @param taskStatus 
     * @param taskName 
     */
    async switchCurationStatus(taskStatus: string, id: string) {
        if (taskStatus === "Enable") {
            await this.axiosHelper.put(`/api/curationconfiguration/enabled?id=${id}`);
        }
        else {
            await this.axiosHelper.put(`/api/curationconfiguration/disabled?id=${id}`);
        }
    }

    /**
     * delete task
     * @param taskName 
     */
    async runCuration(id: string, runType: string, isForcedTicket: boolean) {
        await this.axiosHelper.post(`/api/curationconfiguration/run?id=${id}&runType=${runType}&isForcedTicket=${isForcedTicket}`);
    }

    /**
   * get task by curation name
   * @param taskName 
   */
    async getCurationById(id: string) {
        return (await this.axiosHelper.get(`/api/curationconfiguration/get?id=${id}`))
            .data as Task;
    }

    /**
  * get task by curation name
  * @param taskName 
  */
    async exportTaskRunsResult(csvPath: string) {
        return (await this.axiosHelper.put(`api/pipeline/download2string?blobPath=${csvPath}`))
            .data as string;
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async followUser(id: string) {
        return (await this.axiosHelper.post(`/api/curationconfiguration/follow?id=${id}`))
            .data
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async unFollowUser(id: string) {
        return (await this.axiosHelper.post(`/api/curationconfiguration/unfollow?id=${id}`))
            .data;
    }

    /**
* get task by curation name
* @param taskName 
*/
    async onExportAlgoReport(
        searchQuery: string,
        searchIndex: string,
        filterStr: string,
        selectStr: string
    ) {
        let orderBy = "ArticleArchivingRemainingDays desc, SapStatus desc";
        if (searchIndex.startsWith("gitpub-metadata-schema-others-")) {
            orderBy = "NoOfBrokenLinks desc";
        }
        else if (searchIndex.startsWith("gitpub-metadata-schema-policheck-")) {
            orderBy = "Domain";
        }

        const queryParams: MetaDataQueryParams = {
            SearchIndex: searchIndex,
            OrderBy: orderBy,
            SearchQuery: "*",
            Filter: filterStr || "",
            Select: selectStr
        };

        return (await this.axiosHelper.post("/api/search/download", queryParams))
            .data as string;
    }

    /**
     * 
     * @param buildId 
     * @returns 
     */
    async ticketDownload(buildId: string) {
        return (await this.axiosHelper.get(`api/ticket/download?buildId=${buildId}`))
            .data as string;
    }

    /**
   * 
   * @param CurationId 
   * @returns 
   */
    async createDownloadLogs(
        CurationId: string,
        PageType: string,
        DownloadUrl: string
    ) {

        let objParams = {
            CurationId: CurationId,
            PageType: PageType,
            DownloadUrl: DownloadUrl
        }

        return (await this.axiosHelper.post("/api/download/logs", objParams))
            .data as string;
    }


    /**
     * 
     * @returns 
     */
    async getCurationTags() {
        let curationTags: ITagItem[] = []
        return await this.axiosHelper.get(`api/content/tags`)
            .then((res) => {
                if (res.data) {
                    res.data.map((item) => {
                        curationTags.push({
                            id: item,
                            text: item
                        })
                    })

                    return curationTags;
                }

                return curationTags
            })
    }

    /**
     * 
     * @param buildId 
     * @returns 
     */
    async getTicketDevOpsConfig() {
        return (await this.axiosHelper.get(`api/curationconfiguration/ticketdevopsconfigs`))
            .data as any[];
    }

    async getBrokenLinkScanResult(requestUrl: string, enforce: boolean) {
        let resultData = (await this.axiosHelper.post(`api/brokenlinkchecker/verify?requestUrl=${requestUrl}&enforce=${enforce}`))
            .data as any;

        // console.log("getBrokenLinkScanResult", resultData)
        return resultData
    }

    async getChildLinks(requestUrl: string) {
        let resultData = (await this.axiosHelper.get(`api/brokenlinkchecker/getscanresult?requestUrl=${requestUrl}`))
            .data as any;

        // console.log("getChildLinks", resultData)
        return resultData
    }

    async getScanRequests(requestType: string) {
        let resultData = (await this.axiosHelper.get(`api/brokenlinkchecker/getscanrequests?requestType=${requestType}`))
            .data as any;

        // console.log(requestType)
        // console.log("getScanRequests", resultData)
        return resultData
    }

    async getDataRefreshSchedule(factoryType: string, factoryName: string) {
        let resultData = (await this.axiosHelper.get(`api/brokenlinkchecker/getdatarefreshschedule?factoryType=${factoryType}&factoryName=${factoryName}`))
            .data as any;

        return resultData
    }

    async getContentDataFactory(factoryType: string, factoryName: string) {
        let resultData = (await this.axiosHelper.get(`api/contentdatafactory/getcontentdatabyfactory?factoryType=${factoryType}&factoryName=${factoryName}`))
            .data as any;

        return resultData
    }

    async getPiiFreshData(articleUrl: string) {
        const resultData = (await this.axiosHelper.post(`api/pii/crawler?url=${articleUrl}`)).data as any
        return resultData
    }

    async getPoliCheckScanData(articleUrl: string) {
        const resultData = (await this.axiosHelper.post(`api/policheck/crawler?url=${articleUrl}`)).data as any
        return resultData
    }

    async getIssueList(articleUrl: string, userEmail: string, sourceData: IScanIssueData[], riskArea: string) {
        const getParam = {
            articleUrl,
            userEmail,
            sourceData,
            riskArea
        }
        const resultData = (await this.issueCenterHelper.post('v1/issue/querybyurl', getParam)).data as any
        return resultData
    }

    async createIssueReport(reportParam: IReportIssueParam) {
        const resultData = (await this.issueCenterHelper.post('v1/issue/create', reportParam)).data as any
        return resultData
    }

    async renewIssueReport(issueId: string, reporter: string, expireTime: string) {
        const renewParam = {
            issueId,
            reporter,
            expireTime
        }
        const resultData = (await this.issueCenterHelper.post('v1/issue/renew', renewParam)).data as any
        return resultData
    }

    async cancelIssueReport(issueId: string, reporter: string) {
        const cancelParam = {
            issueId,
            reporter
        }
        const resultData = (await this.issueCenterHelper.post('v1/issue/cancel', cancelParam)).data as any
        return resultData
    }

}

/**
 * Welcome loader
 */
export class WelcomeService extends Loader {

    /**
     * get runs count
     * @returns 
     */
    async getStats() {
        return (await this.axiosHelper.get(`/api/welcome/stats`))
            .data as IWelcomeStats;
    }

    /**
     * check site inprocess
     * @returns 
     */
    async getSiteStatus() {
        return (await this.axiosHelper.get(`/api/welcome/searchindexchecker`))
            .data as string;
    }
}

export class DataSourceService extends Loader {
    /**
     * upload data source file
     * @returns 
     */
    async uploadDataSourceFile(formData: any) {
        return await this.axiosHelper.post(`/api/datasource/upload2blob`, formData)
    }

    /**
     * get data source list
     * @returns 
     */
    async getDataSourceList(type?: string) {
        let result = (await this.axiosHelper.get(`/api/datasource/fetchdatasource?type=${type}`))
            .data as IDataSource[];

        return result
    }

    /**
     * delete a data source
     * @param taskName 
     */
    async deleteDataSource(name: string) {
        return await this.axiosHelper.post(`/api/datasource/deletefromblob?dataSourceName=${name}`);
    }

    async getFindTextsConfig(dataSourceName: string) {
        return await this.axiosHelper.get(`api/datasource/getfindtexts?datasourceName=${dataSourceName}`);
    }
}

export class UserService extends Loader {

    /**
     * get repo count
     * @returns 
     */
    async getUserInfo2() {
        // const userAccount = msalAuth.getAccount();
        // console.log("getUserInfo", userAccount)
        // return userAccount

        const userAccount = msalInstance.getActiveAccount();

        return null
    }
}

export default class MsacctAPI {
    constructor() { }

    powerQueryService = new PowerQueryService();
    taskService = new TaskService();
    welcomeService = new WelcomeService()
    userService = new UserService()
    dataSourceService = new DataSourceService()
    axiosHelper = new Loader().axiosHelper;
}
