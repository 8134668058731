import { IComboBoxOption } from "@fluentui/react";
import moment from "moment";
import * as _ from 'lodash'
import { saveAs } from 'file-saver'
// import { row as CSVRow } from 'csv-string'
// import { row as Row } from 'csv-string'
// import json2csv from "json2csv";
import { Parser as CSVParser } from "json2csv";
import { formatDateTime } from '../Utilities/CommonFunction'

import { DataField } from '../Interface/IPowerQuery'
import { DataListItem, DataListItemDataSourceBrokenLink, DataListItemOthers, DataListItemPoliCheck } from '../Interface/IDataResult'

/**
 * export csv file from Curation Filter Editor
 * @param dataContent 
 * @param fileName 
 */
export const exportCurationFilterData = (dataContent: string, fileName: string) => {
    const test = new Blob([dataContent], {
        type: 'text/csv;charset=utf-8'
    })

    saveAs(test, fileName)
}

/**
 * export run result data
 * @param taskRunsResultContent 
 * @param fileName 
 */
export const exportTaskRunsResults = (taskRunsResultContent: string, fileName: string) => {
    const test = new Blob([taskRunsResultContent], {
      type: 'text/csv;charset=utf-8'
    })
  
    saveAs(test, fileName)
  }