import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { ITaskDataListResult } from '../../Interface/ITaskSearch'
import {
  loadAllTaskList,
  onCurationDelete,
  clearAllTaskList,
  switchCurationStatus,
  runCuration
} from "../../../redux/Actions/PowerDataQuery";
import {
  Spinner
} from "@fluentui/react";
import { Surface, SurfaceBackground } from "azure-devops-ui/Surface";
import { Page } from "azure-devops-ui/Page";
import {
  Table,
  SimpleTableCell,
  renderSimpleCell,
  ITableColumn,
  TwoLineTableCell,
  ColumnMore
} from "azure-devops-ui/Table";
import { Tooltip } from "azure-devops-ui/TooltipEx";
import { Link } from "azure-devops-ui/Link";
import { css } from "azure-devops-ui/Util";
import { Ago } from "azure-devops-ui/Ago";
import { Icon } from "azure-devops-ui/Icon";
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Duration } from "azure-devops-ui/Duration";

import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Card } from "azure-devops-ui/Card";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { Status, StatusSize } from "azure-devops-ui/Status";
import { Dialog } from "azure-devops-ui/Dialog";
import { Observer } from "azure-devops-ui/Observer";
import { formatDateTime } from '../../Utilities/CommonFunction'

import {
  getStatusIndicatorData
} from '../../Utilities/DataListExtension'

import RunTypeDialog from '../../Modules/RunTypeDialog/RunTypeDialog';

const actionCreators = {
  loadAllTaskList,
  onCurationDelete,
  clearAllTaskList,
  switchCurationStatus,
  runCuration
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
  allTaskList: state.allTaskList
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

/**
 * interface for all task search 
 */
interface IAllTaskSearchResultState {
  items: ITaskDataListResult[]
}

interface IAllDialogState {
  showDialog: boolean,
  dialogType: "delete" | "enable" | "disable" | "other",
  actionCurationId: string,
  actionCurationName: string
}

/**
 * interface for run type
 */
interface IRunTypeDialogState extends IAllDialogState {
  selectedRunType: string,
  runTypeSubmitState: string,
}

interface ITaskFiterProps {
  FilterKey?: string
}

const dialogObservable = new ObservableValue<boolean>(false);
const runDialogObservable = new ObservableValue<boolean>(false);
const runTypeDialogObservable = new ObservableValue<boolean>(false);
function All(props: StoreProps & DispatchProps & ITaskFiterProps) {
  const history = useNavigate();

  /**
   * navigate to edit form
   * @param taskName 
   */
  function navigateToEditForm(curationId: string, curationName: string) {
    history(`/curations/edit?curationId=${curationId}&curationName=${curationName}`)
  }

  /**
   * navigate to detail form
   * @param event 
   * @param buildId 
   */
  function navigateToDetailForm(event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>, buildId: string) {
    history(`/runs/results?buildId=${buildId}`)
    event.preventDefault()
  }

  /**
 * navigate to curation jobs form
 * @param event 
 * @param CurationConfigurationName 
 */
  function navigateToCurationJobs(event: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>, curationId: string, curationName: string) {
    history(`/curations/runs?curationId=${curationId}&curationName=${curationName}`)
    event.preventDefault()
  }

  /**
   * initialize state
   */
  const [currentState, setCurrentState] = useState<IAllTaskSearchResultState>({
    items: []
  })

  const [allDialogState, setAllDialogState] = useState<IAllDialogState>({
    showDialog: false,
    dialogType: "delete",
    actionCurationId: '',
    actionCurationName: ''
  })

  /**
  * run type state
  */
  const [runTypeDialogState, setRunTypeDialogState] = useState<IRunTypeDialogState>({
    showDialog: false,
    dialogType: "other",
    actionCurationId: "",
    actionCurationName: "",
    runTypeSubmitState: "",
    selectedRunType: "Incremental"
  })

  /**
   * loading state
   */
  const [isLoading, setIsLoading] = useState<boolean>(false)

  /**
  * like componentDidMount
  */
  useEffect(() => {

    setRunTypeDialogState({
      showDialog: false,
      dialogType: "other",
      actionCurationId: '',
      actionCurationName: '',
      runTypeSubmitState: "",
      selectedRunType: "Incremental"
    })

    runDialogObservable.value = false

    const loadData = async () => {

      setIsLoading(true)

      // call the api to get the list
      await props.loadAllTaskList();

      // set isLoading = false
      setCurrentState({
        ...currentState,
        items: buildItems()
      })

      setIsLoading(false)
    }

    loadData();
  }, [])

  /**
   * like componentDidUpdate
   */
  useEffect(() => {
    setCurrentState({
      items: buildItems()
    })

  }, [props.allTaskList, props.FilterKey])

  /**
   * build last run column
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
  function renderLastRunColumn(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<ITaskDataListResult>,
    tableItem: ITaskDataListResult
  ) {
    const {
      RunId,
      RunUrl,
      RunResult,
      RunStatus,
      RunStartTime,
      RunFinishTime
    } = tableItem;
    const text = "Job ID: #" + RunId;

    return (
      <TwoLineTableCell
        className="bolt-table-cell-content-with-inline-link no-v-padding"
        key={"col-" + columnIndex}
        columnIndex={columnIndex}
        tableColumn={tableColumn}
        line1={
          <span className="flex-row scroll-hidden">
            {
              RunId && RunId.toString() !== '' ?
                (
                  <Tooltip text={text} overflowOnly>
                    <Link
                      className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link "
                      excludeTabStop
                      href={RunUrl}
                      onClick={(event) => navigateToDetailForm(event, RunId.toString())}
                    >
                      <Status
                        {...getStatusIndicatorData(RunResult).statusProps}
                        className="icon-large-margin task-datalist-icon"
                        size={StatusSize.s}
                      />
                      {text}
                    </Link>
                  </Tooltip>

                )
                :
                (
                  <>

                    <span>
                      <Status
                        {...getStatusIndicatorData("Queued").statusProps}
                        className="icon-large-margin task-datalist-icon"
                        size={StatusSize.s}
                      />
                      No runs yet
                    </span>
                  </>

                )
            }
          </span>
        }
        line2={
          <span className="flex-row scroll-hidden dataFormatter">
            {
              RunId && RunId.toString() !== '' ?
                (
                  <>
                    {
                      WithIcon({
                        className: "fontSize font-size",
                        iconProps: { iconName: "Calendar" },
                        children: (
                          <Ago date={new Date(RunFinishTime)!} /*format={AgoFormat.Extended}*/ />
                        )
                      })
                    }
                    {
                      WithIcon({
                        className: "fontSize font-size bolt-table-two-line-cell-item msacct-curationall-clock",
                        iconProps: { iconName: "Clock" },
                        children: (
                          <Duration
                            startDate={new Date(RunStartTime)!}
                            endDate={new Date(RunFinishTime)}
                          />
                        )
                      })
                    }
                  </>
                )
                : null
            }
          </span>
        }
      />
    )
  }

  function WithIcon(props: {
    className?: string;
    iconProps: IIconProps;
    children?: React.ReactNode;
  }) {
    return (
      <div className={css(props.className, "flex-row flex-center")}>
        {Icon({ ...props.iconProps, className: "icon-margin" })}
        {props.children}
      </div>
    );
  }

  /**
   * render curation configuration name
   * @param rowIndex 
   * @param columnIndex 
   * @param tableColumn 
   * @param tableItem 
   * @returns 
   */
  function renderCurationConfigurationName(
    rowIndex: number,
    columnIndex: number,
    tableColumn: ITableColumn<ITaskDataListResult>,
    tableItem: ITaskDataListResult) {
    const {
      CurationConfigurationNameDisplayName,
      CurationConfigurationName,
      RunId,
      CurationId
    } = tableItem;

    return (
      <SimpleTableCell
        columnIndex={columnIndex}
        tableColumn={tableColumn}
        key={"col-" + columnIndex}
        contentClassName="bolt-table-cell-content-with-inline-link no-v-padding"
      >
        {
          RunId && RunId.toString() !== '' ?
            (
              <Link
                className="fontSizeM font-size-m text-ellipsis bolt-table-link bolt-table-inline-link"
                excludeTabStop
                href={"#"}
                onClick={(event) => navigateToCurationJobs(event, CurationId, CurationConfigurationNameDisplayName)}
              >
                {CurationConfigurationNameDisplayName}
              </Link>
            )
            :
            (
              <span>
                {CurationConfigurationNameDisplayName}
              </span>
            )
        }

      </SimpleTableCell>
    );
  }

  /**
   * build data list columns
   * @returns 
   */
  function buildColumns(): any {
    const columns = [
      {
        id: "CurationConfigurationNameDisplayName",
        name: "Curation configuration name",
        readonly: true,
        renderCell: renderCurationConfigurationName,
        sortProps: {
          ariaLabelAscending: "Sorted A to Z",
          ariaLabelDescending: "Sorted Z to A",
        },
        width: new ObservableValue(-30),
      },
      {
        id: "LastRun",
        maxWidth: 300,
        name: "Last run",
        readonly: true,
        renderCell: renderLastRunColumn,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-30),
      },
      {
        id: "NextRun",
        name: "Next run date",
        maxWidth: 300,
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-30),
      },
      {
        id: "UpdatedDate",
        name: "Modified date",
        maxWidth: 300,
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-30),
      },
      {
        id: "UpdatedBy",
        name: "Last Modified By",
        maxWidth: 300,
        readonly: true,
        renderCell: renderSimpleCell,
        sortProps: {
          ariaLabelAscending: "Sorted low to high",
          ariaLabelDescending: "Sorted high to low",
        },
        width: new ObservableValue(-30),
      },
      new ColumnMore((currentItem: ITaskDataListResult) => {
        const statusText = currentItem.Status.toString() === "Enabled" ? "Disable" : "Enable";
        let editDisable = false
        if (currentItem.Status.toString() !== "Enabled") {
          editDisable = true
        }

        let runDisable = true;
        if (!editDisable && currentItem.PipelineId && currentItem.PipelineId !== -1) {
          runDisable = false
        }

        let actionDisable =  true
        if (currentItem.PipelineId && currentItem.PipelineId !== -1) {
          actionDisable = false
        }

        return {
          id: "sub-menu",
          items: [
            { id: "edit", disabled: editDisable, text: "Edit", onActivate: () => { navigateToEditForm(currentItem.CurationId, currentItem.CurationConfigurationName) } },
            { id: "run", disabled: runDisable, text: "Run Curation", onActivate: () => { handleRunTypeDialogShow(currentItem.CurationId, currentItem.CurationConfigurationName, '') } },
            {
              id: "delete", disabled: actionDisable,  text: "Delete", onActivate: () => {
                setAllDialogState({
                  ...allDialogState,
                  showDialog: true,
                  dialogType: "delete",
                  actionCurationId: currentItem.CurationId,
                  actionCurationName: currentItem.CurationConfigurationName
                })
              }
            },
            {
              id: "disable", disabled: actionDisable,  text: statusText, onActivate: (disStatus) => {
                if (disStatus.text === "Disable") {
                  setAllDialogState({
                    ...allDialogState,
                    showDialog: true,
                    dialogType: "disable",
                    actionCurationId: currentItem.CurationId,
                    actionCurationName: currentItem.CurationConfigurationName
                  })
                }
                else {
                  setAllDialogState({
                    ...allDialogState,
                    showDialog: true,
                    dialogType: "enable",
                    actionCurationId: currentItem.CurationId,
                    actionCurationName: currentItem.CurationConfigurationName
                  })
                }

              }
            },
          ],
        };
      }),
    ];

    return columns;
  }

  /**
   * build data list items
   * @returns 
   */
  function buildItems() {
    let itmes: ITaskDataListResult[] = []
    if (!props.allTaskList || !props.allTaskList.length) {
      return itmes;
    }

    props.allTaskList.filter(t => t.SharedType !== "System").map((item) => {
      const taskName = item.Status.toString() === "Enabled" ?
        item.CurationConfigurationName
        : item.CurationConfigurationName + " (Disabled)"

      if (props.FilterKey && props.FilterKey.trim() !== '') {
        if (item.CurationConfigurationName.indexOf(props.FilterKey?.toString()) !== -1) {
          itmes.push({
            CurationId: item.id,
            CurationConfigurationName: item.CurationConfigurationName,
            CurationConfigurationNameDisplayName: taskName,
            CreatedDate: item.CreatedDate,
            UpdatedDate: formatDateTime(item.UpdatedDate ? item.UpdatedDate : item.CreatedDate),
            UpdatedBy: item.UpdatedBy ? item.UpdatedBy : item.CreatedBy,
            CreatedBy: item.CreatedBy,
            IsOwner: item.IsOwner,
            SharedType: item.SharedType,
            Status: item.Status,
            RunId: item.LastRun?.Id,
            RunBuildNumber: item.LastRun?.BuildNumber,
            RunStatus: item.LastRun?.Status,
            RunResult: item.LastRun?.Result,
            RunUrl: item.LastRun?.Url,
            RunDescription: item.LastRun?.Description,
            RunStartTime: item.LastRun?.StartTime,
            RunFinishTime: item.LastRun?.FinishTime,
            RunSourceBranch: item.LastRun?.SourceBranch,
            NextRun: formatDateTime(item.NextRun),
            PipelineId: item.PipelineId
          })
        }
      }
      else {
        itmes.push({
          CurationId: item.id,
          CurationConfigurationName: item.CurationConfigurationName,
          CurationConfigurationNameDisplayName: taskName,
          CreatedDate: item.CreatedDate,
          UpdatedDate: formatDateTime(item.UpdatedDate ? item.UpdatedDate : item.CreatedDate),
          UpdatedBy: item.UpdatedBy ? item.UpdatedBy : item.CreatedBy,
          CreatedBy: item.CreatedBy,
          IsOwner: item.IsOwner,
          SharedType: item.SharedType,
          Status: item.Status,
          RunId: item.LastRun?.Id,
          RunBuildNumber: item.LastRun?.BuildNumber,
          RunStatus: item.LastRun?.Status,
          RunResult: item.LastRun?.Result,
          RunUrl: item.LastRun?.Url,
          RunDescription: item.LastRun?.Description,
          RunStartTime: item.LastRun?.StartTime,
          RunFinishTime: item.LastRun?.FinishTime,
          RunSourceBranch: item.LastRun?.SourceBranch,
          NextRun: formatDateTime(item.NextRun),
          PipelineId: item.PipelineId
        })
      }
    })

    return itmes.filter(item => item.IsOwner === true);
  }

  /**
    * build the conform dialog text
    * @param taskName 
    * @returns dialog text
    */
  function buildDialogText(): JSX.Element {
    const { dialogType, actionCurationName } = allDialogState

    switch (dialogType) {
      case "delete":
        return (
          <div>
            The curation <b>{actionCurationName}</b> will be deleted permanently, click confirm to proceed.
          </div>
        )
      case "disable":
        return (
          <div>
            The curation <b>{actionCurationName}</b> will be disabled, click confirm to proceed.
          </div>
        )
      case "enable":
        return (
          <div>
            The curation <b>{actionCurationName}</b> will be enabled, click confirm to proceed.
          </div>
        )
      default:
        return (
          <div>
            The curation <b>{actionCurationName}</b> will be deleted permanently, click confirm to proceed.
          </div>
        )
    }
  }

  /**
   * build run type complete message
   * @returns 
   */
  function buildRunDialogText(): JSX.Element {
    const { actionCurationName } = runTypeDialogState

    return (
      <div>
        Curation <b>{actionCurationName}</b> has been running, it may take a few minutes, you can refresh the page later to check the running status.
      </div>
    )
  }

  /**
   * build the dialog title
   * @returns 
   */
  function buildDialogTitle() {
    const { dialogType } = allDialogState

    switch (dialogType) {
      case "delete":
        return "Delete curation";
      case "disable":
        return "Disable curation";
      case "enable":
        return "Enable curation";
      default:
        return "Delete curation";
    }
  }

  /**
   * click confirm of dialog form
   */
  async function handleDilagConfirm() {
    const { dialogType, actionCurationId, actionCurationName } = allDialogState

    setAllDialogState({
      ...allDialogState,
      showDialog: false
    })

    setIsLoading(true)

    switch (dialogType) {
      case "delete":
        await props.onCurationDelete(actionCurationId)
        break;
      case "disable":
        await props.switchCurationStatus("Disable", actionCurationId)
        break;
      case "enable":
        await props.switchCurationStatus("Enable", actionCurationId)
        break;
      default:
        break
    }

    await props.loadAllTaskList()

    setIsLoading(false)
  }

  /**
   * click cancel of dialog form
   */
  function handleDialogeDismiss() {
    dialogObservable.value = false;

    setAllDialogState({
      ...allDialogState,
      showDialog: false
    })
  };

  /**
  * click cancel of dialog form
  */
  function handleRunDialogeDismiss() {
    runDialogObservable.value = false;

    setRunTypeDialogState({
      ...runTypeDialogState,
      showDialog: false
    })
  };

  /**
   * 
   * @param curationId 
   * @param curationName 
   */
  function handleRunTypeDialogShow(curationId: string, curationName: string, selectedRunType: string) {

    setRunTypeDialogState({
      ...runTypeDialogState,
      showDialog: false,
      actionCurationId: curationId,
      actionCurationName: curationName,
      selectedRunType: selectedRunType
    })

    runTypeDialogObservable.value = true
    runDialogObservable.value = false
  }


  /**
   * handle run type dialog dismiss
   */
  function handleRunTypeDialogDismiss() {
    runTypeDialogObservable.value = false
  }

  function onRunTypeSubmit(data: any) {

    if (data !== null && data !== undefined && data.submitState !== '') {
      setRunTypeDialogState({
        ...runTypeDialogState,
        showDialog: true,
        actionCurationId: data.actionCurationId,
        actionCurationName: data.actionCurationName,
      })
    }
  }


  return (
    <div>
      <Surface background={SurfaceBackground.neutral}>
        <Page className="pipelines-page flex-grow">
          <div className="page-content page-content-top">
            <Card
              className="flex-grow bolt-card-no-vertical-padding"
              contentProps={{ contentPadding: true }}
            >
              {
                isLoading ?
                  (
                    <Spinner className='msccat-page-loading' label="Loading" />
                  )
                  :
                  (
                    <Table<Partial<ITaskDataListResult>>
                      columns={buildColumns()}
                      itemProvider={new ArrayItemProvider<ITaskDataListResult>(currentState.items)}
                      showLines={true}
                      onSelect={(event, data) => console.log("Selected Row - " + data.index)}
                      onActivate={(event, row) => console.log("Activated Row - " + row.index)}
                      containerClassName="msacct-devops-table"
                    />
                  )
              }

              {/* delete/enable/disable task */}
              <Observer isDialogOpen={dialogObservable}>
                {
                  () => {
                    return allDialogState.showDialog ?
                      (
                        <Dialog
                          titleProps={{ text: buildDialogTitle() }}
                          footerButtonProps={[
                            {
                              text: "Cancel",
                              onClick: handleDialogeDismiss
                            },
                            {
                              text: "Confirm",
                              onClick: handleDilagConfirm,
                              primary: true
                            }
                          ]}
                          onDismiss={handleDialogeDismiss}
                        >
                          {buildDialogText()}
                        </Dialog>
                      )
                      : null
                  }
                }
              </Observer>

              {/* run curation */}
              <Observer isDialogOpen={runDialogObservable}>
                {
                  () => {
                    return runTypeDialogState.showDialog ?
                      (
                        <Dialog
                          titleProps={{ text: "" }}
                          footerButtonProps={[
                            {
                              text: "OK",
                              primary: true,
                              onClick: handleRunDialogeDismiss
                            },
                          ]}
                          onDismiss={handleRunDialogeDismiss}
                        >
                          {buildRunDialogText()}
                        </Dialog>
                      )
                      : null
                  }
                }
              </Observer>

              {/* run type form */}
              <Observer isDialogOpen={runTypeDialogObservable} >
                {(runTypeProps: { isDialogOpen: boolean }) => {
                  return runTypeProps.isDialogOpen ? (
                    <RunTypeDialog
                      actionCurationId={runTypeDialogState.actionCurationId}
                      actionCurationName={runTypeDialogState.actionCurationName}
                      curationRunType={runTypeDialogState.selectedRunType}
                      onRunTypeSubmit={onRunTypeSubmit}
                      onRunTypeDialogDimiss={handleRunTypeDialogDismiss} />

                  ) : null;
                }}
              </Observer>

            </Card>
          </div>
        </Page>
      </Surface>
    </div>
  )
}

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  bindActionCreators.bind({}, actionCreators)
)(All);
