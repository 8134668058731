import React, { useState, useEffect } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { returnType } from "../../Utilities/ReturnType";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IColor } from "azure-devops-ui/Utilities/Color";
import { Icon } from '@fluentui/react/lib/Icon';
import { Tab, TabBar, TabSize } from "azure-devops-ui/Tabs";
import { Toggle } from "azure-devops-ui/Toggle";
import { ButtonGroup } from "azure-devops-ui/ButtonGroup";
import { Card } from "azure-devops-ui/Card";
import { PillGroup, PillGroupOverflow } from "azure-devops-ui/PillGroup";
import {
    CustomHeader,
    TitleSize,
    HeaderDescription,
    HeaderIcon,
    HeaderTitle,
    HeaderTitleArea,
    HeaderTitleRow,
} from "azure-devops-ui/Header";

import { Pill, PillSize, PillVariant } from "azure-devops-ui/Pill";
import { FilterBar } from "azure-devops-ui/FilterBar";
import { KeywordFilterBarItem } from "azure-devops-ui/TextFilterBarItem";
import {  Filter } from "azure-devops-ui/Utilities/Filter";
import { DropdownSelection } from "azure-devops-ui/Utilities/DropdownSelection";
import { DropdownFilterBarItem } from "azure-devops-ui/Dropdown";
import { ObservableValue } from "azure-devops-ui/Core/Observable";
import { Observer } from "azure-devops-ui/Observer";
import { Image } from '@fluentui/react/lib/Image';
import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { onExportAlgoReport, getCurationById, clearSearchResults, followUser, unFollowUser } from '../../../redux/Actions/PowerDataQuery'
import { formatDateTime, checkUserExsit,getUserCount } from '../../Utilities/CommonFunction'
import { useMsal, useAccount } from "@azure/msal-react";

import ReportView from './AlgorithmViews/ReportView'
import RunHistoryView from './AlgorithmViews/RunHistoryView'

import "./Algorithm.css";

const actionCreators = {
    clearSearchResults,
    onExportAlgoReport,
    getCurationById,
    followUser,
    unFollowUser
};
type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    searchResult: state.searchResult,
    curationRunsWithName: state.curationRunsWithName,
    curation: state.taskEditItem,
});
const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

let selectedTabId: ObservableValue<string> = new ObservableValue("tab1");
let isViewHistoryDialogOpen = new ObservableValue<boolean>(false);
let isViewReportDialogOpen = new ObservableValue<boolean>(false);
const filter = new Filter();

function ArticlesWithEmptySAPValues(props: StoreProps & DispatchProps) {
    /** Get template params from Welcome page */
    const [search, setSearch] = useSearchParams();
    const curationId = search.get('curationId')

    const lightColor: IColor = {
        red: 255,
        green: 206,
        blue: 80,
    };

    const whiteColor: IColor = {
        red: 255,
        green: 255,
        blue: 255,
    };

    /** Import msal-react for getting login user information */
    const { instance, accounts } = useMsal();
    const userAccount = useAccount(accounts[0]);

    const [tabIndex, setTabIndex] = useState("tab1")
    const [isUserExist, setIsUserExist] = useState(false)
    const [userCount, setUserCount] = useState(0)
    const [curationInfo, setCurationInfo] = useState({
        isReportButtonDisabled: true,
        curationName: '',
        Filter: '',
        SearchIndex: ''
    })

    /**
   * like componentDidMount
   */
    useEffect(() => {
        const loadData = async () => {
            await props.clearSearchResults()
            await props.getCurationById(curationId)        
        }

        loadData();
    }, [curationId])

    /**
     * like componentUpdateMount
     */
    useEffect(() => {
        if (props.curation &&
            props.curation.ReportTo) {
            setIsUserExist(checkUserExsit(userAccount.username, props.curation.ReportTo))
            setUserCount(getUserCount(props.curation.ReportTo))
        }

        if (props.curation?.CurationConfigurationName) {
            setCurationInfo({
                isReportButtonDisabled: false,
                curationName: props.curation?.CurationConfigurationName,
                Filter: props.curation?.CurationFilter.Filter,
                SearchIndex: props.curation?.CurationFilter.SearchIndex
            })
        }
    }, [props.curation.ReportTo])

    /**
         * Render header icon
         * @param className 
         * @returns 
         */
    function renderIcon(className?: string) {
        return (
            <Icon ariaLabel="Product Lifecycle Analyzer icon" iconName="Lifesaver" />
        );
    }

    /**
     * Tab change
     * @param newTabId 
     */
    function onSelectedTabChanged(newTabId: string) {
        selectedTabId.value = newTabId;
        setTabIndex(newTabId)
    }

    /**
     * Render filter bar
     * @returns 
     */
    function renderFilterBarInContent() {
        if (selectedTabId.value === "tab3") {
            return (
                <FilterBar filter={filter}>
                    <KeywordFilterBarItem filterItemKey="keyword" />
                    <DropdownFilterBarItem
                        filterItemKey="picklist"
                        filter={filter}
                        items={[
                            { id: "Item 1", text: "Item 1" },
                            { id: "Item 2", text: "Item 2" },
                        ]}
                        selection={new DropdownSelection()}
                        placeholder="Dropdown"
                    />
                </FilterBar>
            );
        }
        return null;
    }

    /**
     * Show run history dialog
     */
    function onShowRunHistoryView() {
        isViewHistoryDialogOpen.value = true
    }

    /**
     * Show report dialog
     */
    function onShowReportView() {
        isViewReportDialogOpen.value = true
    }

    /**
     * Close run history dialog
     */
    function onRunHistoryViewDimiss() {
        isViewHistoryDialogOpen.value = false
    }

    /**
     * Close report dialog
     */
    function onReportViewDimiss() {
        isViewReportDialogOpen.value = false
    }

    /**
     * Follow switch
     * @param switchType 
     */
    async function onFollowSwitch(switchType: string) {
        if (switchType === "Follow") {
            await props.unFollowUser(curationId)
        }
        else {
            await props.followUser(curationId)
        }

        await props.getCurationById(curationId)
    }

    /**
     * Render run history dialog
     * @returns 
     */
    function renderRunHistoryView() {
        return (
            <div>
                <Observer isDialogOpen={isViewHistoryDialogOpen}  >
                    {(historyProps: { isDialogOpen: boolean }) => {
                        return historyProps.isDialogOpen ? (
                            <RunHistoryView
                                CurationId={curationId}
                                CurationName={curationInfo.curationName}
                                onRunHistoryDialogDimiss={onRunHistoryViewDimiss} />

                        ) : null;
                    }}
                </Observer>
            </div>
        )
    }

    /**
     * Render report dialog
     * @returns 
     */
    function renderReportView() {
        return (
            <div>
                <Observer isDialogOpen={isViewReportDialogOpen}  >
                    {(reportProps: { isDialogOpen: boolean }) => {
                        return reportProps.isDialogOpen ? (
                            <ReportView
                                CurationId={curationId}
                                CurationName={curationInfo.curationName}
                                Filter={curationInfo.Filter}
                                SearchIndex={curationInfo.SearchIndex}
                                onReportDialogDimiss={onReportViewDimiss} />

                        ) : null;
                    }}
                </Observer>
            </div>
        )
    }

    function getPageContent() {
        if (tabIndex === "tab1") {
            return (
                <div className="page-content-top">

                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
                                <Card
                                    className="bolt-card-content"
                                    contentProps={{ contentPadding: true }}
                                >
                                    {renderRunHistoryView()}
                                    {renderReportView()}
                                    <div className="ms-Grid">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div>
                                                    <h2>Product Lifecycle Analyzer - Articles with Empty SAP Values</h2>


                                                    <div className="ms-Grid">
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <p>
                                                                    <Image
                                                                        width={"100%"}
                                                                        src={require("./AlgoAD001.png")}
                                                                        alt='Content does not have product information.'
                                                                    />
                                                                </p>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <div className='AlgoDesSection'>
                                                            <h3>Algorithm description</h3>
                                                                <div className="algoDescribe">
                                                                    This algorithm monitors <a href="https://msaas.support.microsoft.com/sap/browse">Support Area Path (SAP) state</a> to check whether there is assigned products. The algorithm monitors articles without products.
                                                                </div>
                                                                <div>
                                                                    <ButtonGroup className='flex-row flex-center flex-noshrink'>
                                                                        <PrimaryButton
                                                                            text="View report"
                                                                            iconProps={{ iconName: 'ReportDocument' }}
                                                                            primary={true}
                                                                            disabled={curationInfo.isReportButtonDisabled}
                                                                            onClick={() => onShowReportView()}
                                                                        />

                                                                        <DefaultButton
                                                                            iconProps={{ iconName: 'FullHistory' }}
                                                                            text="View history"
                                                                            disabled={curationInfo.isReportButtonDisabled}
                                                                            onClick={() => onShowRunHistoryView()}
                                                                        />
                                                                        {
                                                                            isUserExist ?
                                                                                <DefaultButton
                                                                                    iconProps={{ iconName: 'UserFollowed' }}
                                                                                    text="Unfollow"
                                                                                    onClick={() => onFollowSwitch("Follow")}
                                                                                    className={"msacct-follow-focus"}
                                                                                />
                                                                                :
                                                                                <DefaultButton
                                                                                    iconProps={{ iconName: 'FollowUser' }}
                                                                                    text="Follow"
                                                                                    onClick={() => onFollowSwitch("Unfollow")}
                                                                                    className={"msacct-follow-focus"}
                                                                                />
                                                                        }
                                                                        <div style={{ marginLeft: "0px" }} className="page-follower-count bolt-pill flex-row flex-center standard regular">
                                                                            <div aria-label="" aria-hidden="true" className="bolt-pill-content text-ellipsis" role="presentation">{userCount}
                                                                            </div>
                                                                        </div>

                                                                    </ButtonGroup>
                                                                </div>

                                                                </div>
                                                                <Card
                                        className="bolt-card-content"
                                        contentProps={{ contentPadding: true }}
                                    >
                                        <div>
                                        <h3>Internal support article governance best practices</h3>
                                                    <ul>
                                                        <li>Use correct and non-Retired <a href='https://msaas.support.microsoft.com/sap/browse'>Support Area Path</a> (SAP)</li>
                                                        <li>Archive or unindex the article if the applied products that are <a href='https://docs.microsoft.com/en-us/lifecycle'>no longer supported under standard or extended support</a>.</li>
                                                    </ul>

                <p>
                                                                <h3>Remediation guidance</h3>
                                                    <div>
                                                        Assign each of the articles at least one valid and non-Retired state SAP value, unindex or archive the articles.
                                                    </div>
                                                                </p>
                                                                </div>
          </Card>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h3>Scheduler</h3>
                                                    <ul>
                                                        <li>Recurrence: Recurring</li>
                                                        <li>Recur every: 1 Week</li>
                                                        <li>Start: July 1st, 2022</li>
                                                        <li>End: Never</li>
                                                    </ul>
                                                    <h3>Filters</h3>
                                                    <ul>
                                                        <li>Locale = <b>en-us</b></li>
                                                        <li>And (SAP Guid <b>Is not set</b>)</li>
                                                    </ul>
                                                    <h3>Report columns</h3>
                                                    <ul>
                                                        <li>Content ID</li>
                                                        <li>Locale</li>
                                                        <li>Title</li>
                                                        <li># of Referrers</li>
                                                        <li># of Page Views (1 Month/Total)</li>
                                                        <li>Article Ages (Last Published/Created)</li>
                                                        <li>SAP Name</li>
                                                        <li>SAP State</li>
                                                        <li>Article Archiving Remaining Days</li>
                                                        <li>Mainstream Remaining Days</li>
                                                        <li>Extended End Remaining Days</li>
                                                        <li>Retirement Remaining Days</li>
                                                        <li>Release End Remaining Days</li>
                                                        <li>Mainstream Date</li>
                                                        <li>Extended End Date</li>
                                                        <li>Retirement Date</li>
                                                        <li>Release End Date</li>
                                                        <li>Repos Name</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                                <div>
                                    <Card
                                        className="bolt-card-content"
                                        contentProps={{ contentPadding: true }}
                                    >
                                        <div>
                                            <h3 className="columnTitle">Data sources</h3>
                                            <div className="columnCells">
                                                <div className="cell">
                                                    <header aria-label="App_Version">
                                                        Support Area Path (SAP)
                                                    </header>
                                                    <ul>
                                                        <li><div className="cellLabel">URL: <a href="https://msaas.support.microsoft.com/sap/browse">https://msaas.support.microsoft.com/sap/browse</a></div></li>
                                                        <li><div className="cellLabel">Data latency: 72 Hours</div></li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <h3 className="columnTitle">Works with</h3>
                                            <div className="columnCells">
                                                <div className="cell">
                                                    <header aria-label="App_Publisher">
                                                        Internal content
                                                    </header>
                                                    <ul>
                                                        <li><div className="cellLabel">URL: <a href="https://internal.evergreen.microsoft.com">https://internal.evergreen.microsoft.com</a></div></li>
                                                        <li><div className="cellLabel">Data latency: 48 Hours</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="columnTitle">Related filter fields</h3>
                                            <div className="columnCells">
                                                <div className="cell">
                                                    <PillGroup className="flex-row" overflow={PillGroupOverflow.wrap}>
                                                        <Pill
                                                            size={PillSize.compact}
                                                            color={whiteColor}
                                                            variant={PillVariant.outlined}
                                                        >SAP Guid
                                                        </Pill>
                                                    </PillGroup>
                                                </div>

                                                <DefaultButton
                                                    text="Field dictionary"
                                                    iconProps={{ iconName: 'DocumentSearch' }}
                                                    target="_blank"
                                                    href="https://aka.ms/ACCTFieldDictionary"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            );
        } else if (tabIndex === "tab2") {
            return (
                <div className="page-content-top">
                    <Card
                        className="bolt-card-content"
                        contentProps={{ contentPadding: true }}
                    >
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <h3 className="columnTitle">Details</h3>
                                    <div className="columnCells">
                                        <div className="cell">
                                            <header>
                                                Publisher
                                            </header>
                                            <span className="cellLabel">Microsoft CE&amp;S KM team</span>
                                        </div>
                                        <div className="cell">
                                            <header>
                                                Version
                                            </header>
                                            <span className="cellLabel">0.1.1</span>
                                        </div>
                                        <div className="cell">
                                            <header>
                                                Updated
                                            </header>
                                            <span className="cellLabel">{formatDateTime('5/16/2022')!}</span>
                                        </div>
                                        <div className="cell">
                                            <header>
                                                Curation areas
                                            </header>
                                            Curation
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <h3 className="columnTitle">Support</h3>
                                    <div className="columnCells">
                                        <div className="cell">
                                            <header>
                                                Algorithm
                                            </header>
                                            <span className="cellLabel"><a href="https://aka.ms/ACCTTools">Algorithm development v-team</a></span>
                                        </div>
                                        <div className="cell">
                                            <header>
                                                Data
                                            </header>
                                            <span className="cellLabel"><a href="https://microsoft.sharepoint.com/teams/CSSBIReporting">CE&amp;S BI team</a></span>
                                        </div>
                                        <div className="cell">
                                            <header>
                                                Platform
                                            </header>
                                            <span className="cellLabel"><a href="https://aka.ms/ACCTTools">Tooling team</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        } if (tabIndex === "tab3") {
            <div className="page-content page-content-top">
                <Card
                    className="flex-grow bolt-card-no-vertical-padding"
                    contentProps={{ contentPadding: true }}
                >

                </Card>
            </div>
        }
        return null;
    }

    return (
        <div className="algoPage">
            <CustomHeader className="bolt-header-with-commandbar">
                <HeaderIcon
                    className="bolt-table-status-icon-large"
                    iconProps={{ render: renderIcon }}
                    titleSize={TitleSize.Large}
                />
                <HeaderTitleArea>
                    <HeaderTitleRow>
                        <HeaderTitle
                            ariaLevel={3}
                            className="text-ellipsis"
                            titleSize={TitleSize.Medium}
                        >
                            Articles with Empty SAP Values
                        </HeaderTitle>
                        <div>by Microsoft Internal</div>
                    </HeaderTitleRow>
                    <HeaderDescription>
                        An algorithm to monitor the articles without SAP values.
                    </HeaderDescription>
                    <div className="ms-Grid intro">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <div className="categoryName">Domain:</div>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                <PillGroup className="flex-row">
                                    <Pill
                                        size={PillSize.compact}
                                        color={lightColor}
                                        variant={PillVariant.outlined}
                                    >Evergreen
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >SMC
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >DMC
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >SXC
                                    </Pill>
                                </PillGroup>
                            </div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <div className="categoryName"> Curation areas:</div>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                <PillGroup className="flex-row">
                                    <Pill size={PillSize.compact} color={lightColor}>
                                        Curation
                                    </Pill>
                                </PillGroup>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <div className="categoryName">Tags:</div>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                <PillGroup className="flex-row">
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >Product Lifecycle
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >
                                        SAP
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >
                                        Product Name
                                    </Pill>

                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >
                                        Product Version
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >
                                        Product Family
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >
                                        Content Lifecycle
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >
                                        Archiving
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >
                                        End of support
                                    </Pill>
                                    <Pill
                                        size={PillSize.compact}
                                        color={whiteColor}
                                        variant={PillVariant.outlined}
                                    >
                                        Applies to
                                    </Pill>
                                </PillGroup>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <div className="categoryName"> Status:</div>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                <Toggle
                                    offText={"Disabled"}
                                    onText={"Enabled on internal content domain"}
                                    checked={true}
                                    onChange={(event, value) => (alert("You do not have permission to perform this action. Only those users who have site Admin rights will be able to disable or enable a system algorithm."))}
                                />
                            </div>
                        </div>
                    </div>
                </HeaderTitleArea>
            </CustomHeader>
            <div className="page-content">
                <TabBar
                    onSelectedTabChanged={onSelectedTabChanged}
                    selectedTabId={selectedTabId}
                    tabSize={TabSize.Tall}
                    disableSticky={false}
                >
                    <Tab name="Overview" id="tab1" />
                    <Tab name="Details + Support" id="tab2" />
                </TabBar>

                {renderFilterBarInContent()}
                {getPageContent()}
            </div>
        </div>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(ArticlesWithEmptySAPValues);
