import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { GlobalDataQuery } from "../../Interface/IPowerQuery";
import { bindActionCreators } from "redux";
import { returnType } from "../../Utilities/ReturnType";

import { ITableColumn, SimpleTableCell, Table, renderSimpleCell } from "azure-devops-ui/Table";
import { ArrayItemProvider } from "azure-devops-ui/Utilities/Provider";
import { Link } from "azure-devops-ui/Link";
import InfiniteScroll from "react-infinite-scroll-component";
import { getCurationQueryFields, buildCurationQueryColumns, buildCurationTableItems, buildConditionJson } from "../../Utilities/QueryBuilderExtension";
import { QueryExpression } from "../../Utilities/QueryBuilderUtils";

import { searchContent, clearSearchResults } from "../../../redux/Actions/PowerDataQuery";

import "./DataQueryResult.css";

const actionCreators = {
    searchContent,
    clearSearchResults
};

type DispatchProps = typeof actionCreators;

const mapStateToProps = (state: GlobalDataQuery) => ({
    searchResult: state.searchResult
});

const storeProps = returnType(mapStateToProps);
type StoreProps = typeof storeProps.returnType;

interface IPaginationState {
    PageSize: number,
    PageIndex: number,
    SearchDataIndex: number
}

interface IQueryConditionsProps {
    AlgorithmType: string;
    metadataSchemaProps: string;
    filterStringProps: string;
    clickCountProps: number;
    expressionChildren: QueryExpression[];
    indexName: string;
}

function DataQueryResult(props: StoreProps & DispatchProps & IQueryConditionsProps) {
    const [articleItems, setArticleItems] = useState<Array<any>>([])
    const [articlePagination, setArticlePagination] = useState<IPaginationState>({
        SearchDataIndex: 0,
        PageSize: 200,
        PageIndex: 0
    })

    const [seeMore, setSeeMore] = useState<boolean>(true)
    const [firstDataLoadComplete, setFirstDataLoadComplete] = useState<boolean>(false)

    /**
     * When props.metadataSchemaProps changes, the initial configuration
     */
    useEffect(() => {

        setArticlePagination({
            SearchDataIndex: 0,
            PageSize: 200,
            PageIndex: 0
        })

        setArticleItems([])

        const loadData = async () => {

            let expList = buildConditionJson(props.expressionChildren);

            const ConditionJsonString = JSON.stringify(expList);
            // console.log("expList", ConditionJsonString)
            let selectString = getCurationQueryFields(props.metadataSchemaProps, props.AlgorithmType);
            await props.searchContent(
                "*",
                props.metadataSchemaProps,
                props.filterStringProps,
                ConditionJsonString,
                selectString,
                articlePagination.PageSize,
                0
            );
        }

        if (props && props.metadataSchemaProps !== '') {
            loadData();
        }

        return () => {
            props.clearSearchResults()
        }

    }, [props.metadataSchemaProps])

    /**
     * like componentDidMount
     */
    useEffect(() => {
        buildArticleItems()
    }, [props.searchResult.Topics])

    /**
    * like componentDidMount
    */
    useEffect(() => {
        setArticleItems([])
        setSeeMore(true)
        setFirstDataLoadComplete(true)
    }, [props.clickCountProps])

    /**
     * 
     * @returns 
     */
    function buildArticleItems() {

        let isSeeMore = true
        if (props.searchResult && props.searchResult.Topics) {

            let tableItems = buildCurationTableItems(props.searchResult.Topics, props.metadataSchemaProps, 'DataQueryResult', props.indexName);
            if(props.metadataSchemaProps?.startsWith("gitpub-metadata-schema-others-")) {
                tableItems = tableItems.filter(item=>item.hasOwnProperty('ReferrerLink'));
            }
            if(props.metadataSchemaProps?.startsWith("gitpub-metadata-schema-media-")) {
                setArticleItems(tableItems)
                isSeeMore = false
            }else {
                setArticleItems([...articleItems, ...tableItems])
                if (props.searchResult.Topics.length < articlePagination.PageSize) {
                    isSeeMore = false
                }
            }
            if (firstDataLoadComplete && props.searchResult.Topics.length === 0) {
                isSeeMore = true
            }
        }
        else {
            isSeeMore = false
        }

        setSeeMore(isSeeMore)
    }

    /**
     * Build datalist columns
     * @returns 
     */
    function buildReportColumns(): any {
        return buildCurationQueryColumns(props.metadataSchemaProps, 'DataQueryResult', props.indexName)
    }

    /**
    * PageIndex    SearchDataIndex
    * 0            0
    * 1            100
    * 2            200
    */
    const fetchMoreData = async () => {
        let pageIndex = articlePagination.PageIndex + 1
        let searchDataIndex = pageIndex * articlePagination.PageSize

        setFirstDataLoadComplete(false)

        let selectString = getCurationQueryFields(props.metadataSchemaProps, props.AlgorithmType);
        let expList = buildConditionJson(props.expressionChildren);
        const conditionJsonString = JSON.stringify(expList);

        await props.searchContent(
            "*",
            props.metadataSchemaProps,
            props.filterStringProps,
            conditionJsonString,
            selectString,
            articlePagination.PageSize,
            searchDataIndex
        );

        setArticlePagination({
            ...articlePagination,
            PageIndex: pageIndex,
            SearchDataIndex: searchDataIndex
        })
    }

    function getLoaderText(): JSX.Element {
        if (props.searchResult && props.searchResult.Topics) {
            if (
                props.searchResult.Topics.length === 0 &&
                props.searchResult.hasRunQuery) {
                return (
                    <h4>No results found</h4>
                )
            }
            else {
                return (
                    <h4>Loading...</h4>
                )
            }
        }

        return (
            <h4>Loading...</h4>
        )
    }

    return (
        <div style={{ height: "100%" }}>
            <div id='msacct-algo-scrollableDiv'
                style={{
                    overflow: 'auto',
                    display: 'flex',
                    height: "65%"
                }}>
                <InfiniteScroll
                    dataLength={articleItems.length}
                    next={fetchMoreData}
                    hasMore={seeMore}
                    loader={
                        getLoaderText()
                    }
                    scrollableTarget="msacct-algo-scrollableDiv"
                    endMessage={
                        <p style={{ textAlign: 'left' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                    <Table<Partial<any>>
                        columns={buildReportColumns()}
                        itemProvider={new ArrayItemProvider<any>(articleItems)}
                        showLines={true}
                        pageSize={2000}
                        containerClassName="msacct-devops-table"
                        showScroll={false}
                    />
                </InfiniteScroll>
            </div>
            <div style={{ textAlign: "right", marginRight: "20px" }}>
                <span>(Showing {articleItems.length} of {props.metadataSchemaProps?.startsWith("gitpub-metadata-schema-media-")? articleItems.length: props.searchResult.Count})</span>
            </div>
        </div>
    )
}

export default connect<StoreProps, DispatchProps>(
    mapStateToProps,
    bindActionCreators.bind({}, actionCreators)
)(DataQueryResult);
