import React from "react";
import {
    PrimaryButton
} from "@fluentui/react";
import {
    useNavigate,
} from "react-router-dom";

interface NavigatePrimaryButtonProps {
    buttonText: string
    jumpPath: string
    target?: string
}

export default function NavigatePrimaryButton(props: NavigatePrimaryButtonProps) {
    const history = useNavigate();

    function onPrimaryButtonClick(event: React.MouseEvent<HTMLElement>) {
        if (props.target==null) {
            history(props.jumpPath);
        } else {
            window.open(props.jumpPath, props.target);
        }
    }

    return (
        <>
            <PrimaryButton onClick={onPrimaryButtonClick}>{props.buttonText}</PrimaryButton>
        </>
    );
}
